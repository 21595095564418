import { Injectable } from '@angular/core';
import {ContainerColumn} from './model/container-column';
import {ColumnIds} from './model/column-ids';
import {properties} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ColumnUtils} from './model/column-utils';

@Injectable({
  providedIn: 'root'
})
export class ColumnService {

  private columns: ContainerColumn[];

  constructor(private http: HttpClient) { }

  async getColumns(): Promise<ContainerColumn[]> {
    if (this.columns) {
      return this.columns;
    } else {
      await this.http.get<ContainerColumn[]>(properties.serverUrl + '/column').toPromise().then(data => {
        this.columns = this.reconcileColumns(data, this.generateDefaultColumns());
      });
    }
    return this.columns;
  }
  async getPivotColumns(): Promise<ContainerColumn[]> {
    if (this.columns) {
      return this.reconcilePivotColumns(this.columns);
    } else {
      await this.http.get<ContainerColumn[]>(properties.serverUrl + '/column').toPromise().then(data => {
        this.columns = this.reconcileColumns(data, this.generateDefaultColumns());

      });
      return this.reconcilePivotColumns(this.columns);
    }
  }

  updateColumns(columns: ContainerColumn[]) {
    let seq = 0;
    columns.forEach(item => {
      item.seq = ++ seq;
    });
    this.columns = columns;
    return this.http.post(properties.serverUrl + '/column', columns);
  }
  private reconcileColumns(savedItems: ContainerColumn[], defaultItems: ContainerColumn[]): ContainerColumn[] {

    const list = new Array<ContainerColumn>();

    let addingSeq = savedItems.length;

    defaultItems.forEach(defaultItem => {

      let found = false;
      savedItems.forEach(savedItem => {
        savedItem.editable = true;
        if (defaultItem.code === savedItem.code) {

          if (!savedItem.name) {
            savedItem.name = ColumnUtils.getDefaultShortName(savedItem.code);
          }
          savedItem.fullName = ColumnUtils.getDefaultFullName(savedItem.code);
          list.push(new ContainerColumn(savedItem.code, savedItem.name, savedItem.fullName, savedItem.visible, savedItem.seq, defaultItem.colorable, savedItem.colored));
          found = true;
          return;
        }
      });

      if (!found) {
        ++ addingSeq;
        defaultItem.seq = addingSeq;
        if (!defaultItem.name) {
          defaultItem.name = ColumnUtils.getDefaultShortName(defaultItem.code);
        }
        defaultItem.fullName = ColumnUtils.getDefaultFullName(defaultItem.code);
        defaultItem.editable = true;
        list.push(defaultItem);
      }
    });
    function sort(a: ContainerColumn, b: ContainerColumn) {

      if (!a.visible  && b.visible) {
        return 1;
      } else if (a.visible && !b.visible) {
        return -1;
      } else {
        if (a.seq > b.seq) {
          return 1;
        } else if (a.seq < b.seq) {
          return -1;
        } else {
          return 0;
        }
      }
    }
    list.sort((a, b) => sort(a, b));
    let seq = 0;
    list.forEach(item => {
      item.seq = ++seq;
    });
    return list;
  }
  private reconcilePivotColumns(columns: ContainerColumn[]): ContainerColumn[] {
    const list = new Array<ContainerColumn>();

    columns.forEach(column => {

      if (this.isRecapableColumn(column.code)) {
        const recapColumnId = this.getRecapColumnId(column.code);

        if (recapColumnId) {
          const recapColumn = new ContainerColumn(recapColumnId, column.name + ' Y/N', column.fullName + 'Y/N', column.visible, column.seq);
          recapColumn.fullName = column.fullName + ' Y/N';
          list.push(recapColumn);
        } else {
          list.push(column);
        }
      }
    });
    // list.push(new ContainerColumn(ColumnIds.HATCH, 'Bay', 'Bay', false, list.length + 1));
    return list;
  }
  private generateDefaultColumns(): ContainerColumn[] {
    const columns = new Array<ContainerColumn>();
    let seq = 0;
    columns.push(new ContainerColumn(ColumnIds.CONTAINER_NO, ColumnUtils.getDefaultShortName(ColumnIds.CONTAINER_NO), ColumnUtils.getDefaultFullName(ColumnIds.CONTAINER_NO), true, ++seq));
    columns.push(new ContainerColumn(ColumnIds.CARRIER, ColumnUtils.getDefaultShortName(ColumnIds.CARRIER), ColumnUtils.getDefaultFullName(ColumnIds.CARRIER), true, ++seq, true, true));
    columns.push(new ContainerColumn(ColumnIds.STOWAGE, ColumnUtils.getDefaultShortName(ColumnIds.STOWAGE), ColumnUtils.getDefaultFullName(ColumnIds.STOWAGE), true, ++seq));
    columns.push(new ContainerColumn(ColumnIds.ISO_SIZE_TYPE, ColumnUtils.getDefaultShortName(ColumnIds.ISO_SIZE_TYPE), ColumnUtils.getDefaultFullName(ColumnIds.ISO_SIZE_TYPE), true, ++seq));
    columns.push(new ContainerColumn(ColumnIds.CONTAINER_LENGTH, ColumnUtils.getDefaultShortName(ColumnIds.CONTAINER_LENGTH), ColumnUtils.getDefaultFullName(ColumnIds.CONTAINER_LENGTH), true, ++seq, true, true));
    columns.push(new ContainerColumn(ColumnIds.CONTAINER_HEIGHT, ColumnUtils.getDefaultShortName(ColumnIds.CONTAINER_HEIGHT), ColumnUtils.getDefaultFullName(ColumnIds.CONTAINER_HEIGHT), true, ++seq, true, true));
    columns.push(new ContainerColumn(ColumnIds.CONTAINER_TYPE, ColumnUtils.getDefaultShortName(ColumnIds.CONTAINER_TYPE), ColumnUtils.getDefaultFullName(ColumnIds.CONTAINER_TYPE), true, ++seq, true, true));

    columns.push(new ContainerColumn(ColumnIds.POR, ColumnUtils.getDefaultShortName(ColumnIds.POR), ColumnUtils.getDefaultFullName(ColumnIds.POR), false, ++seq, true, true));
    columns.push(new ContainerColumn(ColumnIds.POL, ColumnUtils.getDefaultShortName(ColumnIds.POL), ColumnUtils.getDefaultFullName(ColumnIds.POL), true, ++seq, true, true));
    columns.push(new ContainerColumn(ColumnIds.POD, ColumnUtils.getDefaultShortName(ColumnIds.POD), ColumnUtils.getDefaultFullName(ColumnIds.POD), true, ++seq, true, true));
    columns.push(new ContainerColumn(ColumnIds.TS_PORT, ColumnUtils.getDefaultShortName(ColumnIds.TS_PORT), ColumnUtils.getDefaultFullName(ColumnIds.TS_PORT), false, ++seq, true));
    columns.push(new ContainerColumn(ColumnIds.OPT_POD, ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD), ColumnUtils.getDefaultFullName(ColumnIds.OPT_POD), false, ++seq, true));
    columns.push(new ContainerColumn(ColumnIds.NPOD, ColumnUtils.getDefaultShortName(ColumnIds.NPOD), ColumnUtils.getDefaultFullName(ColumnIds.NPOD), false, ++seq, true));
    columns.push(new ContainerColumn(ColumnIds.FPOD, ColumnUtils.getDefaultShortName(ColumnIds.FPOD), ColumnUtils.getDefaultFullName(ColumnIds.FPOD), false, ++seq, true));
    columns.push(new ContainerColumn(ColumnIds.PLACE_OF_DELIVERY, ColumnUtils.getDefaultShortName(ColumnIds.PLACE_OF_DELIVERY), ColumnUtils.getDefaultFullName(ColumnIds.PLACE_OF_DELIVERY), false, ++seq, true));

    columns.push(new ContainerColumn(ColumnIds.FULL_EMPTY, ColumnUtils.getDefaultShortName(ColumnIds.FULL_EMPTY), ColumnUtils.getDefaultFullName(ColumnIds.FULL_EMPTY), true, ++seq, true, true));
    columns.push(new ContainerColumn(ColumnIds.CARGO_TYPE, ColumnUtils.getDefaultShortName(ColumnIds.CARGO_TYPE), ColumnUtils.getDefaultFullName(ColumnIds.CARGO_TYPE), true, ++seq, true, true));
    columns.push(new ContainerColumn(ColumnIds.IMDG, ColumnUtils.getDefaultShortName(ColumnIds.IMDG), ColumnUtils.getDefaultFullName(ColumnIds.IMDG), true, ++seq));
    columns.push(new ContainerColumn(ColumnIds.UNNO, ColumnUtils.getDefaultShortName(ColumnIds.UNNO), ColumnUtils.getDefaultFullName(ColumnIds.UNNO), true, ++seq));

    columns.push(new ContainerColumn(ColumnIds.BUNDLED_CONTAINER, ColumnUtils.getDefaultShortName(ColumnIds.BUNDLED_CONTAINER), ColumnUtils.getDefaultFullName(ColumnIds.BUNDLED_CONTAINER), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.BREAK_BULK, ColumnUtils.getDefaultShortName(ColumnIds.BREAK_BULK), ColumnUtils.getDefaultFullName(ColumnIds.BREAK_BULK), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.OVER_DIMENSIONS, ColumnUtils.getDefaultShortName(ColumnIds.OVER_DIMENSIONS), ColumnUtils.getDefaultFullName(ColumnIds.OVER_DIMENSIONS), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.OVER_SLOTS, ColumnUtils.getDefaultShortName(ColumnIds.OVER_SLOTS), ColumnUtils.getDefaultFullName(ColumnIds.OVER_SLOTS), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.TEMPERATURE, ColumnUtils.getDefaultShortName(ColumnIds.TEMPERATURE), ColumnUtils.getDefaultFullName(ColumnIds.TEMPERATURE), true, ++seq));
    columns.push(new ContainerColumn(ColumnIds.TEMPERATURE_RANGE, ColumnUtils.getDefaultShortName(ColumnIds.TEMPERATURE_RANGE), ColumnUtils.getDefaultFullName(ColumnIds.TEMPERATURE_RANGE), false, ++seq));

    columns.push(new ContainerColumn(ColumnIds.GROSS_WEIGHT, ColumnUtils.getDefaultShortName(ColumnIds.GROSS_WEIGHT), ColumnUtils.getDefaultFullName(ColumnIds.GROSS_WEIGHT), true, ++seq));
    columns.push(new ContainerColumn(ColumnIds.VGM_WEIGHT, ColumnUtils.getDefaultShortName(ColumnIds.VGM_WEIGHT), ColumnUtils.getDefaultFullName(ColumnIds.VGM_WEIGHT), true, ++seq));
    columns.push(new ContainerColumn(ColumnIds.VGM_CERTI_CODE, ColumnUtils.getDefaultShortName(ColumnIds.VGM_CERTI_CODE), ColumnUtils.getDefaultFullName(ColumnIds.VGM_CERTI_CODE), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.VGM_INFO, ColumnUtils.getDefaultShortName(ColumnIds.VGM_INFO), ColumnUtils.getDefaultFullName(ColumnIds.VGM_INFO), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.GROUP_CODE, ColumnUtils.getDefaultShortName(ColumnIds.GROUP_CODE), ColumnUtils.getDefaultFullName(ColumnIds.GROUP_CODE), false, ++seq));

    columns.push(new ContainerColumn(ColumnIds.HANDLING_INSTRUCTION, ColumnUtils.getDefaultShortName(ColumnIds.HANDLING_INSTRUCTION), ColumnUtils.getDefaultFullName(ColumnIds.HANDLING_INSTRUCTION), true, ++seq));
    columns.push(new ContainerColumn(ColumnIds.SPECIAL_CARGO_INSTRUCTION, ColumnUtils.getDefaultShortName(ColumnIds.SPECIAL_CARGO_INSTRUCTION), ColumnUtils.getDefaultFullName(ColumnIds.SPECIAL_CARGO_INSTRUCTION), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.LOADING_REMARK, ColumnUtils.getDefaultShortName(ColumnIds.LOADING_REMARK), ColumnUtils.getDefaultFullName(ColumnIds.LOADING_REMARK), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.GENERAL_INFORMATION, ColumnUtils.getDefaultShortName(ColumnIds.GENERAL_INFORMATION), ColumnUtils.getDefaultFullName(ColumnIds.GENERAL_INFORMATION), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.DESCRIPTION_OF_GOODS, ColumnUtils.getDefaultShortName(ColumnIds.DESCRIPTION_OF_GOODS), ColumnUtils.getDefaultFullName(ColumnIds.DESCRIPTION_OF_GOODS), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.MUTUALLY_DEFINED_REMARK, ColumnUtils.getDefaultShortName(ColumnIds.MUTUALLY_DEFINED_REMARK), ColumnUtils.getDefaultFullName(ColumnIds.MUTUALLY_DEFINED_REMARK), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.CARGO_NATURE, ColumnUtils.getDefaultShortName(ColumnIds.CARGO_NATURE), ColumnUtils.getDefaultFullName(ColumnIds.CARGO_NATURE), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.PACKAGE_QUANTITY, ColumnUtils.getDefaultShortName(ColumnIds.PACKAGE_QUANTITY), ColumnUtils.getDefaultFullName(ColumnIds.PACKAGE_QUANTITY), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.PACKAGE_TYPE, ColumnUtils.getDefaultShortName(ColumnIds.PACKAGE_TYPE), ColumnUtils.getDefaultFullName(ColumnIds.PACKAGE_TYPE), false, ++seq));

    columns.push(new ContainerColumn(ColumnIds.BL_NO, ColumnUtils.getDefaultShortName(ColumnIds.BL_NO), ColumnUtils.getDefaultFullName(ColumnIds.BL_NO), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.BOOKING_NO, ColumnUtils.getDefaultShortName(ColumnIds.BOOKING_NO), ColumnUtils.getDefaultFullName(ColumnIds.BOOKING_NO), false, ++seq));

    columns.push(new ContainerColumn(ColumnIds.POL_TERMINAL, ColumnUtils.getDefaultShortName(ColumnIds.POL_TERMINAL), ColumnUtils.getDefaultFullName(ColumnIds.POL_TERMINAL), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.POD_TERMINAL, ColumnUtils.getDefaultShortName(ColumnIds.POD_TERMINAL), ColumnUtils.getDefaultFullName(ColumnIds.POD_TERMINAL), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.TS_TERMINAL, ColumnUtils.getDefaultShortName(ColumnIds.TS_TERMINAL), ColumnUtils.getDefaultFullName(ColumnIds.TS_TERMINAL), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.OPT_POD_TERMINAL, ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD_TERMINAL), ColumnUtils.getDefaultFullName(ColumnIds.OPT_POD_TERMINAL), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.NPOD_TERMINAL, ColumnUtils.getDefaultShortName(ColumnIds.NPOD_TERMINAL), ColumnUtils.getDefaultFullName(ColumnIds.NPOD_TERMINAL), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.POR_TERMINAL, ColumnUtils.getDefaultShortName(ColumnIds.POR_TERMINAL), ColumnUtils.getDefaultFullName(ColumnIds.POR_TERMINAL), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.PLACE_OF_DELIVERY_TERMINAL, ColumnUtils.getDefaultShortName(ColumnIds.PLACE_OF_DELIVERY_TERMINAL), ColumnUtils.getDefaultFullName(ColumnIds.PLACE_OF_DELIVERY_TERMINAL), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.EQUIPMENT_TYPE, ColumnUtils.getDefaultShortName(ColumnIds.EQUIPMENT_TYPE), ColumnUtils.getDefaultFullName(ColumnIds.EQUIPMENT_TYPE), false, ++seq));
    columns.push(new ContainerColumn(ColumnIds.HATCH, ColumnUtils.getDefaultShortName(ColumnIds.HATCH), ColumnUtils.getDefaultFullName(ColumnIds.HATCH), false, ++seq));

    return columns;
  }
  getRecapColumnId(code: string): string {
    if (code === ColumnIds.OVER_SLOTS) {
      return ColumnIds.OVER_SLOTS_YN;
    }
    if (code === ColumnIds.OVER_DIMENSIONS) {
      return ColumnIds.OVER_DIMENSIONS_YN;
    }
    if (code === ColumnIds.BREAK_BULK) {
      return ColumnIds.BREAK_BULK_YN;
    }
    if (code === ColumnIds.BUNDLED_CONTAINER) {
      return ColumnIds.BUNDLED_CONTAINER_YN;
    }
    if (code === ColumnIds.VGM_INFO) {
      return  ColumnIds.VGM_INFO_YN;
    }
    if (code === ColumnIds.VGM_WEIGHT) {
      return  ColumnIds.VGM_WEIGHT_YN;
    }
    if (code === ColumnIds.CARGO_NATURE) {
      return  ColumnIds.CARGO_NATURE_YN;
    }
    if (code === ColumnIds.PACKAGE_QUANTITY) {
      return  ColumnIds.PACKAGE_QUANTITY_YN;
    }
    if (code === ColumnIds.PACKAGE_TYPE) {
      return  ColumnIds.PACKAGE_TYPE_YN;
    }
    if (code === ColumnIds.GROUP_CODE) {
      return  ColumnIds.GROUP_CODE_YN;
    }
    if (code === ColumnIds.BL_NO) {
      return  ColumnIds.BL_NO_YN;
    }
    if (code === ColumnIds.BOOKING_NO) {
      return  ColumnIds.BOOKING_NO_YN;
    }
    if (code === ColumnIds.TEMPERATURE_RANGE) {
      return  ColumnIds.TEMPERATURE_RANGE_YN;
    }

    return undefined;
  }
  isRecapableColumn(code: string): boolean {

    if (code === ColumnIds.ISO_SIZE_TYPE
      || code === ColumnIds.CONTAINER_LENGTH
      || code === ColumnIds.CONTAINER_HEIGHT
      || code === ColumnIds.CONTAINER_TYPE
      || code === ColumnIds.FULL_EMPTY
      || code === ColumnIds.CARGO_TYPE
      || code === ColumnIds.POR
      || code === ColumnIds.POL
      || code === ColumnIds.POD
      || code === ColumnIds.TS_PORT
      || code === ColumnIds.OPT_POD
      || code === ColumnIds.NPOD
      || code === ColumnIds.PLACE_OF_DELIVERY
      || code === ColumnIds.DESCRIPTION_OF_GOODS
      || code === ColumnIds.HANDLING_INSTRUCTION
      || code === ColumnIds.LOADING_REMARK
      || code === ColumnIds.VGM_CERTI_CODE
      || code === ColumnIds.VGM_INFO
      || code === ColumnIds.GENERAL_INFORMATION
      || code === ColumnIds.GROUP_CODE
      || code === ColumnIds.CARRIER
      || code === ColumnIds.CARGO_NATURE
      || code === ColumnIds.SPECIAL_CARGO_INSTRUCTION
      || code === ColumnIds.PACKAGE_QUANTITY
      || code === ColumnIds.PACKAGE_TYPE
      || code === ColumnIds.POL_TERMINAL
      || code === ColumnIds.POD_TERMINAL
      || code === ColumnIds.OPT_POD_TERMINAL
      || code === ColumnIds.NPOD_TERMINAL
      || code === ColumnIds.POR_TERMINAL
      || code === ColumnIds.PLACE_OF_DELIVERY_TERMINAL
      // || code === ColumnIds.EQUIPMENT_TYPE
      || code === ColumnIds.OVER_SLOTS
      || code === ColumnIds.OVER_DIMENSIONS
      || code === ColumnIds.BREAK_BULK
      || code === ColumnIds.BUNDLED_CONTAINER
      || code === ColumnIds.VGM_WEIGHT
      || code === ColumnIds.MUTUALLY_DEFINED_REMARK
      || code === ColumnIds.BL_NO
      || code === ColumnIds.BOOKING_NO
      || code === ColumnIds.TEMPERATURE_RANGE
      || code === ColumnIds.HATCH


    ) {
      return true;
    }

    return false;
  }
}
