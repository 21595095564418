import {EdiDocument} from '../my-plans/service/model/edi-document';
import {BayPlan} from './model/bay-plan';
import {Datetime} from '../my-plans/service/model/datetime';
import {Stowage} from '../my-plans/service/model/stowage';
import {Container} from './model/container';
import {Place} from '../my-plans/service/model/place';
import {Freetext} from '../my-plans/service/model/freetext';
import {Measurement} from '../my-plans/service/model/measurement';
import {Equipment} from '../my-plans/service/model/equipment';
import {Reference} from '../my-plans/service/model/reference';
import {Dimension} from '../my-plans/service/model/dimension';
import {ContainerEquipment} from '../my-plans/service/model/container-equipment';
import {BaplieReaderService} from '../my-plans/service/baplie-reader.service';
import {Dangerous} from '../my-plans/service/model/dangerous';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import xml2js from 'xml2js';
import {VesselUtils} from '../hull/service/vessel-utils';
import {DateTimeUtils} from '../hull/service/date-time-utils';

@Injectable({
  providedIn: 'root'
})
export class BayPlanEdiParserService {

  constructor(private http: HttpClient) {}

  async parseText2BayPlan(text: string, id?: string, updateDatetime?: string, serviceLane?: string): Promise<BayPlan> {
    const document: EdiDocument = await this.parseText2EdiDocument(text, id, serviceLane);
    return this.parseEdiDocument2BayPlan(document, id, updateDatetime, serviceLane);
  }
  async parseText2EdiDocument(text: string, id?: string, updateDatetime?: string, serviceLane?: string): Promise<EdiDocument> {
    const ediReaderService: BaplieReaderService = new BaplieReaderService(text, this.http);
    return ediReaderService.parseEDI(id, updateDatetime, serviceLane);
  }
  parseEdiDocument2BayPlan(document: EdiDocument, id?: string, updateDatetime?: string, serviceLane?: string): BayPlan {
    const bayPlan: BayPlan = new BayPlan();
    this.parseSchedule(bayPlan, document);
    this.parseStowage(bayPlan.containers, document.stowages, 'LOA');
    this.parseStowage(bayPlan.shiftContainers, document.shiftStowages, 'SHI');
    this.parseStowage(bayPlan.restowContainers, document.restowStowages, 'RES');
    this.parseStowage(bayPlan.dischargeContainers, document.dischargeStowages, 'DIS');
    this.parseStowage(bayPlan.codContainers, document.codStowages, 'COD');
    this.parseStowage(bayPlan.excessContainers, document.excessStowages, 'EXC');
    this.parseStowage(bayPlan.balanceContainers, document.balanceStowages, 'BAL');
    this.parseStowage(bayPlan.avoidContainers, document.avoidStowages, 'VOI');
    if (id) {
      bayPlan.header.id = id;
    }
    if (updateDatetime) {
      bayPlan.header.updateDatetime = updateDatetime;
    }
    if (serviceLane) {
      bayPlan.header.serviceLaneCode = serviceLane;
    }
    bayPlan.syntaxViolations = document.syntaxMessages;
    return bayPlan;
  }

 private parseSchedule(bayPlan: BayPlan, document: EdiDocument) {
    bayPlan.header.sender = this.fixNull(document.senderId);
    bayPlan.header.recipient = this.fixNull(document.recipientId);
    bayPlan.header.messageType = this.fixNull(document.messageType);
    bayPlan.header.versionNo = this.fixNull(document.versionNo);
    bayPlan.header.voyageNo = this.fixNull(document.transport.conveyanceRefNo);
    bayPlan.header.vesselCode = this.fixNull(document.transport.id);
    bayPlan.header.vesselCodeType = this.fixNull(document.transport.type);
    bayPlan.header.vesselName = this.fixNull(document.transport.vesselName);

    const vesselCodeType = bayPlan.header.vesselCodeType;
    if (vesselCodeType === 'CALLSIGN' || vesselCodeType === '103') {
       bayPlan.header.callSign = this.fixNull(bayPlan.header.vesselCode);
    } else if (vesselCodeType === 'IMO' || vesselCodeType === '146') {
       bayPlan.header.imoNo = this.fixNull(bayPlan.header.vesselCode);
    }

    for (const place of document.placeLocations) {
      if (place.type === '5') {
        bayPlan.header.portCode = this.fixNull(place.id);
      } else if (place.type === '61') {
        bayPlan.header.nextPortCode = this.fixNull(place.id);
      } else if (place.type === 'BE' || place.type === 'PB') {
        const beInfo = place.id.split('^');
        if (beInfo.length > 0) {
          bayPlan.header.id = beInfo[0];
        }
        if (beInfo.length > 1) {
          bayPlan.header.updateDatetime = beInfo[1];
        }
        if (beInfo.length > 2) {
          bayPlan.header.serviceLaneCode = beInfo[2];
        }
      }
    }
    for (const datetime of document.placeDates) {
      if (datetime.dateTimeCode === '178') {
        bayPlan.header.ata = DateTimeUtils.toRfcFormat(datetime.dateTime);
      } else if (datetime.dateTimeCode === '132') {
        bayPlan.header.nextEta = DateTimeUtils.toRfcFormat(datetime.dateTime);
      } else if (datetime.dateTimeCode === '133') {
        bayPlan.header.etd = DateTimeUtils.toRfcFormat(datetime.dateTime);
      } else if (datetime.dateTimeCode === '136') {
        bayPlan.header.atd = DateTimeUtils.toRfcFormat(datetime.dateTime);
      }
    }

    // ADD all parameter in EdiDocument
    bayPlan.header.interchangeControlRefStart = document.interchangeControlRefStart;
    bayPlan.header.communicationsAgreementId = document.communicationsAgreementId;
    bayPlan.header.messageRefNo = document.messageNo;
    bayPlan.header.releaseNumber = document.releaseNo;
    bayPlan.header.smdgVersion = document.associationCode;
    bayPlan.header.documentType = document.documentTypeCode;
    bayPlan.header.documentName = document.documentName;
    bayPlan.header.documentNo = document.documentNo;
    bayPlan.header.documentVersion = document.documentVersion;
    bayPlan.header.documentRevision = document.documentRevision;
    bayPlan.header.documentResponseType = document.documentResponseType;
    bayPlan.header.documentStatus = document.documentStatus;
    bayPlan.header.loadingVoyageNo = document.loadingVoyageNo;
    bayPlan.header.interchangeControlCount = document.interchangeControlCount;
    bayPlan.header.interchangeControlRefEnd = document.interchangeControlRefEnd;

    bayPlan.header.carrier = document.transport.carrier;
    bayPlan.header.transportCarrierResponsible = document.transport.carrierResponsibleAgency;
    bayPlan.header.transportCode = document.transport.type;
    bayPlan.header.transportResponsible = document.transport.responsibleAgency;
    bayPlan.header.transportCountryCode = document.transport.countryCode;

    bayPlan.header.placeLocations = document.placeLocations;
    bayPlan.header.placeDates = document.placeDates;
    bayPlan.header.remarks = document.remarks;
    bayPlan.header.preparationDate = document.preparationDate;
    bayPlan.header.preparationTime = document.preparationTime;
  }
  private fixNull(value: string): string {
    if (!value) {
      return '';
    }
    return value;
  }

  private parseStowage(containers: Array<Container>, stowageArray: Array<Stowage>, handle: string) {
    if (stowageArray === undefined || stowageArray.length === 0) {
      return;
    }
    for (const stowage of stowageArray) {
      const container: Container = new Container();
      container.lineIndex = stowage.lineIndex;
      container.stowage = VesselUtils.fixStowageType(stowage.location.id, 7);
      for (const text of stowage.remarks) {
        if (text.type === 'AAA') {
          container.descriptionOfGoods = text.remark;
        } else if (text.type === 'HAN') {
          container.handlingInstruction = text.remark;
        } else if (text.type === 'CLR') {
          container.loadingRemark = text.remark;
        } else if (text.type === 'AAI') {
          container.generalInformation = text.remark;
        } else if (text.type === 'AAY') {
          container.vgmCertificationCode = text.vgmCertificationCode;
          container.vgmInfo1 = text.remark;
          container.vgmInfo2 = text.vgmInfo2;
          container.vgmInfo3 = text.vgmInfo3;
          container.vgmInfo4 = text.vgmInfo4;
          container.vgmInfo5 = text.vgmInfo5;
        } else if (text.type === 'ZZZ' && container.descriptionOfGoods === undefined) {
          container.descriptionOfGoods = text.remark;
        }
      }
      for (const weight of stowage.weights) {
        if (weight.type === 'WT') {
          container.grossWeight = weight.value;
          container.grossWeightUnit = weight.unit;
        } else if (weight.type === 'VGM') {
          container.vgmWeight = weight.value;
          container.vgmWeightUnit = weight.unit;
        }
      }
      for (const dimension of stowage.dimensions) {
        if (dimension.code === '5') {
          container.overDimFront = dimension.length;
          container.overDimFrontUnit = dimension.unit;
        } else if (dimension.code === '6') {
          container.overDimBack = dimension.length;
          container.overDimBackUnit = dimension.unit;
        } else if (dimension.code === '7') {
          container.overDimRight = dimension.width;
          container.overDimRightUnit = dimension.unit;
        } else if (dimension.code === '8') {
          container.overDimLeft = dimension.width;
          container.overDimLeftUnit = dimension.unit;
        } else if (dimension.code === '9') {
          container.overDimHeight = dimension.height;
          container.overDimHeightUnit = dimension.unit;
        }
      }
      container.temperature = stowage.temperature.value;
      container.temperatureUnit = stowage.temperature.unit;
      container.temperatureRangeMin = stowage.rangeDetail.rangeMin;
      container.temperatureRangeMax = stowage.rangeDetail.rangeMax;
      container.temperatureRangeUnit = stowage.rangeDetail.unit;
      container.temperatureRangeType = stowage.rangeDetail.type;
      for (const place of stowage.places) {
        if (place.type === '9' || place.type === '6') {
          container.pol = place.id;
          container.polTerminalCode = place.terminalCode;
        } else if (place.type === '11' || place.type === '12') {
          container.pod = place.id;
          container.podTerminalCode = place.terminalCode;
        } else if (place.type === '13') {
          container.tsPort = place.id;
          container.tsTerminalCode = place.terminalCode;
        } else if (place.type === '64' || place.type === '63') {
          container.optPod1 = place.id;
          container.optPod1TerminalCode = place.terminalCode;
        } else if (place.type === '68') {
          container.optPod2 = place.id;
          container.optPod2TerminalCode = place.terminalCode;
        } else if (place.type === '70' || place.type === '69') {
          container.optPod3 = place.id;
          container.optPod3TerminalCode = place.terminalCode;
        } else if (place.type === '71') {
          container.optPod4 = place.id;
          container.optPod4TerminalCode = place.terminalCode;
        } else if (place.type === '74') {
          container.optPod5 = place.id;
          container.optPod5TerminalCode = place.terminalCode;
        } else if (place.type === '76' || place.type === '80') {
          container.por = place.id;
          container.porTerminalCode = place.terminalCode;
        } else if (place.type === '83') {
          container.placeOfDelivery = place.id;
          container.placeOfDeliveryTerminalCode = place.terminalCode;
        } else if (place.type === '97') {
          container.optPod = place.id;
          container.optPodTerminalCode = place.terminalCode;
        } else if (place.type === '152') {
          container.nPod = place.id;
          container.nPodTerminalCode = place.terminalCode;
        } else if (place.type === '65') {
          container.fPod = place.id;
          container.fPodTerminalCode = place.terminalCode;
        }
      }
      for (const reference of stowage.references) {
        if (reference.type === 'BM') {
          container.blNo = reference.referenceNo;
        } else if (reference.type === 'BN') {
          container.bookingNo = reference.referenceNo;
        } else if (reference.type === 'DSI') {
          if (handle === 'SHI' || handle === 'RES') {
            container.shiftStowage = reference.referenceNo;
          }
        }
      }

      if (stowage.containers.length > 0) {
        container.equipmentType = stowage.containers[0].equipment.type;
        container.containerNo = stowage.containers[0].equipment.id;
        container.isoSizeType = stowage.containers[0].equipment.sizeType;
        container.fullEmpty = stowage.containers[0].equipment.fullEmpty;
        if (container.fullEmpty === '4') {
          container.fullEmpty = 'M';
        } else if (container.fullEmpty === '5') {
          container.fullEmpty = 'F';
        }
        for (const equipments of stowage.containers[0].attachEquipments) {
          container.bundledContainers.push(equipments.id);
        }
        container.groupCode = stowage.containers[0].equipment.statusCode;
        if (handle === 'SHI' || handle === 'RES') {
          container.shiftResponsibleParty = stowage.containers[0].carrier;
        } else {
          container.carrier = stowage.containers[0].carrier;
        }
      }
      container.dangerousGoods = stowage.dangerousGoods;

      container.packageQuantity = stowage.cargo.packageNo;
      container.packageType = stowage.cargo.packageId;
      container.cargoNatures = [];
      for (const natureCargo of stowage.cargoNatures) {
        container.cargoNatures.push(natureCargo.agencyCode);
      }

      containers.push(container);
      // document.stowageMap.set(container.id, stowage);
    }
  }
  async parseBayPlan2Text(bayPlan: BayPlan, ediVersion?: string): Promise<string> {
    const document: EdiDocument = this.parseBayPlan2EdiDocument(bayPlan);
    return this.parseEdiDocument2Text(document, ediVersion);
  }
  parseBayPlan2EdiDocument(bayPlan: BayPlan): EdiDocument {
    const document: EdiDocument = new EdiDocument();
    if (!bayPlan) {
      return document;
    }
    this.parseSchedule2EdiDocument(document, bayPlan);
    this.parseStowage2EdiDocument(document.stowages, bayPlan.containers);
    this.parseStowage2EdiDocument(document.shiftStowages, bayPlan.shiftContainers);
    this.parseStowage2EdiDocument(document.restowStowages, bayPlan.restowContainers);
    this.parseStowage2EdiDocument(document.codStowages, bayPlan.codContainers);
    this.parseStowage2EdiDocument(document.dischargeStowages, bayPlan.dischargeContainers);
    this.parseStowage2EdiDocument(document.excessStowages, bayPlan.excessContainers);
    this.parseStowage2EdiDocument(document.balanceStowages, bayPlan.balanceContainers);
    this.parseStowage2EdiDocument(document.avoidStowages, bayPlan.avoidContainers);

    for (const container of bayPlan.containers) {
      if (container.shiftStowage && container.stowage.length > 1 && container.shiftStowage.length > 1) {
        let find = false;
        for (const shift of bayPlan.shiftContainers) {
          if (container.shiftStowage === shift.stowage) {
            shift.shiftStowage = container.shiftStowage;
            find = true;
          }
        }
        if (!find) {
          for (const restow of bayPlan.shiftContainers) {
            if (container.shiftStowage === restow.stowage) {
              restow.shiftStowage = container.shiftStowage;
              find = true;
            }
          }
        }
        if (!find) {
          const shiftStowage = new Stowage();
          shiftStowage.location.id = container.shiftStowage;
          const place = new Place();
          place.type = '11';
          place.id = container.pod;
          shiftStowage.places.push(place);
          const reference = new Reference();
          reference.type = 'DSI';
          reference.referenceNo = container.stowage;
          shiftStowage.references.push(reference);
          const equipment = new ContainerEquipment();
          equipment.equipment.id = container.containerNo;
          equipment.carrier = container.shiftResponsibleParty;
          shiftStowage.containers.push(equipment);
          if (container.stowage.substring(0, 2) === container.shiftStowage.substring(0, 2)) {
            document.shiftStowages.push(shiftStowage);
          } else {
            document.restowStowages.push(shiftStowage);
          }
        }
      }
    }

    document.id = bayPlan.header.id;
    document.serviceLaneCode = bayPlan.header.serviceLaneCode;
    document.updateDatetime = bayPlan.header.updateDatetime;
    document.syntaxMessages = bayPlan.syntaxViolations;
    return document;
  }
  async parseEdiDocument2Text(document: EdiDocument, ediVersion?: string): Promise<string> {
    const schemaArray: Array<SyntaxItem> = [];
    let schemaItem: SyntaxItem = new SyntaxItem('UNB', 0);
    schemaItem.segmentArray.push(new SegmentItem('s001', 'e0001', undefined));
    schemaItem.segmentArray.push(new SegmentItem('s001', 'e0002', undefined));
    schemaItem.segmentArray.push(new SegmentItem('s002.e0004', undefined, document.senderId));
    schemaItem.segmentArray.push(new SegmentItem('s003.e0010', undefined, document.recipientId));
    schemaItem.segmentArray.push(new SegmentItem('s004', 'e0017', document.preparationDate));
    schemaItem.segmentArray.push(new SegmentItem('s004', 'e0019', document.preparationTime));
    schemaItem.segmentArray.push(new SegmentItem('e0020', undefined, document.interchangeControlRefStart));
    schemaItem.segmentArray.push(new SegmentItem('e0032', undefined, document.communicationsAgreementId));
    schemaArray.push(schemaItem);

    schemaItem = new SyntaxItem('UNH', 0);
    schemaItem.segmentArray.push(new SegmentItem('e0062', undefined, document.messageNo));
    schemaItem.segmentArray.push(new SegmentItem('s009', 'e0065', document.messageType));
    schemaItem.segmentArray.push(new SegmentItem('s009', 'e0052', document.versionNo));
    schemaItem.segmentArray.push(new SegmentItem('s009', 'e0054', document.messageNo));
    schemaItem.segmentArray.push(new SegmentItem('s009', 'e0051', undefined));
    schemaItem.segmentArray.push(new SegmentItem('s009', 'e0057', undefined));
    schemaArray.push(schemaItem);

    schemaItem = new SyntaxItem('BGM', 0);
    schemaItem.segmentArray.push(new SegmentItem('e1004', undefined, document.documentNo));
    schemaItem.segmentArray.push(new SegmentItem('e1225', undefined, document.messageFunction));
    schemaItem.segmentArray.push(new SegmentItem('e4343', undefined, document.documentResponseType));
    schemaItem.segmentArray.push(new SegmentItem('e1373', undefined, document.documentStatus));
    schemaArray.push(schemaItem);

    schemaItem = new SyntaxItem('DTM', 0);
    schemaItem.segmentArray.push(new SegmentItem('c507', 'e2005', document.compilingDateTimeFormat));
    schemaItem.segmentArray.push(new SegmentItem('c507', 'e2380', document.compilingDateTime));
    schemaItem.segmentArray.push(new SegmentItem('c507', 'e2379', document.compilingDateTimeFormat));
    schemaArray.push(schemaItem);

    schemaItem = new SyntaxItem('RFF', 0);
    schemaItem.segmentArray.push(new SegmentItem('c506', 'e1153', document.referenceType));
    schemaItem.segmentArray.push(new SegmentItem('c506', 'e1154', document.referenceId));
    schemaItem.segmentArray.push(new SegmentItem('c506', 'e1156', document.referenceDocument));
    schemaItem.segmentArray.push(new SegmentItem('c506', 'e1056', document.referenceVersion));
    schemaItem.segmentArray.push(new SegmentItem('c506', 'e1060', document.referenceRevision));
    schemaArray.push(schemaItem);

    schemaItem = new SyntaxItem('TDT', 1);
    schemaItem.segmentArray.push(new SegmentItem('e8051', undefined, document.transport.type));
    schemaItem.segmentArray.push(new SegmentItem('e8028', undefined, document.transport.conveyanceRefNo));
    schemaItem.segmentArray.push(new SegmentItem('c040', 'e3127', document.transport.carrier));
    schemaItem.segmentArray.push(new SegmentItem('c040', 'c040.e1131', undefined));
    schemaItem.segmentArray.push(new SegmentItem('c040', 'e3055', document.transport.carrierResponsibleAgency));
    schemaItem.segmentArray.push(new SegmentItem('c222', 'e8213', document.transport.id));
    schemaItem.segmentArray.push(new SegmentItem('c222', 'c222.e1131', document.transport.type));
    schemaItem.segmentArray.push(new SegmentItem('c222', 'c222.e3055', document.transport.responsibleAgency));
    schemaItem.segmentArray.push(new SegmentItem('c222', 'e8212', document.transport.vesselName));
    schemaItem.segmentArray.push(new SegmentItem('c222', 'e8453', document.transport.countryCode));

    schemaItem.segmentArray.push(new SegmentItem('c040_15', 'e8213_15', document.transport.id));
    schemaItem.segmentArray.push(new SegmentItem('c040_15', 'c040.e1131_15', document.transport.type));
    schemaItem.segmentArray.push(new SegmentItem('c040_15', 'e3055_15', document.transport.responsibleAgency));
    schemaItem.segmentArray.push(new SegmentItem('c040_15', 'e8212_15', document.transport.vesselName));
    schemaItem.segmentArray.push(new SegmentItem('c040_15', 'e8453_15', document.transport.countryCode));
    schemaItem.segmentArray.push(new SegmentItem('c222_15', 'e3127_15', document.transport.carrier));
    schemaItem.segmentArray.push(new SegmentItem('c222_15', 'c222.e1131_15', undefined));
    schemaItem.segmentArray.push(new SegmentItem('c222_15', 'e3055_15', document.transport.carrierResponsibleAgency));

    schemaArray.push(schemaItem);

    for (const place of document.placeLocations) {
      schemaItem = new SyntaxItem('LOC', 1);
      schemaItem.segmentArray.push(new SegmentItem('e3227', undefined, place.type));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3225', place.id));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'c517.e1131', undefined));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'c517.e3055', place.responsibleAgency));
      if (place.countryCode) {
        schemaItem.segmentArray.push(new SegmentItem('c519', 'e3223', place.countryCode));
        schemaItem.segmentArray.push(new SegmentItem('c519', 'c519.e1131', undefined));
        schemaItem.segmentArray.push(new SegmentItem('c519', 'c519.e3055', place.countryResponsibleAgency));
      }
      if (place.stateId) {
        schemaItem.segmentArray.push(new SegmentItem('c553', 'e3223', place.stateId));
        schemaItem.segmentArray.push(new SegmentItem('c553', 'e1131', undefined));
      }
      schemaArray.push(schemaItem);
    }

    schemaItem = new SyntaxItem('LOC', 1);
    schemaItem.segmentArray.push(new SegmentItem('e3227', undefined, 'PB'));
    schemaItem.segmentArray.push(new SegmentItem('c517', 'e3225', document.id + '^' + document.updateDatetime + '^' + document.serviceLaneCode));
    schemaItem.segmentArray.push(new SegmentItem('c517', 'e1131', undefined));
    schemaItem.segmentArray.push(new SegmentItem('c517', 'e3055', undefined));
    schemaItem.segmentArray.push(new SegmentItem('c519', 'e3223', undefined));
    schemaItem.segmentArray.push(new SegmentItem('c519', 'e1131', undefined));
    schemaItem.segmentArray.push(new SegmentItem('c519', 'e3055', undefined));
    schemaItem.segmentArray.push(new SegmentItem('c553', 'e3223', undefined));
    schemaItem.segmentArray.push(new SegmentItem('c553', 'e1131', undefined));
    schemaArray.push(schemaItem);

    for (const datetime of document.placeDates) {
      schemaItem = new SyntaxItem('DTM', 1);
      schemaItem.segmentArray.push(new SegmentItem('c507', 'e2005', datetime.dateTimeCode));
      schemaItem.segmentArray.push(new SegmentItem('c507', 'e2380', datetime.dateTime));
      schemaItem.segmentArray.push(new SegmentItem('c507', 'e2379', datetime.dateTimeFormat));
      schemaArray.push(schemaItem);
    }

    if (document.loadingVoyageNo) {
      schemaItem = new SyntaxItem('RFF', 1);
      schemaItem.segmentArray.push(new SegmentItem('c506', 'e1153', undefined));
      schemaItem.segmentArray.push(new SegmentItem('c506', 'e1154', document.loadingVoyageNo));
      schemaArray.push(schemaItem);
    }

    schemaItem = new SyntaxItem('HAN', 1);
    schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'LOA'));
    schemaArray.push(schemaItem);
    this.parseStowages(schemaArray, document.stowages, ediVersion);

    if (document.dischargeStowages.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'DIS'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, document.dischargeStowages, ediVersion);
    }

    if (document.shiftStowages.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'SHI'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, document.shiftStowages, ediVersion);
    }

    if (document.restowStowages.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'RES'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, document.restowStowages, ediVersion);
    }

    if (document.codStowages.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'COD'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, document.codStowages, ediVersion);
    }

    if (document.excessStowages.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'EXC'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, document.excessStowages, ediVersion);
    }

    if (document.balanceStowages.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'BAL'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, document.balanceStowages, ediVersion);
    }

    if (document.avoidStowages.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'VOI'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, document.avoidStowages, ediVersion);
    }

    schemaItem = new SyntaxItem('UNT', 0);
    schemaItem.segmentArray.push(new SegmentItem('e0074', undefined, document.messageTrailer.segmentNo));
    schemaItem.segmentArray.push(new SegmentItem('e0062', undefined, document.messageTrailer.referenceNo));
    schemaArray.push(schemaItem);

    schemaItem = new SyntaxItem('UNZ', 0);
    schemaItem.segmentArray.push(new SegmentItem('e0036', undefined, document.interchangeControlCount));
    schemaItem.segmentArray.push(new SegmentItem('e0020', undefined, document.interchangeControlRefEnd));
    schemaArray.push(schemaItem);

    if (ediVersion === undefined || ediVersion === '') {
      if (document.messageType !== undefined && document.messageType !== '' && document.associationCode !== undefined && document.associationCode.length === 6) {
        ediVersion = document.messageType + document.associationCode.substring(4, 5) + '.' + document.associationCode.substring(5, 6);
      }
    }
    return this.parseSchema2Text(schemaArray, ediVersion);
  }

  private parseStowages(schemaArray: Array<SyntaxItem>, stowages: Array<Stowage>, ediVersion: string) {
    for (const stowage of stowages) {
      let schemaItem: SyntaxItem = new SyntaxItem('LOC', 2);
      schemaItem.segmentArray.push(new SegmentItem('e3227', undefined, undefined));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3225', stowage.location.id));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3055', stowage.location.responsibleAgencyFormat));
      schemaArray.push(schemaItem);

      schemaItem = new SyntaxItem('GID', 1);
      schemaItem.segmentArray.push(new SegmentItem('c213', 'e7224', stowage.cargo.packageNo));
      schemaItem.segmentArray.push(new SegmentItem('c213', 'e7065', stowage.cargo.packageId));
      schemaArray.push(schemaItem);

      for (const cargo of stowage.cargoNatures) {
        schemaItem = new SyntaxItem('GDS', 1);
        schemaItem.segmentArray.push(new SegmentItem('c703.e7085', undefined, cargo.code));
        schemaArray.push(schemaItem);
      }

      for (const freeText of stowage.remarks) {
        schemaItem = new SyntaxItem('FTX', 2);
        schemaItem.segmentArray.push(new SegmentItem('e4451', undefined, freeText.type));
        schemaItem.segmentArray.push(new SegmentItem('c107.4441', undefined, freeText.vgmCertificationCode));
        // if (freeText.type === 'AAY') {
        if (true) {
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.1', freeText.remark));
          schemaItem.segmentArray.push(new SegmentItem('c108.4440', undefined, freeText.remark));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.2', freeText.vgmInfo2));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.3', freeText.vgmInfo3));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.4', freeText.vgmInfo4));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.5', freeText.vgmInfo5));
        }
        schemaArray.push(schemaItem);
      }

      for (const weight of stowage.weights) {
        if (this.validValue(weight.value)) {
          schemaItem = new SyntaxItem('MEA', 1);
          schemaItem.segmentArray.push(new SegmentItem('e6311', undefined, weight.type));
          schemaItem.segmentArray.push(new SegmentItem('c174', 'e6411', weight.unit));
          schemaItem.segmentArray.push(new SegmentItem('c174', 'e6314', weight.value));
          schemaArray.push(schemaItem);
        }
      }

      for (const dimension of stowage.dimensions) {
        schemaItem = new SyntaxItem('DIM', 1);
        schemaItem.segmentArray.push(new SegmentItem('e6145', undefined, dimension.code));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6411', dimension.unit));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6168', dimension.length));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6140', dimension.width));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6008', dimension.height));
        schemaArray.push(schemaItem);
      }

      if (this.validValue(stowage.temperature.value)) {
        schemaItem = new SyntaxItem('TMP', 1);
        schemaItem.segmentArray.push(new SegmentItem('e6145', undefined, stowage.temperature.type));
        schemaItem.segmentArray.push(new SegmentItem('c239', 'e6246', stowage.temperature.value));
        schemaItem.segmentArray.push(new SegmentItem('c239', 'e6411', stowage.temperature.unit));
        schemaArray.push(schemaItem);
      }

      schemaItem = new SyntaxItem('RNG', 1);
      schemaItem.segmentArray.push(new SegmentItem('e6167', undefined, stowage.rangeDetail.type));
      schemaItem.segmentArray.push(new SegmentItem('c280', 'e6411', stowage.rangeDetail.unit));
      schemaItem.segmentArray.push(new SegmentItem('c280', 'e6162', stowage.rangeDetail.rangeMin));
      schemaItem.segmentArray.push(new SegmentItem('c280', 'e6152', stowage.rangeDetail.rangeMax));
      schemaArray.push(schemaItem);

      for (const place of stowage.places) {
        if (this.validValue(place.id)) {
          schemaItem = new SyntaxItem('LOC', 2.5);
          if (place.id) {
            if (ediVersion === 'BAPLIE1.5' ) {
              if (place.type === '9') {
                place.type = '6';
              } else if (place.type === '11') {
                place.type = '12';
              }
            } else {
              if (place.type === '6') {
                place.type = '9';
              } else if (place.type === '12') {
                place.type = '11';
              }
            }
            schemaItem.segmentArray.push(new SegmentItem('e3227', undefined, place.type));
            schemaItem.segmentArray.push(new SegmentItem('c517', 'e3225', place.id));
            schemaItem.segmentArray.push(new SegmentItem('c517', 'c517.e1131', place.qualifierCode));
            schemaItem.segmentArray.push(new SegmentItem('c517', 'c517.e3055', place.responsibleAgency));
          }
          if (place.terminalCode) {
            schemaItem.segmentArray.push(new SegmentItem('c519', 'e3223', place.terminalCode));
            schemaItem.segmentArray.push(new SegmentItem('c519', 'c519.e1131', place.terminalQualifierCode));
            schemaItem.segmentArray.push(new SegmentItem('c519', 'c519.e3055', place.terminalResponsibleAgency));
          }
          schemaArray.push(schemaItem);
        }
      }

      for (const reference of stowage.references) {
        schemaItem = new SyntaxItem('RFF', 2);
        schemaItem.segmentArray.push(new SegmentItem('c506', 'e1153', reference.type));
        schemaItem.segmentArray.push(new SegmentItem('c506', 'e1154', reference.referenceNo));
        schemaArray.push(schemaItem);
      }

      let lastContainer: ContainerEquipment;
      for (const container of stowage.containers) {
        schemaItem = new SyntaxItem('EQD', 2);
        schemaItem.segmentArray.push(new SegmentItem('e8053', undefined, container.equipment.type));
        schemaItem.segmentArray.push(new SegmentItem('c237.e8260', undefined, container.equipment.id));
        schemaItem.segmentArray.push(new SegmentItem('c224.e8155', undefined, container.equipment.sizeType));
        schemaItem.segmentArray.push(new SegmentItem('e8249', undefined, container.equipment.statusCode));
        schemaItem.segmentArray.push(new SegmentItem('e8169', undefined, container.equipment.fullEmpty));
        schemaArray.push(schemaItem);
        lastContainer = container;
      }

      if (lastContainer !== undefined) {
        for (const equipment of lastContainer.attachEquipments) {
          schemaItem = new SyntaxItem('EQA', 2);
          schemaItem.segmentArray.push(new SegmentItem('e8053', undefined, equipment.type));
          schemaItem.segmentArray.push(new SegmentItem('c237.e8260', undefined, equipment.id));
          schemaArray.push(schemaItem);
        }

        if (lastContainer.carrier) {
          schemaItem = new SyntaxItem('NAD', 2);
          schemaItem.segmentArray.push(new SegmentItem('e3035', undefined, lastContainer.carrierParty));
          schemaItem.segmentArray.push(new SegmentItem('c082', 'e3039', lastContainer.carrier));
          schemaItem.segmentArray.push(new SegmentItem('c082', 'e1131', undefined));
          schemaItem.segmentArray.push(new SegmentItem('c082', 'e3055', lastContainer.carrierAgency));
          schemaArray.push(schemaItem);
        }
      }

      for (const dangerous of stowage.dangerousGoods) {
        schemaItem = new SyntaxItem('DGS', 2);
        schemaItem.segmentArray.push(new SegmentItem('e8273', undefined, undefined));
        schemaItem.segmentArray.push(new SegmentItem('c205', 'e8351', dangerous.imdg));
        schemaItem.segmentArray.push(new SegmentItem('c205', 'e8078', dangerous.hazardPageNo));
        schemaItem.segmentArray.push(new SegmentItem('c234.e7124', undefined, dangerous.unno));
        schemaItem.segmentArray.push(new SegmentItem('c233', 'e7106', dangerous.shipmentFlashPoint));
        schemaItem.segmentArray.push(new SegmentItem('c233', 'e6411', dangerous.measureUnitCode));
        schemaItem.segmentArray.push(new SegmentItem('e8339', undefined, dangerous.packingGroup));
        schemaItem.segmentArray.push(new SegmentItem('e8364', undefined, dangerous.emsNo));
        schemaItem.segmentArray.push(new SegmentItem('e8410', undefined, dangerous.mfag));
        schemaItem.segmentArray.push(new SegmentItem('c235', 'e8158', dangerous.hazardIdUpper));
        schemaItem.segmentArray.push(new SegmentItem('c235', 'e8186', dangerous.hazardIdLower));
        schemaItem.segmentArray.push(new SegmentItem('c236', 'e8246', dangerous.goodsLabelMark1));
        schemaItem.segmentArray.push(new SegmentItem('c236', 'e8246.1', dangerous.goodsLabelMark2));
        schemaItem.segmentArray.push(new SegmentItem('c236', 'e8246.2', dangerous.goodsLabelMark3));
        schemaArray.push(schemaItem);

        // if (dangerous.freeTextCode || dangerous.freeText1 || dangerous.freeText2 || dangerous.freeText3) {
        for (const remark of dangerous.remarks) {
          schemaItem = new SyntaxItem('FTX', 4);
          schemaItem.segmentArray.push(new SegmentItem('e4451', undefined, remark.freeTextCode));
          schemaItem.segmentArray.push(new SegmentItem('c107.4441', undefined, remark.freeTextValueCode));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.1', remark.freeText1));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.2', remark.freeText2));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.3', remark.freeText3));
          schemaArray.push(schemaItem);
        }
      }

      if (stowage.extraElements.length > 0) {
        schemaItem = new SyntaxItem('ZZ', undefined);
        for (const text of stowage.extraElements) {
          schemaItem.segmentArray.push(new SegmentItem(undefined, undefined, text));
        }
        schemaArray.push(schemaItem);
      }
    }
  }

  private parseSchedule2EdiDocument(document: EdiDocument, bayPlan: BayPlan) {
    document.senderId = bayPlan.header.sender;
    document.recipientId = bayPlan.header.recipient;
    document.interchangeControlRefStart = bayPlan.header.interchangeControlRefStart;
    document.communicationsAgreementId = bayPlan.header.communicationsAgreementId;
    document.messageNo = bayPlan.header.messageRefNo;
    document.messageType = bayPlan.header.messageType;
    document.versionNo = bayPlan.header.versionNo;
    document.releaseNo = bayPlan.header.releaseNumber;
    document.associationCode = bayPlan.header.smdgVersion;

    document.documentTypeCode = bayPlan.header.documentType;
    document.documentName = bayPlan.header.documentName;
    document.documentNo = bayPlan.header.documentNo;
    document.documentVersion = bayPlan.header.documentVersion;
    document.documentRevision = bayPlan.header.documentRevision;
    document.messageFunction = bayPlan.header.messageFunction;
    document.documentResponseType = bayPlan.header.documentResponseType;
    document.documentStatus = bayPlan.header.documentStatus;

    document.transport.conveyanceRefNo = bayPlan.header.voyageNo;
    document.transport.id = bayPlan.header.vesselCode;
    document.transport.vesselName = bayPlan.header.vesselName;
    document.transport.carrier = bayPlan.header.carrier;

    if (bayPlan.header.portCode !== undefined) {
      const place: Place = new Place();
      place.type = '5';
      place.id = bayPlan.header.portCode;
      document.placeLocations.push(place);
    }
    if (bayPlan.header.nextPortCode !== undefined) {
      const place: Place = new Place();
      place.type = '61';
      place.id = bayPlan.header.nextPortCode;
      document.placeLocations.push(place);
    }

    if (bayPlan.header.ata !== undefined) {
      const dateTime: Datetime = this.getDateTimeDocument('178', bayPlan.header.ata);
      document.placeDates.push(dateTime);
    }
    if (bayPlan.header.nextEta !== undefined) {
      const dateTime: Datetime = this.getDateTimeDocument('132', bayPlan.header.nextEta);
      document.placeDates.push(dateTime);
    }
    if (bayPlan.header.etd !== undefined) {
      const dateTime: Datetime = this.getDateTimeDocument('133', bayPlan.header.etd);
      document.placeDates.push(dateTime);
    }
    if (bayPlan.header.atd !== undefined) {
      const dateTime: Datetime = this.getDateTimeDocument('136', bayPlan.header.atd);
      document.placeDates.push(dateTime);
    }
  }

  private validValue(value: string): boolean {
    return value !== undefined && value !== '';
  }

  private parseStowage2EdiDocument(stowageArray: Array<Stowage>, containers: Array<Container>) {
    if (!containers || containers.length === 0) {
      return;
    }
    for (const container of containers) {
      const stowage: Stowage = new Stowage();
      stowage.lineIndex = container.lineIndex;
      stowage.location.id = container.stowage;
      if (this.validValue(container.descriptionOfGoods)) {
        stowage.remarks.push(this.getFreeTextDocument('AAA', container.descriptionOfGoods));
      }
      if (this.validValue(container.handlingInstruction)) {
        stowage.remarks.push(this.getFreeTextDocument('HAN', container.handlingInstruction));
      }
      if (this.validValue(container.loadingRemark)) {
        stowage.remarks.push(this.getFreeTextDocument('CLR', container.loadingRemark));
      }
      if (this.validValue(container.generalInformation)) {
        stowage.remarks.push(this.getFreeTextDocument('AAI', container.generalInformation));
      }
      if (container.hasVgmInfo()) {
        const text: Freetext = new Freetext();
        text.type = 'AAY';
        text.vgmCertificationCode = container.vgmCertificationCode;
        text.remark = container.vgmInfo1;
        text.vgmInfo2 = container.vgmInfo2;
        text.vgmInfo3 = container.vgmInfo3;
        text.vgmInfo4 = container.vgmInfo4;
        text.vgmInfo5 = container.vgmInfo5;
        stowage.remarks.push(text);
      }
      if (this.validValue(container.grossWeight)) {
        const weight: Measurement = new Measurement();
        weight.type = 'WT';
        weight.value = container.grossWeight;
        weight.unit = container.grossWeightUnit;
        stowage.weights.push(weight);
      }
      if (this.validValue(container.vgmWeight)) {
        const weight: Measurement = new Measurement();
        weight.type = 'VGM';
        weight.value = container.vgmWeight;
        weight.unit = container.vgmWeightUnit;
        stowage.weights.push(weight);
      }
      if (this.validValue(container.overDimFront)) {
        stowage.dimensions.push(this.getDimensionDocument('5', container.overDimFront, container.overDimFrontUnit));
      }
      if (this.validValue(container.overDimBack)) {
        stowage.dimensions.push(this.getDimensionDocument('6', container.overDimBack, container.overDimBackUnit));
      }
      if (this.validValue(container.overDimRight)) {
        stowage.dimensions.push(this.getDimensionDocument('7', container.overDimRight, container.overDimRightUnit));
      }
      if (this.validValue(container.overDimLeft)) {
        stowage.dimensions.push(this.getDimensionDocument('8', container.overDimLeft, container.overDimLeftUnit));
      }
      if (this.validValue(container.overDimHeight)) {
        stowage.dimensions.push(this.getDimensionDocument('9', container.overDimHeight, container.overDimHeightUnit));
      }
      if (this.validValue(container.temperature)) {
        stowage.temperature.value = container.temperature;
        stowage.temperature.unit = container.temperatureUnit;
      }
      if (this.validValue(container.temperatureRangeMin) || this.validValue(container.temperatureRangeMax)) {
        stowage.rangeDetail.rangeMin = container.temperatureRangeMin;
        stowage.rangeDetail.rangeMax = container.temperatureRangeMax;
        stowage.rangeDetail.unit = container.temperatureRangeUnit;
        stowage.rangeDetail.type = container.temperatureRangeType;
      }
      if (this.validValue(container.pol)) {
        stowage.places.push(this.getPlaceDocument('9', container.pol));
      }
      if (this.validValue(container.pod)) {
        stowage.places.push(this.getPlaceDocument('11', container.pod));
      }
      if (this.validValue(container.tsPort)) {
        stowage.places.push(this.getPlaceDocument('13', container.tsPort));
      }
      if (this.validValue(container.optPod1)) {
        stowage.places.push(this.getPlaceDocument('64', container.optPod1));
      }
      if (this.validValue(container.optPod2)) {
        stowage.places.push(this.getPlaceDocument('68', container.optPod2));
      }
      if (this.validValue(container.optPod3)) {
        stowage.places.push(this.getPlaceDocument('70', container.optPod3));
      }
      if (this.validValue(container.nPod)) {
        stowage.places.push(this.getPlaceDocument('97', container.nPod));
      }
      if (this.validValue(container.fPod)) {
        stowage.places.push(this.getPlaceDocument('65', container.fPod));
      }
      if (this.validValue(container.optPod)) {
        stowage.places.push(this.getPlaceDocument('152', container.optPod));
      }
      if (this.validValue(container.blNo)) {
        stowage.references.push(this.getReferenceDocument('BM', container.blNo));
      }
      if (this.validValue(container.bookingNo)) {
        stowage.references.push(this.getReferenceDocument('BN', container.bookingNo));
      }
      if (this.validValue(container.containerNo)) {
        const containerEquipment: ContainerEquipment = new ContainerEquipment();
        if (container.equipmentType === '') {
          containerEquipment.equipment.type = 'CN';
        } else {
          containerEquipment.equipment.type = container.equipmentType;
        }
        containerEquipment.equipment.id = container.containerNo;
        containerEquipment.equipment.sizeType = container.isoSizeType;
        if (container.fullEmpty === 'M') {
          containerEquipment.equipment.fullEmpty = '4';
        } else if (container.fullEmpty === 'F') {
          containerEquipment.equipment.fullEmpty = '5';
        } else {
          containerEquipment.equipment.fullEmpty = container.fullEmpty;
        }
        containerEquipment.equipment.statusCode = container.groupCode;
        containerEquipment.carrier = container.carrier;
        for (const bundleNo of container.bundledContainers) {
          containerEquipment.attachEquipments.push(this.getEquipmentDocument(bundleNo));
        }
        stowage.containers.push(containerEquipment);
      }
      stowage.dangerousGoods = container.dangerousGoods;

      stowageArray.push(stowage);
    }
  }
  private getFreeTextDocument(subjectQualifier: string, freeText: string): Freetext {
    const text: Freetext = new Freetext();
    text.type = subjectQualifier;
    text.remark = freeText;
    return text;
  }
  private getDimensionDocument(qualifier: string, dimensionFront: string, dimensionFrontUnit: string): Dimension {
    const dimension: Dimension = new Dimension();
    dimension.code = qualifier;
    dimension.length = dimensionFront;
    dimension.unit = dimensionFrontUnit;
    return dimension;
  }
  private getPlaceDocument(qualifier: string, identification: string): Place {
    const place: Place = new Place();
    place.type = qualifier;
    place.id = identification;
    return place;
  }
  private getReferenceDocument(qualifier: string, referenceNumber: string): Reference {
    const reference: Reference = new Reference();
    reference.type = qualifier;
    reference.referenceNo = referenceNumber;
    return reference;
  }
  private getEquipmentDocument(identification: string): Equipment {
    const equipment: Equipment = new Equipment();
    equipment.id = identification;
    return equipment;
  }

  private getDateTimeDocument(dateTimeQualifier: string, value: string): Datetime {
    const dateTime: Datetime = new Datetime();
    dateTime.dateTimeCode = dateTimeQualifier;
    dateTime.dateTime = value.replace('T', '').split('-').join('').replace(':', '');
    if (dateTime.dateTime.length < 7) {
      dateTime.dateTimeFormat = '101';
    } else if (dateTime.dateTime.length < 11) {
      dateTime.dateTimeFormat = '201';
    } else {
      dateTime.dateTimeFormat = '301';
    }
    return dateTime;
  }
  private removeDelimiter(value: string, delimiter: string): string {
    for (let i = value.length; i > 0; i--) {
      if (value.substring(i - 1, i) !== delimiter) {
        return value.substring(0, i);
      }
    }
    return '';
  }
  private async parseSchema2Text(schemaArray: Array<SyntaxItem>, baplieVersion?: string): Promise<string> {
    let schemaFile = '/assets/schema/BAPLIE22.xml';

    if (baplieVersion === 'BAPLIE3.1') {
      schemaFile = '/assets/schema/BAPLIE31.xml';

    } else if (baplieVersion === 'BAPLIE2.0') {
      schemaFile = '/assets/schema/BAPLIE20.xml';

    } else if (baplieVersion === 'BAPLIE1.5') {
      schemaFile = '/assets/schema/BAPLIE15.xml';

    } else if (baplieVersion === 'MOVINS2.0') {
      schemaFile = '/assets/schema/MOVINS20.xml';

    } else if (baplieVersion === 'MOVINS2.1') {
      schemaFile = '/assets/schema/MOVINS21.xml';
    }
    const schema = await this.http.get(schemaFile, {responseType: 'text'}).toPromise();
    const parser: xml2js = new xml2js.Parser({trim: true, explicitArray: true});

    let syntax = '';
    parser.parseString(schema, function _(err, data) {
     syntax = data;
    });

    let ediText = '';
    const ediReaderService: BaplieReaderService = new BaplieReaderService(undefined, undefined);
    for (const item of schemaArray) {
      if (item.segmentId === 'LOC' && item.segmentArray.length > 0 && item.segmentArray[0].segmentId === 'e3227' && item.segmentArray[0].value === 'PB') {
        continue;
      }
      if (item.segmentId === 'ZZ') {
        for (const segment of item.segmentArray) {
          if (segment.value && segment.value.length > 0) {
            ediText += segment.value + '\'\n';
          }
        }
        continue;
      }

      ediReaderService.setSyntaxGroup(Number(item.syntaxGroup));
      const groupItem = ediReaderService.findSegmentSyntax(item.segmentId, syntax);
      if (!groupItem) {
        continue;
      }
      let text = '';
      text += item.segmentId;
      let segmentId = '';
      let tmpText = '';
      for (const segment of item.segmentArray) {
        if (segmentId !== segment.segmentId) {
          text += this.removeDelimiter(tmpText, ':');
          tmpText = '';
        }
        let elementItem;
        let elementSeq = 0;
        // tslint:disable-next-line:prefer-for-of
        for (let s = 0; s < groupItem.segment.length; s = s + 1) {
          if (groupItem.segment[s].$.id === segment.segmentId) {
            if (groupItem.segment[s].element === undefined) {
              elementItem = groupItem.segment[s];
            } else {
              // tslint:disable-next-line:prefer-for-of
              for (let e = 0; e < groupItem.segment[s].element.length; e = e + 1) {
                elementSeq++;
                if (groupItem.segment[s].element[e].$.id === segment.elementId) {
                  elementItem = groupItem.segment[s].element[e];
                  break;
                }
              }
            }
            break;
          }
          if (!elementItem && groupItem.segment[s].$.id === segment.elementId) {
            elementItem = groupItem.segment[s];
          }
        }
        if (!elementItem) {
          continue;
        }
        let symbolCount = 0;
        if (elementItem && elementItem.$.symbol) {
          symbolCount = Number(elementItem.$.symbol);
        } else if (elementItem) {
          symbolCount = 1;
        }
        let delimiter = ':';
        if (segmentId !== segment.segmentId || elementSeq === 1) {
          delimiter = '+';
        } else if (tmpText.length > 0 && tmpText.substring(tmpText.length - 1, tmpText.length) === '+' && (!elementItem || elementItem.$.mandatory !== 'O')) {
          continue;
        }
        for (let i = 0; i < symbolCount; i++) {
          tmpText += delimiter;
        }
        if (elementItem && elementItem.$.constant !== undefined) {
          tmpText += elementItem.$.constant;
        } else if (segment.value !== undefined) {
          tmpText += segment.value;
        }
        segmentId = segment.segmentId;
      }
      const tmpTexts2 = tmpText.replace('+', '').split(':');
      if (tmpTexts2.length > 0) {
        text += this.removeDelimiter(tmpText, ':');
      }
      const newText = this.removeDelimiter(text, '+').trim();
      if (newText.length > 4) {
        ediText += newText + '\'\n';
      }
    }
    return ediText;
  }
}

export class SyntaxItem {
  segmentId: string;
  syntaxGroup: number;
  segmentArray: Array<SegmentItem>;
  constructor(segmentId: string, syntaxGroup: number) {
    this.segmentId = segmentId;
    this.syntaxGroup = syntaxGroup;
    this.segmentArray = [];
  }
}
export class SegmentItem {
  segmentId: string;
  elementId: string;
  value: string;
  constructor(segmentId: string, elementId: string, value: string) {
    this.segmentId = segmentId;
    this.elementId = elementId;
    this.value = value;
  }
}
