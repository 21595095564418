import {ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CodeService} from '../../code/service/code.service';
import {Port} from '../../code/port/service/model/port';
import {Operator} from '../../code/operator/service/model/operator';
import {SizeTypeCode} from '../../code/size-type/service/model/size-type-code';
import {EventService} from '../../core/event/event.service';
import {EventIds} from '../../core/event/event-ids';
import {BayPlanService} from '../../service/bay-plan.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {ContextMenuModel} from './legend-context-menu/context-menu-model';
import {SizeTypeService} from '../../code/size-type/service/size-type.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OperatorService} from '../../code/operator/service/operator.service';
import {PortService} from '../../code/port/service/port.service';
import {ColumnIds} from '../columns/service/model/column-ids';
import {ColumnUtils} from '../columns/service/model/column-utils';
import {ColumnService} from '../columns/service/column.service';
import {Router} from '@angular/router';
import {MenuUrls} from '../../core/menu/menu-urls';
import {ToolbarEventService} from '../../core/event/toolbar-event.service';
import {SessionIds, SessionValues} from '../../core/service/session-ids';
import {PreferenceService} from '../../service/preference.service';
import {Preference} from '../../service/model/preference';
import {WeightGroupService} from '../../code/weight-group/service/weight-group.service';
import {WeightGroup} from '../../code/weight-group/service/weight-group';
import {OkDialogComponent} from '../../dialogs/ok-dialog/ok-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {StringUtils} from '../../utils/string-utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.css']
})
export class LegendComponent implements OnInit, OnDestroy {

  title = 'context-menu';

  isDisplayContextMenu: boolean;
  rightClickMenuItems: Array<ContextMenuModel> = [];
  rightClickMenuPositionX: number;
  rightClickMenuPositionY: number;

  ports: Port[];
  operators: Operator[];
  lengthCodes: SizeTypeCode[];
  heightCodes: SizeTypeCode[];
  typeCodes: SizeTypeCode[];
  fullEmpty: Preference[];
  cargoTypes: Preference[];
  weightGroups: WeightGroup[];
  private eventSubscription;
  private toolbarEventSubscription;

  menuTopLeftPosition =  {x: '0', y: '0'};
  portTypes = [{code: ColumnIds.POR, name: ColumnUtils.getDefaultShortName(ColumnIds.POR)}
              , {code: ColumnIds.POL, name: ColumnUtils.getDefaultShortName(ColumnIds.POL)}
              , {code: ColumnIds.POD, name: ColumnUtils.getDefaultShortName(ColumnIds.POD)}
              , {code: ColumnIds.TS_PORT, name: ColumnUtils.getDefaultShortName(ColumnIds.TS_PORT)}
              , {code: ColumnIds.NPOD, name: ColumnUtils.getDefaultShortName(ColumnIds.NPOD)}
              , {code: ColumnIds.PLACE_OF_DELIVERY, name: ColumnUtils.getDefaultShortName(ColumnIds.PLACE_OF_DELIVERY)}
              ];
  selectedPorts = [];

  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;
  foreColorPicker = false;
  backColorPicker = false;

  constructor(private elementRef: ElementRef,
              private codeService: CodeService,
              private columnService: ColumnService,
              private translate: TranslateService,
              private bayPlanService: BayPlanService,
              private eventService: EventService,
              private toolbarEventService: ToolbarEventService,
              private portService: PortService,
              private sizeTypeService: SizeTypeService,
              private operatorService: OperatorService,
              private preferenceService: PreferenceService,
              private weightGroupService: WeightGroupService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private router: Router,
              private cdr: ChangeDetectorRef) {

  }
  ngOnInit(): void {
    this.initCodes();

    if (this.router.url === MenuUrls.EDIT_LIST) {
      this.columnService.getColumns().then(columns => {
        columns.forEach(column => {
          if (ColumnUtils.isPortColumn(column.code) && column.visible && column.colored) {
            // this.portTypes.push({code: column.code, name: ColumnUtils.getDefaultShortName(column.name)});
            this.selectedPorts.push(column.code);
          }
        });
        this.initPort();
      });
    } else {
      const colorOption = sessionStorage.getItem(SessionIds.COLOR_OPTION);
      this.setSelectedPort(colorOption);
      this.initPort();
    }

    this.eventSubscription = this.eventService.getEmitter().subscribe(event => {
      if (event.id === EventIds.CODES_RECONCILED) {
        this.initCodes();
        this.initPort();
        this.cdr.detectChanges();
      }
    });
    this.toolbarEventSubscription = this.toolbarEventService.getEmitter().subscribe(event => {
      if (event.id === EventIds.CHANGE_COLOR_OPTION) {
        this.setSelectedPort(event.newValue);
        this.initPort();
      }
    });
  }
  setSelectedPort(colorOption: string) {
    this.selectedPorts = new Array();

    if (colorOption === SessionValues.COLOR_POL) {
      this.selectedPorts.push(ColumnIds.POL);
    } else if (colorOption === SessionValues.COLOR_POR) {
      this.selectedPorts.push(ColumnIds.POR);
    } else if (colorOption === SessionValues.COLOR_TS_PORT) {
      this.selectedPorts.push(ColumnIds.TS_PORT);
    } else if (colorOption === SessionValues.COLOR_NPOD) {
      this.selectedPorts.push(ColumnIds.NPOD);
    } else if (colorOption === SessionValues.COLOR_PLACE_OF_DELIVERY) {
      this.selectedPorts.push(ColumnIds.PLACE_OF_DELIVERY);
    } else {
      this.selectedPorts.push(ColumnIds.POD);
    }
  }
  initCodes() {
    // this.ports = this.codeService.getOwnPorts();
    this.operators = this.codeService.getOwnOperators();

    this.lengthCodes = this.codeService.getContainerLengths();
    if (this.lengthCodes) {
      this.lengthCodes.sort((a, b) => sort(a.code, b.code));
    }

    this.heightCodes = this.codeService.getContainerHeights();
    if (this.heightCodes) {
      this.heightCodes.sort((a, b) => sort(a.code, b.code));
    }

    this.typeCodes = this.codeService.getContainerTypes();
    if (this.typeCodes) {
    this.typeCodes.sort((a, b) => sort(a.code, b.code));
    }
    this.fullEmpty = this.preferenceService.getPreferences('017');
    this.cargoTypes = this.preferenceService.getPreferences('016');
    this.weightGroups = this.codeService.getWeightGroups();

    function sort(a, b) {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    }
  }
  initPort() {

    this.ports = new Array<Port>();
    const ownPorts = this.codeService.getOwnPorts();

    if (ownPorts) {
      ownPorts.forEach(port => {

        let found = false;
        this.selectedPorts.forEach(type => {

          if (type === ColumnIds.POR && this.codeService.porMap.get(port.ownCode)) {
            found = true;
          }
          if (type === ColumnIds.POL && this.codeService.polMap.get(port.ownCode)) {
            found = true;
          }
          if (type === ColumnIds.POD && this.codeService.podMap.get(port.ownCode)) {
            found = true;
          }
          if (type === ColumnIds.TS_PORT && this.codeService.tsPortMap.get(port.ownCode)) {
            found = true;
          }
          if (type === ColumnIds.NPOD && this.codeService.npodMap.get(port.ownCode)) {
            found = true;
          }
          if (type === ColumnIds.PLACE_OF_DELIVERY && this.codeService.placeOfDeliveryMap.get(port.ownCode)) {
            found = true;
          }
        });
        if (found) {
          this.ports.push(port);
        }
      });
    }

    if (this.ports) {
      this.ports.sort((a, b) => sort(a.code, b.code));
    }

    function sort(a, b) {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    }
  }
  getPortLabel(portCode: string) {

    // if (portCode && portCode.length === 5) {
    //
    //   return portCode.substring(0, 2) + ' ' + portCode.substring(2);
    // }
    return portCode;
  }
  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.toolbarEventSubscription) {
      this.toolbarEventSubscription.unsubscribe();
    }
  }

  clickPort(e: any) {
  }

  mouseEnter(code: any) {
  }

  mouseLeave(code: any) {
  }
  onRightClick(event: MouseEvent, item: any) {
    // event.preventDefault();

    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    this.matMenuTrigger.menuData = {data: item};

    this.matMenuTrigger.openMenu();
  }

  displayContextMenu(event) {
    this.isDisplayContextMenu = true;

    this.rightClickMenuItems = [
      {
        menuText: 'Refactor',
        menuEvent: 'Handle refactor',
      },
      {
        menuText: 'Format',
        menuEvent: 'Handle format',
      },
    ];

    this.rightClickMenuPositionX = event.clientX;
    this.rightClickMenuPositionY = event.clientY;

  }

  getRightClickMenuStyle() {
    return {
      position: 'fixed',
      left: `${this.rightClickMenuPositionX}px`,
      top: `${this.rightClickMenuPositionY}px`
    };
  }

  handleMenuItemClick(event) {
    switch (event.data) {
      case this.rightClickMenuItems[0].menuEvent:
        break;
      case this.rightClickMenuItems[1].menuEvent:
    }
  }

  @HostListener('document:click')
  documentClick(): void {
    this.isDisplayContextMenu = false;
  }

  changePortColor(port: Port) {
    this.portService.changePortColor(port, this.bayPlanService.getBayPlan().header.serviceLaneCode).subscribe(data => {
      this.eventService.emit(EventIds.CHANGE_COLOR_OPTION);
      this.openSnackBar('Saved', 'Close');
    }, error => {
      // this.openSnackBar(error, 'Close');
      this.showErrorDialog(error);
    });
  }
  changeOperatorColor(operator: Operator) {
    this.operatorService.changeOperatorColor(operator, this.bayPlanService.getBayPlan().header.serviceLaneCode).subscribe(data => {
      this.eventService.emit(EventIds.CHANGE_COLOR_OPTION);
      this.openSnackBar('Saved', 'Close');
    }, error => {
      this.showErrorDialog(error);
    });
  }
  changeLengthColor(sizeType: SizeTypeCode) {
    this.sizeTypeService.updateContainerLength([sizeType]).subscribe(data => {
      this.codeService.changeLengthColor(sizeType);
      this.eventService.emit(EventIds.CHANGE_COLOR_OPTION);
      this.openSnackBar('Saved', 'Close');

    }, error => {
      this.showErrorDialog(error);
    });
  }
  changeHeightColor(sizeType: SizeTypeCode) {
    this.sizeTypeService.updateContainerHeight([sizeType]).subscribe(data => {
      this.codeService.changeHeightColor(sizeType);
      this.eventService.emit(EventIds.CHANGE_COLOR_OPTION);
      this.openSnackBar('Saved', 'Close');
    }, error => {
      this.showErrorDialog(error);
    });
  }
  changeTypeColor(sizeType: SizeTypeCode) {
    this.sizeTypeService.updateContainerType([sizeType]).subscribe(data => {
      this.codeService.changeTypeColor(sizeType);
      this.eventService.emit(EventIds.CHANGE_COLOR_OPTION);
      this.openSnackBar('Saved', 'Close');
    }, error => {
      this.showErrorDialog(error);
    });
  }
  changeWeightGroupColor(weightGroup: WeightGroup) {
    this.weightGroupService.updateWeightGroup([weightGroup]).subscribe(data => {
      this.eventService.emit(EventIds.CHANGE_COLOR_OPTION);
      this.openSnackBar('Saved', 'Close');
    }, error => {
      this.showErrorDialog(error);
    });
  }
  formatWeight(weightGroup: WeightGroup) {
    let minWeight = StringUtils.formatNumber(weightGroup.minWeight);
    let maxWeight = StringUtils.formatNumber(weightGroup.maxWeight);

    if (!minWeight) {
      minWeight = '0';
    }

    if (!maxWeight) {
      maxWeight = '0';
    }

    return minWeight + ' kg < weight <= ' + maxWeight + ' kg';
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  showErrorDialog(error: any) {
    console.error(error);
    let errorMessage = error.message;
    if (typeof error.error === 'string') {
      errorMessage = this.translate.instant(error.error);
    }
    const dialogRef = this.dialog.open(OkDialogComponent, {
      maxWidth: '800px',
      data: {header: error.statusText, message: errorMessage}
    });
  }
  changePort() {

  }

  changePreference(fm: Preference) {
    this.preferenceService.updatePreference(fm).subscribe(data => {
      this.eventService.emit(EventIds.CHANGE_COLOR_OPTION);
      this.openSnackBar('Saved', 'Close');
    }, error => {
      this.showErrorDialog(error);
    });
  }
}
