<div fxLayout="row wrap" fxLayoutAlign="center start">
  <div fxFlex.gt-sm="55" fxFlex="100">
    <mat-card class="mat-card-top pa-0">
      <div class="pa-1" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          Preferences
        </div>
<!--        <button mat-raised-button class="primary" color="primary" (click)="save()">Save</button>-->
        <!--        <button mat-raised-button color="primary">Cancel</button>-->
      </div>
      <div fxLayout="column" fxLayoutAlign="start start" >

        <mat-radio-group aria-label="Vessel design" [(ngModel)]="vesselDesign" class="radio-group" (ngModelChange)="changeValue('VesselDesign')">
          <span>Vessel Design:</span>
          <mat-radio-button [value]="'Certified'" class="radio-button">Use certified vessel design if exists</mat-radio-button>
          <mat-radio-button [value]="'Baplie'" class="radio-button">Always structure based on Baplie</mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-card>
  </div>
</div>
