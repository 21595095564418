<div *ngIf="isEditable && containers.length == 1">
  <button mat-icon-button color="primary"
          matTooltipClass="tooltip"
          matTooltip="Lock for readonly"
          (click)="changeEditable(false)">
    <mat-icon>lock_open</mat-icon>
  </button>
  <button mat-icon-button color="primary"
          matTooltipClass="tooltip"
          matTooltip="Save properties"
          disabled = "{{!authService.eligibleToEdit()}}"
          (click)="cntrEdit.save()">
    <mat-icon>save</mat-icon>
  </button>
  <app-container-edit [container]="selectedContainer?selectedContainer: getEmptyContainer()" [containerEmitter]="containerEmitter" #cntrEdit></app-container-edit>
</div>
<div *ngIf="isEditable && containers.length !== 1">
</div>
<div *ngIf="!isEditable">
  <div *ngIf="containers.length == 1">
    <button mat-icon-button color="primary"
            matTooltipClass="tooltip"
            matTooltip="Unlock to edit properties"
            (click)="changeEditable(true)">
      <mat-icon>lock</mat-icon>
    </button>
    <button mat-icon-button color="primary"
            matTooltipClass="tooltip"
            matTooltip="Filter containers like these"
            (click)="cntrDetail.applyFilters()">
      <mat-icon>bolt</mat-icon>
    </button>
    <button mat-icon-button color="primary"
            matTooltipClass="tooltip"
            matTooltip="Clear filters"
            *ngIf="filterService.filter.numOfFilters() > 0"
            (click)="filterService.clearFilters()">
      <mat-icon>filter_alt_off</mat-icon>
    </button>
  </div>
  <div *ngIf="containers.length !== 1">
    <button mat-icon-button color="primary"
            matTooltipClass="tooltip"
            matTooltip="Unlock to edit properties"
            disabled>
      <mat-icon>lock</mat-icon>
    </button>
    <button mat-icon-button color="primary"
            matTooltipClass="tooltip"
            matTooltip="Filter containers like these"
            disabled>
      <mat-icon>bolt</mat-icon>
    </button>
    <button mat-icon-button color="primary"
            matTooltipClass="tooltip"
            matTooltip="Clear filters"
            *ngIf="filterService.filter.numOfFilters() > 0"
            (click)="filterService.clearFilters()">
      <mat-icon>filter_alt_off</mat-icon>
    </button>
  </div>
  <app-container-details [container]="selectedContainer" [containerEmitter]="containerEmitter" [url]="url" #cntrDetail></app-container-details>
</div>
<div *ngIf="containers.length > 1">
<!--      <button mat-stroked-button [ngClass]="isSelected(container.stowage)? 'selectedButton': 'tabButton'"-->
<!--              *ngFor="let container of containers" (click)="clickStowageButton(container)">-->
<!--        {{formatStowage(container.stowage)}}-->
<!--      </button>-->
    <br>
  <p style="margin-left: 10px">{{containers.length - 1}} more entries selected</p>
</div>
