import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment, properties} from '../../environments/environment';
import {Preference} from './model/preference';
import {PreferenceCode, PreferenceValue} from '../panels/preferences/preferences.component';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {

  private preferenceMap: Map<string, Preference>;
  private preferences: Preference[];

  constructor(private http: HttpClient) { }

  // async getPreference(code: string): Promise<Preference> {
  //
  //   console.info(this.preferenceMap);
  //   if (!this.preferenceMap) {
  //     await this.http.get<Preference[]>(environment.serverUrl + '/preference').toPromise().then(data => {
  //
  //       this.preferences = data;
  //       this.preferenceMap = new Map<string, Preference>();
  //       data.forEach(item => {
  //        this.preferenceMap.set(item.code, item);
  //       });
  //     });
  //   }
  //   return this.preferenceMap.get(code);
  // }
  // async getPreferences2(groupCode: string): Promise<Preference[]> {
  //
  //   if (!this.preferences) {
  //     await this.http.get<Preference[]>(environment.serverUrl + '/preference').toPromise().then(data => {
  //
  //       this.preferences = data;
  //       this.preferenceMap = new Map<string, Preference>();
  //       data.forEach(pre => {
  //        this.preferenceMap.set(pre.code, pre);
  //       });
  //     });
  //   }
  //
  //   const item = new Array<Preference>();
  //   this.preferences.forEach(data => {
  //     if (data.groupCode === groupCode) {
  //       item.push(data);
  //     }
  //   });
  //
  //   return item;
  // }
  getPreferenceTemp(code: string) {

    let value = localStorage.getItem(code);

    if (!value) {

      if (code === PreferenceCode.VesselDesign) {
        value = PreferenceValue.VesselDesign_Certified;
        localStorage.setItem(PreferenceCode.VesselDesign, value);
      }
    }
    return value;
  }
  getPreference(code: string): Preference {

    if (!this.preferenceMap) {
      this.preferenceMap = new Map<string, Preference>();
    }

    return this.preferenceMap.get(code);
  }
  getPreferences(groupCode: string): Preference[] {

    const item = new Array<Preference>();

    if (this.preferences) {
      this.preferences.forEach(data => {
        if (data.groupCode === groupCode) {
          item.push(data);
        }
      });
    } else {
      this.retrievePreferences();
    }

    return item;
  }
  retrievePreferences() {
    this.http.get<Preference[]>(environment.serverUrl + '/preference').subscribe(data => {
      this.preferences = data;
      this.preferenceMap = new Map<string, Preference>();
      data.forEach(pre => {
        this.preferenceMap.set(pre.code, pre);
      });
    });
  }

  updatePreference(preference: Preference) {
    return this.http.post(properties.serverUrl + '/preference', preference);
  }
}
