import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ContainerColumn} from '../../../panels/columns/service/model/container-column';

@Component({
  selector: 'app-pivot-column-dialog',
  templateUrl: './row-column-dialog.component.html',
  styleUrls: ['./row-column-dialog.component.css']
})
export class RowColumnDialogComponent implements OnInit {

  columns: string[] = ['property', 'pivot-row', 'pivot-column'];
  dataSource = new MatTableDataSource<PivotRowColumn>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private readonly pivotRowColumns: Array<PivotRowColumn>;
  private readonly recapType: string;
  selectedProperty: PivotRowColumn;
  constructor(private dialogRef: MatDialogRef<RowColumnDialogComponent>
            , @Inject(MAT_DIALOG_DATA) @Inject(MAT_DIALOG_DATA) data: any) {

    this.pivotRowColumns = data.rowColumns;
    this.recapType = data.recapType;
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<PivotRowColumn>(this.pivotRowColumns);
    this.dataSource.paginator = this.paginator;
  }
  apply() {

    const rows = new Array<PivotRowColumn>();
    const columns = new Array<PivotRowColumn>();

    if (this.recapType === '015TABLE') {

      this.pivotRowColumns.forEach(data => {
        if (data.rowSelected) {
          rows.push(data);
        }
        if (data.columnSelected) {
          columns.push(data);
        }
      });
      this.dialogRef.close({rows, columns});
    } else {

      this.pivotRowColumns.forEach(data => {
        if (data.code === this.selectedProperty.code) {
         rows.push(data);
        }
      });
      this.dialogRef.close({rows, columns, title: this.selectedProperty.name});
    }

  }
  cancel() {
    this.dialogRef.close({data: new Array()});
  }

  drop(event: CdkDragDrop<MatTableDataSource<PivotRowColumn>, any>) {
    moveItemInArray(this.pivotRowColumns, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource<PivotRowColumn>(this.pivotRowColumns);
  }

  selectRow(element, $event: MouseEvent) {

  }
  isForChart(): boolean {
    return this.recapType === '015CHART';
  }
  isForTable(): boolean {
    return this.recapType !== '015CHART';
  }

  print(rowSelected: any) {
    // console.info(rowSelected);
    // console.info(this.selectedProperty);
  }

  selectProperty() {
  }
}
export class PivotRowColumn {
  constructor(public code, public name, public rowSelected: boolean, public columnSelected: boolean, public index: number) {
  }
}
