export enum ColumnIds {
  CONTAINER_NO = 'CONTAINER_NO',
  HATCH = 'HATCH',
  STOWAGE = 'STOWAGE',
  ISO_SIZE_TYPE = 'ISO_SIZE_TYPE',
  CONTAINER_LENGTH = 'CONTAINER_LENGTH',
  CONTAINER_HEIGHT = 'CONTAINER_HEIGHT',
  CONTAINER_TYPE = 'CONTAINER_TYPE',
  FULL_EMPTY = 'FULL_EMPTY',
  FULL = 'FULL',
  EMPTY = 'EMPTY',
  POL = 'POL',
  POD = 'POD',
  TS_PORT = 'TS_PORT',
  OPT_POD1 = 'OPT_POD1',
  OPT_POD2 = 'OPT_POD2',
  OPT_POD3 = 'OPT_POD3',
  OPT_POD4 = 'OPT_POD4',
  OPT_POD5 = 'OPT_POD5',
  OPT_POD = 'OPT_POD',
  NPOD = 'NPOD',
  FPOD = 'FPOD',
  POR = 'POR',
  PLACE_OF_DELIVERY = 'PLACE_OF_DELIVERY',
  DESCRIPTION_OF_GOODS = 'DESCRIPTION_OF_GOODS',
  HANDLING_INSTRUCTION = 'HANDLING_INSTRUCTION',
  LOADING_REMARK = 'LOADING_REMARK',
  VGM_CERTI_CODE = 'VGM_CERTI_CODE',
  VGM_INFO = 'VGM_INFO',
  VGM_INFO1 = 'VGM_INFO1',
  VGM_INFO2 = 'VGM_INFO2',
  VGM_INFO3 = 'VGM_INFO3',
  VGM_INFO4 = 'VGM_INFO4',
  VGM_INFO5 = 'VGM_INFO5',
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  WEIGHT = 'WEIGHT',
  GROSS_WEIGHT = 'GROSS_WEIGHT',
  GROSS_WEIGHT_UNIT = 'GROSS_WEIGHT_UNIT',
  VGM_WEIGHT = 'VGM_WEIGHT',
  VGM_WEIGHT_UNIT = 'VGM_WEIGHT_UNIT',
  OVER_DIMENSIONS = 'OVER_DIMENSIONS',
  OVER_DIMENSION_FRONT = 'OVER_DIMENSION_FRONT',
  OVER_DIMENSION_FRONT_UNIT = 'OVER_DIMENSION_FRONT_UNIT',
  OVER_DIMENSION_BACK = 'OVER_DIMENSION_BACK',
  OVER_DIMENSION_BACK_UNIT = 'OVER_DIMENSION_BACK_UNIT',
  OVER_DIMENSION_RIGHT = 'OVER_DIMENSION_RIGHT',
  OVER_DIMENSION_RIGHT_UNIT = 'OVER_DIMENSION_RIGHT_UNIT',
  OVER_DIMENSION_LEFT = 'OVER_DIMENSION_LEFT',
  OVER_DIMENSION_LEFT_UNIT = 'OVER_DIMENSION_LEFT_UNIT',
  OVER_DIMENSION_HEIGHT = 'OVER_DIMENSION_HEIGHT',
  OVER_DIMENSION_HEIGHT_UNIT = 'OVER_DIMENSION_HEIGHT_UNIT',

  TEMPERATURE = 'TEMPERATURE',
  TEMPERATURE_UNIT = 'TEMPERATURE_UNIT',
  TEMPERATURE_RANGE = 'TEMPERATURE_RANGE',
  TEMPERATURE_RANGE_MIM = 'TEMPERATURE_RANGE_MIN',
  TEMPERATURE_RANGE_MAX = 'TEMPERATURE_RANGE_MAX',
  TEMPERATURE_RANGE_UNIT = 'TEMPERATURE_RANGE_UNIT',
  BL_NO = 'BL_NO',
  BOOKING_NO = 'BOOKING_NO',
  GROUP_CODE = 'GROUP_CODE',
  BUNDLED_CONTAINER = 'BUNDLED_CONTAINER',
  BUNDLED_CONTAINER2 = 'BUNDLED_CONTAINER2',
  BUNDLED_CONTAINER3 = 'BUNDLED_CONTAINER3',
  BUNDLED_CONTAINER4 = 'BUNDLED_CONTAINER4',
  BUNDLED_CONTAINER5 = 'BUNDLED_CONTAINER5',

  HAZARDOUS = 'HAZARDOUS',
  REEFER = 'REEFER',
  IMDG = 'IMDG',
  UNNO = 'UNNO',
  CARRIER = 'CARRIER',
  CARGO_NATURE = 'CARGO_NATURE',
  SPECIAL_CARGO_INSTRUCTION = 'SPECIAL_CARGO_INSTRUCTION',
  MUTUALLY_DEFINED_REMARK = 'MUTUALLY_DEFINED_REMARK',

  BREAK_BULK = 'BREAK_BULK',
  BREAK_BULK_LENGTH = 'BREAK_BULK_LENGTH',
  BREAK_BULK_HEIGHT = 'BREAK_BULK_HEIGHT',
  BREAK_BULK_WIDTH = 'BREAK_BULK_WIDTH',
  BREAK_BULK_UNIT = 'BREAK_BULK_UNIT',

  OVER_SLOTS = 'OVER_SLOTS',

  PACKAGE_QUANTITY = 'PACKAGE_QUANTITY',
  PACKAGE_TYPE = 'PACKAGE_TYPE',

  POL_TERMINAL = 'POL_TERMINAL',
  POD_TERMINAL = 'POD_TERMINAL',
  TS_TERMINAL = 'TS_TERMINAL',
  OPT_POD1_TERMINAL = 'OPT_POD1_TERMINAL',
  OPT_POD2_TERMINAL = 'OPT_POD2_TERMINAL',
  OPT_POD3_TERMINAL = 'OPT_POD3_TERMINAL',
  OPT_POD4_TERMINAL = 'OPT_POD4_TERMINAL',
  OPT_POD5_TERMINAL = 'OPT_POD5_TERMINAL',
  OPT_POD_TERMINAL = 'OPT_POD_TERMINAL',
  NPOD_TERMINAL = 'NPOD_TERMINAL',
  FPOD_TERMINAL = 'FPOD_TERMINAL',
  POR_TERMINAL = 'POR_TERMINAL',
  PLACE_OF_DELIVERY_TERMINAL = 'PLACE_OF_DELIVERY_TERMINAL',

  EQUIPMENT_TYPE = 'EQUIPMENT_TYPE',

  OVER_SLOTS_YN = 'OVER_SLOTS_YN',
  OVER_DIMENSIONS_YN = 'OVER_DIMENSIONS_YN',
  BREAK_BULK_YN = 'BREAK_BULK_YN',
  CARGO_TYPE = 'CARGO_TYPE',
  CARGO_TYPE_CODE = 'CARGO_TYPE_CODE',
  BUNDLED_CONTAINER_YN = 'BUNDLED_CONTAINER_YN',
  VGM_INFO_YN = 'VGM_INFO_YN',
  VGM_WEIGHT_YN = 'VGM_WEIGHT_YN',
  GENERAL_INFORMATION_YN = 'GENERAL_INFORMATION_YN',
  LOADING_REMARK_YN = 'LOADING_REMARK_YN',
  CARGO_NATURE_YN = 'CARGO_NATURE_YN',
  DESCRIPTION_OF_GOODS_YN = 'DESCRIPTION_OF_GOODS_YN',
  SPECIAL_CARGO_INSTRUCTION_YN = 'SPECIAL_CARGO_INSTRUCTION_YN',
  MUTUALLY_DEFINED_REMARK_YN = 'MUTUALLY_DEFINED_REMARK_YN',
  PACKAGE_QUANTITY_YN = 'PACKAGE_QUANTITY_YN',
  PACKAGE_TYPE_YN = 'PACKAGE_TYPE_YN',
  GROUP_CODE_YN = 'GROUP_CODE_YN',
  BL_NO_YN = 'BL_NO_YN',
  BOOKING_NO_YN = 'BOOKING_NO_YN',
  TEMPERATURE_RANGE_YN = 'TEMPERATURE_RANGE_YN',
}


