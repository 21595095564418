export class StringUtils {

  static readonly numberReg = '^[-]?[0-9]*[.]{0,1}[0-9]*$';
  static readonly positiveNumberReg = '^[0-9]*[.]{0,1}[0-9]*$';
  static readonly naturalNumberReg = '^[0-9]*';

  static formatNumber(num: any) {

    if (!num) {
      return '';
    }
    // return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

    const numValue = Number(num);

    if (isNaN(numValue)) {
      return num;
    } else {
      return numValue.toLocaleString('en-US');
    }
  }
  static isEmpty(value: any) {
    if (value === '') {
      return true;
    }
    if (value === undefined) {
      return true;
    }
    return false;
  }
  static toNumber(numStr: string): number {
    let num = 0;

    try {
      num = Number(numStr);
    } catch (e) {

    }
    return num;
  }
}
