import {SyntaxMessage} from './model/syntax-message';
import {SyntaxMessageError} from './model/syntax-message-error';
import {MarkersProperty} from '../../panels/markers/model/markers.property';

export class EdiValidateService {
  constructor() {
  }

  validation(item, value: string): Array<SyntaxMessageError> {
    const errorArray: Array<SyntaxMessageError> = [];
    if (item === undefined || value === undefined || value === '') {
      return errorArray;
    }
    // if (item.mandatory !== undefined && item.$.mandatory === 'M' && value !== undefined) {
    //   const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_SYNTAX);
    //   error.syntaxName = MarkersProperty.ITEM_SYNTAX_MANDATORY;
    //   error.description = item.name + ' : ' + value;
    //   errorArray.push(error);
    // }
    const numericRepr = Number(value);
    if (item.$.type !== undefined && item.$.type === 'n') {
      if (isNaN(numericRepr)) {
        const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_SYNTAX);
        error.syntaxName = MarkersProperty.ITEM_SYNTAX_NUMBER;
        error.description = item.$.name + ' : ' + value;
        errorArray.push(error);
      }
    }
    if (item.$.length !== undefined && item.$.length < value.length) {
      if ((!isNaN(numericRepr) && String(numericRepr).replace('-', '').replace('.', '').length > item.$.length)
          || (isNaN(numericRepr) && value.length > item.$.length)) {
        const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_SYNTAX);
        error.syntaxName = MarkersProperty.ITEM_SYNTAX_LENGTH;
        error.description = '(' + item.$.length + ') ' + item.$.name + ' : ' + value;
        errorArray.push(error);
      }
    }
    if (item.$.constant !== undefined && item.$.constant !== value) {
      const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_SYNTAX);
      error.syntaxName = MarkersProperty.ITEM_SYNTAX_CONSTANTS;
      error.description = item.$.name + ' (' + item.$.constant + ') : ' + value;
      errorArray.push(error);
    }
    return errorArray;
  }
}
