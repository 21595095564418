import {Measurement} from './model/measurement';
import {Reference} from './model/reference';
import {Dimension} from './model/dimension';
import {EdiDocument} from './model/edi-document';
import {Freetext} from './model/freetext';
import {Stowage} from './model/stowage';
import {Place} from './model/place';
import {Datetime} from './model/datetime';
import {ContainerEquipment} from './model/container-equipment';
import {Equipment} from './model/equipment';
import {Dangerous} from './model/dangerous';
import {CargoNature} from './model/cargo-nature';
import {DangerousRemark} from './model/dangerous-remark';

export class EdiAdapterService {

  constructor() {
  }

  putInterchangeHeader(item, value: string, ediDocument: EdiDocument) {
    if (item.$.id === 's002.e0004') {
      ediDocument.senderId = value;
    } else if (item.$.id === 's003.e0010') {
      ediDocument.recipientId = value;
    } else if (item.$.id === 'e0017') {
      ediDocument.preparationDate = value;
    } else if (item.$.id === 'e0019') {
      ediDocument.preparationTime = value;
    } else if (item.$.id === 'e0020') {
      ediDocument.interchangeControlRefStart = value;
    } else if (item.$.id === 'e0032') {
      ediDocument.communicationsAgreementId = value;
    }
  }

  putMessageHeader(item, value: string, ediDocument: EdiDocument) {
    if (item.$.id === 'e0062') {
      ediDocument.messageNo = value;
    } else if (item.$.id === 'e0065') {
      ediDocument.messageType = value;
    } else if (item.$.id === 'e0052') {
      ediDocument.versionNo = value;
    } else if (item.$.id === 'e0054') {
      ediDocument.releaseNo = value;
    } else if (item.$.id === 'e0057') {
      ediDocument.associationCode = value;
    }
  }

  putBeginningMessage(item, value: string, ediDocument: EdiDocument) {
    if (item.$.id === 'e1001') {
      ediDocument.documentTypeCode = value;
    } else if (item.$.id === 'e1000') {
      ediDocument.documentName = value;
    } else if (item.$.id === 'e1004') {
      ediDocument.documentNo = value;
    } else if (item.$.id === 'e1056') {
      ediDocument.documentVersion = value;
    } else if (item.$.id === 'e1056') {
      ediDocument.documentRevision = value;
    } else if (item.$.id === 'e1225') {
      ediDocument.messageFunction = value;
    } else if (item.$.id === 'e4343') {
      ediDocument.documentResponseType = value;
    } else if (item.$.id === 'e1373') {
      ediDocument.documentStatus = value;
    }
  }

  putDateTimePeriodGroup0(item, value: string, ediDocument: EdiDocument) {
    if (item.$.id === 'e2380') {
      ediDocument.compilingDateTime = value;
    } else if (item.$.id === 'e2379') {
      ediDocument.compilingDateTimeFormat = value;
    }
  }

  putReferenceGroup0(item, value: string, ediDocument: EdiDocument) {
    if (item.$.id === 'e1153') {
      ediDocument.referenceType = value;
    } else if (item.$.id === 'e1154') {
      ediDocument.referenceId = value;
    } else if (item.$.id === 'e1156') {
      ediDocument.referenceDocument = value;
    } else if (item.$.id === 'e1056') {
      ediDocument.referenceVersion = value;
    } else if (item.$.id === 'e1060') {
      ediDocument.referenceRevision = value;
    }
  }

  putDetailTransport(item, value: string, ediDocument: EdiDocument) {
    if (item.$.id === 'e8028') {
      ediDocument.transport.conveyanceRefNo = value;
    } else if (item.$.id === 'e3127' || item.$.id === 'e3127_15') {
      ediDocument.transport.carrier = value;
    } else if (item.$.id === 'e3055' || item.$.id === 'e3055_15') {
      ediDocument.transport.carrierResponsibleAgency = value;
    } else if (item.$.id === 'e8213' || item.$.id === 'e8213_15') {
      ediDocument.transport.id = value;
    } else if (item.$.id === 'c222.e1131' || item.$.id === 'c040.e1131_15') {
      ediDocument.transport.type = value;
    } else if (item.$.id === 'c222.e3055') {
      ediDocument.transport.responsibleAgency = value;
    } else if (item.$.id === 'e8212' || item.$.id === 'e8212_15') {
      ediDocument.transport.vesselName = value;
    } else if (item.$.id === 'e8453' || item.$.id === 'e8453_15') {
      ediDocument.transport.countryCode = value;
    }
  }

  petHandlingInstruction(item, value: string, ediDocument: EdiDocument) {
    if (item.$.id === 'c524.e4079') {
      ediDocument.handle = value;
    }
  }

  putPlaceIdentificationGroup1(item, value: string, ediDocument: EdiDocument, firstElement: boolean) {
    let ediPlace: Place = new Place();
    if (!firstElement) {
      ediPlace = ediDocument.placeLocations[ediDocument.placeLocations.length - 1];
    }

    if (item.$.id === 'e3227') {
      ediPlace.type = value;
    } else if (item.$.id === 'e3225') {
      ediPlace.id = value;
    } else if (item.$.id === 'c517.e3055') {
      ediPlace.responsibleAgency = value;
    } else if (item.$.id === 'e3223') {
      ediPlace.countryCode = value;
    } else if (item.$.id === 'c519.e3055') {
      ediPlace.countryResponsibleAgency = value;
    } else if (item.$.id === 'e3223') {
      ediPlace.stateId = value;
    }

    if (firstElement) {
      ediDocument.placeLocations.push(ediPlace);
    }
  }

  putDateTimePeriodGroup1(item, value: string, ediDocument: EdiDocument, firstElement: boolean) {
    let ediDatetime: Datetime = new Datetime();
    if (!firstElement) {
      ediDatetime = ediDocument.placeDates[ediDocument.placeDates.length - 1];
    }

    if (item.$.id === 'e2005') {
      ediDatetime.dateTimeCode = value;
    } else if (item.$.id === 'e2380') {
      ediDatetime.dateTime = value;
    } else if (item.$.id === 'e2379') {
      ediDatetime.dateTimeFormat = value;
    }

    if (firstElement) {
      ediDocument.placeDates.push(ediDatetime);
    }
  }

  putReferenceGroup1(item, value: string, ediDocument: EdiDocument) {
    if (item.$.id === 'e1154') {
      ediDocument.loadingVoyageNo = value;
    }
  }

  private getEdiStowage(ediDocument: EdiDocument, repeatGroup: boolean): Stowage {
    let stowage: Stowage = new Stowage();
    if (!repeatGroup && this.getStowage(ediDocument).length > 0) {
      stowage = this.getStowage(ediDocument)[this.getStowage(ediDocument).length - 1];
    }
    if (repeatGroup || this.getStowage(ediDocument).length === 0) {
      this.getStowage(ediDocument).push(stowage);
    }
    return stowage;
  }
  private getStowage(ediDocument: EdiDocument): Array<Stowage> {
    if (ediDocument.handle === 'DIS') {
      return ediDocument.dischargeStowages;
    } else if (ediDocument.handle === 'SHI') {
      return ediDocument.shiftStowages;
    } else if (ediDocument.handle === 'RES') {
      return ediDocument.restowStowages;
    } else if (ediDocument.handle === 'COD') {
      return ediDocument.codStowages;
    } else if (ediDocument.handle === 'EXC') {
      return ediDocument.excessStowages;
    } else if (ediDocument.handle === 'BAL') {
      return ediDocument.balanceStowages;
    } else if (ediDocument.handle === 'VOI') {
      return ediDocument.avoidStowages;
    }
    return ediDocument.stowages;
  }

  putPlaceIdentificationGroup2(item, value: string, ediDocument: EdiDocument, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    if (item.$.id === 'e3225') {
      stowage.location.id = value;
    } else if (item.$.id === 'e3055') {
      stowage.location.responsibleAgencyFormat = value;
    }
  }

  putGoodItemDetail(item, value: string, ediDocument: EdiDocument, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    if (item.$.id === 'e7224') {
      stowage.cargo.packageNo = value;
    } else if (item.$.id === 'e7065') {
      stowage.cargo.packageId = value;
    }
  }

  putNatureCargo(item, value: string, ediDocument: EdiDocument, firstElement: boolean, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    let natureCargo = new CargoNature();
    if (!firstElement) {
      natureCargo = stowage.cargoNatures[stowage.weights.length - 1];
    }
    if (item.$.id === 'e7085' || item.$.id === 'e7022') {
      natureCargo.packageQuantity = value;
    } else if (item.$.id === 'e7023') {
      natureCargo.packageType = value;
    } else if (item.$.id === 'c703.e7085' || item.$.id === 'e1131') {
      natureCargo.code = value;
    } else if (item.$.id === 'c703.e7703') {
      natureCargo.agencyCode = value;
    }
    if (firstElement) {
      stowage.cargoNatures.push(natureCargo);
    }
  }

  putFreeText0(item, value: string, ediDocument: EdiDocument, firstElement: boolean, repeatGroup: boolean) {
    let freetext: Freetext = new Freetext();
    if (!firstElement) {
      freetext = ediDocument.remarks[ediDocument.placeDates.length - 1];
    }

    if (item.$.id === 'e4451') {
      freetext.type = value;
    } else if (item.$.id === 'c108.4440') {
      freetext.remark = value;
    }

    if (firstElement) {
      ediDocument.remarks.push(freetext);
    }

    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    let freeText: Freetext = new Freetext();
    if (!firstElement) {
      freeText = stowage.remarks[stowage.remarks.length - 1];
    }

    if (item.$.id === 'e4451') {
      freeText.type = value;
    } else if (freeText.type === 'AAY' && item.$.id === 'c107.4441') {
      freeText.vgmCertificationCode = value;
    } else if (item.$.id === 'e4440.1' || item.$.id === 'c108.4440') {
      freeText.remark = value;
    } else if (freeText.type === 'AAY' && item.$.id === 'e4440.2') {
      freeText.vgmInfo2 = value;
    } else if (freeText.type === 'AAY' && item.$.id === 'e4440.3') {
      freeText.vgmInfo3 = value;
    } else if (freeText.type === 'AAY' && item.$.id === 'e4440.4') {
      freeText.vgmInfo4 = value;
    } else if (freeText.type === 'AAY' && item.$.id === 'e4440.5') {
      freeText.vgmInfo5 = value;
    }

    if (firstElement) {
      stowage.remarks.push(freeText);
    }
  }

  putFreeText(item, value: string, ediDocument: EdiDocument, firstElement: boolean, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    let freeText: Freetext = new Freetext();
    if (!firstElement) {
      freeText = stowage.remarks[stowage.remarks.length - 1];
    }

    if (item.$.id === 'e4451') {
      freeText.type = value;
    } else if (item.$.id === 'c107.4441') {
      freeText.vgmCertificationCode = value;
    } else if (item.$.id === 'e4440.1' || item.$.id === 'c108.4440') {
      freeText.remark = value;
    } else if (item.$.id === 'e4440.2') {
      freeText.vgmInfo2 = value;
    } else if (item.$.id === 'e4440.3') {
      freeText.vgmInfo3 = value;
    } else if (item.$.id === 'e4440.4') {
      freeText.vgmInfo4 = value;
    } else if (item.$.id === 'e4440.5') {
      freeText.vgmInfo5 = value;
    }

    if (firstElement) {
      stowage.remarks.push(freeText);
    }
  }

  putWeight(item, value: string, ediDocument: EdiDocument, firstElement: boolean, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    let measurement: Measurement = new Measurement();
    if (!firstElement) {
      measurement = stowage.weights[stowage.weights.length - 1];
    }

    if (item.$.id === 'e6311') {
      measurement.type = value;
    } else if (item.$.id === 'e6411') {
      measurement.unit = value;
    } else if (item.$.id === 'e6314') {
      measurement.value = value;
    }

    if (firstElement) {
      stowage.weights.push(measurement);
    }
  }

  putDimension(item, value: string, ediDocument: EdiDocument, firstElement: boolean, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    let dimension: Dimension = new Dimension();
    if (!firstElement) {
      dimension = stowage.dimensions[stowage.dimensions.length - 1];
    }

    if (item.$.id === 'e6145') {
      dimension.code = value;
    } else if (item.$.id === 'e6411') {
      dimension.unit = value;
    } else if (item.$.id === 'e6168') {
      dimension.length = value;
    } else if (item.$.id === 'e6140') {
      dimension.width = value;
    } else if (item.$.id === 'e6008') {
      dimension.height = value;
    }

    if (firstElement) {
      stowage.dimensions.push(dimension);
    }
  }

  putTemperature(item, value: string, ediDocument: EdiDocument, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    if (item.$.id === 'e6245') {
      stowage.temperature.type = value;
    } else if (item.$.id === 'e6246') {
      stowage.temperature.value = value;
    } else if (item.$.id === 'e6411') {
      stowage.temperature.unit = value;
    }
  }

  putRangeDetail(item, value: string, ediDocument: EdiDocument, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    if (item.$.id === 'e6167') {
      stowage.rangeDetail.type = value;
    } else if (item.$.id === 'e6411') {
      stowage.rangeDetail.unit = value;
    } else if (item.$.id === 'e6162') {
      stowage.rangeDetail.rangeMin = value;
    } else if (item.$.id === 'e6152') {
      stowage.rangeDetail.rangeMax = value;
    }
  }

  putPlaceLocation(item, value: string, ediDocument: EdiDocument, firstElement: boolean, repeatGroup: boolean, ports: Map<string, Place>) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    let place: Place = new Place();
    if (!firstElement) {
      place = stowage.places[stowage.places.length - 1];
    }

    if (item.$.id === 'e3227') {
      place.type = value;
    } else if (item.$.id === 'e3225') {
      place.id = value;
      ports.set(place.id, place);
    } else if (item.$.id === 'c517.e1131') {
      place.qualifierCode = value;
    } else if (item.$.id === 'c517.e3055') {
      place.responsibleAgency = value;
    } else if (item.$.id === 'e3223') {
      place.terminalCode = value;
    } else if (item.$.id === 'c519.e1131') {
      place.terminalQualifierCode = value;
    } else if (item.$.id === 'c519.e3055') {
      place.terminalResponsibleAgency = value;
    }

    if (firstElement) {
      stowage.places.push(place);
    }
  }

  putReferenceGroup2(item, value: string, ediDocument: EdiDocument, firstElement: boolean, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    let reference: Reference = new Reference();
    if (!firstElement) {
      reference = stowage.references[stowage.references.length - 1];
    }

    if (item.$.id === 'e1153') {
      reference.type = value;
    } else if (item.$.id === 'e1154') {
      reference.referenceNo = value;
    }

    if (firstElement) {
      stowage.references.push(reference);
    }
  }

  putEquipment(item, value: string, ediDocument: EdiDocument, firstElement: boolean, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    let container: ContainerEquipment = new ContainerEquipment();
    if (!firstElement) {
      container = stowage.containers[stowage.containers.length - 1];
    }

    if (item.$.id === 'e8053') {
      container.equipment.type = value;
    } else if (item.$.id === 'c237.e8260') {
      container.equipment.id = value;
    } else if (item.$.id === 'c224.e8155' || item.$.id === 'e8155') {
      container.equipment.sizeType = value;
    } else if (item.$.id === 'e8249') {
      container.equipment.statusCode = value;
    } else if (item.$.id === 'e8169') {
      container.equipment.fullEmpty = value;
    } else if (item.$.id === 'e8154') {
      container.equipment.sizeTypeNonIso = value;
    }

    if (firstElement) {
      stowage.containers.push(container);
    }
  }

  putEquipmentAttached(item, value: string, ediDocument: EdiDocument, firstElement: boolean, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    if (stowage.containers.length > 0) {
      const installation = stowage.containers[stowage.containers.length - 1];
      let equipmentAttach: Equipment = new Equipment();
      if (!firstElement) {
        equipmentAttach = installation.attachEquipments[installation.attachEquipments.length - 1];
      }

      if (item.$.id === 'e8053') {
        equipmentAttach.type = value;
      } else if (item.$.id === 'c237.e8260') {
        equipmentAttach.id = value;
      }

      if (firstElement) {
        installation.attachEquipments.push(equipmentAttach);
      }
    }
  }

  putNameAddress(item, value: string, ediDocument: EdiDocument, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    if (stowage.containers.length > 0) {
      const container = stowage.containers[stowage.containers.length - 1];
      if (item.$.id === 'e3039') {
        container.carrier = value;
      } else if (item.$.id === 'e3035') {
        container.carrierParty = value;
      } else if (item.$.id === 'e3055') {
        container.carrierAgency = value;
      }
    }
  }

  putDangerousGood(item, value: string, ediDocument: EdiDocument, firstElement: boolean, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    let dangerous: Dangerous = new Dangerous();
    if (!firstElement) {
      dangerous = stowage.dangerousGoods[stowage.dangerousGoods.length - 1];
    }
    if (item.$.id === 'e8351') {
      dangerous.imdg = value;
    } else if (item.$.id === 'e8078') {
      dangerous.hazardPageNo = value;
    } else if (item.$.id === 'c234.e7124') {
      dangerous.unno = value;
    } else if (item.$.id === 'e7106') {
      dangerous.shipmentFlashPoint = value;
    } else if (item.$.id === 'e6411') {
      dangerous.measureUnitCode = value;
    } else if (item.$.id === 'e8339') {
      dangerous.packingGroup = value;
    } else if (item.$.id === 'e8364') {
      dangerous.emsNo = value;
    } else if (item.$.id === 'e8410') {
      dangerous.mfag = value;
    } else if (item.$.id === 'e8158') {
      dangerous.hazardIdUpper = value;
    } else if (item.$.id === 'e8186') {
      dangerous.hazardIdLower = value;
    } else if (item.$.id === 'e8246') {
      dangerous.goodsLabelMark1 = value;
    } else if (item.$.id === 'e8246.1') {
      dangerous.goodsLabelMark2 = value;
    } else if (item.$.id === 'e8246.2') {
      dangerous.goodsLabelMark3 = value;
    }

    if (firstElement) {
      stowage.dangerousGoods.push(dangerous);
    }
  }

  putDangerousFreeText(item, value: string, ediDocument: EdiDocument, repeatGroup: boolean) {
    const stowage: Stowage = this.getEdiStowage(ediDocument, repeatGroup);
    if (stowage.dangerousGoods.length > 0) {
      const dangerous: Dangerous = stowage.dangerousGoods[stowage.dangerousGoods.length - 1];
      if (item.$.id === 'e4451') {
        const remark = new DangerousRemark();
        remark.freeTextCode = value;
        dangerous.remarks.push(remark);
      } else if (dangerous.remarks.length > 0 && (item.$.id === 'e4440' || item.$.id === 'e4440.1')) {
        dangerous.remarks[dangerous.remarks.length - 1].freeText1 = value;
      } else if (dangerous.remarks.length > 0 && item.$.id === 'e4440.2') {
        dangerous.remarks[dangerous.remarks.length - 1].freeText2 = value;
      } else if (dangerous.remarks.length > 0 && item.$.id === 'e4440.3') {
        dangerous.remarks[dangerous.remarks.length - 1].freeText3 = value;
      } else if (dangerous.remarks.length > 0 && item.$.id === 'c107.4441') {
        dangerous.remarks[dangerous.remarks.length - 1].freeTextValueCode = value;
      }
    }
  }

  putMessageTrailer(item, value: string, ediDocument: EdiDocument) {
    if (item.$.id === 'e0074') {
      ediDocument.messageTrailer.segmentNo = value;
    } else if (item.$.id === 'e0062') {
      ediDocument.messageTrailer.referenceNo = value;
    }
  }

  putInterchangeTrailer(item, value: string, ediDocument: EdiDocument) {
    if (item.$.id === 'e0036') {
      ediDocument.interchangeControlCount = value;
    } else if (item.$.id === 'e0020') {
      ediDocument.interchangeControlRefEnd = value;
    }
  }
}
