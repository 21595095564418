import * as PIXI from 'pixi.js';
import {VisualPart} from '../../../core/pixi/visual-part';
import {Cell} from '../../../hull/model/cell';
import {Container} from '../../../service/model/container';
import {ContainerVisualPart} from './container.visual-part';
import {OccupiedMarkVisualPart} from './occupied-mark.visual-part';
import {BayVisualPart} from './bay.visual-part';

export const OogSlotTypes = {

  VERTICAL_LINE: 2,
  HORIZONTAL_LINE: 4,
  TOP_LEFT: 8,
  TOP_RIGHT: 16,
  BOTTOM_LEFT: 32,
  BOTTOM_RIGHT: 64,

};

export class CellVisualPart extends VisualPart {

  hasPairCell = true;
  private lineColor = 0x000000;
  oogSlotType = 0;

  constructor(parentGraphics: PIXI.Container, public cell: Cell, public rowIndex: number, public tierIndex: number) {
    super(parentGraphics);
  }
  setBounds(x: number, y: number, width: number, height: number) {
    super.setBounds(x, y, width, height);

    this.children.forEach( cntr => {
      cntr.setBounds(x, y, width, height);
    });
    this.childrenMap.forEach( cntr => {
      cntr.setBounds(x, y, width, height);
    });
  }
  pushContainer(container: Container, foreColor?: number, backColor?: number): ContainerVisualPart {
    const child = new ContainerVisualPart(this.graphics, container, foreColor, backColor);
    super.pushChild(child, this.bounds);
    child.draw();
    return child;
  }
  removeContainer(containerNo: string) {

    if (this.children.length > 0) {
      const index = this.children.findIndex(child => (child as ContainerVisualPart).container.containerNo === containerNo);

      if (index >= 0) {
        const containerPart = this.children[index] as ContainerVisualPart;
        const childGraphics = containerPart.graphics;
        this.graphics.removeChild(childGraphics);
        this.children.splice(index, 1);
      }
    }
  }
  draw() {
    super.draw();

    // if (!this.hasOccupyCell) {
    //   this.lineColor = 0x0000AA;
    // }
    this.getGraphics().lineStyle(1, this.lineColor);
    this.getGraphics().drawRect(this.bounds.x, this.bounds.y, this.bounds.width, this.bounds.height);
    this.getGraphics().endFill();
  }
  setOccupied() {
    const occupiedPart = new OccupiedMarkVisualPart(this.graphics);
    super.addChild('OCCUPIED', occupiedPart);
  }
  drawOccupied() {
    const occupiedPart = new OccupiedMarkVisualPart(this.graphics);
    super.addChild('OCCUPIED', occupiedPart, this.bounds);
    // this.draw();
    occupiedPart.draw();
  }
  clearOccupied() {
    this.deleteChild('OCCUPIED');
  }
  isOccupied(): boolean {
    return this.getChild('OCCUPIED') != null;
  }
  getPairCellVisualParts(): CellVisualPart[] {
    return (this.getParent() as BayVisualPart).getPairCellVisualParts(this.cell.stowage);
  }
  getStowage(length: number): string {
    return (this.getParent() as BayVisualPart).getStowage(this.cell.stowage, length);
  }
}
