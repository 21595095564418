import {Container} from './container';
import {EdiHeader} from '../../my-plans/service/model/edi-header';
import {SyntaxMessage} from '../../my-plans/service/model/syntax-message';

export class BayPlan {

  header: EdiHeader;
  containers: Array<Container>;

  shiftContainers = new Array<Container>();
  restowContainers = new Array<Container>();
  codContainers = new Array<Container>();

  dischargeContainers = new Array<Container>();
  excessContainers = new Array<Container>();
  balanceContainers = new Array<Container>();
  avoidContainers = new Array<Container>();

  syntaxViolations = new Array<SyntaxMessage>();


  constructor() {
    this.header = new EdiHeader();
    this.containers = [];
  }
  sortByStowage(): Container[] {
   this.containers.sort((a, b) => a.stowage >= b.stowage ? 1 : -1);
   return this.containers;
  }
  sortById(): Container[] {
    this.containers.sort((a, b) => a.id >= b.id ? 1 : -1);
    return this.containers;
  }

  setContainer(stowage: string, container: Container): boolean {

    return this.searchContainerByStowage(0, this.containers.length, stowage, container);
  }
  private searchContainerByStowage(m, r, stowage: string, container: Container): boolean {

    if ( r >= m) {

      const mid = Math.round(m + (r - m) / 2);

      // console.info('mid: ' + mid + '\t' + this.containerArray[mid].stowage + '\t' + stowage + '\t' + container.stowage);

      if (this.containers[mid].stowage === stowage) {

        this.containers[mid] = container;
        return true;
      }
      if (this.containers[mid].stowage >= stowage) {
        return this.searchContainerByStowage(m, mid - 1, stowage, container);
      }
      return this.searchContainerByStowage(mid + 1, r, stowage, container);
    }
    return false;
  }
}
