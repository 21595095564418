<!--<div [class]="toolsClass">-->
<!--  <button mat-mini-fab color="accent" style="margin-left: 5px">-->
<!--    <mat-icon>save</mat-icon>-->
<!--  </button>-->
<!--</div>-->
<mat-tab-group id="bottom" mat-align-tabs="start" (selectedTabChange)="selectTab($event)" animationDuration="0ms" style="width: 100%;height: 100%"
  selectedIndex="{{selectedTabIndex}}">
  <mat-tab label="{{BottomTabText.MARKERS}}" *ngIf="bottomPanelVisible.markersVisible">
    <app-markers [tabEmitter]="tabEmitter"></app-markers>
  </mat-tab>
  <mat-tab label="{{BottomTabText.HEADER}}" *ngIf="bottomPanelVisible.headerVisible">
    <app-baplie-header [tabEmitter]="tabEmitter"></app-baplie-header>
  </mat-tab>
  <mat-tab label="{{BottomTabText.CONTAINER_SUMMARY}}" *ngIf="bottomPanelVisible.containerSummaryVisible">
    <app-container-summary [tabEmitter]="tabEmitter"></app-container-summary>
  </mat-tab>
  <mat-tab label="{{BottomTabText.CONSOLE}}" *ngIf="bottomPanelVisible.consoleVisible">
    <div style="height: 100%; overflow: hidden">
      <app-console [bottomHeight]=bottomHeight></app-console>
    </div>
  </mat-tab>
  <mat-tab label="{{BottomTabText.EDI_TEXT}}" cdkListbox  *ngIf="bottomPanelVisible.ediTextVisible" style="">
    <div style="height: 100%; overflow: hidden">
      <app-edi-text [bottomHeight]=bottomHeight [tabEmitter]="tabEmitter"></app-edi-text>
    </div>
  </mat-tab>
  <mat-tab label="{{BottomTabText.SHARED_EDI}}" cdkListbox  *ngIf="bottomPanelVisible.sharedEdiVisible" style="">
    <app-shared-edi [bottomHeight]=bottomHeight [tabEmitter]="tabEmitter" [active] = "bottomPanelVisible.sharedEdiVisible"></app-shared-edi>
  </mat-tab>

<!--  <mat-tab label="{{BottomTabText.VESSEL_EDIT}}" *ngIf="vesselEditVisible">-->
<!--    <app-vessel-edit [tabEmitter]="tabEmitter"></app-vessel-edit>-->
<!--  </mat-tab>-->
</mat-tab-group>

