import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HandleError, HttpErrorHandler} from '../../../service/http-error-handler.service';
import {properties} from '../../../../environments/environment';
import {EdiHeader} from '../../service/model/edi-header';
import {EdiText} from '../../service/model/edi-text';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class EdiFileService {

  private readonly handleError: HandleError;

  constructor(private http: HttpClient,
              httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('EdiFileService');
  }

  getEdiText(id: string) {

    return this.http.get<EdiText>(properties.serverUrl + '/edi?id=' + id);
  }

  patchEdi(param = {}) {

    return this.http.patch<string>(properties.serverUrl + '/edi', param);
  }
  deleteEdi(id: string) {
    return this.http.delete<EdiHeader>(properties.serverUrl + '/edi?id=' + id);
  }
  rollbackEdi(id: string) {
    return this.http.get<string>(properties.serverUrl + '/edi/rollback?id=' + id);
  }

}
