export enum ZoomLevel {
  L0 = 0,
  L1 = 1,
  L2 = 2,
  L3 = 3,
}

export const BayPlanDimensions: BayDimensions[] = [{
    zoomLevel: ZoomLevel.L0,
    displayType: 0,
    bayNoHeight: 10,
    rowNoHeight: 0,
    tierWidth: 0,
    cellWidth: 10,
    cellHeight: 9,
    hatchCoverHeight: 2,
    xMargin: 2,
    yMargin: 2,
    cellMargin: 0,
    bayNoFontSize: 12,
    rowNoFontSize: 0,
    tierNoFontSize: 0,
    maxDisplayBays: 1000,
  },
  {
    zoomLevel: ZoomLevel.L1,
    displayType: 1,
    bayNoHeight: 12,
    rowNoHeight: 20,
    tierWidth: 20,
    cellWidth: 17,
    cellHeight: 18,
    hatchCoverHeight: 10,
    xMargin: 5,
    yMargin: 10,
    cellMargin: 0,
    bayNoFontSize: 18,
    rowNoFontSize: 12,
    tierNoFontSize: 12,
    maxDisplayBays: 1000,
  },
  {
    zoomLevel: ZoomLevel.L2,
    displayType: 1,
    bayNoHeight: 20,
    rowNoHeight: 20,
    tierWidth: 28,
    cellWidth: 30,
    cellHeight: 33,
    hatchCoverHeight: 10,
    xMargin: 5,
    yMargin: 10,
    cellMargin: 2,
    bayNoFontSize: 24,
    rowNoFontSize: 16,
    tierNoFontSize: 16,
    maxDisplayBays: 21,
  },
  {
    zoomLevel: ZoomLevel.L3,
    displayType: 1,
    bayNoHeight: 30,
    rowNoHeight: 60,
    tierWidth: 53,
    cellWidth: 53,
    cellHeight: 50,
    hatchCoverHeight: 16,
    xMargin: 5,
    yMargin: 10,
    cellMargin: 2,
    bayNoFontSize: 30,
    rowNoFontSize: 20,
    tierNoFontSize: 20,
    maxDisplayBays: 18,
  }

];

export declare interface BayDimensions {
  zoomLevel: number;
  displayType: number;
  bayNoHeight: number;
  rowNoHeight: number;
  tierWidth: number;
  cellWidth: number;
  cellHeight: number;
  hatchCoverHeight: number;
  xMargin: number;
  yMargin: number;
  cellMargin: number;
  bayNoFontSize: number;
  rowNoFontSize: number;
  tierNoFontSize: number;
  maxDisplayBays: number;
}
