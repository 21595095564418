import { Component, OnInit } from '@angular/core';
import {UserService} from '../service/user.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OkDialogComponent} from '../../dialogs/ok-dialog/ok-dialog.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from 'ng2-validation';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {TranslateService} from '@ngx-translate/core';
import {UserDeletionDialogComponent} from './user-deletion-dialog/user-deletion-dialog.component';
import {AuthService} from '../service/auth.service';
import {Router} from '@angular/router';

const oldPassword = new FormControl('', Validators.required);
const newPassword = new FormControl('', [Validators.required, Validators.min(8)]);
const confirmedPassword = new FormControl('', CustomValidators.equalTo(newPassword));

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  PASSWORD_REQUIRED_FOR_SIGN_IN = this.translate.instant('PASSWORD_REQUIRED_FOR_SIGN_IN');
  PASSWORD2_REQUIRED_FOR_SIGN_IN = this.translate.instant('PASSWORD2_REQUIRED_FOR_SIGN_IN');
  PASSWORDS_DO_NOT_MATCH = this.translate.instant('PASSWORDS_DO_NOT_MATCH');
  PASSWORD_MIN_LENGTH = this.translate.instant('PASSWORD_MIN_LENGTH');

  private selectedTabIndex = 0;

  profileForm: FormGroup;
  passwordForm: FormGroup;

  constructor(private userService: UserService,
              private authService: AuthService,
              private fb: FormBuilder,
              private snackBar: MatSnackBar,
              private router: Router,
              private translate: TranslateService,
              private dialog: MatDialog) {

  }

  ngOnInit(): void {

    this.profileForm = this.fb.group({
      id: new FormControl(),
      username: new FormControl(),
      password: new FormControl(),
      active: new FormControl(),
      verified: new FormControl(),
      activeGroupId: new FormControl(),
      classCode: new FormControl(),
      roleCode: new FormControl(),
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email])
      ],
      fullName: [
        Validators.max(200)
      ],
      companyName: [
        Validators.max(300)
      ]
    });
    this.passwordForm = this.fb.group({
      oldPassword,
      newPassword,
      confirmedPassword
    });
    this.userService.getProfile().subscribe(data => {
      this.profileForm.setValue(data);
    }, error => {
      this.showErrorDialog(error);
    });
  }
  save() {

    if (this.selectedTabIndex === 0) {
      this.saveProfile();
    } else {
      this.changePassword();
    }
  }
  saveProfile() {
    this.userService.updateProfile(this.profileForm.value).subscribe(data => {
      this.profileForm.setValue(data);
      this.openSnackBar('Saved', 'Close');
    }, error => {
      this.showErrorDialog(error);
    });
  }
  changePassword() {

    if (confirmedPassword.errors) {
      return;
    }

    const oldPwd = this.oldPassword.value;
    const newPwd = this.newPassword.value;

    this.userService.changePassword(oldPwd, newPwd).subscribe(data => {

      this.openSnackBar('Saved', 'Close');

    }, error => {
      this.showErrorDialog(error);
    });

  }

  get oldPassword() {
    return this.passwordForm.get('oldPassword');
  }
  get newPassword() {
    return this.passwordForm.get('newPassword');
  }

  get confirmedPassword() {
    return this.passwordForm.get('confirmedPassword');
  }
  set confirmedPassword(value: any) {
    this.passwordForm.get('confirmedPassword').setValue(value);
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  showErrorDialog(error: any) {
    console.error(error);
    let errorMessage = error.message;
    if (typeof error.error === 'string') {
      errorMessage = this.translate.instant(error.error);
    }
    const dialogRef = this.dialog.open(OkDialogComponent, {
      maxWidth: '800px',
      data: {header: error.statusText, message: errorMessage}
    });
  }
  selectTab($event: MatTabChangeEvent) {
    this.selectedTabIndex = $event.index;
  }

  delete() {
    const confirmDialog = this.dialog.open(UserDeletionDialogComponent, {
      maxWidth: '800px',
      data: this.profileForm.controls['email'].value
    });
    confirmDialog.afterClosed().subscribe(arg => {

      if (arg.data) {

        this.userService.deleteAccount().subscribe(() => {
          this.authService.logout();
          this.router.navigateByUrl('/');
        }, error => {
          this.showErrorDialog(error);
        });
      }
    });
  }
}
