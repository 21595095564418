import {Row} from './row';
import {Tier} from './tier';
import {Cell} from './cell';

export class Section {

  deck: boolean;

  rowList = new Array<Row>();
  tierList = new Array<Tier>();
  cellList = new Array<Cell>();

}
