import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment, properties} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import * as moment from 'moment';
import {JwtHelperService} from '@auth0/angular-jwt';
// import * as jwt_decode from 'jwt-decode';
import {HandleError, HttpErrorHandler} from '../../service/http-error-handler.service';
import {Token} from './model/token';
import {TokenClaim} from './model/token-claim';
import {LoginInput} from './model/login.input';
import {SessionIds} from '../../core/service/session-ids';
import {VisualEvent} from '../../core/event/visual-event';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

const EXPIRES_AT = 'EXPIRES_AT';
const STAY_SIGNED_IN = 'STAY_SIGNED_IN';
const ACCESS_TOKEN = 'ACCESS_TOKEN';
const ACCESS_ROLE = 'ACCESS_ROLE';
const USER_CLASS = 'USER_CLASS';
const CURRENT_USER = 'CURRENT_USER';


export const AuthEventId = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  CHANGE_TEAM: 'CHANGE_TEAM',
};

export const UserClass = {
  ADMIN : '001A',
  MEMBER: '001B',
  GUEST: '001C',
};
export const ROLE = {
  MAINTAINER : '002A',
  PLANNER: '002B',
  GUEST: '002C'
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly handleError: HandleError;
  private currentUserSubject: BehaviorSubject<Token>;
  private currentUser: Token;

  private configUrl = 'assets/config.json';

  // readonly onSwitchingTeam = new EventEmitter<any>();
  // readonly onSignInOut = new EventEmitter<any>();
  readonly emitter = new EventEmitter<VisualEvent>();
  private staySignedIn = false;

  constructor(private http: HttpClient) {

    if ('Y' === localStorage.getItem(STAY_SIGNED_IN)) {
      this.currentUserSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem(CURRENT_USER)));
    } else {
      this.currentUserSubject = new BehaviorSubject<Token>(JSON.parse(sessionStorage.getItem(CURRENT_USER)));
    }

    // this.currentUser = this.currentUserSubject.asObservable();

    if ('Y' === localStorage.getItem(STAY_SIGNED_IN)) {

      sessionStorage.setItem(CURRENT_USER, localStorage.getItem(CURRENT_USER));
      sessionStorage.setItem(ACCESS_TOKEN, localStorage.getItem(ACCESS_TOKEN));
      sessionStorage.setItem(SessionIds.USER_ID, localStorage.getItem(SessionIds.USER_ID));
      sessionStorage.setItem(ACCESS_ROLE, localStorage.getItem(ACCESS_ROLE));
      sessionStorage.setItem(USER_CLASS, localStorage.getItem(USER_CLASS));
      sessionStorage.setItem(EXPIRES_AT, localStorage.getItem(EXPIRES_AT));
    }
  }

  public get currentUserValue(): Token {
    return this.currentUserSubject.value;
  }
  public getTokenClaim(): TokenClaim {

    if (this.currentUserSubject.value === null) {
      return new class implements TokenClaim {
        active_group_id: string;
        class: string;
        exp: number;
        id: string;
        role: string;
      };
    }

    const token =  this.currentUserSubject.value.access_token;

    // const decoded = jwt_decode(token);
    const helper = new JwtHelperService();

    const decoded = helper.decodeToken(token);

    return decoded;
  }

  signIn(body: LoginInput): Observable<any> {
    this.staySignedIn = body.staySignedIn;

    return this.http.post<any>(environment.serverUrl + '/signin', body)
      .pipe(map(token => {

        localStorage.setItem(STAY_SIGNED_IN, this.staySignedIn ? 'Y' : 'N');
        this.setSession(token);
        // this.onSignInOut.emit('signIn');
        this.emitter.emit( new VisualEvent(AuthEventId.SIGN_IN));

        return token;
      }));
  }
  switchTeam(teamId: string): Observable<any> {

    return this.http.get<any>(environment.serverUrl + '/switch/' + teamId)
      .pipe(map(token => {

        this.setSession(token);
        // this.onSignInOut.emit(AuthEventId.SIGN_IN);
        // this.onSwitchingTeam.emit(teamId);
        this.emitter.emit(new VisualEvent(AuthEventId.CHANGE_TEAM, teamId));

        return token;
      }));
  }
  // getSignInOutEmitter(): EventEmitter<any> {
  //   return this.onSignInOut;
  // }

  private setSession(token: Token): void {

    const helper = new JwtHelperService();
    const claim = helper.decodeToken(token.access_token);
    const expiresAt = moment().add(claim.exp, 'second');

    sessionStorage.setItem(CURRENT_USER, JSON.stringify(token));
    sessionStorage.setItem(ACCESS_TOKEN, token.access_token);
    sessionStorage.setItem(SessionIds.USER_ID, claim.id);
    sessionStorage.setItem(ACCESS_ROLE, claim.role);
    sessionStorage.setItem(USER_CLASS, claim.class);
    sessionStorage.setItem(EXPIRES_AT, JSON.stringify(expiresAt.valueOf()));

    if ('Y' === localStorage.getItem(STAY_SIGNED_IN)) {
      localStorage.setItem(CURRENT_USER, JSON.stringify(token));
      localStorage.setItem(ACCESS_TOKEN, token.access_token);
      localStorage.setItem(SessionIds.USER_ID, claim.id);
      localStorage.setItem(ACCESS_ROLE, claim.role);
      localStorage.setItem(USER_CLASS, claim.class);
      localStorage.setItem(EXPIRES_AT, JSON.stringify(expiresAt.valueOf()));
    }

    this.currentUserSubject.next(token);

  }
  setGuestSession(accessToken: string) {
    this.logout();
    const helper = new JwtHelperService();
    const claim = helper.decodeToken(accessToken);
    const expiresAt = moment().add(claim.exp, 'second');

    const token = new class implements Token {
      access_token = accessToken;
      refresh_token = '';
      token_type = '';

    };
    sessionStorage.setItem(CURRENT_USER, JSON.stringify(token));
    sessionStorage.setItem(ACCESS_TOKEN, accessToken);
    sessionStorage.setItem(SessionIds.USER_ID, claim.id);
    sessionStorage.setItem(ACCESS_ROLE, claim.role);
    sessionStorage.setItem(USER_CLASS, claim.class);
    sessionStorage.setItem(EXPIRES_AT, JSON.stringify(expiresAt.valueOf()));
    this.emitter.emit( new VisualEvent(AuthEventId.SIGN_IN));
  }

  logout(): void {

    sessionStorage.removeItem(ACCESS_TOKEN);
    sessionStorage.removeItem(CURRENT_USER);
    sessionStorage.removeItem(ACCESS_ROLE);
    sessionStorage.removeItem(USER_CLASS);
    sessionStorage.removeItem(EXPIRES_AT);
    sessionStorage.removeItem(SessionIds.USER_ID);
    sessionStorage.removeItem(SessionIds.EDI_ID);

    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(CURRENT_USER);
    localStorage.removeItem(ACCESS_ROLE);
    localStorage.removeItem(USER_CLASS);
    localStorage.removeItem(EXPIRES_AT);
    localStorage.removeItem(STAY_SIGNED_IN);
    localStorage.removeItem(SessionIds.USER_ID);
    localStorage.removeItem(SessionIds.EDI_ID);

    this.currentUserSubject.next(null);
    // this.onSignInOut.emit(AuthEventId.SIGN_OUT);
    this.emitter.emit( new VisualEvent(AuthEventId.SIGN_OUT));

  }
  public isLoggedIn(): boolean {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  getExpiration(): moment.Moment {

    const expiration = ('Y' === localStorage.getItem(STAY_SIGNED_IN)) ? localStorage.getItem(EXPIRES_AT) : sessionStorage.getItem(EXPIRES_AT);
    // const expiration =  sessionStorage.getItem(EXPIRES_AT);
    const expiresAt = JSON.parse(expiration);

    return moment(expiresAt);
  }
  isAuthenticated(): boolean {
    return this.isLoggedIn();
  }
  getEmitter(): EventEmitter<VisualEvent> {
    return this.emitter;
  }
  getMemberRole(): string {

    return this.getTokenClaim().role;
  }
  isAdmin(): boolean {
    return (('Y' === localStorage.getItem(STAY_SIGNED_IN)) ? localStorage.getItem(USER_CLASS) === UserClass.ADMIN : sessionStorage.getItem(USER_CLASS)) ===  UserClass.ADMIN;
  }
  eligibleToEdit(): boolean {
    return this.getTokenClaim().role === ROLE.MAINTAINER || this.getTokenClaim().role === ROLE.PLANNER ? true : false;
  }
  isMemberOrAdmin(): boolean {

    return 'Y' === localStorage.getItem(STAY_SIGNED_IN)
      ? localStorage.getItem(USER_CLASS) === UserClass.ADMIN || localStorage.getItem(USER_CLASS) === UserClass.MEMBER
      : sessionStorage.getItem(USER_CLASS) === UserClass.ADMIN || sessionStorage.getItem(USER_CLASS) === UserClass.MEMBER;
  }
}
