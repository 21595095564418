import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {EventService} from '../../core/event/event.service';
import {EdiSharingService} from './service/edi-sharing.service';
import {EventIds} from '../../core/event/event-ids';
import {BottomTabText} from '../bottom-panel/bottom-panel.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {OkDialogComponent} from '../../dialogs/ok-dialog/ok-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {SharedEdi} from './service/shared-edi';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-shared-edi',
  templateUrl: './shared-edi.component.html',
  styleUrls: ['./shared-edi.component.css']
})
export class SharedEdiComponent implements OnInit, OnDestroy {

  @Input()
  bottomHeight = 200;

  @Input()
  tabEmitter: EventEmitter<MatTabChangeEvent>;
  private eventSubscription;
  private tabSubscription;

  showProgress = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  columnNames = [ColumnCodes.Select, ColumnCodes.Granter, ColumnCodes.Grantee, ColumnCodes.IssuedAt, ColumnCodes.ExpiredAt, ColumnCodes.Expire];
  dataSource = new MatTableDataSource<SharedEdi>();

  @Input()
  active: boolean;

  ediId = '';
  constructor(private eventService: EventService,
              private elementRef: ElementRef,
              private translate: TranslateService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private ediSharingService: EdiSharingService) { }

  ngOnInit(): void {
    this.eventSubscription = this.eventService.getEmitter().subscribe(event => {

      if (event.id === EventIds.SELECT_EDI_FILE) {

        if (this.active) {
          this.ediId = event.newValue.ediId;
          this.search();
        }
      }
    });
    this.tabSubscription = this.tabEmitter.subscribe(event => {
      if (event.tab.textLabel === BottomTabText.SHARED_EDI) {
        this.active = true;
      } else {
        this.active = false;
      }
    });
  }
  search() {

    this.ediSharingService.getSharedEdis(this.ediId).subscribe(data => {

      this.dataSource = new MatTableDataSource<SharedEdi>(data);
      this.dataSource.paginator = this.paginator;
      this.showProgress = false;

    }, error => {
      this.showErrorDialog(error);
    });
  }
  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.tabSubscription) {
      this.tabSubscription.unsubscribe();
    }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  showErrorDialog(error: any) {
    console.error(error);
    this.showProgress = false;
    let errorMessage = error.message;
    if (typeof error.error === 'string') {

      errorMessage = this.translate.instant(error.error);
    }
    const dialogRef = this.dialog.open(OkDialogComponent, {
      maxWidth: '800px',
      data: {header: error.statusText, message:  errorMessage}
    });
  }

  getValue(element: SharedEdi, column: string) {

    if (column === ColumnCodes.Granter) {
      return element.granter;
    } else if (column ===  ColumnCodes.Grantee) {
      return element.grantee;
    } else if (column === ColumnCodes.IssuedAt) {
      return new Date(element.sharedAt).toLocaleString();
    } else if (column === ColumnCodes.ExpiredAt) {
      return new Date(element.expiredAt).toLocaleString();
    }
    return '';
  }

  expire(id) {
    this.ediSharingService.expire(id).subscribe(data => {
      this.search();
      this.openSnackBar('Removed', 'Close');
    }, error => {
      this.showErrorDialog(error);
    });
  }
}
enum ColumnCodes {
  Select = 'No',
  Granter = 'Granter',
  Grantee = 'Grantee',
  IssuedAt = 'Shared',
  ExpiredAt = 'Expiring',
  Expire = 'Expire'
}
