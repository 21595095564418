import { AdminLayoutComponent, AuthLayoutComponent } from './core';

import { Routes } from '@angular/router';
import {MembersComponent} from './user/members/members.component';
import {TeamsComponent} from './user/teams/teams.component';
import {TeamMembersComponent} from './user/team-members/team-members.component';
import {UserProfileComponent} from './user/user-profile/user-profile.component';
import {PreferencesComponent} from './panels/preferences/preferences.component';

export const AppRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule)
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [

      {
        path: 'edi',
        loadChildren: () => import('./my-plans/my-plans.module').then(m => m.MyPlansModule)
      },
      {
        path: 'edit',
        loadChildren: () => import('./edit/edit.module').then(m => m.EditModule)
      },
      // {
      //   path: 'compare',
      //   loadChildren: () => import('./comparison/comparison.module').then(m => m.ComparisonModule)
      // },
      {
        path: 'code',
        loadChildren: () => import('./code/code.module').then(m => m.CodeModule)
      },
      {
        path: 'teams',
        component: TeamMembersComponent
      },
      {
        path: 'members',
        component: MembersComponent
      },
      {
        path: 'teams2',
        component: TeamsComponent
      },
      {
        path: 'profile',
        component: UserProfileComponent
      },
      {
        path: 'preferences',
        component: PreferencesComponent
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'prototype',
        loadChildren: () => import('./prototype/prototype.module').then(m => m.PrototypeModule)
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'session',
        loadChildren: () =>
          import('./session/session.module').then(m => m.SessionModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
    ]
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: '**',
    redirectTo: 'session/404'
  }
];
