<div style="margin-left: 6px;margin-right: 6px" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="button-flex" *ngIf="filter.numOfFilters() > 0">
    <button mat-icon-button color="primary"  (click)="clearFilters()"
            matTooltipClass="tooltip"
            matTooltip="Clear">
      <mat-icon>filter_alt_off</mat-icon>
    </button>
  </div>
  <mat-list>
    <mat-form-field class="port-code-form-field">
      <mat-select [class]= "getClass4Select(filterService.carrierFilter)" placeholder="Operator" [(value)]="filterService.carrierFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of carriers" [value]="code.code">
          <p class="code" style="color: {{code.foreColor}}; background-color: {{code.backColor}}"><b>{{ code.code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="pors.length > 0">
      <mat-select [class]= "getClass4Select(filterService.porFilter)" placeholder="POR" [(value)]="filterService.porFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of pors" [value]="code.code">
          <p class="code" style="color: {{code.foreColor}}; background-color: {{code.backColor}}"><b>{{ code.code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="pols.length > 0">
      <mat-select [class]= "getClass4Select(filterService.polFilter)" placeholder="POL" [(value)]="filterService.polFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of pols" [value]="code.code">
          <p class="code" style="color: {{code.foreColor}}; background-color: {{code.backColor}}"><b>{{ code.code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="pods.length > 0">
      <mat-select [class]= "getClass4Select(filterService.podFilter)" placeholder="POD" [(value)]="filterService.podFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of pods" [value]="code.code">
          <p class="code" style="color: {{code.foreColor}}; background-color: {{code.backColor}}"><b>{{ code.code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="optPods.length > 0">
      <mat-select [class]= "getClass4Select(filterService.optPodFilter)" placeholder="Optional POD" [(value)]="filterService.optPodFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of optPods" [value]="code.code">
          <p class="code" style="color: {{code.foreColor}}; background-color: {{code.backColor}}"><b>{{ code.code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="tsPorts.length > 0">
      <mat-select [class]= "getClass4Select(filterService.tsPortFilter)" placeholder="TS port" [(value)]="filterService.tsPortFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of tsPorts" [value]="code.code">
          <p class="code" style="color: {{code.foreColor}}; background-color: {{code.backColor}}"><b>{{ code.code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="nPods.length > 0">
      <mat-select [class]= "getClass4Select(filterService.nPodFilter)" placeholder="Next POD" [(value)]="filterService.nPodFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of nPods" [value]="code.code">
          <p class="code" style="color: {{code.foreColor}}; background-color: {{code.backColor}}"><b>{{ code.code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="fPods.length > 0">
      <mat-select [class]= "getClass4Select(filterService.fPodFilter)" placeholder="Next POD" [(value)]="filterService.fPodFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of fPods" [value]="code.code">
          <p class="code" style="color: {{code.foreColor}}; background-color: {{code.backColor}}"><b>{{ code.code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="placeOfDeliveries.length > 0">
      <mat-select [class]= "getClass4Select(filterService.placeOfDeliveryFilter)" placeholder="Place of Delivery" [(value)]="filterService.placeOfDeliveryFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of placeOfDeliveries" [value]="code.code">
          <p class="code" style="color: {{code.foreColor}}; background-color: {{code.backColor}}"><b>{{ code.code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-list>

  <mat-list>
    <mat-form-field class="port-code-form-field" *ngIf="isoSizeTypes.length > 0">
      <mat-select [class]= "getClass4Select(filterService.isoSizeTypeFilter)" placeholder="ISO" [(value)]="filterService.isoSizeTypeFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of isoSizeTypes" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="lengthCodes.length > 0">
      <mat-select [class]= "getClass4Select(filterService.lengthFilter)" placeholder="Length" [(value)]="filterService.lengthFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of lengthCodes" [value]="code.code">
          <p class="code" style="color: {{code.foreColor}}; background-color: {{code.backColor}}"><b>{{ code.name }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="heightCodes.length > 0">
      <mat-select [class]= "getClass4Select(filterService.heightFilter)" placeholder="Height" [(value)]="filterService.heightFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of heightCodes" [value]="code.code">
          <p class="code" style="color: {{code.foreColor}}; background-color: {{code.backColor}}"><b>{{ code.name }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="typeCodes.length > 0">
      <mat-select [class]= "getClass4Select(filterService.typeFilter)" placeholder="Type" [(value)]="filterService.typeFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="type-code" *ngFor="let code of typeCodes" [value]="code.code">
          <p class="code" style="color: {{code.foreColor}}; background-color: {{code.backColor}}"><b>{{ code.name }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-list>
  <mat-list>
    <mat-slide-toggle class="toggle-filter" [(ngModel)]="filter.weight" (ngModelChange)="applyFilter()">Weight</mat-slide-toggle>
    <mat-form-field style="width: 60px">
      <mat-label>min</mat-label>
      <input matInput type="number" min="0" max="132860" [ngClass] = "{filtered: filter.weight}" [(ngModel)]="minWeight" (focusout)="applyFilter()">
    </mat-form-field>
    <mat-form-field style="width: 60px">
      <mat-label>max</mat-label>
      <input matInput type="number" min="0" max="132860" [ngClass] = "{filtered: filter.weight}" [(ngModel)]="maxWeight" (focusout)="applyFilter()">
    </mat-form-field>
  </mat-list>
  <mat-list>
    <mat-form-field class="port-code-form-field" *ngIf="fullEmpty.length > 0" matTooltip="{{getToolTip(ColumnIds.FULL_EMPTY)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.fullEmptyFilter)" placeholder="{{getColumnName(ColumnIds.FULL_EMPTY)}}" [(value)]="filterService.fullEmptyFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of fullEmpty" [value]="code.code.substring(3)">
          <p class="code" style="color: {{code.value}}; background-color: {{code.value2}}"><b>{{ code.name }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="cargoTypes.length > 0" matTooltip="Cargo Type" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.cargoTypeFilter)" placeholder="Cargo" [(value)]="filterService.cargoTypeFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="cargo" *ngFor="let code of cargoTypes" [value]="code.code">
          <p class="code" style="color: {{code.value}}; background-color: {{code.value2}}"><b>{{ code.name }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="imdgs.length > 0" matTooltip="{{getToolTip(ColumnIds.IMDG)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.imdgFilter)" placeholder="IMDG" [(value)]="filterService.imdgFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of imdgs" [value]="code">
          <p class="code"><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="bundledYn.length > 0" matTooltip="{{getToolTip(ColumnIds.BUNDLED_CONTAINER)}} Y/N" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.bundledYnFilter)" placeholder="{{getColumnName(ColumnIds.BUNDLED_CONTAINER)}}" [(value)]="filterService.bundledYnFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of bundledYn" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="breakBulkYn.length > 0" matTooltip="{{getToolTip(ColumnIds.BREAK_BULK)}} Y/N" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.breakBulkYnFilter)" placeholder="{{getColumnName(ColumnIds.BREAK_BULK)}}" [(value)]="filterService.breakBulkYnFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of breakBulkYn" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="overDimensionsYn.length > 0" matTooltip="{{getToolTip(ColumnIds.OVER_DIMENSIONS)}} Y/N" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.overDimensionYnFilter)" placeholder="{{getColumnName(ColumnIds.OVER_DIMENSIONS)}}" [(value)]="filterService.overDimensionYnFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of overDimensionsYn" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="overSlotYn.length > 0" matTooltip="{{getToolTip(ColumnIds.OVER_SLOTS)}} Y/N" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.overSlotYnFilter)" placeholder="{{getColumnName(ColumnIds.OVER_SLOTS)}}" [(value)]="filterService.overSlotYnFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of overSlotYn" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="temperatureRangeYn.length > 0" matTooltip="{{getToolTip(ColumnIds.TEMPERATURE_RANGE)}} Y/N" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.temperatureRangeYnFilter)" placeholder="{{getColumnName(ColumnIds.TEMPERATURE_RANGE)}}" [(value)]="filterService.temperatureRangeYnFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of temperatureRangeYn" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="vgmYn.length > 0" matTooltip="{{getToolTip(ColumnIds.VGM_WEIGHT)}} Y/N" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.vgmYnFilter)" placeholder="{{getColumnName(ColumnIds.VGM_WEIGHT)}}" [(value)]="filterService.vgmYnFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of vgmYn" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="vgmCertificationCode.length > 0" matTooltip="{{getToolTip(ColumnIds.VGM_CERTI_CODE)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.vgmCertificationCodeFilter)" placeholder="{{getColumnName(ColumnIds.VGM_CERTI_CODE)}}" [(value)]="filterService.vgmCertificationCodeFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of vgmCertificationCode" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="vgmInfoYm.length > 0" matTooltip="{{getToolTip(ColumnIds.VGM_INFO)}} Y/N" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.vgmInfoYnFilter)" placeholder="{{getColumnName(ColumnIds.VGM_INFO)}}" [(value)]="filterService.vgmInfoYnFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of vgmInfoYm" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="groupCodeYn.length > 0" matTooltip="{{getToolTip(ColumnIds.GROUP_CODE)}} Y/N" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.groupCodeYnFilter)" placeholder="{{getColumnName(ColumnIds.GROUP_CODE)}} Y/N" [(value)]="filterService.groupCodeYnFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of groupCodeYn" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="groupCodes.length > 0" matTooltip="{{getToolTip(ColumnIds.GROUP_CODE)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.groupCodeFilter)" placeholder="{{getColumnName(ColumnIds.GROUP_CODE)}}" [(value)]="filterService.groupCodeFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of groupCodes" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="descriptionOfGoods.length > 0" matTooltip="{{getToolTip(ColumnIds.DESCRIPTION_OF_GOODS)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.descriptionOfGoodsFilter)" placeholder="{{getColumnName(ColumnIds.DESCRIPTION_OF_GOODS)}}" [(value)]="filterService.descriptionOfGoodsFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="remarks" *ngFor="let code of descriptionOfGoods" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="handlingInstruction.length > 0" matTooltip="{{getToolTip(ColumnIds.HANDLING_INSTRUCTION)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.handlingInstructionFilter)" placeholder="{{getColumnName(ColumnIds.HANDLING_INSTRUCTION)}}" [(value)]="filterService.handlingInstructionFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="remarks" *ngFor="let code of handlingInstruction" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="loadingRemarks.length > 0" matTooltip="{{getToolTip(ColumnIds.LOADING_REMARK)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.loadingRemarksFilter)" placeholder="{{getColumnName(ColumnIds.LOADING_REMARK)}}" [(value)]="filterService.loadingRemarksFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="remarks" *ngFor="let code of loadingRemarks" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="specialCargoInstruction.length > 0" matTooltip="{{getToolTip(ColumnIds.SPECIAL_CARGO_INSTRUCTION)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.specialCargoInstructionFilter)" placeholder="{{getColumnName(ColumnIds.SPECIAL_CARGO_INSTRUCTION)}}" [(value)]="filterService.specialCargoInstructionFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="remarks" *ngFor="let code of specialCargoInstruction" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="generalInformation.length > 0" matTooltip="{{getToolTip(ColumnIds.GENERAL_INFORMATION)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.generalInformationFilter)" placeholder="{{getColumnName(ColumnIds.GENERAL_INFORMATION)}}" [(value)]="filterService.generalInformationFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="remarks" *ngFor="let code of generalInformation" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="mutuallyDefinedRemarks.length > 0" matTooltip="{{getToolTip(ColumnIds.MUTUALLY_DEFINED_REMARK)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.mutuallyDefinedRemarksFilter)" placeholder="{{getColumnName(ColumnIds.MUTUALLY_DEFINED_REMARK)}}" [(value)]="filterService.mutuallyDefinedRemarksFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="remarks" *ngFor="let code of mutuallyDefinedRemarks" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
<!--    <mat-form-field class="port-code-form-field" *ngIf="specialCargoInstructionYn.length > 0" matTooltip="{{getToolTip(ColumnIds.SPECIAL_CARGO_INSTRUCTION)}} Y/N" matTooltipClass="tooltip">-->
<!--      <mat-select [class]= "getClass4Select(filterService.specialCargoInstructionYnFilter)" placeholder="{{getColumnName(ColumnIds.SPECIAL_CARGO_INSTRUCTION)}}" [(value)]="filterService.specialCargoInstructionYnFilter" (selectionChange)="applyFilter()" multiple>-->
<!--        <mat-option class="code" *ngFor="let code of specialCargoInstructionYn" [value]="code">-->
<!--          <p class="code" ><b>{{ code }}</b></p>-->
<!--        </mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->
<!--    <mat-form-field class="port-code-form-field" *ngIf="loadingRemarkYn.length > 0" matTooltip="{{getToolTip(ColumnIds.LOADING_REMARK)}} Y/N" matTooltipClass="tooltip">-->
<!--      <mat-select [class]= "getClass4Select(filterService.loadingRemarkYnFilter)" placeholder="{{getColumnName(ColumnIds.LOADING_REMARK)}}" [(value)]="filterService.loadingRemarkYnFilter" (selectionChange)="applyFilter()" multiple>-->
<!--        <mat-option class="code" *ngFor="let code of loadingRemarkYn" [value]="code">-->
<!--          <p class="code" ><b>{{ code }}</b></p>-->
<!--        </mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->
<!--    <mat-form-field class="port-code-form-field" *ngIf="generalInformationYn.length > 0" matTooltip="{{getToolTip(ColumnIds.GENERAL_INFORMATION)}} Y/N" matTooltipClass="tooltip">-->
<!--      <mat-select [class]= "getClass4Select(filterService.generalInformationYnFilter)" placeholder="{{getColumnName(ColumnIds.GENERAL_INFORMATION)}}" [(value)]="filterService.generalInformationYnFilter" (selectionChange)="applyFilter()" multiple>-->
<!--        <mat-option class="code" *ngFor="let code of generalInformationYn" [value]="code">-->
<!--          <p class="code" ><b>{{ code }}</b></p>-->
<!--        </mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->
<!--    <mat-form-field class="port-code-form-field" *ngIf="mutuallyDefinedRemarkYn.length > 0" matTooltip="{{getToolTip(ColumnIds.MUTUALLY_DEFINED_REMARK)}} Y/N" matTooltipClass="tooltip">-->
<!--      <mat-select [class]= "getClass4Select(filterService.mutuallyDefinedRemarkYnFilter)" placeholder="{{getColumnName(ColumnIds.MUTUALLY_DEFINED_REMARK)}}" [(value)]="filterService.mutuallyDefinedRemarkYnFilter" (selectionChange)="applyFilter()" multiple>-->
<!--        <mat-option class="code" *ngFor="let code of mutuallyDefinedRemarkYn" [value]="code">-->
<!--          <p class="code" ><b>{{ code }}</b></p>-->
<!--        </mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->
<!--    <mat-form-field class="port-code-form-field" *ngIf="descriptionOfGoodsYn.length > 0" matTooltip="{{getToolTip(ColumnIds.DESCRIPTION_OF_GOODS)}} Y/N" matTooltipClass="tooltip">-->
<!--      <mat-select [class]= "getClass4Select(filterService.descriptionOfGoodsYnFilter)" placeholder="{{getColumnName(ColumnIds.DESCRIPTION_OF_GOODS)}}" [(value)]="filterService.descriptionOfGoodsYnFilter" (selectionChange)="applyFilter()" multiple>-->
<!--        <mat-option class="code" *ngFor="let code of descriptionOfGoodsYn" [value]="code">-->
<!--          <p class="code" ><b>{{ code }}</b></p>-->
<!--        </mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->
    <mat-form-field class="port-code-form-field" *ngIf="cargoNatureYn.length > 0"  matTooltip="{{getToolTip(ColumnIds.CARGO_NATURE)}} Y/N" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.cargoNatureYnFilter)" placeholder="{{getColumnName(ColumnIds.CARGO_NATURE)}}" [(value)]="filterService.cargoNatureYnFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of cargoNatureYn" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="packageQuantityYn.length > 0" matTooltip="{{getToolTip(ColumnIds.PACKAGE_QUANTITY)}} Y/N" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.packageQuantityYnFilter)" placeholder="{{getColumnName(ColumnIds.PACKAGE_QUANTITY)}}" [(value)]="filterService.packageQuantityYnFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of packageQuantityYn" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="packageTypeYn.length > 0" matTooltip="{{getToolTip(ColumnIds.PACKAGE_TYPE)}} Y/N" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.packageTypeYnFilter)" placeholder="{{getColumnName(ColumnIds.PACKAGE_TYPE)}}" [(value)]="filterService.packageTypeYnFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of packageTypeYn" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="blYn.length > 0" matTooltip="{{getToolTip(ColumnIds.BL_NO)}} Y/N" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.blYnFilter)" placeholder="{{getColumnName(ColumnIds.BL_NO)}}" [(value)]="filterService.blYnFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of blYn" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="bookingNoYn.length > 0" matTooltip="{{getToolTip(ColumnIds.BOOKING_NO)}} Y/N" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.bookingNoYnFilter)" placeholder="{{getColumnName(ColumnIds.BOOKING_NO)}}" [(value)]="filterService.bookingNoYnFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of bookingNoYn" [value]="code">
          <p class="code" ><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
<!--  </mat-list>-->
<!--  <mat-list>-->
    <mat-form-field class="port-code-form-field" *ngIf="porTerminals.length > 0" matTooltip="{{getToolTip(ColumnIds.POR_TERMINAL)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.porTerminalFilter)" placeholder="{{getColumnName(ColumnIds.POR_TERMINAL)}}" [(value)]="filterService.porTerminalFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of porTerminals" [value]="code">
          <p class="code"><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="polTerminals.length > 0" matTooltip="{{getToolTip(ColumnIds.POL_TERMINAL)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.polTerminalFilter)" placeholder="{{getColumnName(ColumnIds.POL_TERMINAL)}}" [(value)]="filterService.polTerminalFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of polTerminals" [value]="code">
          <p class="code"><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="podTerminals.length > 0" matTooltip="{{getToolTip(ColumnIds.POD_TERMINAL)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.podTerminalFilter)" placeholder="{{getColumnName(ColumnIds.POD_TERMINAL)}}" [(value)]="filterService.podTerminalFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of podTerminals" [value]="code">
          <p class="code"><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="tsTerminals.length > 0" matTooltip="{{getToolTip(ColumnIds.TS_TERMINAL)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.tsTerminalFilter)" placeholder="{{getColumnName(ColumnIds.TS_TERMINAL)}}" [(value)]="filterService.tsTerminalFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of tsTerminals" [value]="code">
          <p class="code"><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="optPodTerminals.length > 0" matTooltip="{{getToolTip(ColumnIds.OPT_POD_TERMINAL)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.optPodTerminalFilter)" placeholder="{{getColumnName(ColumnIds.OPT_POD_TERMINAL)}}" [(value)]="filterService.optPodTerminalFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of optPodTerminals" [value]="code">
          <p class="code"><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="nPodTerminals.length > 0" matTooltip="{{getToolTip(ColumnIds.NPOD_TERMINAL)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.nPodTerminalFilter)" placeholder="{{getColumnName(ColumnIds.NPOD_TERMINAL)}}" [(value)]="filterService.nPodTerminalFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of nPodTerminals" [value]="code">
          <p class="code"><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="port-code-form-field" *ngIf="placeOfDeliveryTerminals.length > 0" matTooltip="{{getToolTip(ColumnIds.PLACE_OF_DELIVERY_TERMINAL)}}" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.placeOfDeliveryTerminalFilter)" placeholder="{{getColumnName(ColumnIds.PLACE_OF_DELIVERY_TERMINAL)}}" [(value)]="filterService.placeOfDeliveryTerminalFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="code" *ngFor="let code of placeOfDeliveryTerminals" [value]="code">
          <p class="code"><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="hatch-form-field" *ngIf="hatches.length > 0" matTooltip="Bay" matTooltipClass="tooltip">
      <mat-select [class]= "getClass4Select(filterService.hatchFilter)" placeholder="Bay" [(value)]="filterService.hatchFilter" (selectionChange)="applyFilter()" multiple>
        <mat-option class="hatch" *ngFor="let code of hatches" [value]="code">
          <p class="code"><b>{{ code }}</b></p>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-list>
  <mat-list>
    <mat-form-field class="port-code-form-field">
      <mat-label>Container no</mat-label>
      <input matInput [class] = "getClass4Input(filter.containerNo)" [(ngModel)]="filter.containerNo" placeholder="Pattern" (ngModelChange)="applyFilter()">
    </mat-form-field>
    <mat-form-field class="port-code-form-field">
      <mat-label>Stowage</mat-label>
      <input matInput [class] = "getClass4Input(filter.stowage)" [(ngModel)]="filter.stowage" placeholder="Pattern" (ngModelChange)="applyFilter()">
    </mat-form-field>
  </mat-list>
  <mat-form-field appearance="fill" style="margin-top: 6px">
    <mat-label>Multi container numbers</mat-label>
    <textarea id="containerText" matInput [class] = "getClass4TextArea(filterService.multiContainerFilter)" [(ngModel)]="filterService.multiContainerFilter" placeholder="Separate with new lines" (ngModelChange)="applyFilter('multiContainersFilter')"></textarea>
  </mat-form-field>
  <mat-form-field appearance="fill" style="margin-top: 6px">
    <mat-label>Stowage numbers</mat-label>
    <textarea id="stowageText" matInput [class] = "getClass4TextArea(filterService.multiStowageFilter)" [(ngModel)]="filterService.multiStowageFilter" placeholder="Separate with new lines" (ngModelChange)="applyFilter('multiStowageFilter')"></textarea>
  </mat-form-field>
</div>
