import {BayPlan} from '../../service/model/bay-plan';
import {DateTimeUtils} from '../../hull/service/date-time-utils';
import {EdiHeaderPatch} from './model/edi-header-patch';

export class EdiMarkersInput {

  constructor(public id: string, public markers: number) {
  }
}
export class EdiSummaryInput {

  fullUnits = 0;
  emptyUnits = 0;
  reeferUnits = 0;
  dgUnits = 0;
  breakBulkUnits = 0;
  overDimensionUnits = 0;
  overSlotUnits = 0;
  handlingInstructionUnits = 0;
  markers = 0;

  constructor(public id: string, protected bayPlan: BayPlan, protected segregationErrors= 0, protected stowageErrors = 0) {

    this.summarize();
  }

  private summarize() {

    this.fullUnits = 0;
    this.emptyUnits = 0;
    this.reeferUnits = 0;
    this.dgUnits = 0;
    this.breakBulkUnits = 0;
    this.overDimensionUnits = 0;
    this.overSlotUnits = 0;
    this.handlingInstructionUnits = 0;
    this.markers = this.bayPlan.syntaxViolations.length + this.segregationErrors + this.stowageErrors;

    this.bayPlan.containers.forEach(cntr => {

      if (cntr.fullEmpty === 'F') {
        this.fullUnits ++;
      } else if (cntr.fullEmpty === 'M') {
        this.emptyUnits ++;
      }
      if (cntr.getCargoTypeCode() === '016RD' || cntr.getCargoTypeCode() === '016RF') {
        this.reeferUnits ++;
      }
      if (cntr.getCargoTypeCode() === '016DM' || cntr.getCargoTypeCode() === '016DG') {
        this.dgUnits ++;
      }
      if (cntr.hasBreakBulk()) {
        this.breakBulkUnits ++;
      }
      if (cntr.hasOverDimension()) {
        this.overDimensionUnits ++;
      }
      if (cntr.hasOverSlot()) {
        this.overSlotUnits ++;
      }
      if (cntr.handlingInstruction) {
        this.handlingInstructionUnits ++;
      }

    });
  }
  cleanBayPlan() {
    this.bayPlan = new BayPlan();
  }
}
export class EdiHeaderInput extends EdiSummaryInput implements EdiHeaderPatch {
  id = '';
  fileName = '';
  messageType = '';
  versionNo = '';
  messageFunction = '';
  sender = '';
  recipient = '';
  imoNo = '';
  callSign = '';
  vesselCode = '';
  vesselCodeType = '';
  vesselName = '';
  portCode = '';
  nextPortCode = '';
  voyageNo = '';
  ata = '';
  etd = '';
  atd = '';
  nextEta = '';
  carrier = '';

  preparationDate = '';
  preparationTime = '';
  serviceLaneCode = '';

  smdgVersion = '';
  compilationDatetime = '';
  conveyanceRefNo = '';
  messageRefNo = '';
  documentNo = '';
  interchangeControlCount = '';
  interchangeControlRefStart = '';
  interchangeControlRefEnd = '';


  constructor(public ediText, protected bayPlan: BayPlan, protected segregationErrors= 0, protected stowageErrors = 0) {

    super('', bayPlan, segregationErrors, segregationErrors);

    const header = bayPlan.header;
    this.id = header.id;
    this.messageType = header.messageType;
    this.messageFunction = header.messageFunction;
    this.versionNo = header.versionNo;
    this.sender = header.sender;
    this.recipient = header.recipient;
    this.vesselCode = header.vesselCode;
    this.vesselCodeType = header.vesselCodeType;
    this.vesselName = header.vesselName;
    this.voyageNo = header.voyageNo;

    this.callSign = header.callSign;
    this.imoNo = header.imoNo;
    this.portCode = header.portCode;
    this.nextPortCode = header.nextPortCode;
    this.ata = header.ata;
    this.nextEta = header.nextEta;
    this.etd = header.etd;
    this.atd = header.atd;
    this.carrier = header.carrier;
    this.preparationDate = header.preparationDate;
    this.preparationTime = header.preparationTime;
    this.serviceLaneCode = header.serviceLaneCode;

    this.smdgVersion = header.smdgVersion;
    this.compilationDatetime = DateTimeUtils.toEdiFormat(header.compilationDatetime);
    this.messageRefNo = header.messageRefNo;
    this.documentNo = header.documentNo;
    this.interchangeControlCount = header.interchangeControlCount;
    this.interchangeControlRefStart = header.interchangeControlRefStart;
    this.interchangeControlRefEnd = header.interchangeControlRefEnd;


  }

}
