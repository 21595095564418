import {VisualPart} from './visual-part';
import * as PIXI from 'pixi.js';

export class BitmapTextVisualPart extends VisualPart {

  private readonly bitmapText: PIXI.BitmapText;
  constructor(protected parentGraphics: PIXI.Container, private name: string, public fontSize?: number, private bold?: boolean) {
    super(parentGraphics);

    try {
      this.bitmapText = new PIXI.BitmapText(name, { fontName: 'DefaultFont', fontSize: this.fontSize, align: 'center' });
      this.graphics.addChild(this.bitmapText);

    } catch (error) {
      console.error(error);
    }

  }
  draw() {
    super.draw();
    this.bitmapText.x = this.bounds.x + (this.bounds.width - this.bitmapText.width) / 2;
    this.bitmapText.y = this.bounds.y + (this.bounds.height - this.bitmapText.height) / 2;

    // this.getGraphics().lineStyle(1, 0x0000AA);
    // this.getGraphics().drawRect(this.bounds.x, this.bounds.y, this.bounds.width, this.bounds.height);
    // this.getGraphics().endFill();
  }
  setText(name: string, fontSize?: number) {
    this.name = JSON.parse(JSON.stringify(name));
    this.bitmapText.fontSize = fontSize;
    this.bitmapText.text = this.name;
    this.draw();
  }
}
