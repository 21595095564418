import {Bounds, VisualPart} from '../../../core/pixi/visual-part';
import PIXI from 'pixi.js';
import {ContainerVisualPart} from './container.visual-part';
import {Container} from '../../../service/model/container';

export class OverSlotVisualPart extends VisualPart {

  private readonly orangeColor = 0xFFA500;
  private readonly blueColor = 0x0000FF;
  private readonly redColor = 0xFF0000;
  private readonly yellowColor = 0xFFFF00;

  constructor(parentGraphics: PIXI.Container, private containerBounds: Bounds, public container: Container) {
    super(parentGraphics);
  }
  draw() {
    super.draw();
    this.getGraphics().lineStyle(3, this.redColor);

    const cell = this.containerBounds;
    const left = Number(this.container.overSlotLeft);
    const right = Number(this.container.overSlotRight);
    const top = Number(this.container.overSlotHeight);

    const oogX = cell.x + cell.width / 3 - left * cell.width;
    const oogY = cell.y + cell.height / 3 - top * cell.height;
    const oogWidth = (left + right) * cell.width + cell.width / 3;
    const oogHeight = cell.height * top + cell.height / 3;

    if (left > 0 && top < 1 && right < 1) {
      this.graphics.moveTo(cell.x, cell.y + cell.height);
      this.graphics.lineTo(cell.x, oogY + oogHeight);
      this.graphics.lineTo(oogX, oogY + oogHeight);
      this.graphics.lineTo(oogX, oogY);
      this.graphics.lineTo(cell.x, oogY);
      this.graphics.lineTo(cell.x, cell.y);
      this.graphics.lineTo(cell.x + cell.width, cell.y);
      this.graphics.lineTo(cell.x + cell.width, cell.y + cell.height);
    }

    if (left > 0 && top > 0 && right < 1) {
      this.graphics.moveTo(cell.x, cell.y + cell.height);
      this.graphics.lineTo(cell.x, oogY + oogHeight);
      this.graphics.lineTo(oogX, oogY + oogHeight);
      this.graphics.lineTo(oogX, oogY);
      this.graphics.lineTo(oogX + oogWidth, oogY);
      this.graphics.lineTo(oogX + oogWidth, cell.y);
      this.graphics.lineTo(cell.x + cell.width, cell.y);
      this.graphics.lineTo(cell.x + cell.width, cell.y + cell.height);
    }
    if (left > 0 && top > 0 && right > 0) {
      this.graphics.moveTo(cell.x, cell.y + cell.height);
      this.graphics.lineTo(cell.x, oogY + oogHeight);
      this.graphics.lineTo(oogX, oogY + oogHeight);
      this.graphics.lineTo(oogX, oogY);
      this.graphics.lineTo(oogX + oogWidth, oogY);
      this.graphics.lineTo(oogX + oogWidth, oogY + oogHeight);
      this.graphics.lineTo(cell.x + cell.width, oogY + oogHeight);
      this.graphics.lineTo(cell.x + cell.width, cell.y + cell.height);
    }
    if (left < 1 && top > 0 && right < 1) {
      this.graphics.moveTo(cell.x, cell.y + cell.height);
      this.graphics.lineTo(cell.x, cell.y);
      this.graphics.lineTo(oogX, cell.y);
      this.graphics.lineTo(oogX, oogY);
      this.graphics.lineTo(oogX + oogWidth, oogY);
      this.graphics.lineTo(oogX + oogWidth, cell.y);
      this.graphics.lineTo(cell.x + cell.width, cell.y);
      this.graphics.lineTo(cell.x + cell.width, cell.y + cell.height);
    }
    if (left < 1 && top > 0 && right > 0) {
      this.graphics.moveTo(cell.x, cell.y);
      this.graphics.lineTo(oogX, cell.y);
      this.graphics.lineTo(oogX, oogY);
      this.graphics.lineTo(oogX + oogWidth, oogY);
      this.graphics.lineTo(oogX + oogWidth, oogY + oogHeight);
      this.graphics.lineTo(cell.x + cell.width, oogY + oogHeight);
      this.graphics.lineTo(cell.x + cell.width, cell.y + cell.height);
      this.graphics.lineTo(cell.x, cell.y + cell.height);
    }
    if (left < 1 && top < 1 && right > 0) {
      this.graphics.moveTo(cell.x + cell.width, cell.y);
      this.graphics.lineTo(cell.x + cell.width, oogY);
      this.graphics.lineTo(oogX + oogWidth, oogY);
      this.graphics.lineTo(oogX + oogWidth, oogY + oogHeight);
      this.graphics.lineTo(cell.x + cell.width, oogY + oogHeight);
      this.graphics.lineTo(cell.x + cell.width, cell.y + cell.height);
      this.graphics.lineTo(cell.x, cell.y + cell.height);
      this.graphics.lineTo(cell.x, cell.y);
    }

    if (left > 0 && top < 1 && right > 0) {
      this.graphics.moveTo(cell.x, cell.y + cell.height);
      this.graphics.lineTo(cell.x, oogY + oogHeight);
      this.graphics.lineTo(oogX, oogY + oogHeight);
      this.graphics.lineTo(oogX, oogY);
      this.graphics.lineTo(cell.x, oogY);
      this.graphics.lineTo(cell.x, cell.y);
      this.graphics.lineTo(cell.x + cell.width, cell.y);
      this.graphics.lineTo(cell.x + cell.width, oogY);
      this.graphics.lineTo(oogX + oogWidth, oogY);
      this.graphics.lineTo(oogX + oogWidth, oogY + oogHeight);
      this.graphics.lineTo(cell.x + cell.width, oogY + oogHeight);
      this.graphics.lineTo(cell.x + cell.width, cell.y + cell.height);

    }

    this.getGraphics().closePath();
  }
  resetBounds() {

    const left = Number(this.container.overSlotLeft);
    const right = Number(this.container.overSlotRight);
    const top = Number(this.container.overSlotHeight);

    const cell = this.containerBounds;
    const oogX = cell.x + cell.width / 3 - left * cell.width;
    const oogY = cell.y + cell.height / 3 - top * cell.height;
    const oogWidth = (left + right) * cell.width + cell.width / 3;
    const oogHeight = cell.height * top + cell.height / 3;
    this.setBounds(oogX, oogY, oogWidth, oogHeight);

  }
}
