import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Container} from '../../service/model/container';
import {ColumnService} from '../columns/service/column.service';
import {ColumnIds} from '../columns/service/model/column-ids';
import {ColumnUtils} from '../columns/service/model/column-utils';
import {CodeService} from '../../code/service/code.service';
import {SizeType} from '../../code/size-type/service/model/size-type';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-container-filter-dialog',
  templateUrl: './container-filter-dialog.component.html',
  styleUrls: ['./container-filter-dialog.component.css']
})
export class ContainerFilterDialogComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  columns: string[] = ['select', 'name', 'value'];
  dataSource = new MatTableDataSource<Property>();
  private sizeTypeMap: Map<string, SizeType>;
  constructor(@Inject(MAT_DIALOG_DATA) public container: Container
            , private codeService: CodeService
            , private columnService: ColumnService
            , private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.sizeTypeMap = this.codeService.getSizeTypeMap();
    this.fillTable(this.container);
  }
  fillTable(container: Container) {
    const list = new Array<Property>();
    this.columnService.getColumns().then(data => {

      data.forEach(column => {

        if (column.code === ColumnIds.ISO_SIZE_TYPE) {
          const value = container.isoSizeType;
          if (value && value !== 'N/A') {
            const sizeType = this.sizeTypeMap.get(value);
            if (sizeType) {
              list.push({code: column.code, name: ColumnUtils.getDefaultFullName(column.code), value, formattedValue: ColumnUtils.getValueFormatted(column.code, container), selected: false});
              if (sizeType.lengthCode && sizeType.lengthName) {
                list.push({code: column.code, name: 'Container Length', value: sizeType.lengthCode, formattedValue: sizeType.lengthName, selected: true});
              }
              if (sizeType.heightCode && sizeType.heightName) {
                list.push({code: column.code, name: 'Container Height', value: sizeType.heightCode, formattedValue: sizeType.heightName, selected: true});
              }
              if (sizeType.typeCode && sizeType.typeName) {
                list.push({code: column.code, name: 'Container Type', value: sizeType.typeCode, formattedValue: sizeType.typeName, selected: true});
              }
            }
          }
          return;
        }
        if (column.code === ColumnIds.OVER_DIMENSIONS) {
          if (container.hasOverDimension()) {
            list.push({code: column.code, name: ColumnUtils.getDefaultFullName(column.code), value: 'Y', formattedValue: 'Y', selected: true});
          }
          return;
        }
        if (column.code === ColumnIds.IMDG) {
          if (container.dangerousGoods) {
            if (container.dangerousGoods.length > 0) {
              list.push({code: column.code, name: ColumnUtils.getDefaultFullName(column.code), value: 'Y', formattedValue: 'Y', selected: true});

              let index = 0;
              container.dangerousGoods.forEach(dg => {
                const order = (++index).toString();


                list.push({code: ColumnIds.IMDG + '-' + order, name:  order + '] IMDG', value: dg.imdg, formattedValue: dg.imdg, selected: false, filterable: false});

                if (dg.unno) {
                  list.push({code: ColumnIds.UNNO + '-' + order, name: order + '] UNNO', value: dg.unno, formattedValue: dg.unno, selected: false, filterable: false});
                }
                if (dg.emsNo) {
                  list.push({code: 'EMS_NO' + '-' + order, name: order + '] EMS no', value: dg.emsNo, formattedValue: dg.emsNo, selected: false, filterable: false});
                }
                if (dg.packingGroup) {
                  list.push({code: 'PACKING_GROUP' + '-' + order, name: order + '] Packing Grp.', value: dg.packingGroup, formattedValue: dg.packingGroup, selected: false, filterable: false});
                }
                if (dg.hazardPageNo) {
                  list.push({code: 'HAZARD_PAGE_NO' + '-' + order, name: order + '] Page no', value: dg.hazardPageNo, formattedValue: dg.hazardPageNo, selected: false, filterable: false});
                }
                if (dg.mfag) {
                  list.push({code: 'M_FLAG' + '-' + order, name: order + '] M flag', value: dg.mfag, formattedValue: dg.mfag, selected: false, filterable: false});
                }
                if (dg.hazardIdLower || dg.hazardIdUpper) {
                  list.push({code: 'HAZARD_ID' + '-' + order, name: order + '] Lower/Upper ID', value: '', formattedValue: dg.hazardIdLower + ' / ' + dg.hazardIdUpper , selected: false, filterable: false});
                }
                if (dg.goodsLabelMark1 || dg.goodsLabelMark2 || dg.goodsLabelMark3) {
                  list.push({code: 'GOODS_LABEL_MARK' + '-' + order, name: order + '] Goods Label', value: '', formattedValue: this.trim(dg.goodsLabelMark1) + ' / ' + this.trim(dg.goodsLabelMark2) + ' / ' + this.trim(dg.goodsLabelMark3) , selected: false, filterable: false});
                }
                if (dg.freeText1 || dg.freeText2 || dg.freeText3) {
                  list.push({code: 'DG_REMARK' + '-' + order, name: order + '] DG remarks', value: '', formattedValue: dg.freeText1 + ' / ' + dg.freeText2 + ' / ' + dg.freeText3 , selected: false, filterable: false});
                }
              });
            }
          }
          return;
        }
        if (column.code === ColumnIds.BUNDLED_CONTAINER) {
          if (container.hasBundledContainers()) {
            list.push({code: column.code, name: ColumnUtils.getDefaultFullName(column.code), value: 'Y', formattedValue: 'Y', selected: true});

            let index = 0;
            container.bundledContainers.forEach(cntrNo => {
              const order = (++index).toString();
              list.push({code: ColumnIds.BUNDLED_CONTAINER + '-' + order, name: 'Bundled Cntr. [' + order + ']', value: cntrNo, formattedValue: cntrNo,  selected: false, filterable: false});
            });
          }
          return;
        }
        if (column.code === ColumnIds.TEMPERATURE_RANGE) {
          return;
        }
        let selected = true;
        if (column.code === ColumnIds.STOWAGE) {selected = false; }
        if (column.code === ColumnIds.CONTAINER_NO) {selected = false; }
        if (column.code === ColumnIds.CARRIER) {selected = true; }
        if (column.code === ColumnIds.FULL_EMPTY) {selected = true; }
        if (column.code === ColumnIds.POR) {selected = true; }
        if (column.code === ColumnIds.POL) {selected = true; }
        if (column.code === ColumnIds.POD) {selected = true; }
        if (column.code === ColumnIds.NPOD) {selected = true; }
        if (column.code === ColumnIds.FPOD) {selected = true; }
        if (column.code === ColumnIds.OPT_POD) {selected = true; }
        if (column.code === ColumnIds.OPT_POD1) {selected = true; }
        if (column.code === ColumnIds.OPT_POD2) {selected = true; }
        if (column.code === ColumnIds.OPT_POD3) {selected = true; }
        if (column.code === ColumnIds.OPT_POD4) {selected = true; }
        if (column.code === ColumnIds.OPT_POD5) {selected = true; }
        if (column.code === ColumnIds.TS_PORT) {selected = true; }
        if (column.code === ColumnIds.PLACE_OF_DELIVERY) {selected = true; }
        if (column.code === ColumnIds.GROSS_WEIGHT) {selected = false; }
        if (column.code === ColumnIds.VGM_WEIGHT) {selected = false; }
        if (column.code === ColumnIds.VGM_CERTI_CODE) {selected = true; }
        if (column.code === ColumnIds.VGM_INFO1) {selected = true; }
        if (column.code === ColumnIds.VGM_INFO2) {selected = true; }
        if (column.code === ColumnIds.VGM_INFO3) {selected = true; }
        if (column.code === ColumnIds.VGM_INFO4) {selected = true; }
        if (column.code === ColumnIds.VGM_INFO5) {selected = true; }
        if (column.code === ColumnIds.TEMPERATURE) {selected = false; }
        if (column.code === ColumnIds.HANDLING_INSTRUCTION) {selected = true; }
        if (column.code === ColumnIds.LOADING_REMARK) {selected = true; }
        if (column.code === ColumnIds.SPECIAL_CARGO_INSTRUCTION) {selected = true; }
        if (column.code === ColumnIds.MUTUALLY_DEFINED_REMARK) {selected = true; }
        if (column.code === ColumnIds.GROUP_CODE) {selected = true; }
        if (column.code === ColumnIds.GENERAL_INFORMATION) {selected = true; }
        if (column.code === ColumnIds.DESCRIPTION_OF_GOODS) {selected = true; }
        if (column.code === ColumnIds.POR_TERMINAL) {selected = true; }
        if (column.code === ColumnIds.POL_TERMINAL) {selected = true; }
        if (column.code === ColumnIds.POD_TERMINAL) {selected = true; }
        if (column.code === ColumnIds.NPOD_TERMINAL) {selected = true; }
        if (column.code === ColumnIds.FPOD_TERMINAL) {selected = true; }
        if (column.code === ColumnIds.OPT_POD_TERMINAL) {selected = true; }
        if (column.code === ColumnIds.OPT_POD1_TERMINAL) {selected = true; }
        if (column.code === ColumnIds.OPT_POD2_TERMINAL) {selected = true; }
        if (column.code === ColumnIds.OPT_POD3_TERMINAL) {selected = true; }
        if (column.code === ColumnIds.OPT_POD4_TERMINAL) {selected = true; }
        if (column.code === ColumnIds.OPT_POD5_TERMINAL) {selected = true; }
        if (column.code === ColumnIds.TS_TERMINAL) {selected = true; }
        if (column.code === ColumnIds.PLACE_OF_DELIVERY_TERMINAL) {selected = true; }

        const value = ColumnUtils.getValue(column.code, container);
        if (value && value !== 'N/A') {
          list.push({code: column.code, name: ColumnUtils.getDefaultFullName(column.code), value, formattedValue: ColumnUtils.getValueFormatted(column.code, container), selected});
        }
      });

      this.dataSource = new MatTableDataSource<Property>(list);
      this.cdr.detectChanges();
    });
  }
  private trim(property: any): string {
    if (property) {
      return property;
    } else {
      return '';
    }
  }
  getBackColor(property: Property) {
    if (ColumnUtils.isPortColumn(property.code)) {
      const codeMap = this.codeService.getOwnPortMap();

      if (codeMap) {
        const code = codeMap.get(property.value);

        if (code) {

          return code.backColor;
        }
      }
    }
    if (property.code === ColumnIds.CARRIER) {
      const codeMap = this.codeService.getOwnOperatorMap();

      if (codeMap) {
        const code = codeMap.get(property.value);

        if (code) {
          return code.backColor;
        }
      }
    }
    return '';
  }

  getForeColor(property: Property) {
    if (ColumnUtils.isPortColumn(property.code)) {
      const codeMap = this.codeService.getOwnPortMap();

      if (codeMap) {
        const code = codeMap.get(property.value);

        if (code) {

          return code.foreColor;
        }
      }
    }
    if (property.code === ColumnIds.CARRIER) {
      const codeMap = this.codeService.getOwnOperatorMap();

      if (codeMap) {
        const code = codeMap.get(property.value);

        if (code) {
          return code.foreColor;
        }
      }
    }
    return '';
  }

  getStyleClass(property: Property) {
    if (ColumnUtils.isPortColumn(property.code)) {
      return 'port-label';
    } else if (property.code === ColumnIds.CARRIER) {
      return 'operator-label';
    }
  }
}
export class Property {
  constructor(public code, public name, public value, public formattedValue, public selected: boolean, public filterable?: boolean) {
  }
}
