import {ColumnIds} from './column-ids';
import {Container} from '../../../../service/model/container';
import {StringUtils} from '../../../../utils/string-utils';
import {Freetext} from '../../../../my-plans/service/model/freetext';

export enum OogDimensions {
  FRONT = 'front',
  BACK = 'back',
  RIGHT = 'right',
  LEFT = 'left',
  HEIGHT = 'height'
}

export enum BbkDimensions {
  LENGTH= 'Length',
  HEIGHT = 'Height',
  WIDTH = 'Width'
}
export enum BundleContainers {
  CONTAINER2 = 'Container 2',
  CONTAINER3 = 'Container 3',
  CONTAINER4 = 'Container 4',
  CONTAINER5 = 'Container 5',
}

export class ColumnUtils {

  static getDefaultShortName(id: string): string {

    if (id === ColumnIds.CONTAINER_NO) {
      return 'Container No';
    } else if (id === ColumnIds.ISO_SIZE_TYPE) {
      return 'ISO';
    } else if (id === ColumnIds.CONTAINER_LENGTH) {
      return 'Length';
    } else if (id === ColumnIds.CONTAINER_HEIGHT) {
      return 'Height';
    } else if (id === ColumnIds.CONTAINER_TYPE) {
      return 'Type';
    } else if (id === ColumnIds.FULL_EMPTY) {
      return 'F/M';
    } else if (id === ColumnIds.POR) {
      return 'POR';
    } else if (id === ColumnIds.POL) {
      return 'POL';
    } else if (id === ColumnIds.POD) {
      return 'POD';
    } else if (id === ColumnIds.TS_PORT) {
      return 'TS Port';
    } else if (id === ColumnIds.OPT_POD1) {
      return 'OPT POD1';
    } else if (id === ColumnIds.OPT_POD2) {
      return 'OPT POD2';
    } else if (id === ColumnIds.OPT_POD3) {
      return 'OPT POD3';
    } else if (id === ColumnIds.OPT_POD4) {
      return 'OPT POD4';
    } else if (id === ColumnIds.OPT_POD5) {
      return 'OPT POD5';
    } else if (id === ColumnIds.OPT_POD) {
      return 'OPT POD';
    } else if (id === ColumnIds.NPOD) {
      return 'NPOD';
    } else if (id === ColumnIds.FPOD) {
      return 'FPOD';
    } else if (id === ColumnIds.PLACE_OF_DELIVERY) {
      return 'PoDeli.';
    } else if (id === ColumnIds.DESCRIPTION_OF_GOODS) {
      return 'Goods';
    } else if (id === ColumnIds.HANDLING_INSTRUCTION) {
      return 'Hdl Inst.';
    } else if (id === ColumnIds.LOADING_REMARK) {
      return 'Loading Remarks';
    } else if (id === ColumnIds.VGM_CERTI_CODE) {
      return 'VGM Certi.';
    } else if (id === ColumnIds.VGM_INFO) {
      return 'VGM Info';
    } else if (id === ColumnIds.VGM_INFO1) {
      return 'VGM Info1';
    } else if (id === ColumnIds.VGM_INFO2) {
      return 'VGM Info2';
    } else if (id === ColumnIds.VGM_INFO3) {
      return 'VGM Info3';
    } else if (id === ColumnIds.VGM_INFO4) {
      return 'VGM Info4';
    } else if (id === ColumnIds.VGM_INFO5) {
      return 'VGM Info5';
    } else if (id === ColumnIds.GENERAL_INFORMATION) {
      return 'General Info.';
    } else if (id === ColumnIds.GROSS_WEIGHT) {
      return 'Gross WGT';
    } else if (id === ColumnIds.VGM_WEIGHT) {
      return 'VGM';
    } else if (id === ColumnIds.OVER_DIMENSIONS) {
      return 'Over Dims';
    } else if (id === ColumnIds.OVER_SLOTS) {
      return 'Over Slots';
    } else if (id === ColumnIds.TEMPERATURE) {
      return 'Temp.';
    } else if (id === ColumnIds.TEMPERATURE_RANGE) {
      return 'Temp. Range';
    } else if (id === ColumnIds.BL_NO) {
      return 'BL';
    } else if (id === ColumnIds.BOOKING_NO) {
      return 'BKG No';
    } else if (id === ColumnIds.GROUP_CODE) {
      return 'Group Code';
    } else if (id === ColumnIds.BUNDLED_CONTAINER) {
      return 'Bundled Cntrs';
    } else if (id === ColumnIds.CARRIER) {
      return 'Carrier';
    } else if (id === ColumnIds.CARGO_NATURE) {
      return 'Commodity';
    } else if (id === ColumnIds.SPECIAL_CARGO_INSTRUCTION) {
      return 'Special Cargo Inst.';
    } else if (id === ColumnIds.MUTUALLY_DEFINED_REMARK) {
      return 'Mutually Defined Remarks';
    } else if (id === ColumnIds.BREAK_BULK) {
      return 'Break Bulk';
    } else if (id === ColumnIds.PACKAGE_QUANTITY) {
      return 'Pkg. Qty';
    } else if (id === ColumnIds.PACKAGE_TYPE) {
      return 'Pkg. Type';
    } else if (id === ColumnIds.POL_TERMINAL) {
      return 'POL TML';
    } else if (id === ColumnIds.POD_TERMINAL) {
      return 'POD TML';
    } else if (id === ColumnIds.TS_TERMINAL) {
      return 'POT TML';
    } else if (id === ColumnIds.OPT_POD1_TERMINAL) {
      return 'Opt. POD1 TML';
    } else if (id === ColumnIds.OPT_POD2_TERMINAL) {
      return 'Opt. POD2 TML';
    } else if (id === ColumnIds.OPT_POD3_TERMINAL) {
      return 'Opt. POD3 TML';
    } else if (id === ColumnIds.OPT_POD4_TERMINAL) {
      return 'Opt. POD4 TML';
    } else if (id === ColumnIds.OPT_POD5_TERMINAL) {
      return 'Opt. POD5 TML';
    } else if (id === ColumnIds.OPT_POD_TERMINAL) {
      return 'Opt.POD TML';
    } else if (id === ColumnIds.NPOD_TERMINAL) {
      return 'NPOD TML';
    } else if (id === ColumnIds.FPOD_TERMINAL) {
      return 'FPOD TML';
    } else if (id === ColumnIds.POR_TERMINAL) {
      return 'POR TML';
    } else if (id === ColumnIds.PLACE_OF_DELIVERY_TERMINAL) {
      return 'PoDeli. TML';
    } else if (id === ColumnIds.EQUIPMENT_TYPE) {
      return 'EQ Type';
    } else if (id === ColumnIds.OVER_SLOTS_YN) {
      return 'Over Slots Y/N';
    } else if (id === ColumnIds.OVER_DIMENSIONS_YN) {
      return 'Over Dims Y/N';
    } else if (id === ColumnIds.BREAK_BULK_YN) {
      return 'Break Bulk Y/N';
    } else if (id === ColumnIds.CARGO_TYPE) {
      return 'Cargo';
    } else if (id === ColumnIds.BUNDLED_CONTAINER_YN) {
      return 'Bundled Y/N';
    }
    return this.getDefaultFullName(id);
  }
  static getDefaultFullName(id: string): string {

    if (id === ColumnIds.CONTAINER_NO) {
      return 'Container No';
    } else if (id === ColumnIds.STOWAGE) {
      return 'Location';
    } else if (id === ColumnIds.ISO_SIZE_TYPE) {
      return 'ISO Size/Type';
    } else if (id === ColumnIds.CONTAINER_LENGTH) {
      return 'Container Length';
    } else if (id === ColumnIds.CONTAINER_HEIGHT) {
      return 'Container Height';
    } else if (id === ColumnIds.CONTAINER_TYPE) {
      return 'Container Type';
    } else if (id === ColumnIds.FULL_EMPTY) {
      return 'Full Empty';
    } else if (id === ColumnIds.POL) {
      return 'Port of Loading';
    } else if (id === ColumnIds.POD) {
      return 'Port of Discharge';
    } else if (id === ColumnIds.TS_PORT) {
      return 'Transhipment Port';
    } else if (id === ColumnIds.OPT_POD1) {
      return 'Optional POD1';
    } else if (id === ColumnIds.OPT_POD2) {
      return 'Optional POD2';
    } else if (id === ColumnIds.OPT_POD3) {
      return 'Optional POD3';
    } else if (id === ColumnIds.OPT_POD4) {
      return 'Optional POD4';
    } else if (id === ColumnIds.OPT_POD5) {
      return 'Optional POD5';
    } else if (id === ColumnIds.OPT_POD) {
      return 'Optional POD';
    } else if (id === ColumnIds.NPOD) {
      return 'Next POD';
    } else if (id === ColumnIds.FPOD) {
      return 'Final POD';
    } else if (id === ColumnIds.POR) {
      return 'Port of Origin';
    } else if (id === ColumnIds.PLACE_OF_DELIVERY) {
      return 'Place of Delivery';
    } else if (id === ColumnIds.DESCRIPTION_OF_GOODS) {
      return 'Goods description';
    } else if (id === ColumnIds.HANDLING_INSTRUCTION) {
      return 'Handling Instructions';
    } else if (id === ColumnIds.LOADING_REMARK) {
      return 'Loading Remarks';
    } else if (id === ColumnIds.VGM_CERTI_CODE) {
      return 'VGM Certification Code';
    } else if (id === ColumnIds.VGM_INFO) {
      return 'VGM Information';
    } else if (id === ColumnIds.VGM_INFO1) {
      return 'VGM Information 1';
    } else if (id === ColumnIds.VGM_INFO2) {
      return 'VGM Information 2';
    } else if (id === ColumnIds.VGM_INFO3) {
      return 'VGM Information 3';
    } else if (id === ColumnIds.VGM_INFO4) {
      return 'VGM Information 4';
    } else if (id === ColumnIds.VGM_INFO5) {
      return 'VGM Information 5';
    } else if (id === ColumnIds.GENERAL_INFORMATION) {
      return 'General Information';
    } else if (id === ColumnIds.GROSS_WEIGHT) {
      return 'Gross Weight';
    } else if (id === ColumnIds.VGM_WEIGHT) {
      return 'VGM';
    } else if (id === ColumnIds.OVER_DIMENSIONS) {
      return 'Over Dimensions';
    } else if (id === ColumnIds.OVER_DIMENSION_FRONT) {
      return 'Over Dimension Front';
    } else if (id === ColumnIds.OVER_DIMENSION_BACK) {
      return 'Over Dimension Back';
    } else if (id === ColumnIds.OVER_DIMENSION_RIGHT) {
      return 'Over Dimension Right';
    } else if (id === ColumnIds.OVER_DIMENSION_LEFT) {
      return 'Over Dimension Left';
    } else if (id === ColumnIds.OVER_DIMENSION_HEIGHT) {
      return 'Over Dimension Height';
    } else if (id === ColumnIds.OVER_SLOTS) {
      return 'Over Slots';

      //FIXME over slots left right...
    } else if (id === ColumnIds.REEFER) {
      return 'Reefer';
    } else if (id === ColumnIds.TEMPERATURE) {
      return 'Temperature';
    } else if (id === ColumnIds.TEMPERATURE_RANGE) {
      return 'Temperature Range';
    } else if (id === ColumnIds.BL_NO) {
      return 'Bill of Lading';
    } else if (id === ColumnIds.BOOKING_NO) {
      return 'Booking No';
    } else if (id === ColumnIds.GROUP_CODE) {
      return 'Group Code';
    } else if (id === ColumnIds.BUNDLED_CONTAINER) {
      return 'Bundled Containers';
    } else if (id === ColumnIds.IMDG) {
      return 'IMDG';
    } else if (id === ColumnIds.UNNO) {
      return 'UNNO';
    } else if (id === ColumnIds.CARRIER) {
      return 'Carrier';
    } else if (id === ColumnIds.CARGO_NATURE) {
      return 'Nature of Cargo/Commodity';
    } else if (id === ColumnIds.SPECIAL_CARGO_INSTRUCTION) {
      return 'Special Cargo Instructions';
    } else if (id === ColumnIds.MUTUALLY_DEFINED_REMARK) {
      return 'Mutually Defined Remarks';
    } else if (id === ColumnIds.BREAK_BULK) {
      return 'Break Bulk';
    } else if (id === ColumnIds.PACKAGE_QUANTITY) {
      return 'Package Quantity';
    } else if (id === ColumnIds.PACKAGE_TYPE) {
      return 'Package Type';
    } else if (id === ColumnIds.POL_TERMINAL) {
      return 'POL Terminal';
    } else if (id === ColumnIds.POD_TERMINAL) {
      return 'POD Terminal';
    } else if (id === ColumnIds.TS_TERMINAL) {
      return 'Transhipment Terminal';
    } else if (id === ColumnIds.OPT_POD1_TERMINAL) {
      return 'Optional POD1 Terminal';
    } else if (id === ColumnIds.OPT_POD2_TERMINAL) {
      return 'Optional POD2 Terminal';
    } else if (id === ColumnIds.OPT_POD3_TERMINAL) {
      return 'Optional POD3 Terminal';
    } else if (id === ColumnIds.OPT_POD4_TERMINAL) {
      return 'Optional POD4 Terminal';
    } else if (id === ColumnIds.OPT_POD5_TERMINAL) {
      return 'Optional POD5 Terminal';
    } else if (id === ColumnIds.OPT_POD_TERMINAL) {
      return 'Optional POD Terminal';
    } else if (id === ColumnIds.NPOD_TERMINAL) {
      return 'Next POD Terminal';
    } else if (id === ColumnIds.FPOD_TERMINAL) {
      return 'Final POD Terminal';
    } else if (id === ColumnIds.POR_TERMINAL) {
      return 'POR Terminal';
    } else if (id === ColumnIds.PLACE_OF_DELIVERY_TERMINAL) {
      return 'Place of Delivery Terminal';
    } else if (id === ColumnIds.EQUIPMENT_TYPE) {
      return 'Equipment Type';
    } else if (id === ColumnIds.OVER_SLOTS_YN) {
      return 'Over Slot Y/N';
    } else if (id === ColumnIds.OVER_DIMENSIONS_YN) {
      return 'Over Dimensions Y/N';
    } else if (id === ColumnIds.BREAK_BULK_YN) {
      return 'Break Bulk Y/N';
    } else if (id === ColumnIds.CARGO_TYPE) {
      return 'Cargo Type';
    } else if (id === ColumnIds.BUNDLED_CONTAINER_YN) {
      return 'Bundled Containers Y/N';
    } else if (id === ColumnIds.VGM_WEIGHT_YN) {
      return 'VGM Y/N';
    } else if (id === ColumnIds.GENERAL_INFORMATION_YN) {
      return 'General Information Y/N';
    } else if (id === ColumnIds.LOADING_REMARK_YN) {
      return 'Loading Remarks Y/N';
    } else if (id === ColumnIds.CARGO_NATURE_YN) {
      return 'Cargo Nature Y/N';
    } else if (id === ColumnIds.DESCRIPTION_OF_GOODS_YN) {
      return 'Description Of Goods Y/N';
    } else if (id === ColumnIds.MUTUALLY_DEFINED_REMARK_YN) {
      return 'Mutually Defined Remarks Y/N';
    } else if (id === ColumnIds.PACKAGE_QUANTITY_YN) {
      return 'Package Quantity Y/N';
    } else if (id === ColumnIds.PACKAGE_TYPE_YN) {
      return 'Package Type Y/N';
    } else if (id === ColumnIds.GROUP_CODE_YN) {
      return 'Group Code Y/N';
    } else if (id === ColumnIds.BL_NO) {
      return 'BL Y/N';
    } else if (id === ColumnIds.BOOKING_NO_YN) {
      return 'Booking No Y/N';
    } else if (id === ColumnIds.TEMPERATURE_RANGE_YN) {
      return 'Temperature Range Y/N';
    } else if (id === ColumnIds.HATCH) {
      return 'Bay';
    }

    return id;
  }
  static getValue(id: string, cntr: Container, hatches?: string[]): string {

    let value = '';
    if (id === ColumnIds.OVER_DIMENSIONS
      || id === ColumnIds.OVER_SLOTS
      || id === ColumnIds.IMDG
      || id === ColumnIds.BUNDLED_CONTAINER
      || id === ColumnIds.BREAK_BULK
      || id === ColumnIds.TEMPERATURE_RANGE
      || id === ColumnIds.CARGO_NATURE

    ) {
      return '';
    } else if (id === ColumnIds.STOWAGE) {
      value = cntr.stowage;
    } else if (id === ColumnIds.CONTAINER_NO) {
      value = cntr.containerNo;
    } else if (id === ColumnIds.CARRIER) {
      value = cntr.carrier;
    } else if (id === ColumnIds.ISO_SIZE_TYPE) {
      value = cntr.isoSizeType;
    } else if (id === ColumnIds.FULL_EMPTY) {
      value = cntr.fullEmpty;
    } else if (id === ColumnIds.POR) {
      value = cntr.por;
    } else if (id === ColumnIds.POL) {
      value = cntr.pol;
    } else if (id === ColumnIds.POD) {
      value = cntr.pod;
    } else if (id === ColumnIds.NPOD) {
      value = cntr.nPod;
    } else if (id === ColumnIds.FPOD) {
      value = cntr.fPod;
    } else if (id === ColumnIds.OPT_POD) {
      value = cntr.optPod;
    } else if (id === ColumnIds.OPT_POD1) {
      value = cntr.optPod1;
    } else if (id === ColumnIds.OPT_POD2) {
      value = cntr.optPod2;
    } else if (id === ColumnIds.OPT_POD3) {
      value = cntr.optPod3;
    } else if (id === ColumnIds.OPT_POD4) {
      value = cntr.optPod4;
    } else if (id === ColumnIds.OPT_POD5) {
      value = cntr.optPod5;
    } else if (id === ColumnIds.TS_PORT) {
      value = cntr.tsPort;
    } else if (id === ColumnIds.PLACE_OF_DELIVERY) {
      value = cntr.placeOfDelivery;
    } else if (id === ColumnIds.GROSS_WEIGHT) {
      value = cntr.grossWeight;
    } else if (id === ColumnIds.GROSS_WEIGHT_UNIT) {
      value = cntr.grossWeightUnit;
    } else if (id === ColumnIds.VGM_WEIGHT) {
      value = cntr.vgmWeight;
    } else if (id === ColumnIds.VGM_WEIGHT_UNIT) {
      value = cntr.vgmWeightUnit;
    } else if (id === ColumnIds.VGM_CERTI_CODE) {
      value = this.getFirstRemarks(cntr, id);
    } else if (id === ColumnIds.VGM_INFO) {
      value = this.getFirstVgmInfo(cntr);
    } else if (id === ColumnIds.TEMPERATURE) {
      value = cntr.temperature;
    } else if (id === ColumnIds.TEMPERATURE_UNIT) {
      value = cntr.temperatureUnit;
    } else if (id === ColumnIds.TEMPERATURE_RANGE_MIM) {
      value = cntr.temperatureRangeMin;
    } else if (id === ColumnIds.TEMPERATURE_RANGE_MAX) {
      value = cntr.temperatureRangeMax;
    } else if (id === ColumnIds.TEMPERATURE_RANGE_UNIT) {
      value = cntr.temperatureRangeUnit;
    } else if (id === ColumnIds.BL_NO) {
      value = cntr.blNo;
    } else if (id === ColumnIds.BOOKING_NO) {
      value = cntr.bookingNo;
    } else if (id === ColumnIds.HANDLING_INSTRUCTION
      || id === ColumnIds.DESCRIPTION_OF_GOODS
      || id === ColumnIds.LOADING_REMARK
      || id === ColumnIds.SPECIAL_CARGO_INSTRUCTION
      || id === ColumnIds.GENERAL_INFORMATION
      || id === ColumnIds.MUTUALLY_DEFINED_REMARK) {
      value = this.getFirstRemarks(cntr, id);
    } else if (id === ColumnIds.BREAK_BULK_LENGTH) {
      value = cntr.breakBulkLength;
    } else if (id === ColumnIds.BREAK_BULK_HEIGHT) {
      value = cntr.breakBulkHeight;
    } else if (id === ColumnIds.BREAK_BULK_WIDTH) {
      value = cntr.breakBulkWidth;
    } else if (id === ColumnIds.BREAK_BULK_UNIT) {
      value = cntr.breakBulkDimensionUnit;
    } else if (id === ColumnIds.PACKAGE_QUANTITY) {
      value = cntr.packageQuantity;
    } else if (id === ColumnIds.PACKAGE_TYPE) {
      value = cntr.packageType;
    } else if (id === ColumnIds.GROUP_CODE) {
      value = cntr.groupCode;
    } else if (id === ColumnIds.POR_TERMINAL) {
      value = cntr.porTerminalCode;
    } else if (id === ColumnIds.POL_TERMINAL) {
      value = cntr.polTerminalCode;
    } else if (id === ColumnIds.POD_TERMINAL) {
      value = cntr.podTerminalCode;
    } else if (id === ColumnIds.NPOD_TERMINAL) {
      value = cntr.nPodTerminalCode;
    } else if (id === ColumnIds.FPOD_TERMINAL) {
      value = cntr.fPodTerminalCode;
    } else if (id === ColumnIds.OPT_POD_TERMINAL) {
      value = cntr.optPodTerminalCode;
    } else if (id === ColumnIds.OPT_POD1_TERMINAL) {
      value = cntr.optPod1TerminalCode;
    } else if (id === ColumnIds.OPT_POD2_TERMINAL) {
      value = cntr.optPod2TerminalCode;
    } else if (id === ColumnIds.OPT_POD3_TERMINAL) {
      value = cntr.optPod3TerminalCode;
    } else if (id === ColumnIds.OPT_POD4_TERMINAL) {
      value = cntr.optPod4TerminalCode;
    } else if (id === ColumnIds.OPT_POD5_TERMINAL) {
      value = cntr.optPod5TerminalCode;
    } else if (id === ColumnIds.TS_TERMINAL) {
      value = cntr.tsTerminalCode;
    } else if (id === ColumnIds.PLACE_OF_DELIVERY_TERMINAL) {
      value = cntr.placeOfDeliveryTerminalCode;
    } else if (id === ColumnIds.EQUIPMENT_TYPE) {
      value = cntr.equipmentType;
    } else if (id === ColumnIds.CARGO_TYPE) {
      value = cntr.getCargoType();
    } else if (id === ColumnIds.CARGO_TYPE_CODE) {
      value = cntr.getCargoTypeCode();
    } else if (id === ColumnIds.HATCH) {
      if (hatches && cntr.stowage && cntr.stowage.length >= 3) {
        for (let i = 0 ; i < hatches.length; i ++ ) {
          if (hatches[i].includes(cntr.stowage.substring(0, 3))) {
            return hatches[i];
          }
        }
        return '';
      }
    }
    return this.checkNull(value);
  }
  static getValueFormatted(id: string, cntr: Container): string {

    if (!cntr) {
      return '';
    }

    try {

      if (id === ColumnIds.CONTAINER_NO) {
        let value = '';
        if (cntr.containerNo) {
          // if (cntr.containerNo.length > 4) {
          //   value = cntr.containerNo.substring(0, 4) + ' ' + cntr.containerNo.substring(4);
          // } else {
          //   value = cntr.containerNo;
          // }
          value = cntr.containerNo;
        }
        return this.checkNull(value);
      } else if (id === ColumnIds.STOWAGE) {
        // if (cntr.stowage && cntr.stowage.length === 7) {
        //   return cntr.stowage.substring(0, 3) + ' ' + cntr.stowage.substring(3, 5) + ' ' + cntr.stowage.substring(5, 7);
        // }
        return this.checkNull(cntr.stowage);
      } else if (id === ColumnIds.ISO_SIZE_TYPE) {
        return this.checkNull(cntr.isoSizeType);

      } else if (id === ColumnIds.FULL_EMPTY) {
        return cntr.getFullEmpty();

      } else if (id === ColumnIds.GROSS_WEIGHT) {
        if (cntr.grossWeight) {

          let unit = this.checkNull(cntr.grossWeightUnit);

          if (unit.length >= 2) {
            unit = unit.toLowerCase().substring(0, 2);
          }
          return StringUtils.formatNumber(cntr.grossWeight) + ' ' + unit;
        }
        return '';
      } else if (id === ColumnIds.VGM_WEIGHT) {
        if (cntr.vgmWeight) {

          let unit = this.checkNull(cntr.vgmWeightUnit);
          if (unit.length >= 2) {
            unit = unit.toLowerCase().substring(0, 2);
          }
          return StringUtils.formatNumber(cntr.vgmWeight) + ' ' + unit;
        }
        return '';
      } else if (id === ColumnIds.TEMPERATURE) {
        if (cntr.temperature) {

          let unit = this.checkNull(cntr.temperatureUnit);

          if (unit.length >= 1) {
            unit = unit.toUpperCase().substring(0, 1);
          }
          return this.checkNull(cntr.temperature) + ' ' + unit;
        }
        return '';
      } else if (id === ColumnIds.TEMPERATURE_RANGE) {

        if (cntr.temperatureRangeMin || cntr.temperatureRangeMax) {
          return this.checkNull(cntr.temperatureRangeMin) + '~' + this.checkNull(cntr.temperatureRangeMax) + ' ' + this.checkNull(cntr.temperatureRangeUnit);
        }
        return '';
      } else if (id === ColumnIds.BUNDLED_CONTAINER) {

        if (cntr.bundledContainers) {
          return cntr.bundledContainers.join(', ');
        }
        return '';
      } else if (id === ColumnIds.IMDG) {

        if (cntr.dangerousGoods) {
          let dg = '';
          cntr.dangerousGoods.forEach(data => {
            dg = this.checkNull(data.imdg) + ',';
          });
          if (dg.substring(dg.length - 1, dg.length) === ',') {
            dg = dg.substring(0, dg.length - 1);
          }
          return this.checkNull(dg);
        }
        return '';
      } else if (id === ColumnIds.UNNO) {

        if (cntr.dangerousGoods) {
          let dg = '';
          cntr.dangerousGoods.forEach(data => {
            dg = this.checkNull(data.unno) + ',';
          });
          if (dg.substring(dg.length - 1, dg.length) === ',') {
            dg = dg.substring(0, dg.length - 1);
          }
          return this.checkNull(dg);
        }
        return '';
      } else if (id === ColumnIds.BREAK_BULK) {

        if (cntr.breakBulkLength || cntr.breakBulkHeight || cntr.breakBulkWidth || cntr.breakBulkDimensionUnit) {
          return this.checkNull(cntr.breakBulkLength)
            + '/' + this.checkNull(cntr.breakBulkHeight)
            + '/' + this.checkNull(cntr.breakBulkWidth)
            + ' ' + this.checkNull(cntr.breakBulkDimensionUnit);
        }
        return '';
      } else if (id === ColumnIds.OVER_DIMENSIONS) {

        if (cntr.overDimFront || cntr.overDimBack || cntr.overDimRight || cntr.overDimLeft || cntr.overDimHeight) {
          let unit = cntr.overDimFrontUnit ? cntr.overDimFrontUnit
            : (cntr.overDimBackUnit ? cntr.overDimBackUnit
              : (cntr.overDimRightUnit ? cntr.overDimRightUnit
                : (cntr.overDimLeftUnit ? cntr.overDimLeftUnit
                  : (cntr.overDimHeightUnit ? cntr.overDimHeightUnit : ''))));

          if (unit.length >= 2) {
            unit = unit.toLowerCase().substring(0, 2);
          }

          return this.checkNull(cntr.overDimFront)
            + '/' + this.checkNull(cntr.overDimBack)
            + '/' + this.checkNull(cntr.overDimRight)
            + '/' + this.checkNull(cntr.overDimLeft)
            + '/' + this.checkNull(cntr.overDimHeight)
            + ' ' + unit;
        }
        return '';
      } else if (id === ColumnIds.OVER_SLOTS) {
        if (cntr.overSlotFront || cntr.overSlotBack || cntr.overSlotLeft || cntr.overSlotRight || cntr.overSlotHeight) {
          return this.checkNull(cntr.overSlotFront)
            + '/' + this.checkNull(cntr.overSlotBack)
            + '/' + this.checkNull(cntr.overSlotRight)
            + '/' + this.checkNull(cntr.overSlotLeft)
            + '/' + this.checkNull(cntr.overSlotHeight);
        }
      } else if (id === ColumnIds.CARGO_NATURE) {
        if (cntr.cargoNatures) {
          return cntr.cargoNatures.join(', ');
        }
        return '';

      } else if (id === ColumnIds.OPT_POD) {
        let value = this.checkNull(cntr.optPod);
        if (cntr.optPod1) {
          if (value) {value = value + ','; }
          value = value + cntr.optPod1;
        }
        if (cntr.optPod2) {
          if (value) {value = value + ','; }
          value = value + cntr.optPod2;
        }
        if (cntr.optPod3) {
          if (value) {value = value + ','; }
          value = value + cntr.optPod3;
        }
        if (cntr.optPod4) {
          if (value) {value = value + ','; }
          value = value + cntr.optPod4;
        }
        if (cntr.optPod5) {
          if (value) {value = value + ','; }
          value = value + cntr.optPod5;
        }
        return value;
      } else if (id === ColumnIds.OPT_POD_TERMINAL) {
        let value = this.checkNull(cntr.optPodTerminalCode);
        if (cntr.optPod1TerminalCode) {
          if (value) {value = value + ','; }
          value = value + cntr.optPod1TerminalCode;
        }
        if (cntr.optPod2TerminalCode) {
          if (value) {value = value + ','; }
          value = value + cntr.optPod2TerminalCode;
        }
        if (cntr.optPod3TerminalCode) {
          if (value) {value = value + ','; }
          value = value + cntr.optPod3TerminalCode;
        }
        if (cntr.optPod4TerminalCode) {
          if (value) {value = value + ','; }
          value = value + cntr.optPod4TerminalCode;
        }
        if (cntr.optPod5TerminalCode) {
          if (value) {value = value + ','; }
          value = value + cntr.optPod5TerminalCode;
        }
        return value;
      } else if (id === ColumnIds.DESCRIPTION_OF_GOODS
        || id === ColumnIds.HANDLING_INSTRUCTION
        || id === ColumnIds.LOADING_REMARK
        || id === ColumnIds.SPECIAL_CARGO_INSTRUCTION
        || id === ColumnIds.GENERAL_INFORMATION
        || id === ColumnIds.VGM_CERTI_CODE
        || id === ColumnIds.MUTUALLY_DEFINED_REMARK) {

        return this.getRemarksAppended(cntr, id);

      } else if (id === ColumnIds.VGM_INFO) {
        const list = cntr.freeTexts.get('AAY') as Array<Freetext>;

        if (list) {

          let text = '';

          for (let i = 0; i < list.length; i++) {

            if (i > 0) {
              text = text + '+';
            }
            let vgmInfo = list[i].vgmInfo5;

            if (vgmInfo) {
              vgmInfo = ':' + vgmInfo;
            }

            if (list[i].vgmInfo4) {
              vgmInfo = list[i].vgmInfo4 + vgmInfo;
            }
            if (vgmInfo) {
              vgmInfo = ':' + vgmInfo;
            }
            if (list[i].vgmInfo3) {
              vgmInfo = list[i].vgmInfo3 + vgmInfo;
            }
            if (vgmInfo) {
              vgmInfo = ':' + vgmInfo;
            }
            if (list[i].vgmInfo2) {
              vgmInfo = list[0].vgmInfo2 + vgmInfo;
            }
            if (vgmInfo) {
              vgmInfo = ':' + vgmInfo;
            }
            if (list[i].remark) {
              vgmInfo = list[i].remark + vgmInfo;
            }
            text = text + vgmInfo;
          }
          return text;
        }
      }
    } catch (error) {
      console.error(error);
    }

    return this.getValue(id, cntr);

  }

  static getContainerValueForExport(id: string, cntr: Container, separator: string): string {

    if (id === ColumnIds.GROSS_WEIGHT) {
      return this.checkNull(cntr.grossWeight) + separator + this.checkNull(cntr.grossWeightUnit);
    }
    if (id === ColumnIds.VGM_WEIGHT) {
      return this.checkNull(cntr.vgmWeight) + separator + this.checkNull(cntr.vgmWeightUnit);
    }
    if (id === ColumnIds.TEMPERATURE) {
      return this.checkNull(cntr.temperature) + separator + this.checkNull(cntr.temperatureUnit);
    }
    if (id === ColumnIds.TEMPERATURE_RANGE) {
      return this.checkNull(cntr.temperatureRangeMin) + separator + this.checkNull(cntr.temperatureRangeMax) + this.checkNull(cntr.temperatureRangeUnit);
    }
    if (id === ColumnIds.OVER_DIMENSIONS) {
      return this.checkNull(cntr.overDimFront) + separator + this.checkNull(cntr.overDimFrontUnit)
        + this.checkNull(cntr.overDimBack) + separator + this.checkNull(cntr.overDimBackUnit)
        + this.checkNull(cntr.overDimRight) + separator + this.checkNull(cntr.overDimRightUnit)
        + this.checkNull(cntr.overDimLeft) + separator + this.checkNull(cntr.overDimLeftUnit)
        + this.checkNull(cntr.overDimHeight) + separator + this.checkNull(cntr.overDimHeightUnit)
        ;
    }
    return this.getValueFormatted(id, cntr);
  }
  private static checkNull(value: any): string {
    if (value) {
      return value;
    } else {
      return '';
    }
  }
  static getToolTip(code: string) {

    const toolTip = ColumnUtils.getDefaultFullName(code);

    if (code === ColumnIds.OVER_SLOTS || code === ColumnIds.OVER_DIMENSIONS) {

      return toolTip + ' (front / back / right / left / height)';
    }
    if (code === ColumnIds.BREAK_BULK) {
      return toolTip + ' (length / height/ width)';
    }

    if (code === ColumnIds.CARGO_TYPE) {

      return toolTip + ': Reefer, Dangerous, Reefer Dangerous, Empty Dangerous, Empty or Other (full)';
    }
    return toolTip;
  }
  static isPortColumn(code) {
    return code === ColumnIds.POR
      || code === ColumnIds.POL
      || code === ColumnIds.POD
      || code === ColumnIds.TS_PORT
      // || code === ColumnIds.OPT_POD1
      // || code === ColumnIds.OPT_POD2
      // || code === ColumnIds.OPT_POD3
      // || code === ColumnIds.OPT_POD4
      // || code === ColumnIds.OPT_POD5
      || code === ColumnIds.NPOD
      || code === ColumnIds.FPOD
      || code === ColumnIds.PLACE_OF_DELIVERY;
  }
  static getPortCode(container: Container, columnCode: string) {
    let key = '';

    if (columnCode === ColumnIds.POL) {
      key = container.pol;
    } else if (columnCode === ColumnIds.POD) {
      key = container.pod;
    } else if (columnCode === ColumnIds.TS_PORT) {
      key = container.tsPort;
    } else if (columnCode === ColumnIds.POR) {
      key = container.por;
    } else if (columnCode === ColumnIds.OPT_POD1) {
      key = container.optPod1;
    } else if (columnCode === ColumnIds.OPT_POD2) {
      key = container.optPod2;
    } else if (columnCode === ColumnIds.OPT_POD3) {
      key = container.optPod3;
    } else if (columnCode === ColumnIds.OPT_POD4) {
      key = container.optPod4;
    } else if (columnCode === ColumnIds.OPT_POD5) {
      key = container.optPod5;
    } else if (columnCode === ColumnIds.NPOD) {
      key = container.nPod;
    } else if (columnCode === ColumnIds.FPOD) {
      key = container.fPod;
    } else if (columnCode === ColumnIds.PLACE_OF_DELIVERY) {
      key = container.placeOfDelivery;
    }
    return key;
  }
  static setValue(cntr: Container, id: string, value: any): boolean {
    if (id === ColumnIds.CONTAINER_NO) {
      cntr.containerNo = value;
      return true;
    } else if (id === ColumnIds.STOWAGE) {
      cntr.stowage = value;
      return true;
    } else if (id === ColumnIds.ISO_SIZE_TYPE) {
      cntr.isoSizeType = value;
      return true;
    } else if (id === ColumnIds.FULL_EMPTY) {
      cntr.fullEmpty = value;
      return true;
    } else if (id === ColumnIds.POL) {
      cntr.pol = value;
      return true;
    } else if (id === ColumnIds.POD) {
      cntr.pod = value;
      return true;
    } else if (id === ColumnIds.TS_PORT) {
      cntr.tsPort = value;
      return true;
    } else if (id === ColumnIds.OPT_POD1) {
      cntr.optPod1 = value;
      return true;
    } else if (id === ColumnIds.OPT_POD2) {
      cntr.optPod2 = value;
      return true;
    } else if (id === ColumnIds.OPT_POD3) {
      cntr.optPod3 = value;
      return true;
    } else if (id === ColumnIds.OPT_POD4) {
      cntr.optPod4 = value;
      return true;
    } else if (id === ColumnIds.OPT_POD5) {
      cntr.optPod5 = value;
      return true;
    } else if (id === ColumnIds.OPT_POD) {
      cntr.optPod = value;
      return true;
    } else if (id === ColumnIds.NPOD) {
      cntr.nPod = value;
      return true;
    } else if (id === ColumnIds.FPOD) {
      cntr.fPod = value;
      return true;
    } else if (id === ColumnIds.POR) {
      cntr.por = value;
      return true;
    } else if (id === ColumnIds.PLACE_OF_DELIVERY) {
      cntr.placeOfDelivery = value;
      return true;
    } else if (id === ColumnIds.DESCRIPTION_OF_GOODS
      || id === ColumnIds.HANDLING_INSTRUCTION
      || id === ColumnIds.LOADING_REMARK
      || id === ColumnIds.GENERAL_INFORMATION
      || id === ColumnIds.SPECIAL_CARGO_INSTRUCTION
      || id === ColumnIds.VGM_CERTI_CODE
      || id === ColumnIds.MUTUALLY_DEFINED_REMARK
    ) {
      return this.setRemark(cntr, id, value);
    } else if (id === ColumnIds.VGM_INFO1) {
      return this.setVgmInfo(cntr, value, 1);
    } else if (id === ColumnIds.VGM_INFO2) {
      return this.setVgmInfo(cntr, value, 2);
    } else if (id === ColumnIds.VGM_INFO3) {
      return this.setVgmInfo(cntr, value, 3);
    } else if (id === ColumnIds.VGM_INFO4) {
      return this.setVgmInfo(cntr, value, 4);
    } else if (id === ColumnIds.VGM_INFO5) {
      return this.setVgmInfo(cntr, value, 5);
    } else if (id === ColumnIds.GROSS_WEIGHT) {
      cntr.grossWeight = value;
      return true;
    } else if (id === ColumnIds.GROSS_WEIGHT_UNIT) {
      cntr.grossWeightUnit = value;
      return true;
    } else if (id === ColumnIds.VGM_WEIGHT) {
      cntr.vgmWeight = value;
      return true;
    } else if (id === ColumnIds.VGM_WEIGHT_UNIT) {
      cntr.vgmWeightUnit = value;
      return true;
    } else if (id === ColumnIds.OVER_DIMENSION_FRONT) {
      cntr.overDimFront = value;
      return true;
    } else if (id === ColumnIds.OVER_DIMENSION_FRONT_UNIT) {
      cntr.overDimFrontUnit = value;
      return true;
    } else if (id === ColumnIds.OVER_DIMENSION_BACK) {
      cntr.overDimBack = value;
      return true;
    } else if (id === ColumnIds.OVER_DIMENSION_BACK_UNIT) {
      cntr.overDimBackUnit = value;
      return true;
    } else if (id === ColumnIds.OVER_DIMENSION_RIGHT) {
      cntr.overDimRight = value;
      return true;
    } else if (id === ColumnIds.OVER_DIMENSION_RIGHT_UNIT) {
      cntr.overDimRightUnit = value;
      return true;
    } else if (id === ColumnIds.OVER_DIMENSION_LEFT) {
      cntr.overDimLeft = value;
      return true;
    } else if (id === ColumnIds.OVER_DIMENSION_LEFT_UNIT) {
      cntr.overDimLeftUnit = value;
      return true;
    } else if (id === ColumnIds.OVER_DIMENSION_HEIGHT) {
      cntr.overDimHeight = value;
      return true;
    } else if (id === ColumnIds.OVER_DIMENSION_HEIGHT_UNIT) {
      cntr.overDimHeightUnit = value;
      return true;
    } else if (id === ColumnIds.TEMPERATURE) {
      cntr.temperature = value;
      return true;
    } else if (id === ColumnIds.TEMPERATURE_UNIT) {
      cntr.temperatureUnit = value;
      return true;
    } else if (id === ColumnIds.TEMPERATURE_RANGE_MIM) {
      cntr.temperatureRangeMin = value;
      return true;
    } else if (id === ColumnIds.TEMPERATURE_RANGE_MAX) {
      cntr.temperatureRangeMax = value;
      return true;
    } else if (id === ColumnIds.TEMPERATURE_RANGE_UNIT) {
      cntr.temperatureRangeUnit = value;
      return true;
    } else if (id === ColumnIds.BL_NO) {
      cntr.blNo = value;
      return true;
    } else if (id === ColumnIds.BOOKING_NO) {
      cntr.bookingNo = value;
      return true;
    } else if (id === ColumnIds.GROUP_CODE) {
      cntr.groupCode = value;
      return true;
    } else if (id === ColumnIds.BUNDLED_CONTAINER2
            || id === ColumnIds.BUNDLED_CONTAINER3
            || id === ColumnIds.BUNDLED_CONTAINER4
            || id === ColumnIds.BUNDLED_CONTAINER5) {
      cntr.bundledContainers.push(value);
      return true;
    } else if (id === ColumnIds.IMDG) {

    } else if (id === ColumnIds.UNNO) {

    } else if (id === ColumnIds.CARRIER) {
      cntr.carrier = value;
      return true;
    } else if (id === ColumnIds.CARGO_NATURE) {
      // cntr.cargoNature = value;
    } else if (id === ColumnIds.BREAK_BULK_LENGTH) {
      cntr.breakBulkLength = value;
      return true;
    } else if (id === ColumnIds.BREAK_BULK_HEIGHT) {
      cntr.breakBulkHeight = value;
      return true;
    } else if (id === ColumnIds.BREAK_BULK_WIDTH) {
      cntr.breakBulkWidth = value;
      return true;
    } else if (id === ColumnIds.PACKAGE_QUANTITY) {
      cntr.packageQuantity = value;
      return true;
    } else if (id === ColumnIds.PACKAGE_TYPE) {
      cntr.packageType = value;
      return true;
    } else if (id === ColumnIds.POL_TERMINAL) {
      cntr.polTerminalCode = value;
      return true;
    } else if (id === ColumnIds.POD_TERMINAL) {
      cntr.podTerminalCode = value;
      return true;
    } else if (id === ColumnIds.TS_TERMINAL) {
      cntr.tsTerminalCode = value;
      return true;
    } else if (id === ColumnIds.OPT_POD1_TERMINAL) {
      cntr.optPod1TerminalCode = value;
      return true;
    } else if (id === ColumnIds.OPT_POD2_TERMINAL) {
      cntr.optPod2TerminalCode = value;
      return true;
    } else if (id === ColumnIds.OPT_POD3_TERMINAL) {
      cntr.optPod3TerminalCode = value;
      return true;
    } else if (id === ColumnIds.OPT_POD4_TERMINAL) {
      cntr.optPod4TerminalCode = value;
      return true;
    } else if (id === ColumnIds.OPT_POD5_TERMINAL) {
      cntr.optPod5TerminalCode = value;
      return true;
    } else if (id === ColumnIds.OPT_POD_TERMINAL) {
      cntr.optPodTerminalCode = value;
      return true;
    } else if (id === ColumnIds.NPOD_TERMINAL) {
      cntr.nPodTerminalCode = value;
      return true;
    } else if (id === ColumnIds.FPOD_TERMINAL) {
      cntr.fPodTerminalCode = value;
      return true;
    } else if (id === ColumnIds.POR_TERMINAL) {
      cntr.porTerminalCode = value;
      return true;
    } else if (id === ColumnIds.PLACE_OF_DELIVERY_TERMINAL) {
      cntr.placeOfDeliveryTerminalCode = value;
      return true;
    } else if (id === ColumnIds.EQUIPMENT_TYPE) {
      cntr.equipmentType = value;
      return true;
    }

    return false;
  }
  static getRemarksAppended(cntr: Container, code: string): string {

    let qualifier = '';
    if (code === ColumnIds.DESCRIPTION_OF_GOODS) {
      qualifier = 'AAA';
    } else if (code === ColumnIds.HANDLING_INSTRUCTION) {
      qualifier = 'HAN';
    } else if (code === ColumnIds.LOADING_REMARK) {
      qualifier = 'CLR';
    } else if (code === ColumnIds.SPECIAL_CARGO_INSTRUCTION) {
      qualifier = 'SIN';
    } else if (code === ColumnIds.GENERAL_INFORMATION) {
      qualifier = 'AAI';
    } else if (code === ColumnIds.VGM_CERTI_CODE) {
      qualifier = 'AAY';
    } else if (code === ColumnIds.MUTUALLY_DEFINED_REMARK) {
      qualifier = 'ZZZ';
    } else {
      return '';
    }

    const list = cntr.freeTexts.get(qualifier) as Array<Freetext>;

    let text = '';
    if (list && list.length > 0) {
      for (const fx of list) {

        if (text !== '') {
          text = text + '+';
        }
        if (code === ColumnIds.VGM_CERTI_CODE) {
          text = text + fx.vgmCertificationCode;
        } else {
          text = text + fx.remark;
        }
      }
    }
    return text;
  }
  static getFirstRemarks(cntr: Container, code: string): string {

    const qualifier = this.getRemarkQualifier(code);
    if (qualifier === '') {
      return '';
    }

    const list = cntr.freeTexts.get(qualifier) as Array<Freetext>;

    if (list && list.length > 0) {

      if (code === ColumnIds.VGM_CERTI_CODE) {
        return list[0].vgmCertificationCode;
      } else {
        return list[0].remark;
      }
    }
    return '';
  }
  static setRemark(cntr: Container, code: string, value: string): boolean {

    const qualifier = this.getRemarkQualifier(code);

    if (qualifier === '') {
      return false;
    }

    let list = cntr.freeTexts.get(qualifier) as Array<Freetext>;

    if (list && list.length > 0) {

      if (code === ColumnIds.VGM_CERTI_CODE) {
        list[0].vgmCertificationCode = value;
      } else {
        list[0].remark = value;
      }
    } else {

      list = new Array<Freetext>();
      const fx = new Freetext();
      fx.type = qualifier;
      if (code === ColumnIds.VGM_CERTI_CODE) {
        fx.vgmCertificationCode = value;
      } else {
        fx.remark = value;
      }

      list.push(fx);
      cntr.freeTexts.set(qualifier, list);
    }
    return true;
  }
  static getRemarkQualifier(code: string): string {
    let qualifier = '';
    if (code === ColumnIds.DESCRIPTION_OF_GOODS) {
      qualifier = 'AAA';
    } else if (code === ColumnIds.HANDLING_INSTRUCTION) {
      qualifier = 'HAN';
    } else if (code === ColumnIds.LOADING_REMARK) {
      qualifier = 'CLR';
    } else if (code === ColumnIds.SPECIAL_CARGO_INSTRUCTION) {
      qualifier = 'SIN';
    } else if (code === ColumnIds.GENERAL_INFORMATION) {
      qualifier = 'AAI';
    } else if (code === ColumnIds.VGM_CERTI_CODE) {
      qualifier = 'AAY';
    } else if (code === ColumnIds.MUTUALLY_DEFINED_REMARK) {
      qualifier = 'ZZZ';
    }
    return qualifier;
  }
  static getFirstVgmInfo(cntr: Container, index?: number): string {
    const qualifier = 'AAY';
    const list = cntr.freeTexts.get(qualifier) as Array<Freetext>;

    if (list && list.length > 0) {

      if (index === 1) {
        return list[0].remark;
      } else if (index === 2) {
        return list[0].vgmInfo2;
      } else if (index === 3 ) {
        return list[0].vgmInfo3;
      } else if (index === 4) {
        return list[0].vgmInfo4;
      } else if (index === 5) {
        return list[0].vgmInfo5;
      } else {

        let vgmInfo = list[0].vgmInfo5;

        if (vgmInfo) {
          vgmInfo = ':' + vgmInfo;
        }

        if (list[0].vgmInfo4) {
          vgmInfo = list[0].vgmInfo4 + vgmInfo;
        }
        if (vgmInfo) {
          vgmInfo = ':' + vgmInfo;
        }
        if (list[0].vgmInfo3) {
          vgmInfo = list[0].vgmInfo3 + vgmInfo;
        }
        if (vgmInfo) {
          vgmInfo = ':' + vgmInfo;
        }
        if (list[0].vgmInfo2) {
          vgmInfo = list[0].vgmInfo2 + vgmInfo;
        }
        if (vgmInfo) {
          vgmInfo = ':' + vgmInfo;
        }
        if (list[0].remark) {
          vgmInfo = list[0].remark + vgmInfo;
        }
        return vgmInfo;

      }

    }
    return '';
  }
  static setVgmInfo(cntr: Container, value: string, index: number): boolean {
    const qualifier = 'AAY';
    let list = cntr.freeTexts.get(qualifier) as Array<Freetext>;

    if (list && list.length > 0) {
      if (index === 1) {
        list[0].remark = value;
      } else if (index === 2) {
        list[0].vgmInfo2 = value;
      } else if (index === 3) {
        list[0].vgmInfo3 = value;
      } else if (index === 4) {
        list[0].vgmInfo4 = value;
      } else if (index === 5) {
        list[0].vgmInfo5 = value;
      } else {
        return false;
      }
    } else {
      list = new Array<Freetext>();
      const fx = new Freetext();
      fx.type = qualifier;

      if (index === 1) {
        fx.remark = value;
      } else if (index === 2) {
        fx.vgmInfo2 = value;
      } else if (index === 3) {
        fx.vgmInfo3 = value;
      } else if (index === 4) {
        fx.vgmInfo4 = value;
      } else if (index === 5) {
        fx.vgmInfo5 = value;
      } else {
        return false;
      }
      list.push(fx);
      cntr.freeTexts.set(qualifier, list);
    }
    return true;
  }
}
