import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {properties} from '../../../../environments/environment';
import {SizeTypeCode} from './model/size-type-code';
import {SizeType} from './model/size-type';
import {Observable} from 'rxjs';
import {EdiPort} from '../../port/service/model/edi.port';
import {Port} from '../../port/service/model/port';

@Injectable({
  providedIn: 'root'
})
export class SizeTypeService {

  constructor(private http: HttpClient) { }

  getContainerLengths() {
    return this.http.get<SizeTypeCode[]>(properties.serverUrl + '/sizetype/length');
  }
  getContainerHeights() {
    return this.http.get<SizeTypeCode[]>(properties.serverUrl + '/sizetype/height');
  }
  getContainerTypes() {
    return this.http.get<SizeTypeCode[]>(properties.serverUrl + '/sizetype/type');
  }
  getSizeTypes() {
    return this.http.get<SizeType[]>(properties.serverUrl + '/sizetype');
  }

  updateContainerLength(items: SizeTypeCode[]){
    return this.http.post(properties.serverUrl + '/sizetype/length', items);
  }
  updateContainerHeight(items: SizeTypeCode[]){
    return this.http.post(properties.serverUrl + '/sizetype/height', items);
  }
  updateContainerType(items: SizeTypeCode[]){
    return this.http.post(properties.serverUrl + '/sizetype/type', items);
  }

  deleteContainerLength(items: SizeTypeCode[]) {

    let ids = '';

    items.forEach(item => {
      ids = ids + ',' + item.id;
    });
    return this.http.delete(properties.serverUrl + '/sizetype/length?id=' + ids);
  }
  deleteContainerHeight(items: SizeTypeCode[]) {

    let ids = '';

    items.forEach(item => {
      ids = ids + ',' + item.id;
    });
    return this.http.delete(properties.serverUrl + '/sizetype/height?id=' + ids);
  }
  deleteContainerType(items: SizeTypeCode[]) {

    let ids = '';

    items.forEach(item => {
      ids = ids + ',' + item.id;
    });
    return this.http.delete(properties.serverUrl + '/sizetype/type?id=' + ids);
  }
  deleteOwnSizeType(items: SizeType[]) {
    let ids = '';
    items.forEach(item => {
      ids = ids + ',' + item.id;
    });
    return this.http.delete(properties.serverUrl + '/sizetype?id=' + ids);
  }
  updateSizeType(items: SizeType[]){
    return this.http.post(properties.serverUrl + '/sizetype', items);
  }
  getSizeTypeBeforeReconciled(portCodes: string[]): Observable<SizeType[]> {
    let codes = '';

    portCodes.forEach(port => {

      codes = codes + ',' + portCodes;
    });
    return this.http.get<SizeType[]>(properties.serverUrl + '/sizetype/reconcile?code=' + codes);
  }
  reconcilePorts(items: string[]): Observable<SizeType[]> {

    return this.http.post<SizeType[]>(properties.serverUrl + '/sizetype/reconcile', items);
  }
}
