<div fxLayout="column" fxLayoutAlign="start stretch">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z0"  class="topics">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>Filter</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox *ngIf="element.filterable" (click)="$event.stopPropagation()"
                      [checked]="element.selected"
                      (change) = "element.selected = !element.selected">
<!--          <span *ngIf="element.booleanType">*</span>-->
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element" matTooltip="{{getTooTip(element.code)}}" matTooltipClass="tooltip"><span class="common-label">{{element.name}}</span></td>
    </ng-container>
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>Value</th>
      <td mat-cell *matCellDef="let element" >
        <div [ngClass]="getStyleClass(element)" [style.background]="getBackColor(element)" [style.color]="getForeColor(element)">
          {{element.formattedValue}}
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;">
  </table>
<!--  <div *ngIf="dataSource.data.length > 0"  style="margin-left: 20px;margin-top: 15px">-->
<!--    * Y or N property-->
<!--  </div>-->
</div>
