import {EditableModel} from '../../be-com/etable/editable-model';

export class Preference implements EditableModel {
  id = '';
  modified: boolean;
  updaterEmail = '';
  updateDatetime = '';

  code = '';
  name = '';
  shortName = '';
  groupCode = '';
  value = '';
  value2 = '';
  seq = 0;
}
