import {Component, OnInit} from '@angular/core';
import {ToolbarEventService} from '../../core/event/toolbar-event.service';
import {EventIds} from '../../core/event/event-ids';
import {SessionIds, SessionValues} from '../../core/service/session-ids';
import {TranslateService} from '@ngx-translate/core';
import {BayPlanService} from '../../service/bay-plan.service';
import {EventService} from '../../core/event/event.service';
import {LayoutModes} from '../../edit/bay-plan/visual-part/bay-plan-root.visual-part';
import {MatDialog} from '@angular/material/dialog';
import {LoadableSapceDialog} from './loadable-sapce-dialog/loadable-sapce-dialog';
import {IMDGSegregationService} from '../../imdg/service/imdg-segregation.service';
import {ZoomLevel} from '../../edit/bay-plan/visual-part/bay-plan-dimensions';

@Component({
  selector: 'app-bay-toolbar',
  templateUrl: './bay-toolbar.component.html',
  styleUrls: ['./bay-toolbar.component.css']
})
export class BayToolbarComponent implements OnInit {

  displayColorButton = 'dialpad';
  displayTextButton = 'ac_unit';

  leftButtonEnabled = true;
  rightButtonEnabled = true;

  hatches = new Array<DisplayMode>();
  readonly sessionIds = SessionIds;
  readonly sessionValues = SessionValues;
  colorOption = 'COLOR_POD';
  zoomInButtonEnabled = true;
  zoomOutButtonEnabled = true;

  constructor(public toolbarEventService: ToolbarEventService
            , private eventService: EventService
            , private translate: TranslateService
            , private bayPlanService: BayPlanService
            , private dialog: MatDialog
            , private iMDGSegregationService: IMDGSegregationService
            ) {
  }

  ngOnInit(): void {


    this.initHatchSelection();

    this.eventService.getEmitter().subscribe(event => {
      if (event.id === EventIds.LOAD_BAY_PLAN) {
        this.initHatchSelection();
      }
    });

    this.colorOption = sessionStorage.getItem(SessionIds.COLOR_OPTION);
    if (!this.colorOption) {
      this.colorOption = SessionValues.COLOR_POD;
      sessionStorage.setItem(SessionIds.COLOR_OPTION, SessionValues.COLOR_POD);
    }
  }

  private initHatchSelection() {
    const vessel = this.bayPlanService.getVessel();
    if (!vessel) {
      return;
    }
    this.hatches = new Array<DisplayMode>();
    this.hatches.push(new DisplayMode(LayoutModes.GENERAL_STOWAGE_PLAN, LayoutModes.GENERAL_STOWAGE_PLAN));
    this.hatches.push(new DisplayMode(LayoutModes.VESSEL_LAYOUT, LayoutModes.VESSEL_LAYOUT));


    for (let i = vessel.hatchList.length - 1; i >= 0; i--) {

      const hatch = vessel.hatchList[i];

      let item = '';
      for (let j = hatch.bayNumberList.length - 1; j >= 0; j--) {

        if (j < hatch.bayNumberList.length - 1) {
          item = item + ' ';
        }
        item = item + hatch.bayNumberList[j];
      }
      this.hatches.push(new DisplayMode(hatch.name, item));
    }

  }
  clickLeftButton() {

    for (let i = this.hatches.length - 1; i > 0; i--) {
      if (this.toolbarEventService.layoutZoom.layoutMode === this.hatches[i].name) {

        this.toolbarEventService.layoutZoom.layoutMode = this.hatches[--i].name;

        break;
      }
    }

    if (this.toolbarEventService.layoutZoom.layoutMode === LayoutModes.GENERAL_STOWAGE_PLAN) {
      this.toolbarEventService.layoutZoom.zoomLevel = ZoomLevel.L0;
    }
    if (this.toolbarEventService.layoutZoom.layoutMode === LayoutModes.VESSEL_LAYOUT) {
      this.toolbarEventService.layoutZoom.zoomLevel = ZoomLevel.L1;
    }
    this.toggleLayoutZoomButtons();
    this.toolbarEventService.emit(EventIds.BAY_PLAN_LAYOUT_MODE);
  }

  clickRightButton() {

    for (let i = 0; i < this.hatches.length - 1; i++) {
      if (this.toolbarEventService.layoutZoom.layoutMode === this.hatches[i].name) {

        this.toolbarEventService.layoutZoom.layoutMode = this.hatches[++i].name;
        break;
      }
    }

    if (this.toolbarEventService.layoutZoom.layoutMode === LayoutModes.GENERAL_STOWAGE_PLAN) {
      this.toolbarEventService.layoutZoom.zoomLevel = ZoomLevel.L0;
    }
    if (this.toolbarEventService.layoutZoom.layoutMode === LayoutModes.VESSEL_LAYOUT) {
      this.toolbarEventService.layoutZoom.zoomLevel = ZoomLevel.L1;
    }
    if (this.hatches.length > 2) {
      if (this.toolbarEventService.layoutZoom.layoutMode === this.hatches[2].name) {
        this.toolbarEventService.layoutZoom.zoomLevel = ZoomLevel.L2;
      }
    }

    this.toggleLayoutZoomButtons();
    this.toolbarEventService.emit(EventIds.BAY_PLAN_LAYOUT_MODE);
  }
  showZoomButtons(): boolean {

    // return !(this.bayPlanViewMode === LayoutModes.GENERAL_STOWAGE_PLAN || this.bayPlanViewMode === LayoutModes.VESSEL_LAYOUT);
    return true;
  }
  changeColor(colorOption: string) {
    this.colorOption = colorOption;
    sessionStorage.setItem(SessionIds.COLOR_OPTION, colorOption);
    this.toolbarEventService.emit(EventIds.CHANGE_COLOR_OPTION, colorOption);

  }
  getColorLabel(code?: string) {
    if (code) {
      return this.translate.instant(code);
    } else {
      return this.translate.instant(this.colorOption);
    }

  }
  changeHatch(hatchName: string) {
    this.toolbarEventService.layoutZoom.layoutMode = hatchName;
    if (this.toolbarEventService.layoutZoom.layoutMode === LayoutModes.GENERAL_STOWAGE_PLAN) {
      this.toolbarEventService.layoutZoom.zoomLevel = ZoomLevel.L0;
    }
    if (this.toolbarEventService.layoutZoom.layoutMode === LayoutModes.VESSEL_LAYOUT) {
      this.toolbarEventService.layoutZoom.zoomLevel = ZoomLevel.L1;
    }
    this.toolbarEventService.emit(EventIds.BAY_PLAN_LAYOUT_MODE);

  }

  redo() {
    // this.bayPlanService.db.delete();

  }

  loadableSpace() {
    const dialogRef = this.dialog.open(LoadableSapceDialog, {
      width: '250px',
      data: {unno: ''}
    });

    dialogRef.afterClosed().subscribe(unNo => {
      const invalid = this.iMDGSegregationService.invalidDangerousSpace(unNo);
    });
  }

  zoomIn() {

    const zoomLevel = ++this.toolbarEventService.layoutZoom.zoomLevel;

    if (zoomLevel === ZoomLevel.L1) {
      this.toolbarEventService.layoutZoom.layoutMode = LayoutModes.VESSEL_LAYOUT;
    }

    if (zoomLevel === ZoomLevel.L2) {
      if (this.hatches.length >= 3) {
        this.toolbarEventService.layoutZoom.layoutMode = this.hatches[2].name;
      }
    }

    this.toggleLayoutZoomButtons();
    this.toolbarEventService.emit(EventIds.BAY_PLAN_ZOOM);

  }

  zoomOut() {

    const zoomLevel = --this.toolbarEventService.layoutZoom.zoomLevel;

    if (zoomLevel === ZoomLevel.L0) {
      this.toolbarEventService.layoutZoom.layoutMode = LayoutModes.GENERAL_STOWAGE_PLAN;
    }
    if (zoomLevel === ZoomLevel.L1) {
      this.toolbarEventService.layoutZoom.layoutMode = LayoutModes.VESSEL_LAYOUT;
    }

    this.toggleLayoutZoomButtons();
    this.toolbarEventService.emit(EventIds.BAY_PLAN_ZOOM);
  }
  private toggleLayoutZoomButtons() {

    this.zoomInButtonEnabled = true;
    this.zoomOutButtonEnabled = true;
    this.leftButtonEnabled = true;
    this.rightButtonEnabled = true;

    const zoomLevel = this.toolbarEventService.layoutZoom.zoomLevel;

    if (zoomLevel === ZoomLevel.L0) {
      this.zoomOutButtonEnabled = false;

    }
    if (zoomLevel === ZoomLevel.L3) {
      this.zoomInButtonEnabled = false;
    }

    if (this.toolbarEventService.layoutZoom.layoutMode === this.hatches[0].name) {
      this.leftButtonEnabled = false;
    }
    if (this.toolbarEventService.layoutZoom.layoutMode === this.hatches[this.hatches.length-1].name) {
      this.rightButtonEnabled = false;
    }
  }
}

export class DisplayMode {
  constructor(public name: string, public displayName: string) {

  }
}
