import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SharedEdi, SharingInput} from './shared-edi';
import {properties} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EdiSharingService {

  constructor(private http: HttpClient) { }

  getSharedEdis(ediId: string): Observable<any> {

    return this.http.get<SharedEdi[]>(properties.serverUrl + '/edi/share?id=' + ediId);
  }
  shareEdi(ediId: string, grantee: string): Observable<any> {

    const input = new SharingInput(ediId, grantee);

    return this.http.post(properties.serverUrl + '/edi/share', input);
  }
  expire(id: string): Observable<any> {

    return this.http.delete(properties.serverUrl + '/edi/share?id=' + id);
  }
}
