import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {properties} from '../../../environments/environment';
import {Port} from '../port/service/model/port';
import {HandleError, HttpErrorHandler} from '../../service/http-error-handler.service';
import {HttpClient} from '@angular/common/http';
import {Operator} from '../operator/service/model/operator';
import {ServiceLane} from '../service-lane/serivce/model/service-lane';
import {BayPlan} from '../../service/model/bay-plan';
import {SizeType} from '../size-type/service/model/size-type';
import {EventService} from '../../core/event/event.service';
import {EventIds} from '../../core/event/event-ids';
import {SizeTypeCode} from '../size-type/service/model/size-type-code';
import {CodeRecon} from './model/code-recon';
import {WeightGroup} from '../weight-group/service/weight-group';
import {Dangerous} from '../../my-plans/service/model/dangerous';

@Injectable({
  providedIn: 'root'
})
export class CodeService {
  private readonly handleError: HandleError;

  private ownPorts: Port[];
  private ownPortMap: Map<string, Port>;
  porMap: Map<string, string>;
  polMap: Map<string, string>;
  podMap: Map<string, string>;
  tsPortMap: Map<string, string>;
  npodMap: Map<string, string>;
  placeOfDeliveryMap: Map<string, string>;
  private ownOperators: Operator[];
  private ownOperatorMap: Map<string, Operator>;
  private sizeTypeMap: Map<string, SizeType>;
  private sizeTypes: SizeType[];
  private containerLengths: SizeTypeCode[];
  private containerHeights: SizeTypeCode[];
  private containerTypes: SizeTypeCode[];
  private serviceLaneCode = '';
  private weightGroups: WeightGroup[];
  private imdgCodeMap: Map<string, Array<Dangerous>>;

  constructor(private http: HttpClient,
              httpErrorHandler: HttpErrorHandler,
              private eventService: EventService) {

    this.handleError = httpErrorHandler.createHandleError('CodeService');

    eventService.getEmitter().subscribe(event => {

      if (event.id === EventIds.UPDATE_WEIGHT_GROUPS) {

        if (this.serviceLaneCode === event.newValue) {
          this.http.get<WeightGroup[]>(properties.serverUrl + '/weight_group?service_lane_code=' + this.serviceLaneCode).subscribe(items => {
            this.weightGroups = items;
          });
        }
      }
    });

  }
  getOwnPorts(): Port[] {
    return this.ownPorts;
  }
  getOwnPortMap(): Map<string, Port> {
    return this.ownPortMap;
  }
  getOwnOperatorMap(): Map<string, Operator> {
    return this.ownOperatorMap;
  }
  getOwnOperators(): Operator[] {
    return this.ownOperators;
  }
  getSizeTypeMap(): Map<string, SizeType> {
    return this.sizeTypeMap;
  }
  getServiceLane(): Observable<Map<string, ServiceLane>> {
    return this.http.get<Map<string, ServiceLane>>(properties.serverUrl + '/lane');
  }
  getSizeTypes(): SizeType[] {
   return this.sizeTypes;
  }
  getContainerLengths() {
    return this.containerLengths;
  }
  getContainerHeights() {
    return this.containerHeights;
  }
  getContainerTypes() {
    return this.containerTypes;
  }
  getWeightGroups(): WeightGroup[] {
    return this.weightGroups;
  }
  getImdgCodeMap(): Map<string, Array<Dangerous>> {
    return this.imdgCodeMap;
  }
  reconcileImdgCodes(bayPlan: BayPlan): Observable<Map<string, Array<Dangerous>>> {

    this.imdgCodeMap = new Map<string, Array<Dangerous>>();

    const unNoMaps = new Map<string, Dangerous>();
    for (const container of bayPlan.containers) {

      for (const dangerous of container.dangerousGoods) {
        unNoMaps.set(dangerous.unno, dangerous);
      }
    }

    const unNos = Array.from(unNoMaps.keys()).join(',');

    return new Observable<Map<string, Array<Dangerous>>>((observer) => {

      if (unNos) {
        this.http.get<Dangerous[]>(properties.serverUrl + '/dangerous_goods?UNNO=' + unNos).subscribe(data => {
          for (const dangerous of data) {
            let codes = this.imdgCodeMap.get(dangerous.unno);
            if (!codes) {
              codes = new Array<Dangerous>();
            }
            codes.push(dangerous);
            this.imdgCodeMap.set(dangerous.unno, codes);
          }
          observer.next(this.imdgCodeMap);
          observer.complete();
        }, error => {
          observer.error(error);
          observer.complete();
        });

      } else {
        observer.next(this.imdgCodeMap);
        observer.complete();

      }
    });
  }
  reconcileCodes(bayPlan: BayPlan): Observable<string> {
    this.serviceLaneCode = bayPlan.header.serviceLaneCode;
    // this.http.get<WeightGroup[]>(properties.serverUrl + '/weight_group?code=' + this.serviceLaneCode).subscribe(items => {
    //   this.weightGroups = items;
    // });

    const portCodeMap = new Map<string, string>();
    const operatorCodeMap = new Map<string, string>();
    const sizeTypeCodeMap = new Map<string, string>();

    bayPlan.containers.forEach(cntr => {

      if (cntr.por) {
        portCodeMap.set(cntr.por, cntr.por);
      }
      if (cntr.pol) {
        portCodeMap.set(cntr.pol, cntr.pol);
      }
      if (cntr.pod) {
        portCodeMap.set(cntr.pod, cntr.pod);
      }
      if (cntr.optPod) {
        portCodeMap.set(cntr.optPod, cntr.optPod);
      }
      if (cntr.optPod1) {
        portCodeMap.set(cntr.optPod1, cntr.optPod1);
      }
      if (cntr.optPod2) {
        portCodeMap.set(cntr.optPod2, cntr.optPod2);
      }
      if (cntr.optPod3) {
        portCodeMap.set(cntr.optPod3, cntr.optPod3);
      }
      if (cntr.optPod4) {
        portCodeMap.set(cntr.optPod4, cntr.optPod4);
      }
      if (cntr.optPod5) {
        portCodeMap.set(cntr.optPod5, cntr.optPod5);
      }
      if (cntr.tsPort) {
        portCodeMap.set(cntr.tsPort, cntr.tsPort);
      }
      if (cntr.nPod) {
        portCodeMap.set(cntr.nPod, cntr.nPod);
      }
      if (cntr.placeOfDelivery) {
        portCodeMap.set(cntr.placeOfDelivery, cntr.placeOfDelivery);
      }
      if (cntr.carrier) {
        operatorCodeMap.set(cntr.carrier, cntr.carrier);
      }
      if (cntr.isoSizeType) {
        sizeTypeCodeMap.set(cntr.isoSizeType, cntr.isoSizeType);
      }
    });
    let portCodes = '';
    Array.from(portCodeMap.keys()).forEach(code => {
      portCodes = portCodes + ',' + code;
    });

    let operatorCodes = '';
    Array.from(operatorCodeMap.keys()).forEach(code => {
      operatorCodes = operatorCodes + ',' + code;
    });

    let sizeTypeCodes = '';
    Array.from(sizeTypeCodeMap.keys()).forEach(code => {
      sizeTypeCodes = sizeTypeCodes + ',' + code;
    });
    return new Observable<string>((observer) => {
      this.http.get<CodeRecon>(properties.serverUrl + '/code/reconcile?lane=' + bayPlan.header.serviceLaneCode
        + '&port=' + portCodes.substring(1)
        + '&operator=' + operatorCodes.substring(1)
        + '&sizetype=' + sizeTypeCodes.substring(1)
      ).subscribe(data => {

        const ports = data.portRecon.ports;
        const portMap = new Map<string, Port>();
        const tempPortMap = new Map<string, Port>();
        ports.forEach(port => {
          portMap.set(port.code, port);
          tempPortMap.set(port.ownCode, port);
        });
        this.reconcilePort(tempPortMap);

        const operators = data.operatorRecon.operators;
        const operatorMap = new Map<string, Operator>();
        const tempOperatorMap = new Map<string, Operator>();
        operators.forEach(operator => {
          operatorMap.set(operator.code, operator);
          tempOperatorMap.set(operator.ownCode, operator);
        });
        this.reconcileOperator(tempOperatorMap);

        this.sizeTypes = data.sizeTypeRecon.sizeTypes;
        this.sizeTypeMap = new Map();
        this.sizeTypes.forEach(sizeType => {

          this.sizeTypeMap.set(sizeType.code, sizeType);
        });

        this.containerLengths = data.containerLength;
        this.containerHeights = data.containerHeight;
        this.containerTypes = data.containerType;

        this.weightGroups = data.weightGroup;

        this.porMap = new Map<string, string>();
        this.polMap = new Map<string, string>();
        this.podMap = new Map<string, string>();
        this.tsPortMap = new Map<string, string>();
        this.npodMap = new Map<string, string>();
        this.placeOfDeliveryMap = new Map<string, string>();

        bayPlan.containers.forEach(cntr => {

          const por = portMap.get(cntr.por);
          if (por) {
            cntr.por = por.ownCode;
            this.porMap.set(cntr.por, cntr.por);
          }
          const pol = portMap.get(cntr.pol);
          if (pol) {
            cntr.pol = pol.ownCode;
            this.polMap.set(cntr.pol, cntr.pol);
          }
          const pod = portMap.get(cntr.pod);
          if (pod) {
            cntr.pod = pod.ownCode;
            this.podMap.set(cntr.pod, cntr.pod);
          }
          const optPod = portMap.get(cntr.optPod);
          if (optPod) {
            cntr.optPod = optPod.ownCode;
          }
          const optPod1 = portMap.get(cntr.optPod1);
          if (optPod1) {
            cntr.optPod1 = optPod1.ownCode;
          }
          const optPod2 = portMap.get(cntr.optPod2);
          if (optPod2) {
            cntr.optPod2 = optPod2.ownCode;
          }
          const optPod3 = portMap.get(cntr.optPod3);
          if (optPod3) {
            cntr.optPod3 = optPod3.ownCode;
          }
          const optPod4 = portMap.get(cntr.optPod4);
          if (optPod4) {
            cntr.optPod4 = optPod4.ownCode;
          }
          const optPod5 = portMap.get(cntr.optPod5);
          if (optPod5) {
            cntr.optPod5 = optPod5.ownCode;
          }
          const tsPort = portMap.get(cntr.tsPort);
          if (tsPort) {
            cntr.tsPort = tsPort.ownCode;
            this.tsPortMap.set(cntr.tsPort, cntr.tsPort);
          }
          const nPod = portMap.get(cntr.nPod);
          if (nPod) {
            cntr.nPod = nPod.ownCode;
            this.npodMap.set(cntr.nPod, cntr.nPod);
          }
          const placeOfDelivery = portMap.get(cntr.placeOfDelivery);
          if (placeOfDelivery) {
            cntr.placeOfDelivery = placeOfDelivery.ownCode;
            this.placeOfDeliveryMap.set(cntr.placeOfDelivery, cntr.placeOfDelivery);
          }
          const operator = operatorMap.get(cntr.carrier);
          if (operator) {
            cntr.carrier = operator.ownCode;
          }
        });
        this.eventService.emit(EventIds.CODES_RECONCILED);
        observer.next('');
        observer.complete();
        }, error => {
        console.error(error);
        observer.error(error);
        observer.complete();
        }
      );
    });
  }

  private reconcilePort(tempPortMap: Map<string, Port>) {

    this.ownPorts = new Array<Port>();
    this.ownPortMap = new Map<string, Port>();

    tempPortMap.forEach(port => {
      this.ownPorts.push(port);
    });

    this.ownPorts.forEach(port => {
      port.code = port.ownCode;
      this.ownPortMap.set(port.ownCode, port);
    });
    this.ownPorts.sort( (a, b) => a.ownCode > b.ownCode ? 0 : 1);
  }
  private reconcileOperator(tempOperatorMap: Map<string, Operator>) {
    this.ownOperators = new Array<Operator>();
    this.ownOperatorMap = new Map<string, Operator>();

    tempOperatorMap.forEach(operator => {
      this.ownOperators.push(operator);
    });

    this.ownOperators.forEach(operator => {
      operator.code = operator.ownCode;
      this.ownOperatorMap.set(operator.ownCode, operator);
    });
    this.ownOperators.sort((a, b) => a.ownCode > b.ownCode ? 0 : 1);
  }
  changeLengthColor(sizeType: SizeTypeCode) {

    this.containerLengths.forEach(item => {
      if (item.code === sizeType.code) {
        item.foreColor = sizeType.foreColor;
        item.backColor = sizeType.backColor;
        return;
      }
    });
    this.sizeTypes.forEach(item => {
      if (item.lengthCode === sizeType.code) {
        item.lengthForeColor = sizeType.foreColor;
        item.lengthBackColor = sizeType.backColor;

        const map = this.sizeTypeMap.get(item.code);
        if (map) {
          map.lengthForeColor = sizeType.foreColor;
          map.lengthBackColor = sizeType.backColor;
        }
        return;
      }
    });
  }
  changeHeightColor(sizeType: SizeTypeCode) {

    this.containerHeights.forEach(item => {
      if (item.code === sizeType.code) {
        item.foreColor = sizeType.foreColor;
        item.backColor = sizeType.backColor;
        return;
      }
    });
    this.sizeTypes.forEach(item => {
      if (item.heightCode === sizeType.code) {
        item.heightForeColor = sizeType.foreColor;
        item.heightBackColor = sizeType.backColor;

        const map = this.sizeTypeMap.get(item.code);
        if (map) {
          map.heightForeColor = sizeType.foreColor;
          map.heightBackColor = sizeType.backColor;
        }
        return;
      }
    });
  }
  changeTypeColor(sizeType: SizeTypeCode) {

    this.containerTypes.forEach(item => {
      if (item.code === sizeType.code) {
        item.foreColor = sizeType.foreColor;
        item.backColor = sizeType.backColor;
        return;
      }
    });
    this.sizeTypes.forEach(item => {
      if (item.typeCode === sizeType.code) {
        item.typeForeColor = sizeType.foreColor;
        item.typeBackColor = sizeType.backColor;

        const map = this.sizeTypeMap.get(item.code);
        if (map) {
          map.typeForeColor = sizeType.foreColor;
          map.typeBackColor = sizeType.backColor;
        }
        return;
      }
    });
  }
  checkNull(value): string {
    if (value) {
      return value;
    } else {
      return '';
    }
  }

}
