import { Injectable } from '@angular/core';
import {EdiDocument} from '../my-plans/service/model/edi-document';
import {BayPlan} from './model/bay-plan';
import {BayPlanEdiParserService} from './bay-plan-edi-parser.service';
import {Vessel} from '../hull/model/vessel';
import {AuthEventId, AuthService} from '../user/service/auth.service';
import {SessionIds} from '../core/service/session-ids';
import {BayPlanVesselParser} from '../hull/service/bay-plan-vessel-parser';
import {CodeService} from '../code/service/code.service';
import {EdiHeaderInput, EdiSummaryInput} from '../my-plans/service/edi-header-input';
import {properties} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EdiText} from '../my-plans/service/model/edi-text';
import {EventService} from '../core/event/event.service';
import {EventIds} from '../core/event/event-ids';
import {Container} from './model/container';
import {MatDialog} from '@angular/material/dialog';
import {VesselTextParserService} from '../hull/service/vessel-text-parser.service';
import {LocalDbService} from './local-db.service';
import {EdiReaderService} from '../my-plans/service/edi-reader.service';
import {EdiWriterService} from '../my-plans/service/edi-writer.service';
import {VesselDesign} from '../hull/model/vessel-design';
import {EdiHeader} from '../my-plans/service/model/edi-header';
import {IMDGSegregationService} from '../imdg/service/imdg-segregation.service';
import {StowageValidationService} from '../my-plans/service/stowage-validation.service';
import {EdiHeaderExt} from '../my-plans/service/model/edi-header-ext';
import {VesselSchedule} from '../my-plans/vessel-schedule/service/model/vessel-schedule';
import {PreferenceService} from './preference.service';
import {PreferenceCode, PreferenceValue} from '../panels/preferences/preferences.component';

// export interface Entry {
//   seq?: number;
//   userId: string;
//   id: string;
//   content: string;
// }
export interface BayPlanEntry {
  userId: string;
  id: string;
  content: BayPlan;
}

export enum BayPlanServiceDataIds {
  BAY_PLAN = 'BAY_PLAN',
  VESSEL = 'VESSEL',
}

@Injectable({
  providedIn: 'root'
})
export class BayPlanService {

  private bayPlan: BayPlan;
  private vessel: Vessel;
  private hatches: string[];
  private hatchesDesc: string[];
  private ediHeaderExt: EdiHeaderExt;

  constructor(private authService: AuthService,
              private codeService: CodeService,
              private eventService: EventService,
              private dialog: MatDialog,
              private http: HttpClient,
              private bayPlanEdiParserService: BayPlanEdiParserService,
              private localDbService: LocalDbService,
              private vesselTextParserService: VesselTextParserService,
              private ediReaderService: EdiReaderService,
              private ediWriterService: EdiWriterService,
              private imdgSegregationService: IMDGSegregationService,
              private preferenceService: PreferenceService,
              private stowageValidationService: StowageValidationService) {

    this.authService.getEmitter().subscribe(event => {
      if (event.id === AuthEventId.CHANGE_TEAM || event.id === AuthEventId.SIGN_OUT) {
        this.bayPlan = null;
        this.vessel = null;
        this.setBayPlanSession(undefined);
      }
    });
  }

  retrieveEdiText(id: string): Observable<EdiText> {
    return this.http.get<EdiText>(properties.serverUrl + '/edi?id=' + id);
  }
  retrieveBayPlanFromServer(ediHeaderExt?: EdiHeaderExt): Observable<BayPlan> {

    const ediId = sessionStorage.getItem(SessionIds.EDI_ID);
    const vesselId = sessionStorage.getItem(SessionIds.VESSEL_ID);

    if (ediHeaderExt) {
      this.ediHeaderExt = ediHeaderExt;
    }

    return new Observable<BayPlan> ((observer) => {

      this.retrieveEdiText(ediId).subscribe(data => {

        this.reconcileBayPlan(data.ediText, vesselId).subscribe(bayPlan => {

          this.saveEdiTextToLocalDb(ediId, data.ediText);

          this.retrieveVessel(ediId);

          this.updateEdiSummer(ediId, this.vessel, bayPlan);
          this.eventService.emit(EventIds.LOAD_BAY_PLAN, bayPlan.header);
          this.eventService.emit(EventIds.CONSOLE_LOGGING, bayPlan.header.messageType + ' ' + bayPlan.header.vesselName + ' / ' + bayPlan.header.voyageNo  + ' opened', 'INFO');

          observer.next(bayPlan);
          observer.complete();

        }, error3 => {
          observer.error(error3);
          observer.complete();
        });
        }
        ,
        error2 => {
          observer.error(error2);
          observer.complete();
        }
      );
    });
  }
  private updateEdiSummer(ediId: string, vessel: Vessel, bayPlan: BayPlan) {

    if (!this.authService.isMemberOrAdmin()) {
      return;
    }
    this.stowageValidationService.run(vessel, bayPlan);
    this.imdgSegregationService.initData(vessel, bayPlan).subscribe(() => {
      this.imdgSegregationService.run();
      this.eventService.emit(EventIds.MARKERS);

      const markersInput = new EdiSummaryInput(ediId, bayPlan, this.stowageValidationService.getErrorMessages().length, this.imdgSegregationService.getErrorMessages().length);
      markersInput.cleanBayPlan();
      this.http.post<string>(properties.serverUrl + '/edi/summary', markersInput).subscribe(() => {}, error => {console.error(error); });

    }, () => {
      this.eventService.emit(EventIds.MARKERS);
    });
  }
  private saveEdiTextToLocalDb(ediId: string, ediText: string) {
    try {
      this.localDbService.ediTextTable.put({userId: this.authService.getTokenClaim().id, id: ediId, content: ediText});
    } catch (error) {
      console.error(error);
    }
  }

  setBayPlanSession(ediHeader: VesselSchedule, bayPlanLayoutMode?: string) {

    if (ediHeader) {
      sessionStorage.setItem(SessionIds.EDI_ID, ediHeader.ediId);
      sessionStorage.setItem(SessionIds.VESSEL_ID, ediHeader.vesselId);
      sessionStorage.setItem(SessionIds.VESSEL_NAME, ediHeader.vesselName);
      sessionStorage.setItem(SessionIds.VOYAGE_NO, ediHeader.voyageNo);
      sessionStorage.setItem(SessionIds.EDI_TYPE, ediHeader.messageType);
      sessionStorage.setItem(SessionIds.SERVICE_LANE_CODE, ediHeader.serviceLaneCode);
      sessionStorage.setItem(SessionIds.EDI_VERSION, ediHeader.smdgVersion);
      sessionStorage.setItem(SessionIds.EDI_FILE_NAME, ediHeader.fileName);
      sessionStorage.setItem(SessionIds.EDI_FILE_CREATOR, ediHeader.creatorName);
      sessionStorage.setItem(SessionIds.EDI_FILE_CREATE_TIME, ediHeader.creationDatetime);
      sessionStorage.setItem(SessionIds.EDI_FILE_UPDATER, ediHeader.updaterName);
      sessionStorage.setItem(SessionIds.EDI_FILE_UPDATE_TIME, ediHeader.updateDatetime);
    } else {
      sessionStorage.setItem(SessionIds.EDI_ID, '');
      sessionStorage.setItem(SessionIds.VESSEL_ID, '');
      sessionStorage.setItem(SessionIds.VESSEL_NAME, '');
      sessionStorage.setItem(SessionIds.VOYAGE_NO, '');
      sessionStorage.setItem(SessionIds.EDI_TYPE, '');
      sessionStorage.setItem(SessionIds.SERVICE_LANE_CODE, '');
      sessionStorage.setItem(SessionIds.EDI_VERSION, '');
    }


    if (bayPlanLayoutMode) {
      sessionStorage.setItem(SessionIds.BAY_PLAN_LAYOUT_MODE, bayPlanLayoutMode);
    }
  }
  retrieveBayPlanFromLocal(loadFromMemory?: boolean): Observable<BayPlan> {

    const user = sessionStorage.getItem(SessionIds.USER_ID);
    const ediId = sessionStorage.getItem(SessionIds.EDI_ID);

    const location = new Observable<BayPlan>((observer) => {

      if (this.bayPlan && loadFromMemory) {

        this.codeService.reconcileCodes(this.bayPlan).subscribe(re => {

          this.retrieveVessel(ediId);
          this.eventService.emit(EventIds.LOAD_BAY_PLAN, this.bayPlan.header);
          observer.next(this.bayPlan);
          observer.complete();
        }, error2 => {
          console.error(error2);
          observer.error(error2);
          observer.complete();
        });

        return location;
      }

      if (user && ediId) {

        this.localDbService.ediTextTable.where({userId: user, id: ediId}).last(data => {
          this.reconcileBayPlan(data.content).subscribe(bayPlan => {
            this.bayPlan = bayPlan;

            this.retrieveVessel(ediId);
            this.eventService.emit(EventIds.LOAD_BAY_PLAN, bayPlan.header);
            observer.next(bayPlan);
            observer.complete();
          }, error => {
            console.error(error);
            observer.error(error);
            observer.complete();
          });
        });
      } else {
        observer.error({error: 'Open a file'});
        observer.complete();
      }
    });
    return location;
  }
  retrieveVessel(ediId: string) {
    this.vessel = null;

    this.findVessel(ediId).then(vessel => {
      this.vessel = vessel;
    }, error => {
      console.error(error);
    });

    if (!this.vessel) {
      if (this.preferenceService.getPreferenceTemp(PreferenceCode.VesselDesign) === PreferenceValue.VesselDesign_Certified) {
        this.searchVesselDesign(this.bayPlan.header).toPromise().then(vesselDesign => {

          if (vesselDesign && vesselDesign.content) {
            this.vessel = this.vesselTextParserService.parseText2Vessel(vesselDesign.content, true);
            this.vessel.certified = true;
            this.putVessel(ediId, vesselDesign.content);
          }
        }, error => {console.error(error); });
      }
    }
    if (!this.vessel) {
      this.vessel = BayPlanVesselParser.translate(this.bayPlan);
      this.putVessel(ediId, this.vesselTextParserService.parseVessel2Text(this.vessel));
    }
  }
  getBayPlan(): BayPlan {
    return this.bayPlan;
  }
  getVessel(): Vessel {
    return this.vessel;
  }
  setVessel(vessel: Vessel) {
    this.vessel = vessel;
  }
  getEdiDocument(): EdiDocument {

    if (!this.bayPlan) {
      return null;
    }
    return this.bayPlanEdiParserService.parseBayPlan2EdiDocument(this.bayPlan);
  }
  private getEdiSession(): string {

    return sessionStorage.getItem(SessionIds.VESSEL_NAME) + ' / ' + sessionStorage.getItem(SessionIds.VOYAGE_NO) + ' ' + sessionStorage.getItem(SessionIds.EDI_TYPE);
  }
  getEdiText(): Observable<string> {
    return new Observable<string>((observer) => {

      if (!this.bayPlan) {
        observer.next('');
        observer.complete();
      } else {
        this.ediWriterService.parseBayPlan2Text(this.bayPlan).then(ediText => {
          observer.next(ediText);
          observer.complete();
        });
      }
    });
  }
  fixLength(value: string, length: number): string {
    if (value && value.length > length) {
      return value.substring(0, length);
    }
    return value;
  }

  importEdiToServer(fileName: string, ediText: string, serviceLaneCode?: string): Observable<string> {
    const id = new Observable<string>((observer) => {
      // const newEdiText = this.appendServiceLane(ediText, serviceLaneCode);
      this.ediReaderService.parseText2BayPlan(ediText, serviceLaneCode).then(bayPlan => {

        bayPlan.header.serviceLaneCode = serviceLaneCode;
        // console.info(bayPlan.header);
        const ediHeaderInput = new EdiHeaderInput(ediText, bayPlan);
        ediHeaderInput.id = '';
        ediHeaderInput.fileName = this.fixLength(fileName, 300);
        ediHeaderInput.messageType = this.fixLength(ediHeaderInput.messageType, 6);
        ediHeaderInput.versionNo = this.fixLength(ediHeaderInput.versionNo, 6);
        ediHeaderInput.messageFunction = this.fixLength(ediHeaderInput.messageFunction, 3);
        ediHeaderInput.sender = this.fixLength(ediHeaderInput.sender, 35);
        ediHeaderInput.recipient = this.fixLength(ediHeaderInput.recipient, 35);
        ediHeaderInput.carrier = this.fixLength(ediHeaderInput.carrier, 100);
        ediHeaderInput.vesselName = this.fixLength(ediHeaderInput.vesselName, 500);

        // console.info(ediHeaderInput);

        this.http.post<string>(properties.serverUrl + '/edi', ediHeaderInput).toPromise().then(ediId => {
          ediHeaderInput.id = ediId;

          this.codeService.reconcileCodes(bayPlan);
          this.localDbService.ediTextTable.put({userId: this.authService.getTokenClaim().id, id: ediId, content: ediText});

          this.eventService.emit(EventIds.CONSOLE_LOGGING, ediHeaderInput.messageType + ' ' + ediHeaderInput.vesselName + ' / ' + ediHeaderInput.voyageNo  + ' imported', 'INFO');

          let vessel = null;
          if (this.preferenceService.getPreferenceTemp(PreferenceCode.VesselDesign) === PreferenceValue.VesselDesign_Certified) {

            this.searchVesselDesign(bayPlan.header).toPromise().then(vesselDesign => {

              if (vesselDesign && vesselDesign.content) {
                vessel = this.vesselTextParserService.parseText2Vessel(vesselDesign.content, true);
              }

            }, error => {
              console.error(error);
            });

          }
          if (!vessel) {
            vessel = BayPlanVesselParser.translate(bayPlan);
          }
          const stowageMarkers = new StowageValidationService().run(vessel, bayPlan);
          const imdgSegregationService =  new IMDGSegregationService(this.http);

          imdgSegregationService.initData(vessel, bayPlan).subscribe(() => {

            const imdgMarkers = imdgSegregationService.run();
            ediHeaderInput.markers = bayPlan.syntaxViolations.length + stowageMarkers.length + imdgMarkers.length;

            this.http.post<string>(properties.serverUrl + '/edi/summary', ediHeaderInput).subscribe(() => {
              observer.next(ediId);
              observer.complete();

            }, error => { console.error(error); });
          }, () => {
            observer.next(ediId);
            observer.complete();
          });

        }, error2 => {
          console.error(error2);
          observer.error(error2);
          observer.complete();
        });

      });
    });
    return id;
  }
  private searchVesselDesign(header: EdiHeader, certified?: boolean): Observable<VesselDesign> {

    const subRoute = '/vessel/design';

    // if (certified) {
    //   subRoute = '/vessel/design/certified';
    // }

    return this.http.get<VesselDesign>(properties.serverUrl + subRoute + '?imo_no=' + header.imoNo
      + '&call_sign=' + header.callSign + '&vessel_code=' + header.vesselCode);
  }

  private appendServiceLane(ediText: string, serviceLaneCode?: string): string {
    if (!serviceLaneCode || serviceLaneCode === '') {
      return ediText;
    }
    let text = '';
    const lines = ediText.split('\'');
    let serviceLane = false;
    for (const line of lines) {
      const type = line.split('+');
      if (!serviceLane && type[0].replace('\r\n', '') === 'LOC') {
        text += 'LOC+PB+^^' + serviceLaneCode + '\'';
        serviceLane = true;
      }
      if (type.length < 2 || (type[1] !== 'PB' && type[1] !== 'BE')) {
        text += line + '\'';
      }
    }
    return text;
  }
  updateToServer(): Observable<string> {
    const id = new Observable<string>((observer) => {
      if (!this.bayPlan) {
        observer.next('');
        observer.complete();
      } else {
        const ediId = sessionStorage.getItem(SessionIds.EDI_ID);
        this.ediWriterService.parseBayPlan2Text(this.bayPlan).then(ediText => {

          const serviceLaneCode = this.bayPlan.header.serviceLaneCode;
          // const newEdiText = this.appendServiceLane(ediText, serviceLaneCode);
          let IMDGErrorSize = 0;
          if (this.stowageValidationService.getErrorMessages()) {
            IMDGErrorSize = this.imdgSegregationService.getErrorMessages().length;
          }
          let stowageErrorSize = 0;
          if (this.stowageValidationService.getErrorMessages()) {
            stowageErrorSize = this.stowageValidationService.getErrorMessages().length;
          }
          const ediHeaderInput = new EdiHeaderInput(ediText, this.bayPlan, IMDGErrorSize, stowageErrorSize);
          ediHeaderInput.id = ediId;

          this.saveEdiTextToLocalDb(ediId, ediText);
          this.cleanContainers(this.bayPlan.containers);

          this.codeService.reconcileCodes(this.bayPlan).toPromise().then(() => {
            this.eventService.emit(EventIds.CONSOLE_LOGGING, ediHeaderInput.messageType  + ' ' + ediHeaderInput.vesselName + ' / ' + ediHeaderInput.voyageNo + ' updated', 'INFO');

            observer.next(ediId);
            observer.complete();
          }, rcError => {
            observer.error(rcError);
            observer.complete();
          });

          this.http.post<string>(properties.serverUrl + '/edi', ediHeaderInput).subscribe(() => {},
            headerError => {
              observer.error(headerError);
              observer.complete();
            }
          );

          if (!this.vessel) {
            this.vessel = BayPlanVesselParser.translate(this.bayPlan);
            this.putVessel(ediId, this.vesselTextParserService.parseVessel2Text(this.vessel));
          }

          this.updateEdiSummer(ediId, this.vessel, this.bayPlan);

          try {
            this.localDbService.ediTextTable.put({userId: this.authService.getTokenClaim().id, id: ediId, content: ediText});
          } catch (error) {
            console.error(error);
          }

        }, ediError => {
          observer.error(ediError);
          observer.complete();
        });
      }
    });
    return id;
  }
  private cleanContainers(containers: Container[]) {

    // for (let i = 0; i < containers.length; i ++ ) {
    //   containers[i].modified = false;
    // }
  }
  private reconcileHeader(ediHeader: EdiHeader, vesselId?: string): EdiHeader {
    ediHeader.vesselId = vesselId;
    ediHeader.id = sessionStorage.getItem(SessionIds.EDI_ID);
    ediHeader.serviceLaneCode = sessionStorage.getItem(SessionIds.SERVICE_LANE_CODE);
    ediHeader.fileName = sessionStorage.getItem(SessionIds.EDI_FILE_NAME);
    ediHeader.creatorName  = sessionStorage.getItem(SessionIds.EDI_FILE_CREATOR);
    ediHeader.creationDatetime = sessionStorage.getItem(SessionIds.EDI_FILE_CREATE_TIME);
    ediHeader.updaterName = sessionStorage.getItem(SessionIds.EDI_FILE_UPDATER);
    ediHeader.updateDatetime = sessionStorage.getItem(SessionIds.EDI_FILE_UPDATE_TIME);

    if (this.ediHeaderExt) {
      ediHeader.fileName = this.ediHeaderExt.fileName;
      ediHeader.creationDatetime = this.ediHeaderExt.creationDatetime;
      ediHeader.creatorName = this.ediHeaderExt.creatorName;
      ediHeader.updateDatetime = this.ediHeaderExt.updateDatetime;
      ediHeader.updaterName = this.ediHeaderExt.updaterName;
    }

    return ediHeader;
  }
  private reconcileBayPlan(ediText: string, vesselId?: string): Observable<BayPlan> {

    const data = new Observable<BayPlan>((observer) => {

      this.ediReaderService.parseText2BayPlan(ediText).then(bayPlan => {

        bayPlan.header = this.reconcileHeader(bayPlan.header, vesselId);

        this.codeService.reconcileCodes(bayPlan).subscribe(re => {

          this.bayPlan = bayPlan;

          observer.next(bayPlan);
          observer.complete();
        }, error2 => {
          observer.error(error2);
          observer.complete();
        });
      });
    });
    return data;
  }
  getHatches(): string[] {return this.hatches; }
  getHatchesDesc(): string[] {return this.hatchesDesc; }
  private initHatches() {

    this.hatches = new Array<string>();
    this.hatchesDesc = new Array<string>();
    const vessel = this.vessel;
    if (!vessel) {
      return;
    }
    for (let i = vessel.hatchList.length - 1; i >= 0; i--) {

      const hatch = vessel.hatchList[i];

      let item = '';
      for (let j = hatch.bayNumberList.length - 1; j >= 0; j--) {

        if (j < hatch.bayNumberList.length - 1) {
          item = item + ' ';
        }
        item = item + hatch.bayNumberList[j];
      }
      this.hatchesDesc.push(item);
    }

    for (const hatch of vessel.hatchList) {

      let item = '';
      for (let j = 0; j < hatch.bayNumberList.length; j++) {

        if (j > 0) {
          item = item + ' ';
        }
        item = item + hatch.bayNumberList[j];
      }
      this.hatches.push(item);
    }
  }

  saveBayPlanLocally(bayPlan: BayPlan) {
    const ediId = sessionStorage.getItem(SessionIds.EDI_ID);
    this.bayPlan = bayPlan;
    this.ediWriterService.parseBayPlan2Text(bayPlan).then(ediText => {
      try {
        this.localDbService.ediTextTable.put({userId: this.authService.getTokenClaim().id, id: ediId, content: ediText});
      } catch (error) {
        console.error(error);
      }
    });
  }
  private undoEdiDocument(): EdiDocument {
    const ediId = sessionStorage.getItem(SessionIds.EDI_ID);
    if (!ediId) {
      throw new Error('ediId is undefined');
    }
    // this.ediTextTable.where('id').equals(ediId).toArray().
    return null;
  }

  private putVessel(ediId, vesselContent: string): Observable<any> {
    return new Observable<any>((observer) => {
      this.localDbService.vesselTable.put({userId: this.authService.getTokenClaim().id, id: ediId, content: vesselContent});
      observer.next(ediId);
      observer.complete();
    });
  }
  findVessel(ediId: string) {
    const self = this;
    return this.localDbService.vesselTable.where({userId: this.authService.getTokenClaim().id, id: ediId}).first().then(function _(vesselTable) {
      if (vesselTable && vesselTable.content) {
        return self.vesselTextParserService.parseText2Vessel(vesselTable.content, true);
      } else {
        return null;
      }

    });
  }
  // deleteDatabase() {
  //   this.localDbService.db.delete();
  // }
}

