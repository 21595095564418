import {Place} from './place';
import {Datetime} from './datetime';
import {Freetext} from './freetext';
import {EdiHeaderPatch} from './edi-header-patch';
import {EdiHeaderExt} from './edi-header-ext';

export class EdiHeader implements EdiHeaderPatch, EdiHeaderExt {
  id = '';
  vesselId = '';
  creationDatetime = '';
  creatorName = '';
  updateDatetime = '';
  updaterName = '';

  fileName = '';
  messageType = '';
  ediText = '';
  ediJson = '';
  messageFunction = '';
  versionNo = '';
  vesselCode = '';
  vesselCodeType = '';
  imoNo = '';
  callSign = '';
  voyageNo = '';
  sender = '';
  recipient = '';
  carrier = '';

  deleted = 'N';
  eta = '';
  etd = '';
  atd = '';
  ata = '';

  updateUser = '';
  updateDate = '';

  portCode = '';
  portResponsibleAgency = '';
  portCountryId = '';
  portStateId = '';
  serviceLaneCode = '';
  nextPortCode = '';
  nextPortResponsibleAgency = '';
  nextPortCountryId = '';
  nextPortStateId = '';

  vesselName = '';
  nextEta = '';
  nextEtd = '';

  preparationDate = '';
  preparationTime = '';

  position: number;

  // ADD all parameter in EdiDocument
  interchangeControlRefStart = '';
  communicationsAgreementId = '';
  releaseNumber = '';
  smdgVersion = '';
  documentType = '';
  documentName = '';
  documentNo = '';
  documentVersion = '';
  documentRevision = '';
  documentResponseType = '';
  documentStatus = '';
  compilationDatetime = '';
  compilationDateTimeFormat = '';
  referenceType = '';
  referenceId = '';
  referenceDocument = '';
  referenceVersion = '';
  referenceRevision = '';

  transportCarrierResponsible = '';
  transportCode = '';
  transportType = '';
  transportResponsible = '';
  transportCountryCode = '';
  conveyanceRefNo = '';

  messageRefNo = '';
  releaseNo = '';

  segmentNo = '';
  referenceNo = '';

  loadingVoyageNo = '';
  interchangeControlCount = '';
  interchangeControlRefEnd = '';

  placeLocations = new Array<Place>();
  placeDates = new Array<Datetime>();
  remarks = new Array<Freetext>();

  getDisplayVersion(): string {
    // return this.smdgVersion.length > 4 ? this.smdgVersion.substring(4) : this.smdgVersion;
    return this.smdgVersion;
  }
  getTypeInitial(): string {
    return this.messageType.substring(0, 1);
  }
  getLocalVesselId(): string {
    if (this.callSign && this.callSign !== '') {
      return this.callSign;
    } else if (this.imoNo && this.imoNo !== '') {
      return this.imoNo;
    } else if (this.vesselCode && this.vesselCode !== '') {
      return this.vesselCode;
    } else if (this.vesselName && this.vesselName !== '') {
      return this.vesselName;
    }
    return '';
  }
}
