import {VisualPart} from '../../../core/pixi/visual-part';
import {Container} from '../../../service/model/container';
import * as PIXI from 'pixi.js';
import {ZoomLevel} from './bay-plan-dimensions';
import {ContentDisplayModes} from './content-display-modes';
import {BayPlanRoot} from '../../../core/pixi/bay-plan-root';
// import {BayPlanRootVisualPart} from './bay-plan-root.visual-part';

export class ContainerVisualPart extends VisualPart {

  private foreColor = 0x999999;
  private backColor = 0x000000;
  portInitial = 'T';
  private filteredOut = false;
  private filterColor = 0x000000;
  private filterAlpha = 1;

  private portInitText: PIXI.BitmapText;
  private leftBitmapText: PIXI.BitmapText;
  private rightBitmapText: PIXI.BitmapText;
  private cntrNoText: PIXI.Text;

  private readonly orangeColor = 0xFFA500;
  private readonly blueColor = 0x0000FF;
  private readonly redColor = 0xFF0000;
  private readonly yellowColor = 0xFFFF00;

  constructor(parentGraphics: PIXI.Container, public container: Container, foreColor?: number, backColor?: number) {
    super(parentGraphics);

    if (foreColor) {
      this.foreColor = foreColor;
    }
    if (backColor) {
      this.backColor = backColor;
    }
  }
  draw() {
    super.draw();

    let alpha = 1;

    if (this.selected) {
      alpha = 0.5;
      if (this.portInitText) {
        this.portInitText.visible = false;
      }
    } else {
      if (this.portInitText) {
        this.portInitText.visible = true;
      }
    }

    if (!this.filteredOut) {
      this.graphics.beginFill(this.backColor, alpha);
      this.graphics.lineStyle(0.5, 0x000000);
      this.graphics.drawRect(this.bounds.x, this.bounds.y, this.bounds.width, this.bounds.height);
      this.graphics.endFill();

    }
    if (this.filteredOut && this.filterAlpha > 0) {
      this.graphics.beginFill(this.filterColor, this.selected ? alpha : this.filterAlpha);
      this.graphics.drawRect(this.bounds.x, this.bounds.y, this.bounds.width, this.bounds.height);
      this.graphics.endFill();
    }

    if (this.container.getLength() > 20) {
      this.graphics.lineStyle(1, 0x999999)
        .moveTo(this.bounds.x, this.bounds.y)
        .lineTo(this.bounds.x + this.bounds.width, this.bounds.y + this.bounds.height);
    }
    if (this.getContentDisplayMode() === ContentDisplayModes.CONTAINER_NO) {

      if (this.getZoomLevel() === ZoomLevel.L1) {

      } else if (this.getZoomLevel() === ZoomLevel.L2) {
        this.drawZoomLevel2();
      } else if (this.getZoomLevel() === ZoomLevel.L3) {
        this.drawZoomLevel3();
      }

    }
    this.setTextBounds();

  }
  setBounds(x: number, y: number, width: number, height: number) {
    super.setBounds(x, y, width, height);
  }
  drawZoomLevel2() {

  }
  drawZoomLevel3() {
    const container = this.container;

    const root = this.getRoot() as BayPlanRoot;
    const sizeTypeMap = root.getSizeTypeMap();
    const sizeType = sizeTypeMap.get(container.isoSizeType);
    if (sizeType) {
      if ('012H' === sizeType.heightCode) {
        // this.graphics.lineStyle(1, this.toNumberColor(sizeType.heightBackColor), 1);
        this.graphics.beginFill(this.toNumberColor(sizeType.heightBackColor));
        this.graphics.drawRect(this.bounds.x + this.bounds.width / 3, this.bounds.y + 2, this.bounds.width / 4 - 1, this.bounds.height / 4 - 1);
        this.graphics.endFill();
      }
    }
    if (this.getCargoType() === 'RF') {
      this.graphics.beginFill(this.blueColor);
      this.graphics.drawRect(this.bounds.x + this.bounds.width / 4 * 3 - 4, this.bounds.y + 1, this.bounds.width / 4 + 3, this.bounds.height / 4 - 1);
      this.graphics.endFill();
    } else if (this.getCargoType() === 'DG') {
      this.graphics.beginFill(this.redColor);
      this.graphics.drawRect(this.bounds.x + this.bounds.width / 4 * 3 - 4, this.bounds.y + 1, this.bounds.width / 4 + 3, this.bounds.height / 4 - 1);
      this.graphics.endFill();
    } else if (this.getCargoType() === 'RD') {
      this.graphics.beginFill(this.blueColor);
      this.graphics.drawRect(this.bounds.x + this.bounds.width / 4 * 3 - 4, this.bounds.y + 1, (this.bounds.width / 4 + 3) / 2 + 1, this.bounds.height / 4 - 1);
      this.graphics.beginFill(this.redColor);
      this.graphics.drawRect(this.bounds.x + this.bounds.width / 4 * 3 - 4 + (this.bounds.width / 4 + 3) / 2 + 1, this.bounds.y + 1, (this.bounds.width / 4 + 2) / 2, this.bounds.height / 4 - 1);
      this.graphics.endFill();
    } else if (this.getCargoType() === 'BB') {
      this.graphics.beginFill(this.orangeColor);
      this.graphics.drawRect(this.bounds.x + this.bounds.width / 4 * 3 - 4, this.bounds.y + 1, this.bounds.width / 4 + 3, this.bounds.height / 4 - 1);
      this.graphics.endFill();
    } else if (this.getCargoType() === 'OG') {
      this.graphics.beginFill(this.yellowColor);
      this.graphics.drawRect(this.bounds.x + this.bounds.width / 4 * 2 - 4, this.bounds.y + 1, this.bounds.width / 4 + 3, this.bounds.height / 4 - 1);
      this.graphics.endFill();
    }
  }
  private displayPropertiesZoomLevel1() {

    if (this.getContentDisplayMode() === ContentDisplayModes.PORT_INITIAL) {
      if (!this.container.pod || this.container.pod.length < 3) {
        return;
      }

      this.graphics.removeChild(this.portInitText);
      this.portInitText = new PIXI.BitmapText(this.portInitial, { fontName: this.getFontName(), fontSize: 11, align: 'center' });
      this.graphics.addChild(this.portInitText);
      this.portInitial = this.container.pod.substr(2, 1);
      this.portInitText.text = this.portInitial;

    }

  }
  private displayPropertiesZoomLevel2() {
    this.graphics.removeChild(this.cntrNoText);
    this.cntrNoText = new PIXI.Text(this.getContentsZoomLevel2(), new PIXI.TextStyle(
      {fontFamily: 'Arial',
        fill: [this.toStringColor(this.foreColor)],
        fontSize: 9,
        align: 'left',
        fontWeight: 'bold',
        lineHeight: 9,
      }
    ));

    this.graphics.addChild(this.cntrNoText);
  }
  private displayPropertiesZoomLevel3() {

    if (this.getContentDisplayMode() === ContentDisplayModes.PORT_INITIAL) {
      if (!this.container.pod || this.container.pod.length < 3) {
        return;
      }

      this.graphics.removeChild(this.portInitText);
      this.portInitText = new PIXI.BitmapText(this.portInitial, { fontName: this.getFontName(), fontSize: 11, align: 'center' });
      this.graphics.addChild(this.portInitText);
      this.portInitial = this.container.pod.substr(2, 1);
      this.portInitText.text = this.portInitial;

    }

    if (this.getContentDisplayMode() === ContentDisplayModes.CONTAINER_NO) {

      this.graphics.removeChild(this.leftBitmapText);
      this.leftBitmapText = new PIXI.BitmapText(this.getContentsZoomLevel3(), { fontName: this.getFontName(), fontSize: 11, align: 'left' });
      this.graphics.addChild(this.leftBitmapText);


      this.graphics.removeChild(this.rightBitmapText);
      if (this.getCargoType() === 'RF' || this.getCargoType() === 'DG') {
        this.rightBitmapText = new PIXI.BitmapText(this.getCargoType(), { fontName: 'DefaultFont-white', fontSize: 11, align: 'right' });
      } else if (this.getCargoType() === 'MT') {
        this.rightBitmapText = new PIXI.BitmapText(this.getCargoType(), { fontName: this.getFontName(), fontSize: 11, align: 'right' });
      } else if (this.getCargoType() === 'RD' || this.getCargoType() === 'OG' || this.getCargoType() === 'BB') {
        this.rightBitmapText = new PIXI.BitmapText(this.getCargoType(), { fontName: 'DefaultFont', fontSize: 11, align: 'right' });
      }

      if (this.rightBitmapText) {
        this.graphics.addChild(this.rightBitmapText);
      }

    }
    // this.setTextBounds();
  }
  private setTextBounds() {

    if (this.portInitText) {
      const textX = this.bounds.x + (this.bounds.width - this.portInitText.width) / 2;
      const textY = this.bounds.y + (this.bounds.height - this.portInitText.height) / 2;
      if (textX > 0 && textY > 0) {
        this.portInitText.x = textX;
        this.portInitText.y = textY;
      } else {
        this.portInitText.x = this.getBounds().x + 1;
        this.portInitText.y = this.getBounds().y + 1;
      }
    }
    if (this.cntrNoText) {
      this.cntrNoText.x = this.bounds.x + 2;
      this.cntrNoText.y = this.bounds.y + 2;
    }

    if (this.leftBitmapText) {
      this.leftBitmapText.x = this.bounds.x + 2;
      this.leftBitmapText.y = this.bounds.y + 2;
    }
    if (this.rightBitmapText) {
      this.rightBitmapText.x = this.bounds.x + this.bounds.width / 3 * 2;
      this.rightBitmapText.y = this.bounds.y + 2;
    }
  }
  private getContentsZoomLevel3(): string {

    const container = this.container;
    if (!container) {
      return '';
    }
    const sizeTypeMap = (this.getRoot() as BayPlanRoot).getSizeTypeMap();
    const sizeType = sizeTypeMap.get(container.isoSizeType);

    let value = '';
    if (sizeType) {
      value = sizeType.lengthName;

      const heightCode = sizeType.heightCode.substr(3, 1);
      if (heightCode === 'H') {
        value = value + ' ' + heightCode;
      }
    } else {
      value = container.isoSizeType;
    }
    // value = value + '\n';
    if (!container.containerNo) {
      value = value + '\n\n';
    } else {
      value = value + '\n' + this.container.containerNo.substring(0, 4) + '\n' + this.container.containerNo.substring(4);
    }
    if (container.pod.length >= 3) {
      value = value + '\n' + this.container.pod.substring(2);
    } else {
      value = value + '\n' + this.container.pod;
    }
    return value;
  }
  private getContentsZoomLevel2(): string {
    const container = this.container;
    if (!container) {
      return '';
    }
    const sizeTypeMap = (this.getRoot() as BayPlanRoot).getSizeTypeMap();
    const sizeType = sizeTypeMap.get(container.isoSizeType);

    let value = '';
    if (sizeType) {
      value = sizeType.lengthName;

      const heightCode = sizeType.heightCode.substr(3, 1);
      if (heightCode === 'H') {
        value = value + ' ' + heightCode;
      }
    } else {
      value = container.isoSizeType;
    }
    value = value + '\n' + this.getCargoType();
    // if (container.pod.length >= 3) {
    //   value = value + '\n' + this.container.pod.substring(2);
    // } else {
    //   value = value + '\n' + this.container.pod;
    // }
    value = value + '\n' + this.getWeightTon();
    return value;
  }
  private getCargoType(): string {
    const container = this.container;

    if ('M' ===  container.fullEmpty) {
      return 'MT';
    } else if (container.temperature && container.dangerousGoods.length > 0) {
      return 'RD';
    } else if (container.temperature && container.dangerousGoods.length == 0) {
      return 'RF';
    } else if (!container.temperature && container.dangerousGoods.length > 0) {
      return 'DG';
    }
    // if (container.overSlotLeft > 0 || container.overSlotRight > 0 || container.overSlotBack || container.overSlotHeight) {
    //   return 'OG';
    // } else {
    //   if (container.breakBulkLength || container.breakBulkHeight || container.breakBulkWidth) {
    //     return 'BB';
    //   }
    // }
    return '';
  }
  private getWeightTon(): string {

    if (this.container.vgmWeightUnit === 'KGM') {
      return Math.round(Number(this.container.vgmWeight) / 100) / 10 + ' T';
    }

    if (this.container.grossWeightUnit === 'KGM') {
      return Math.round(Number(this.container.grossWeight) / 100) / 10 + ' T';
    }

    return '';
  }
  filterOut(alpha: number) {
    this.filterAlpha = alpha;
    this.filteredOut = true;
    this.draw();

  }
  changeColor(foreColor: string, backColor: string) {
    this.foreColor = this.toNumberColor(foreColor);
    this.backColor = this.toNumberColor(backColor);

    if (!this.portInitText) {
      this.graphics.removeChild(this.portInitText);
    }
    if (!this.leftBitmapText) {
      this.graphics.removeChild(this.leftBitmapText);
    }
    if (!this.rightBitmapText) {
      this.graphics.removeChild(this.rightBitmapText);
    }
    if (!this.cntrNoText) {
      this.graphics.removeChild(this.cntrNoText);
    }
    if (this.bounds.x <= 0  && this.bounds.y <= 0) {
      return;
    }
    this.displayPropertiesZoomLevel();

  }
  displayPropertiesZoomLevel() {
    if (this.getZoomLevel() === ZoomLevel.L1) {
      this.displayPropertiesZoomLevel1();
    }
    if (this.getZoomLevel() === ZoomLevel.L2) {
      this.displayPropertiesZoomLevel2();
    }
    if (this.getZoomLevel() === ZoomLevel.L3) {
      this.displayPropertiesZoomLevel3();
    }
    this.setTextBounds();
  }
  getForeColor(): number {
    return this.foreColor;
  }
  getBackColor(): number {
    return this.backColor;
  }
  removeFilter() {
    this.filteredOut = false;
    this.draw();
  }

  private toHex(r) {
    return r;
  }
  private toNumberColor(r) {
    return r.replace('#', '0x');
  }
  private toStringColor(r) {
    return  String(r).replace('0x', '#');
  }
  private getFontName() {
    return 'DefaultFont' + '-' + this.toStringColor(this.foreColor);
  }
  public getContentDisplayMode(): string {

    const root = this.getRoot();
    if (root) {

      return (this.getRoot() as BayPlanRoot).getContentDisplayMode();
    }
    return '';
  }
  getZoomLevel(): number {
    const root = this.getRoot();
    if (root) {

      return (this.getRoot() as BayPlanRoot).getZoomLevel();
    }
    return -1;
  }
}
