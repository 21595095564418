
export class SharedEdi {
  id = '';
  sharedAt: Date;
  granter = '';
  expiredAt: Date;
  grantee = '';
}

export class SharingInput {

  constructor(public ediId: string, public grantee: string) {

  }
}
