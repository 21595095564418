import {Injectable} from '@angular/core';
import {Container} from '../../service/model/container';
import {Dangerous} from '../../my-plans/service/model/dangerous';
import {Observable} from 'rxjs';
import {properties} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {VesselUtils} from '../../hull/service/vessel-utils';
import {IMDGTableUtils} from '../util/imdg-table.utils';
import {SyntaxMessageError} from '../../my-plans/service/model/syntax-message-error';
import {Bay} from '../../hull/model/bay';
import {MarkersProperty} from '../../panels/markers/model/markers.property';
import { Vessel } from 'src/app/hull/model/vessel';
import {BayPlan} from '../../service/model/bay-plan';

@Injectable({
  providedIn: 'root'
})
export class IMDGSegregationService {

  private vesselUtil;
  private IMDGCodes: Map<string, Array<Dangerous>> = new Map<string, Array<Dangerous>>();
  private containers: Map<string, Array<Container>> = new Map<string, Array<Container>>();
  private containerDgs: Map<string, Array<Container>> = new Map<string, Array<Container>>();

  private errorMessages = new  Array<SyntaxMessageError>();

  constructor(private http: HttpClient) {

  }
  getErrorMessages(): Array<SyntaxMessageError> {
    return this.errorMessages;
  }

  run(): SyntaxMessageError[] {
    const errorArray = new Array<SyntaxMessageError>();
    for (const containers of this.containerDgs.values()) {
      for (const source of containers) {
        this.segregationTable(source, errorArray);

        const targetContainers = new Array<Container>();
        this.getDgContainerList(targetContainers, source.stowage, -2);
        this.getDgContainerList(targetContainers, source.stowage, -1);
        this.getDgContainerList(targetContainers, source.stowage, 0);
        this.getDgContainerList(targetContainers, source.stowage, 1);
        this.getDgContainerList(targetContainers, source.stowage, 2);
        for (const target of targetContainers) {
          if (source.stowage === target.stowage) {
            continue;
          }
          this.segregationTableTarget(source, target, errorArray);
        }
      }
    }
    this.errorMessages = errorArray;
    return this.errorMessages;
  }

  private getContainerList(targetContainers: Array<Container>, stowage: string, count: number) {
    const tempList = this.containers.get(this.vesselUtil.getHatchName(stowage, count));
    if (tempList) {
      for (const temp of tempList) {
        targetContainers.push(temp);
      }
    }
  }
  private getDgContainerList(targetContainers: Array<Container>, stowage: string, count: number) {
    const tempList = this.containerDgs.get(this.vesselUtil.getHatchName(stowage, count));
    if (tempList) {
      for (const temp of tempList) {
        targetContainers.push(temp);
      }
    }
  }
  private segregationTable(source: Container, errorArray: Array<SyntaxMessageError>) {
    const IMDGUtils = new IMDGTableUtils();
    const duplicateIMDG = new Map<string, string>();
    for (const sourceIMDG of source.dangerousGoods) {
      const sourceDangerous = this.retrieveDangerous(sourceIMDG);
      const sourceDeck = this.vesselUtil.isDeck(source.stowage);
      const sourceRowIndex = this.vesselUtil.getRowIndex(source.stowage, sourceDeck);
      const sourceTierIndex = this.vesselUtil.getTierIndex(source.stowage);

      for (const targetIMDG of source.dangerousGoods) {
        if (sourceIMDG.unno === targetIMDG.unno) {
          continue;
        }
        if (duplicateIMDG.get(sourceIMDG.unno || targetIMDG.unno) || duplicateIMDG.get(targetIMDG.unno || sourceIMDG.unno)) {
          continue;
        }
        duplicateIMDG.set(sourceIMDG.unno || targetIMDG.unno, targetIMDG.unno || sourceIMDG.unno);
        const targetDangerous = this.retrieveDangerous(targetIMDG);
        const segregation = IMDGUtils.segregation(sourceDangerous, targetDangerous);
        const segregationGroup = IMDGUtils.segregationGroup(sourceDangerous, targetDangerous);
        if (segregation >= '1' && segregation !== 'X') {
          const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_IMDG);
          error.syntaxName = MarkersProperty.ITEM_IMDG_CO_TABLE;
          error.description =  source.containerNo + ' ; ' + sourceDangerous.imdg + '/' + sourceDangerous.unno + ' must be ' + this.getSegregationDescription(segregation)
            + targetDangerous.imdg + '/' + targetDangerous.unno + '.';
          error.stowage = source.stowage;
          errorArray.push(error);
        }

        if (segregationGroup >= '1' && segregation !== 'X') {
          const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_IMDG);
          error.syntaxName = MarkersProperty.ITEM_IMDG_CO_GROUPS;
          error.description = source.containerNo + ' ; ' + sourceDangerous.imdg + '/' + sourceDangerous.unno + ' must be ' + this.getSegregationDescription(segregationGroup)
            + targetDangerous.imdg + '/' + targetDangerous.unno + '.';
          error.stowage = source.stowage;
          errorArray.push(error);
        }
      }

      const sourceCategory = IMDGUtils.segregationCategory(sourceDangerous);
      if (sourceCategory === '05' || sourceCategory === 'C' || sourceCategory === 'D') {
        if (!sourceDeck) {
          const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_IMDG);
          error.syntaxName = MarkersProperty.ITEM_IMDG_CATEGORY;
          error.description = source.containerNo + ' ; ' + sourceDangerous.imdg + '/' + sourceDangerous.unno + ' must be stowed only on deck.';
          error.stowage = source.stowage;
          errorArray.push(error);
        }
      }

      if (sourceDeck) {
        if (sourceIMDG.marinePollutant) {
          if (sourceRowIndex === 0 || sourceRowIndex === this.vesselUtil.getBay(source.stowage).deckSection.rowList.length - 1) {
            const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_IMDG);
            error.syntaxName = MarkersProperty.ITEM_IMDG_MP;
            error.description = source.containerNo + ' ; ' + sourceDangerous.imdg + '/' + sourceDangerous.unno + ' is a marine pollutant.';
            error.stowage = source.stowage;
            errorArray.push(error);
          }
        }
        let shade = false;
        const targetContainers = new Array<Container>();
        this.getContainerList(targetContainers, source.stowage, 0);
        for (const container of targetContainers) {
          const targetDeck = this.vesselUtil.isDeck(container.stowage);
          if (targetDeck) {
            const targetRowIndex = this.vesselUtil.getRowIndex(container.stowage, targetDeck);
            const targetTierIndex = this.vesselUtil.getTierIndex(container.stowage);
            if (this.vesselUtil.getBayPhysicalGap(source.stowage, container.stowage) < 2) {
              if ((sourceRowIndex === targetRowIndex && sourceTierIndex > targetTierIndex)
                || (Math.abs(sourceRowIndex - targetRowIndex) === 1 && sourceTierIndex === targetTierIndex)) {
                shade = true;
                break;
              }
            }
          }
        }
        if (!shade) {
          const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_IMDG);
          error.syntaxName = MarkersProperty.ITEM_IMDG_SHADE;
          error.description = source.containerNo + ' ; ' + sourceDangerous.imdg + '/' + sourceDangerous.unno + ' must be shaded from direct sunlight.';
          error.stowage = source.stowage;
          errorArray.push(error);
        }
      }
    }
  }
  private getSegregationDescription(seq: string): string {
    if (seq === '1') {
      return 'away from ';
    } else if (seq === '2') {
      return 'separated from ';
    } else if (seq === '3') {
      return 'separated by a complete compartment ';
    } else if (seq === '4') {
      return 'separated longitudinally by an intervening complete compartment ';
    }
    return '';
  }
  private segregationTableTarget(source: Container, target: Container, errorArray: Array<SyntaxMessageError>) {
    const IMDGUtils = new IMDGTableUtils();
    const duplicateIMDG = new Map<string, string>();
    for (const sourceIMDG of source.dangerousGoods) {
      const sourceDangerous = this.retrieveDangerous(sourceIMDG);
      const sourceDeck = this.vesselUtil.isDeck(source.stowage);
      const sourceRowIndex = this.vesselUtil.getRowIndex(source.stowage, sourceDeck);
      const sourceTierIndex = this.vesselUtil.getTierIndex(source.stowage);

      for (const targetIMDG of target.dangerousGoods) {
        if (sourceIMDG.unno === targetIMDG.unno) {
          continue;
        }
        if (duplicateIMDG.get(sourceIMDG.unno || targetIMDG.unno) || duplicateIMDG.get(targetIMDG.unno || sourceIMDG.unno)) {
          continue;
        }
        duplicateIMDG.set(sourceIMDG.unno || targetIMDG.unno, targetIMDG.unno || sourceIMDG.unno);
        const targetDangerous = this.retrieveDangerous(targetIMDG);
        let segregation = IMDGUtils.segregation(sourceDangerous, targetDangerous);
        const segregationGroup = IMDGUtils.segregationGroup(sourceDangerous, targetDangerous);
        if (segregationGroup && segregation < segregationGroup) {
          segregation = segregationGroup;
        }

        let valid = true;
        const targetDeck = this.vesselUtil.isDeck(target.stowage);
        const targetTierIndex = this.vesselUtil.getTierIndex(target.stowage);
        const bayGap = this.vesselUtil.getBayPhysicalGap(source.stowage, target.stowage);
        // Horizontal (Fore and aft)
        if (segregation === '1' && bayGap < 1) {
          valid = false;
        } else if (segregation === '2' && bayGap < 2) {
          valid = false;
        } else if (segregation === '3' && bayGap < 3) {
          valid = false;
        } else if (segregation === '4' && bayGap < 5) {
          valid = false;
        }

        if (!valid) {

          valid = true;
          // Horizontal (Athwart ships)
          const targetRowIndex = this.vesselUtil.getRowIndex(target.stowage, targetDeck);
          if (sourceRowIndex === targetRowIndex) {
            valid = false;
          // } else if (segregation === '1' && sourceDeck === targetDeck && Math.abs(sourceRowIndex - targetRowIndex) < 2) {
          //   valid = false;
          } else if (segregation === '2' && sourceDeck === targetDeck && Math.abs(sourceRowIndex - targetRowIndex) < 2) {
            valid = false;
          } else if (segregation === '3' && sourceDeck === targetDeck && Math.abs(sourceRowIndex - targetRowIndex) < 3) {
            valid = false;
          } else if (segregation === '4') {
            valid = false;
          }
        }

        if (!valid) {
          valid = true;
          // Vertical
          if ((segregation === '1' && sourceDeck === targetDeck && Math.abs(sourceTierIndex - targetTierIndex)) < 2
              || ((segregation === '2' || segregation === '3') && sourceDeck === targetDeck) || segregation === '4') {
            if (source.stowage.substring(source.stowage.length - 4, source.stowage.length - 2) === target.stowage.substring(target.stowage.length - 4, target.stowage.length - 2)) {
              valid = false;
            }
          }
        }

        if (!valid) {
          if (segregation < segregationGroup) {
            const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_IMDG);
            error.syntaxName = MarkersProperty.ITEM_IMDG_GROUPS;
            error.description = source.containerNo + ' (' + sourceDangerous.imdg + '/' + sourceDangerous.unno + ') must be segregated from '
              + target.containerNo + ' (' + targetDangerous.imdg + '/' + targetDangerous.unno + ') which is at ' + target.stowage;
            error.stowage = source.stowage;
            errorArray.push(error);
          } else {
            const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_IMDG);
            error.syntaxName = MarkersProperty.ITEM_IMDG_TABLE;
            error.description = source.containerNo + ' (' + sourceDangerous.imdg + '/' + sourceDangerous.unno + ') must be segregated from '
              + target.containerNo + ' (' + targetDangerous.imdg + '/' + targetDangerous.unno + ') which is at ' + target.stowage;
            error.stowage = source.stowage;
            errorArray.push(error);
          }
          break;
        }
      }
    }
  }

  private retrieveDangerous(dangerous: Dangerous): Dangerous {
    const codes = this.IMDGCodes.get(dangerous.unno);
    // if (!codes) {
    //   this.retrieveIMDGCodes(dangerous.unno).subscribe(data => {
    //       if (data) {
    //         this.IMDGCodes.set(dangerous.unno, data);
    //         return this.findDangerous(data, dangerous);
    //       } else {
    //         return dangerous;
    //       }
    //     }
    //     ,
    //     error => {
    //       console.error(error);
    //     }
    //   );
    // }
    return this.findDangerous(codes, dangerous);
  }

  private findDangerous(dangerousGoods: Array<Dangerous>, IMDG: Dangerous): Dangerous {
    if (!dangerousGoods || dangerousGoods.length === 0) {
      return IMDG;
    } else if (dangerousGoods.length === 1) {
      return dangerousGoods[0];
    }
    for (const dangerous of dangerousGoods) {
      if (dangerous.unno === IMDG.unno && dangerous.imdg === IMDG.imdg && dangerous.packingGroup === IMDG.packingGroup) {
        return dangerous;
      }
    }
    for (const dangerous of dangerousGoods) {
      if (dangerous.unno === IMDG.unno && dangerous.goodsLabelMark1 === IMDG.goodsLabelMark1) {
        return dangerous;
      }
    }
    return dangerousGoods[0];
  }

  initData(vessel: Vessel, bayPlan: BayPlan): Observable<string> {

    this.IMDGCodes = new Map<string, Array<Dangerous>>();
    this.containers = new Map<string, Array<Container>>();
    this.containerDgs = new Map<string, Array<Container>>();

    this.vesselUtil = new VesselUtils(vessel);

    const unNoMaps = new Map<string, Dangerous>();
    for (const container of bayPlan.containers) {
      const hatchName = this.vesselUtil.getHatchName(container.stowage, 0);
      let sectionContainer = this.containers.get(hatchName);
      if (sectionContainer === undefined) {
        sectionContainer = new Array<Container>();
      }
      sectionContainer.push(container);
      this.containers.set(hatchName, sectionContainer);

      if (container.dangerousGoods.length > 0) {
        sectionContainer = this.containerDgs.get(hatchName);
        if (sectionContainer === undefined) {
          sectionContainer = new Array<Container>();
        }
        sectionContainer.push(container);
        for (const dangerous of container.dangerousGoods) {
          unNoMaps.set(dangerous.unno, dangerous);
        }

        this.containerDgs.set(hatchName, sectionContainer);
      }
    }
    // let unNos = '';
    // for (const unNo of unNoMaps.keys()) {
    //   unNos += (unNos.length > 0 ? ',' : '') + unNo;
    // }
    const unNos = Array.from(unNoMaps.keys()).join(',');
    return new Observable<string>((observer)  => {

      if (unNos) {
        this.http.get<Dangerous[]>(properties.serverUrl + '/dangerous_goods?UNNO=' + unNos).subscribe(data => {
          for (const dangerous of data) {
            let codes = this.IMDGCodes.get(dangerous.unno);
            if (!codes) {
              codes = new Array<Dangerous>();
            }
            codes.push(dangerous);
            this.IMDGCodes.set(dangerous.unno, codes);
          }
          observer.next('');
          observer.complete();
        }, error => {
          observer.error(error);
          observer.complete();
        });

      } else {
        observer.next('');
        observer.complete();

      }
    });
  }

  invalidDangerousSpace(unNo: string): Map<string, boolean> {
    const sourceIMDG = new Dangerous();
    sourceIMDG.unno = unNo;
    const sourceDangerous = this.retrieveDangerous(sourceIMDG);
    const IMDGUtils = new IMDGTableUtils();
    // this.vesselUtil = new VesselUtils(this.vessel);
    const invalidMap = new Map<string, boolean>();

    for (const containers of this.containerDgs.values()) {
      for (const container of containers) {
        for (const dangerous of container.dangerousGoods) {
          const targetDangerous = this.retrieveDangerous(dangerous);
          const segregation = IMDGUtils.segregation(sourceDangerous, targetDangerous);

          let bays = new Array<Bay>();
          if (segregation === '1') {
            bays = this.vesselUtil.getBayArray(container.stowage, 0);
          } else if (segregation === '2') {
            bays = this.vesselUtil.getBayArray(container.stowage, 1);
          } else if (segregation === '3') {
            bays = this.vesselUtil.getBayArray(container.stowage, 2);
          } else if (segregation === '4') {
            bays = this.vesselUtil.getBayArray(container.stowage, 3);
          }

          for (const bay of bays) {
            this.invalidStowage(invalidMap, bay, container.stowage, segregation);
          }
        }
      }
    }
    return invalidMap;
  }
  private invalidStowage(invalidMap: Map<string, boolean>, bay: Bay, sourceStowage: string, segregation: string) {
    const sourceDeck = this.vesselUtil.isDeck(sourceStowage);
    const sourceRowIndex = this.vesselUtil.getRowIndex(sourceStowage, sourceDeck);
    const sourceTierIndex = this.vesselUtil.getTierIndex(sourceStowage);

    if (sourceDeck) {
      for (const cell of bay.deckSection.cellList) {
        let valid = true;
        const targetRowIndex = this.vesselUtil.getRowIndex(cell.stowage, true);
        const targetTierIndex = this.vesselUtil.getTierIndex(cell.stowage);
        if (sourceRowIndex === targetRowIndex) {
          valid = false;
        } else if (segregation === '2' && Math.abs(sourceRowIndex - targetRowIndex) < 2) {
          valid = false;
        } else if (segregation === '3' && Math.abs(sourceRowIndex - targetRowIndex) < 3) {
          valid = false;
        } else if (segregation === '4') {
          valid = false;
        }

        if (!valid) {
          valid = true;
          if ((segregation === '1' && Math.abs(sourceTierIndex - targetTierIndex) < 2)
            || (segregation === '2' || segregation === '3' || segregation === '4')) {
            if (sourceStowage.substring(sourceStowage.length - 4, sourceStowage.length - 2) === cell.stowage.substring(cell.stowage.length - 4, cell.stowage.length - 2)) {
              valid = false;
            }
          }
        }

        if (!valid) {
          invalidMap.set(cell.stowage, false);
        }
      }
    }
    if (!sourceDeck || segregation === '4') {
      for (const cell of bay.holdSection.cellList) {
        invalidMap.set(cell.stowage, false);
      }
    }
  }

}
