import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';

export interface DialogData {
  unno: string;
}

@Component({
  selector: 'app-loadable-space-dialog',
  templateUrl: './loadable-space-dialog.html'
})
export class LoadableSapceDialog {

  constructor(public dialogRef: MatDialogRef<LoadableSapceDialog>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
