import {EdiHeaderPatch} from '../../../service/model/edi-header-patch';
import {EdiHeaderExt} from '../../../service/model/edi-header-ext';

export class VesselSchedule implements EdiHeaderPatch, EdiHeaderExt {

  id = '';
  fileName = '';
  messageType = '';
  versionNo = '';
  messageFunction = '';
  sender = '';
  recipient = '';
  imoNo = '';
  callSign = '';
  vesselCode = '';
  vesselCodeType = '';
  vesselName = '';
  portCode = '';
  nextPortCode = '';
  voyageNo = '';
  ata = '';
  etd = '';
  atd = '';
  nextEta = '';
  carrier = '';

  preparationDate = '';
  preparationTime = '';
  serviceLaneCode = '';

  smdgVersion = '';
  compilationDatetime = '';
  messageRefNo = '';
  documentNo = '';
  interchangeControlCount = '';
  interchangeControlRefStart = '';
  interchangeControlRefEnd = '';


  ediId = '';
  updateDatetime = '';
  updaterId = '';
  updaterName = '';

  creatorName = '';
  creationDatetime = '';

  vesselId = '';
  serviceLaneForeColor = '';
  serviceLaneBackColor = '';
  ediQty: number;

  fullUnits: number;
  emptyUnits: number;
  reeferUnits: number;
  breakBulkUnits: number;
  overDimensionUnits: number;
  overSlotUnits: number;
  handlingInstructionUnits: number;
  dgUnits: number;
  markers: number;


}
