import {Component, OnInit, EventEmitter, ViewChild, Output, Input, ElementRef} from '@angular/core';
import {UserGroupService} from '../service/user-group.service';
import {MyTeam} from '../service/model/my-team';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {AlertService} from '../../service/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {OkDialogComponent} from '../../dialogs/ok-dialog/ok-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EventService} from '../../core/event/event.service';
import {EventIds} from '../../core/event/event-ids';
import {TabButtonEvent} from '../../core/event/tab-button.event';
import {TabAction} from '../../core/event/tab-action';
import {AbstractEditableTableComponent} from '../../be-com/etable/abstract-editable-table.component';
import {EditableColumn} from '../../be-com/etable/editable-column';
import {TeamDeletionDialogComponent} from './team-deletion-dialog/team-deletion-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent extends AbstractEditableTableComponent implements OnInit {

  @Output()
  showProgressEvent = new EventEmitter<boolean>();

  @Input()
  tabIndex: number;

  @Input()
  buttonEmitter: EventEmitter<TabButtonEvent>;

  public form: FormGroup;

  private eventEmitter = new EventEmitter<any>();

  submitted = false;

  constructor(private userGroupService: UserGroupService,
              private alertService: AlertService,
              private eventService: EventService,
              private dialog: MatDialog,
              private translate: TranslateService,
              protected snackBar: MatSnackBar,
              private fb: FormBuilder,
              protected elementRef: ElementRef) {
    super(elementRef, snackBar);
  }

  ngOnInit(): void {
    this.search();
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      visibility: '003PR',
    });
    this.eventEmitter.subscribe(data => {
      if (data === 'refresh') {
        this.search();
      }
    });
    this.buttonEmitter.subscribe(event => {
      if (this.tabIndex !== event.tabIndex) {
        return;
      }
      if (event.action === TabAction.SEARCH) {
        this.search();
      }
    });
    super.ngOnInit(true);
    this.search();
  }
  search(): void {
    this.userGroupService.getMyTeams().subscribe(items => {

      this.dataSource = new MatTableDataSource<MyTeam>(items);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (data, headerId) => {
        return this.getValueFormatted(data as MyTeam, headerId);
      };
      this.selection.clear();

    }, error => {
      this.showErrorDialog(error);
    });
  }

  get f() { return this.form.controls; }

  createTeam() {
    this.submitted = true;
    this.alertService.clear();

    if (this.form.invalid) {
      return;
    }
    const self = this;
    this.userGroupService.createNewTeam(this.form.value)
      .pipe(first())
      .subscribe(data => {
          this.alertService.success('Created', true);
          this.eventService.emit(EventIds.TEAMS_UPDATED);
          this.snackBar.open('Created', 'Close', {
            duration: 2000,
          });
          this.search();
        },
        error => {
          this.alertService.error(error);

          let message = error.error;

          if (message === 'PB-0002') {
            message = 'The team name is ready in use.';
            const dialogRef = this.dialog.open(OkDialogComponent, {
              maxWidth: '800px',
              data: {header: error.statusText, message}
            });
            dialogRef.afterClosed().subscribe(ok => {
              this.eventEmitter.emit('refresh');
            });
          } else {
            const dialogRef = this.showErrorDialog(error);
            dialogRef.afterClosed().subscribe(ok => {
              this.eventEmitter.emit('refresh');
            });
          }

        }
      );


  }
  onSubmit(): void {
    this.submitted = true;
    // if (this.registerForm.invalid) {
    //   return;
    // }
  }
  delete(team: MyTeam): void {

    const confirmDialog = this.dialog.open(TeamDeletionDialogComponent, {
      width: '500px',
      data: team.name
    });

    confirmDialog.afterClosed().subscribe(arg => {

      if (arg.data) {
        this.userGroupService.disableTeam(team.id).subscribe(data => {

            this.eventService.emit(EventIds.TEAMS_UPDATED);
            this.openSnackBar('Deleted', 'Close');
            this.search();
          },
          error => {

            this.showErrorDialog(error, true);
          }
        );
      }
    });
  }

  getValue(item: MyTeam, code: string) {
    if (code === ColumnCodes.Name) {
      return item.name;
    }
    if (code === ColumnCodes.RoleCode) {
      return item.roleCode;
    }
    if (code === ColumnCodes.RoleName) {
      return item.roleName;
    }
    if (code === ColumnCodes.Members) {
      return item.members;
    }
    if (code === ColumnCodes.CreationDatetime) {
      return item.creationDatetime.toString();
    }

    return '';
  }
  canManage(item: MyTeam): boolean {

    if (item.roleCode === '002A') {
      return true;
    }
    return false;
  }

  fireSelectedItem(index: number) {
  }

  getValueFormatted(item: MyTeam, code: string) {
    return this.getValue(item, code);
  }

  initColumns(): EditableColumn[] {
    const columns = new Array<EditableColumn>();
    columns.push(new EditableColumn(ColumnCodes.Name, ColumnCodes.Name, EditableColumn.CELL_SELECTION | EditableColumn.EDITABLE));
    columns.push(new EditableColumn(ColumnCodes.RoleName, ColumnCodes.RoleName, EditableColumn.LINE_SELECTION));
    columns.push(new EditableColumn(ColumnCodes.Members, ColumnCodes.Members, EditableColumn.LINE_SELECTION));
    columns.push(new EditableColumn(ColumnCodes.CreationDatetime, ColumnCodes.CreationDatetime, EditableColumn.LINE_SELECTION));
    columns.push(new EditableColumn(ColumnCodes.Delete, ''));
    return columns;
  }

  setValue(item: MyTeam, code: string, value: string): boolean {
    // console.info(value);
    if (code === ColumnCodes.Name && this.canManage(item)) {
      item.name = value;
      return true;
    }

    return false;
  }

  save() {
    const teams = this.selection.selected as MyTeam[];

    teams.forEach(team => {
      if (!this.canManage(team)) {
        this.snackBar.open('Not authorized to change \'' + team.name + '\'', 'Close', {
          duration: 2000,
        });
      }
    });

    const param = teams.filter(team => this.canManage(team));

    this.userGroupService.updateTeamNames(param).subscribe(data => {
      this.eventService.emit(EventIds.TEAMS_UPDATED);
      this.openSnackBar('Saved', 'Close');
      this.search();
    }, error => {
      this.showErrorDialog(error, true);
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  showErrorDialog(error: any, searchAgain?: boolean) {
    console.error(error);
    this.showProgressEvent.emit(false);
    let errorMessage = error.message;
    if (typeof error.error === 'string') {
      errorMessage = this.translate.instant(error.error);
    }
    const dialogRef = this.dialog.open(OkDialogComponent, {
      maxWidth: '800px',
      data: {header: error.statusText, message: errorMessage}
    });
    if (searchAgain) {
      dialogRef.afterClosed().subscribe(ok => {
        this.search();
      });
    }
    return dialogRef;
  }
}
enum ColumnCodes {
  Name = 'Name',
  RoleCode = 'RoleCode',
  RoleName = 'Role',
  Members = 'Members',
  CreationDatetime = 'Created at',
  Delete = 'Delete'
}

