import {Container} from './container';
import {ContainerFilter} from './container-filter';
import {SizeType} from '../../code/size-type/service/model/size-type';
import {Freetext} from '../../my-plans/service/model/freetext';

export class ContainerFilterUtil {

  static filterOut(filter: ContainerFilter, container: Container, sizeTypeMap: Map<string, SizeType>): boolean {

    if (filter.weight && !(filter.minWeight <= Number(container.vgmWeight) && Number(container.vgmWeight) <= filter.maxWeight )) {
      return true;
    }
    if (filter.stowage && container.stowage.toUpperCase().search( filter.stowage.toUpperCase() ) < 0) {
      return true;
    }
    if (filter.containerNo && container.containerNo.toUpperCase().search( filter.containerNo.toUpperCase() ) < 0) {
      return true;
    }

    if (filter.bayMap.size > 0 && container.stowage.length >= 3 && filter.bayMap.get(container.stowage.substring(0, 3)) === undefined) {
      return true;
    }
    if (filter.multiContainerMap.size > 0 && filter.multiContainerMap.get(container.containerNo) === undefined) {
      return true;
    }
    if (filter.multiStowageMap.size > 0 && filter.multiStowageMap.get(container.stowage) === undefined) {
      return true;
    }
    if (filter.carrierMap.size > 0 && filter.carrierMap.get(container.carrier) === undefined) {
      return true;
    }
    if (filter.porMap.size > 0  && filter.porMap.get(container.por) === undefined) {
      return true;
    }
    if (filter.polMap.size > 0  && filter.polMap.get(container.pol) === undefined) {
      return true;
    }
    if (filter.podMap.size > 0  && filter.podMap.get(container.pod) === undefined) {
      return true;
    }
    if (filter.optPodMap.size > 0  && (filter.optPodMap.get(container.optPod) === undefined
      && filter.optPodMap.get(container.optPod1) === undefined
      && filter.optPodMap.get(container.optPod2) === undefined
      && filter.optPodMap.get(container.optPod3) === undefined
      && filter.optPodMap.get(container.optPod4) === undefined
      && filter.optPodMap.get(container.optPod5) === undefined
    )) {
      return true;
    }
    if (filter.tsPortMap.size > 0  && filter.tsPortMap.get(container.tsPort) === undefined) {
      return true;
    }

    if (filter.nPodMap.size > 0  && filter.nPodMap.get(container.nPod) === undefined) {
      return true;
    }
    if (filter.fPodMap.size > 0  && filter.fPodMap.get(container.fPod) === undefined) {
      return true;
    }
    if (filter.placeOfDeliveryMap.size > 0  && filter.placeOfDeliveryMap.get(container.placeOfDelivery) === undefined) {
      return true;
    }
    if (filter.isoSizeTypeMap.size > 0 && filter.isoSizeTypeMap.get(container.isoSizeType) === undefined) {
      return true;
    }
    if (filter.lengthCodeMap.size > 0 || filter.heightCodeMap.size > 0 || filter.typeCodeMap.size > 0) {

      const sizeType = sizeTypeMap.get(container.isoSizeType);

      if (filter.lengthCodeMap.size > 0 && filter.lengthCodeMap.get(sizeType.lengthCode) === undefined) {
        return true;
      }
      if (filter.heightCodeMap.size > 0 && filter.heightCodeMap.get(sizeType.heightCode) === undefined) {
        return true;
      }
      if (filter.typeCodeMap.size > 0 && filter.typeCodeMap.get(sizeType.typeCode) === undefined) {
        return true;
      }
    }
    if (filter.fullEmptyMap.size > 0 && filter.fullEmptyMap.get(container.fullEmpty) === undefined) {
      return true;
    }
    if (filter.cargoTypeMap.size > 0 && filter.cargoTypeMap.get(container.getCargoTypeCode()) === undefined) {
      return true;
    }
    if (filter.imdgMap.size > 0) {

      let found = false;
      if (container.dangerousGoods) {
        container.dangerousGoods.forEach(dg => {
          if (filter.imdgMap.get(dg.imdg)) {
            found = true;
          }
        });
      }
      return !found;
    }
    if (filter.bundledYnMap.size > 0 && filter.bundledYnMap.get(container.hasBundledContainers() ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.breakBulkYnMap.size > 0 && filter.breakBulkYnMap.get(container.hasBreakBulk() ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.overDimensionYnMap.size > 0 && filter.overDimensionYnMap.get(container.hasOverDimension() ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.overSlotYnMap.size > 0 && filter.overSlotYnMap.get(container.hasOverSlot() ? 'Y' : 'N' ) === undefined) {
      return true;
    }
    if (filter.temperatureRangeYnMap.size > 0 && filter.temperatureRangeYnMap.get(container.hasTemperatureRange() ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.vgmYnMap.size > 0 && filter.vgmYnMap.get(container.vgmWeight ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.vgmCertificationCodeMap.size > 0 && filter.vgmCertificationCodeMap.get(container.vgmCertificationCode) === undefined) {
      return true;
    }
    if (filter.vgmInfoYnMap.size > 0 && filter.vgmInfoYnMap.get(container.hasVgmInfo() ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.groupCodeYnMap.size > 0 && filter.groupCodeYnMap.get(container.groupCode ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.groupCodeMap.size > 0 && filter.groupCodeMap.get(container.groupCode) === undefined) {
      return true;
    }
    if (filter.descriptionOfGoodsMap.size > 0) {
      let filteredOut = true;

      const list = container.freeTexts.get('AAA') as Array<Freetext>;

      if (list && list.length > 0) {
        list.forEach(fx => {
          if (filter.descriptionOfGoodsMap.get(fx.remark)) {
            filteredOut = false;
          }
        });
      }
      if (filteredOut) {
        return filteredOut;
      }
    }
    if (filter.handlingInstructionMap.size > 0) {
      let filteredOut = true;

      const list = container.freeTexts.get('HAN') as Array<Freetext>;

      if (list && list.length > 0) {
        list.forEach(fx => {
          if (filter.handlingInstructionMap.get(fx.remark)) {
            filteredOut = false;
          }
        });
      }
      if (filteredOut) {
        return filteredOut;
      }
    }
    if (filter.loadingRemarksMap.size > 0) {
      let filteredOut = true;

      const list = container.freeTexts.get('CLR') as Array<Freetext>;

      if (list && list.length > 0) {
        list.forEach(fx => {
          if (filter.loadingRemarksMap.get(fx.remark)) {
            filteredOut = false;
          }
        });
      }
      if (filteredOut) {
        return filteredOut;
      }
    }
    if (filter.specialCargoInstructionMap.size > 0) {
      let filteredOut = true;

      const list = container.freeTexts.get('SIN') as Array<Freetext>;

      if (list && list.length > 0) {
        list.forEach(fx => {
          if (filter.specialCargoInstructionMap.get(fx.remark)) {
            filteredOut = false;
          }
        });
      }
      if (filteredOut) {
        return filteredOut;
      }
    }
    if (filter.generalInformationMap.size > 0) {
      let filteredOut = true;

      const list = container.freeTexts.get('AAI') as Array<Freetext>;

      if (list && list.length > 0) {
        list.forEach(fx => {
          if (filter.generalInformationMap.get(fx.remark)) {
            filteredOut = false;
          }
        });
      }
      if (filteredOut) {
        return filteredOut;
      }
    }
    if (filter.mutuallyDefinedRemarksMap.size > 0) {
      let filteredOut = true;

      const list = container.freeTexts.get('ZZZ') as Array<Freetext>;

      if (list && list.length > 0) {
        list.forEach(fx => {
          if (filter.mutuallyDefinedRemarksMap.get(fx.remark)) {
            filteredOut = false;
          }
        });
      }
      if (filteredOut) {
        return filteredOut;
      }
    }
    if (filter.specialCargoInstructionYnMap.size > 0 && filter.specialCargoInstructionYnMap.get(container.specialCargoInstruction ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.loadingRemarkYnMap.size > 0 && filter.loadingRemarkYnMap.get(container.loadingRemark ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.generalInformationYnMap.size > 0 && filter.generalInformationYnMap.get(container.generalInformation ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.mutuallyDefinedRemarkYnMap.size > 0 && filter.mutuallyDefinedRemarkYnMap.get(container.mutuallyDefinedRemark ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.descriptionOfGoodsYnMap.size > 0 && filter.descriptionOfGoodsYnMap.get(container.descriptionOfGoods ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.cargoNatureYnMap.size > 0 && filter.cargoNatureYnMap.get(container.cargoNatures ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.packageQuantityYnMap.size > 0 && filter.packageQuantityYnMap.get(container.packageQuantity ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.packageTypeYnMap.size > 0 && filter.packageTypeYnMap.get(container.packageType ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.blYnMap.size > 0 && filter.blYnMap.get(container.blNo ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.bookingNoYnMap.size > 0 && filter.bookingNoYnMap.get(container.bookingNo ? 'Y' : 'N') === undefined) {
      return true;
    }
    if (filter.porTerminalMap.size > 0 && filter.porTerminalMap.get(container.porTerminalCode) === undefined) {
      return true;
    }
    if (filter.polTerminalMap.size > 0 && filter.polTerminalMap.get(container.polTerminalCode) === undefined) {
      return true;
    }
    if (filter.podTerminalMap.size > 0 && filter.podTerminalMap.get(container.podTerminalCode) === undefined) {
      return true;
    }
    if (filter.optPodTerminalMap.size > 0 && (filter.optPodTerminalMap.get(container.optPodTerminalCode) === undefined
      && filter.optPodTerminalMap.get(container.optPod1TerminalCode) === undefined
      && filter.optPodTerminalMap.get(container.optPod2TerminalCode) === undefined
      && filter.optPodTerminalMap.get(container.optPod3TerminalCode) === undefined
      && filter.optPodTerminalMap.get(container.optPod4TerminalCode) === undefined
      && filter.optPodTerminalMap.get(container.optPod5TerminalCode) === undefined
    )) {
      return true;
    }
    if (filter.tsTerminalMap.size > 0 && filter.tsTerminalMap.get(container.tsTerminalCode) === undefined) {
      return true;
    }
    if (filter.nPodTerminalMap.size > 0 && filter.nPodTerminalMap.get(container.nPodTerminalCode) === undefined) {
      return true;
    }
    if (filter.fPodTerminalMap.size > 0 && filter.fPodTerminalMap.get(container.fPodTerminalCode) === undefined) {
      return true;
    }
    if (filter.placeOfDeliveryTerminalMap.size > 0 && filter.placeOfDeliveryTerminalMap.get(container.placeOfDeliveryTerminalCode) === undefined) {
      return true;
    }
    return false;
  }
  static checkNull(value: string): string {
    if (value) {
      return value;
    } else {
      return '';
    }
  }

}
