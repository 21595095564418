import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UserGroupService} from '../service/user-group.service';
import {AlertService} from '../../service/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {AuthEventId, AuthService} from '../service/auth.service';
import {MyMember, UserGroupMemberInput} from '../service/model/my-member';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {CommonService} from '../../service/common.service';
import {CommonCode} from '../../service/model/common-code';
import {UserService} from '../service/user.service';
import {OkDialogComponent} from '../../dialogs/ok-dialog/ok-dialog.component';
import {AbstractEditableTableComponent} from '../../be-com/etable/abstract-editable-table.component';
import { EditableColumn } from 'src/app/be-com/etable/editable-column';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TabButtonEvent} from '../../core/event/tab-button.event';
import {TabAction} from '../../core/event/tab-action';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent extends AbstractEditableTableComponent implements OnInit, OnDestroy {

  @Output()
  showProgressEvent = new EventEmitter<boolean>();

  @Input()
  tabIndex: number;

  @Input()
  buttonEmitter: EventEmitter<TabButtonEvent>;

  form: FormGroup;
  defaultRoleCode =  '002C';

  submitted = false;

  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  roleCodes: CommonCode [];
  selectedRoleCode = '002B';

  activeTeamName = '';

  private authSubscription: Subscription;

  constructor(private authService: AuthService,
              private userService: UserService,
              private userGroupService: UserGroupService,
              private commonService: CommonService,
              private alertService: AlertService,
              protected snackBar: MatSnackBar,
              private translate: TranslateService,
              private dialog: MatDialog,
              private fb: FormBuilder,
              protected elementRef: ElementRef) {
    super(elementRef, snackBar);
  }

  ngOnInit(): void {

    this.authSubscription = this.authService.getEmitter().subscribe(event => {
      if (event.id === AuthEventId.CHANGE_TEAM) {

        this.setActiveTeam();
        this.search();
      }
    });

    this.commonService.getRoleCodes().subscribe(data => {
      this.roleCodes = data;

    });
    this.buttonEmitter.subscribe(event => {
      if (this.tabIndex !== event.tabIndex) {
        return;
      }
      if (event.action === TabAction.SEARCH) {
        this.search();
      }
    });

    this.search();
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
      visibility: [''],
    });

    this.filteredOptions = this.form.controls.email.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );


    super.ngOnInit(false);
    this.setActiveTeam();

  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  search(): void {

    if (!this.eligibleToEdit()) {
      this.dataSource = new MatTableDataSource();
      return;
    }
    this.userGroupService.getMyMembers(this.authService.getTokenClaim().active_group_id).subscribe(items => {

      this.dataSource = new MatTableDataSource<MyMember>(items);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (data, headerId) => {
        return this.getValueFormatted(data as MyMember, headerId).toUpperCase();
      };
      // this.selection.clear();
      this.deselectRow();

    }, error => {
      this.showErrorDialog(error);
    });
  }
  get f() { return this.form.controls; }
  eligibleToEdit(): boolean {

    return this.authService.eligibleToEdit();
  }
  inviteMember(): void {

    this.userService.findUser(this.form.controls.email.value).subscribe(list => {

      if (list.length === 0) {
        console.error('there is no such user');

        const dialogRef = this.dialog.open(OkDialogComponent, {
          width: '250px',
          data: {header: 'Error', message:  'No such user exists'}
        });
        return;
      }
      if (list.length > 1) {

        const dialogRef = this.dialog.open(OkDialogComponent, {
          width: '250px',
          data: {header: 'Error', message:  'Multiple users exist with the email addreass.'}
        });
        return;
      }

      const profile = list[0];

      const input = new UserGroupMemberInput();
      input.memberId = profile.id;
      input.userGroupId = this.authService.getTokenClaim().active_group_id;
      input.roleCode = this.selectedRoleCode;


      this.userGroupService.inviteMember(input).subscribe(data => {
          this.form.controls.email.setValue('');

          this.snackBar.open('Invited', 'Close', {
            duration: 2000,
          });
          this.search();
        }
        , error => {
          this.showErrorDialog(error, true);
        });
    }, error => {
      this.showErrorDialog(error, true);
    });
  }



  focusOut($event: FocusEvent) {
    // console.info($event);
  }

  enter($event: KeyboardEvent) {


  }

  updateRole(id: string, value: string) {

    this.userGroupService.updateMemberRole(id, value).subscribe(data => {
        // const dialogRef = this.dialog.open(OkDialogComponent, {
        //   width: '250px',
        //   data: {header: '', message:  'OK'}
        // });
        // dialogRef.afterClosed().subscribe(ok => {
        //   self.editableId = ''
        //   self.search();
        // });
        this.snackBar.open('Updated', 'Close', {
          duration: 2000,
        });
        this.search();
      },
      error => {
        this.showErrorDialog(error, true);
      }
    );
  }

  removeMember(id: string) {

    const self = this;

    this.userGroupService.removeMember(id).subscribe( data => {

        this.snackBar.open('Removed', 'Close', {
          duration: 2000,
        });
        this.search();
      },
      error => {
        this.showErrorDialog(error, true);

      }
    );
  }
  setActiveTeam() {

    const activeTeamId = this.authService.getTokenClaim().active_group_id;
    this.userGroupService.getMyTeams().subscribe(teams => {

       teams.forEach(team => {
         if (team.id === activeTeamId) {
           this.activeTeamName = team.name;
         }
       });
    }, error => {
      this.showErrorDialog(error, true);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
  getValueFormatted(item: MyMember, code: string) {

    if (code === ColumnCodes.RoleName) {

      if (this.isMySelf(item)) {
        return 'Owner';
      }

      return item.roleName;
    }
    return this.getValue(item, code);
  }
  setValue(item: MyMember, code: string, value: string): boolean {

    if (code === ColumnCodes.RoleName) {
      item.roleCode = value;
      return true;
    }
    return false;
  }
  fireSelectedItem(index: number) {
  }
  initColumns(): EditableColumn[] {

    const columns = new Array<EditableColumn>();
    // columns.push(new EditableColumn(ColumnCodes.Username, ColumnCodes.Username));
    columns.push(new EditableColumn(ColumnCodes.Email, ColumnCodes.Email));
    columns.push(new EditableColumn(ColumnCodes.RoleName, ColumnCodes.RoleName));
    columns.push(new EditableColumn(ColumnCodes.FullName, ColumnCodes.FullName));
    columns.push(new EditableColumn(ColumnCodes.Remove, ''));
    return columns;
  }
  getValue(item: MyMember, code: string) {
    // if (code === ColumnCodes.Username) {
    //   return item.username;
    // }
    if (code === ColumnCodes.Email) {
      return item.email;
    }
    if (code === ColumnCodes.RoleName) {
      return item.roleCode;
    }
    if (code === ColumnCodes.FullName) {
      return item.fullName;
    }
    if (code === ColumnCodes.Remove) {
      return 'Remove';
    }

    return '';
  }
  showPlanLabel(rowIndex: number, column: EditableColumn): boolean {
    const code = column.code;

    if (code === ColumnCodes.Email
      || code === ColumnCodes.FullName
      || (code === ColumnCodes.RoleName && !this.isColumnInEditMode(rowIndex, column))
    ) {
      return true;
    }
    return false;
  }
  isMySelf(item: MyMember) {

    return this.authService.getTokenClaim().id === item.userId;
  }
  showErrorDialog(error: any, searchAgain?: boolean) {
    console.error(error);
    this.showProgressEvent.emit(false);
    let errorMessage = error.message;
    if (typeof error.error === 'string') {
      errorMessage = this.translate.instant(error.error);
    }
    const dialogRef = this.dialog.open(OkDialogComponent, {
      maxWidth: '800px',
      data: {header: error.statusText, message: errorMessage}
    });
    if (searchAgain) {
      dialogRef.afterClosed().subscribe(ok => {
        this.search();
      });
    }
    return dialogRef;
  }
}
enum ColumnCodes {
  Email = 'Email',
  RoleName = 'Role',
  FullName = 'Full name',
  Remove = 'Remove'
}
