export class Freetext {
  type = '';
  vgmCertificationCode = '';
  remark = '';     // + vgmInfo1
  vgmInfo2 = '';
  vgmInfo3 = '';
  vgmInfo4 = '';
  vgmInfo5 = '';
  constructor() {
  }

}
