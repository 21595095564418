import {EditableModel} from '../../../../be-com/etable/editable-model';

export class Operator implements EditableModel {

  id: string;
  code = '';
  ownCode = '';
  outCode = '';
  secondaryCode = '';
  name = '';
  foreColor = '';
  backColor = '';
  foreColor2 = '';
  backColor2 = '';
  editType = '';
  modified = false;
}
