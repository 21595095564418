<mat-card class="material" fxLayout="column" fxLayoutAlign="start stretch">
  <div><h2>My teams</h2></div>
  <div>
    <div fxLayout="row" fxLayoutAlign="space-between center" >
      <form  [formGroup]="form" (ngSubmit)="createTeam()">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field class="stack">
            <mat-label for="name">Team name</mat-label>
            <input matInput formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required">Username is required</div>
              <div *ngIf="f.username.errors.minlength">Username must be at least 4 characters</div>
              <div *ngIf="f.username.errors.maxlength">Maximum 20 characters are allowed.</div>
            </div>
          </mat-form-field>
          <!--        <mat-form-field class="stack">-->
          <!--          <input matInput formControlName="visibility" value="003PR"/>-->
          <!--        </mat-form-field>-->
          <button mat-raised-button  color="primary" color="basic" type="submit"
                  matTooltip="New team"
                  matTooltipClass="tooltip"
                  [disabled]="!form.valid">New team</button>
        </div>
      </form>
      <div>
        <button mat-raised-button class="primary" color="primary" (click)="save()">Save</button>
<!--        <button mat-raised-button class="primary" color="accent" (click)="delete()">Delete</button>-->
      </div>

    </div>

  </div>
  <div class="table">
    <table id="editTable"
           mat-table
           [dataSource]="dataSource" matSort
           class="mat-elevation-z3"
           class="topics"
           (contextmenu)="$event.preventDefault()"
    >
      <ng-container matColumnDef="select" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element; let i = index"
            (mousedown)="mouseDownOnCell($event, element, i, selectionBoxColumn)"
            (mouseup)="mouseUpOnCell($event, i, selectionBoxColumn)"
            (mouseenter)="mouseEnterOnCell($event, i, selectionBoxColumn)"
            (mouseleave)="mouseLeaveOnCell($event, i, selectionBoxColumn)"
        >
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(element) : null"
                        [checked]="selection.isSelected(element)"
                        [aria-label]="checkboxLabel(element)">
          </mat-checkbox>
          <container *ngIf="element.modified" style="margin-left: 4px">*</container>
        </td>
      </ng-container>
      <ng-container *ngFor="let column of columns" matColumnDef="{{column.code}}" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            (mousedown)="mouseDownOnHeader(column)"
            matTooltip="{{column.name}}"
            matTooltipClass="tooltip">
          <div [ngClass]="getStyleClass('', column.code)">{{column.name}}</div>
        </th>
        <td mat-cell *matCellDef="let element; let i = index"
            (mousedown)="mouseDownOnCell($event, element, i, column)"
            (mouseup)="mouseUpOnCell($event, i, column)"
            (mouseenter)="mouseEnterOnCell($event, i, column)"
            (mouseleave)="mouseLeaveOnCell($event, i, column)"
            [ngClass]="getTdStyleClass(i, column, element)"
        >
          <div *ngIf="column.code !== 'Delete'"
               [ngClass]="getStyleClass(element, column.code)"
               [style.background]="getBackColor(element, column)"
               [style.color]="getForeColor(element, column)" >
            <span *ngIf="column.code === 'Created at'">{{getValueFormatted(element, column.code) | date: 'medium'}}</span>
            <span *ngIf="column.code !== 'Created at'">{{getValueFormatted(element, column.code)}}</span>
          </div>
          <ng-container *ngIf="column.code === 'Delete'">
            <button mat-stroked-button type="submit" *ngIf="canManage(element)" (click)="delete(element)" color="warn">Delete</button>
          </ng-container>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headerCodes; sticky: true" ></tr>
      <tr mat-row *matRowDef="let row; columns: headerCodes;let i = index"
          [ngClass]="{ 'etable-selected': isReadOnlyRowSelected(row)}">
    </table>
    <br>
    <mat-paginator [pageSizeOptions]="[100, 500]"></mat-paginator>
  </div>
</mat-card>
