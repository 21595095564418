import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {EventIds} from '../../core/event/event-ids';
import {MenuUrls} from '../../core/menu/menu-urls';
import {SessionIds, SessionValues} from '../../core/service/session-ids';
import {ToolbarEventService} from '../../core/event/toolbar-event.service';
import {EventService} from '../../core/event/event.service';
import {ContainerFilter} from '../../service/model/container-filter';
import {ContainerFilterService} from '../../service/container-filter.service';
import {LocalPreferenceIds} from '../../service/local-preference-ids';
import {ContainerSelectionEventService} from '../../core/event/container-selection-event.service';

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightPanelComponent implements OnInit, OnDestroy {

  private eventSubscription;
  private toolbarEventSubscription;
  private containerSelectionSubscription;

  showHelp = false;

  showLegend = false;
  showProperties = false;
  showSelections = false;
  showColumns = false;
  showMovinsPalette = false;
  showChangeOfDischarge = false;
  showPreferences = false;
  showContainerFilter = false;

  readonly LEGEND = 'LEGEND';
  readonly SELECTIONS = 'SELECTIONS';
  readonly COLUMNS = 'COLUMNS';
  readonly PROPERTIES = 'PROPERTIES';
  readonly MOVINSPALETTE = 'MOVINSPALETTE';
  readonly CHANGE_OF_DISCHARGE = 'CHANGE_OF_DISCHARGE';
  readonly HELP = 'HELP';
  readonly PREFERENCES = 'PREFERENCES';
  readonly CONTAINER_FILTER = 'CONTAINER_FILTER';

  selectedTab = this.HELP;
  // numOfFilters = 0;
  filter = new ContainerFilter();

  constructor(private eventService: EventService,
              private containerSelectionEventService: ContainerSelectionEventService,
              private toolbarEventService: ToolbarEventService,
              private filterService: ContainerFilterService,
              private cdr: ChangeDetectorRef) {

    // this.configureSelectionMode(sessionStorage.getItem(SessionIds.SELECTION_MODE));


  }
  configureMenuUrl(menuUrl: string, selectionMode: string) {

    if (!selectionMode) {
      selectionMode = SessionValues.SELECTION_MODE_INFO;
      sessionStorage.setItem(SessionIds.SELECTION_MODE, SessionValues.SELECTION_MODE_INFO);
    }

    this.showLegend = false;
    this.showProperties = false;
    this.showSelections = false;
    this.showColumns = false;
    this.showMovinsPalette = false;
    this.showPreferences = false;
    this.showChangeOfDischarge = false;
    this.showContainerFilter = false;

    if (menuUrl === MenuUrls.EDIT_BAY) {

      if (!this.selectedTab) {
        this.selectedTab = this.PROPERTIES;
      }

      this.showLegend = true;
      this.showColumns = true;
      this.showContainerFilter = true;

      if (selectionMode === SessionValues.SELECTION_MODE_INFO) {
        this.showProperties = true;
      }
      if (selectionMode === SessionValues.SELECTION_MODE_SHIFT) {
        // this.showSelections = true;
        this.showProperties = true;
      }
      if (selectionMode === SessionValues.SELECTION_MODE_COD) {
        // this.showSelections = true;
      }
      if (selectionMode === SessionValues.SELECTION_MODE_MOVINS) {
        this.showMovinsPalette = true;
      }
    }
    if (menuUrl === MenuUrls.EDIT_LIST) {
      if (!this.selectedTab) {
        this.selectedTab = this.PROPERTIES;
      }
      this.showLegend = true;
      this.showProperties = true;
      this.showColumns = true;
      this.showContainerFilter = true;
    }

    if (menuUrl === MenuUrls.EDIT_CHARTS) {
      if (!this.selectedTab) {
        this.selectedTab = this.LEGEND;
      }
      this.showLegend = true;
    }
    if (menuUrl === MenuUrls.EDIT_SUMMARY) {
      if (!this.selectedTab) {
        this.selectedTab = this.LEGEND;
      }
      this.showLegend = true;
    }
    if (menuUrl === MenuUrls.COMPARE_BAY) {
      if (!this.selectedTab) {
        this.selectedTab = this.LEGEND;
      }
      this.showLegend = true;
    }
    if (menuUrl === MenuUrls.COMPARE_LIST) {
      if (!this.selectedTab) {
        this.selectedTab = this.LEGEND;
      }
      this.showLegend = true;
    }
    if (menuUrl === MenuUrls.COMPARE_CHARTS) {
      if (!this.selectedTab) {
        this.selectedTab = this.LEGEND;
      }
      this.showLegend = true;
    }
    if (menuUrl === MenuUrls.COMPARE_SUMMARY) {
      if (!this.selectedTab) {
        this.selectedTab = this.LEGEND;
      }
      this.showLegend = true;
    }
    if (menuUrl === MenuUrls.CODE_OPERATOR) {

    }
    if (menuUrl === MenuUrls.CODE_PORT) {

    }
    if (menuUrl === MenuUrls.CODE_VESSEL) {

    }
    if (menuUrl === MenuUrls.CODE_WEIGHT_GROUP) {

    }
    if (menuUrl === MenuUrls.CODE_SERVICE_LANE) {

    }
    if (menuUrl === MenuUrls.CODE_SIZE_TYPE) {

    }
  }
  configureSelectionMode(selectionMode: string) {

    if (!selectionMode) {
      selectionMode = SessionValues.SELECTION_MODE_INFO;
      sessionStorage.setItem(SessionIds.SELECTION_MODE, SessionValues.SELECTION_MODE_INFO);
    }
    this.showLegend = true;
    this.showProperties = false;
    this.showSelections = false;
    this.showChangeOfDischarge = false;
    this.showMovinsPalette = false;

    if (selectionMode === SessionValues.SELECTION_MODE_INFO) {
      this.showProperties = true;
    }
    if (selectionMode === SessionValues.SELECTION_MODE_SHIFT) {
      this.showProperties = true;
    }
    if (selectionMode === SessionValues.SELECTION_MODE_COD){
      this.showChangeOfDischarge = true;
    }
    if (selectionMode === SessionValues.SELECTION_MODE_MOVINS) {
      this.showMovinsPalette = true;
    }
  }
  ngOnInit(): void {

    this.selectedTab = localStorage.getItem(LocalPreferenceIds.SELECTED_RIGHT_TAB);
    this.filter = this.filterService.filter;

    this.eventSubscription = this.eventService.getEmitter().subscribe(event => {
      if (event.id === EventIds.APPLY_FILTER) {
        this.filter = event.newValue as ContainerFilter;
        this.cdr.detectChanges();
        return;
      }
      if (event.id === EventIds.FILTER_FROM_LIST) {

        this.clickButton(this.PROPERTIES);
        this.cdr.detectChanges();
        return;
      }

      if (event.id === EventIds.LOAD_BAY_PLAN) {

        const selectedTab = localStorage.getItem(LocalPreferenceIds.SELECTED_RIGHT_TAB);

        if (selectedTab === this.CONTAINER_FILTER) {
          this.clickButton('');
          this.cdr.detectChanges();
          this.clickButton(selectedTab);
          this.cdr.detectChanges();
        }
      }
      if (event.id !== EventIds.ROUTE_CHANGE) {
        return;
      }

      this.configureMenuUrl(event.newValue, sessionStorage.getItem(SessionIds.SELECTION_MODE));
      this.cdr.detectChanges();
    });
    this.toolbarEventSubscription = this.toolbarEventService.getEmitter().subscribe(event => {
      if (event.id !== EventIds.CHANGE_SELECTION_MODE) {
        return;
      }
      this.configureSelectionMode(event.newValue);

      this.cdr.detectChanges();
    });
    this.containerSelectionSubscription = this.containerSelectionEventService.getEmitter().subscribe(event => {

      if (this.selectedTab !== this.PROPERTIES) {

        if (event.newValue && event.newValue.length > 0) {

          this.selectedTab = this.PROPERTIES;
          localStorage.setItem(LocalPreferenceIds.SELECTED_RIGHT_TAB, this.selectedTab);
          this.cdr.detectChanges();
        }
      }
    });

    let selectionMode = sessionStorage.getItem(SessionIds.SELECTION_MODE);
    if (!selectionMode) {
      selectionMode = SessionValues.SELECTION_MODE_INFO;
    }
    this.configureSelectionMode(selectionMode);
  }

  clickButton(tab: string) {
    this.selectedTab = tab;
    localStorage.setItem(LocalPreferenceIds.SELECTED_RIGHT_TAB, tab);
  }

  ngOnDestroy(): void {

    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.toolbarEventSubscription) {
      this.toolbarEventSubscription.unsubscribe();
    }
    if (this.containerSelectionSubscription) {
      this.containerSelectionSubscription.unsubscribe();
    }
  }
  isSelected(tab: string): boolean {

    return this.selectedTab === tab;
  }
}
