<div class="app mat-typography"
     [dir]="options.dir"
     [ngClass]="{'app-dark': options.dark, 'boxed': options.boxed, 'collapsed-sidebar': options.collapsed, 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }">
  <ngx-loading-bar color="#009688"></ngx-loading-bar>
  <app-header (toggleSidenav)="sidemenu.toggle()" (toggleNotificationSidenav)="notifications.toggle()"></app-header>
  <mat-sidenav-container class="app-inner" >
    <mat-sidenav #sidemenu class="sidebar-panel" id="sidebar-panel" [mode]="isOver() ? 'over' : 'side'" [opened]="isLeftSideOpened()"
      (openedStart)="sidePanelOpened = true" (closedStart)="sidePanelOpened = false" (mouseover)="menuMouseOver()"
      (mouseout)="menuMouseOut()" [perfectScrollbar]="config" [disabled]="isScreenSmall" >
      <app-menu (click)="updatePS()" [direction]="options.dir"></app-menu>
    </mat-sidenav>
<!--    <mat-sidenav #notifications position="end" class="chat-panel" mode="side" opened="true" >-->
    <mat-sidenav #notifications  position="end" mode="side" opened="{{rightPanelOpened}}" style="width: 300px"
      (openedChange)="onRightPanelOpenedChange(notifications.opened)" >
      <app-right-panel></app-right-panel>
    </mat-sidenav>
    <div [perfectScrollbar]="config" [disabled]="isScreenSmall">

      <as-split direction="vertical"
                unit="pixel" (click)="click()">
        <as-split-area id="topArea">
          <router-outlet></router-outlet>
        </as-split-area>
        <as-split-area [size]="bottomHeight" id="bottomArea" *ngIf="bottomPanelVisible.visible()">
          <app-bottom-panel [bottomHeight]='bottomHeight - 26' style="height: {{bottomHeight}}px" [bottomPanelVisible] ="bottomPanelVisible"></app-bottom-panel>
        </as-split-area>
      </as-split>

    </div>
  </mat-sidenav-container>

  <!-- Demo Purposes Only -->
<!--  <app-options (messageEvent)="receiveOptions($event)"></app-options>-->
  <!-- /Demo Purposes Only -->
</div>

<!--<app-home *ngIf="!isLoggedIn()"></app-home>-->
