export class MarkersProperty {

  static CATEGORY_SYNTAX = 'Syntax Validation';
  static ITEM_SYNTAX_CONSTANTS = 'Invalid identifier';
  static ITEM_SYNTAX_LOOP = 'Too many occurrences';
  /*static ITEM_SYNTAX_MANDATORY = 'Mandatory';*/
  static ITEM_SYNTAX_MISS_ELEMENT = 'Mandatory element missing';
  static ITEM_SYNTAX_NUMBER = 'Number type only';
  static ITEM_SYNTAX_LENGTH = 'Out of length';

  static CATEGORY_STOWAGE = 'Stowage Validation';
  static ITEM_STOWAGE_CONTAINER_NO = 'Container no. duplication';
  static ITEM_STOWAGE_NULL_CONTAINER_NO = 'Null container no.';
  static ITEM_STOWAGE_DUPLICATE = 'Location duplication';
  static ITEM_STOWAGE_SIZE_TYPE = 'Invalid size/type';
  static ITEM_STOWAGE_INVALID = 'Invalid Stowage';
  static ITEM_STOWAGE_OVERLAP = 'Containers overlapped';

  static CATEGORY_IMDG = 'IMDG Segregation';
  static ITEM_IMDG_TABLE = 'Segregation Table';
  static ITEM_IMDG_GROUPS = 'Segregation Groups';
  static ITEM_IMDG_CO_TABLE = 'Segregation Table (Co-Loaded)';
  static ITEM_IMDG_CO_GROUPS = 'Segregation Groups (Co-Loaded)';
  static ITEM_IMDG_CATEGORY = 'Stowage Category';
  static ITEM_IMDG_MP = 'Marine Pollutant';
  static ITEM_IMDG_SHADE = 'Protected Shade';

  static getSyntaxItems() {
    return [this.ITEM_SYNTAX_CONSTANTS, this.ITEM_SYNTAX_LOOP, this.ITEM_SYNTAX_MISS_ELEMENT,
            this.ITEM_SYNTAX_NUMBER, this.ITEM_SYNTAX_LENGTH];
  }

  static getStowageItems() {
    return [this.ITEM_STOWAGE_CONTAINER_NO, this.ITEM_STOWAGE_NULL_CONTAINER_NO, this.ITEM_STOWAGE_DUPLICATE, this.ITEM_STOWAGE_SIZE_TYPE, this.ITEM_STOWAGE_INVALID,
      this.ITEM_STOWAGE_OVERLAP];
  }

  static getImdgItems() {
    return [this.ITEM_IMDG_TABLE, this.ITEM_IMDG_GROUPS, this.ITEM_IMDG_CO_TABLE, this.ITEM_IMDG_CO_GROUPS, this.ITEM_IMDG_CATEGORY,
      this.ITEM_IMDG_MP, this.ITEM_IMDG_SHADE];
  }

}
