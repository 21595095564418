import {EventEmitter, Injectable} from '@angular/core';
import {VisualEvent} from './visual-event';
import {Container} from '../../service/model/container';
import {EventIds} from './event-ids';
import {ContainerVisualPart} from '../../edit/bay-plan/visual-part/container.visual-part';

@Injectable({
  providedIn: 'root'
})
export class ContainerSelectionEventService {

  private eventEmitter = new EventEmitter<VisualEvent>();
  selections = new Array<Container>();
  constructor() {
    this.eventEmitter.subscribe(event => {
      if (event.id === EventIds.SELECT_VISUAL_PARTS) {
        this.selections = new Array<Container>();
        if (event.newValue) {

          if (event.newValue instanceof Array) {
            event.newValue.forEach(data => {
              if (data instanceof ContainerVisualPart) {
                const vp = data as ContainerVisualPart;

                this.selections.push(vp.container);
              }
            });
          }
        }
      }
      if (event.id === EventIds.CONTAINERS_SELECTED) {
        this.selections = event.newValue;
      }
      if (event.id === EventIds.CHANGE_SELECTION_MODE
      ) {
        this.selections = new Array<Container>();
        // this.emit(EventIds.SELECT_VISUAL_PARTS, null);
      }
    });
  }
  emit(id: string, newValue?: any, source?: any) {
    this.eventEmitter.emit(new VisualEvent(id, newValue, source));
  }
  getEmitter() {
    return this.eventEmitter;
  }
  unsubscribe() {
    return this.eventEmitter.unsubscribe();
  }
}
