import { Injectable } from '@angular/core';
import {ContainerFilter} from './model/container-filter';
import {EventService} from '../core/event/event.service';
import {EventIds} from '../core/event/event-ids';
import {SessionIds} from '../core/service/session-ids';
import {ColumnIds} from '../panels/columns/service/model/column-ids';

@Injectable({
  providedIn: 'root'
})
export class ContainerFilterService {

  filter = new ContainerFilter();
  alphaValue: 0;
  numOfFilters = 0;

  hatchFilter: string[];
  multiContainerFilter: string;
  multiStowageFilter: string;
  carrierFilter: string[];

  porFilter: string[];
  polFilter: string[];
  podFilter: string[];
  optPodFilter: string[];
  tsPortFilter: string[];
  nPodFilter: string[];
  fPodFilter: string[];
  placeOfDeliveryFilter: string[];

  isoSizeTypeFilter: string[];
  lengthFilter: string[];
  heightFilter: string[];
  typeFilter: string[];

  fullEmptyFilter: string[];
  cargoTypeFilter: string[];
  imdgFilter: string[];

  bundledYnFilter: string[];
  breakBulkYnFilter: string[];
  overDimensionYnFilter: string[];
  overSlotYnFilter: string[];
  temperatureRangeYnFilter: string[];

  vgmYnFilter: string[];
  vgmCertificationCodeFilter: string[];
  vgmInfoYnFilter: string[];

  groupCodeYnFilter: string[];
  groupCodeFilter: string[];


  descriptionOfGoodsFilter: string[];
  handlingInstructionFilter: string[];
  loadingRemarksFilter: string[];
  specialCargoInstructionFilter: string[];
  generalInformationFilter: string[];
  mutuallyDefinedRemarksFilter: string[];


  specialCargoInstructionYnFilter: string[];
  loadingRemarkYnFilter: string[];
  generalInformationYnFilter: string[];
  mutuallyDefinedRemarkYnFilter: string[];
  descriptionOfGoodsYnFilter: string[];
  cargoNatureYnFilter: string[];
  packageQuantityYnFilter: string[];
  packageTypeYnFilter: string[];

  blYnFilter: string[];
  bookingNoYnFilter: string[];

  porTerminalFilter: string[];
  polTerminalFilter: string[];
  podTerminalFilter: string[];
  optPodTerminalFilter: string[];
  tsTerminalFilter: string[];
  nPodTerminalFilter: string[];
  fPodTerminalFilter: string[];
  placeOfDeliveryTerminalFilter: string[];

  constructor(private eventService: EventService) {

    const filterStr = sessionStorage.getItem(SessionIds.FILTER);

    if (filterStr) {
      this.filter = new ContainerFilter();
      this.filter.applyFromString(filterStr);
    }

    eventService.getEmitter().subscribe(event => {
      if (event.id === EventIds.APPLY_FILTER) {
        this.filter = event.newValue;
        const str = this.filter.toString();
        sessionStorage.setItem(SessionIds.FILTER, str);
      }
    });
  }
  acceptFilter() {

    this.hatchFilter = Array.from(this.filter.hatchMap.keys());

    this.multiContainerFilter = Array.from(this.filter.multiContainerMap.keys()).join('\n');
    this.multiStowageFilter = Array.from(this.filter.multiStowageMap.keys()).join('\n');
    this.carrierFilter = Array.from(this.filter.carrierMap.keys());

    this.porFilter = Array.from(this.filter.porMap.keys());
    this.polFilter = Array.from(this.filter.polMap.keys());
    this.podFilter = Array.from(this.filter.podMap.keys());
    this.optPodFilter = Array.from(this.filter.optPodMap.keys());
    this.tsPortFilter = Array.from(this.filter.tsPortMap.keys());
    this.nPodFilter = Array.from(this.filter.nPodMap.keys());
    this.fPodFilter = Array.from(this.filter.fPodMap.keys());
    this.placeOfDeliveryFilter = Array.from(this.filter.placeOfDeliveryMap.keys());

    this.isoSizeTypeFilter = Array.from(this.filter.isoSizeTypeMap.keys());
    this.lengthFilter = Array.from(this.filter.lengthCodeMap.keys());
    this.heightFilter = Array.from(this.filter.heightCodeMap.keys());
    this.typeFilter = Array.from(this.filter.typeCodeMap.keys());

    this.fullEmptyFilter = Array.from(this.filter.fullEmptyMap.keys());
    this.cargoTypeFilter = Array.from(this.filter.cargoTypeMap.keys());
    this.imdgFilter = Array.from(this.filter.imdgMap.keys());

    this.bundledYnFilter = Array.from(this.filter.bundledYnMap.keys());
    this.breakBulkYnFilter = Array.from(this.filter.breakBulkYnMap.keys());
    this.overDimensionYnFilter = Array.from(this.filter.overDimensionYnMap.keys());
    this.overSlotYnFilter = Array.from(this.filter.overSlotYnMap.keys());
    this.temperatureRangeYnFilter = Array.from(this.filter.temperatureRangeYnMap.keys());

    this.vgmYnFilter = Array.from(this.filter.vgmYnMap.keys());
    this.vgmCertificationCodeFilter = Array.from(this.filter.vgmCertificationCodeMap.keys());
    this.vgmInfoYnFilter = Array.from(this.filter.vgmInfoYnMap.keys());

    this.groupCodeYnFilter = Array.from(this.filter.groupCodeYnMap.keys());
    this.groupCodeFilter = Array.from(this.filter.groupCodeMap.keys());

    this.descriptionOfGoodsFilter = Array.from(this.filter.descriptionOfGoodsMap.keys());
    this.handlingInstructionFilter = Array.from(this.filter.handlingInstructionMap.keys());
    this.loadingRemarksFilter = Array.from(this.filter.loadingRemarksMap.keys());
    this.specialCargoInstructionFilter = Array.from(this.filter.specialCargoInstructionMap.keys());
    this.generalInformationFilter = Array.from(this.filter.generalInformationMap.keys());
    this.mutuallyDefinedRemarksFilter = Array.from(this.filter.mutuallyDefinedRemarksMap.keys());

    this.specialCargoInstructionYnFilter = Array.from(this.filter.specialCargoInstructionYnMap.keys());
    this.loadingRemarkYnFilter = Array.from(this.filter.loadingRemarkYnMap.keys());
    this.generalInformationYnFilter = Array.from(this.filter.generalInformationYnMap.keys());
    this.mutuallyDefinedRemarkYnFilter = Array.from(this.filter.mutuallyDefinedRemarkYnMap.keys());
    this.descriptionOfGoodsYnFilter = Array.from(this.filter.descriptionOfGoodsYnMap.keys());
    this.cargoNatureYnFilter = Array.from(this.filter.cargoNatureYnMap.keys());
    this.packageQuantityYnFilter = Array.from(this.filter.packageQuantityYnMap.keys());
    this.packageTypeYnFilter = Array.from(this.filter.packageTypeYnMap.keys());

    this.blYnFilter = Array.from(this.filter.blYnMap.keys());
    this.bookingNoYnFilter = Array.from(this.filter.bookingNoYnMap.keys());

    this.porTerminalFilter = Array.from(this.filter.porTerminalMap.keys());
    this.polTerminalFilter = Array.from(this.filter.polTerminalMap.keys());
    this.podTerminalFilter = Array.from(this.filter.podTerminalMap.keys());
    this.tsTerminalFilter = Array.from(this.filter.tsTerminalMap.keys());
    this.nPodTerminalFilter = Array.from(this.filter.nPodTerminalMap.keys());
    this.fPodTerminalFilter = Array.from(this.filter.fPodTerminalMap.keys());
    this.placeOfDeliveryTerminalFilter = Array.from(this.filter.placeOfDeliveryTerminalMap.keys());

  }
  public applyFilter(item?: string) {

    if (this.hatchFilter) {
      this.filter.hatchMap = new Map<string, string>();
      this.filter.bayMap = new Map<string, string>();
      this.hatchFilter.forEach(data => {this.filter.apply(ColumnIds.HATCH, data); } );
    }
    if (item === 'multiContainersFilter') {
      if (this.multiContainerFilter) {
        this.filter.multiContainerMap = new Map<string, string>();
        this.multiContainerFilter.split('\n').forEach(data => {this.filter.multiContainerMap.set(data.toUpperCase(), data.toUpperCase()); } );
      } else {
        this.filter.multiContainerMap = new Map<string, string>();
      }
    }
    if (item === 'multiStowageFilter') {
      if (this.multiStowageFilter) {
        this.filter.multiStowageMap = new Map<string, string>();
        this.multiStowageFilter.split('\n').forEach(data => {this.filter.multiStowageMap.set(data.toUpperCase(), data.toUpperCase()); } );
      } else {
        this.filter.multiStowageMap = new Map<string, string>();
      }
    }

    if (this.carrierFilter) {
      this.filter.carrierMap = new Map<string, string>();
      this.carrierFilter.forEach(data => {this.filter.carrierMap.set(data, data); } );
    }
    if (this.porFilter) {
      this.filter.porMap = new Map<string, string>();
      this.porFilter.forEach(data => {this.filter.porMap.set(data, data); } );
    }
    if (this.polFilter) {
      this.filter.polMap = new Map<string, string>();
      this.polFilter.forEach(data => {this.filter.polMap.set(data, data); } );
    }
    if (this.podFilter) {
      this.filter.podMap = new Map<string, string>();
      this.podFilter.forEach(data => {this.filter.podMap.set(data, data); } );
    }
    if (this.optPodFilter) {
      this.filter.optPodMap = new Map<string, string>();
      this.optPodFilter.forEach(data => {this.filter.optPodMap.set(data, data); } );
    }
    if (this.tsPortFilter) {
      this.filter.tsPortMap = new Map<string, string>();
      this.tsPortFilter.forEach(data => {this.filter.tsPortMap.set(data, data); } );
    }
    if (this.nPodFilter) {
      this.filter.nPodMap = new Map<string, string>();
      this.nPodFilter.forEach(data => {this.filter.nPodMap.set(data, data); } );
    }
    if (this.fPodFilter) {
      this.filter.fPodMap = new Map<string, string>();
      this.fPodFilter.forEach(data => {this.filter.fPodMap.set(data, data); } );
    }
    if (this.placeOfDeliveryFilter) {
      this.filter.placeOfDeliveryMap = new Map<string, string>();
      this.placeOfDeliveryFilter.forEach(data => {this.filter.placeOfDeliveryMap.set(data, data); } );
    }

    if (this.isoSizeTypeFilter) {
      this.filter.isoSizeTypeMap = new Map<string, string>();
      this.isoSizeTypeFilter.forEach(data => {this.filter.isoSizeTypeMap.set(data, data); });
    }
    if (this.lengthFilter) {
      this.filter.lengthCodeMap = new Map<string, string>();
      this.lengthFilter.forEach(data => {this.filter.lengthCodeMap.set(data, data); } );
    }
    if (this.heightFilter) {
      this.filter.heightCodeMap = new Map<string, string>();
      this.heightFilter.forEach(data => {this.filter.heightCodeMap.set(data, data); } );
    }
    if (this.typeFilter) {
      this.filter.typeCodeMap = new Map<string, string>();
      this.typeFilter.forEach(data => {this.filter.typeCodeMap.set(data, data); } );
    }

    if (this.fullEmptyFilter) {
      this.filter.fullEmptyMap = new Map<string, string>();
      this.fullEmptyFilter.forEach(data => {
        let value = '';
        if (data === 'M') {
          value = 'Empty';
        } else if (data === 'F') {
          value = 'Full';
        }
        this.filter.fullEmptyMap.set(data, value);
      });
    }
    if (this.cargoTypeFilter) {

      this.filter.cargoTypeMap = new Map<string, string>();
      this.cargoTypeFilter.forEach(data => {this.filter.cargoTypeMap.set(data, data); });
    }
    if (this.imdgFilter) {
      this.filter.imdgMap = new Map<string, string>();
      this.imdgFilter.forEach(data => {this.filter.imdgMap.set(data, data); });
    }

    if (this.bundledYnFilter) {
      this.filter.bundledYnMap = new Map<string, string>();
      this.bundledYnFilter.forEach(data => {this.filter.bundledYnMap.set(data, data); });
    }
    if (this.breakBulkYnFilter) {
      this.filter.breakBulkYnMap = new Map<string, string>();
      this.breakBulkYnFilter.forEach(data => {this.filter.breakBulkYnMap.set(data, data); });
    }
    if (this.overDimensionYnFilter) {
      this.filter.overDimensionYnMap = new Map<string, string>();
      this.overDimensionYnFilter.forEach(data => {this.filter.overDimensionYnMap.set(data, data); });
    }
    if (this.overSlotYnFilter) {
      this.filter.overSlotYnMap = new Map<string, string>();
      this.overSlotYnFilter.forEach(data => {this.filter.overSlotYnMap.set(data, data); });
    }
    if (this.temperatureRangeYnFilter) {
      this.filter.temperatureRangeYnMap = new Map<string, string>();
      this.temperatureRangeYnFilter.forEach(data => {this.filter.temperatureRangeYnMap.set(data, data); });
    }
    if (this.vgmYnFilter) {
      this.filter.vgmYnMap = new Map<string, string>();
      this.vgmYnFilter.forEach(data => {this.filter.vgmYnMap.set(data, data); });
    }
    if (this.vgmCertificationCodeFilter) {
      this.filter.vgmCertificationCodeMap = new Map<string, string>();
      this.vgmCertificationCodeFilter.forEach(data => {this.filter.vgmCertificationCodeMap.set(data, data); });
    }
    if (this.vgmInfoYnFilter) {
      this.filter.vgmInfoYnMap = new Map<string, string>();
      this.vgmInfoYnFilter.forEach(data => {this.filter.vgmInfoYnMap.set(data, data); });
    }
    if (this.groupCodeYnFilter) {
      this.filter.groupCodeYnMap = new Map<string, string>();
      this.groupCodeYnFilter.forEach(data => {this.filter.groupCodeYnMap.set(data, data); });
    }
    if (this.groupCodeFilter) {
      this.filter.groupCodeMap = new Map<string, string>();
      this.groupCodeFilter.forEach(data => {this.filter.groupCodeMap.set(data, data); });
    }
    if (this.descriptionOfGoodsFilter) {
      this.filter.descriptionOfGoodsMap = new Map<string, string>();
      this.descriptionOfGoodsFilter.forEach(data => {this.filter.descriptionOfGoodsMap.set(data, data); });
    }
    if (this.handlingInstructionFilter) {
      this.filter.handlingInstructionMap = new Map<string, string>();
      this.handlingInstructionFilter.forEach(data => {this.filter.handlingInstructionMap.set(data, data); });
    }
    if (this.loadingRemarksFilter) {
      this.filter.loadingRemarksMap = new Map<string, string>();
      this.loadingRemarksFilter.forEach(data => {this.filter.loadingRemarksMap.set(data, data); });
    }
    if (this.specialCargoInstructionFilter) {
      this.filter.specialCargoInstructionMap = new Map<string, string>();
      this.specialCargoInstructionFilter.forEach(data => {this.filter.specialCargoInstructionMap.set(data, data); });
    }
    if (this.generalInformationFilter) {
      this.filter.generalInformationMap = new Map<string, string>();
      this.generalInformationFilter.forEach(data => {this.filter.generalInformationMap.set(data, data); });
    }
    if (this.mutuallyDefinedRemarksFilter) {
      this.filter.mutuallyDefinedRemarksMap = new Map<string, string>();
      this.mutuallyDefinedRemarksFilter.forEach(data => {this.filter.mutuallyDefinedRemarksMap.set(data, data); });
    }
    if (this.specialCargoInstructionYnFilter) {
      this.filter.specialCargoInstructionYnMap = new Map<string, string>();
      this.specialCargoInstructionYnFilter.forEach(data => {this.filter.specialCargoInstructionYnMap.set(data, data); });
    }
    if (this.loadingRemarkYnFilter) {
      this.filter.loadingRemarkYnMap = new Map<string, string>();
      this.loadingRemarkYnFilter.forEach(data => {this.filter.loadingRemarkYnMap.set(data, data); });
    }
    if (this.generalInformationYnFilter) {
      this.filter.generalInformationYnMap = new Map<string, string>();
      this.generalInformationYnFilter.forEach(data => {this.filter.generalInformationYnMap.set(data, data); });
    }
    if (this.mutuallyDefinedRemarkYnFilter) {
      this.filter.mutuallyDefinedRemarkYnMap = new Map<string, string>();
      this.mutuallyDefinedRemarkYnFilter.forEach(data => {this.filter.mutuallyDefinedRemarkYnMap.set(data, data); });
    }
    if (this.descriptionOfGoodsYnFilter) {
      this.filter.descriptionOfGoodsYnMap = new Map<string, string>();
      this.descriptionOfGoodsYnFilter.forEach(data => {this.filter.descriptionOfGoodsYnMap.set(data, data); });
    }
    if (this.cargoNatureYnFilter) {
      this.filter.cargoNatureYnMap = new Map<string, string>();
      this.cargoNatureYnFilter.forEach(data => {this.filter.cargoNatureYnMap.set(data, data); });
    }
    if (this.packageQuantityYnFilter) {
      this.filter.packageQuantityYnMap = new Map<string, string>();
      this.packageQuantityYnFilter.forEach(data => {this.filter.packageQuantityYnMap.set(data, data); });
    }
    if (this.packageTypeYnFilter) {
      this.filter.packageTypeYnMap = new Map<string, string>();
      this.packageTypeYnFilter.forEach(data => {this.filter.packageTypeYnMap.set(data, data); });
    }
    if (this.blYnFilter) {
      this.filter.blYnMap = new Map<string, string>();
      this.blYnFilter.forEach(data => {this.filter.blYnMap.set(data, data); });
    }
    if (this.bookingNoYnFilter) {
      this.filter.bookingNoYnMap = new Map<string, string>();
      this.bookingNoYnFilter.forEach(data => {this.filter.bookingNoYnMap.set(data, data); });
    }

    if (this.porTerminalFilter) {
      this.filter.porTerminalMap = new Map<string, string>();
      this.porTerminalFilter.forEach(data => this.filter.porTerminalMap.set(data, data));
    }
    if (this.polTerminalFilter) {
      this.filter.polTerminalMap = new Map<string, string>();
      this.polTerminalFilter.forEach(data => this.filter.polTerminalMap.set(data, data));
    }
    if (this.podTerminalFilter) {
      this.filter.podTerminalMap = new Map<string, string>();
      this.podTerminalFilter.forEach(data => this.filter.podTerminalMap.set(data, data));
    }
    if (this.tsTerminalFilter) {
      this.filter.tsTerminalMap = new Map<string, string>();
      this.tsTerminalFilter.forEach(data => this.filter.tsTerminalMap.set(data, data));
    }
    if (this.nPodTerminalFilter) {
      this.filter.nPodTerminalMap = new Map<string, string>();
      this.nPodTerminalFilter.forEach(data => this.filter.nPodTerminalMap.set(data, data));
    }
    if (this.fPodTerminalFilter) {
      this.filter.fPodTerminalMap = new Map<string, string>();
      this.fPodTerminalFilter.forEach(data => this.filter.fPodTerminalMap.set(data, data));
    }
    if (this.placeOfDeliveryTerminalFilter) {
      this.filter.placeOfDeliveryTerminalMap = new Map<string, string>();
      this.placeOfDeliveryTerminalFilter.forEach(data => this.filter.placeOfDeliveryTerminalMap.set(data, data));
    }

    this.numOfFilters = this.filter.numOfFilters();
    if (this.numOfFilters === 0) {
      this.alphaValue = 0;
      this.filter.alpha = 1;
    }
    this.eventService.emit(EventIds.APPLY_FILTER, this.filter);
  }
  clearFilters() {
    this.filter = new ContainerFilter();
    this.numOfFilters = this.filter.numOfFilters();
    if (this.numOfFilters === 0) {
      this.alphaValue = 0;
      this.filter.alpha = 1;
    }
    this.eventService.emit(EventIds.APPLY_FILTER, this.filter);
  }
}
