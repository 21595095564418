import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ContainerColumn} from '../service/model/container-column';
import {ColumnService} from '../service/column.service';
import {EventService} from '../../../core/event/event.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {EventIds} from '../../../core/event/event-ids';
import {ColumnActionIds} from '../columns.component';
import {ColumnUtils} from '../service/model/column-utils';

@Component({
  selector: 'app-column-info',
  templateUrl: './column-info.component.html',
  styleUrls: ['./column-info.component.css']
})
export class ColumnInfoComponent implements OnInit, OnDestroy {

  @Input()
  visible: boolean;

  @Input()
  actionEmitter: EventEmitter<string>;
  private actionSubscription;

  columns: string[] = ['seq', 'name', 'visible', 'colored'];
  dataSource = new MatTableDataSource<ContainerColumn>();
  containerColumns: ContainerColumn[];

  showProgress = false;

  constructor(private columnService: ColumnService, private eventService: EventService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.search();

    this.actionSubscription = this.actionEmitter.subscribe(event => {

      if (event === ColumnActionIds.SAVE && this.visible) {

        this.save();
      } else if (event === ColumnActionIds.EDIT_SELECTED) {
        this.visible = false;
      } else if (event === ColumnActionIds.INFO_SELECTED) {
        this.visible = true;
      }
    });
  }

  search() {
    this.showProgress = true;
    this.columnService.getColumns().then(data => {
      this.containerColumns = data;
      this.dataSource = new MatTableDataSource<ContainerColumn>(data);
      this.cdr.detectChanges();
      this.showProgress = false;
    });
  }

  drop(event: CdkDragDrop<MatTableDataSource<ContainerColumn>, any>) {
    moveItemInArray(this.containerColumns, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource<ContainerColumn>(this.containerColumns);
    this.actionEmitter.emit(ColumnActionIds.MODIFIED);
  }

  changeVisible(column: ContainerColumn, $event: MatCheckboxChange) {
    column.visible = $event.checked;
    this.actionEmitter.emit(ColumnActionIds.MODIFIED);
  }
  changeColored(column: ContainerColumn, $event: MatCheckboxChange) {
    column.colored = $event.checked;
    this.actionEmitter.emit(ColumnActionIds.MODIFIED);
  }

  save() {
    this.showProgress = true;
    this.columnService.updateColumns(this.containerColumns).subscribe(data => {
        this.search();
        this.actionEmitter.emit(ColumnActionIds.MODIFICATION_CLEANED);
        this.eventService.emit(EventIds.COLUMNS_CHANGED);
        this.showProgress = false;
      },
      error => {
        console.error(error);
        this.showProgress = false;
      });
  }
  ngOnDestroy(): void {
    this.actionSubscription.unsubscribe();
  }
  getColumnFullName(code: string) {
    return ColumnUtils.getDefaultFullName(code);
  }
}
