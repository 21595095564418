export class HatchCover {

  name: string;
  startRowNumber: number;
  endRowNumber: number;

  upperStartPoint: number;
  upperEndPoint: number;
  lowerStartPoint: number;
  lowerEndPoint: number;

}
