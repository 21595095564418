import {EventEmitter, Injectable} from '@angular/core';
import {VisualEvent} from './visual-event';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private eventEmitter = new EventEmitter<VisualEvent>();

  constructor() {
  }

  emit(id: string, newValue?: any, source?: any) {
    this.eventEmitter.emit(new VisualEvent(id, newValue, source));
  }

  getEmitter() {
    return this.eventEmitter;
  }

}
