import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SizeTypeCode} from '../../size-type/service/model/size-type-code';
import {properties} from '../../../../environments/environment';
import {ServiceLane} from './model/service-lane';
import {ServiceLanePort} from './model/service-lane-port';
import {PortCode} from '../../port/service/model/port.code';
import {ServiceLaneOperator} from './model/service-lane-operator';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceLaneService {

  constructor(private http: HttpClient) { }

  getServiceLanes(): Observable<ServiceLane[]> {
    return this.http.get<ServiceLane[]>(properties.serverUrl + '/lane');
  }
  updateServiceLanes(items: ServiceLane[]) {
    return this.http.post(properties.serverUrl + '/lane', items);
  }
  deleteServiceLane(items: ServiceLane[]) {
    let ids = '';

    items.forEach(item => {
      ids = ids + ',' + item.id;
    });
    return this.http.delete(properties.serverUrl + '/lane?id=' + ids);
  }
  getServiceLanePorts(laneCode: string) {
    return this.http.get<ServiceLanePort[]>(properties.serverUrl + '/lane/port?code=' + laneCode);
  }
  updateServiceLanePorts(items: ServiceLanePort[]) {
    return this.http.post(properties.serverUrl + '/lane/port', items);
  }
  deleteServiceLanePorts(items: ServiceLanePort[]) {
    let ids = '';

    items.forEach(item => {
      ids = ids + ',' + item.id;
    });
    return this.http.delete(properties.serverUrl + '/lane/port?id=' + ids);
  }
  searchPorts(code: string) {

    return this.http.get<PortCode[]>(properties.serverUrl + '/port/filtered?code=' + code);
  }
  getServiceLaneOperators(laneCode: string) {
    return this.http.get<ServiceLaneOperator[]>(properties.serverUrl + '/lane/operator?code=' + laneCode);
  }
  updateServiceLaneOperators(items: ServiceLaneOperator[]){
    return this.http.post(properties.serverUrl + '/lane/operator', items);
  }
  deleteServiceLaneOperators(items: ServiceLaneOperator[]){
    let ids = '';

    items.forEach(item => {
      ids = ids + ',' + item.id;
    });
    return this.http.delete(properties.serverUrl + '/lane/operator?id=' + ids);
  }
}
