import {Dangerous} from '../../my-plans/service/model/dangerous';

export class IMDGTableUtils {

  CLASS_11_TABLE = ['', '', '', '4', '2', '2', '4', '4', '4', '4', '4', '4', '2', '4', '2', '4', 'X'];
  CLASS_13_TABLE = ['', '', '', '4', '2', '2', '4', '3', '3', '4', '4', '4', '2', '4', '2', '2', 'X'];
  CLASS_14_TABLE = ['', '', '', '2', '1', '1', '2', '2', '2', '2', '2', '2', 'X', '4', '2', '2', 'X'];
  CLASS_21_TABLE = ['4', '4', '2', 'X', 'X', 'X', '2', '1', '2', '2', '2', '2', 'X', '4', '2', '1', 'X'];
  CLASS_22_TABLE = ['2', '2', '1', 'X', 'X', 'X', '1', 'X', '1', 'X', 'X', '1', 'X', '2', '1', 'X', 'X'];
  CLASS_23_TABLE = ['2', '2', '1', 'X', 'X', 'X', '2', 'X', '2', 'X', 'X', '2', 'X', '2', '1', 'X', 'X'];
  CLASS_3_TABLE = ['4', '4', '2', '2', '1', '2', 'X', 'X', '2', '2', '2', '2', 'X', '3', '2', 'X', 'X'];
  CLASS_41_TABLE = ['4', '3', '2', '1', 'X', 'X', 'X', 'X', '1', 'X', '1', '2', 'X', '3', '2', '1', 'X'];
  CLASS_42_TABLE = ['4', '3', '2', '2', '1', '2', '2', '1', 'X', '1', '2', '2', '1', '3', '2', '1', 'X'];
  CLASS_43_TABLE = ['4', '4', '2', '2', 'X', 'X', '2', 'X', '1', 'X', '2', '2', 'X', '2', '2', '1', 'X'];
  CLASS_51_TABLE = ['4', '4', '2', '2', 'X', 'X', '2', '1', '2', '2', 'X', '2', '1', '3', '1', '2', 'X'];
  CLASS_52_TABLE = ['4', '4', '2', '2', '1', '2', '2', '2', '2', '2', '2', 'X', '1', '3', '2', '2', 'X'];
  CLASS_61_TABLE = ['2', '2', 'X', 'X', 'X', 'X', 'X', 'X', '1', 'X', '1', '1', 'X', '1', 'X', 'X', 'X'];
  CLASS_62_TABLE = ['4', '4', '4', '4', '2', '2', '3', '3', '3', '2', '3', '3', '1', 'X', '3', '3', 'X'];
  CLASS_7_TABLE = ['2', '2', '2', '2', '1', '1', '2', '2', '2', '2', '1', '2', 'X', '3', 'X', '2', 'X'];
  CLASS_8_TABLE = ['4', '2', '2', '1', 'X', 'X', 'X', '1', '1', '1', '2', '2', 'X', '3', '2', 'X', 'X'];
  CLASS_9_TABLE = ['X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X', 'X'];

  constructor() {
  }

  segregation(dangerous1: Dangerous, dangerous2: Dangerous): string {
    return this.segregationTable(dangerous1)[this.segregationIndex(dangerous2.imdg)];
  }
  segregationGroup(dangerous1: Dangerous, dangerous2: Dangerous): string {
    let segregation = '0';
    if (dangerous1.segregationGroup && dangerous2.segregationCodes) {
      const groups = dangerous1.segregationGroup.split('+');
      for (const group of groups) {
        const codes = dangerous2.segregationCodes.split('+');
        for (const code of codes) {
          const segregation2 = this.groupTable(group, code);
          if (segregation < segregation2) {
            segregation = segregation2;
          }
        }
      }
    }
    if (dangerous2.segregationGroup && dangerous1.segregationCodes) {
      const groups = dangerous2.segregationGroup.split('+');
      for (const group of groups) {
        const codes = dangerous1.segregationCodes.split('+');
        for (const code of codes) {
          const segregation2 = this.groupTable(group, code);
          if (segregation < segregation2) {
            segregation = segregation2;
          }
        }
      }
    }
    if (segregation !== '0') {
      return segregation;
    }
    return undefined;
  }
  segregationCategory(dangerous: Dangerous) {
    const stowageCategory = dangerous.stowage;
    if (dangerous.segregationCodes) {
      const codes = dangerous.segregationCodes.split('+');
      for (const code of codes) {
        if (code === 'SW15' || code === 'SW16') {
          return 'B';
        } else if (code === 'SW18') {
          return 'A';
        } else if (code === 'SW19') {
          return 'C';
        } else if (code === 'SW20' || code === 'SW21') {
          return 'D';
        }
      }
    }
    return stowageCategory;
  }

  groupTable(group: string, code: string): string {
    if (code === 'SG20' && group === '1') {
      return '1';
    } else if (code === 'SG21' && group === '18') {
      return '1';
    } else if (code === 'SG22' && group === '2') {
      return '1';
    } else if (code === 'SG24' && group === '17') {
      return '1';
    } else if (code === 'SG24' && group === '17') {
      return '1';
    } else if (code === 'SG27' && (group === '5' || group === '13')) {
      return '1';
    } else if (code === 'SG28' && group === '2') {
      return '1';
    } else if (code === 'SG30' && group === '7') {
      return '1';
    } else if (code === 'SG31' && group === '9') {
      return '1';
    } else if (code === 'SG32' && group === '10') {
      return '1';
    } else if (code === 'SG33' && group === '15') {
      return '1';
    } else if (code === 'SG34' && (group === '4' || group === '13')) {
      return '1';
    } else if (code === 'SG35' && group === '1') {
      return '2';
    } else if (code === 'SG36' && group === '18') {
      return '2';
    } else if ((code === 'SG37' || code === 'SG38' || code === 'SG39' || code === 'SG40') && group === '2') {
      return '2';
    } else if (code === 'SG42' && group === '3') {
      return '2';
    } else if (code === 'SG45' && group === '4') {
      return '2';
    } else if (code === 'SG47' && group === '5') {
      return '2';
    } else if (code === 'SG49' && group === '6') {
      return '2';
    } else if (code === 'SG51' && group === '8') {
      return '2';
    } else if ((code === 'SG54' || code === 'SG55') && group === '11') {
      return '2';
    } else if (code === 'SG58' && group === '13') {
      return '2';
    } else if (code === 'SG59' && group === '14') {
      return '2';
    } else if (code === 'SG60' && group === '16') {
      return '2';
    } else if (code === 'SG61' && group === '15') {
      return '2';
    } else if (code === 'SG75' && group === '1') {
      return '2';
    }
    return '0';
  }

  segregationTable(dangerous: Dangerous) {
    let IMDGtable;
    if (dangerous.imdg === '1.1' || dangerous.imdg === '1.2' || dangerous.imdg === '1.5') {
      IMDGtable = this.CLASS_11_TABLE;
    } else if (dangerous.imdg === '1.3' || dangerous.imdg === '1.6') {
      IMDGtable = this.CLASS_13_TABLE;
    } else if (dangerous.imdg === '1.4') {
      IMDGtable = this.CLASS_14_TABLE;
    } else if (dangerous.imdg === '2.1' || dangerous.imdg === '2') {
      IMDGtable = this.CLASS_21_TABLE;
    } else if (dangerous.imdg === '2.2') {
      IMDGtable = this.CLASS_22_TABLE;
    } else if (dangerous.imdg === '2.3') {
      IMDGtable = this.CLASS_23_TABLE;
    } else if (dangerous.imdg === '3') {
      IMDGtable = this.CLASS_3_TABLE;
    } else if (dangerous.imdg === '4.1') {
      IMDGtable = this.CLASS_41_TABLE;
    } else if (dangerous.imdg === '4.2') {
      IMDGtable = this.CLASS_42_TABLE;
    } else if (dangerous.imdg === '4.3') {
      IMDGtable = this.CLASS_43_TABLE;
    } else if (dangerous.imdg === '5.1') {
      IMDGtable = this.CLASS_51_TABLE;
    } else if (dangerous.imdg === '5.2') {
      IMDGtable = this.CLASS_52_TABLE;
    } else if (dangerous.imdg === '6.1') {
      IMDGtable = this.CLASS_61_TABLE;
    } else if (dangerous.imdg === '6.2') {
      IMDGtable = this.CLASS_62_TABLE;
    } else if (dangerous.imdg === '7') {
      IMDGtable = this.CLASS_7_TABLE;
    } else if (dangerous.imdg === '8') {
      IMDGtable = this.CLASS_8_TABLE;
    } else {
      IMDGtable = this.CLASS_9_TABLE;
    }

    if (dangerous.segregationCodes) {
      const codes = dangerous.segregationCodes.split('+');
      for (const code of codes) {
        if (code === 'SG2' || code === 'SG3') {
          IMDGtable = this.CLASS_11_TABLE;
        } else if (code === 'SG4') {
          IMDGtable = this.CLASS_21_TABLE;
        } else if (code === 'SG5') {
          IMDGtable = this.CLASS_3_TABLE;
        } else if (code === 'SG6') {
          IMDGtable = this.CLASS_51_TABLE;
        } else if (code === 'SG68') {
          IMDGtable = this.CLASS_3_TABLE;
        } else if (code === 'SG69') {
          IMDGtable = this.CLASS_9_TABLE;
        } else if (code === 'SG74') {
          IMDGtable = this.CLASS_14_TABLE;
        }
      }
      for (const code of codes) {
        if (code === 'SG7') {
          IMDGtable[this.segregationIndex('3')] = '1';
        } else if (code === 'SG8') {
          IMDGtable[this.segregationIndex('4.1')] = '1';
        } else if (code === 'SG9') {
          IMDGtable[this.segregationIndex('4.3')] = '1';
        } else if (code === 'SG10') {
          IMDGtable[this.segregationIndex('5.1')] = '1';
        } else if (code === 'SG11') {
          IMDGtable[this.segregationIndex('6.2')] = '1';
        } else if (code === 'SG12') {
          IMDGtable[this.segregationIndex('7')] = '1';
        } else if (code === 'SG13') {
          IMDGtable[this.segregationIndex('8')] = '1';
        } else if (code === 'SG14') {
          IMDGtable[this.segregationIndex('1.1')] = '2';
          IMDGtable[this.segregationIndex('1.3')] = '2';
        } else if (code === 'SG15') {
          IMDGtable[this.segregationIndex('3')] = '2';
        } else if (code === 'SG16') {
          IMDGtable[this.segregationIndex('4.1')] = '2';
        } else if (code === 'SG17') {
          IMDGtable[this.segregationIndex('5.1')] = '2';
        } else if (code === 'SG18') {
          IMDGtable[this.segregationIndex('6.2')] = '2';
        } else if (code === 'SG19') {
          IMDGtable[this.segregationIndex('7')] = '2';
        } else if (code === 'SG25') {
          IMDGtable[this.segregationIndex('2.1')] = '2';
          IMDGtable[this.segregationIndex('3')] = '2';
        } else if (code === 'SG26') {
          if (IMDGtable[this.segregationIndex('2.1')] < '2') {
            IMDGtable[this.segregationIndex('2.1')] = '2';
          }
          if (IMDGtable[this.segregationIndex('3')] < '2') {
            IMDGtable[this.segregationIndex('3')] = '2';
          }
        } else if (code === 'SG67') {
          IMDGtable[this.segregationIndex('1.1')] = '4';
          IMDGtable[this.segregationIndex('1.3')] = '4';
        } else if (code === 'SG68') {
          IMDGtable[this.segregationIndex('4.1')] = '1';
        } else if (code === 'SG69') {
          IMDGtable[this.segregationIndex('1.1')] = '1';
          IMDGtable[this.segregationIndex('1.3')] = '1';
        }
      }
    }

    return IMDGtable;
  }
  segregationIndex(imdg: string): number {
    if (imdg === '1.1' || imdg === '1.2' || imdg === '1.5') {
      return 0;
    } else if (imdg === '1.3' || imdg === '1.6') {
      return 1;
    } else if (imdg === '1.4') {
      return 2;
    } else if (imdg === '2.1' || imdg === '2') {
      return 3;
    } else if (imdg === '2.2') {
      return 4;
    } else if (imdg === '2.3') {
      return 5;
    } else if (imdg === '3') {
      return 6;
    } else if (imdg === '4.1') {
      return 7;
    } else if (imdg === '4.2') {
      return 8;
    } else if (imdg === '4.3') {
      return 9;
    } else if (imdg === '5.1') {
      return 10;
    } else if (imdg === '5.2') {
      return 11;
    } else if (imdg === '6.1') {
      return 12;
    } else if (imdg === '6.2') {
      return 13;
    } else if (imdg === '7') {
      return 14;
    } else if (imdg === '8') {
      return 15;
    }
    return 16;
  }
}
