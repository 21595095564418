import { Injectable } from '@angular/core';
import {LocalDbService} from './local-db.service';
import {SessionIds} from '../core/service/session-ids';
import {observable, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsoleService {

  userId = sessionStorage.getItem(SessionIds.USER_ID);
  constructor(private localDbService: LocalDbService) {

  }

  add(log: string) {
    const maxEntries = 1000;
    this.localDbService.consoleTable.add({userId: this.userId, content: log});
    this.localDbService.consoleTable.orderBy('seq').last().then(max => {
      this.localDbService.consoleTable.where('seq').below(max.seq - maxEntries).delete();
    });

  }
  get(): Observable<string> {
    return new Observable<string>((observer) => {
      this.localDbService.consoleTable.where('userId').equals(this.userId).sortBy('seq').then(entries => {
        let log = '';

        entries.forEach(entry => {
          if (entry.userId === this.userId) {
            log = log + entry.content;
          }
        });
        observer.next(log);
        observer.complete();
      }, error => {
        observer.error('Console error');
        observer.complete();
      });
    });
  }
  delete() {
    this.localDbService.consoleTable.clear();
  }
}
