<mat-toolbar class="main-header" color="primary">
  <button (click)="toggleSidenav.emit()" mat-icon-button *ngIf="this.authService.isAuthenticated()">
    <mat-icon>menu</mat-icon>
  </button>
<!--  <div class="main-header-section">-->
<!--    <img src="assets/images/logo.png" class="logo-icon">-->
<!--    &lt;!&ndash;  <div  class="branding">&ndash;&gt;-->
<!--    &lt;!&ndash;    <div class="logo"></div>&ndash;&gt;-->

<!--  </div>-->
  <img src="assets/images/logo.png" class="logo-icon">
  <a href="{{goHome()}}" class="logo-title">{{BAPLIE_EDIT}}</a>


<!--  <div class="search-bar" fxFlex>-->
  <div style="width: 100%" fxFlex>
    <app-bay-toolbar *ngIf="showBayToolbar"></app-bay-toolbar>
<!--    <form class="search-form" [ngStyle.xs]="{display: 'none'}">-->
<!--      <mat-icon>search</mat-icon>-->
<!--      <input type="text" placeholder="Search" autofocus="true" />-->
<!--    </form>-->

  </div>
  <div style="min-width: 30px; font-size: small;">
    <span matTooltip="EDI type & version" matTooltipClass="tooltip"><b>{{ediType}}&nbsp;{{ediVersion}}</b></span>
  </div>
  <div style="margin-left: 5px; min-width: 300px;font-size: small;">
    <span class="edi-text" matTooltip="Service lane" matTooltipClass="tooltip">{{serviceLaneCode}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
    <span class="edi-text" matTooltip="Vessel name" matTooltipClass="tooltip">{{vessel}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
    <span class="edi-text" matTooltip="Voyage no" matTooltipClass="tooltip">{{voyage}}</span>
  </div>
<!--  <div>-->
<!--    <mat-icon>attach_file</mat-icon>-->
<!--    <input type="file" ng2FileSelect (change)="onOpenFile($event.target.files)"/>-->
<!--  </div>-->
  <div *ngIf="!authService.isMemberOrAdmin()">
    <a mat-button [routerLink]="'/user/signin'"><b>{{SIGN_IN}}</b></a>
    <a mat-button [routerLink]="'/user/signup'"><b>{{SIGN_UP}}</b></a>
  </div>
  <div *ngIf="isAuthenticated()">
    <mat-form-field appearance="legacy" *ngIf="authService.isMemberOrAdmin()">
      <mat-select [(ngModel)]="selectedValue" name="team" matTooltip="My teams" matTooltipClass="tooltip" (selectionChange)="switchTeam($event)">
        <mat-option *ngFor="let team of myTeams" [value]="team.id">
          {{team.name}} ({{team.roleName.substring(0, 1)}})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button (click)="fullScreenToggle()" mat-icon-button matTooltip="Full screen" matTooltipClass="tooltip" >
      <mat-icon>fullscreen</mat-icon>
    </button>
    <button *ngIf="authService.isMemberOrAdmin()" [matMenuTriggerFor]="user" mat-icon-button class="ml-xs">
      <mat-icon>person</mat-icon>
    </button>
    <button (click)="toggleNotificationSidenav.emit()" mat-icon-button class="ml-xs overflow-visible" *ngIf="rightPanelVisible">
      <mat-icon>view_sidebar</mat-icon>
      <!--      <span class="notification-label">5</span>-->
    </button>
    <mat-menu #user="matMenu" x-position="before">
      <button mat-menu-item (click)="openPreferences()">
        <mat-icon>settings</mat-icon>
        {{SETTINGS}}
      </button>
      <button mat-menu-item (click)="openProfile()">
        <mat-icon>account_box</mat-icon>
        {{ACCOUNT}}
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        {{SIGN_OUT}}
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
