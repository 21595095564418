import {Vessel} from '../model/vessel';
import {Injectable} from '@angular/core';
import {toNumbers} from '@angular/compiler-cli/src/diagnostics/typescript_version';
import {Hatch} from '../model/hatch';
import {Bay} from '../model/bay';
import {Section} from '../model/section';
import {Row} from '../model/row';
import {Tier} from '../model/tier';
import {Cell} from '../model/cell';
import {HatchCover} from '../model/hatch-cover';
import {BayPlanVesselParser} from './bay-plan-vessel-parser';

@Injectable({
  providedIn: 'root'
})
export class VesselTextParserService {

  constructor() {
  }

  parseVessel2Text(vessel: Vessel): string {
    let text = '';
    text += 'HEADER+';
    text += this.parseText(vessel.code) + '+';
    text += this.parseText(vessel.name) + '+';
    text += this.parseText(vessel.callSign) + '+';
    text += this.parseText(vessel.lloydNumber) + '+';
    text += this.parseText(vessel.lengthOfAll) + '+';
    text += this.parseText(vessel.deckHousePosition) + '+';
    text += this.parseText(vessel.funnelPosition) + '+';
    text += this.parseText(vessel.maxDeckRow) + '+';
    text += this.parseText(vessel.maxHoldRow) + '+';
    text += this.parseText(vessel.maxDeckTier) + '+';
    text += this.parseText(vessel.maxHoldTier) + '+';
    text += this.parseText(vessel.maxBay) + '+';
    text += this.parseText(vessel.remark) + '+';
    text += this.parseText(vessel.version) + '\'\r\n';

    if (vessel.hatchList) {
      for (const hatch of vessel.hatchList) {
        text += 'HATCH+';
        text += this.parseText(hatch.name) + '+';
        text += this.parseText(hatch.holdName) + '+';
        text += this.parseText(hatch.loadableMixedLength) + '+';
        text += this.parseText(hatch.reeferReceptacleDeckBow) + '+';
        text += this.parseText(hatch.reeferReceptacleDeckStern) + '+';
        text += this.parseText(hatch.reeferReceptacleHoldBow) + '+';
        text += this.parseText(hatch.reeferReceptacleHoldStern) + '+';
        text += this.parseText(hatch.shipCraneTypeStern) + '+';
        text += this.parseText(hatch.shipCraneTypeBow) + '\'\r\n';
        text += 'BAY_NO+';
        for (const bayNo of hatch.bayNumberList) {
          text += this.parseText(bayNo) + ':';
        }
        text += '\'\r\n';
      }
    }

    for (const bay of vessel.bayList) {
      text += 'BAY+';
      text += this.parseText(bay.name) + '+';
      text += this.parseText(bay.hatchName) + '+';
      text += this.parseText(bay.bayLength) + '+';
      text += this.parseText(bay.deckLcg) + '+';
      text += this.parseText(bay.holdLcg) + '+';
      text += this.parseText(bay.deckBaseLine) + '\'\r\n';

      text += 'ROW+';
      text += this.parseText(bay.deckSection.deck) + '+';
      for (const row of bay.deckSection.rowList) {
        text += this.parseText(row.name) + ':';
        text += this.parseText(row.gapPoint) + ':';
        text += this.parseText(row.stackingWeight) + ':';
        text += this.parseText(row.hatchCoverClearance) + ':';
        text += this.parseText(row.cellGuideClearancePort) + ':';
        text += this.parseText(row.cellGuideClearanceStarboard) + ':';
        text += this.parseText(row.tcg) + '+';
      }
      text += '\'\r\n';

      text += 'TIER+';
      text += this.parseText(bay.deckSection.deck) + '+';
      for (const tier of bay.deckSection.tierList) {
        text += this.parseText(tier.name) + ':';
        text += this.parseText(tier.vcg) + '+';
      }
      text += '\'\r\n';

      text += 'CELL+';
      text += this.parseText(bay.deckSection.deck) + '+';
      for (const tier of bay.deckSection.cellList) {
        text += this.parseText(tier.stowage) + ':';
        text += this.parseText(tier.coneType) + ':';
        text += this.parseText(tier.loadableSpace) + ':';
        text += this.parseText(tier.loadableReefer) + ':';
        text += this.parseText(tier.loadable45Ft) + ':';
        text += this.parseText(tier.loadable53Ft) + '+';
      }
      text += '\'\r\n';

      text += 'ROW+';
      text += this.parseText(bay.holdSection.deck) + '+';
      for (const row of bay.holdSection.rowList) {
        text += this.parseText(row.name) + ':';
        text += this.parseText(row.gapPoint) + ':';
        text += this.parseText(row.stackingWeight) + ':';
        text += this.parseText(row.hatchCoverClearance) + ':';
        text += this.parseText(row.cellGuideClearancePort) + ':';
        text += this.parseText(row.cellGuideClearanceStarboard) + ':';
        text += this.parseText(row.tcg) + '+';
      }
      text += '\'\r\n';

      text += 'TIER+';
      text += this.parseText(bay.holdSection.deck) + '+';
      for (const tier of bay.holdSection.tierList) {
        text += this.parseText(tier.name) + ':';
        text += this.parseText(tier.vcg) + '+';
      }
      text += '\'\r\n';

      text += 'CELL+';
      text += this.parseText(bay.holdSection.deck) + '+';
      for (const tier of bay.holdSection.cellList) {
        text += this.parseText(tier.stowage) + ':';
        text += this.parseText(tier.coneType) + ':';
        text += this.parseText(tier.loadableSpace) + ':';
        text += this.parseText(tier.loadableReefer) + ':';
        text += this.parseText(tier.loadable45Ft) + ':';
        text += this.parseText(tier.loadable53Ft) + '+';
      }
      text += '\'\r\n';

      text += 'HATCHCOVER+';
      for (const hatchCover of bay.hatchCoverList) {
        text += this.parseText(hatchCover.name) + ':';
        text += this.parseText(hatchCover.startRowNumber) + ':';
        text += this.parseText(hatchCover.endRowNumber) + ':';
        text += this.parseText(hatchCover.upperStartPoint) + ':';
        text += this.parseText(hatchCover.upperEndPoint) + ':';
        text += this.parseText(hatchCover.lowerStartPoint) + ':';
        text += this.parseText(hatchCover.lowerEndPoint) + '+';
      }
      text += '\'\r\n';
    }
    return text;
  }

  parseText2Vessel(data: string, confirmed?: boolean) {
    const vessel = new Vessel();
    vessel.hatchList = [];
    vessel.bayList = [];
    const lines: string[] = data.split('\'');
    for (const line of lines) {
      const texts = line.replace('\r\n', '').split('+');
      if (texts[0] === 'HEADER') {
        this.putHeader(texts, vessel);
      } else if (texts[0] === 'HATCH') {
        this.putHatchList(texts, vessel);
      } else if (texts[0] === 'BAY_NO') {
        this.putHatchBayNo(texts, vessel);
      } else if (texts[0] === 'BAY') {
        this.putBayList(texts, vessel);
      } else if (texts[0] === 'ROW') {
        this.putRowList(texts, vessel);
      } else if (texts[0] === 'TIER') {
        this.putTierList(texts, vessel);
      } else if (texts[0] === 'CELL') {
        this.putCellList(texts, vessel);
      } else if (texts[0] === 'HATCHCOVER') {
        this.putHatchCoverList(texts, vessel);
      }
    }
    if (confirmed) {
      return vessel;
    }
    return BayPlanVesselParser.makeBayData(vessel.bayList);
  }

  private putHeader(texts: string[], vessel: Vessel) {
    let index = 1;
    vessel.code = texts[index++];
    vessel.name = texts[index++];
    vessel.callSign = texts[index++];
    vessel.lloydNumber = texts[index++];
    vessel.lengthOfAll = this.parseNumber(texts[index++]);
    vessel.deckHousePosition = this.parseNumber(texts[index++]);
    vessel.funnelPosition = this.parseNumber(texts[index++]);
    vessel.maxDeckRow = this.parseNumber(texts[index++]);
    vessel.maxHoldRow = this.parseNumber(texts[index++]);
    vessel.maxDeckTier = this.parseNumber(texts[index++]);
    vessel.maxHoldTier = this.parseNumber(texts[index++]);
    vessel.maxBay = this.parseNumber(texts[index++]);
    vessel.remark = texts[index++];
  }

  private putHatchList(texts: string[], vessel: Vessel) {
    let index = 1;
    const hatch = new Hatch();
    hatch.name = texts[index++];
    hatch.holdName = texts[index++];
    hatch.loadableMixedLength = this.parseBoolean(texts[index++]);
    hatch.reeferReceptacleDeckBow = this.parseBoolean(texts[index++]);
    hatch.reeferReceptacleDeckStern = this.parseBoolean(texts[index++]);
    hatch.reeferReceptacleDeckBow = this.parseBoolean(texts[index++]);
    hatch.reeferReceptacleHoldBow = this.parseBoolean(texts[index++]);
    hatch.reeferReceptacleHoldStern = this.parseBoolean(texts[index++]);
    hatch.shipCraneTypeStern = texts[index++];
    hatch.shipCraneTypeBow = texts[index++];
    hatch.bayNumberList = [];
    vessel.hatchList.push(hatch);
  }

  private putHatchBayNo(texts: string[], vessel: Vessel) {
    const bayNos = texts[1].split(':');
    for (const bayNo of bayNos) {
      if (bayNo !== '') {
        vessel.hatchList[vessel.hatchList.length - 1].bayNumberList.push(bayNo);
      }
    }
  }

  private putBayList(texts: string[], vessel: Vessel) {
    let index = 1;
    const bay = new Bay();
    bay.name = texts[index++];
    bay.hatchName = texts[index++];
    bay.bayLength = this.parseNumber(texts[index++]);
    bay.deckLcg = this.parseNumber(texts[index++]);
    bay.holdLcg = this.parseNumber(texts[index++]);
    bay.holdLcg = this.parseNumber(texts[index++]);
    bay.deckBaseLine = this.parseNumber(texts[index++]);
    bay.deckSection = new Section();
    bay.deckSection.deck = true;
    bay.deckSection.rowList = [];
    bay.deckSection.tierList = [];
    bay.deckSection.cellList = [];
    bay.holdSection = new Section();
    bay.holdSection.deck = false;
    bay.holdSection.rowList = [];
    bay.holdSection.tierList = [];
    bay.holdSection.cellList = [];
    bay.hatchCoverList = [];
    vessel.bayList.push(bay);
  }

  private putRowList(texts: string[], vessel: Vessel) {
    let list;
    if (this.parseBoolean(texts[1])) {
      list = vessel.bayList[vessel.bayList.length - 1].deckSection.rowList;
    } else {
      list = vessel.bayList[vessel.bayList.length - 1].holdSection.rowList;
    }
    for (let i = 2; i < texts.length; i++) {
      const text = texts[i].split(':');
      let index = 0;
      const row = new Row();
      row.name = text[index++];
      row.gapPoint = this.parseNumber(text[index++]);
      row.stackingWeight = this.parseNumber(text[index++]);
      row.hatchCoverClearance = this.parseNumber(text[index++]);
      row.cellGuideClearancePort = this.parseNumber(text[index++]);
      row.cellGuideClearanceStarboard = this.parseNumber(text[index++]);
      row.tcg = this.parseNumber(text[index++]);
      // if (row.name !== '') {
      list.push(row);
      // }
    }
  }

  private putTierList(texts: string[], vessel: Vessel) {
    let list;
    if (this.parseBoolean(texts[1])) {
      list = vessel.bayList[vessel.bayList.length - 1].deckSection.tierList;
    } else {
      list = vessel.bayList[vessel.bayList.length - 1].holdSection.tierList;
    }
    for (let i = 2; i < texts.length; i++) {
      const text = texts[i].split(':');
      let index = 0;
      const tier = new Tier();
      tier.name = text[index++];
      tier.vcg = this.parseNumber(text[index++]);
      // if (tier.name !== '') {
      list.push(tier);
      // }
      if (this.parseBoolean(texts[1])) {
        vessel.bayList[vessel.bayList.length - 1].maxDeckTierNo = Math.max(vessel.bayList[vessel.bayList.length - 1].maxDeckTierNo, Number(tier.name));
        if (Number(tier.name) > 0 && vessel.bayList[vessel.bayList.length - 1].minDeckTierNo === 0) {
          vessel.bayList[vessel.bayList.length - 1].minDeckTierNo = Number(tier.name);
        } else if (Number(tier.name) > 0) {
          vessel.bayList[vessel.bayList.length - 1].minDeckTierNo = Math.min(vessel.bayList[vessel.bayList.length - 1].minDeckTierNo, Number(tier.name));
        }
      } else {
        vessel.bayList[vessel.bayList.length - 1].maxHoldTierNo = Math.max(vessel.bayList[vessel.bayList.length - 1].maxHoldTierNo, Number(tier.name));
        if (Number(tier.name) > 0 && vessel.bayList[vessel.bayList.length - 1].minHoldTierNo === 0) {
          vessel.bayList[vessel.bayList.length - 1].minHoldTierNo = Number(tier.name);
        } else if (Number(tier.name) > 0) {
          vessel.bayList[vessel.bayList.length - 1].minHoldTierNo = Math.min(vessel.bayList[vessel.bayList.length - 1].minHoldTierNo, Number(tier.name));
        }
      }
    }
  }

  private putCellList(texts: string[], vessel: Vessel) {
    let list;
    let rowList;
    if (this.parseBoolean(texts[1])) {
      list = vessel.bayList[vessel.bayList.length - 1].deckSection.cellList;
      rowList = vessel.bayList[vessel.bayList.length - 1].deckSection.rowList;
    } else {
      list = vessel.bayList[vessel.bayList.length - 1].holdSection.cellList;
      rowList = vessel.bayList[vessel.bayList.length - 1].deckSection.rowList;
    }
    for (let i = 2; i < texts.length; i++) {
      const text = texts[i].split(':');
      let index = 0;
      const cell = new Cell();
      cell.stowage = text[index++];
      cell.coneType = text[index++];
      cell.loadableSpace = this.parseBoolean(text[index++]);
      cell.loadableReefer = this.parseBoolean(text[index++]);
      cell.loadable45Ft = this.parseBoolean(text[index++]);
      cell.loadable53Ft = this.parseBoolean(text[index++]);
      if (cell.stowage.length === 6) {
        cell.stowage = '0' + cell.stowage;
      }
      if (cell.stowage.length < 6) {
        cell.loadableSpace = false;
      }
      list.push(cell);
    }
  }

  private putHatchCoverList(texts: string[], vessel: Vessel) {
    for (let i = 1; i < texts.length; i++) {
      const text = texts[i].split(':');
      let index = 0;
      const hatchCover = new HatchCover();
      hatchCover.name = text[index++];
      hatchCover.startRowNumber = this.parseNumber(text[index++]);
      hatchCover.endRowNumber = this.parseNumber(text[index++]);
      hatchCover.upperStartPoint = this.parseNumber(text[index++]);
      hatchCover.upperEndPoint = this.parseNumber(text[index++]);
      hatchCover.lowerStartPoint = this.parseNumber(text[index++]);
      hatchCover.lowerEndPoint = this.parseNumber(text[index++]);
      vessel.bayList[vessel.bayList.length - 1].hatchCoverList.push(hatchCover);
    }
  }

  private parseText(text): string {
    if (text === undefined) {
      text = '';
    } else if (text === true) {
      text = '1';
    } else if (text === false) {
      text = '0';
    }
    return text;
  }
  private parseNumber(text: string): number {
    if (!text) {
      return 0;
    }
    return Number.parseFloat(text);
  }
  private parseBoolean(text: string): boolean {
    return text === '1';
  }
  mergeVessel2(newVessel: Vessel, baseVessel: Vessel): Vessel {

    return null;
  }
  mergeVessel(source: Vessel, target: Vessel): Vessel {
    if (!source || source.bayList.length === 0) {
      return target;
    }
    if (!target || target.bayList.length === 0) {
      return source;
    }

    const vessel = new Vessel();
    vessel.code = this.fixValue(source.code, target.code);
    vessel.name = this.fixValue(source.name, target.name);
    vessel.callSign = this.fixValue(source.callSign, target.callSign);
    vessel.lloydNumber = this.fixValue(source.lloydNumber, target.lloydNumber);
    vessel.lengthOfAll = Math.max(source.lengthOfAll, target.lengthOfAll);
    vessel.deckHousePosition = Math.max(source.deckHousePosition, target.deckHousePosition);
    vessel.funnelPosition = Math.max(source.funnelPosition, target.funnelPosition);
    vessel.maxDeckRow = Math.max(source.maxDeckRow, target.maxDeckRow);
    vessel.maxHoldRow = Math.max(source.maxHoldRow, target.maxHoldRow);
    vessel.maxDeckTier = Math.max(source.maxDeckTier, target.maxDeckTier);
    vessel.maxHoldTier = Math.max(source.maxHoldTier, target.maxHoldTier);
    vessel.maxBay = Math.max(source.maxBay, target.maxBay);
    vessel.remark = this.fixValue(source.remark, target.remark);

    const hatchMap: Map<string, Hatch> = new Map<string, Hatch>();
    const hatchList: Array<Hatch> = new Array<Hatch>();
    for (const sourceHatch of source.hatchList) {
      let targetHatch;
      for (const tmpTargetHatch of target.hatchList) {
        if (sourceHatch.name === tmpTargetHatch.name) {
          targetHatch = tmpTargetHatch;
          hatchMap.set(tmpTargetHatch.name, tmpTargetHatch);
          break;
        } else if (sourceHatch.name < tmpTargetHatch.name) {
          break;
        } else if (!hatchMap.get(tmpTargetHatch.name) && sourceHatch.name > tmpTargetHatch.name) {
          hatchList.push(tmpTargetHatch);
          vessel.merged = true;
        }
      }

      if (targetHatch) {
        if (sourceHatch.bayNumberList.length < targetHatch.bayNumberList.length) {
          hatchList.push(targetHatch);
          vessel.merged = true;
        } else {
          hatchList.push(sourceHatch);
        }
      } else {
        hatchList.push(sourceHatch);
      }
    }
    vessel.hatchList = hatchList;

    const bayMap: Map<string, Bay> = new Map<string, Bay>();
    for (const sourceBay of source.bayList) {
      bayMap.set(sourceBay.name, sourceBay);
    }
    const bayList: Array<Bay> = new Array<Bay>();
    for (const sourceBay of source.bayList) {
      let targetBay;
      for (const tmpTargetBay of target.bayList) {
        if (sourceBay.name === tmpTargetBay.name) {
          targetBay = tmpTargetBay;
          break;
        } else if (sourceBay.name < tmpTargetBay.name) {
          break;
        } else if (!bayMap.get(tmpTargetBay.name) && (sourceBay.name > tmpTargetBay.name || sourceBay.name === source.bayList[source.bayList.length - 1].name)) {
          bayMap.set(tmpTargetBay.name, tmpTargetBay);
          bayList.push(tmpTargetBay);
          vessel.merged = true;
        }
      }

      if (targetBay) {
        if (targetBay.deckSection && sourceBay.deckSection.tierList.length < targetBay.deckSection.tierList.length) {
          sourceBay.deckSection = targetBay.deckSection;
          vessel.merged = true;
        }
        if (targetBay.holdSection && sourceBay.holdSection.tierList.length < targetBay.holdSection.tierList.length) {
          sourceBay.holdSection = targetBay.holdSection;
          vessel.merged = true;
        }
      }
      bayList.push(sourceBay);
    }
    vessel.bayList = bayList;

    return vessel;
  }

  private fixValue(source: string, target: string): string {
    if (target && target !== '') {
      return target;
    }
    return source;
  }
  private fixNumValue(baseValue: number, newValue: number): number {
    if (newValue > 0) {
      return newValue;
    }
    return baseValue;
  }

}
