import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CommonCode} from './model/common-code';
import {Observable} from 'rxjs';
import {Country} from './model/country';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }

  private getCommonCodes(groupCode: string): Observable<CommonCode[]> {
    return this.http.get<CommonCode[]>(environment.serverUrl + '/common/codes?group_code=' + groupCode);
  }
  getRoleCodes(): Observable<CommonCode[]> {
    return this.getCommonCodes('002');
  }
  getCountries() {
    return this.http.get<Country[]>(environment.serverUrl + '/countries');
  }
}
