import {Place} from './place';
import {Datetime} from './datetime';
import {Stowage} from './stowage';
import {Transport} from './transport';
import {Reference} from './reference';
import {SyntaxMessage} from './syntax-message';
import {Freetext} from './freetext';

export class EdiDocument {

  senderId: string;
  recipientId: string;
  preparationDate: string;
  preparationTime: string;
  interchangeControlRefStart: string;
  communicationsAgreementId: string;

  messageNo: string;
  messageType: string;
  versionNo: string;
  releaseNo: string;
  associationCode: string;

  documentTypeCode: string;
  documentName: string;
  documentNo: string;
  documentVersion: string;
  documentRevision: string;
  messageFunction: string;
  documentResponseType: string;
  documentStatus: string;

  // BAPLIE 3.0
  referenceType: string;
  referenceId: string;
  referenceDocument: string;
  referenceVersion: string;
  referenceRevision: string;

  compilingDateTime: string;
  compilingDateTimeFormat: string;

  transport = new Transport();

  placeLocations = new Array<Place>();

  placeDates = new Array<Datetime>();

  loadingVoyageNo: string;

  remarks = new Array<Freetext>();

  stowages = new Array<Stowage>();

  dischargeStowages = new Array<Stowage>();
  shiftStowages = new Array<Stowage>();
  restowStowages = new Array<Stowage>();
  codStowages = new Array<Stowage>();
  excessStowages = new Array<Stowage>();
  balanceStowages = new Array<Stowage>();
  avoidStowages = new Array<Stowage>();

  messageTrailer = new Reference();

  interchangeControlCount: string;
  interchangeControlRefEnd: string;

  // stowageMap = new Map<string, Stowage>();
  id: string;
  updateDatetime: string;
  serviceLaneCode: string;
  syntaxMessages = new Array<SyntaxMessage>();
  handle: string;
}
