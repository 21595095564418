import {EditableColumn} from '../../../../be-com/etable/editable-column';

export class ContainerColumn extends EditableColumn {

  constructor(public code: string,
              public name: string,
              public fullName: string,
              public visible: boolean,
              public seq: number,
              public colorable = false,
              public colored?: boolean) {
    super(code, name, EditableColumn.CELL_SELECTION | EditableColumn.EDITABLE);
  }
}
