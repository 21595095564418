import {Component, ElementRef, HostListener, Input, OnInit, EventEmitter, OnDestroy, Output} from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {BottomPanelVisible} from '../../core';
import {LocalPreferenceIds} from '../../service/local-preference-ids';

@Component({
  selector: 'app-bottom-panel',
  templateUrl: './bottom-panel.component.html',
  styleUrls: ['./bottom-panel.component.css']
})
export class BottomPanelComponent implements OnInit, OnDestroy {

  tabEmitter = new EventEmitter();

  BottomTabText = BottomTabText;

  @Input()
  bottomHeight;


  @Input()
  bottomPanelVisible: BottomPanelVisible;

  toolsClass = 'toolsClass';
  selectedTabIndex = 0;

  constructor() {
  }

  ngOnInit(): void {
    this.selectedTabIndex = Number(localStorage.getItem(LocalPreferenceIds.SELECTED_BOTTOM_TAB_INDEX_FOR_PLAN));
  }

  selectTab($event: MatTabChangeEvent) {
    this.tabEmitter.emit($event);

    localStorage.setItem(LocalPreferenceIds.SELECTED_BOTTOM_TAB_INDEX_FOR_PLAN, String($event.index));
  }

  ngOnDestroy(): void {
  }
}

export const BottomTabText = {
  MARKERS: 'Markers',
  HEADER: 'Header',
  CONTAINER_SUMMARY: 'Summary',
  CONSOLE: 'Console',
  EDI_TEXT: 'EDI text',
  VESSEL_EDIT: 'Vessel Editor',
  SHARED_EDI: 'Shared'
};
