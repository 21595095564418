export class Transport {
  conveyanceRefNo: string;
  carrier: string;
  carrierResponsibleAgency: string;
  id: string;
  type: string;
  responsibleAgency: string;
  vesselName: string;
  countryCode: string;
  constructor() {
  }
}
