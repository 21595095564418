import {Injectable} from '@angular/core';
import {VesselUtils} from '../../hull/service/vessel-utils';
import {BayPlanService} from '../../service/bay-plan.service';
import {SyntaxMessageError} from './model/syntax-message-error';
import {Container} from '../../service/model/container';
import {SessionIds} from '../../core/service/session-ids';
import {MarkersProperty} from '../../panels/markers/model/markers.property';
import {Vessel} from '../../hull/model/vessel';
import {BayPlan} from '../../service/model/bay-plan';

@Injectable({
  providedIn: 'root'
})
export class StowageValidationService {

  constructor() {
  }
  private errorMessages = new  Array<SyntaxMessageError>();

  run(vessel: Vessel, bayPlan: BayPlan): Array<SyntaxMessageError> {

    const errorArray = new Array<SyntaxMessageError>();
    const vesselUtil = new VesselUtils(vessel);
    const containerNos = new Map<string, Container>();
    const stowages = new Map<string, Container>();

    for (const container of bayPlan.containers) {
      if (!container.stowage || !vesselUtil.getCell(container.stowage)) {
        const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_STOWAGE);
        error.syntaxName = MarkersProperty.ITEM_STOWAGE_INVALID;
        error.description = container.containerNo;
        error.stowage = container.stowage;
        errorArray.push(error);
      }
      if (!container.isoSizeType || container.isoSizeType.length !== 4) {
        const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_STOWAGE);
        error.syntaxName = MarkersProperty.ITEM_STOWAGE_SIZE_TYPE;
        error.description = 'ISO : ' + container.isoSizeType;
        error.stowage = container.stowage;
        errorArray.push(error);
      }
      if (!container.containerNo && bayPlan.header.messageType === 'BAPLIE') {
        const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_STOWAGE);
        error.syntaxName = MarkersProperty.ITEM_STOWAGE_NULL_CONTAINER_NO;
        error.description = 'Null container no.';
        error.stowage = container.stowage;
        errorArray.push(error);
      }
      if (container.containerNo && containerNos.get(container.containerNo)) {
        const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_STOWAGE);
        error.syntaxName = MarkersProperty.ITEM_STOWAGE_CONTAINER_NO;
        error.description = container.containerNo + ' (' + containerNos.get(container.containerNo).stowage + ' , ' + container.stowage + ')';
        error.stowage = container.stowage;
        errorArray.push(error);
      }
      if (container.stowage && stowages.get(container.stowage)) {
        const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_STOWAGE);
        error.syntaxName = MarkersProperty.ITEM_STOWAGE_DUPLICATE;
        error.description = stowages.get(container.stowage).containerNo + ' , ' + container.containerNo;
        error.stowage = container.stowage;
        errorArray.push(error);
      }
      const occupiedStowages = vesselUtil.findOccupiedCellKeys(container.stowage);
      if (occupiedStowages) {
        for (const stowage of occupiedStowages) {
          const occupy = stowages.get(stowage);
          if (occupy) {
            const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_STOWAGE);
            error.syntaxName = MarkersProperty.ITEM_STOWAGE_OVERLAP;
            error.description =  container.containerNo + ' occupied ' + occupy.stowage + ' overlapping with ' + occupy.containerNo + '';
            error.stowage = container.stowage;
            errorArray.push(error);
            break;
          }
        }
      }

      if (container.containerNo && !containerNos.get(container.containerNo)) {
        containerNos.set(container.containerNo, container);
      }
      if (container.stowage && !stowages.get(container.stowage)) {
        stowages.set(container.stowage, container);
      }
    }
    this.errorMessages = errorArray;
    return errorArray;
  }
  getErrorMessages(): Array<SyntaxMessageError> {
    return this.errorMessages;
  }
}
