import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {EventService} from '../../core/event/event.service';
import {EventIds} from '../../core/event/event-ids';
import {BayPlanService} from '../../service/bay-plan.service';
import {BayPlan} from '../../service/model/bay-plan';
import {Port} from '../../code/port/service/model/port';
import {CodeService} from '../../code/service/code.service';
import {ContainerFilter} from '../../service/model/container-filter';
import {ContainerFilterUtil} from '../../service/model/container-filter-util';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {StringUtils} from '../../utils/string-utils';
import {SizeTypeCode} from '../../code/size-type/service/model/size-type-code';
import {ColumnIds} from '../columns/service/model/column-ids';
import {ContainerUtils} from '../../service/model/container-utils';
import {ContainerFilterService} from '../../service/container-filter.service';

@Component({
  selector: 'app-container-summary',
  templateUrl: './container-summary.component.html',
  styleUrls: ['./container-summary.component.css']
})
export class ContainerSummaryComponent implements OnInit, OnDestroy {

  bayPlan: BayPlan;
  total: ContainerSummary;
  items: Array<ContainerSummary>;

  hatches: string[];
  nameList: any;
  rowType: string;

  ports = [];
  carriers = [];

  portMap: Map<string, Port>;

  filter = new ContainerFilter();
  properties = PROPERTIES;

  @Input()
  tabEmitter: EventEmitter<MatTabChangeEvent>;
  eventSubscription;

  lengthCodes: SizeTypeCode[];

  columnIds = ColumnIds;

  constructor(private eventService: EventService,
              private bayPlanService: BayPlanService,
              public filterService: ContainerFilterService,
              private codeService: CodeService) {
  }

  ngOnInit(): void {
    this.filter = this.filterService.filter;

    this.lengthCodes = this.codeService.getContainerLengths();
    if (this.lengthCodes) {
      this.lengthCodes.sort((a, b) => a.code > b.code ? 1 : -1);
    }

    this.total = new ContainerSummary('');
    this.items = new Array<ContainerSummary>();
    this.eventSubscription = this.eventService.getEmitter().subscribe(event => {
      if (event.id ===  EventIds.LOAD_BAY_PLAN) {
        this.bayPlan = this.bayPlanService.getBayPlan();
        if (this.bayPlan) {
          this.hatches = this.bayPlanService.getHatches();
          this.initCode();
          this.displayContainerQuantity();
        }


      } else if (event.id === EventIds.APPLY_FILTER) {
        this.filter = event.newValue;
        this.displayContainerQuantity();
        // console.info(event);
      }
    });
    this.nameList = [{Id: ColumnIds.POD, Name: ColumnIds.POD},
      {Id: ColumnIds.POL, Name: ColumnIds.POL},
      {Id: ColumnIds.POR, Name: ColumnIds.POR},
      {Id: ColumnIds.CARRIER, Name: 'Carrier'},
      {Id: ColumnIds.HATCH, Name: 'Bay'}];

    this.rowType = ColumnIds.POD;
    this.portMap = this.codeService.getOwnPortMap();

  }
  initCode() {
    const portMap: Map<string, string> = new Map<string, string>();
    const carrierMap: Map<string, string> = new Map<string, string>();
    for (const container of this.bayPlan.containers) {
      portMap.set(container.pol, container.pol);
      portMap.set(container.pod, container.pod);
      portMap.set(container.tsPort, container.tsPort);
      portMap.set(container.optPod1, container.optPod1);
      portMap.set(container.nPod, container.nPod);
      carrierMap.set(container.carrier, container.carrier);
    }
    this.ports = [''];
    const portSortMap = new Map([...portMap.entries()].sort());
    for (const port of portSortMap.keys()) {
      if (port !== undefined && port !== '') {
        this.ports.push(port);
      }
    }
    this.carriers = [''];
    const carrierSortMap = new Map([...carrierMap.entries()].sort());
    for (const carrier of carrierSortMap.keys()) {
      if (carrier !== undefined && carrier !== '') {
        this.carriers.push(carrier);
      }
    }
  }

  private toHex(r) {
    return r.replace('#', '0x');
  }
  displayContainerQuantity() {
    const itemMap: Map<string, ContainerSummary> = new Map<string, ContainerSummary>();

    const sizeTypeMap = this.codeService.getSizeTypeMap();

    this.total = new ContainerSummary('Total');
    for (const container of this.bayPlan.containers) {

      if (ContainerFilterUtil.filterOut(this.filter, container, this.codeService.getSizeTypeMap())) {
        continue;
      }
      let key = '';
      if (this.rowType === ColumnIds.POD) {
        key = container.pod;
      } else if (this.rowType === ColumnIds.POL) {
        key = container.pol;
      } else if (this.rowType === ColumnIds.POR) {
        key = container.por;
      } else if (this.rowType === ColumnIds.CARRIER) {
        key = container.carrier;
      } else if (this.rowType === ColumnIds.HATCH) {
        // key = container.stowage.substring(0, container.stowage.length - 4);

        if (container.stowage && container.stowage.length >= 3) {
          for (let m = 0; m < this.hatches.length; m ++) {
            const hatch = this.hatches[m];
            if (hatch.includes(container.stowage.substring(0, 3))) {
              key = hatch;
              break;
            }
          }
        }
        // if (key === '') {
        //   key = 'Unknown';
        // }
      }
      let item: ContainerSummary = itemMap.get(key);
      if (!item) {
        item = new ContainerSummary(key);
      }
      if (this.portMap) {
        const code = this.portMap[key];
        item.color = code && code.foreColor ? this.toHex(code.foreColor) : '#d7d9f2';
      }

      const sizeType = sizeTypeMap.get(container.isoSizeType);

      if (sizeType) {
        if (sizeType.lengthCode === '0112') {
          this.total.length20++;
          item.length20++;
        } else if (sizeType.lengthCode === '0114') {
          this.total.length40++;
          item.length40++;
        } else if (sizeType.lengthCode === '011L') {
          this.total.lengthL++;
          item.lengthL++;
        } else if (sizeType.lengthCode === '011M') {
          this.total.lengthM++;
          item.lengthM++;
        } else {
          this.total.lengthUnknown++;
          item.lengthUnknown++;
        }
      } else {
        // console.info(container.isoSizeType);
        this.total.lengthUnknown++;
        item.lengthUnknown++;
      }


      const cargoCode = container.getCargoTypeCode();
      if (cargoCode === '016RD') {
        this.total.reeferDangerous++;
        item.reeferDangerous++;
      } else if (cargoCode === '016RF') {
        this.total.reefer++;
        item.reefer++;
      } else if (cargoCode === '016DM') {
        this.total.emptyDangerous++;
        item.emptyDangerous++;
      } else if (cargoCode === '016DG') {
        this.total.dangerous++;
        item.dangerous++;
      } else if (cargoCode === '016MT') {
        this.total.empty++;
        item.empty++;
      } else {
        this.total.otherFull++;
        item.otherFull++;
      }

      if (container.hasOverSlot()) {
        this.total.overSlot++;
        item.overSlot++;
      } else if (container.hasOverDimension()) {
        this.total.overDimension++;
        item.overDimension++;
      } else if (container.hasBreakBulk()) {
        this.total.breakBulk++;
        item.breakBulk++;
      }
      this.total.total++;
      item.total++;
      itemMap.set(item.rowValue, item);
    }

    this.items = new Array<ContainerSummary>();
    const itemSortMap = new Map([...itemMap.entries()].sort());
    for (const item of itemSortMap.values()) {
      this.items.push(item);
    }
  }

  numberFormat(getNum: number): string {
    const rNum = (getNum / 1).toFixed(0).replace('.', ',');
    return StringUtils.formatNumber(rNum);
  }

  onClickItem(node, rowValue: string, columnCode: string, num: number, columnValue?: string) {
    if (num == 0) {
      return;
    }
    // const j = document.getElementsByClassName('on');
    // for (let i = 0; i < j.length; i++) {
    //   j[i].className = '';
    // }
    // if (node !== undefined) {
    //   node.className = 'on';
    // }

    const filter = new ContainerFilter();

    if (rowValue !== 'SUM') {
      if (this.rowType === ColumnIds.POD) {
        filter.podMap.set(rowValue, rowValue);
      }
      if (this.rowType === ColumnIds.POL) {
        filter.polMap.set(rowValue, rowValue);
      }
      if (this.rowType === ColumnIds.POR) {
        filter.porMap.set(rowValue, rowValue);
      }

      if (this.rowType === ColumnIds.CARRIER) {
        filter.carrierMap.set(rowValue, rowValue);
      }

      if (this.rowType === ColumnIds.HATCH) {
        filter.hatchMap.set(rowValue, rowValue);
        rowValue.split(' ').forEach(bay => {
          filter.bayMap.set(bay, bay);
        });
      }
    }

    if (columnCode === ColumnIds.CONTAINER_LENGTH) {
      filter.lengthCodeMap.set(columnValue, columnValue);
    }

    if (columnCode === ColumnIds.CARGO_TYPE_CODE) {
      filter.cargoTypeMap.set(columnValue, columnValue);
    }

    if (columnCode === ColumnIds.OVER_SLOTS_YN) {
      filter.overSlotYnMap.set('Y', 'Y');
    }
    if (columnCode === ColumnIds.OVER_DIMENSIONS_YN) {
      filter.overDimensionYnMap.set('Y', 'Y');
    }
    if (columnCode === ColumnIds.BREAK_BULK_YN) {
      filter.breakBulkYnMap.set('Y', 'Y');
    }

    if (columnCode === ColumnIds.BUNDLED_CONTAINER_YN) {
      filter.bundledYnMap.set('Y', 'Y');
    }
    this.eventService.emit(EventIds.APPLY_FILTER, filter);
  }
  mouseLeaveCell(node, cssClass: string) {
    node.className = cssClass;
  }
  mouseEnterCell(node, cssClass: string, sum: number) {
    if (sum > 0) {
      node.className = cssClass + '-selected';
    }
  }
  decodeCargoType(code: string): string {
    return ContainerUtils.decodeCargoType(code);
  }
  deepObject<T>(source: T) {
    const result = {};
    Object.keys(source).forEach((key) => {
      const value = source[key];
      result[key] = this.deep(value);
    }, {});
    return result as T;
  }
  deep<T extends object>(source: T): T {
    return JSON.parse(JSON.stringify(source));
  }
  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }

    // this.tabSubscription.unsubscribe();
  }

  clearFilters() {
    this.filterService.clearFilters();
  }
}

export class ContainerSummary {
  color;
  total = 0;

  length20 = 0;
  length40 = 0;
  lengthL = 0;
  lengthM = 0;
  lengthUnknown = 0;

  empty = 0;
  reefer = 0;
  dangerous = 0;
  emptyDangerous = 0;
  reeferDangerous = 0;
  otherFull = 0;

  overSlot = 0;
  overDimension = 0;
  breakBulk = 0;
  bundled = 0;

  constructor(public rowValue) {
  }
}

export class DropdownValue {
  value: string;
  label: string;

  constructor(value: string, label: string) {
    this.value = value;
    this.label = label;
  }
}
const PROPERTIES = {
  TOTAL: 'TOTAL',
  LENGTH_2: '0112',
  LENGTH_4: '0114',
  LENGTH_L: '011L',
  LENGTH_M: '011M',
  LENGTH_UNKNOWN: '011',

  REEFER: '016RF',
  DG: '016DG',
  REEFER_DG: '016RD',
  EMPTY_DG: '016DM',
  EMPTY: '016MT',
  OTHER_FULL: '016OT',
};
