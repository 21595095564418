import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {Container} from '../../service/model/container';
import {EventIds} from '../../core/event/event-ids';
import {ContainerVisualPart} from '../../edit/bay-plan/visual-part/container.visual-part';
import {SessionIds, SessionValues} from '../../core/service/session-ids';
import {ContainerFilterService} from '../../service/container-filter.service';
import {ContainerSelectionEventService} from '../../core/event/container-selection-event.service';
import {AuthService} from '../../user/service/auth.service';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertiesComponent implements OnInit, OnDestroy {
  containers = new Array<Container>();

  selectedContainer: Container;
  @Output()
  containerEmitter = new EventEmitter<Container>();

  @Output()
  url = '';

  buttonColor = 'primary';
  isEditable = false;
  private containerSelectionSubscription;
  private windowKeyDownListener: any;

  constructor(private elementRef: ElementRef,
              private cdr: ChangeDetectorRef,
              private containerSelectionEventService: ContainerSelectionEventService,
              private authService: AuthService,
              public filterService: ContainerFilterService
  ) { }

  ngOnInit(): void {

    this.windowKeyDownListener = this.windowKeyDown.bind(this);
    window.addEventListener('keydown', this.windowKeyDownListener, true);

    this.containerSelectionSubscription = this.containerSelectionEventService.getEmitter().subscribe(event => {
      if (event.id === EventIds.SELECT_VISUAL_PARTS) {
        this.containers = new Array<Container>();
        if (!event.newValue) {
          this.selectedContainer = null;
          this.containerEmitter.emit(null);
          this.cdr.detectChanges();
          return;
        }
        if (event.newValue instanceof Array) {

          if (sessionStorage.getItem(SessionIds.SELECTION_MODE) === SessionValues.SELECTION_MODE_INFO) {
            if (event.newValue.length > 1) {
              this.selectedContainer = null;
              this.containerEmitter.emit(null);
              this.cdr.detectChanges();
              return;
            }
          }

          let index = 0;
          event.newValue.forEach(data => {
            if (data instanceof ContainerVisualPart) {
              const containerVisualPart = data as ContainerVisualPart;

              if (index == 0) {
                this.selectedContainer = containerVisualPart.container;
                this.containerEmitter.emit(this.selectedContainer);
                index++;
              }
              this.containers.push(containerVisualPart.container);
              // window.location.reload();
            }
          });
          this.cdr.detectChanges();
        }
      }
      if (event.id === EventIds.CONTAINERS_SELECTED) {
        this.containers = event.newValue as Container[];
        this.url = event.source;
        if (this.containers.length > 0) {
          this.selectedContainer = this.containers[0];
          if (this.selectedContainer.modified) {
            this.isEditable = true;
          }
        } else {
          this.selectedContainer = null;
        }
        this.containerEmitter.emit(this.selectedContainer);
        this.cdr.detectChanges();
      } else if (event.id === EventIds.ROUTE_CHANGE) {
        this.url = event.newValue;
        // console.info(event);
      }
    });
    if (!this.containerSelectionEventService.selections || this.containerSelectionEventService.selections.length == 0) {
      this.selectedContainer = null;
    }
    let index = 0;
    this.containerSelectionEventService.selections.forEach(data => {

      if (index == 0 ) {
        this.selectedContainer = data;
        this.containerEmitter.emit(this.selectedContainer);
        index++;
      }
      this.containers.push(data);

    });

    if (this.containerSelectionEventService.selections.length == 0) {
      this.containerEmitter.emit(null);
    }
  }
  formatStowage(stowage: string): string {
    if (!stowage) {
      return stowage;
    }
    if (stowage.length == 7) {
      return stowage.substring(0, 3) + '  ' + stowage.substring(3, 5) + '  ' + stowage.substring(5, 7);
    }
    if (stowage.length == 6) {
      return stowage.substring(0, 2) + '  ' + stowage.substring(2, 4) + '  ' + stowage.substring(4, 6);
    }
    return stowage;
  }
  private windowKeyDown(e: KeyboardEvent) {
    // console.info(e);
    if (e.key === Keys.Escape) {
      this.isEditable = false;
      e.preventDefault();
    }
  }
  isSelected(stowage: string) {
    return this.selectedContainer && this.selectedContainer.stowage === stowage;
  }

  clickStowageButton(container: Container) {
    this.selectedContainer = container;
    this.containerEmitter.emit(container);
    this.cdr.detectChanges();
  }

  changeEditable(editable: boolean) {
    this.isEditable = editable;
  }

  getEmptyContainer() {
    return new Container();
  }

  ngOnDestroy(): void {
    if (this.containerSelectionSubscription) {
      this.containerSelectionSubscription.unsubscribe();
    }
    window.removeEventListener('keydown', this.windowKeyDownListener, true);
  }
}
enum Keys {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Space = 'Space',
  Tab = 'Tab',
  Delete = 'Delete',
  Escape = 'Escape',
  Backspace = 'Backspace',
  Enter = 'Enter',
  Shift = 'Shift',
  Control = 'Control',
  Alt = 'Alt'
}
