export class Place {
  type: string;
  id: string;
  qualifierCode: string;
  responsibleAgency: string;

  countryCode: string;
  countryResponsibleAgency: string;

  terminalCode: string;
  terminalQualifierCode: string;
  terminalResponsibleAgency: string;

  stateId: string;

  constructor() {
  }
}
