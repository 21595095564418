import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';

import {HandleError, HttpErrorHandler} from '../../../service/http-error-handler.service';
import {PortCode} from './model/port.code';
import {properties} from '../../../../environments/environment';
import {Port} from './model/port';
import {EdiPort} from './model/edi.port';
import {Observable} from 'rxjs';
import {Operator} from '../../operator/service/model/operator';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
    })
};

@Injectable({
    providedIn: 'root'
})
export class PortService {

  private readonly handleError: HandleError;

  constructor(private http: HttpClient,
              httpErrorHandler: HttpErrorHandler) {
      this.handleError = httpErrorHandler.createHandleError('PortService');
  }

  getOwnPorts(): Observable<Port[]> {
    return this.http.get<Port[]>(properties.serverUrl + '/port/own');
  }
  getEdiPorts() {
    return this.http.get<EdiPort[]>(properties.serverUrl + '/port/edi');
  }
  updateOwnPorts(ports: Port[]) {
    return this.http.post(properties.serverUrl + '/port/own', ports);
  }
  updateEdiPorts(ports: EdiPort[]) {
    return this.http.post(properties.serverUrl + '/port/edi', ports);
  }

  deleteOwnPorts(ports: Port[]) {

    let ids = '';

    ports.forEach(port => {

      ids = ids + ',' + port.id;
    });
    return this.http.delete(properties.serverUrl + '/port/own?id=' + ids);
  }
  deleteEdiPorts(ports: EdiPort[]) {

    let ids = '';

    ports.forEach(port => {
      ids = ids + ',' + port.id;
    });
    return this.http.delete(properties.serverUrl + '/port/edi?id=' + ids);
  }
  getAllPortCodes(code: string) {

    return this.http.get<PortCode[]>(properties.serverUrl + '/port/iso/filtered?code=' + code);
  }
  changePortColor(port: Port, serviceLaneCode?: string) {
    return this.http.post(properties.serverUrl + '/port/color?lane=' + serviceLaneCode, port);
  }
}
