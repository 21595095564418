import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-user-deletion-dialog',
  templateUrl: './user-deletion-dialog.component.html',
  styleUrls: ['./user-deletion-dialog.component.css']
})
export class UserDeletionDialogComponent implements OnInit {

  public form: FormGroup;
  submitted = false;
  teamName = '';
  teamNameControl: FormControl;

  constructor(private dialogRef: MatDialogRef<UserDeletionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) @Inject(MAT_DIALOG_DATA) data: any,
              private fb: FormBuilder) {
    this.teamName = data;
    this.teamNameControl = new FormControl('', [Validators.required, matchValidator(this.teamName)]);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      teamNameControl: this.teamNameControl
    });
  }
  get Form() { return this.form.controls; }
  close() {
    this.dialogRef.close({data: false});
  }
  delete() {
    this.submitted = true;
    if (this.teamNameControl.errors) {
      return;
    }
    this.dialogRef.close({data: true});
    // console.info('delete');
  }

  onKeydown() {
    this.submitted = false;
  }
}

export function matchValidator(value: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value !== value ? {unmatched: true} : null;
  };
}
