import {EdiHeader} from '../../my-plans/service/model/edi-header';
import {EditableModel} from '../../be-com/etable/editable-model';
import {VesselDesign} from './vessel-design';

export class VesselDesignInput extends VesselDesign {

  constructor(header: EdiHeader) {
    super();
    this.callSign = header.callSign;
    this.imoNo = header.imoNo;
    this.vesselCode = header.vesselCode;
    this.vesselName = header.vesselName;
  }
}
