import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {EdiDocument} from '../../my-plans/service/model/edi-document';
import {EventService} from '../../core/event/event.service';
import {EventIds} from '../../core/event/event-ids';
import {BayPlanService} from '../../service/bay-plan.service';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {OkDialogComponent} from '../../dialogs/ok-dialog/ok-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DateTimeUtils} from '../../hull/service/date-time-utils';
import {ObjectHelper} from '../../hull/service/object-helper';
import {ServiceLane} from '../../code/service-lane/serivce/model/service-lane';
import {AuthEventId, AuthService} from '../../user/service/auth.service';
import {ServiceLaneService} from '../../code/service-lane/serivce/service-lane.service';
import {FormControl} from '@angular/forms';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {VesselSchedule} from '../../my-plans/vessel-schedule/service/model/vessel-schedule';
import {SessionIds} from '../../core/service/session-ids';
import {EdiFileService} from '../../my-plans/edi-file/service/edi-file.service';
import {ScheduleService} from '../../my-plans/vessel-schedule/service/schedule.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-baplie-header',
  templateUrl: './baplie-header.component.html',
  styleUrls: ['./baplie-header.component.scss']
})
export class BaplieHeaderComponent implements OnInit, OnDestroy {

  filteredServiceLanes: ServiceLane[];
  serviceLaneControl = new FormControl();
  isServiceLaneLoading = false;

  ediDocument = new EdiDocument();

  ediHeader = new VesselSchedule();

  public config: PerfectScrollbarConfigInterface = {};
  private mediaMatcher: MediaQueryList = matchMedia(
    `(max-width: 960px)`
  );
  private eventSubscription;
  private authSubscription;
  private tabSubscription;

  editableColumn = '';

  @Input()
  tabEmitter: EventEmitter<MatTabChangeEvent>;
  private isEditable = false;
  showProgress = false;

  // vesselScheduleId = '';

  constructor(private authService: AuthService,
              private serviceLaneService: ServiceLaneService,
              private scheduleService: ScheduleService,
              private eventService: EventService,
              private cdr: ChangeDetectorRef,
              private bayPlanService: BayPlanService,
              private ediFileService: EdiFileService,
              private dialog: MatDialog,
              private translate: TranslateService,
              protected snackBar: MatSnackBar) {
    this.ediDocument = new EdiDocument();
    this.ediHeader = new VesselSchedule();
    this.eventSubscription = this.eventService.getEmitter().subscribe(event => {

      if (event.id === EventIds.CHANGE_EDI_HEADER) {

        this.initServiceLaneControl();

      } else if (event.id === EventIds.SELECT_EDI_FILE) {

        if (event.newValue) {
          this.ediHeader = ObjectHelper.deepCopy(event.newValue);

          if (this.ediHeader) {
            this.ediHeader.etd = DateTimeUtils.toRfcFormat(this.ediHeader.etd);
            this.ediHeader.nextEta = DateTimeUtils.toRfcFormat(this.ediHeader.nextEta);
            // this.vesselScheduleId = this.ediHeader.id;
            this.ediHeader.id = this.ediHeader.ediId;
          } else {
            this.ediHeader = new VesselSchedule();
          }

        } else {
          this.ediHeader = new VesselSchedule();
        }

      } else if (event.id ===  EventIds.LOAD_BAY_PLAN) {
        this.ediHeader = event.newValue;
      }
    });

    this.authSubscription = this.authService.getEmitter().subscribe(event => {
      if (event.id === AuthEventId.CHANGE_TEAM) {
        this.ediHeader = new VesselSchedule();
        this.initServiceLaneControl();
      }
    });
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.initServiceLaneControl();
    }

    // this.tabSubscription = this.tabEmitter.subscribe(event => {
    //   if (event.tab.textLabel === BottomTabText.HEADER) {
    //     this.inputItemField();
    //   }
    // });
  }
  eligibleToEdit(): boolean {

    return this.authService.eligibleToEdit();
  }
  private initServiceLaneControl() {
    this.serviceLaneService.getServiceLanes().subscribe(lanes => {

      this.filteredServiceLanes = lanes;
      this.serviceLaneControl.valueChanges
        .pipe(
          debounceTime(100),
          tap(() => this.isServiceLaneLoading = true),
          switchMap(value => this.filterServiceLanes(lanes, value).pipe(finalize(() => this.isServiceLaneLoading = false)), )
        ).subscribe(data => {this.filteredServiceLanes = data; });
    });
  }
  filterServiceLanes(serviceLanes: ServiceLane[], filter: string): Observable<ServiceLane[]> {
    const list = serviceLanes.filter(data => data.code.toUpperCase().startsWith(filter.toUpperCase()));
    return of(list);
  }

  save() {

    if (this.editable()) {

      const ediHeader = this.bayPlanService.getBayPlan().header;
      ediHeader.sender = this.ediHeader.sender;
      ediHeader.recipient = this.ediHeader.recipient;
      ediHeader.interchangeControlCount = this.ediHeader.interchangeControlCount;
      ediHeader.interchangeControlRefStart = this.ediHeader.interchangeControlRefStart;
      ediHeader.interchangeControlRefEnd = this.ediHeader.interchangeControlRefEnd;
      ediHeader.messageRefNo = this.ediHeader.messageRefNo;
      ediHeader.messageType = this.ediHeader.messageType;
      ediHeader.smdgVersion = this.ediHeader.smdgVersion;
      ediHeader.documentNo = this.ediHeader.documentNo;
      ediHeader.messageFunction = this.ediHeader.messageFunction;
      ediHeader.carrier = this.ediHeader.carrier;
      ediHeader.serviceLaneCode = this.ediHeader.serviceLaneCode;
      ediHeader.vesselName = this.ediHeader.vesselName;
      ediHeader.vesselCode = this.ediHeader.vesselCode;
      ediHeader.voyageNo = this.ediHeader.voyageNo;
      ediHeader.portCode = this.ediHeader.portCode;
      ediHeader.nextPortCode = this.ediHeader.nextPortCode;

      this.showProgress = true;
      this.bayPlanService.updateToServer().subscribe(() => {
          this.showProgress = false;
          this.isEditable = false;
          this.openSnackBar('Saved', 'Close');
          this.eventService.emit(EventIds.BAPLIE_HEADER_SAVED);
        }, error => {
          console.error(error);
          this.showProgress = false;
          this.showErrorDialog(error);
        }
      );


    } else {

      const param = {id: this.ediHeader.id, serviceLaneCode: this.ediHeader.serviceLaneCode};
      this.scheduleService.updateServiceLaneCode(param).subscribe(data => {
        this.eventService.emit(EventIds.BAPLIE_HEADER_SAVED);
        this.eventService.emit(EventIds.CONSOLE_LOGGING, this.ediHeader.messageType  + ' ' + this.ediHeader.vesselName + ' / ' + this.ediHeader.voyageNo + ': service lane changed', 'INFO');
        this.isEditable = false;
      }, error => {
        console.error(error);
        this.showErrorDialog(error);
      });
    }
  }

  formatDateTime(dateTime: string): string {
    if (dateTime) {
      return DateTimeUtils.formatDateTime(dateTime);
    }
    return dateTime;
  }

  displayFn(code: string): string {
    return code;
  }
  getMessageFunctionDesc(code: string): string {

    if ('2' === code) {
      return '(Add)';
    }
    if ('3' === code) {
      return '(Delete)';
    }
    if ('4' === code) {
      return '(Change)';
    }
    if ('5' === code) {
      return '(Replace)';
    }
    if ('9' === code) {
      return '(Original)';
    }
    if ('22' === code) {
      return '(Final)';
    }
    return '';
  }

  editable(always?: boolean): boolean {

    // if (!this.vesselScheduleId) {
    //   return false;
    // }

    const ediId = sessionStorage.getItem(SessionIds.EDI_ID);

    if (always) {
      return this.isEditable;
    }

    return this.isEditable && this.ediHeader.id === ediId;
  }
  clickToEdit(code: string) {
    this.editableColumn = code;
    this.cdr.detectChanges();
    if (code === 'SERVICE_LANE_CODE') {
      document.getElementById('serviceLaneInput').focus();
    }
  }

  blur(code: string) {

    if (code === 'SERVICE_LANE_CODE') {
      this.editableColumn = '';
    }
  }

  changeEditable(editable: boolean) {
    this.isEditable = editable;
  }
  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.tabSubscription) {
      this.tabSubscription.unsubscribe();
    }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  showErrorDialog(error: any, searchAgain?: boolean) {
    console.error(error);
    let errorMessage = error.message;
    if (typeof error.error === 'string') {
      errorMessage = this.translate.instant(error.error);
    }
    const dialogRef = this.dialog.open(OkDialogComponent, {
      maxWidth: '800px',
      data: {header: error.statusText, message: errorMessage}
    });
    if (searchAgain) {
      dialogRef.afterClosed().subscribe(ok => {
      });
    }
  }
}
