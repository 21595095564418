import xml2js from 'xml2js';
import {EdiValidateService} from './edi-validate.service';
import {EdiAdapterService} from './edi-adapter.service';
import {EdiDocument} from './model/edi-document';
import {HttpClient} from '@angular/common/http';
import {Place} from './model/place';
import {SyntaxMessage} from './model/syntax-message';
import {SyntaxMessageError} from './model/syntax-message-error';
import {MarkersProperty} from '../../panels/markers/model/markers.property';
import {Observable} from 'rxjs';

export class BaplieReaderService extends EdiValidateService {

  ediAdapterService: EdiAdapterService = new EdiAdapterService();
  data;
  parser: xml2js;
  syntaxGroup: number;
  ports: Map<string, Place> = new Map<string, Place>();

  constructor(data: string | ArrayBuffer, private http: HttpClient) {
    super();
    this.data = data;
    this.parser = new xml2js.Parser({trim: true, explicitArray: true});
  }
  async parseEDI(id?: string, updateDatetime?: string, serviceLaneCode?: string): Promise<EdiDocument> {
    this.ports = new Map<string, Place>();
    const ediDocument: EdiDocument = new EdiDocument();
    const lines: string[] = this.data.split('\'');
    let header;
    if (lines.length > 1) {
      const unh = lines[1].split('+');
      header = unh[unh.length - 1];
    }
    const self = this;
    this.syntaxGroup = 0;
    const schema = await this.getSchema(header).toPromise();
    this.parser.parseString(schema, function _(err, syntax) {
      // PLAN >> TEXT
      // self.validationSchema(lines, syntax, ediDocument);
      let lineIndex = 1;
      let handle = 'LOA';
      self.syntaxGroup = 0;
      for (const line of lines) {
        const segmentLine: string[] = line.split('+');
        if (segmentLine.length > 1 && segmentLine[0] === 'HAN') {
          handle = segmentLine[1];
        }
        self.translate(line, ediDocument, syntax, lineIndex++, handle);
      }
    });
    if (id) {
      ediDocument.id = id;
    }
    if (updateDatetime) {
      ediDocument.updateDatetime = updateDatetime;
    }
    if (serviceLaneCode) {
      ediDocument.serviceLaneCode = serviceLaneCode;
    }
    return ediDocument;
  }
  private getSchema(header: string) {
    if (header !== undefined) {
      const headers = header.split(':');
      const type = headers[0];
      const version = headers[headers.length - 1];
      if (type === 'BAPLIE') {
        if (version === 'SMDG15') {
          return this.http.get('/assets/schema/BAPLIE15.xml', {responseType: 'text'});
        } else if (version === 'SMDG31') {
          return this.http.get('/assets/schema/BAPLIE31.xml', {responseType: 'text'});
        }
        return this.http.get('/assets/schema/BAPLIE22.xml', {responseType: 'text'});
      } else if (type === 'MOVINS') {
        return this.http.get('/assets/schema/MOVINS21.xml', {responseType: 'text'});
      }
    }
    return this.http.get('/assets/schema/BAPLIE22.xml', {responseType: 'text'});
  }

  private translate(line: string, ediDocument: EdiDocument, syntax, lineIndex: number, handle: string) {
    const segmentLine: string[] = line.split('+');
    const segmentId = segmentLine[0].trim();
    const item = this.findSegmentSyntax(segmentId, syntax);
    let stowages = ediDocument.stowages;
    if (handle === 'SHI') {
      stowages = ediDocument.shiftStowages;
    } else if (handle === 'RES') {
      stowages = ediDocument.restowStowages;
    } else if (handle === 'DIS') {
      stowages = ediDocument.dischargeStowages;
    } else if (handle === 'COD') {
      stowages = ediDocument.codStowages;
    } else if (handle === 'EXC') {
      stowages = ediDocument.excessStowages;
    } else if (handle === 'BAL') {
      stowages = ediDocument.balanceStowages;
    } else if (handle === 'VOI') {
      stowages = ediDocument.avoidStowages;
    }
    const stowageLength = stowages.length;
    if (!item || !item.segment) {
      if (stowageLength > 0) {
        stowages[stowageLength - 1].extraElements.push(line);
      }
      return;
    }

    let repeatGroup = false;
    if (item.$.currentGroup !== undefined) {
      if (Number(item.$.currentGroup) < this.syntaxGroup) {
        repeatGroup = true;
      }
      this.syntaxGroup = Number(item.$.currentGroup);
    }
    if (segmentId === 'LOC' && this.syntaxGroup === 2 && segmentLine.length > 1 && segmentLine[1] !== '147') {
      this.syntaxGroup = 2.5;
    }
    let indexSegment = 0;
    let firstElement = true;
    for (let s = 0; s < item.segment.length; s = s + 1) {
      const errorArray: Array<SyntaxMessageError> = [];
      const segmentItem = item.segment[s];
      if (s === 1) {
        firstElement = false;
        repeatGroup = false;
      }

      if (segmentItem.element === undefined) {
        indexSegment += segmentItem.$.symbol === undefined ? 1 : Number(segmentItem.$.symbol);
        if (indexSegment >= segmentLine.length) {
          break;
        }
        this.putSegmentElementValue(segmentId, segmentItem, segmentLine[indexSegment], ediDocument, firstElement, repeatGroup, errorArray);
        continue;
      }

      indexSegment += segmentItem.element[0].$.symbol === undefined ? 1 : Number(segmentItem.element[0].$.symbol);
      if (indexSegment >= segmentLine.length) {
        break;
      }
      let indexElement = -1;
      const elementLine: string[] = segmentLine[indexSegment].split(':');
      for (let e = 0; e < segmentItem.element.length; e = e + 1) {
        if (e === 1) {
          firstElement = false;
          repeatGroup = false;
        }
        const elementItem = segmentItem.element[e];
        indexElement += (e === 0 || !elementItem.$.symbol) ? 1 : Number(elementItem.$.symbol);
        if (indexElement >= elementLine.length) {
          break;
        }
        this.putSegmentElementValue(segmentId, elementItem, elementLine[indexElement], ediDocument, firstElement, repeatGroup, errorArray);
      }

      for (const error of errorArray) {
        this.addSyntaxMessage(ediDocument.syntaxMessages, lineIndex, line, error);
      }
    }
    if (stowageLength !== stowages.length) {
      stowages[stowages.length - 1].lineIndex = lineIndex;
    }
    if (item.$.nextGroup !== undefined) {
      this.syntaxGroup = Number(item.$.nextGroup);
    }
  }

  private validationSchema(lines: string[], syntax, ediDocument: EdiDocument) {
    let groupCd = 'GRP0';
    let countIndex = 0;
    let stowage = '';
    for (let lineIdx = 0; lineIdx < lines.length; lineIdx++) {
      const line = lines[lineIdx].split('+');
      if (line.length > 2 && line[0].trim() === 'LOC' && line[1].trim() === '147') {
        stowage = line[2];
      }
      if (line.length > 1 && line[0].trim() === 'LOC' && (line[1].trim() === 'BE' || line[1].trim() === 'PB')) {
        continue;
      }

      const groupSyntax = this.findGroupSyntax(groupCd, countIndex, syntax);
      const segments: string[] = groupSyntax.$.segment.split('+');
      let groupCount = 0;
      let firstGroupSegment = '';
      let loop = 0;
      let segmentId = '';
      for (const segment of segments) {
        this.resetSyntaxGroup(groupCd, countIndex);
        const item = this.findSegmentSyntax(segment, syntax);
        if (!item) {
          continue;
        }
        if (segmentId !== segment) {
          loop = 0;
        }
        if (!firstGroupSegment) {
          firstGroupSegment = segment;
        }
        if (segment === segments[0]) {
          groupCount++;
        }
        if (loop > Number(item.$.loop)) {
          const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_SYNTAX);
          error.syntaxName = MarkersProperty.ITEM_SYNTAX_LOOP;
          error.description = item.$.name;
          error.stowage = stowage;
          this.addSyntaxMessage(ediDocument.syntaxMessages, lineIdx, lines[lineIdx], error);
        }
        if (firstGroupSegment === segments[0] && loop === 0 && item.$.mandatory === 'M' && segment !== segments[0]) {
          const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_SYNTAX);
          error.syntaxName = MarkersProperty.ITEM_SYNTAX_MISS_ELEMENT;
          error.description = item.$.name;
          error.stowage = stowage;
          this.addSyntaxMessage(ediDocument.syntaxMessages, lineIdx, lines[lineIdx], error);
        }
        segmentId = segment;
        loop++;
      }
      const segmentItem = this.findSegmentSyntax(segments[0], syntax);
      if (firstGroupSegment !== segments[0] && segmentItem.$.mandatory === 'M'
        && (groupSyntax.$.mandatory === 'M' || firstGroupSegment !== undefined)) {
        const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_SYNTAX);
        error.syntaxName = MarkersProperty.ITEM_SYNTAX_MISS_ELEMENT;
        error.description = segmentItem.$.name;
        error.stowage = stowage;
        this.addSyntaxMessage(ediDocument.syntaxMessages, lineIdx, lines[lineIdx], error);
      }
      if (groupCd === groupSyntax.$.nextGroup) {
        countIndex++;
      } else if (groupSyntax.$.nextGroup !== undefined) {
        countIndex = 0;
      }
      groupCd = groupSyntax.$.nextGroup;
      if (groupCd === undefined || groupCount === 0) {
        groupCd = groupSyntax.$.previousGroup;
      }
    }
  }

  addSyntaxMessage(syntaxMessages: Array<SyntaxMessage>, lineIndex: number, lineText: string, error: SyntaxMessageError) {
    let message: SyntaxMessage;
    for (const syntax of syntaxMessages ) {
      if (syntax.lineIndex === lineIndex) {
        message = syntax;
      } else if (syntax.lineIndex > lineIndex) {
        break;
      }
    }
    if (message === undefined) {
      message = new SyntaxMessage();
      message.lineIndex = lineIndex;
      message.lineText = lineText;
      message.errorMessageArray.push(error);
      syntaxMessages.push(message);
    } else {
      message.errorMessageArray.push(error);
    }
  }

  resetSyntaxGroup(segmentId: string, index: number) {
    if (segmentId === 'GRP0') {
      this.syntaxGroup = 0;
    } else if (segmentId === 'GRP1' && index === 0) {
      this.syntaxGroup = 1;
    } else if (segmentId === 'GRP1' && index === 1) {
      this.syntaxGroup = 2;
    } else if (segmentId === 'GRP2' && index === 0) {
      this.syntaxGroup = 3;
    } else if (segmentId === 'GRP2' && index === 1) {
      this.syntaxGroup = 4;
    }
  }

  findGroupSyntax(segmentId: string, index: number, syntax) {
    if (segmentId === 'GRP0') {
      return syntax.GRP0;
    } else if (segmentId === 'GRP1') {
      return syntax.GRP0.GRP1[index];
    } else if (segmentId === 'GRP2') {
      return syntax.GRP0.GRP1[1].GRP2[index];
    }
  }

  setSyntaxGroup(syntaxGroup: number) {
    this.syntaxGroup = syntaxGroup;
  }

  findSegmentSyntax(segmentId: string, syntax) {
    if (segmentId === 'UNB') {
      return syntax.GRP0.UNB[0];
    } else if (segmentId === 'UNH') {
      return syntax.GRP0.UNH[0];
    } else if (segmentId === 'BGM') {
      return syntax.GRP0.BGM[0];
    } else if (segmentId === 'DTM' && this.syntaxGroup === 0) {
      return syntax.GRP0.DTM[0];
    } else if (segmentId === 'RFF' && this.syntaxGroup === 0 && syntax.GRP0.RFF) {
      return syntax.GRP0.RFF[0];
    } else if (segmentId === 'TDT') {
      return syntax.GRP0.GRP1[0].TDT[0];
    } else if (segmentId === 'LOC' && this.syntaxGroup === 1) {
      return syntax.GRP0.GRP1[0].LOC[0];
    } else if (segmentId === 'DTM') {
      return syntax.GRP0.GRP1[0].DTM[0];
    } else if (segmentId === 'RFF' && this.syntaxGroup === 1) {
      return syntax.GRP0.GRP1[0].RFF[0];
    } else if (segmentId === 'HAN' && syntax.GRP0.HAN) {
      return syntax.GRP0.HAN[0];
    } else if (segmentId === 'LOC' && (this.syntaxGroup === 2 || this.syntaxGroup >= 3)) {
      return syntax.GRP0.GRP1[1].LOC[0];
    } else if (segmentId === 'GID' && syntax.GRP0.GRP1[1].GID) {
      return syntax.GRP0.GRP1[1].GID[0];
    } else if (segmentId === 'GDS') {
      return syntax.GRP0.GRP1[1].GDS[0];
    } else if (segmentId === 'FTX' && this.syntaxGroup === 2) {
      return syntax.GRP0.GRP1[1].FTX[0];
    } else if (segmentId === 'MEA') {
      return syntax.GRP0.GRP1[1].MEA[0];
    } else if (segmentId === 'DIM') {
      return syntax.GRP0.GRP1[1].DIM[0];
    } else if (segmentId === 'TMP') {
      return syntax.GRP0.GRP1[1].TMP[0];
    } else if (segmentId === 'RNG') {
      return syntax.GRP0.GRP1[1].RNG[0];
    } else if (segmentId === 'LOC' && this.syntaxGroup === 2.5) {
      return syntax.GRP0.GRP1[1].LOC[1];
    } else if (segmentId === 'RFF' && this.syntaxGroup > 1) {
      return syntax.GRP0.GRP1[1].RFF[0];
    } else if (segmentId === 'EQD') {
      return syntax.GRP0.GRP1[1].GRP2[0].EQD[0];
    } else if (segmentId === 'EQA') {
      return syntax.GRP0.GRP1[1].GRP2[0].EQA[0];
    } else if (segmentId === 'NAD') {
      return syntax.GRP0.GRP1[1].GRP2[0].NAD[0];
    } else if (segmentId === 'DGS') {
      return syntax.GRP0.GRP1[1].GRP2[1].DGS[0];
    } else if (segmentId === 'FTX' && this.syntaxGroup === 4) {
      return syntax.GRP0.GRP1[1].GRP2[1].FTX[0];
    } else if (segmentId === 'UNT') {
      return syntax.GRP0.UNT[0];
    } else if (segmentId === 'UNZ') {
      return syntax.GRP0.UNZ[0];
    }
    return undefined;
  }

  putSegmentElementValue(segmentId: string, elementItem, elementLine: string, ediDocument: EdiDocument,
                         firstElement: boolean, repeatGroup: boolean, errorArray: Array<SyntaxMessageError>) {
    const newErrorArray: Array<SyntaxMessageError> = this.validation(elementItem, elementLine);
    for (const error of newErrorArray) {
      errorArray.push(error);
    }
    if (segmentId === 'UNB') {
      this.ediAdapterService.putInterchangeHeader(elementItem, elementLine, ediDocument);
    } else if (segmentId === 'UNH') {
      this.ediAdapterService.putMessageHeader(elementItem, elementLine, ediDocument);
    } else if (segmentId === 'BGM') {
      this.ediAdapterService.putBeginningMessage(elementItem, elementLine, ediDocument);
    } else if (segmentId === 'DTM' && this.syntaxGroup === 0) {
      this.ediAdapterService.putDateTimePeriodGroup0(elementItem, elementLine, ediDocument);
    } else if (segmentId === 'RFF' && this.syntaxGroup === 0) {
      this.ediAdapterService.putReferenceGroup0(elementItem, elementLine, ediDocument);
    } else if (segmentId === 'TDT') {
      this.ediAdapterService.putDetailTransport(elementItem, elementLine, ediDocument);
    } else if (segmentId === 'HAN') {
      this.ediAdapterService.petHandlingInstruction(elementItem, elementLine, ediDocument);
    } else if (segmentId === 'LOC' && this.syntaxGroup === 1) {
      this.ediAdapterService.putPlaceIdentificationGroup1(elementItem, elementLine, ediDocument, firstElement);
    } else if (segmentId === 'DTM') {
      this.ediAdapterService.putDateTimePeriodGroup1(elementItem, elementLine, ediDocument, firstElement);
    } else if (segmentId === 'RFF' && this.syntaxGroup === 2) {
      this.ediAdapterService.putReferenceGroup1(elementItem, elementLine, ediDocument);
    } else if (segmentId === 'FTX' && this.syntaxGroup === 1) {
      this.ediAdapterService.putFreeText0(elementItem, elementLine, ediDocument, firstElement, repeatGroup);
    } else if (segmentId === 'LOC' && this.syntaxGroup === 2) {
      this.ediAdapterService.putPlaceIdentificationGroup2(elementItem, elementLine, ediDocument, repeatGroup);
    } else if (segmentId === 'GID') {
      this.ediAdapterService.putGoodItemDetail(elementItem, elementLine, ediDocument, repeatGroup);
    } else if (segmentId === 'GDS') {
      this.ediAdapterService.putNatureCargo(elementItem, elementLine, ediDocument, firstElement, repeatGroup);
    } else if (segmentId === 'FTX' && this.syntaxGroup < 4) {
      this.ediAdapterService.putFreeText(elementItem, elementLine, ediDocument, firstElement, repeatGroup);
    } else if (segmentId === 'MEA') {
      this.ediAdapterService.putWeight(elementItem, elementLine, ediDocument, firstElement, repeatGroup);
    } else if (segmentId === 'DIM') {
      this.ediAdapterService.putDimension(elementItem, elementLine, ediDocument, firstElement, repeatGroup);
    } else if (segmentId === 'TMP') {
      this.ediAdapterService.putTemperature(elementItem, elementLine, ediDocument, repeatGroup);
    } else if (segmentId === 'RNG') {
      this.ediAdapterService.putRangeDetail(elementItem, elementLine, ediDocument, repeatGroup);
    } else if (segmentId === 'LOC' && this.syntaxGroup === 2.5) {
      this.ediAdapterService.putPlaceLocation(elementItem, elementLine, ediDocument, firstElement, repeatGroup, this.ports);
    } else if (segmentId === 'RFF' && this.syntaxGroup > 2) {
      this.ediAdapterService.putReferenceGroup2(elementItem, elementLine, ediDocument, firstElement, repeatGroup);
    } else if (segmentId === 'EQD') {
      this.ediAdapterService.putEquipment(elementItem, elementLine, ediDocument, firstElement, repeatGroup);
    } else if (segmentId === 'EQA') {
      this.ediAdapterService.putEquipmentAttached(elementItem, elementLine, ediDocument, firstElement, repeatGroup);
    } else if (segmentId === 'NAD') {
      this.ediAdapterService.putNameAddress(elementItem, elementLine, ediDocument, repeatGroup);
    } else if (segmentId === 'DGS') {
      this.ediAdapterService.putDangerousGood(elementItem, elementLine, ediDocument, firstElement, repeatGroup);
    } else if (segmentId === 'FTX') {
      this.ediAdapterService.putDangerousFreeText(elementItem, elementLine, ediDocument, repeatGroup);
    } else if (segmentId === 'UNT') {
      this.ediAdapterService.putMessageTrailer(elementItem, elementLine, ediDocument);
    } else if (segmentId === 'UNZ') {
      this.ediAdapterService.putInterchangeTrailer(elementItem, elementLine, ediDocument);
    }
  }

}
