import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EventService} from '../../core/event/event.service';
import {BayPlanService} from '../../service/bay-plan.service';
import {CodeService} from '../../code/service/code.service';
import {BayPlan} from '../../service/model/bay-plan';
import {Port} from '../../code/port/service/model/port';
import {Operator} from '../../code/operator/service/model/operator';
import {EventIds} from '../../core/event/event-ids';
import {SizeTypeCode} from '../../code/size-type/service/model/size-type-code';
import {ContainerFilter} from '../../service/model/container-filter';
import {ContainerFilterService} from '../../service/container-filter.service';
import {Preference} from '../../service/model/preference';
import {PreferenceService} from '../../service/preference.service';
import {ColumnService} from '../columns/service/column.service';
import {ColumnIds} from '../../panels/columns/service/model/column-ids';
import {AuthEventId, AuthService} from '../../user/service/auth.service';
import {Subscription} from 'rxjs';
import {StringUtils} from '../../utils/string-utils';
import {Freetext} from '../../my-plans/service/model/freetext';
import {Container} from '../../service/model/container';
import {ColumnUtils} from '../columns/service/model/column-utils';

@Component({
  selector: 'app-container-filter',
  templateUrl: './container-filter.component.html',
  styleUrls: ['./container-filter.component.css']
})
export class ContainerFilterComponent implements OnInit, OnDestroy {

  private authSubscription: Subscription;

  bayPlan: BayPlan;
  ColumnIds = ColumnIds;
  @Input()
  filter: ContainerFilter;

  minWeight = -1;
  maxWeight = -1;

  hatches = new Array<string>();

  ports: Port[];
  carriers = new Array<Operator>();

  pors = new Array<Port>();
  pols = new Array<Port>();
  pods = new Array<Port>();
  optPods = new Array<Port>();
  tsPorts = new Array<Port>();
  nPods = new Array<Port>();
  fPods = new Array<Port>();
  placeOfDeliveries = new Array<Port>();

  isoSizeTypes = new Array<string>();
  lengthCodes = new Array<SizeTypeCode>();
  heightCodes = new Array<SizeTypeCode>();
  typeCodes = new Array<SizeTypeCode>();

  fullEmpty = new Array<Preference>();
  cargoTypes = new Array<Preference>();
  imdgs = new Array<string>();

  bundledYn = new Array<string>();
  breakBulkYn = new Array<string>();
  overDimensionsYn = new Array<string>();
  overSlotYn = new Array<string>();
  temperatureRangeYn = new Array<string>();

  vgmYn = new Array<string>();
  vgmCertificationCode = new Array<string>();
  vgmInfoYm = new Array<string>();

  groupCodeYn = new Array<string>();
  groupCodes = new Array<string>();

  descriptionOfGoods = new Array<string>();
  handlingInstruction = new Array<string>();
  loadingRemarks = new Array<string>();
  specialCargoInstruction = new Array<string>();
  generalInformation = new Array<string>();
  mutuallyDefinedRemarks = new Array<string>();

  specialCargoInstructionYn = new Array<string>();
  loadingRemarkYn = new Array<string>();
  generalInformationYn = new Array<string>();
  mutuallyDefinedRemarkYn = new Array<string>();
  descriptionOfGoodsYn = new Array<string>();




  cargoNatureYn = new Array<string>();
  packageQuantityYn = new Array<string>();
  packageTypeYn = new Array<string>();

  blYn = new Array<string>();
  bookingNoYn = new Array<string>();

  porTerminals = new Array<string>();
  polTerminals = new Array<string>();
  podTerminals = new Array<string>();
  tsTerminals = new Array<string>();
  optPodTerminals = new Array<string>();
  nPodTerminals = new Array<string>();
  fPodTerminals = new Array<string>();
  placeOfDeliveryTerminals = new Array<string>();

  private eventSubscription;

  columnNameMap = new Map<string, string>();
  columnToolTipMap = new Map<string, string>();

  constructor(private authService: AuthService,
              private eventService: EventService,
              private bayPlanService: BayPlanService,
              private codeService: CodeService,
              private columnService: ColumnService,
              private preferenceService: PreferenceService,
              public filterService: ContainerFilterService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.filter = this.filterService.filter;
    this.acceptFilter();
    this.cdr.detectChanges();
    this.eventSubscription = this.eventService.getEmitter().subscribe(event => {

      if (event.id === EventIds.LOAD_BAY_PLAN) {

        this.bayPlan = this.bayPlanService.getBayPlan();
        this.hatches = this.bayPlanService.getHatchesDesc();
        if (!this.hatches) {
          this.hatches = new Array();
        }

      } else if (event.id === EventIds.CODES_RECONCILED) {
        if (this.bayPlan) {
          this.initCode();
          this.cdr.detectChanges();
        }
      } else if (event.id === EventIds.APPLY_FILTER) {
        this.filter = event.newValue;
        this.filter = this.filterService.filter;

        this.acceptFilter();
        this.cdr.detectChanges();
      }

    });
    this.bayPlan = this.bayPlanService.getBayPlan();
    if (this.bayPlan) {
      this.hatches = this.bayPlanService.getHatchesDesc();

      if (!this.hatches) {
        this.hatches = new Array();
      }
      this.initCode();
      this.cdr.detectChanges();
    }
    this.columnService.getColumns().then(columns => {
      this.columnNameMap = new Map<string, string>();
      this.columnToolTipMap = new Map<string, string>();
      columns.forEach(column => {
        this.columnNameMap.set(column.code, column.name);
        this.columnToolTipMap.set(column.code, column.fullName);
      });
    });

    this.authSubscription = this.authService.getEmitter().subscribe(event => {
      if (event.id === AuthEventId.CHANGE_TEAM || event.id === AuthEventId.SIGN_OUT) {
        this.clearFilters();
      }
    });
  }
  initCode() {
    const yes = 'Y';
    const no = 'N';
    const ownPortMap = this.codeService.getOwnPortMap();

    // const carrierMap = new Map<string, string>();
    let hasNullCarrier = false;
    const porMap = new Map<string, string>();
    const polMap = new Map<string, string>();
    const podMap = new Map<string, string>();
    const optPodMap = new Map<string, string>();
    const tsPortMap = new Map<string, string>();
    const nPodMap = new Map<string, string>();
    const fPodMap = new Map<string, string>();
    const placeOfDeliveryMap = new Map<string, string>();

    const isoSizeTypeMap = new Map<string, string>();

    const fullEmptyMap = new Map<string, string>();
    const cargoTypeMap = new Map<string, string>();
    const imdgMap = new Map<string, string>();

    const bundleYnMap = new Map<string, string>();
    const breakBulkYnMap = new Map<string, string>();
    const overDimensionYnMap = new Map<string, string>();
    const overSlotYnMap = new Map<string, string>();
    const temperatureRangeYnMap = new Map<string, string>();

    const vgmYnMap = new Map<string, string>();
    const vgmCodeMap = new Map<string, string>();
    const vgmInfoYnMap = new Map<string, string>();

    const groupCodeMap = new Map<string, string>();
    const groupCodeYnMap = new Map<string, string>();

    const descriptionOfGoodsMap = new Map<string, string>();
    const handlingInstructionMap = new Map<string, string>();
    const loadingRemarksMap = new Map<string, string>();
    const specialCargoInstructionMap = new Map<string, string>();
    const generalInformationMap = new Map<string, string>();
    const mutuallyDefinedRemarksMap = new Map<string, string>();


    const specialCargoInstructionYnMap = new Map<string, string>();
    const loadingRemarkYnMap = new Map<string, string>();
    const generalInformationYnMap = new Map<string, string>();
    const descriptionOfGoodsYnMap = new Map<string, string>();
    const mutuallyDefinedRemarkYnMap = new Map<string, string>();


    const cargoNatureYnMap = new Map<string, string>();
    const packageQuantityYnMap = new Map<string, string>();
    const packageTypeYnMap = new Map<string, string>();

    const blYnMap = new Map<string, string>();
    const bookingNoYnMap = new Map<string, string>();

    const porTerminalMap = new Map<string, string>();
    const polTerminalMap = new Map<string, string>();
    const podTerminalMap = new Map<string, string>();
    const optPodTerminalMap = new Map<string, string>();
    const tsTerminalMap = new Map<string, string>();
    const nPodTerminalMap = new Map<string, string>();
    const fPodTerminalMap = new Map<string, string>();
    const placeOfDeliveryTerminalMap = new Map<string, string>();


    for (const cntr of this.bayPlan.containers) {

      const weight = StringUtils.toNumber(cntr.getWeight());
      if (this.minWeight < 0 || this.minWeight > weight) {
        this.minWeight = weight;
      }
      if (this.maxWeight < weight) {
        this.maxWeight = weight;
      }

      if (cntr.carrier === '') {
        hasNullCarrier = true;
      }

      porMap.set(cntr.por, cntr.por);
      polMap.set(cntr.pol, cntr.pol);
      podMap.set(cntr.pod, cntr.pod);
      optPodMap.set(cntr.optPod, cntr.optPod);
      optPodMap.set(cntr.optPod1, cntr.optPod1);
      optPodMap.set(cntr.optPod2, cntr.optPod2);
      optPodMap.set(cntr.optPod3, cntr.optPod3);
      optPodMap.set(cntr.optPod4, cntr.optPod4);
      optPodMap.set(cntr.optPod5, cntr.optPod5);
      tsPortMap.set(cntr.tsPort, cntr.tsPort);
      nPodMap.set(cntr.nPod, cntr.nPod);
      fPodMap.set(cntr.fPod, cntr.fPod);
      placeOfDeliveryMap.set(cntr.placeOfDelivery, cntr.placeOfDelivery);

      isoSizeTypeMap.set(cntr.isoSizeType, cntr.isoSizeType);

      fullEmptyMap.set(cntr.fullEmpty, cntr.getFullEmpty());
      const cargoTypeCode = cntr.getCargoTypeCode();
      cargoTypeMap.set(cargoTypeCode, cargoTypeCode);
      if (cntr.dangerousGoods && cntr.dangerousGoods.length > 0) {
        cntr.dangerousGoods.forEach(dg => {
          imdgMap.set(dg.imdg, dg.imdg);
        });
      }

      if (cntr.hasBundledContainers()) {
        bundleYnMap.set(yes, yes);
      } else {
        bundleYnMap.set(no, no);
      }
      if (cntr.hasBreakBulk()) {
        breakBulkYnMap.set(yes, yes);
      } else {
        breakBulkYnMap.set(no, no);
      }
      if (cntr.hasOverDimension()) {
        overDimensionYnMap.set(yes, yes);
      } else {
        overDimensionYnMap.set(no, no);
      }
      if (cntr.hasOverSlot()) {
        overSlotYnMap.set(yes, yes);
      } else {
        overSlotYnMap.set(no, no);
      }
      if (cntr.hasTemperatureRange()) {
        temperatureRangeYnMap.set(yes, yes);
      } else {
        temperatureRangeYnMap.set(no, no);
      }
      if (cntr.vgmWeight) {
        vgmYnMap.set(yes, yes);
      } else {
        vgmYnMap.set(no, no);
      }
      vgmCodeMap.set(cntr.vgmCertificationCode, cntr.vgmCertificationCode);
      if (cntr.hasVgmInfo()) {
        vgmInfoYnMap.set(yes, yes);
      } else {
        vgmInfoYnMap.set(no, no);
      }

      groupCodeMap.set(cntr.groupCode, cntr.groupCode);
      if (cntr.groupCode) {
        groupCodeYnMap.set(yes, yes);
      } else {
        groupCodeYnMap.set(no, no);
      }

      this.inputRemarkMap(descriptionOfGoodsMap, cntr, ColumnIds.DESCRIPTION_OF_GOODS);
      this.inputRemarkMap(handlingInstructionMap, cntr, ColumnIds.HANDLING_INSTRUCTION);
      this.inputRemarkMap(loadingRemarksMap, cntr, ColumnIds.LOADING_REMARK);
      this.inputRemarkMap(specialCargoInstructionMap, cntr, ColumnIds.SPECIAL_CARGO_INSTRUCTION);
      this.inputRemarkMap(generalInformationMap, cntr, ColumnIds.GENERAL_INFORMATION);
      this.inputRemarkMap(mutuallyDefinedRemarksMap, cntr, ColumnIds.MUTUALLY_DEFINED_REMARK);

      if (cntr.specialCargoInstruction) {
        specialCargoInstructionYnMap.set(yes, yes);
      } else {
        specialCargoInstructionYnMap.set(no, no);
      }
      if (cntr.loadingRemark) {
        loadingRemarkYnMap.set(yes, yes);
      } else {
        loadingRemarkYnMap.set(no , no);
      }
      if (cntr.generalInformation) {
        generalInformationYnMap.set(yes, yes);
      } else {
        generalInformationYnMap.set(no, no);
      }
      if (cntr.descriptionOfGoods) {
        descriptionOfGoodsYnMap.set(yes, yes);
      } else {
        descriptionOfGoodsYnMap.set(no, no);
      }
      if (cntr.mutuallyDefinedRemark) {
        mutuallyDefinedRemarkYnMap.set(yes, yes);
      } else {
        mutuallyDefinedRemarkYnMap.set(no, no);
      }
      if (cntr.cargoNatures) {
        cargoNatureYnMap.set(yes, yes);
      } else {
        cargoNatureYnMap.set(no, no);
      }
      if (cntr.packageQuantity) {
        packageQuantityYnMap.set(yes, yes);
      } else {
        packageQuantityYnMap.set(no, no);
      }
      if (cntr.packageType) {
        packageTypeYnMap.set(yes, yes);
      } else {
        packageTypeYnMap.set(no, no);
      }

      if (cntr.blNo) {
        blYnMap.set(yes, yes);
      } else {
        blYnMap.set(no, no);
      }
      if (cntr.bookingNo) {
        bookingNoYnMap.set(yes, yes);
      } else {
        bookingNoYnMap.set(no, no);
      }


      porTerminalMap.set(cntr.porTerminalCode, cntr.porTerminalCode);
      polTerminalMap.set(cntr.polTerminalCode, cntr.polTerminalCode);
      podTerminalMap.set(cntr.podTerminalCode, cntr.podTerminalCode);
      optPodTerminalMap.set(cntr.optPodTerminalCode, cntr.optPodTerminalCode);
      optPodTerminalMap.set(cntr.optPod1TerminalCode, cntr.optPod1TerminalCode);
      optPodTerminalMap.set(cntr.optPod2TerminalCode, cntr.optPod2TerminalCode);
      optPodTerminalMap.set(cntr.optPod3TerminalCode, cntr.optPod3TerminalCode);
      optPodTerminalMap.set(cntr.optPod4TerminalCode, cntr.optPod4TerminalCode);
      optPodTerminalMap.set(cntr.optPod5TerminalCode, cntr.optPod5TerminalCode);
      tsTerminalMap.set(cntr.tsTerminalCode, cntr.tsTerminalCode);
      nPodTerminalMap.set(cntr.nPodTerminalCode, cntr.nPodTerminalCode);
      fPodTerminalMap.set(cntr.fPodTerminalCode, cntr.fPodTerminalCode);
      placeOfDeliveryTerminalMap.set(cntr.placeOfDeliveryTerminalCode, cntr.placeOfDeliveryTerminalCode);
    }

    this.carriers = Array.from(this.codeService.getOwnOperatorMap().values());
    this.carriers.sort((a, b) => sort(a.code, b.code));
    if (hasNullCarrier) {
      this.carriers.push(new Operator());
    }

    this.pors = new Array<Port>();
    if (porMap.size > 1) {
      for (const code of porMap.keys()) {
        if (code) {
          const port = ownPortMap.get(code);
          if (port) {
            this.pors.push(port);
          }
        } else {
          this.pors.push(new Port());
        }
      }
      this.pors.sort((a, b) => a.portOrder - b.portOrder);
    }

    this.pols = new Array<Port>();
    if (polMap.size > 1) {
      for (const code of polMap.keys()) {
        if (code) {
          const port = ownPortMap.get(code);
          if (port) {
            this.pols.push(port);
          }
        } else {
          this.pols.push(new Port());
        }
      }
      this.pols.sort((a, b) => a.portOrder - b.portOrder);
    }

    this.pods = new Array<Port>();
    if (podMap.size > 1) {
      for (const code of podMap.keys()) {
        if (code) {
          const port = ownPortMap.get(code);
          if (port) {
            this.pods.push(port);
          }
        } else {
          this.pods.push(new Port());
        }
      }
      this.pods.sort((a, b) => a.portOrder - b.portOrder);
    }

    this.optPods = new Array<Port>();
    if (optPodMap.size > 1) {
      for (const code of optPodMap.keys()) {
        if (code) {
          const port = ownPortMap.get(code);
          if (port) {
            this.optPods.push(port);
          }
        } else {
          this.optPods.push(new Port());
        }
      }
      this.optPods.sort((a, b) => a.portOrder - b.portOrder);
    }

    this.tsPorts = new Array<Port>();
    if (tsPortMap.size > 1) {
      for (const code of tsPortMap.keys()) {
        if (code) {
          const port = ownPortMap.get(code);
          if (port) {
            this.tsPorts.push(port);
          }
        } else {
          this.tsPorts.push(new Port());
        }
      }
      this.tsPorts.sort((a, b) => a.portOrder - b.portOrder);
    }

    this.nPods = new Array<Port>();
    if (nPodMap.size > 1) {
      for (const code of nPodMap.keys()) {
        if (code) {
          const port = ownPortMap.get(code);
          if (port) {
            this.nPods.push(port);
          }
        } else {
          this.nPods.push(new Port());
        }
      }
      this.nPods.sort((a, b) => a.portOrder - b.portOrder);
    }

    this.fPods = new Array<Port>();
    if (fPodMap.size > 1) {
      for (const code of fPodMap.keys()) {
        if (code) {
          const port = ownPortMap.get(code);
          if (port) {
            this.fPods.push(port);
          }
        } else {
          this.fPods.push(new Port());
        }
      }
      this.fPods.sort((a, b) => a.portOrder - b.portOrder);
    }

    this.placeOfDeliveries = new Array<Port>();
    if (placeOfDeliveryMap.size > 1) {
      for (const code of placeOfDeliveryMap.keys()) {
        if (code) {
          const port = ownPortMap.get(code);
          if (port) {
            this.placeOfDeliveries.push(port);
          }
        } else {
          this.placeOfDeliveries.push(new Port());
        }
      }
      this.placeOfDeliveries.sort((a, b) => a.portOrder - b.portOrder);
    }

    this.fullEmpty = new Array<Preference>();
    if (fullEmptyMap.size > 1) {
      fullEmptyMap.forEach((value, key) => {
        let pref = this.preferenceService.getPreference('017' + key);
        if (!pref) {
          pref = new Preference();
          pref.code = key;
        }
        this.fullEmpty.push(pref);
      });
    }

    this.cargoTypes = new Array<Preference>();
    if (cargoTypeMap.size > 1) {
      cargoTypeMap.forEach((value, key) => {
        let pref = this.preferenceService.getPreference(key);
        if (!pref) {
          pref = new Preference();
          pref.code = key;
        }
        this.cargoTypes.push(pref);
      });
    }

    if (imdgMap.size > 1) {
      this.imdgs = Array.from(imdgMap.values()).sort();
    } else {
      this.imdgs = new Array<string>();
    }

    this.isoSizeTypes = Array.from(isoSizeTypeMap.values()).sort();
    this.lengthCodes = this.codeService.getContainerLengths().sort((a, b) => sort(a.code, b.code));
    this.heightCodes = this.codeService.getContainerHeights().sort((a, b) => sort(a.code, b.code));
    this.typeCodes = this.codeService.getContainerTypes().sort((a, b) => sort(a.code, b.code));

    this.bundledYn = new Array<string>();
    if (bundleYnMap.size > 1) {
      this.bundledYn = Array.from(bundleYnMap.keys()).sort();
    }

    this.breakBulkYn = new Array<string>();
    if (breakBulkYnMap.size > 1) {
      this.breakBulkYn = Array.from(breakBulkYnMap.keys()).sort();
    }

    this.overDimensionsYn = new Array<string>();
    if (overDimensionYnMap.size > 1) {
      this.overDimensionsYn = Array.from(overDimensionYnMap.keys()).sort();
    }

    this.overSlotYn = new Array<string>();
    if (overSlotYnMap.size > 1) {
      this.overSlotYn = Array.from(overSlotYnMap.keys()).sort();
    }

    this.temperatureRangeYn = new Array<string>();
    if (temperatureRangeYnMap.size > 1) {
      this.temperatureRangeYn = Array.from(temperatureRangeYnMap.keys()).sort();
    }

    this.vgmYn = new Array<string>();
    if (vgmYnMap.size > 1) {
      this.vgmYn = Array.from(vgmYnMap.keys()).sort();
    }

    this.vgmCertificationCode = new Array<string>();
    if (vgmCodeMap.size > 1) {
      this.vgmCertificationCode = Array.from(vgmCodeMap.keys()).sort();
    }

    this.vgmInfoYm = new Array<string>();
    if (vgmInfoYnMap.size > 1) {
      this.vgmInfoYm = Array.from(vgmInfoYnMap.keys()).sort();
    }

    this.groupCodeYn = new Array<string>();
    if (groupCodeYnMap.size > 1) {
      this.groupCodeYn = Array.from(groupCodeYnMap.keys()).sort();
    }

    this.groupCodes = new Array<string>();
    if (groupCodeMap.size > 1) {
      this.groupCodes = Array.from(groupCodeMap.keys()).sort();
    }

    this.descriptionOfGoods = new Array<string>();
    if (descriptionOfGoodsMap.size > 1) {
      this.descriptionOfGoods = Array.from(descriptionOfGoodsMap.keys()).sort();
    }
    this.handlingInstruction = new Array<string>();
    if (handlingInstructionMap.size > 1) {
      this.handlingInstruction = Array.from(handlingInstructionMap.keys()).sort();
    }
    this.loadingRemarks = new Array<string>();
    if (loadingRemarksMap.size > 1) {
      this.loadingRemarks = Array.from(loadingRemarksMap.keys()).sort();
    }
    this.generalInformation = new Array<string>();
    if (generalInformationMap.size > 1) {
      this.generalInformation = Array.from(generalInformationMap.keys()).sort();
    }
    this.specialCargoInstruction = new Array<string>();
    if (specialCargoInstructionMap.size > 1) {
      this.specialCargoInstruction = Array.from(specialCargoInstructionMap.keys()).sort();
    }
    this.mutuallyDefinedRemarks = new Array<string>();
    if (mutuallyDefinedRemarksMap.size > 1) {
      this.mutuallyDefinedRemarks = Array.from(mutuallyDefinedRemarksMap.keys()).sort();
    }

    this.specialCargoInstructionYn = new Array<string>();
    if (specialCargoInstructionYnMap.size > 1) {
      this.specialCargoInstructionYn = Array.from(specialCargoInstructionYnMap.keys()).sort();
    }

    this.loadingRemarkYn = new Array<string>();
    if (loadingRemarkYnMap.size > 1) {
      this.loadingRemarkYn = Array.from(loadingRemarkYnMap.keys()).sort();
    }

    this.generalInformationYn = new Array<string>();
    if (generalInformationYnMap.size > 1) {
      this.generalInformationYn = Array.from(loadingRemarkYnMap.keys()).sort();
    }

    this.mutuallyDefinedRemarkYn = new Array<string>();
    if (mutuallyDefinedRemarkYnMap.size > 1) {
      this.mutuallyDefinedRemarkYn = Array.from(mutuallyDefinedRemarkYnMap.keys()).sort();
    }

    this.descriptionOfGoodsYn = new Array<string>();
    if (descriptionOfGoodsYnMap.size > 1) {
      this.descriptionOfGoodsYn = Array.from(descriptionOfGoodsYnMap.keys()).sort();
    }

    this.cargoNatureYn = new Array<string>();
    if (cargoNatureYnMap.size > 1) {
      this.cargoNatureYn = Array.from(cargoNatureYnMap.keys()).sort();
    }

    this.packageQuantityYn = new Array<string>();
    if (packageQuantityYnMap.size > 1) {
      this.packageQuantityYn = Array.from(packageQuantityYnMap.keys()).sort();
    }

    this.packageTypeYn = new Array<string>();
    if (packageTypeYnMap.size > 1) {
      this.packageTypeYn = Array.from(packageTypeYnMap.keys()).sort();
    }

    this.blYn = new Array<string>();
    if (blYnMap.size > 1) {
      this.blYn = Array.from(blYnMap.keys()).sort();
    }

    this.bookingNoYn = new Array<string>();
    if (bookingNoYnMap.size > 1) {
      this.bookingNoYn = Array.from(bookingNoYnMap.keys()).sort();
    }

    this.porTerminals = new Array<string>();
    if (porTerminalMap.size > 1) {
      this.porTerminals = Array.from(porTerminalMap.keys()).sort();
    }

    this.polTerminals = new Array<string>();
    if (polTerminalMap.size > 1) {
      this.polTerminals = Array.from(polTerminalMap.keys()).sort();
    }

    this.podTerminals = new Array<string>();
    if (podTerminalMap.size > 1) {
      this.podTerminals = Array.from(podTerminalMap.keys()).sort();
    }

    this.tsTerminals = new Array<string>();
    if (tsTerminalMap.size > 1) {
      this.tsTerminals = Array.from(tsTerminalMap.keys()).sort();
    }

    this.optPodTerminals = new Array<string>();
    if (optPodTerminalMap.size > 1) {
      this.optPodTerminals = Array.from(optPodTerminalMap.keys()).sort();
    }

    this.nPodTerminals = new Array<string>();
    if (nPodTerminalMap.size > 1) {
      this.nPodTerminals = Array.from(nPodTerminalMap.keys()).sort();
    }

    this.fPodTerminals = new Array<string>();
    if (fPodTerminalMap.size > 1) {
      this.fPodTerminals = Array.from(fPodTerminalMap.keys()).sort();
    }

    this.placeOfDeliveryTerminals = new Array<string>();
    if (placeOfDeliveryTerminalMap.size > 1) {
      this.placeOfDeliveryTerminals = Array.from(placeOfDeliveryTerminalMap.keys()).sort();
    }


    function sort(a, b) {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    }
  }

  inputRemarkMap(map: Map<string, string>, cntr: Container, code: string) {

    const qualifier = ColumnUtils.getRemarkQualifier(code);

    if (qualifier === '') {
      return;
    }
    const list = cntr.freeTexts.get(qualifier) as Array<Freetext>;

    if (list && list.length > 0) {
      map.set('', '');
      list.forEach(fx => {
        map.set(fx.remark, fx.remark);

      });
    }
  }
  acceptFilter() {
    this.filterService.acceptFilter();
  }
  applyFilter(item?: string) {

    if (this.filter.weight) {
      this.filter.minWeight = this.minWeight;
      this.filter.maxWeight = this.maxWeight;
    }

    this.filterService.applyFilter(item);
  }

  clearFilters() {
    this.filterService.clearFilters();
  }

  changeAlpha() {

    this.filter.alpha = (100 - this.filterService.alphaValue) / 100;

    this.applyFilter();
  }
  checkNull(value: string): string {
    if (!value) {
      return '';
    }
    return value.trim();
  }
  ngOnDestroy(): void {

    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }

    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }
  getColumnName(code: string): string {
    const name = this.columnNameMap.get(code);
    if (name) {
      return name;
    } else {
      return code;
    }
  }
  getToolTip(code: string): string {
    const name = this.columnToolTipMap.get(code);
    if (name) {
      return name;
    } else {
      return code;
    }
  }
  getClass4Select(items: string[]): string {

    if (items) {
      return items.length > 0 ? 'filtered' : '';
    } else {
      return '';
    }
  }
  getClass4Input(input: string): string {

    if (input && input.length > 0) {
      return 'filtered';
    } else {
      return '';
    }
  }
  getClass4TextArea(input: string): string {
    if (input && input.length > 0) {
      return 'filtered';
    } else {
      return 'container-no';
    }
  }

}
