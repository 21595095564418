<div>
  Are you absolutely sure?
</div>
<div>
  This action cannot be undone. This will permanently delete your account, and remove all collaborator associations.

  Please type <b>your email address</b> to confirm.
</div>
<form  [formGroup]="form" (ngSubmit)="delete()">
  <div>
    <mat-form-field class="stack">
      <mat-label for="teamName">Email address</mat-label>
      <input matInput [formControl]="teamNameControl"  class="form-control" (keydown)="onKeydown()" [ngClass]="{ 'is-invalid': submitted && teamNameControl.errors }" />
    </mat-form-field>
    <div>
      <small *ngIf="submitted && teamNameControl.hasError('required') && teamNameControl.touched"
             class="mat-text-warn">Required</small>
      <small *ngIf="submitted && teamNameControl.errors && teamNameControl.errors['unmatched'] && teamNameControl.touched" class="mat-text-warn">
        Incorrect email address
      </small>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" >
    <button mat-raised-button color="accent" type="submit" >I understand the consequences, delete my account</button>
    <button mat-raised-button class="primary" color="basic" (click)="close()">Close</button>
  </div>
</form>

