import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import {HandleError, HttpErrorHandler} from '../../../service/http-error-handler.service';
import {VesselSchedule} from './model/vessel-schedule';
import {properties} from '../../../../environments/environment';
import {VesselScheduleNode} from '../vessel-schedule.component';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  private readonly handleError: HandleError;

  constructor(private http: HttpClient,
              httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('ScheduleService');
  }

  getVesselSchedule(startTime: string, endTime: string, groupByVvd: boolean) {


    const groupByYn = groupByVvd ? 'Y' : 'N';

    return this.http.get<VesselScheduleNode[]>(properties.serverUrl + '/vessel_schedule?start_time=' + startTime + '&end_time=' + endTime + '&group_by_vvd=' + groupByYn);
  }
  updateServiceLaneCode(code: any) {
    return this.http.post<string>(properties.serverUrl + '/vessel_schedule/service_lane', code);
  }

  getVesselScheduleByCondition(lane: string, vessel: string, port: string, startDt: string, endDt: string) {
    let condition = '';
    if (lane !== undefined) {
      condition += '&lane=' + lane;
    }
    if (vessel !== undefined) {
      condition += '&vessel=' + vessel;
    }
    if (port !== undefined) {
      condition += '&port=' + port;
    }
    if (startDt !== undefined) {
      condition += '&startDt=' + startDt;
    }
    if (endDt !== undefined) {
      condition += '&endDt=' + endDt;
    }
    return this.http.get<VesselSchedule[]>(properties.serverUrl + '/vessel_schedules?searchby=date' + condition)
      .pipe(catchError(this.handleError('getHeroes', []))
      );
  }
  private addParameter(condition: string, first: boolean): string {
    if (first) {
      condition += '?';
      first = false;
    } else {
      condition += '&';
    }
    return condition;
  }

  getVesselScheduleById(id: string) {
    return this.http.get<VesselSchedule[]>(properties.serverUrl + '/vessel_schedules?searchby=id&id=' + id)
      .pipe(catchError(this.handleError('getHeroes', []))
      );

  }
  //
  // postVesselSchedule(schedules: EdiHeader) {
  //   return this.http.post(properties.serverUrl + '/vessel_schedule', schedules);
  // }

  deleteVesselSchedule(ediId: string, scheduleId: string) {
    return this.http.delete<VesselSchedule>(properties.serverUrl + '/vessel_schedules?edi_id=' + ediId + '&schedule_id=' + scheduleId);
  }

}
