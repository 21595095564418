import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SignUpInput} from './model/sign-up-input';
import {Observable} from 'rxjs';
import {environment, properties} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {UserProfile} from './model/user-profile';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {

  }

  signUp(body: SignUpInput): Observable<any> {

    return this.http.post<any>(environment.serverUrl + '/user/signup', body)
      .pipe(map(token => {

        return token;
      }));
  }
  findUser(usernameEmail: string): Observable<Array<UserProfile>> {

    return this.http.get<Array<UserProfile>>(environment.serverUrl + '/user?key=' + usernameEmail);
  }
  getProfile(): Observable<UserProfile> {
    return this.http.get<UserProfile>(environment.serverUrl + '/user/profile');
  }
  updateProfile(userProfile: UserProfile): Observable<UserProfile> {
    return this.http.post<UserProfile>(environment.serverUrl + '/user/profile', userProfile);
  }
  emailPasswordRest(email: string): Observable<any> {

    return this.http.get<any>(properties.serverUrl + '/user/email/password?email=' + email);
  }
  validateVerificationCode(code: string): Observable<any> {

    return this.http.get<any>(properties.serverUrl + '/user/validate/verification?code=' + code);
  }
  resetPassword(verificationCode: string, password: string): Observable<any> {

    const input = {verificationCode, password};
    return this.http.post<any>(properties.serverUrl + '/user/reset/password', input);
  }
  emailSignUp(email: string): Observable<any> {
    return this.http.get<any>(properties.serverUrl + '/user/email/signup?email=' + email);
  }
  verifyEmail(verificationCode: string): Observable<any> {

    return this.http.get<any>(properties.serverUrl + '/user/verify?code=' + verificationCode);
  }
  changePassword(oldPassword, newPassword): Observable<any>  {
    const input = {oldPassword, newPassword};
    return this.http.post<any>(properties.serverUrl + '/user/change/password', input);
  }
  deleteAccount(): Observable<any>  {
    return this.http.delete<any>(properties.serverUrl + '/user/profile');
  }
}
