<!--<mat-toolbar color="primary">-->
<!--  <mat-toolbar-row></mat-toolbar-row>-->
<!--  <mat-toolbar-row></mat-toolbar-row>-->
<!--</mat-toolbar>-->

<div fxLayout="row wrap" fxLayoutAlign="center start">
  <div fxFlex.gt-sm="55" fxFlex="100">
    <mat-card class="mat-card-top pa-0">
      <div class="pa-1" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          User Account
        </div>
        <button mat-raised-button class="primary" color="primary" (click)="save()">Save</button>
        <!--        <button mat-raised-button color="primary">Cancel</button>-->
      </div>
      <mat-tab-group (selectedTabChange)="selectTab($event)">
        <mat-tab>
          <ng-template matTabLabel>Profile</ng-template>
          <mat-card-content class="pa-1">
            <form fxLayout="column" [formGroup]="profileForm">

              <mat-form-field class="mb-1">
                <input matInput placeholder="Email address" [value]="profileForm.controls['email'].value" [disabled] = 'true'>
              </mat-form-field>
              <mat-form-field class="mb-1">
                <input matInput placeholder="Full name" [formControl]="profileForm.controls['fullName']" maxlength="300">
              </mat-form-field>
              <mat-form-field class="mb-1">
                <input matInput placeholder="Company" [formControl]="profileForm.controls['companyName']" maxlength="300">
              </mat-form-field>

            </form>
          </mat-card-content>
        </mat-tab>
        <mat-tab>
          <ng-template matTabLabel>Security</ng-template>
          <mat-card-content class="pa-1">
            <form fxLayout="column" [formGroup]="passwordForm">
              <mat-form-field class="mb-1">
                <input matInput placeholder="Old password" type="password" [formControl]="passwordForm.controls['oldPassword']">
              </mat-form-field>
              <small *ngIf="oldPassword.hasError('required') && oldPassword.touched" class="mat-text-warn">{{PASSWORD_REQUIRED_FOR_SIGN_IN}}</small>
              <mat-form-field class="mb-1">
                <input matInput placeholder="New password" type="password" [formControl]="passwordForm.controls['newPassword']" minlength="4" maxlength="20">
              </mat-form-field>
              <div *ngIf="newPassword.invalid">
                <small *ngIf="newPassword.hasError('required') && newPassword.touched"   class="mat-text-warn">{{PASSWORD_REQUIRED_FOR_SIGN_IN}}</small>
                <small *ngIf="newPassword.hasError('minlength')  && (newPassword.dirty || newPassword.touched)"   class="mat-text-warn">{{PASSWORD_MIN_LENGTH}}</small>
              </div>
              <mat-form-field class="mb-1">
                <input matInput placeholder="Confirm new password" type="password" [formControl]="confirmedPassword">
              </mat-form-field>
              <div *ngIf="confirmedPassword.invalid && (confirmedPassword.dirty || confirmedPassword.touched)">
                <small *ngIf="confirmedPassword.hasError('required')" class="mat-text-warn">{{PASSWORD2_REQUIRED_FOR_SIGN_IN}}</small>
                <small *ngIf="confirmedPassword.errors?.equalTo" class="mat-text-warn">{{PASSWORDS_DO_NOT_MATCH}}</small>
              </div>
            </form>
          </mat-card-content>
          <button mat-raised-button class="delete-account" color="accent" (click)="delete()">Delete my account</button>
        </mat-tab>
<!--        <mat-tab>-->
<!--          <ng-template matTabLabel>Preferances</ng-template>-->
<!--          <mat-card-content class="pa-1">-->
<!--            <mat-list>-->
<!--              <mat-list-item *ngFor="let system of systems">-->
<!--                <span fxFlex>{{system.name}}</span>-->
<!--                <mat-slide-toggle [(ngModel)]="system.on"></mat-slide-toggle>-->
<!--              </mat-list-item>-->
<!--            </mat-list>-->
<!--          </mat-card-content>-->
<!--        </mat-tab>-->
      </mat-tab-group>
    </mat-card>
  </div>
</div>
