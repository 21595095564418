export const SessionIds = {
  USER_ID: 'USER_ID',
  SELECTION_MODE: 'SELECTION_MODE',
  COLOR_OPTION: 'COLOR_OPTION',
  EDI_ID: 'EDI_ID',
  VESSEL_ID: 'VESSEL_ID',
  VESSEL_NAME: 'VESSEL_NAME',
  VOYAGE_NO: 'VOYAGE_NO',
  VESSEL: 'VESSEL',
  SERVICE_LANE_CODE: 'SERVICE_LANE_CODE',
  EDI_TYPE: 'EDI_TYPE',
  EDI_VERSION: 'EDI_VERSION',
  SCHEMA_BAPLIE_22: 'SCHEMA_BAPLIE_22',
  BAY_PLAN_LAYOUT_MODE: 'BAY_PLAN_LAYOUT_MODE',
  BAY_PLAN_ZOOM_LEVEL: 'BAY_PLAN_ZOOM_LEVEL',
  BAY_PLAN_CONTENT_DISPLAY_MODE: 'BAY_PLAN_CONTENT_DISPLAY_MODE',
  BOTTOM_PANEL_HEIGHT: 'BOTTOM_PANEL_HEIGHT',
  IMDG_SEGREGATION: 'IMDG_SEGREGATION',
  STOWAGE_VALIDATION: 'STOWAGE_VALIDATION',
  FILTER: 'FILTER',
  IS_BAY_PLAN_DIRTY: 'IS_BAY_PLAN_DIRTY',
  EDI_FILE_NAME: 'EDI_FILE_NAME',
  EDI_FILE_UPDATER: 'EDI_FILE_UPDATER',
  EDI_FILE_UPDATE_TIME: 'EDI_FILE_UPDATE_TIME',
  EDI_FILE_CREATOR: 'EDI_FILE_CREATOR',
  EDI_FILE_CREATE_TIME: 'EDI_FILE_CREATE_TIME',
};
export const SessionValues = {
  SELECTION_MODE_INFO: 'SELECTION_MODE_INFO',
  SELECTION_MODE_SHIFT: 'SELECTION_MODE_SHIFT',
  SELECTION_MODE_COD: 'SELECTION_MODE_COD',
  SELECTION_MODE_MOVINS: 'SELECTION_MODE_MOVINS',

  COLOR_POR: 'COLOR_POR',
  COLOR_POL: 'COLOR_POL',
  COLOR_POD: 'COLOR_POD',
  COLOR_TS_PORT: 'COLOR_TS_PORT',
  COLOR_NPOD: 'COLOR_NPOD',
  COLOR_FPOD: 'COLOR_FPOD',
  COLOR_PLACE_OF_DELIVERY: 'COLOR_PLACE_OF_DELIVERY',
  COLOR_CARGO: 'COLOR_CARGO',
  COLOR_LENGTH: 'COLOR_LENGTH',
  COLOR_HEIGHT: 'COLOR_HEIGHT',
  COLOR_TYPE: 'COLOR_TYPE',
  COLOR_OPERATOR: 'COLOR_OPERATOR',
  COLOR_FULL_EMPTY: 'COLOR_FULL_EMPTY',
  COLOR_WEIGHT_GROUP: 'COLOR_WEIGHT_GROUP'

};
