export class ContainerUtils {
  static decodeCargoType(code): string {

    switch (code) {

      case '016RD': {
        return 'Reefer Dangerous';
      }
      case '016RF': {
        return 'Reefer';
      }
      case '016DM': {
        return 'Empty Dangerous';
      }
      case '016DG': {
        return 'Dangerous';
      }
      case '016MT': {
        return 'Empty';
      }
      default: {
        return 'Other';
      }
    }
  }
}
