<div #legend fxLayout="column" fxLayoutAlign="start stretch" >
  <div fxLayout="row" fxLayoutAlign="start start">
    <div fxLayout="column" fxLayoutAlign="start start">
<!--      <p class="code-title"><b>Port</b></p>-->
      <mat-form-field style="margin-left: 2px; margin-top: 4px; width: 87px">
        <mat-select placeholder="Port Type" [(value)]="selectedPorts" (selectionChange)="initPort()" multiple>
          <mat-option class="code" *ngFor="let port of portTypes" [value]="port.code">
            <b>{{ port.name }}</b>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngFor="let port of ports;" fxLayout="row" fxLayoutAlign="start start">
        <div class="color-panel" fxLayout="column" fxLayoutAlign="start start">
          <span class="color-label" style="background-color:{{port.foreColor}}"
                (colorPickerSelect)="changePortColor(port)"
                [cpWidth]="170"
                [cpPosition]="'bottom-right'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="port.foreColor">&nbsp;</span>
          <span class="color-label" style="background-color:{{port.backColor}}"
                (colorPickerSelect)="changePortColor(port)"
                [cpWidth]="170"
                [cpPosition]="'bottom-right'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="port.backColor">&nbsp;</span>
        </div>
        <span class="code-label" [matMenuTriggerFor]="codeMenu" style="color:{{port.foreColor}};background-color:{{port.backColor}}">
        {{getPortLabel(port.code)}}
        </span>
        <mat-menu #codeMenu="matMenu">
          <button mat-menu-item >Add filter</button>
          <button mat-menu-item (click)="foreColorPicker=true">Fore color</button>
        </mat-menu>
      </div>
      <p class="code-title"><b>Operator</b></p>
      <div *ngFor="let operator of operators" fxLayout="row" fxLayoutAlign="start start">
        <div class="color-panel" fxLayout="column" fxLayoutAlign="start start">
          <span class="color-label" style="background-color:{{operator.foreColor}}"
                (colorPickerSelect)="changeOperatorColor(operator)"
                [cpWidth]="170"
                [cpPosition]="'bottom'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="operator.foreColor">&nbsp;</span>
          <span class="color-label" style="background-color:{{operator.backColor}}"
                (colorPickerSelect)="changeOperatorColor(operator)"
                [cpWidth]="170"
                [cpPosition]="'bottom'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="operator.backColor">&nbsp;</span>
        </div>
        <span class="code-label" style="color: {{operator.foreColor}};background-color:{{operator.backColor}}">
          {{operator.code}}
        </span>
      </div>
    </div>
    <div style="margin-left: 15px" fxLayout="column" fxLayoutAlign="start start">
      <p class="code-title" style="margin-top: 10px"><b>Full / Empty</b></p>
      <div *ngFor="let fm of fullEmpty" fxLayout="row" fxLayoutAlign="start start">
        <div class="color-panel" fxLayout="column" fxLayoutAlign="start start">
          <span class="color-label" style="background-color: {{fm.value}}"
                (colorPickerSelect)="changePreference(fm)"
                [cpWidth]="170"
                [cpPosition]="'bottom-right'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="fm.value">&nbsp;</span>
          <span class="color-label" style="background-color: {{fm.value2}}"
                (colorPickerSelect)="changePreference(fm)"
                [cpWidth]="170"
                [cpPosition]="'bottom-right'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="fm.value2">&nbsp;</span>
        </div>
        <span class="code-label" style="color:{{fm.value}};background-color:{{fm.value2}}">
          {{fm.name}}
        </span>
      </div>
      <p class="code-title"><b>Cargo</b></p>
      <div *ngFor="let fm of cargoTypes" fxLayout="row" fxLayoutAlign="start start">
        <div class="color-panel" fxLayout="column" fxLayoutAlign="start start">
          <span class="color-label" style="background-color: {{fm.value}}"
                (colorPickerSelect)="changePreference(fm)"
                [cpWidth]="170"
                [cpPosition]="'bottom-right'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="fm.value">&nbsp;</span>
          <span class="color-label" style="background-color: {{fm.value2}}"
                (colorPickerSelect)="changePreference(fm)"
                [cpWidth]="170"
                [cpPosition]="'bottom-right'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="fm.value2">&nbsp;</span>
        </div>
        <span class="code-label" style="color:{{fm.value}};background-color:{{fm.value2}}">
          {{fm.name}}
        </span>
      </div>
      <p class="code-title" style="margin-top: 10px"><b>Length</b></p>
      <div *ngFor="let lengthCode of lengthCodes" fxLayout="row" fxLayoutAlign="start start">
        <div class="color-panel" fxLayout="column" fxLayoutAlign="start start">
          <span class="color-label" style="background-color:{{lengthCode.foreColor}}"
                (colorPickerSelect)="changeLengthColor(lengthCode)"
                [cpWidth]="170"
                [cpPosition]="'bottom-right'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="lengthCode.foreColor">&nbsp;</span>
          <span class="color-label" style="background-color:{{lengthCode.backColor}}"
                (colorPickerSelect)="changeLengthColor(lengthCode)"
                [cpWidth]="170"
                [cpPosition]="'bottom-right'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="lengthCode.backColor">&nbsp;</span>
        </div>
        <span class="code-label" style="color:{{lengthCode.foreColor}};background-color:{{lengthCode.backColor}}">
          {{lengthCode.name}}
        </span>
      </div>
      <p class="code-title" style="margin-top: 10px"><b>Height</b></p>
      <div *ngFor="let heightCode of heightCodes" fxLayout="row" fxLayoutAlign="start start">
        <div class="color-panel" fxLayout="column" fxLayoutAlign="start start">
          <span class="color-label" style="background-color:{{heightCode.foreColor}}"
                (colorPickerSelect)="changeHeightColor(heightCode)"
                [cpWidth]="170"
                [cpPosition]="'bottom-right'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="heightCode.foreColor">&nbsp;</span>
          <span class="color-label" style="background-color:{{heightCode.backColor}}"
                (colorPickerSelect)="changeHeightColor(heightCode)"
                [cpWidth]="170"
                [cpPosition]="'bottom-right'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="heightCode.backColor">&nbsp;</span>
        </div>
        <span class="code-label" style="color:{{heightCode.foreColor}};background-color:{{heightCode.backColor}}">
          {{heightCode.code.substring(3) + ' : ' + heightCode.name}}
        </span>
      </div>
      <p class="code-title" style="margin-top: 10px"><b>Type</b></p>
      <div style="min-width: 105px" *ngFor="let typeCode of typeCodes" fxLayout="row" fxLayoutAlign="start start">
        <div class="color-panel" fxLayout="column" fxLayoutAlign="start start">
        <span class="color-label" style="background-color:{{typeCode.foreColor}}"
              (colorPickerSelect)="changeTypeColor(typeCode)"
              [cpWidth]="170"
              [cpPosition]="'bottom'"
              [cpPositionOffset]="'50%'"
              [cpPositionRelativeToArrow]="true"
              [cpOKButton]="true"
              [cpSaveClickOutside]="false"
              [(colorPicker)]="typeCode.foreColor">&nbsp;</span>
          <span class="color-label" style="background-color:{{typeCode.backColor}}"
                (colorPickerSelect)="changeTypeColor(typeCode)"
                [cpWidth]="170"
                [cpPosition]="'bottom'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="typeCode.backColor">&nbsp;</span>
        </div>
        <span class="code-label" style="color:{{typeCode.foreColor}};background-color:{{typeCode.backColor}}">
        {{typeCode.name}}
        </span>
      </div>
      <p class="code-title" style="margin-top: 10px"><b>WeightGroup</b></p>
      <div style="min-width: 105px" *ngFor="let weightGroup of weightGroups" fxLayout="row" fxLayoutAlign="start start">
        <div class="color-panel" fxLayout="column" fxLayoutAlign="start start">
        <span class="color-label" style="background-color: {{weightGroup.foreColor}}"
              (colorPickerSelect)="changeWeightGroupColor(weightGroup)"
              [cpWidth]="170"
              [cpPosition]="'bottom'"
              [cpPositionOffset]="'50%'"
              [cpPositionRelativeToArrow]="true"
              [cpOKButton]="true"
              [cpSaveClickOutside]="false"
              [(colorPicker)]="weightGroup.foreColor">&nbsp;</span>
          <span class="color-label" style="background-color:{{weightGroup.backColor}}"
                (colorPickerSelect)="changeWeightGroupColor(weightGroup)"
                [cpWidth]="170"
                [cpPosition]="'bottom'"
                [cpPositionOffset]="'50%'"
                [cpPositionRelativeToArrow]="true"
                [cpOKButton]="true"
                [cpSaveClickOutside]="false"
                [(colorPicker)]="weightGroup.backColor">&nbsp;</span>
        </div>
        <span class="weight-group-label" style="color:{{weightGroup.foreColor}};background-color:{{weightGroup.backColor}}"
              matTooltip ="{{formatWeight(weightGroup)}}"
              matTooltipClass="tooltip"
        >
        {{weightGroup.name}}
        </span>
      </div>
    </div>
  </div>
  <div><br></div>
  <div fxLayout="row" fxLayoutAlign="start start">
<!--    <div fxLayout="column" fxLayoutAlign="start start">-->

<!--    </div>-->

  </div>
</div>
