import * as Screenfull from 'screenfull';
import {AfterViewInit, Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../user/service/auth.service';
import {MyTeam} from '../../user/service/model/my-team';
import {UserGroupService} from '../../user/service/user-group.service';
import {HttpClient} from '@angular/common/http';
import {BaplieReaderService} from '../../my-plans/service/baplie-reader.service';
import {EventIds} from '../event/event-ids';
import {MenuUrls} from '../menu/menu-urls';
import {EventService} from '../event/event.service';
import {EdiHeader} from '../../my-plans/service/model/edi-header';
import {SessionIds} from '../service/session-ids';
import {Router} from '@angular/router';
import {OkDialogComponent} from '../../dialogs/ok-dialog/ok-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']

})
export class HeaderComponent implements OnInit, OnChanges, AfterViewInit {
  @Output()
  toggleSidenav = new EventEmitter<void>();
  @Output()
  toggleNotificationSidenav = new EventEmitter<void>();
  rightPanelVisible = false;

  private langChangeSubscription!: Subscription;

  BAPLIE_EDIT = this.translate.instant('BAPLIE_EDIT');
  SIGN_IN = this.translate.instant('SIGN_IN');
  SIGN_UP = this.translate.instant('SIGN_UP');
  SETTINGS = this.translate.instant('SETTINGS');
  PROFILE = this.translate.instant('PROFILE');
  ACCOUNT = this.translate.instant('ACCOUNT');
  SIGN_OUT = this.translate.instant('SIGN_OUT');

  serviceLaneCode = '';
  ediType = '';
  ediVersion = '';
  vessel = '';
  voyage = '';

  selectedValue: string;
  selectedCar: string;

  myTeams: MyTeam[];
  showBayToolbar = false;

  constructor(public translate: TranslateService,
              private userGroupService: UserGroupService,
              public authService: AuthService,
              private http: HttpClient,
              private router: Router,
              private dialog: MatDialog,
              private eventService: EventService) {

    this.eventService.getEmitter().subscribe(event => {

      if (event.id === EventIds.ROUTE_CHANGE) {
        if (event.newValue === MenuUrls.EDIT_BAY) {
          this.showBayToolbar = true;
          this.rightPanelVisible = true;
        } else if (event.newValue === MenuUrls.EDIT_LIST) {
          this.rightPanelVisible = true;
        } else {
          this.showBayToolbar = false;
          this.rightPanelVisible = false;
        }
      } else if (event.id === EventIds.LOAD_BAY_PLAN) {
        this.displaySelectedEdiInfo(event.newValue);

      } else if (event.id === EventIds.TEAMS_UPDATED) {
        this.searchMyTeams();
      }

    });
  }

  ngAfterViewInit(): void {
    // console.info('SessionIds.EDI_ID: ' + sessionStorage.getItem(SessionIds.EDI_ID));
    if (sessionStorage.getItem(SessionIds.EDI_ID) && sessionStorage.getItem(SessionIds.USER_ID)) {
      this.serviceLaneCode = sessionStorage.getItem(SessionIds.SERVICE_LANE_CODE);
      this.ediType = this.getTypeInitial(sessionStorage.getItem(SessionIds.EDI_TYPE));
      this.ediVersion = sessionStorage.getItem(SessionIds.EDI_VERSION);
      this.vessel = sessionStorage.getItem(SessionIds.VESSEL_NAME);
      this.voyage = sessionStorage.getItem(SessionIds.VOYAGE_NO);
    } else {
      this.clearSelectedEdiInfo();
    }

    // console.info('Header: ' + this.ediType + ',' + this.ediVersion + ',' + this.vessel );
  }

  ngOnInit() {

    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.BAPLIE_EDIT = this.translate.instant('BAPLIE_EDIT');
      this.SIGN_IN = this.translate.instant('SIGN_IN');
      this.SIGN_UP = this.translate.instant('SIGN_UP');
      this.SETTINGS = this.translate.instant('SETTINGS');
      this.PROFILE = this.translate.instant('PROFILE');
      this.ACCOUNT = this.translate.instant('ACCOUNT');
      this.SIGN_OUT = this.translate.instant('SIGN_OUT');

    });
    if (this.authService.isLoggedIn() && this.authService.isMemberOrAdmin()) {
      this.searchMyTeams();
    }
  }
  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  logout() {
    this.authService.logout();
    this.clearSelectedEdiInfo();
    this.router.navigateByUrl('/');
  }
  displaySelectedEdiInfo(header: EdiHeader) {
    try {

      if (header) {
        if (header.messageType && header.messageType.length > 0) {
          this.ediType = this.getTypeInitial(header.messageType);
        }
        this.ediVersion = header.getDisplayVersion();
        this.serviceLaneCode = header.serviceLaneCode;
        this.vessel = header.vesselName;
        this.voyage = header.voyageNo;
      }

    } catch (error) {
      console.error(error);
    }
  }
  clearSelectedEdiInfo() {
    this.serviceLaneCode = '';
    this.ediType = '';
    this.ediVersion = '';
    this.vessel = '';
    this.voyage = '';
  }

  fullScreenToggle(): void {
    if (Screenfull.isEnabled) {
      Screenfull.toggle();
    }
  }
  searchMyTeams(): void {

    this.userGroupService.getMyTeams().subscribe(data => {
      this.myTeams = data;
      this.selectedValue = this.authService.getTokenClaim().active_group_id;

    }, error => {
      this.showErrorDialog(error);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  switchTeam($event) {
    // console.info($event);
    this.authService.switchTeam(this.selectedValue).subscribe(data => {
        // console.info(data);

      this.clearSelectedEdiInfo();
      },
      error => {
        this.showErrorDialog(error);
      });
  }

  onOpenFile(fileList: FileList) {
    const file = fileList[0];
    const fileReader: FileReader = new FileReader();
    const self = this;
    fileReader.onloadend = function _(x) {
      const ediReaderService: BaplieReaderService = new BaplieReaderService(fileReader.result.toString(), self.http);
      ediReaderService.parseEDI().then((data) => {
        // window.location.reload();
      });
    };
    fileReader.readAsText(file);
  }

  openPreferences() {
    this.router.navigateByUrl('/preferences').then(r => {
    });
  }
  getTypeInitial(messageType: string): string {
    return messageType.substring(0, 1);
  }
  openProfile() {
    this.router.navigateByUrl('/profile').then(r => {
    });
  }
  showErrorDialog(error: any) {
    console.error(error);
    let errorMessage = error.message;
    if (typeof error.error === 'string') {
      errorMessage = this.translate.instant(error.error);
    }
    const dialogRef = this.dialog.open(OkDialogComponent, {
      maxWidth: '800px',
      data: {header: error.statusText, message: errorMessage}
    });
  }

  goHome() {

    if (this.authService.isMemberOrAdmin()) {
      return MenuUrls.FILE;
    } else {
      return '/';
    }

  }
}
