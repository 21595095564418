import {
  ChangeDetectorRef,
  Component, ElementRef, EventEmitter, HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit, Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';
import { filter, map } from 'rxjs/operators';

import { BreakpointObserver } from '@angular/cdk/layout';
import {EventIds} from '../event/event-ids';
import {EventService} from '../event/event.service';
import {SessionIds} from '../service/session-ids';
import {MenuUrls} from '../menu/menu-urls';
import {PreferenceService} from '../../service/preference.service';
import {AuthEventId, AuthService} from '../../user/service/auth.service';

const SMALL_WIDTH_BREAKPOINT = 500;


@Component({
  selector: 'app-layout',
  template: `
    <app-layout-inner
      [isScreenSmall]="isScreenSmall | async"
    ></app-layout-inner>
  `,
  encapsulation: ViewEncapsulation.None
})
export class AdminLayoutComponent {
  isScreenSmall: Observable<boolean>;

  constructor(public breakpoints: BreakpointObserver) {
    this.isScreenSmall = breakpoints
      .observe(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`)
      .pipe(map(breakpoint => breakpoint.matches));
  }
}

@Component({
  selector: 'app-layout-inner',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['admin-layout.component.css']
})
export class LayoutComponent implements OnInit, OnDestroy {
  private layoutRouter: Subscription;
  @Input() isScreenSmall: boolean;

  url: string;
  sidePanelOpened;
  options = {
    collapsed: false,
    boxed: false,
    dark: false,
    dir: 'ltr'
  };

  @ViewChild('sidemenu', { static: true }) sidemenu;
  @ViewChild(PerfectScrollbarDirective, { static: true })
  directiveScroll: PerfectScrollbarDirective;

  // @Output();
  bottomHeight = 300;
  rightPanelOpened = 'false';

  headerVisible = true;

  bottomPanelVisible = new BottomPanelVisible();

  public config: PerfectScrollbarConfigInterface = {};
  private eventSubscription;
  private authSubscription;

  constructor(private router: Router,
              private eventService: EventService,
              private preferenceService: PreferenceService,
              private authService: AuthService,
              private cdr: ChangeDetectorRef,
              private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.configureUrl(this.router.url);

    this.eventSubscription = this.eventService.getEmitter().subscribe(event => {
      if (event.id === EventIds.ROUTE_CHANGE) {
        this.configureUrl(event.newValue);
      }
    });

    this.authSubscription = this.authService.getEmitter().subscribe(event => {
      if (event.id === AuthEventId.SIGN_OUT) {
        this.bottomPanelVisible = new BottomPanelVisible();
      }
    });
    if (this.authService.isLoggedIn()) {
      this.preferenceService.retrievePreferences();
    }

    this.url = this.router.url;
    this.layoutRouter = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // document.querySelector(
        //   '.app-inner > .mat-drawer-content > div'
        // ).scrollTop = 0;
        // console.info(event.url);
        this.url = event.url;
        this.runOnRouteChange();
        this.configureRoute(this.url);
        this.eventService.emit(EventIds.ROUTE_CHANGE, this.url);
      });
    this.configureRoute(this.url);
    this.eventService.emit(EventIds.ROUTE_CHANGE, this.url);

    const height = sessionStorage.getItem(SessionIds.BOTTOM_PANEL_HEIGHT);

    if (height) {
      this.bottomHeight = Number(height);
    }

  }
  configureUrl(url: string) {
    if (url === MenuUrls.FILE) {
      this.bottomPanelVisible.markersVisible = false;
      this.bottomPanelVisible.headerVisible = true;
      this.bottomPanelVisible.containerSummaryVisible = false;
      this.bottomPanelVisible.consoleVisible = true;
      this.bottomPanelVisible.ediTextVisible = false;
      this.bottomPanelVisible.vesselEditVisible = false;
      this.bottomPanelVisible.sharedEdiVisible = true;
    } else if (url === MenuUrls.EDIT_BAY || url === MenuUrls.EDIT_LIST) {
      this.bottomPanelVisible.markersVisible = true;
      this.bottomPanelVisible.headerVisible = true;
      this.bottomPanelVisible.containerSummaryVisible = true;
      this.bottomPanelVisible.consoleVisible = true;
      this.bottomPanelVisible.ediTextVisible = true;
      this.bottomPanelVisible.vesselEditVisible = true;
      this.bottomPanelVisible.sharedEdiVisible = false;
    } else {
      this.bottomPanelVisible.markersVisible = false;
      this.bottomPanelVisible.headerVisible = false;
      this.bottomPanelVisible.containerSummaryVisible = false;
      this.bottomPanelVisible.consoleVisible = false;
      this.bottomPanelVisible.ediTextVisible = false;
      this.bottomPanelVisible.vesselEditVisible = false;
      this.bottomPanelVisible.sharedEdiVisible = false;
    }
    this.cdr.detectChanges();
  }
  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
  runOnRouteChange(): void {
    if (this.isOver()) {
      this.sidemenu.close();
    }

    this.updatePS();
  }

  receiveOptions($event): void {
    this.options = $event;
  }

  isOver(): boolean {
    if (
      this.url === '/apps/messages'
      || this.url === '/apps/calendar'
      || this.url === '/apps/media'
      || this.url === '/maps/leaflet'
      || this.url === '/taskboard'
    ) {
      return false;
    } else {
      return this.isScreenSmall;
    }
  }
  isLeftSideOpened(): boolean {

    return !this.isOver() && this.authService.isAuthenticated();
  }


  menuMouseOver(): void {
    if (this.isScreenSmall && this.options.collapsed) {
      this.sidemenu.mode = 'over';
    }
  }

  menuMouseOut(): void {
    if (this.isScreenSmall && this.options.collapsed) {
      this.sidemenu.mode = 'side';
    }
  }

  updatePS(): void {
    if (!this.isScreenSmall) {
      setTimeout(() => {
        this.directiveScroll.update();
      }, 350);
    }
  }


  onRightPanelOpenedChange(opened: boolean) {
    this.eventService.emit(EventIds.CHANGE_RIGHT_PANEL, opened);
  }

  click() {
    const bottomArea = this.elementRef.nativeElement.querySelector('#bottomArea');

    if (bottomArea) {
      this.bottomHeight = this.elementRef.nativeElement.querySelector('#bottomArea').offsetHeight;
      sessionStorage.setItem(SessionIds.BOTTOM_PANEL_HEIGHT, this.bottomHeight.toString());
    }
  }
  @HostListener('window:resize')
  public resize() {

    if (this.bottomPanelVisible.visible()) {
      this.bottomHeight = this.elementRef.nativeElement.querySelector('#bottomArea').offsetHeight - 2;
    }

  }
  configureRoute(url: string) {

    if (url === MenuUrls.EDIT_BAY
      || url === MenuUrls.EDIT_LIST
    ) {
      this.rightPanelOpened = 'true';
      this.options.collapsed = true;
    } else {
      this.rightPanelOpened = 'false';
      this.options.collapsed = false;

    }
    this.headerVisible = true;

    if (url === '/' || url === MenuUrls.EDIT_SUMMARY) {
      this.headerVisible = false;
    }
  }
  isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

}
export class BottomPanelVisible {
  markersVisible = false;
  headerVisible = false;
  containerSummaryVisible = false;
  consoleVisible = false;
  ediTextVisible = false;
  vesselEditVisible = false;
  sharedEdiVisible = false;

  visible() {
    return this.markersVisible
      || this.headerVisible
      || this.containerSummaryVisible
      || this.consoleVisible
      || this.ediTextVisible
      || this.sharedEdiVisible
      || this.vesselEditVisible;
  }
}

