import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {SyntaxMessageNode} from './model/SyntaxMessageNode';
import {EdiDocument} from '../../my-plans/service/model/edi-document';
import {EventService} from '../../core/event/event.service';
import {EventIds} from '../../core/event/event-ids';
import {BayPlanService} from '../../service/bay-plan.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {BottomTabText} from '../bottom-panel/bottom-panel.component';
import {SyntaxMessageError} from '../../my-plans/service/model/syntax-message-error';
import {BayPlanEdiParserService} from '../../service/bay-plan-edi-parser.service';
import {IMDGSegregationService} from '../../imdg/service/imdg-segregation.service';
import {StowageValidationService} from '../../my-plans/service/stowage-validation.service';
import {AbstractEditableTableComponent} from '../../be-com/etable/abstract-editable-table.component';
import {EditableModel} from '../../be-com/etable/editable-model';
import {EditableColumn} from '../../be-com/etable/editable-column';
import {MatTableDataSource} from '@angular/material/table';
import {MarkersProperty} from './model/markers.property';
import {ContainerFilter} from '../../service/model/container-filter';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-markers',
  templateUrl: './markers.component.html',
  styleUrls: ['./markers.component.scss']
})
export class MarkersComponent extends AbstractEditableTableComponent implements OnInit, OnDestroy {

  @Output()
  showProgressEvent = new EventEmitter<boolean>();

  @Input()
  selectionEmitter: EventEmitter<any>;

  @Input()
  buttonEmitter: EventEmitter<any>;

  @Input()
  tabEmitter: EventEmitter<MatTabChangeEvent>;
  private eventSubscription;
  private tabSubscription;

  source = Array<SyntaxMessageNode>();

  selectedCategory = new FormControl();
  categoryList: string[] = [];

  selectedItem = new FormControl();
  itemList = [];
  itemIds = new Map<string, string>();

  columnCodes = ColumnCodes;
  constructor(protected elementRef: ElementRef
            , private bayPlanService: BayPlanService
            , private eventService: EventService,
              protected snackBar: MatSnackBar
            , private bayPlanEdiParserService: BayPlanEdiParserService
            , private imdgSegregationService: IMDGSegregationService
            , private stowageValidationService: StowageValidationService) {
    super(elementRef, snackBar);
    this.eventSubscription = this.eventService.getEmitter().subscribe(event => {
      if (event.id ===  EventIds.MARKERS) {
        this.initIds();
        this.refreshData();
      }
    });
    this.categoryList = [MarkersProperty.CATEGORY_IMDG, MarkersProperty.CATEGORY_SYNTAX, MarkersProperty.CATEGORY_STOWAGE];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.selectedCategory.setValue(this.categoryList);
    this.initIds();
    this.tabSubscription = this.tabEmitter.subscribe(event => {
      if (event.tab.textLabel === BottomTabText.MARKERS) {
        this.refreshData();
      }
    });
    this.refreshData();
  }
  ngOnDestroy(): void {

    try {
      super.ngOnDestroy();
      if (this.eventSubscription) {
        this.eventSubscription.unsubscribe();
      }
    } catch (error) {
      console.error(error);
    }

  }

  initIds() {
    this.itemIds = new Map<string, string>();
    if (this.selectedCategory && this.selectedCategory.value) {
      for (const categoryId of this.selectedCategory.value) {
        if (categoryId === MarkersProperty.CATEGORY_IMDG) {
          for (const item of MarkersProperty.getImdgItems()) {
            this.itemIds.set(item, item);
          }
        }
        if (categoryId === MarkersProperty.CATEGORY_SYNTAX) {
          for (const item of MarkersProperty.getSyntaxItems()) {
            this.itemIds.set(item, item);
          }
        }
        if (categoryId === MarkersProperty.CATEGORY_STOWAGE) {
          for (const item of MarkersProperty.getStowageItems()) {
            this.itemIds.set(item, item);
          }
        }
      }
    }
    this.itemList = [];
    for (const item of this.itemIds.keys()) {
      this.itemList.push(item);
    }
    this.selectedItem.setValue(this.itemList);
  }
  displayCategory() {
    this.initIds();
    this.displayItem();
  }

  displayItem() {
    this.itemIds = new Map<string, string>();
    const data = new Array<SyntaxMessageNode>();
    for (const item of this.selectedItem.value) {
      this.itemIds.set(item, item);
    }
    for (const item of this.source) {
      if (this.itemIds.size === 0) {
        break;
      } else if (this.itemIds.get(item.syntaxName)) {
        data.push(item);
      }
    }
    this.dataSource = new MatTableDataSource<SyntaxMessageNode>(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, headerId) => {

      return this.getValueFormatted(item as SyntaxMessageNode, headerId);
    };
  }
  refreshData() {

    const markers = new Array<SyntaxMessageNode>();

    markers.push.apply(markers, this.resetValidationData());
    markers.push.apply(markers, this.refreshIMDGSegregation());
    markers.push.apply(markers, this.refreshStowageValidation());
    markers.sort((a, b) => a.category >= b.category ? 1 : -1);
    this.source = Array<SyntaxMessageNode>();
    this.source.push.apply(this.source, markers);

    this.dataSource = new MatTableDataSource<SyntaxMessageNode>(markers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, headerId) => {

      return this.getValueFormatted(item as SyntaxMessageNode, headerId);
    };
  }

  refreshIMDGSegregation(): SyntaxMessageNode[] {

    const markers = new Array<SyntaxMessageNode>();
    const errors: Array<SyntaxMessageError> = this.imdgSegregationService.getErrorMessages();

    if (errors) {
      for (const error of errors) {
        const marker: SyntaxMessageNode = new SyntaxMessageNode();
        marker.category = error.category;
        marker.syntaxName = error.syntaxName;
        marker.description = error.description;
        marker.stowage = error.stowage;
        markers.push(marker);
      }
    }
    return markers;
  }

  refreshStowageValidation() {

    const markers = new Array<SyntaxMessageNode>();
    const errors = this.stowageValidationService.getErrorMessages();

    if (errors) {
      for (const error of errors) {
        const marker: SyntaxMessageNode = new SyntaxMessageNode();
        marker.category = error.category;
        marker.syntaxName = error.syntaxName;
        marker.description = error.description;
        marker.stowage = error.stowage;
        markers.push(marker);
      }
    }
    return markers;
  }

  resetValidationData() {
    const markers = new Array<SyntaxMessageNode>();
    const ediDocument: EdiDocument = this.bayPlanEdiParserService.parseBayPlan2EdiDocument(this.bayPlanService.getBayPlan());
    for (const message of ediDocument.syntaxMessages) {
      for (const error of message.errorMessageArray) {
        const marker: SyntaxMessageNode = new SyntaxMessageNode();
        marker.category = error.category;
        marker.lineIndex = message.lineIndex;
        marker.lineText = message.lineText;
        marker.syntaxName = error.syntaxName;
        marker.description = error.description;
        marker.stowage = error.stowage;
        markers.push(marker);
      }
    }
    return markers;
  }

  fireSelectedItem(index: number) {
  }
  getStyleClass(item: any, columnCode) {
    return 'bold-label';
  }
  getValue(item: SyntaxMessageNode, code: string) {
    if (code === ColumnCodes.CATEGORY) {
      return item.category;
    } else if (code === ColumnCodes.ITEM) {
      return item.syntaxName;
    } else if (code === ColumnCodes.DESCRIPTION) {
      return item.description;
    } else if (code === ColumnCodes.DESCRIPTION2) {
      return item.lineText;
    } else if (code === ColumnCodes.STOWAGE) {
      return item.stowage;
    } else if (code === ColumnCodes.LINE) {
      return item.lineIndex;
    }
    return '';
  }

  getValueFormatted(item: SyntaxMessageNode, code: string) {
    return this.getValue(item, code);
  }

  initColumns(): EditableColumn[] {
    const columns = new Array<EditableColumn>();
    columns.push(new EditableColumn(ColumnCodes.CATEGORY, '', EditableColumn.LINE_SELECTION));
    columns.push(new EditableColumn(ColumnCodes.ITEM, '', EditableColumn.LINE_SELECTION));
    columns.push(new EditableColumn(ColumnCodes.DESCRIPTION, ColumnCodes.DESCRIPTION, EditableColumn.LINE_SELECTION));
    columns.push(new EditableColumn(ColumnCodes.STOWAGE, ColumnCodes.STOWAGE, EditableColumn.LINE_SELECTION));
    columns.push(new EditableColumn(ColumnCodes.DESCRIPTION2, ColumnCodes.DESCRIPTION2, EditableColumn.LINE_SELECTION));
    columns.push(new EditableColumn(ColumnCodes.LINE, ColumnCodes.LINE, EditableColumn.LINE_SELECTION));
    return columns;
  }

  setValue(item: EditableModel, code: string, value: string): boolean {
    return false;
  }

  applyContainerFilter() {

    const filter = new ContainerFilter();
    this.selection.selected.forEach(item => {

      const msg = item as SyntaxMessageNode;

      if (msg.stowage) {
        filter.multiStowageMap.set(msg.stowage, msg.stowage);
      }
    });
    this.eventService.emit(EventIds.APPLY_FILTER, filter);

  }
}

enum ColumnCodes {
  CATEGORY = 'Category',
  ITEM = 'Item',
  DESCRIPTION = 'Description',
  STOWAGE = 'Stowage',
  DESCRIPTION2 = 'Description-2',
  LINE = 'Line'
}
