<div fxLayout="row" fxLayoutAlign="start center">
  <button *ngIf="!isEditable" mat-icon-button color="primary" (click)="editable(true)">
    <mat-icon>lock</mat-icon>
  </button>
  <button *ngIf="isEditable" mat-icon-button color="primary" (click)="editable(false)">
    <mat-icon>lock_open</mat-icon>
  </button>
  <button *ngIf="modified" mat-icon-button color="primary"
          disabled = "{{!authService.eligibleToEdit()}}"
          (click)="save()">
    <mat-icon>save</mat-icon>
  </button>
</div>
<app-column-info *ngIf="!isEditable" [visible]="!isEditable" [actionEmitter]="actionEmitter"></app-column-info>
<app-column-edit *ngIf="isEditable" [visible]="isEditable" [actionEmitter]="actionEmitter"></app-column-edit>
