import PIXI from 'pixi.js';
import {Vessel} from '../../../hull/model/vessel';
import {BayPlan} from '../../../service/model/bay-plan';
import {VesselUtils} from '../../../hull/service/vessel-utils';
import {Bay} from '../../../hull/model/bay';
import {Bounds} from '../../../core/pixi/visual-part';
import {BayVisualPart} from './bay.visual-part';
import {BayDimensions, BayPlanDimensions, ZoomLevel} from './bay-plan-dimensions';
import {HatchVisualPart} from './hatch.visual-part';
import {MouseTrackableVisualPart} from '../../../core/pixi/mouse-trackable.visual-part';
import {SizeType} from '../../../code/size-type/service/model/size-type';
import {OverSlotVisualPart} from './over-slot-visual-part';
import {ContentDisplayModes} from './content-display-modes';
import {BayPlanRoot} from '../../../core/pixi/bay-plan-root';
import {LayoutZoom} from './layout-zoom';

export enum LayoutModes {
  GENERAL_STOWAGE_PLAN = 'General Stowage Plan',
  VESSEL_LAYOUT = 'Vessel Layout',
}

export class BayPlanRootVisualPart extends MouseTrackableVisualPart implements BayPlanRoot{

  private vessel: Vessel;
  private vesselHelper: VesselUtils;
  private startBayIndex = 0;
  private endBayIndex = 0;
  private contentDisplayMode: string;

  constructor(protected parentGraphics: PIXI.Container,
              private layoutZoom: LayoutZoom,
              public bayPlan: BayPlan,
              private sizeTypeMap: Map<string, SizeType>
  ) {
    super(parentGraphics);
    this.judgeContentDisplayMode(layoutZoom.layoutMode);

  }
  public refreshData(bayPlan: BayPlan, vessel: Vessel) {
    this.bayPlan = bayPlan;
    this.vessel = vessel;
    this.vesselHelper = new VesselUtils(this.vessel);
    this.initLayout();
    this.initiateBays();
  }
  public setVessel(vessel: Vessel) {
    this.vessel = vessel;
    this.vesselHelper = new VesselUtils(this.vessel);
    this.initLayout();
    this.initiateBays();
  }
  private initLayout() {
    this.startBayIndex = 0;
    this.endBayIndex = this.vessel.bayList.length - 1;

    if (!(this.layoutZoom.layoutMode === LayoutModes.GENERAL_STOWAGE_PLAN || this.layoutZoom.layoutMode === LayoutModes.VESSEL_LAYOUT)) {
      let found = false;
      for (let i = 0; i < this.vessel.bayList.length; i++) {

        if (this.vessel.bayList[i].hatchName === this.layoutZoom.layoutMode) {
          if (!found) {
            this.startBayIndex = i;
          }

          found = true;
        } else {
          if (found) {
            this.endBayIndex = i - 1;
          }
          found = false;
        }
      }
    }
  }

  public changeLayout(layoutZoom: LayoutZoom) {
    this.layoutZoom = layoutZoom;

    this.judgeContentDisplayMode(layoutZoom.layoutMode);
    this.initLayout();
    this.resetBounds();
    this.draw();
  }
  private judgeContentDisplayMode(layoutMode: string) {
    if (layoutMode === LayoutModes.GENERAL_STOWAGE_PLAN) {
      this.contentDisplayMode = ContentDisplayModes.NONE;
    } else if (layoutMode === LayoutModes.VESSEL_LAYOUT) {
      this.contentDisplayMode = ContentDisplayModes.PORT_INITIAL;
    } else {
      this.contentDisplayMode = ContentDisplayModes.CONTAINER_NO;
    }
  }
  private resetBounds(): Bounds {
    const bounds = this.getBounds();

    this.initiateBays();

    this.setBounds(bounds.x, bounds.y, bounds.width, bounds.height);

    this.children.forEach(child => {
      if (child instanceof OverSlotVisualPart) {
        const osVp = child as OverSlotVisualPart;

        osVp.resetBounds();
      }
    });

    return bounds;
  }
  private initiateBays() {

    this.init();

    const startBayIndex = this.startBayIndex;
    const endBayIndex = this.endBayIndex;

    let hatchName = '';
    let occupyBayIndex = 0;
    // for (let bayIndex = startBayIndex; bayIndex <= endBayIndex; bayIndex++) {
    for (let bayIndex = endBayIndex; bayIndex >= startBayIndex; bayIndex--) {

      if (hatchName !== this.vessel.bayList[bayIndex].hatchName || this.vessel.hatchList[Number(this.vessel.bayList[bayIndex].hatchName) - 1].bayNumberList.length < 3) {

        const bay: Bay = this.vessel.bayList[bayIndex];
        let bayName: string = VesselUtils.fixStowageType(bay.name, 2);

        if (this.vessel.hatchList[Number(this.vessel.bayList[bayIndex].hatchName) - 1].bayNumberList.length === 3
          && this.vessel.hatchList[Number(this.vessel.bayList[bayIndex].hatchName) - 1].bayNumberList[2] === bay.name) {
          bayName += '(' + VesselUtils.fixStowageType(this.vessel.bayList[bayIndex - 1].name, 2) + ')';
        }
        this.addChild(bay.name, new BayVisualPart(this.graphics, this.getDimensions(), bay, bayName, this.vesselHelper, this.bayPlan));

        occupyBayIndex = 0;
        hatchName = this.vessel.bayList[bayIndex].hatchName;
      } else {
        occupyBayIndex = bayIndex;
        hatchName = '';
      }

      if ((this.vessel.bayList[bayIndex - 1] && this.vessel.bayList[bayIndex].hatchName !== this.vessel.bayList[bayIndex - 1].hatchName)
          || bayIndex === startBayIndex) {
        this.addChild(this.vessel.bayList[bayIndex].hatchName, new HatchVisualPart(this.graphics));
      }
    }
    this.initTracker(this.tracker);
  }
  public setBounds(x: number, y: number, width: number, height?: number) {
    height = 0;
    const xStart = 2;
    const yStart = 0;
    const bayWidth = this.vessel.maxDeckRow * (this.getDimensions().cellWidth + this.getDimensions().cellMargin)
      + this.getDimensions().xMargin * 1.5 + this.getDimensions().tierWidth;
    const bayHeight = (this.vessel.maxDeckTier + this.vessel.maxHoldTier) * (this.getDimensions().cellHeight + this.getDimensions().cellMargin)
      + this.getDimensions().hatchCoverHeight * 3 + this.getDimensions().bayNoHeight * 2 + this.getDimensions().rowNoHeight * 2;
    let xPosition = xStart + this.getDimensions().xMargin;
    let yPosition = yStart;
    let hatchXPosition = 0;
    let hatchYPosition = 0;
    let hatchWidth = 0;
    height = bayHeight + 5;
    let first = true;
    this.childrenMap.forEach(child => {
      if (child instanceof BayVisualPart) {
        let count = 1;
        if (child.getBayTitle().length > 3) {
          count = 2;
        }
        if (xStart + this.getDimensions().xMargin + xPosition + bayWidth * count > width) {
          xPosition = xStart + this.getDimensions().xMargin;
          if (!first) {
            yPosition = yPosition + bayHeight;
          }
          height = yPosition + bayHeight + 5;
        }
        if (hatchWidth === 0) {
          hatchXPosition = xPosition;
          hatchYPosition = yPosition;
        }

        hatchWidth += bayWidth;
        child.setBounds(xPosition, yPosition, bayWidth, bayHeight, this.getDimensions());
        xPosition = xPosition + bayWidth;
        first = false;
      } else if (child instanceof HatchVisualPart) {
        child.setBounds(hatchXPosition - this.getDimensions().xMargin, hatchYPosition, hatchWidth, bayHeight);
        hatchXPosition = xStart;
        hatchWidth = 0;
      }
    });

    super.setBounds(x, y, width, height);
  }
  public getContentDisplayMode(): string {
    return this.contentDisplayMode;
  }
  public getZoomLevel(): number {
    return this.layoutZoom.zoomLevel;
  }
  private getDimensions(): BayDimensions {

    return BayPlanDimensions[this.layoutZoom.zoomLevel];
  }
  private getBayWidth(): number {

    return this.vessel.maxDeckRow * this.getDimensions().cellWidth + this.getDimensions().xMargin * 2;
  }
  private getBayHeight(): number {

    return (this.vessel.maxDeckTier + this.vessel.maxHoldTier + 1) * this.getDimensions().cellHeight
      + this.getDimensions().hatchCoverHeight * 3 + this.getDimensions().bayNoHeight * 2;
  }
  public getLayoutMode(): string {
    return this.layoutZoom.layoutMode;
  }
  draw() {
    super.draw();
    this.getGraphics().lineStyle(1, 0x000000);
    // this.getGraphics().beginFill(0xAADBFF, 1);
    // this.getGraphics().beginFill(0xFAFAFA, 1);
    this.getGraphics().drawRect(this.bounds.x, this.bounds.y, this.bounds.width, this.bounds.height);
    // this.getGraphics().endFill();

  }
  public getSizeTypeMap(): Map<string, SizeType> {
    return this.sizeTypeMap;
  }
}

