import {DangerousRemark} from './dangerous-remark';

export class Dangerous {
  imdg = '';
  unno = '';
  packingGroup = '';
  goodsLabelMark1 = '';
  goodsLabelMark2 = '';
  goodsLabelMark3 = '';
  shipmentFlashPoint = '';
  measureUnitCode = '';

  freeTextCode = '';
  freeText1 = '';
  freeText2 = '';
  freeText3 = '';
  remarks = new Array<DangerousRemark>();

  hazardPageNo = '';
  emsNo = '';
  mfag = '';
  hazardIdUpper = '';
  hazardIdLower = '';

  amendment = '';
  shippingName = '';
  division = '';
  subsidiaryHazard = '';
  marinePollutant = '';
  specialProvisions = '';
  limitedQuantities = '';
  exceptedQuantities = '';
  packingInstructions = '';
  packingProvisions = '';
  ibcInstructions = '';
  ibcProvisions = '';
  tankInstructions = '';
  tankProvisions = '';
  ems = '';
  stowage = '';
  handling = '';
  segregationGroup = '';
  segregationCodes = '';
  propertiesObservations = '';
  nosFlag = '';

  constructor() {
  }
}
