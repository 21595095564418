import {Component, OnInit, Output, EventEmitter, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {AuthService} from '../../user/service/auth.service';

@Component({
  selector: 'app-columns',
  templateUrl: './columns.component.html',
  styleUrls: ['./columns.component.css']
})
export class ColumnsComponent implements OnInit, OnDestroy {
  isEditable: boolean;

  modified = false;

  @Output()
  actionEmitter = new EventEmitter<string>();

  private actionSubscription;
  constructor(private authService: AuthService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.actionSubscription = this.actionEmitter.subscribe(event => {

      if (event === ColumnActionIds.MODIFIED) {
        this.modified = true;
      } else if (event === ColumnActionIds.MODIFICATION_CLEANED) {
        this.modified = false;
      }

      this.cdr.detectChanges();
    });
  }

  editable(b: boolean) {
    this.isEditable = b;
    this.actionEmitter.emit(this.isEditable ? ColumnActionIds.EDIT_SELECTED : ColumnActionIds.INFO_SELECTED);
  }

  save() {
    this.actionEmitter.emit(ColumnActionIds.SAVE);
  }
  ngOnDestroy(): void {
    if (this.actionSubscription) {
      this.actionSubscription.unsubscribe();
    }
  }
}

export enum ColumnActionIds {
  SAVE = 'SAVE',
  MODIFIED = 'MODIFIED',
  MODIFICATION_CLEANED = 'MODIFICATION_CLEANED',
  INFO_SELECTED = 'INFO_SELECTED',
  EDIT_SELECTED = 'EDIT_SELECTED',
}
