<div *ngIf="showProgress">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div fxLayout="column" fxLayoutAlign="start stretch">
  <table mat-table [dataSource]="dataSource"
         cdkDropList [cdkDropListData]="dataSource"
         (cdkDropListDropped)="drop($event)"
         class="mat-elevation-z0"  class="topics">
    <ng-container matColumnDef="seq">
      <th mat-header-cell *matHeaderCellDef >Seq.</th>
      <td mat-cell *matCellDef="let element">{{element.seq}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef >Name</th>
      <td mat-cell *matCellDef="let element" matTooltip="{{getColumnFullName(element.code)}}" matTooltipClass="tooltip">{{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="visible">
      <th mat-header-cell *matHeaderCellDef>Visible</th>
      <td mat-cell *matCellDef="let element">
        <div style="text-align: center; justify-content: flex-end">
          <mat-checkbox (click)="$event.stopPropagation();"
                        [checked]="element.visible"
                        (change)="changeVisible(element, $event)"
                        [aria-label]="element.visible" style="text-align: center; justify-content: flex-end">
          </mat-checkbox>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="colored">
      <th mat-header-cell *matHeaderCellDef>Colored</th>
      <td mat-cell *matCellDef="let element">
        <div style="text-align: center; justify-content: flex-end">
          <mat-checkbox *ngIf="element.colorable"
                        (click)="$event.stopPropagation();"
                        [checked]="element.colored"
                        (change)="changeColored(element, $event)"
                        [aria-label]="element.colore "style="text-align: center; justify-content: flex-end">
          </mat-checkbox>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;" cdkDragLockAxis="y" cdkDrag [cdkDragData]="row">
  </table>
</div>
