import {Component, Input, OnInit, EventEmitter, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {Container} from '../../../service/model/container';
import {MatTableDataSource} from '@angular/material/table';
import {ColumnIds} from '../../columns/service/model/column-ids';
import {ColumnService} from '../../columns/service/column.service';
import {ColumnUtils} from '../../columns/service/model/column-utils';
import {EventService} from '../../../core/event/event.service';
import {EventIds} from '../../../core/event/event-ids';
import {CodeService} from '../../../code/service/code.service';
import {ContainerFilter} from '../../../service/model/container-filter';
import {Freetext} from '../../../my-plans/service/model/freetext';
import {ContainerColumn} from '../../columns/service/model/container-column';

@Component({
  selector: 'app-container-details',
  templateUrl: './container-details.component.html',
  styleUrls: ['./container-details.component.css']
})
export class ContainerDetailsComponent implements OnInit, OnDestroy {

  @Input()
  container: Container;

  @Input()
  url = '';

  @Input()
  containerEmitter = new EventEmitter<Container>();

  private eventSubscription;
  private containerSubscription;
  columns: string[] = ['select', 'name', 'value'];
  dataSource = new MatTableDataSource<Property>();

  constructor(private columnService: ColumnService
            , private eventService: EventService
            , private codeService: CodeService
            , private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {

    if (this.container) {
      this.fillTable(this.container);
    }

    this.containerSubscription = this.containerEmitter.subscribe(data => {
      this.container = data;

      if (data) {
        try {
          this.fillTable(data);
        } catch (error) {
          console.error(error);
        }

      } else {
        this.dataSource = new MatTableDataSource<Property>();
      }
    });
    this.eventSubscription = this.eventService.getEmitter().subscribe(event => {
      if (event.id === EventIds.ROUTE_CHANGE) {
        // console.info(event.newValue);
      }
      if (event.id === EventIds.FILTER_FROM_LIST) {
        this.applyFilters();
      }
    });
  }
  fillTable(container: Container) {
    const sizeTypeMap = this.codeService.getSizeTypeMap();
    const list = new Array<Property>();
    this.columnService.getColumns().then(data => {

      data.forEach(column => {


        if (column.code === ColumnIds.ISO_SIZE_TYPE) {

          const value = container.isoSizeType;
          if (value) {
            if (sizeTypeMap) {
              const sizeType = sizeTypeMap.get(value);
              if (sizeType) {
                list.push({code: column.code, name: column.name, fullName: ColumnUtils.getDefaultFullName(column.code), value, formattedValue: ColumnUtils.getValueFormatted(column.code, container), filterable: true, selected: false});
                if (sizeType.lengthCode && sizeType.lengthName) {
                  list.push({code: ColumnIds.CONTAINER_LENGTH, name: ColumnUtils.getDefaultShortName(ColumnIds.CONTAINER_LENGTH), fullName: ColumnUtils.getDefaultFullName(ColumnIds.CONTAINER_LENGTH), value: sizeType.lengthCode, formattedValue: sizeType.lengthName, filterable: true, selected: true});
                }
                if (sizeType.heightCode && sizeType.heightName) {
                  list.push({code: ColumnIds.CONTAINER_HEIGHT, name: ColumnUtils.getDefaultShortName(ColumnIds.CONTAINER_HEIGHT), fullName: ColumnUtils.getDefaultFullName(ColumnIds.CONTAINER_HEIGHT), value: sizeType.heightCode, formattedValue: sizeType.heightName, filterable: true, selected: true});
                }
                if (sizeType.typeCode && sizeType.typeName) {
                  list.push({code: ColumnIds.CONTAINER_TYPE, name: ColumnUtils.getDefaultShortName(ColumnIds.CONTAINER_TYPE), fullName: ColumnUtils.getDefaultFullName(ColumnIds.CONTAINER_TYPE), value: sizeType.typeCode, formattedValue: sizeType.typeName, filterable: true, selected: true});
                }
              }
            }
          }
          return;
        }

        if (column.code === ColumnIds.OVER_DIMENSIONS) {
          if (container.overDimLeft || container.overDimRight || container.overDimFront || container.overDimBack || container.overDimHeight) {
            list.push({code: column.code, name: column.name, fullName: ColumnUtils.getDefaultFullName(column.code), value: '', formattedValue: ColumnUtils.getValueFormatted(ColumnIds.OVER_DIMENSIONS, container), filterable: true, selected: true, booleanType: true});
          }
          return;
        }
        if (column.code === ColumnIds.OVER_SLOTS) {
          if (container.overSlotLeft || container.overSlotRight || container.overSlotFront || container.overSlotBack || container.overSlotHeight) {
            list.push({code: column.code, name: column.name, fullName: ColumnUtils.getDefaultFullName(column.code), value: '', formattedValue: ColumnUtils.getValueFormatted(ColumnIds.OVER_SLOTS, container), filterable: true, selected: true, booleanType: true});
          }
          return;
        }
        if (column.code === ColumnIds.BREAK_BULK) {
          if (container.breakBulkLength || container.breakBulkHeight || container.breakBulkWidth) {
            list.push({code: column.code, name: column.name, fullName: ColumnUtils.getDefaultFullName(column.code), value: '', formattedValue: ColumnUtils.getValueFormatted(ColumnIds.BREAK_BULK, container), filterable: true, selected: true, booleanType: true});
          }
          return;
        }
        if (column.code === ColumnIds.CARGO_NATURE) {
          if (container.cargoNatures && container.cargoNatures.length > 0) {
            list.push({code: column.code, name: column.name, fullName: ColumnUtils.getDefaultFullName(column.code), value: '', formattedValue: ColumnUtils.getValueFormatted(ColumnIds.CARGO_NATURE, container), filterable: true, selected: true, booleanType: true});
          }
          return;
        }
        if (column.code === ColumnIds.IMDG) {
          if (container.dangerousGoods) {
            if (container.dangerousGoods.length > 0) {
              list.push({code: ColumnIds.HAZARDOUS, name: 'Dangerous', fullName: ColumnUtils.getDefaultFullName(column.code), value: 'Y', formattedValue: 'Y', filterable: true, selected: true, booleanType: true});

              let index = 0;

              const dgLength = container.dangerousGoods.length;
              container.dangerousGoods.forEach(dg => {
                const order = (++index).toString();

                let orderStr = '';
                if (dgLength > 1) {
                  orderStr = order + ') ';
                }

                list.push({code: ColumnIds.IMDG + '-' + order, name:  orderStr + 'IMDG', fullName: ColumnUtils.getDefaultFullName(column.code), value: dg.imdg, formattedValue: dg.imdg, filterable: false});

                if (dg.hazardPageNo) {
                  list.push({code: 'HAZARD_PAGE_NO' + '-' + order, name: orderStr + 'Page no', fullName: ColumnUtils.getDefaultFullName(column.code), value: dg.hazardPageNo, formattedValue: dg.hazardPageNo, filterable: false});
                }
                if (dg.unno) {
                  list.push({code: ColumnIds.UNNO + '-' + order, name: orderStr + 'UNNO', fullName: ColumnUtils.getDefaultFullName(column.code), value: dg.unno, formattedValue: dg.unno, filterable: false});
                }
                if (dg.shipmentFlashPoint) {
                  list.push({code: 'SHIPMENT_FLASH_POINT' + '-' + order, name: orderStr + 'Shipment Flashpoint', fullName: ColumnUtils.getDefaultFullName(column.code), value: dg.shipmentFlashPoint, formattedValue: dg.shipmentFlashPoint, filterable: false});
                }
                if (dg.measureUnitCode) {
                  list.push({code: ColumnIds.TEMPERATURE_UNIT + '-' + order, name: orderStr + 'Temperature Unit', fullName: ColumnUtils.getDefaultFullName(column.code), value: dg.measureUnitCode, formattedValue: dg.measureUnitCode, filterable: false});
                }
                if (dg.packingGroup) {
                  list.push({code: 'PACKING_GROUP' + '-' + order, name: orderStr + 'Packing Group', fullName: ColumnUtils.getDefaultFullName(column.code), value: dg.packingGroup, formattedValue: dg.packingGroup, filterable: false});
                }
                if (dg.emsNo) {
                  list.push({code: 'EMS_NO' + '-' + order, name: orderStr + 'EMS', fullName: ColumnUtils.getDefaultFullName(column.code), value: dg.emsNo, formattedValue: dg.emsNo, filterable: false});
                }
                if (dg.mfag) {
                  list.push({code: 'M_FLAG' + '-' + order, name: orderStr + 'MFAG', fullName: ColumnUtils.getDefaultFullName(column.code), value: dg.mfag, formattedValue: dg.mfag, filterable: false});
                }
                if (dg.hazardIdUpper) {
                  list.push({code: 'HAZARD_ID' + '-' + order, name: orderStr + 'Hazard ID', fullName: ColumnUtils.getDefaultFullName(column.code), value: '', formattedValue: dg.hazardIdUpper , filterable: false});
                }
                if (dg.hazardIdLower) {
                  list.push({code: 'SUBSTANCE_ID' + '-' + order, name: orderStr + 'Substance ID', fullName: ColumnUtils.getDefaultFullName(column.code), value: '', formattedValue: dg.hazardIdLower, filterable: false});
                }
                if (dg.goodsLabelMark1) {
                  list.push({code: 'GOODS_LABEL_MARK1' + '-' + order, name: orderStr + 'Label', fullName: ColumnUtils.getDefaultFullName(column.code), value: '', formattedValue: this.trim(dg.goodsLabelMark1) , selected: false, filterable: false});
                }
                if (dg.goodsLabelMark2) {
                  list.push({code: 'GOODS_LABEL_MARK2' + '-' + order, name: orderStr + 'Label', fullName: ColumnUtils.getDefaultFullName(column.code), value: '', formattedValue: this.trim(dg.goodsLabelMark2) , selected: false, filterable: false});
                }
                if (dg.goodsLabelMark3) {
                  list.push({code: 'GOODS_LABEL_MARK3' + '-' + order, name: orderStr + 'Label', fullName: ColumnUtils.getDefaultFullName(column.code), value: '', formattedValue: this.trim(dg.goodsLabelMark3) , selected: false, filterable: false});
                }
                if (dg.freeText1) {
                  list.push({code: 'DG_REMARK1' + '-' + order, name: orderStr + 'Remarks', fullName: ColumnUtils.getDefaultFullName(column.code), value: '', formattedValue: dg.freeText1, filterable: false});
                }
                if (dg.freeText2) {
                  list.push({code: 'DG_REMARK2' + '-' + order, name: orderStr + 'Remarks', fullName: ColumnUtils.getDefaultFullName(column.code), value: '', formattedValue: dg.freeText2, filterable: false});
                }
                if (dg.freeText3) {
                  list.push({code: 'DG_REMARK3' + '-' + order, name: orderStr + 'Remarks', fullName: ColumnUtils.getDefaultFullName(column.code), value: '', formattedValue: dg.freeText3, filterable: false});
                }
              });
            }
          }
          return;
        }
        if (column.code === ColumnIds.BUNDLED_CONTAINER) {
          if (container.bundledContainers) {
            if (container.bundledContainers.length > 0) {
              list.push({code: column.code, name: column.name, fullName: ColumnUtils.getDefaultFullName(column.code), value: 'Y', formattedValue: 'Y', filterable: true, selected: true});

              let index = 0;
              container.bundledContainers.forEach(cntrNo => {
                const order = (++index).toString();
                list.push({code: ColumnIds.BUNDLED_CONTAINER + '-' + order, name: column.name + ' [' + order + ')', fullName: ColumnUtils.getDefaultFullName(column.code), value: cntrNo, formattedValue: cntrNo, filterable: false});
              });
            }
          }
          return;
        }

        if (column.code === ColumnIds.DESCRIPTION_OF_GOODS) {
          this.addRemark(column, container, list, 'AAA');
          return;
        }
        if (column.code === ColumnIds.HANDLING_INSTRUCTION) {
          this.addRemark(column, container, list, 'HAN');
          return;
        }
        if (column.code === ColumnIds.LOADING_REMARK) {
          this.addRemark(column, container, list, 'CLR');
          return;
        }
        if (column.code === ColumnIds.SPECIAL_CARGO_INSTRUCTION) {
          this.addRemark(column, container, list, 'SIN');
          return;
        }
        if (column.code === ColumnIds.GENERAL_INFORMATION) {
          this.addRemark(column, container, list, 'AAI');
          return;
        }
        if (column.code === ColumnIds.MUTUALLY_DEFINED_REMARK) {
          this.addRemark(column, container, list, 'ZZZ');
          return;
        }
        if (column.code === ColumnIds.VGM_CERTI_CODE) {
          const fxList = container.freeTexts.get('AAY') as Array<Freetext>;
          if (fxList) {
            fxList.forEach(fx => {
              list.push({code: column.code, name: column.name, fullName: ColumnUtils.getDefaultFullName(column.code), value: fx.vgmCertificationCode, formattedValue: fx.vgmCertificationCode, filterable: true, selected: false, booleanType: false});

              if (fx.remark) {
                list.push({code: ColumnIds.VGM_INFO, name: ColumnUtils.getDefaultShortName(ColumnIds.VGM_INFO), fullName: ColumnUtils.getDefaultFullName(ColumnIds.VGM_INFO), value: fx.remark, formattedValue: fx.remark, filterable: false, selected: false, booleanType: false});
              }
              if (fx.vgmInfo2) {
                list.push({code: ColumnIds.VGM_INFO, name: ColumnUtils.getDefaultShortName(ColumnIds.VGM_INFO), fullName: ColumnUtils.getDefaultFullName(ColumnIds.VGM_INFO), value: fx.vgmInfo2, formattedValue: fx.vgmInfo2, filterable: false, selected: false, booleanType: false});
              }
              if (fx.vgmInfo3) {
                list.push({code: ColumnIds.VGM_INFO, name: ColumnUtils.getDefaultShortName(ColumnIds.VGM_INFO), fullName: ColumnUtils.getDefaultFullName(ColumnIds.VGM_INFO), value: fx.vgmInfo3, formattedValue: fx.vgmInfo3, filterable: false, selected: false, booleanType: false});
              }
              if (fx.vgmInfo4) {
                list.push({code: ColumnIds.VGM_INFO, name: ColumnUtils.getDefaultShortName(ColumnIds.VGM_INFO), fullName: ColumnUtils.getDefaultFullName(ColumnIds.VGM_INFO), value: fx.vgmInfo4, formattedValue: fx.vgmInfo4, filterable: false, selected: false, booleanType: false});
              }
              if (fx.vgmInfo5) {
                list.push({code: ColumnIds.VGM_INFO, name: ColumnUtils.getDefaultShortName(ColumnIds.VGM_INFO), fullName: ColumnUtils.getDefaultFullName(ColumnIds.VGM_INFO), value: fx.vgmInfo5, formattedValue: fx.vgmInfo5, filterable: false, selected: false, booleanType: false});
              }
            });
            return;
          }
        }

        if (column.code === ColumnIds.EQUIPMENT_TYPE || column.code === ColumnIds.VGM_INFO) {
          return;
        }

        let selected = true;
        let filterable = true;
        let booleanType = false;

        if (column.code === ColumnIds.STOWAGE) {selected = false; }
        if (column.code === ColumnIds.CONTAINER_NO) {selected = false; }

        if (column.code === ColumnIds.BUNDLED_CONTAINER) {filterable = false; selected = false; }
        if (column.code === ColumnIds.BREAK_BULK) {filterable = false; selected = false; }
        if (column.code === ColumnIds.OVER_DIMENSIONS) {filterable = false; selected = false; }
        if (column.code === ColumnIds.OVER_SLOTS) {filterable = false; selected = false; }
        if (column.code === ColumnIds.TEMPERATURE) {selected = true; booleanType = true; }
        if (column.code === ColumnIds.TEMPERATURE_RANGE) { selected = false; booleanType = true; }

        if (column.code === ColumnIds.GROSS_WEIGHT) {filterable = false; selected = false; }
        if (column.code === ColumnIds.VGM_WEIGHT) {filterable = false; selected = false; }
        // if (column.code === ColumnIds.VGM_INFO) {selected = false; booleanType = true; }
        // if (column.code === ColumnIds.VGM_CERTI_CODE) {filterable = true; selected = false; }

        // if (column.code === ColumnIds.HANDLING_INSTRUCTION) {selected = false; }
        // if (column.code === ColumnIds.SPECIAL_CARGO_INSTRUCTION) {selected = false; booleanType = true; }
        // if (column.code === ColumnIds.LOADING_REMARK) {selected = false; booleanType = true; }
        // if (column.code === ColumnIds.GENERAL_INFORMATION) {selected = false; booleanType = true; }
        // if (column.code === ColumnIds.MUTUALLY_DEFINED_REMARK) {selected = false; booleanType = true; }
        // if (column.code === ColumnIds.DESCRIPTION_OF_GOODS) {selected = false; booleanType = true; }

        if (column.code === ColumnIds.BL_NO) {selected = false; }
        if (column.code === ColumnIds.BOOKING_NO) {selected = false; }
        if (column.code === ColumnIds.POR_TERMINAL) {selected = false; }
        if (column.code === ColumnIds.POL_TERMINAL) {selected = false; }
        if (column.code === ColumnIds.POD_TERMINAL) {selected = false; }
        if (column.code === ColumnIds.TS_TERMINAL) {selected = false; }
        if (column.code === ColumnIds.NPOD_TERMINAL) {selected = false; }
        if (column.code === ColumnIds.FPOD_TERMINAL) {selected = false; }
        if (column.code === ColumnIds.PLACE_OF_DELIVERY_TERMINAL) {selected = false; }

        if (column.code === ColumnIds.OPT_POD) {filterable = false; selected = false; }
        if (column.code === ColumnIds.OPT_POD_TERMINAL) {filterable = false; selected = false; }


        let value2 = '';

        if (column.code === ColumnIds.CARGO_TYPE) {
          value2 = ColumnUtils.getValue(ColumnIds.CARGO_TYPE_CODE, container);
        } else {
          value2 = ColumnUtils.getValue(column.code, container);
        }

        if (value2) {
          list.push({code: column.code, name: column.name, fullName: ColumnUtils.getDefaultFullName(column.code), value: value2, formattedValue: ColumnUtils.getValueFormatted(column.code, container), filterable, selected, booleanType});
        }
      });
      this.dataSource = new MatTableDataSource<Property>(list);
      this.cdr.detectChanges();
    });
  }
  addRemark(column: ContainerColumn, container: Container, list: Array<Property>, qualifier: string) {
    const fxList = container.freeTexts.get(qualifier) as Array<Freetext>;
    if (fxList) {
      fxList.forEach(fx => {
        list.push({code: column.code, name: column.name, fullName: ColumnUtils.getDefaultFullName(column.code), value: fx.remark, formattedValue: fx.remark, filterable: true, selected: false, booleanType: false});
      });
    }
  }
  applyFilters() {

    const filter = new ContainerFilter();
    const properties: Property[] = this.dataSource.data;

    properties.forEach(data => {
      if (!data.selected) {
        return;
      }
      filter.apply(data.code, data.value);
    });
    this.eventService.emit(EventIds.APPLY_FILTER, filter);
  }
  private trim(property: any): string {
    if (property) {
      return property;
    } else {
      return '';
    }
  }
  getContainerDetails() {
    if (!this.container) {
      return 'nothing selected';
    }
    return this.container.stowage
      + ', ' + this.container.containerNo
      + ', POD: ' + this.container.pod
      + ', POL: ' + this.container.pol
      + ', CARRIER: ' + this.container.carrier
      + ', ISO: ' + this.container.isoSizeType;
  }

  getBackColor(property: Property) {
    if (ColumnUtils.isPortColumn(property.code)) {
      const codeMap = this.codeService.getOwnPortMap();

      if (codeMap) {
        const code = codeMap.get(property.value);

        if (code) {

          return code.backColor;
        }
      }
    }
    if (property.code === ColumnIds.CARRIER) {
      const codeMap = this.codeService.getOwnOperatorMap();

      if (codeMap) {
        const code = codeMap.get(property.value);

        if (code) {
          return code.backColor;
        }
      }
    }
    return '';
  }

  getForeColor(property: Property) {
    if (ColumnUtils.isPortColumn(property.code)) {
      const codeMap = this.codeService.getOwnPortMap();

      if (codeMap) {
        const code = codeMap.get(property.value);

        if (code) {

          return code.foreColor;
        }
      }
    }
    if (property.code === ColumnIds.CARRIER) {
      const codeMap = this.codeService.getOwnOperatorMap();

      if (codeMap) {
        const code = codeMap.get(property.value);

        if (code) {
          return code.foreColor;
        }
      }
    }
    return '';
  }

  getStyleClass(property: Property) {
    if (ColumnUtils.isPortColumn(property.code)) {
      return 'port-label';
    } else if (property.code === ColumnIds.CARRIER
      || property.code === ColumnIds.CONTAINER_TYPE
      || property.code === ColumnIds.CONTAINER_HEIGHT
      || property.code === ColumnIds.CONTAINER_LENGTH
      || property.code === ColumnIds.FULL_EMPTY
      || property.code === ColumnIds.CARGO_TYPE
    ) {
      return 'operator-label';
    } else if (property.code === ColumnIds.VGM_WEIGHT
      || property.code === ColumnIds.GROSS_WEIGHT
      || property.code === ColumnIds.TEMPERATURE
    ) {
      return 'number-label';
    } else {
      return 'common-label';
    }
  }
  getTooTip(code: string) {

    return ColumnUtils.getToolTip(code);
  }
  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.containerSubscription) {
      this.containerSubscription.unsubscribe();
    }
  }
}
// class Property {
//   constructor(public code: string, public name: string, public value: string, public order: number) {
//   }
// }
class Property {
  constructor(public code, public name, public fullName, public value, public formattedValue, public filterable: boolean, public selected?: boolean, public booleanType?: boolean) {
  }
}
