import {Hatch} from './hatch';
import {Bay} from './bay';

export class Vessel {

  id;
  code: string;
  name: string;
  callSign: string;
  lloydNumber: string;
  lengthOfAll: number;

  deckHousePosition: number;
  funnelPosition: number;

  maxDeckRow = 0;
  maxHoldRow = 0;
  maxDeckTier = 0;
  maxHoldTier = 0;
  maxBay = 0;

  remark: string;
  version: string;
  merged = false;

  hatchList: Array<Hatch>;
  bayList: Array<Bay> = new Array<Bay>();

  constructor() {
    this.maxDeckRow = 0;
    this.maxHoldRow = 0;
    this.maxDeckTier = 0;
    this.maxHoldTier = 0;
    this.maxBay = 0;
  }

  certified = false;

}
