import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {EditableColumn} from './editable-column';
import {EditableModel} from './editable-model';
import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, SortDirection} from '@angular/material/sort';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-abstract-editable-table',
  template: ''
})
export abstract class AbstractEditableTableComponent implements OnInit, OnDestroy {
  columns = new Array<EditableColumn>();

  dataSource = new MatTableDataSource<EditableModel>();
  selection = new SelectionModel<EditableModel>(true, []);
  modifiedModels = new SelectionModel<EditableModel>(true, []);
  headerCodes = [];
  private isPropertyDirty = false;

  protected mousedownRowIndex = -1;
  private selectedRowColumnMaps = new Array<RowColumnMap>();
  protected mousedownColumnCode = '';
  protected mouseDragging = false;
  protected startItem = null;
  protected sortDescMap = new Map<string, boolean>();
  protected focusedOnTable = false;
  protected keyMap = new Map<string, boolean>();
  protected readonly SELECT_COLUMN_CODE = 'select';

  protected selectionBoxColumn = new EditableColumn(this.SELECT_COLUMN_CODE, this.SELECT_COLUMN_CODE, EditableColumn.LINE_SELECTION);
  protected multiSelection = true;

  @ViewChild(MatMenuTrigger, {static: true})
  contextMenu: MatMenuTrigger;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  //
  // @Input()
  // sortActionDescription: string;
  //
  // @Input('matSortDirection')
  // direction: SortDirection;

  contextMenuPosition = { x: '0px', y: '0px' };
  contextMenuOpened = false;

  private windowMouseDownListener: any;
  private windowMouseMoveListener: any;
  private windowMouseUpListener: any;
  private windowKeyDownListener: any;
  private windowKeyPressListener: any;
  private windowKeyUpListener: any;
  // private windowBlurListener: any;

  protected constructor(protected elementRef: ElementRef,
                        protected snackBar: MatSnackBar) {
  }

  ngOnDestroy(): void {
    document.removeEventListener('mousedown', this.windowMouseDownListener, true);
    document.removeEventListener('mousemove', this.windowMouseMoveListener, true);
    // window.removeEventListener('mouseup', this.windowMouseUpListener, true);
    document.removeEventListener('keydown', this.windowKeyDownListener, true);
    document.removeEventListener('keypress', this.windowKeyPressListener, true);
    document.removeEventListener('keyup', this.windowKeyUpListener, true);

    // window.removeEventListener('blur', this.windowBlurListener, true);

  }
  ngOnInit(multiSelection?: boolean): void {

    if (this.contextMenu) {
      this.contextMenu.menuClosed.subscribe(event => {
        this.contextMenuOpened = false;
      });
    }

    if (multiSelection !== undefined) {
      this.multiSelection = multiSelection;
    }

    this.columns = this.initColumns();
    this.headerCodes = new Array<string>();
    this.headerCodes.push(this.SELECT_COLUMN_CODE);
    this.columns.forEach(column => {
      this.headerCodes.push(column.code);
    });


    const self = this;
    const editTable = this.queryEditableTable();

    editTable.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.copyCells());
      e.preventDefault();
      editTable.removeEventListener('copy', null);
    });
    editTable.addEventListener('cut', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.copyCells(true));
      e.preventDefault();
      editTable.removeEventListener('cut', null);
    });
    editTable.addEventListener('paste', (e: ClipboardEvent) => {
      const clip = e.clipboardData.getData('text/plain');
      self.paste(clip);
      e.preventDefault();
      editTable.removeEventListener('paste', null);
    });
    editTable.addEventListener('mouseup', function _(e) {
      self.mouseDragging = false;
    });
    editTable.addEventListener('contextmenu', function _(e) {
      e.preventDefault();
    });
    editTable.addEventListener('mousemove', function _(e) {
      if (self.mouseDragging && e.button === 0) {
        e.preventDefault();
      }
    });
    editTable.addEventListener('mouseleave', function _(e) {

      // self.selectionList = new Array<RowColumnMap>();
      // self.focusedOnTable = false;
    });
    editTable.addEventListener('mousedown', this.tableMouseDown.bind(this), true);


    this.windowMouseDownListener = this.windowMouseDown.bind(this);
    document.addEventListener('mousedown', this.windowMouseDownListener, true);

    this.windowMouseMoveListener = this.windowMouseMove.bind(this);
    document.addEventListener('mousemove', this.windowMouseMoveListener, true);

    this.windowKeyDownListener = this.windowKeyDown.bind(this);
    document.addEventListener('keydown', this.windowKeyDownListener, true);

    this.windowKeyPressListener = this.windowKeyPress.bind(this);
    document.addEventListener('keypress', this.windowKeyPressListener, true);

    this.windowKeyUpListener = this.windowKeyUp.bind(this);
    document.addEventListener('keyup', this.windowKeyUpListener, true);

  }
  private tableMouseDown(e: MouseEvent) {

    this.focusedOnTable = true;

  }
  private windowMouseDown(e: MouseEvent) {
    // console.info('windowMouseDown');
    // console.info(!this.focusedOnTable);
    // this.mouseDownPoint = { x: e.clientX, y: e.clientY};
    if (e.button === 0 && !this.contextMenuOpened) {
      if (!(this.keyMap.get(Keys.Control) || this.keyMap.get(Keys.Shift))) {
        this.selectedRowColumnMaps = new Array<RowColumnMap>();
        this.focusedOnTable = false;
      }
    }
  }
  private windowBlur(e: any) {

    if (!this.contextMenuOpened) {
      this.focusedOnTable = false;
      this.selectedRowColumnMaps = new Array<RowColumnMap>();
    }
  }
  private windowMouseMove(e: MouseEvent) {
    // e.preventDefault();
  }
  private windowMouseUp(e: MouseEvent) {
    // console.info('windowMouseUp');
  }
  private tableKeyDown(e: KeyboardEvent) {

  }
  private windowKeyDown(e: KeyboardEvent) {
    // console.info(e);
    if (!this.focusedOnTable) {
      return;
    }
    this.keyMap.set(e.key, true);

    if ([Keys.ArrowUp.toString(), Keys.ArrowDown.toString(), Keys.ArrowLeft.toString(), Keys.ArrowRight.toString()
      , Keys.Shift.toString()].indexOf(e.code) > -1) {
      e.preventDefault();
    } else if ([Keys.Space.toString()].indexOf(e.code) > -1) {
      this.keypressOnCell(e);
      e.preventDefault();
      return;
    } else if ([Keys.Backspace.toString()].indexOf(e.code) > -1) {
      this.keypressOnCell(e);
      return;
    }
    if (this.keyMap.get(Keys.Control) && e.key.toUpperCase() === 'A') {

      e.preventDefault();
      return;
    }
    if (this.mousedownRowIndex < 0 || this.mousedownColumnCode === '') {
      return;
    }
    if (e.key === Keys.Shift) {
      e.preventDefault();
    }
    if (this.keyMap.get(Keys.ArrowLeft) || (this.keyMap.get(Keys.Tab) && this.keyMap.get(Keys.Shift))) {

      const selectedRowIndexMap = new Map<number, number>();
      const selectedColumnMap = new Map<string, string>();
      selectedRowIndexMap.set(this.mousedownRowIndex, this.mousedownRowIndex);

      for (let i = 0; i < this.columns.length; i++) {

        const column = this.columns[i];

        if (column.code === this.mousedownColumnCode) {

          if (i > 0) {
            this.mousedownColumnCode = this.columns[i - 1].code;
            selectedColumnMap.set(this.mousedownColumnCode, this.mousedownColumnCode);
          } else {
            this.mousedownColumnCode = this.SELECT_COLUMN_CODE;
          }
          break;
        }
      }
      this.selectedRowColumnMaps = new Array<RowColumnMap>();
      this.selectedRowColumnMaps.push(new RowColumnMap(selectedRowIndexMap, selectedColumnMap));
      e.preventDefault();
    } else if (this.keyMap.get(Keys.ArrowRight) || (this.keyMap.get(Keys.Tab) && !this.keyMap.get(Keys.Shift))) {

      const selectedRowIndexMap = new Map<number, number>();
      const selectedColumnMap = new Map<string, string>();
      selectedRowIndexMap.set(this.mousedownRowIndex, this.mousedownRowIndex);

      if (this.SELECT_COLUMN_CODE === this.mousedownColumnCode) {
        this.mousedownColumnCode = this.columns[0].code;
        selectedColumnMap.set(this.mousedownColumnCode, this.mousedownColumnCode);

      } else {
        for (let i = 0; i < this.columns.length; i++) {

          const column = this.columns[i];

          if (column.code === this.mousedownColumnCode) {

            if (i < this.columns.length - 1) {
              this.mousedownColumnCode = this.columns[i + 1].code;
              selectedColumnMap.set(this.mousedownColumnCode, this.mousedownColumnCode);
            }
            break;
          }
        }
      }

      this.selectedRowColumnMaps = new Array<RowColumnMap>();
      this.selectedRowColumnMaps.push(new RowColumnMap(selectedRowIndexMap, selectedColumnMap));
      e.preventDefault();
    } else if (this.keyMap.get(Keys.ArrowUp)) {

      const selectedRowIndexMap = new Map<number, number>();
      const selectedColumnMap = new Map<string, string>();
      selectedColumnMap.set(this.mousedownColumnCode, this.mousedownColumnCode);

      if (this.mousedownRowIndex > 0) {
        this.mousedownRowIndex = this.mousedownRowIndex - 1;
        selectedRowIndexMap.set(this.mousedownRowIndex, this.mousedownRowIndex);
        this.fireSelectedItem(this.mousedownRowIndex);
      }
      this.selectedRowColumnMaps = new Array<RowColumnMap>();
      this.selectedRowColumnMaps.push(new RowColumnMap(selectedRowIndexMap, selectedColumnMap));
      this.checkSelectionBoxes();

    } else if (this.keyMap.get(Keys.ArrowDown) || this.keyMap.get(Keys.Enter)) {

      const selectedRowIndexMap = new Map<number, number>();
      const selectedColumnMap = new Map<string, string>();
      selectedColumnMap.set(this.mousedownColumnCode, this.mousedownColumnCode);

      if (this.mousedownRowIndex  < this.dataSource.filteredData.length - 1) {
        this.mousedownRowIndex = this.mousedownRowIndex + 1;
        selectedRowIndexMap.set(this.mousedownRowIndex, this.mousedownRowIndex);
        this.fireSelectedItem(this.mousedownRowIndex);
      }
      this.selectedRowColumnMaps = new Array<RowColumnMap>();
      this.selectedRowColumnMaps.push(new RowColumnMap(selectedRowIndexMap, selectedColumnMap));
      this.checkSelectionBoxes();

    } else if (this.keyMap.get(Keys.Delete)) {

      this.selectedRowColumnMaps.forEach(rowColumn => {

        const selectedRowIndexMap = rowColumn.rowIndexMap;
        const selectedColumnMap = rowColumn.columnMap;

        selectedRowIndexMap.forEach((rowVal, rowKey) => {
          selectedColumnMap.forEach( (colVal, colKey) => {

            this.setValue(this.dataSource.filteredData[rowKey], colKey, '');
          });
        });
      });

    } else if (this.keyMap.get(Keys.Escape)) {
      this.deselectRow();


    }
  }
  private windowKeyPress(e: KeyboardEvent) {
    // console.info(e);
    this.keyMap.set(e.key, true);

    if (!this.focusedOnTable) {
      return;
    }
    // console.info(e.key + ',' + e.code);
    if (this.keyMap.get(Keys.Enter)) {
      return;
    }
    this.keypressOnCell(e);
  }
  private windowKeyUp(e: KeyboardEvent) {

    // console.info('windowKeyUp');



    this.keyMap.set(e.key, false);

    if (!this.focusedOnTable) {
      return;
    }
  }
  copyCells(cut?: boolean) {

    // console.info('copyCells');

    const rowIndexes = new Array<number>();
    const columnIndexes = new Array<number>();
    this.selectedRowColumnMaps.forEach(rc => {
      rc.rowIndexMap.forEach((value, key) => {
        rowIndexes.push(key);
      });
      rc.columnMap.forEach((value, key) => {
        for (const [index, v] of this.headerCodes.entries()) {
          if (v === value) {
            columnIndexes.push(index);
            break;
          }
        }
      });
    });
    rowIndexes.sort((a, b) => a >= b ? 1 : -1);
    columnIndexes.sort((a, b) => a >= b ? 1 : -1);


    // let header = '';
    // columnIndexes.forEach((columnIndex, i) => {
    //   header = header + this.headerCodes[columnIndex]
    //   if (i < columnIndexes.length - 1) {
    //     header = header + '\t';
    //   } else {
    //     header = header + '\n';
    //   }
    // });

    let contents = '';

    // contents = header;

    let lastRowIndex = -1;
    let lastColumnIndex = -1;

    for (const [i, rowIndex] of rowIndexes.entries()) {
      if (rowIndex === lastRowIndex) {
        continue;
      }
      lastRowIndex = rowIndex;

      let searchedInTheLine = false;
      const cntr = this.dataSource.filteredData[rowIndex];

      for (const [j, columnIndex] of columnIndexes.entries()) {
        if (columnIndex === lastColumnIndex && j > 0) {
          continue;
        }
        lastColumnIndex = columnIndex;

        let selected = false;
        this.selectedRowColumnMaps.forEach(rc => {

          for (const rowKey of rc.rowIndexMap.keys()) {

            if (rowKey === rowIndex) {

               for (const colKey of rc.columnMap.keys()) {

                 if (colKey === this.headerCodes[columnIndex]) {
                   selected = true;
                   break;
                 }
               }
               break;
            }
          }
        });

        if (selected) {
          const cntrValue = this.getValue4Copy(cntr, this.headerCodes[columnIndex]);
          if (searchedInTheLine) {
            contents = contents + '\t';
          }
          searchedInTheLine = true;
          contents = contents + cntrValue;
          if (cut) {
            const modified = this.setValue(cntr, this.headerCodes[columnIndex], '');
            if (modified) {
              cntr.modified = true;
              this.modifiedModels.select(cntr);
              this.isPropertyDirty = true;
            }
          }
        }
      }
      if (searchedInTheLine && i < rowIndexes.length - 1) {
        contents = contents + '\n';
      }

    }
    return contents;

  }
  paste(clip: string) {

    const lines: string[] = clip.split('\n');

    let minRowIndex = this.mousedownRowIndex;
    let minColIndex = 0;

    this.selectedRowColumnMaps.forEach(rowColumn => {

      const rowIndexMap = rowColumn.rowIndexMap;
      const columnMap = rowColumn.columnMap;

      rowIndexMap.forEach((value, key) => {
        if (key < this.mousedownRowIndex) {
          minRowIndex = key;
        }
      });

      for (let colIndex = 0; colIndex < this.headerCodes.length; colIndex++) {
        const columnCode = columnMap.get(this.headerCodes[colIndex]);
        if (columnCode) {
          minColIndex = colIndex;
          break;
        }
      }
    });

    this.deselectRow();

    const selectedRowIndexMap = new Map<number, number>();
    const selectedColumnMap = new Map<string, string>();

    const items = this.dataSource.filteredData;
    for (const line of lines) {
      let newLine = '';
      const words = line.split('\t');

      let colCounter = 0;
      for (const word of words)  {
        newLine = newLine + ',' + word;

        const columnCode = this.headerCodes[minColIndex + colCounter];

        selectedRowIndexMap.set(minRowIndex, minRowIndex);
        selectedColumnMap.set(columnCode, columnCode);

        const item = items[minRowIndex];

        item.modified = this.setValue4Paste(item, columnCode, word);
        if (item.modified) {
          this.modifiedModels.select(item);
          this.isPropertyDirty = true;
        }

        if (minColIndex + ++colCounter >= this.headerCodes.length) {
          break;
        }
      }
      if (++minRowIndex >= items.length) {
        break;
      }
    }
    this.selectedRowColumnMaps = new Array<RowColumnMap>();
    this.selectedRowColumnMaps.push(new RowColumnMap(selectedRowIndexMap, selectedColumnMap));
  }
  selectCells(index: number, column: EditableColumn) {

    const columnCode = column.code;

    const selectedRowIndexMap = new Map<number, number>();
    if (this.startItem == null) {
      return;
    }

    if (!(column.cellSelection || column.lineSelection)) {
      return;
    }
    if (!this.multiSelection) {
      this.mousedownRowIndex = index;
    }

    let asc = true;
    if (index < this.mousedownRowIndex) {
      asc = false;
    }

    if (asc) {
      for (let i = this.mousedownRowIndex; i <= index; i++) {
        selectedRowIndexMap.set(i, i);
      }
    } else {
      for (let i = this.mousedownRowIndex; i >= index; i--) {
        selectedRowIndexMap.set(i, i);
      }
    }
    if (!this.multiSelection && !column.cellSelection) {
      this.selectedRowColumnMaps[this.selectedRowColumnMaps.length - 1] = new RowColumnMap(selectedRowIndexMap, new Map<string, string>());
      this.checkSelectionBoxes();
      return;
    }

    const selectedColumnMap = new Map<string, string>();

    if (column.cellSelection) {
      let found = false;
      if (this.mousedownColumnCode === columnCode) {
        selectedColumnMap.set(columnCode, columnCode);
      } else {
        for (let i = 0 ; i < this.headerCodes.length; i++) {
          const code = this.headerCodes[i];
          if (!found && (code === this.mousedownColumnCode || code === columnCode)) {
            found = true;
            selectedColumnMap.set(code, code);
            continue;
          }
          if (found) {
            selectedColumnMap.set(code, code);
          }
          if (found && (code === this.mousedownColumnCode || code === columnCode)) {
            found = false;
            break;
          }
        }
        // for (const code of this.headerCodes) {
        //   if (!found && (code === this.mousedownColumnCode || code === columnCode)) {
        //     found = true;
        //     selectedColumnMap.set(code, code);
        //     continue;
        //   }
        //   if (found) {
        //     selectedColumnMap.set(code, code);
        //   }
        //   if (found && (code === this.mousedownColumnCode || code === columnCode)) {
        //     found = false;
        //     break;
        //   }
        // }
      }
    }

    this.selectedRowColumnMaps[this.selectedRowColumnMaps.length - 1] = new RowColumnMap(selectedRowIndexMap, selectedColumnMap);
    this.checkSelectionBoxes();

  }
  private checkSelectionBoxes() {
    this.selection.clear();

    for (let i = 0 ; i < this.selectedRowColumnMaps.length; i++) {
      const rowColumn = this.selectedRowColumnMaps[i];
      rowColumn.rowIndexMap.forEach((value, key) => {
        this.selection.select(this.dataSource.filteredData[key]);
      });
    }

    // this.selectionList.forEach(rowColumn => {
    //   rowColumn.rowIndexMap.forEach((value, key) => {
    //     this.selection.select(this.dataSource.data[key]);
    //   });
    // });
  }
  protected deselectRow() {
    this.mousedownRowIndex = -1;
    this.selectedRowColumnMaps = new Array<RowColumnMap>();
    this.focusedOnTable = false;
    this.selection.clear();
    this.startItem = null;
    this.mousedownColumnCode = '';
  }
  isSelectedCell( index: number, columnCode: string): boolean {

    let selected = false;
    for (let i = 0 ; i < this.selectedRowColumnMaps.length; i ++) {
      const rowColumn = this.selectedRowColumnMaps[i];
      const selectedRowIndexMap = rowColumn.rowIndexMap;
      const selectedColumnMap = rowColumn.columnMap;

      if (selectedColumnMap.get(columnCode)) {
        if (selectedRowIndexMap.get(index) > -1) {
          selected = true;
          return true;
        }
      }
    }
    // this.selectionList.forEach(rowColumn => {
    //   const selectedRowIndexMap = rowColumn.rowIndexMap;
    //   const selectedColumnMap = rowColumn.columnMap;
    //
    //   if (selectedColumnMap.get(columnCode)) {
    //     if (selectedRowIndexMap.get(index) > -1 ) {
    //       selected = true;
    //       return true;
    //     }
    //   }
    // });
    return selected;
  }
  mouseDownOnCell($event: MouseEvent, item: any, index: number, column: EditableColumn) {

    this.focusedOnTable = true;
    if ($event.button === 0 && (column.cellSelection || column.lineSelection)) {
      this.mouseDragging = true;
      this.startItem = item;
      this.mousedownRowIndex = index + this.paginator.pageIndex * this.paginator.pageSize;
      this.mousedownColumnCode = column.code;


      if (!(this.keyMap.get(Keys.Control) || this.keyMap.get(Keys.Shift)) || !this.multiSelection) {
        this.selectedRowColumnMaps = new Array<RowColumnMap>();
      }
      this.selectedRowColumnMaps.push(new RowColumnMap(new Map<number, number>(), new Map<string, string>()));
      this.selectCells(this.mousedownRowIndex, column);
      this.fireSelectedItem(this.mousedownRowIndex);
    }

  }

  mouseUpOnCell($event: MouseEvent, index: number, column: EditableColumn) {
    if ($event.button === 0) {
      this.mouseDragging = false;
      this.fireSelectedItem(index + this.paginator.pageIndex * this.paginator.pageSize);
    }
  }
  mouseEnterOnCell($event: MouseEvent, index: number, column: EditableColumn) {
    if (this.mouseDragging && $event.button === 0) {
      this.selectCells( index + this.paginator.pageIndex * this.paginator.pageSize, column);
      // this.fireSelectedItem(index);
      $event.preventDefault();
    }
  }
  mouseLeaveOnCell($event: MouseEvent, index: number, column: EditableColumn) {
    if (this.mouseDragging && $event.button === 0) {
      this.selectCells(index + this.paginator.pageIndex * this.paginator.pageSize, column);
      $event.preventDefault();
    }
  }
  keyDownOnCell(e: KeyboardEvent, element, i, column: EditableColumn) {
  }
  keypressOnCell(e: KeyboardEvent) {

    if (e.key === Keys.ArrowUp
      || e.key === Keys.ArrowDown
      || e.key === Keys.ArrowLeft
      || e.key === Keys.ArrowRight
      || e.key === Keys.Space
      || e.key === Keys.Tab
      || e.key === Keys.Delete
      || e.key === Keys.Escape
      || e.key === Keys.Enter
      || e.key === Keys.Shift
      || e.key === Keys.Control
      || e.key === Keys.Alt
    ) {

      e.preventDefault();
      return;
    }

    if (this.keyMap.get(Keys.ArrowUp)
      || this.keyMap.get(Keys.ArrowDown)
      || this.keyMap.get(Keys.ArrowLeft)
      || this.keyMap.get(Keys.ArrowRight)
      || this.keyMap.get(Keys.Tab)
      || this.keyMap.get(Keys.Delete)
      || this.keyMap.get(Keys.Escape)
      || this.keyMap.get(Keys.Enter)
      || this.keyMap.get(Keys.Control)
      || this.keyMap.get(Keys.Alt)
    ) {
      e.preventDefault();
      return;
    }

    if (e.key === ':' || e.key === '\'' || e.key === '+' || e.key === '^') {

      this.showSnackBar(e.key + ' is an unallowable character.');

      return;
    }
    const backspace = e.key === Keys.Backspace ? true : false;

    this.selectedRowColumnMaps.forEach(rowColumn => {

      const selectedRowIndexMap = rowColumn.rowIndexMap;
      const selectedColumnMap = rowColumn.columnMap;

      selectedRowIndexMap.forEach((rowVal, rowKey) => {

        const element = this.dataSource.filteredData[rowKey];

        selectedColumnMap.forEach((colVal, colKey) => {

          const value = this.getValue(element, colKey);
          let modified = false;
          if (backspace) {

            if (value.length > 0) {
              modified = this.setValue(element, colKey, value.substring(0, value.length - 1));
            } else {
              modified = this.setValue(element, colKey, '');
            }
          } else {
            modified = this.setValue(element, colKey, value + e.key);
          }

          this.dataSource.filteredData[rowKey].modified = modified;
          this.modifiedModels.select(element);
          this.isPropertyDirty = modified;
        });

        if (this.selectedRowColumnMaps.length === 1 && selectedRowIndexMap.size === 1) {
          this.fireSelectedItem(rowKey);
        }
      });

    });
  }
  mouseDownOnHeader(column: EditableColumn) {

    this.dataSource.sortData(this.dataSource.filteredData, this.sort);

    const columnCode = column.code;
    if (this.sortDescMap.get(columnCode)) {
      this.dataSource.filteredData.sort((a, b) => String(this.getValueFormatted(a, columnCode)).toUpperCase() < String(this.getValueFormatted(b, columnCode)).toUpperCase() ? 1 : -1);
      this.sortDescMap.set(columnCode, false);
    } else {
      this.dataSource.filteredData.sort((a, b) => String(this.getValueFormatted(a, columnCode)).toUpperCase() >= String(this.getValueFormatted(b, columnCode)).toUpperCase() ? 1 : -1);
      this.sortDescMap.set(columnCode, true);
    }

    this.deselectRow();
  }
  isReadOnlyRowSelected(row): boolean {
    let lineSelected = this.selection.isSelected(row);

    this.selectedRowColumnMaps.forEach(rowColumn => {
      if ( rowColumn.columnMap.size > 1) {
        lineSelected = false;
      }
      rowColumn.columnMap.forEach(columnCode => {

        for (const column of this.columns) {
          if (columnCode === column.code) {
            if (column.cellSelection) {
              lineSelected = false;
              break;
            }
          }
        }
      });
    });

    return lineSelected;
  }
  onContextMenu(event: MouseEvent, model: EditableModel) {
    event.preventDefault();
    // this.selection.clear();
    // this.selection.select(model);
    this.contextMenuOpened = true;
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item : model };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
    // console.info('onContextMenu');
  }

  executeCopy() {
    // console.info('copy');
    document.execCommand('copy');
    // console.info('copy');
  }
  executeCut() {
    document.execCommand('cut');
  }
  executePaste() {
    document.execCommand('paste');
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  checkboxLabel(row?: EditableModel) {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
  }

  queryEditableTable() {
    return this.elementRef.nativeElement.querySelector('#editTable');
  }
  getStyleClass(item: any, columnCode) {

    if (item === 'header') {
      return item + '-' + columnCode;
    }
    return 'etable-common-label';
  }
  getTdStyleClass(rowIndex: number, column: EditableColumn, item?: EditableModel) {
    return this.isSelectedCell(rowIndex + this.paginator.pageIndex * this.paginator.pageSize, column.code) ? (column.cellSelection ? (column.editable ? 'etable-select-td' : 'etable-select-td-ineditable') : '') : '';
  }
  getForeColor(item: EditableModel, column: EditableColumn) {
    return column.colorable ? item.foreColor : '';
  }
  getBackColor(item: EditableModel, column: EditableColumn) {
    return column.colorable ? item.backColor : '';
  }
  isColumnInEditMode(rowIndex: number, column: EditableColumn) {
    return (rowIndex + this.paginator.pageIndex * this.paginator.pageSize) === this.mousedownRowIndex && column.code === this.mousedownColumnCode && column.editable;
  }
  protected applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
  }
  search() {
    this.modifiedModels.clear();
  }

  fireSelectedItem(index: number) {
    // console.info('fireSelectedItem');
    // this.eventService.emit(EventIds.TABLE_ITEM_SELECTED, this.selection.selected, this.queryEditableTable());
  }
  setPropertyDirty(dirty: boolean) {

    this.isPropertyDirty = dirty;
  }
  getPropertyDirty(): boolean {
    return this.isPropertyDirty;
  }
  private showSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 2000,
    });
  }
  getValue4Copy(item: EditableModel, code: string) {
    return this.getValueFormatted(item, code);
  }
  setValue4Paste(item: EditableModel, code: string, value: string) {
    return this.setValue(item, code, value);
  }
  abstract getValue(item: EditableModel, code: string);
  abstract getValueFormatted(item: EditableModel, code: string);
  abstract setValue(item: EditableModel, code: string, value: string): boolean;
  abstract initColumns(): EditableColumn[];

  getSpecialKey(key: string): boolean {
    return this.keyMap.get(key);
  }
}
export class RowColumnMap {

  constructor(public rowIndexMap: Map<number, number>, public columnMap: Map<string, string>) {
  }
}
export enum Keys {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Space = 'Space',
  Tab = 'Tab',
  Delete = 'Delete',
  Escape = 'Escape',
  Backspace = 'Backspace',
  Enter = 'Enter',
  Shift = 'Shift',
  Control = 'Control',
  Alt = 'Alt'
}
