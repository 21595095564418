import {EditableModel} from '../../../../be-com/etable/editable-model';

export class Port implements EditableModel {

  id = '';
  code = '';
  ownCode = '';
  outCode = '';
  secondaryCode = '';
  name = '';
  countryCode = '';
  countryName = '';
  foreColor = '';
  backColor = '';
  foreColor2 = '';
  backColor2 = '';
  classCode: string;
  className: string;
  editType = '';
  timezone = '';
  portOrder = 0;
  initial = '';
  modified = false;

}
