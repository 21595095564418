import {Component, Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {MenuUrls} from './menu-urls';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService} from '../../user/service/auth.service';

@Injectable()
export class MenuService {
  constructor(private authService: AuthService,
              public translate: TranslateService,
              private iconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('bay_view'
      , this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/bay_view_16.svg'));
  }

  getAll() {

    const menus = new Array();

    if (this.authService.isMemberOrAdmin()) {
      menus.push({
        link: MenuUrls.FILE,
        label: this.translate.instant('MY_PLANS'),
        icon: 'web_asset',
      });
    }
    if (this.authService.isAuthenticated()) {
      menus.push({
        label: this.translate.instant('EDIT'),
        icon: 'edit_note',
        items: [
          {link: MenuUrls.EDIT_BAY, label: this.translate.instant('EDIT_BAY'), svgIcon: 'bay_view'},
          {link: MenuUrls.EDIT_LIST, label: this.translate.instant('EDIT_CARGO'), icon: 'view_list'},
          {link: MenuUrls.EDIT_SUMMARY, label: this.translate.instant('EDIT_SUMMARY'), icon: 'pivot_table_chart'},
          // { link: MenuUrls.EDIT_CHARTS, label: this.translate.instant('EDIT_CHARTS') },
        ]
      });
    }
    if (this.authService.isMemberOrAdmin()) {
      menus.push({
        label: this.translate.instant('CODES'),
        icon: 'list_alt',
        items: [
          {link: MenuUrls.CODE_OPERATOR, label: this.translate.instant('CARRIER')},
          {link: MenuUrls.CODE_PORT, label: this.translate.instant('PORT')},
          {link: MenuUrls.CODE_SERVICE_LANE, label: this.translate.instant('SERVICE_LANE')},
          {link: MenuUrls.CODE_WEIGHT_GROUP, label: this.translate.instant('WEIGHT_GROUP')},
          {link: MenuUrls.CODE_SIZE_TYPE, label: this.translate.instant('SIZE_TYPE')},
        ]
      });
      menus.push({
        link: MenuUrls.TEAMS,
        label: this.translate.instant('TEAMS'),
        icon: 'groups'
      });
    }

    if (this.authService.isAdmin()) {
      menus.push( {
        label: this.translate.instant('ADMIN'),
        icon: 'admin_panel_settings',
        items: [
          { link: MenuUrls.ADMIN_VESSEL_DESIGN, label: this.translate.instant('VESSEL_DESIGN') },
          { link: MenuUrls.SYSTEM_CODE, label: this.translate.instant('SYSTEM_CODE') },
          { link: MenuUrls.AGREEMENT_EDIT, label: this.translate.instant('AGREEMENT_EDIT') },
          { link: MenuUrls.CODE_VESSEL, label: this.translate.instant('VESSEL') },
          { link: MenuUrls.COLOR, label: this.translate.instant('COLOR') },
        ]
      });
    }

    return menus;

    // return [
    //   {
    //     link: '/myplans',
    //     label: this.translate.instant('MY_PLANS'),
    //     icon: 'web_asset',
    //   },
    //   {
    //     label: this.translate.instant('EDIT'),
    //     icon: 'edit_note',
    //     items: [
    //       { link: MenuUrls.EDIT_BAY, label: this.translate.instant('EDIT_BAY'), svgIcon: 'bay_view'},
    //       { link: MenuUrls.EDIT_LIST, label: this.translate.instant('EDIT_CARGO'), icon: 'view_list' },
    //       { link: MenuUrls.EDIT_SUMMARY, label: this.translate.instant('EDIT_SUMMARY'), icon: 'pivot_table_chart' },
    //       // { link: MenuUrls.EDIT_CHARTS, label: this.translate.instant('EDIT_CHARTS') },
    //     ]
    //   },
    //   {
    //     label: this.translate.instant('CODES'),
    //     icon: 'list_alt',
    //     items: [
    //       { link: MenuUrls.CODE_OPERATOR, label: this.translate.instant('CARRIER') },
    //       { link: MenuUrls.CODE_VESSEL, label: this.translate.instant('VESSEL') },
    //       { link: MenuUrls.CODE_PORT, label: this.translate.instant('PORT') },
    //       { link: MenuUrls.CODE_SERVICE_LANE, label: this.translate.instant('SERVICE_LANE') },
    //       { link: MenuUrls.CODE_WEIGHT_GROUP, label: this.translate.instant('WEIGHT_GROUP') },
    //       { link: MenuUrls.CODE_SIZE_TYPE, label: this.translate.instant('SIZE_TYPE') },
    //     ]
    //   },
    //   {
    //     link: '/teams',
    //     label: this.translate.instant('TEAMS'),
    //     icon: 'groups'
    //   },
    //   {
    //     label: this.translate.instant('ADMIN'),
    //     icon: 'admin_panel_settings',
    //     items: [
    //       { link: '/admin/vessel-design', label: this.translate.instant('VESSEL_DESIGN') },
    //       { link: '/admin/system-code', label: this.translate.instant('SYSTEM_CODE') },
    //       { link: '/admin/agreement-edit', label: this.translate.instant('AGREEMENT_EDIT') },
    //     ]
    //   },
    // ];
  }
}
