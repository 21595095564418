import {
  AdminLayoutComponent,
  AuthLayoutComponent,
  HeaderComponent,
  LayoutComponent,
  MenuComponent,
  OptionsComponent,
  SidebarComponent
} from './core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularSplitModule } from 'angular-split';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { BidiModule } from '@angular/cdk/bidi';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDividerModule} from '@angular/material/divider';
import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import { NgModule } from '@angular/core';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {AuthInterceptor} from './user/service/auth.interceptor.service';
import {MatPaginatorModule} from '@angular/material/paginator';
import { OkDialogComponent } from './dialogs/ok-dialog/ok-dialog.component';
import { OkSnackBarComponent } from './dialogs/ok-snack-bar/ok-snack-bar.component';
import { MarkersComponent } from './panels/markers/markers.component';
import { ContainerSummaryComponent } from './panels/container-summary/container-summary.component';
import { ConsoleComponent } from './panels/console/console.component';
import { BaplieHeaderComponent } from './panels/baplie-header/baplie-header.component';
import { BottomPanelComponent } from './panels/bottom-panel/bottom-panel.component';
import { RightPanelComponent } from './panels/right-panel/right-panel.component';
import { LegendComponent } from './panels/legend/legend.component';
import { SelectionsComponent } from './panels/selections/selections.component';
import { PropertiesComponent } from './panels/properties/properties.component';
import { PaletteComponent } from './panels/palette/palette.component';
import { ColumnsComponent } from './panels/columns/columns.component';
import { PreferencesComponent } from './panels/preferences/preferences.component';
import { HelpComponent } from './panels/help/help.component';
import { BayToolbarComponent } from './toolbars/bay-toolbar/bay-toolbar.component';
import { ContainerDetailsComponent } from './panels/properties/container-details/container-details.component';
import { ContainerEditComponent } from './panels/properties/container-edit/container-edit.component';
import { CodComponent } from './panels/cod/cod.component';
import { FilterComponent } from './panels/filter/filter.component';
import { EdiTextComponent } from './panels/edi-text/edi-text.component';
import { ContainerFilterComponent } from './panels/container-filter/container-filter.component';
import { ColumnInfoComponent } from './panels/columns/column-info/column-info.component';
import { ColumnEditComponent } from './panels/columns/column-edit/column-edit.component';
import { ContainerDialogComponent } from './panels/properties/container-dialog/container-dialog.component';
import { LegendContextMenuComponent } from './panels/legend/legend-context-menu/legend-context-menu.component';
import {ColorPickerModule} from 'ngx-color-picker';
import { ContainerFilterDialogComponent } from './panels/container-filter-dialog/container-filter-dialog.component';
import { RowColumnDialogComponent } from './edit/cargo-summary/row-column-dialog/row-column-dialog.component';
import { VesselEditComponent } from './panels/vessel-edit/vessel-edit.component';
import {LoadableSapceDialog} from './toolbars/bay-toolbar/loadable-sapce-dialog/loadable-sapce-dialog';
import { FooterComponent } from './core/footer/footer.component';
import {SharedEdiComponent} from './panels/shared-edi/shared-edi.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    OptionsComponent,
    MenuComponent,
    AdminLayoutComponent,
    LayoutComponent,
    AuthLayoutComponent,
    OkDialogComponent,
    OkSnackBarComponent,
    MarkersComponent,
    ContainerSummaryComponent,
    ConsoleComponent,
    BaplieHeaderComponent,
    BottomPanelComponent,
    RightPanelComponent,
    LegendComponent,
    SelectionsComponent,
    PropertiesComponent,
    PaletteComponent,
    ColumnsComponent,
    PreferencesComponent,
    HelpComponent,
    BayToolbarComponent,
    ContainerDetailsComponent,
    ContainerEditComponent,
    CodComponent,
    FilterComponent,
    EdiTextComponent,
    ContainerFilterComponent,
    ColumnInfoComponent,
    ColumnEditComponent,
    ContainerDialogComponent,
    LegendContextMenuComponent,
    ContainerFilterDialogComponent,
    RowColumnDialogComponent,
    VesselEditComponent,
    LoadableSapceDialog,
    FooterComponent,
    SharedEdiComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LoadingBarRouterModule,
    MatSidenavModule,
    MatCardModule,
    FlexLayoutModule,
    BidiModule,
    PerfectScrollbarModule,
    NgMaterialMultilevelMenuModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    AngularSplitModule,
    ColorPickerModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
