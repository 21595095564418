import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContextMenuModel} from './context-menu-model';

@Component({
  selector: 'app-legend-context-menu',
  templateUrl: './legend-context-menu.component.html',
  styleUrls: ['./legend-context-menu.component.css']
})
export class LegendContextMenuComponent implements OnInit {


  @Input()
  contextMenuItems: Array<ContextMenuModel>;

  @Output()
  onContextMenuItemClick: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  onContextMenuClick(event, data): any {
    this.onContextMenuItemClick.emit({
      event,
      data,
    });
  }
}
