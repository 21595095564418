<!--<div fxFill>-->
<!--  <mat-form-field>-->
<!--    <mat-label>Leave a comment</mat-label>-->
<!--    <textarea matInput placeholder="Ex. It makes me feel..."></textarea>-->
<!--  </mat-form-field>-->
<!--</div>-->

<!--    <mat-form-field>-->
<!--      <textarea style="background-color: #59abfd; width: 98%; height: {{bottomHeight}}px; margin-left: 10px;font-family: 'Courier';" matInput [value]="consoleText"></textarea>-->
<!--    </mat-form-field>-->


<textarea id="console"
          style="width: 98%; height: {{bottomHeight-5}}px; margin-left: 10px;font-family: 'Courier';"
          matInput [value]="consoleText" (contextmenu)="onContextMenu($event)"></textarea>
<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="tableContextMenu">
</div>
<mat-menu #tableContextMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="clear(item)"><mat-icon>clear</mat-icon>Clean</button>
  </ng-template>
</mat-menu>

