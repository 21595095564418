<table mat-table [dataSource]="dataSource"
       class="mat-elevation-z3" class="topics tr">
  <ng-container *ngFor="let column of columnNames; let j = index"  matColumnDef="{{column}}">
    <th mat-header-cell *matHeaderCellDef
        matTooltipClass="tooltip"
        class="header">
      <div class="header">{{column}}<span *ngIf="j === 3 || j === 4"> at</span></div>
    </th>
    <td mat-cell *matCellDef="let element; let i = index"  style="vertical-align: center">
      <ng-container *ngIf="column !== 'Expire'">
        <span *ngIf="j > 0" class="common-label-center">{{getValue(element, column)}}</span>
        <span *ngIf="j == 0" class="common-label-center">{{i + 1}}</span>
      </ng-container>
      <ng-container *ngIf="column === 'Expire'">
        <button mat-stroked-button type="submit" (click)="expire(element.id)" color="basic" class="expire">Expire</button>
      </ng-container>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnNames; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>
</table>
