import {Location} from './location';
import {Cargo} from './cargo';
import {Freetext} from './freetext';
import {Measurement} from './measurement';
import {Dimension} from './dimension';
import {Place} from './place';
import {Reference} from './reference';
import {ContainerEquipment} from './container-equipment';
import {Dangerous} from './dangerous';
import {CargoNature} from './cargo-nature';

export class Stowage {
  location: Location;
  cargo: Cargo;
  cargoNatures: Array<CargoNature>;
  remarks: Array<Freetext>;
  weights: Array<Measurement>;
  dimensions: Array<Dimension>;
  temperature: Measurement;
  rangeDetail: Measurement;
  places: Array<Place>;
  references: Array<Reference>;
  containers: Array<ContainerEquipment>;
  dangerousGoods: Array<Dangerous>;

  lineIndex: number;
  extraElements = new Array();

  constructor() {
    this.location = new Location();
    this.cargo = new Cargo();
    this.cargoNatures = new Array<CargoNature>();
    this.remarks = new Array<Freetext>();
    this.weights = new Array<Measurement>();
    this.dimensions = new Array<Dimension>();
    this.temperature = new Measurement();
    this.rangeDetail = new Measurement();
    this.places = new Array<Place>();
    this.references = new Array<Reference>();
    this.containers = new Array<ContainerEquipment>();
    this.dangerousGoods = new Array<Dangerous>();
  }
}
