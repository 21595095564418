import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EventService} from '../../core/event/event.service';
import {EventIds} from '../../core/event/event-ids';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {ConsoleService} from '../../service/console.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {DatePipe} from '@angular/common';
// import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.css']
})
export class ConsoleComponent implements OnInit, OnDestroy {
  consoleText = '';

  @Input()
  bottomHeight = 200;
  @Input()
  tabEmitter: EventEmitter<MatTabChangeEvent>;
  private eventSubscription;
  private tabSubscription;

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  constructor(private eventService: EventService,
              protected elementRef: ElementRef,
              private consoleService: ConsoleService) {

  }

  ngOnInit(): void {

    this.eventSubscription = this.eventService.getEmitter().subscribe(event => {

      if (event.id === EventIds.CONSOLE_LOGGING) {

        this.log(event.newValue, event.source) ;

        const textArea = this.elementRef.nativeElement.querySelector('#console');

        textArea.scrollbarY = 100;
      }
    });

    const date = new Date();

    const logTime = date.toLocaleString();
    this.consoleService.get().subscribe(logs => {
      this.consoleText = logs;
    });
  }
  private log(log: string, type: string) {

    // const newLog = new Date().toLocaleString() + ' [' + type + '] ' + log + '\n';
    // const newLog = this.getNow() + ' [' + type + '] ' + log + '\n';
    const newLog = this.getNow() + ' [' + type + '] ' + log + '\n';

    this.consoleText = this.consoleText + newLog;
    this.consoleService.add(newLog);
  }
  private getNow(): string {
    const date = new Date();
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }
  onContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    // this.contextMenu.menuData = { item : vesselSchedule };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }
  clear(item: any) {
    this.consoleText = '';
    this.consoleService.delete();
  }
}
