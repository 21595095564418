import xml2js from 'xml2js';
import {BayPlan} from '../../service/model/bay-plan';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Container} from '../../service/model/container';
import {Dangerous} from './model/dangerous';
import {SyntaxMessage} from './model/syntax-message';
import {SyntaxMessageError} from './model/syntax-message-error';
import {MarkersProperty} from '../../panels/markers/model/markers.property';
import {DangerousRemark} from './model/dangerous-remark';
import {Freetext} from './model/freetext';

@Injectable({
  providedIn: 'root'
})
export class EdiReaderService {

  private parser: xml2js;

  constructor(private http: HttpClient) {
    this.parser = new xml2js.Parser({trim: true, explicitArray: true});
  }

  async parseText2BayPlan(text: string, serviceLane?: string, id?: string, updateDatetime?: string): Promise<BayPlan> {
    const bayPlan: BayPlan = new BayPlan();
    const lines: string[] = text.split('\'');
    if (lines.length < 2) {
      return bayPlan;
    }
    const header = lines[1].split('+')[lines[1].split('+').length - 1];
    const schema = await this.getSchema(header).toPromise();

    const self = this;
    this.parser.parseString(schema, function _(err, syntax) {
      const value = new ParsingValue();
      value.handle = 'LOA';
      value.dgSegment = false;
      value.elementTypeCode = '';
      let index = 0;
      let stowage = '';
      for (const line of lines) {
        const segmentLine: string[] = line.split('+');
        if (segmentLine.length > 1 && segmentLine[0] === 'HAN') {
          value.handle = segmentLine[1];
        }
        if (segmentLine.length > 2 && segmentLine[0].trim() === 'LOC' && segmentLine[1].trim() === '147') {
          const stowageLines: string[] = segmentLine[2].split(':');
          if (stowageLines.length > 0) {
            stowage = stowageLines[0].trim();
          }
        }
        self.translate(line, bayPlan, syntax, value, index++, stowage);
      }
      if (value.container && value.dangerous) {
        value.container.dangerousGoods.push(value.dangerous);
      } else if (value.container) {
        bayPlan.containers.push(value.container);
      }
    });
    if (id) {
      bayPlan.header.id = id;
    }
    if (updateDatetime) {
      bayPlan.header.updateDatetime = updateDatetime;
    }
    if (serviceLane) {
      bayPlan.header.serviceLaneCode = serviceLane;
    }
    return bayPlan;
  }

  private translate(line: string, bayPlan: BayPlan, syntax, parsingValue: ParsingValue, index: number, stowage: string) {
    const syntaxMessage = new SyntaxMessage();
    const segmentLine: string[] = line.split('+');
    const item = this.findSegmentSyntax(segmentLine, syntax, parsingValue);
    if (item) {
      let segmentIndex = 0;
      for (const segmentItem of item.segment) {
        segmentIndex += segmentItem.$.symbol ? Number(segmentItem.$.symbol) : 1;
        if (segmentLine.length <= segmentIndex) {
          break;
        }
        if (segmentItem.element) {
          segmentIndex += segmentItem.element[0].$.symbol ? Number(segmentItem.element[0].$.symbol) - 1 : 0;
          if (segmentLine.length <= segmentIndex) {
            break;
          }
          const elementLines: string[] = segmentLine[segmentIndex].split(':');
          let elementIndex = -1;
          for (const elementItem of segmentItem.element) {
            elementIndex += (elementIndex > -1 && elementItem.$.symbol) ? Number(elementItem.$.symbol) : 1;
            if (elementLines.length <= elementIndex) {
              break;
            }
            this.putBayPlan(elementItem, segmentLine, elementLines[elementIndex], bayPlan, parsingValue);
            this.validationSchema(elementItem, elementLines[elementIndex], syntaxMessage, stowage);
          }
        } else {
          this.putBayPlan(segmentItem, segmentLine, segmentLine[segmentIndex], bayPlan, parsingValue);
          this.validationSchema(segmentItem, segmentLine[segmentIndex], syntaxMessage, stowage);
        }
      }
    } else {
      parsingValue.container.extraElements.push(line);
    }
    if (segmentLine.length > 1 && segmentLine[0].trim() === 'LOC' && (segmentLine[1].trim() === 'BE' || segmentLine[1].trim() === 'PB')) {
      return;
    }
    if (syntaxMessage.errorMessageArray.length > 0) {
      syntaxMessage.lineText = line.trim();
      syntaxMessage.lineIndex = index;
      bayPlan.syntaxViolations.push(syntaxMessage);
    }
  }
  private validationSchema(syntax, value, syntaxMessage: SyntaxMessage, stowage: string) {
    if (!syntax || !value) {
      return;
    }
    if (syntax.$.length && (syntax.$.length < value.length && (isNaN(Number(value)) || syntax.$.length < Math.abs(Number(value)).toString().replace('.', '').length))) {
      const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_SYNTAX);
      error.syntaxName = MarkersProperty.ITEM_SYNTAX_LENGTH;
      error.description = value + ' (' + syntax.$.name + ': ' + syntax.$.length + ')';
      error.stowage = stowage;
      syntaxMessage.errorMessageArray.push(error);
    }
    if (syntax.$.type && syntax.$.type === 'n' && isNaN(Number(value))) {
      const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_SYNTAX);
      error.syntaxName = MarkersProperty.ITEM_SYNTAX_NUMBER;
      error.description = value + ' (' + syntax.$.name + ')';
      error.stowage = stowage;
      syntaxMessage.errorMessageArray.push(error);
    }
    if (syntax.$.constant && syntax.$.constant !== value) {
      const error: SyntaxMessageError = new SyntaxMessageError(MarkersProperty.CATEGORY_SYNTAX);
      error.syntaxName = MarkersProperty.ITEM_SYNTAX_CONSTANTS;
      error.description = value + ' (' + syntax.$.name + ': ' + syntax.$.constant + ')';
      error.stowage = stowage;
      syntaxMessage.errorMessageArray.push(error);
    }
  }
  private putBayPlan(item, segmentLine: string[], value: string, bayPlan: BayPlan, parsingValue: ParsingValue) {
    if (segmentLine[0].trim() === 'UNB') {
      if (item.$.id === 's002.e0004') {
        bayPlan.header.sender = value;
      } else if (item.$.id === 's003.e0010') {
        bayPlan.header.recipient = value;
      } else if (item.$.id === 'e0017') {
        bayPlan.header.preparationDate = value;
      } else if (item.$.id === 'e0019') {
        bayPlan.header.preparationTime = value;
      } else if (item.$.id === 'e0020') {
        bayPlan.header.interchangeControlRefStart = value;
      } else if (item.$.id === 'e0032') {
        bayPlan.header.communicationsAgreementId = value;
      }
    } else if (segmentLine[0].trim() === 'UNH') {
      if (item.$.id === 'e0062') {
        bayPlan.header.messageRefNo = value;
      } else if (item.$.id === 'e0065') {
        bayPlan.header.messageType = value;
      } else if (item.$.id === 'e0052') {
        bayPlan.header.versionNo = value;
      } else if (item.$.id === 'e0054') {
        bayPlan.header.releaseNo = value;
      } else if (item.$.id === 'e0057') {
        bayPlan.header.smdgVersion = value;
      }
    } else if (segmentLine[0].trim() === 'BGM') {
      if (item.$.id === 'e1001') {
        parsingValue.elementTypeCode = value;
      } else if (item.$.id === 'e1000') {
        bayPlan.header.documentName = value;
      } else if (item.$.id === 'e1004') {
        bayPlan.header.documentNo = value;
      } else if (item.$.id === 'e1056') {
        bayPlan.header.documentVersion = value;
      } else if (item.$.id === 'e1056') {
        bayPlan.header.documentRevision = value;
      } else if (item.$.id === 'e1225') {
        bayPlan.header.messageFunction = value;
      } else if (item.$.id === 'e4343') {
        bayPlan.header.documentResponseType = value;
      } else if (item.$.id === 'e1373') {
        bayPlan.header.documentStatus = value;
      }
    } else if (segmentLine[0].trim() === 'DTM' && segmentLine[1].split(':')[0].trim() === '137') {
      if (item.$.id === 'e2380') {
        bayPlan.header.compilationDatetime = value;
      } else if (item.$.id === 'e2379') {
        bayPlan.header.compilationDateTimeFormat = value;
      } else if (item.$.id === 'e1153') {
        bayPlan.header.referenceType = value;
      } else if (item.$.id === 'e1154') {
        bayPlan.header.referenceId = value;
      } else if (item.$.id === 'e1156') {
        bayPlan.header.referenceDocument = value;
      } else if (item.$.id === 'e1056') {
        bayPlan.header.referenceVersion = value;
      } else if (item.$.id === 'e1060') {
        bayPlan.header.referenceRevision = value;
      }
    } else if (segmentLine[0].trim() === 'TDT') {
      if (item.$.id === 'e8028') {
        bayPlan.header.conveyanceRefNo = value;
        bayPlan.header.voyageNo = value;
      } else if (item.$.id === 'e3127' || item.$.id === 'e3127_15') {
        bayPlan.header.carrier = value;
      } else if (item.$.id === 'e3055' || item.$.id === 'e3055_15') {
        bayPlan.header.transportCarrierResponsible = value;
      } else if (item.$.id === 'e8213' || item.$.id === 'e8213_15') {
        bayPlan.header.vesselCode = value;
      } else if (item.$.id === 'c222.e1131') {
        bayPlan.header.vesselCodeType = value;
        if (bayPlan.header.vesselCodeType === 'CALLSIGN' || bayPlan.header.vesselCodeType === '103') {
          bayPlan.header.callSign = bayPlan.header.vesselCode;
        } else if (bayPlan.header.vesselCodeType === 'IMO' || bayPlan.header.vesselCodeType === '146') {
          bayPlan.header.imoNo = bayPlan.header.vesselCode;
        }
      } else if (item.$.id === 'c222.e3055') {
        bayPlan.header.transportResponsible = value;
      } else if (item.$.id === 'e8212' || item.$.id === 'e8212_15') {
        bayPlan.header.vesselName = value;
      } else if (item.$.id === 'e8453' || item.$.id === 'e8453_15') {
        bayPlan.header.transportCountryCode = value;
      }
    } else if (segmentLine[0].trim() === 'LOC' && (segmentLine[1].trim() === '5' || segmentLine[1].trim() === '61' || segmentLine[1].trim() === 'BE' || segmentLine[1].trim() === 'PB')) {
      if (item.$.id === 'e3227') {
        parsingValue.elementTypeCode = value;
      } else if (item.$.id === 'e3225' && parsingValue.elementTypeCode === '5') {
        bayPlan.header.portCode = value;
      } else if (item.$.id === 'c517.e3055' && parsingValue.elementTypeCode === '5') {
        bayPlan.header.portResponsibleAgency = value;
      } else if (item.$.id === 'e3223' && parsingValue.elementTypeCode === '5') {
        bayPlan.header.portCountryId = value;
      } else if (item.$.id === 'e3223' && parsingValue.elementTypeCode === '5') {
        bayPlan.header.portStateId = value;
      } else if (item.$.id === 'e3225' && parsingValue.elementTypeCode === '61') {
        bayPlan.header.nextPortCode = value;
      } else if (item.$.id === 'c517.e3055' && parsingValue.elementTypeCode === '61') {
        bayPlan.header.nextPortResponsibleAgency = value;
      } else if (item.$.id === 'e3223' && parsingValue.elementTypeCode === '61') {
        bayPlan.header.nextPortCountryId = value;
      } else if (item.$.id === 'e3223' && parsingValue.elementTypeCode === '61') {
        bayPlan.header.nextPortStateId = value;
      } else if (item.$.id === 'e3225' && (parsingValue.elementTypeCode === 'BE' || parsingValue.elementTypeCode === 'PB')) {
        const beInfo = value.split('^');
        if (beInfo.length > 0) {
          bayPlan.header.id = beInfo[0];
        }
        if (beInfo.length > 1) {
          bayPlan.header.updateDatetime = beInfo[1];
        }
        if (beInfo.length > 2) {
          bayPlan.header.serviceLaneCode = beInfo[2];
        }
      }
    } else if (segmentLine[0].trim() === 'LOC' && (segmentLine[1].trim() === 'BE' || segmentLine[1].trim() === 'PB') && item.$.id === 'e3225') {
      const beInfo = value.split('^');
      if (beInfo.length > 0) {
        bayPlan.header.id = beInfo[0];
      }
      if (beInfo.length > 1) {
        bayPlan.header.updateDatetime = beInfo[1];
      }
      if (beInfo.length > 2) {
        bayPlan.header.serviceLaneCode = beInfo[2];
      }
    } else if (segmentLine[0].trim() === 'DTM') {
      if (item.$.id === 'e2005') {
        parsingValue.elementTypeCode = value;
      } else if (item.$.id === 'e2380' && parsingValue.elementTypeCode === '131') {
        bayPlan.header.ata = value;
      } else if (item.$.id === 'e2380' && parsingValue.elementTypeCode === '132') {
        bayPlan.header.nextEta = value;
      } else if (item.$.id === 'e2380' && parsingValue.elementTypeCode === '133') {
        bayPlan.header.etd = value;
      } else if (item.$.id === 'e2380' && parsingValue.elementTypeCode === '134') {
        bayPlan.header.atd = value;
      }
    } else if (segmentLine[0].trim() === 'RFF' && segmentLine[1].split(':')[0].trim() === 'VON') {
      bayPlan.header.loadingVoyageNo = value;
    } else if (segmentLine[0].trim() === 'HAN') {
      parsingValue.handle = value;
    } else if (segmentLine[0].trim() === 'LOC' && segmentLine[1].trim() === '147') {
      parsingValue.dgSegment = false;
      if (item.$.id === 'e3225') {
        if (parsingValue.container.stowage) {
          if (parsingValue.container && parsingValue.dangerous) {
            parsingValue.container.dangerousGoods.push(parsingValue.dangerous);
          }
          if (parsingValue.container.leadingStowage && parsingValue.container.stowage !== parsingValue.container.leadingStowage) {
            bayPlan.containers = this.resetOverSlot(bayPlan.containers, parsingValue.container);
          } else {
            bayPlan.containers.push(parsingValue.container);
          }
        }
        parsingValue.dgSegment = false;
        parsingValue.elementTypeCode = '';
        parsingValue.container = new Container();
        parsingValue.container = new Container();
        parsingValue.dangerous = undefined;
        parsingValue.container.stowage = value;
      }
      if (item.$.id === 'e3055' && parsingValue.container) {
        parsingValue.container.stowageFormat = value;
      }
    } else if (segmentLine[0].trim() === 'GID') {
      if (item.$.id === 'e7224') {
        parsingValue.container.packageQuantity = value;
      } else if (item.$.id === 'e7065') {
        parsingValue.container.packageType = value;
      }
    } else if (segmentLine[0].trim() === 'GDS') {
      parsingValue.container.cargoNatures.push(value);
    } else if (segmentLine[0].trim() === 'FTX' && !parsingValue.dgSegment) {

      if (item.$.id === 'e4451') {
        parsingValue.elementTypeCode = value;
      } else if (item.$.id === 'c107.4441') {
        if (parsingValue.elementTypeCode === 'AAY') {
          parsingValue.container.vgmCertificationCode = value;
        }
      } else if (item.$.id === 'e4440.1' || item.$.id === 'c108.4440') {
        if (parsingValue.elementTypeCode === 'AAA') {
          parsingValue.container.descriptionOfGoods = this.addRemarkText(parsingValue.container.descriptionOfGoods, value);
        } else if (parsingValue.elementTypeCode === 'HAN') {
          parsingValue.container.handlingInstruction = this.addRemarkText(parsingValue.container.handlingInstruction, value);
        } else if (parsingValue.elementTypeCode === 'CLR') {
          parsingValue.container.loadingRemark = this.addRemarkText(parsingValue.container.loadingRemark, value);
        } else if (parsingValue.elementTypeCode === 'AAI') {
          parsingValue.container.generalInformation = this.addRemarkText(parsingValue.container.generalInformation, value);
        } else if (parsingValue.elementTypeCode === 'ZZZ') {
          parsingValue.container.mutuallyDefinedRemark = this.addRemarkText(parsingValue.container.mutuallyDefinedRemark, value);
        } else if (parsingValue.elementTypeCode === 'AAY') {
          parsingValue.container.vgmInfo1 = this.addRemarkText(parsingValue.container.vgmInfo1, value);
        }
      } else if (parsingValue.elementTypeCode === 'AAY' && item.$.id === 'e4440.2') {
        parsingValue.container.vgmInfo2 = this.addRemarkText(parsingValue.container.vgmInfo2, value);
      } else if (parsingValue.elementTypeCode === 'AAY' && item.$.id === 'e4440.3') {
        parsingValue.container.vgmInfo3 = this.addRemarkText(parsingValue.container.vgmInfo3, value);
      } else if (parsingValue.elementTypeCode === 'AAY' && item.$.id === 'e4440.4') {
        parsingValue.container.vgmInfo4 = this.addRemarkText(parsingValue.container.vgmInfo4, value);
      } else if (parsingValue.elementTypeCode === 'AAY' && item.$.id === 'e4440.5') {
        parsingValue.container.vgmInfo5 = this.addRemarkText(parsingValue.container.vgmInfo5, value);
      }

      if (item.$.id === 'e4451') {
        parsingValue.elementTypeCode = value;
        let remarks = parsingValue.container.freeTexts.get(value);
        if (!remarks || remarks === null) {
          remarks = new Array<Freetext>();
        }
        const remark = new Freetext();
        remark.type = value;
        remarks.push(remark);
        parsingValue.container.freeTexts.set(value, remarks);
      } else if (parsingValue.elementTypeCode) {
        const textArray = parsingValue.container.freeTexts.get(parsingValue.elementTypeCode);
        if (textArray && textArray !== null && textArray.length > 0) {
          const freeText = textArray[textArray.length - 1];
          if (item.$.id === 'c107.4441') {
            freeText.vgmCertificationCode = value;
          } else if (item.$.id === 'e4440.1' || item.$.id === 'c108.4440') {
            freeText.remark = value;
          } else if (item.$.id === 'e4440.2') {
            freeText.vgmInfo2 = value;
          } else if (item.$.id === 'e4440.3') {
            freeText.vgmInfo3 = value;
          } else if (item.$.id === 'e4440.4') {
            freeText.vgmInfo4 = value;
          } else if (item.$.id === 'e4440.5') {
            freeText.vgmInfo5 = value;
          }
        }
      }

    } else if (segmentLine[0].trim() === 'MEA') {
      if (item.$.id === 'e6311') {
        parsingValue.elementTypeCode = value;
      }
      if (parsingValue.elementTypeCode === 'VGM') {
        if (item.$.id === 'e6411') {
          parsingValue.container.vgmWeightUnit = value;
        } else if (item.$.id === 'e6314') {
          parsingValue.container.vgmWeight = value;
        }
      } else {
        if (item.$.id === 'e6411') {
          parsingValue.container.grossWeightUnit = value;
        } else if (item.$.id === 'e6314') {
          parsingValue.container.grossWeight = value;
        }
      }
    } else if (segmentLine[0].trim() === 'DIM') {
      if (item.$.id === 'e6145') {
        parsingValue.elementTypeCode = value;
      }
      if (parsingValue.elementTypeCode === '5') {
        if (item.$.id === 'e6411') {
          parsingValue.container.overDimFrontUnit = value;
        } else if (item.$.id === 'e6168') {
          parsingValue.container.overDimFront = value;
        }
      } else if (parsingValue.elementTypeCode === '6') {
        if (item.$.id === 'e6411') {
          parsingValue.container.overDimBackUnit = value;
        } else if (item.$.id === 'e6168') {
          parsingValue.container.overDimBack = value;
        }
      } else if (parsingValue.elementTypeCode === '7') {
        if (item.$.id === 'e6411') {
          parsingValue.container.overDimRightUnit = value;
        } else if (item.$.id === 'e6140') {
          parsingValue.container.overDimRight = value;
        }
      } else if (parsingValue.elementTypeCode === '8') {
        if (item.$.id === 'e6411') {
          parsingValue.container.overDimLeftUnit = value;
        } else if (item.$.id === 'e6140') {
          parsingValue.container.overDimLeft = value;
        }
      } else if (parsingValue.elementTypeCode === '9') {
        if (item.$.id === 'e6411') {
          parsingValue.container.overDimHeightUnit = value;
        } else if (item.$.id === 'e6008') {
          parsingValue.container.overDimHeight = value;
        }
      }
    } else if (segmentLine[0].trim() === 'TMP') {
      if (item.$.id === 'e6246') {
        parsingValue.container.temperature = value;
      } else if (item.$.id === 'e6411') {
        parsingValue.container.temperatureUnit = value;
      }
    } else if (segmentLine[0].trim() === 'RNG') {
      if (item.$.id === 'e6411') {
        parsingValue.container.temperatureRangeUnit = value;
      } else if (item.$.id === 'e6162') {
        parsingValue.container.temperatureRangeMin = value;
      } else if (item.$.id === 'e6152') {
        parsingValue.container.temperatureRangeMax = value;
      } else if (item.$.id === 'e6167') {
        parsingValue.container.temperatureRangeType = value;
      }
    } else if (segmentLine[0].trim() === 'LOC') {
      if (item.$.id === 'e3227') {
        parsingValue.elementTypeCode = value;
      }
      if (parsingValue.elementTypeCode === '9' || parsingValue.elementTypeCode === '6') {
        if (item.$.id === 'e3225') {
          parsingValue.container.pol = value;
        } else if (item.$.id === 'c517.e3055') {
          parsingValue.container.polAgency = value;
        } else if (item.$.id === 'e3223') {
          parsingValue.container.polTerminalCode = value;
        }
      } else if (parsingValue.elementTypeCode === '11' || parsingValue.elementTypeCode === '12') {
        if (item.$.id === 'e3225') {
          parsingValue.container.pod = value;
        } else if (item.$.id === 'c517.e3055') {
          parsingValue.container.podAgency = value;
        } else if (item.$.id === 'e3223') {
          parsingValue.container.podTerminalCode = value;
        }
      } else if (parsingValue.elementTypeCode === '13') {
        if (item.$.id === 'e3225') {
          parsingValue.container.tsPort = value;
        } else if (item.$.id === 'c517.e3055') {
          parsingValue.container.tsPortAgency = value;
        } else if (item.$.id === 'e3223') {
          parsingValue.container.tsTerminalCode = value;
        }
      } else if (parsingValue.elementTypeCode === '64' || parsingValue.elementTypeCode === '63') {
        if (item.$.id === 'e3225') {
          parsingValue.container.optPod1 = value;
        } else if (item.$.id === 'c517.e3055') {
          parsingValue.container.optPod1Agency = value;
        } else if (item.$.id === 'e3223') {
          parsingValue.container.optPod1TerminalCode = value;
        }
      } else if (parsingValue.elementTypeCode === '68') {
        if (item.$.id === 'e3225') {
          parsingValue.container.optPod2 = value;
        } else if (item.$.id === 'c517.e3055') {
          parsingValue.container.optPod2Agency = value;
        } else if (item.$.id === 'e3223') {
          parsingValue.container.optPod2TerminalCode = value;
        }
      } else if (parsingValue.elementTypeCode === '70' || parsingValue.elementTypeCode === '69') {
        if (item.$.id === 'e3225') {
          parsingValue.container.optPod3 = value;
        } else if (item.$.id === 'c517.e3055') {
          parsingValue.container.optPod3Agency = value;
        } else if (item.$.id === 'e3223') {
          parsingValue.container.optPod3TerminalCode = value;
        }
      } else if (parsingValue.elementTypeCode === '71') {
        if (item.$.id === 'e3225') {
          parsingValue.container.optPod4 = value;
        } else if (item.$.id === 'c517.e3055') {
          parsingValue.container.optPod4Agency = value;
        } else if (item.$.id === 'e3223') {
          parsingValue.container.optPod4TerminalCode = value;
        }
      } else if (parsingValue.elementTypeCode === '74') {
        if (item.$.id === 'e3225') {
          parsingValue.container.optPod5 = value;
        } else if (item.$.id === 'c517.e3055') {
          parsingValue.container.optPod5Agency = value;
        } else if (item.$.id === 'e3223') {
          parsingValue.container.optPod5TerminalCode = value;
        }
      } else if (parsingValue.elementTypeCode === '76' || parsingValue.elementTypeCode === '80') {
        if (item.$.id === 'e3225') {
          parsingValue.container.por = value;
        } else if (item.$.id === 'c517.e3055') {
          parsingValue.container.porAgency = value;
        } else if (item.$.id === 'e3223') {
          parsingValue.container.porTerminalCode = value;
        }
      } else if (parsingValue.elementTypeCode === '83') {
        if (item.$.id === 'e3225') {
          parsingValue.container.placeOfDelivery = value;
        } else if (item.$.id === 'c517.e3055') {
          parsingValue.container.placeOfDeliveryAgency = value;
        } else if (item.$.id === 'e3223') {
          parsingValue.container.placeOfDeliveryTerminalCode = value;
        }
      } else if (parsingValue.elementTypeCode === '97') {
        if (item.$.id === 'e3225') {
          parsingValue.container.optPod = value;
        } else if (item.$.id === 'c517.e3055') {
          parsingValue.container.optPodAgency = value;
        } else if (item.$.id === 'e3223') {
          parsingValue.container.optPodTerminalCode = value;
        }
      } else if (parsingValue.elementTypeCode === '152') {
        if (item.$.id === 'e3225') {
          parsingValue.container.nPod = value;
        } else if (item.$.id === 'c517.e3055') {
          parsingValue.container.nPodAgency = value;
        } else if (item.$.id === 'e3223') {
          parsingValue.container.nPodTerminalCode = value;
        }
      } else if (parsingValue.elementTypeCode === '65') {
        if (item.$.id === 'e3225') {
          parsingValue.container.fPod = value;
        } else if (item.$.id === 'c517.e3055') {
          parsingValue.container.fPodAgency = value;
        } else if (item.$.id === 'e3223') {
          parsingValue.container.fPodTerminalCode = value;
        }
      }
    } else if (segmentLine[0].trim() === 'RFF') {
      if (item.$.id === 'e1153') {
        parsingValue.elementTypeCode = value;
      }
      if (item.$.id === 'e1154') {
        if (parsingValue.elementTypeCode === 'BM') {
          parsingValue.container.blNo = value;
        } else if (parsingValue.elementTypeCode === 'BN') {
          parsingValue.container.bookingNo = value;
        } else if (parsingValue.elementTypeCode === 'ET') {
          parsingValue.container.leadingStowage = value;
        }
      }
    } else if (segmentLine[0].trim() === 'EQD') {
      if (item.$.id === 'e8053') {
        parsingValue.container.equipmentType = value;
      } else if (item.$.id === 'c237.e8260') {
        parsingValue.container.containerNo = value;
      } else if (item.$.id === 'c224.e8155' || item.$.id === 'e8155') {
        parsingValue.container.isoSizeType = value;
      } else if (item.$.id === 'e8249') {
        parsingValue.container.deliveryCode = value;
      } else if (item.$.id === 'e8169') {
        parsingValue.container.fullEmpty = value;
        if (parsingValue.container.fullEmpty === '4') {
          parsingValue.container.fullEmpty = 'M';
        } else if (parsingValue.container.fullEmpty === '5') {
          parsingValue.container.fullEmpty = 'F';
        }
      }
    } else if (segmentLine[0].trim() === 'EQA') {
      if (item.$.id === 'c237.e8260') {
        parsingValue.container.bundledContainers.push(value);
      }
    } else if (segmentLine[0].trim() === 'NAD') {
      if (item.$.id === 'e3035') {
        parsingValue.container.carrierParty = value;
      } else if (item.$.id === 'e3039') {
        parsingValue.container.carrier = value;
      } else if (item.$.id === 'e3055') {
        parsingValue.container.carrierAgency = value;
      }
    } else if (segmentLine[0].trim() === 'DGS') {
      parsingValue.dgSegment = true;
      if (item.$.id === 'e8351') {
        if (parsingValue.dangerous) {
          parsingValue.container.dangerousGoods.push(parsingValue.dangerous);
        }
        parsingValue.dangerous = new Dangerous();
        parsingValue.dangerous.imdg = value;

      } else if (!parsingValue.dangerous) {
      } else if (item.$.id === 'e8078') {
        parsingValue.dangerous.hazardPageNo = value;
      } else if (item.$.id === 'c234.e7124') {
        parsingValue.dangerous.unno = value;
      } else if (item.$.id === 'e7106') {
        parsingValue.dangerous.shipmentFlashPoint = value;
      } else if (item.$.id === 'e6411') {
        parsingValue.dangerous.measureUnitCode = value;
      } else if (item.$.id === 'e8339') {
        parsingValue.dangerous.packingGroup = value;
      } else if (item.$.id === 'e8364') {
        parsingValue.dangerous.emsNo = value;
      } else if (item.$.id === 'e8410') {
        parsingValue.dangerous.mfag = value;
      } else if (item.$.id === 'e8158') {
        parsingValue.dangerous.hazardIdUpper = value;
      } else if (item.$.id === 'e8186') {
        parsingValue.dangerous.hazardIdLower = value;
      } else if (item.$.id === 'e8246') {
        parsingValue.dangerous.goodsLabelMark1 = value;
      } else if (item.$.id === 'e8246.1') {
        parsingValue.dangerous.goodsLabelMark2 = value;
      } else if (item.$.id === 'e8246.2') {
        parsingValue.dangerous.goodsLabelMark3 = value;
      }
    } else if (segmentLine[0].trim() === 'FTX' && parsingValue.dgSegment) {
      if (!parsingValue.dangerous) {
      } else if (item.$.id === 'e4451') {
        parsingValue.dangerousRemark = new DangerousRemark();
        parsingValue.dangerousRemark.freeTextCode = value;
        parsingValue.dangerous.remarks.push(parsingValue.dangerousRemark);
      } else if (parsingValue.dangerous.remarks.length > 0 && (item.$.id === 'e4440' || item.$.id === 'e4440.1')) {
        parsingValue.dangerous.remarks[parsingValue.dangerous.remarks.length - 1].freeText1 = value;
      } else if (parsingValue.dangerous.remarks.length > 0 && item.$.id === 'e4440.2') {
        parsingValue.dangerous.remarks[parsingValue.dangerous.remarks.length - 1].freeText2 = value;
      } else if (parsingValue.dangerous.remarks.length > 0 && item.$.id === 'e4440.3') {
        parsingValue.dangerous.remarks[parsingValue.dangerous.remarks.length - 1].freeText3 = value;
      } else if (parsingValue.dangerous.remarks.length > 0 && item.$.id === 'c107.4441') {
        parsingValue.dangerous.remarks[parsingValue.dangerous.remarks.length - 1].freeTextValueCode = value;
      }
    } else if (segmentLine[0].trim() === 'UNT') {
      if (item.$.id === 'e0074') {
        bayPlan.header.segmentNo = value;
      } else if (item.$.id === 'e0062') {
        bayPlan.header.referenceNo = value;
      }
    } else if (segmentLine[0].trim() === 'UNZ') {
      if (item.$.id === 'e0036') {
        bayPlan.header.interchangeControlCount = value;
      } else if (item.$.id === 'e0020') {
        bayPlan.header.interchangeControlRefEnd = value;
      }
    }
  }
  private addRemarkText(text: string, value: string): string {
    if (text && text !== '') {
      text += ', ';
    }
    text += value;
    return text;
  }
  private resetOverSlot(containers: Array<Container>, target: Container): Array<Container> {
    for (const source of containers) {
      if (source.stowage === target.leadingStowage) {
        const sourceBay = Number(source.stowage.substring(0, source.stowage.length - 4));
        const targetBay = Number(target.stowage.substring(0, target.stowage.length - 4));
        const sourceRow = Number(source.stowage.substring(source.stowage.length - 4, source.stowage.length - 2));
        const targetRow = Number(target.stowage.substring(target.stowage.length - 4, target.stowage.length - 2));
        const sourceTier = Number(source.stowage.substring(source.stowage.length - 2, source.stowage.length));
        const targetTier = Number(target.stowage.substring(target.stowage.length - 2, target.stowage.length));
        if (targetTier - sourceTier > 0) {
          if (!source.overSlotHeight) {
            source.overSlotHeight = 1;
          } else {
            source.overSlotHeight += 1;
          }
        } else if (sourceRow !== targetRow) {
          let left = false;
          if (sourceRow % 2 === targetRow % 2) {
            if ((targetRow % 2 === 0 && sourceRow > targetRow) || (targetRow % 2 === 1 && sourceRow < targetRow)) {
              left = true;
            }
          } else {
            if (targetRow % 2 === 1) {
              left = true;
            }
          }
          if (left) {
            if (!source.overSlotLeft) {
              source.overSlotLeft = 1;
            } else {
              source.overSlotLeft += 1;
            }
          } else {
            if (!source.overSlotRight) {
              source.overSlotRight = 1;
            } else {
              source.overSlotRight += 1;
            }
          }
        } else if (sourceBay !== targetBay) {
          if (sourceBay > targetBay) {
            if (!source.overSlotFront) {
              source.overSlotFront = 1;
            } else {
              source.overSlotFront += 1;
            }
          } else {
            if (!source.overSlotBack) {
              source.overSlotBack = 1;
            } else {
              source.overSlotBack += 1;
            }
          }
        }
        return containers;
      }
    }
    return containers;
  }

  private findSegmentSyntax(segmentLine: string[], syntax, parsingValue: ParsingValue) {
    if (segmentLine.length < 2) {
      return;
    }
    if (segmentLine[0].trim() === 'UNB') {
      return syntax.GRP0.UNB[0];
    } else if (segmentLine[0].trim() === 'UNH') {
      return syntax.GRP0.UNH[0];
    } else if (segmentLine[0].trim() === 'BGM') {
      return syntax.GRP0.BGM[0];
    } else if (segmentLine[0].trim() === 'DTM' && segmentLine[1].trim() === '137') {
      return syntax.GRP0.DTM[0];
    } else if (segmentLine[0].trim() === 'TDT') {
      return syntax.GRP0.GRP1[0].TDT[0];
    } else if (segmentLine[0].trim() === 'LOC' && (segmentLine[1].trim() === '5' || segmentLine[1].trim() === '61')) {
      return syntax.GRP0.GRP1[0].LOC[0];
    } else if (segmentLine[0].trim() === 'DTM') {
      return syntax.GRP0.GRP1[0].DTM[0];
    } else if (segmentLine[0].trim() === 'RFF' && segmentLine[1].trim() === 'VON') {
      return syntax.GRP0.GRP1[0].RFF[0];
    } else if (segmentLine[0].trim() === 'HAN' && syntax.GRP0.HAN) {
      return syntax.GRP0.HAN[0];
    } else if (segmentLine[0].trim() === 'LOC' && segmentLine[1].trim() === '147') {
      parsingValue.dgSegment = false;
      return syntax.GRP0.GRP1[1].LOC[0];
    } else if (segmentLine[0].trim() === 'GID') {
      return syntax.GRP0.GRP1[1].GID[0];
    } else if (segmentLine[0].trim() === 'GDS') {
      return syntax.GRP0.GRP1[1].GDS[0];
    } else if (segmentLine[0].trim() === 'FTX' && !parsingValue.dgSegment) {
      return syntax.GRP0.GRP1[1].FTX[0];
    } else if (segmentLine[0].trim() === 'MEA') {
      return syntax.GRP0.GRP1[1].MEA[0];
    } else if (segmentLine[0].trim() === 'DIM') {
      return syntax.GRP0.GRP1[1].DIM[0];
    } else if (segmentLine[0].trim() === 'TMP') {
      return syntax.GRP0.GRP1[1].TMP[0];
    } else if (segmentLine[0].trim() === 'RNG') {
      return syntax.GRP0.GRP1[1].RNG[0];
    } else if (segmentLine[0].trim() === 'LOC') {
      return syntax.GRP0.GRP1[1].LOC[1];
    } else if (segmentLine[0].trim() === 'RFF') {
      return syntax.GRP0.GRP1[1].RFF[0];
    } else if (segmentLine[0].trim() === 'EQD') {
      return syntax.GRP0.GRP1[1].GRP2[0].EQD[0];
    } else if (segmentLine[0].trim() === 'EQA') {
      return syntax.GRP0.GRP1[1].GRP2[0].EQA[0];
    } else if (segmentLine[0].trim() === 'NAD') {
      return syntax.GRP0.GRP1[1].GRP2[0].NAD[0];
    } else if (segmentLine[0].trim() === 'DGS') {
      parsingValue.dgSegment = true;
      return syntax.GRP0.GRP1[1].GRP2[1].DGS[0];
    } else if (segmentLine[0].trim() === 'FTX' && parsingValue.dgSegment) {
      return syntax.GRP0.GRP1[1].GRP2[1].FTX[0];
    } else if (segmentLine[0].trim() === 'UNT') {
      return syntax.GRP0.UNT[0];
    } else if (segmentLine[0].trim() === 'UNZ') {
      return syntax.GRP0.UNZ[0];

    } else if (segmentLine[0].trim() === 'RFF') {
      return syntax.GRP0.RFF[0];
    }
    return;
  }

  private putInterchangeHeader(syntaxSegment, value: string, bayPlan: BayPlan, segmentLine: string[]) {
    let segmentIndex = 0;
    for (const segmentItem of syntaxSegment.segment) {
      const elementLines: string[] = segmentLine[segmentIndex++].split(':');
      let elementIndex = 0;
      if (segmentItem.element) {
        for (const elementItem of segmentItem.element) {
          elementIndex++;
        }
      } else {
      }
    }
  }

  private getSchema(header: string) {
    if (header) {
      const headers = header.split(':');
      const type = headers[0];
      const version = headers[headers.length - 1];
      if (type === 'BAPLIE') {
        if (version === 'SMDG15') {
          return this.http.get('/assets/schema/BAPLIE15.xml', {responseType: 'text'});
        } else if (version === 'SMDG20') {
          return this.http.get('/assets/schema/BAPLIE20.xml', {responseType: 'text'});
        } else if (version === 'SMDG31') {
          return this.http.get('/assets/schema/BAPLIE31.xml', {responseType: 'text'});
        }
        return this.http.get('/assets/schema/BAPLIE22.xml', {responseType: 'text'});
      } else if (type === 'MOVINS') {
        return this.http.get('/assets/schema/MOVINS21.xml', {responseType: 'text'});
      }
    }
    return this.http.get('/assets/schema/BAPLIE22.xml', {responseType: 'text'});
  }

}
export class ParsingValue {
  handle = 'LOA';
  dgSegment = false;
  elementTypeCode = '';
  container = new Container();
  dangerous;
  dangerousRemark;
}
