export class Cell {

  stowage: string;
  coneType: string;

  loadableSpace: boolean;
  loadableReefer: boolean;
  loadable45Ft: boolean;
  loadable53Ft: boolean;

  constructor() {
    this.loadableSpace = true;
    this.loadableReefer = false;
    this.loadable45Ft = false;
    this.loadable53Ft = false;
  }

}
