<mat-card class="material" fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="start center" >
    <div fxLayout="row">
      <mat-form-field class="vessel-edit-form-field">
        <mat-label>Deck Row</mat-label>
        <input matInput type="text" [(ngModel)]="getVessel().maxDeckRow">
      </mat-form-field>
      <mat-form-field class="vessel-edit-form-field">
        <mat-label>Hold Row</mat-label>
        <input matInput type="text" [(ngModel)]="getVessel().maxHoldRow">
      </mat-form-field>
      <mat-form-field class="vessel-edit-form-field">
        <mat-label>Deck Tier</mat-label>
        <input matInput type="text" [(ngModel)]="getVessel().maxDeckTier">
      </mat-form-field>
      <mat-form-field class="vessel-edit-form-field">
        <mat-label>Hold Tier</mat-label>
        <input matInput type="text" [(ngModel)]="getVessel().maxHoldTier">
      </mat-form-field>
      <button mat-raised-button class="primary" color="primary" type="submit" (click)="save()"
              aria-label="Button that displays a tooltip when focused or hovered over">Save</button>
    </div>
  </div>
</mat-card>
<mat-card class="material" fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="start center" >
    <div fxLayout="row">
      <mat-form-field class="vessel-edit-form-field">
        <mat-label>Bay No</mat-label>
        <input matInput type="text" [(ngModel)]="clickedRow.name">
      </mat-form-field>
      <mat-form-field class="vessel-edit-form-field">
        <mat-label>Hatch Group</mat-label>
        <input matInput type="text" [(ngModel)]="clickedRow.hatchName">
      </mat-form-field>
      <mat-form-field class="vessel-edit-form-field">
        <mat-label>Length</mat-label>
        <input matInput type="text" [(ngModel)]="clickedRow.bayLength">
      </mat-form-field>
      <mat-form-field class="vessel-edit-form-field">
        <mat-label>(Deck) Max Tier</mat-label>
        <input matInput type="text" [(ngModel)]="clickedRow.maxDeckTierNo">
      </mat-form-field>
      <mat-form-field class="vessel-edit-form-field">
        <mat-label>(Deck) Min Tier</mat-label>
        <input matInput type="text" [(ngModel)]="clickedRow.minDeckTierNo">
      </mat-form-field>
      <mat-form-field class="vessel-edit-form-field">
        <mat-label>(Hold) Max Tier</mat-label>
        <input matInput type="text" [(ngModel)]="clickedRow.maxHoldTierNo">
      </mat-form-field>
      <mat-form-field class="vessel-edit-form-field">
        <mat-label>(Hold) Min Tier</mat-label>
        <input matInput type="text" [(ngModel)]="clickedRow.minHoldTierNo">
      </mat-form-field>
      <button mat-raised-button class="primary" color="primary" type="submit" (click)="modifyBay()"
              aria-label="Button that displays a tooltip when focused or hovered over">Modify</button>
      <button mat-raised-button class="primary" color="primary" type="submit" (click)="addBay()"
              aria-label="Button that displays a tooltip when focused or hovered over">Add</button>
      <button mat-raised-button class="primary" color="primary" type="submit" (click)="removeBay()"
              aria-label="Button that displays a tooltip when focused or hovered over">Remove</button>
    </div>
  </div>
</mat-card>
<mat-card class="material" fxLayout="column" fxLayoutAlign="start stretch">
  <div id="editTable">
    <table mat-table [dataSource]="getVessel().bayList" matSort class="mat-elevation-z3" class="topics">
      <ng-container *ngFor="let column of columns" matColumnDef="{{column.code}}" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            class="header"
            matTooltip="{{column.name}}"
            matTooltipClass="tooltip">{{column.name}}</th>
        <td mat-cell *matCellDef="let element"
        >
          {{getElementValue(element, column.code)}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnCodes"></tr>
      <tr mat-row
          (click)="clickRow(row)"
          [class.demo-row-is-clicked]="clickedRow === row"
          *matRowDef="let row; columns: columnCodes;"
      ></tr>
    </table>
  </div>
</mat-card>
