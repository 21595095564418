import {VisualPart} from '../../../core/pixi/visual-part';
import PIXI from 'pixi.js';
import {Bay} from '../../../hull/model/bay';
import {TextVisualPart} from '../../../core/pixi/text.visual-part';
import {HatchCoverVisualPart} from './hatch-cover.visual-part';
import {Row} from '../../../hull/model/row';
import {Tier} from '../../../hull/model/tier';
import {Section} from '../../../hull/model/section';
import {Cell} from '../../../hull/model/cell';
import {CellVisualPart} from './cell.visual-part';
import {VesselUtils} from '../../../hull/service/vessel-utils';
import {BayPlan} from '../../../service/model/bay-plan';
import {BayDimensions} from './bay-plan-dimensions';
import {BitmapTextVisualPart} from '../../../core/pixi/bitmap-text.visual-part';
import {OverSlotVisualPart} from './over-slot-visual-part';
import {ContainerVisualPart} from './container.visual-part';

export class BayVisualPart extends VisualPart {

  readonly KEY_BAY_NAME = 'KEY_BAY_NAME';
  readonly PREFIX_ROW_NAME = 'PREFIX_ROW_NAME-';
  readonly PREFIX_TIER_NAME = 'PREFIX_TIER_NAME-';
  readonly PREFIX_HATCH_COVER = 'PREFIX_HATCH_COVER-';
  private oogContainerVps: Array<ContainerVisualPart>;

  constructor(parentGraphics: PIXI.Container
            , protected dimensions: BayDimensions
            , protected bay: Bay, protected bayTitle: string
            , protected vesselHelper: VesselUtils
            , protected bayPlan: BayPlan) {
    super(parentGraphics);

    this.initialize();
   }
  initialize() {

    this.addChild(this.KEY_BAY_NAME,  new TextVisualPart(this.graphics, this.bayTitle, this.dimensions.bayNoFontSize, true));

    if (this.dimensions.zoomLevel > 0) {
      this.initRowNumber(this.bay.deckSection);
      this.initTierNumber(this.bay.deckSection);
      this.initRowNumber(this.bay.holdSection);
      this.initTierNumber(this.bay.holdSection);
    }

    this.initHatchCover();
    this.initSection(this.bay.deckSection, this.vesselHelper.getMaxDeckRow(), this.vesselHelper.getMaxDeckTier());
    this.initSection(this.bay.holdSection, this.vesselHelper.getMaxHoldRow(), this.vesselHelper.getMaxHoldTier());

    this.initContainer();
  }
  private initRowNumber(section: Section) {
    for (let rowIndex = 0; rowIndex < section.rowList.length; rowIndex++) {
      const row: Row = section.rowList[rowIndex];
      if (row.name !== '') {
        this.addChild(this.PREFIX_ROW_NAME + section.deck + row.name, new BitmapTextVisualPart(this.graphics, row.name, this.dimensions.rowNoFontSize));
      }
    }
  }
  private initTierNumber(section: Section) {
    for (let tierIndex = 0; tierIndex < section.tierList.length; tierIndex++) {
      const tier: Tier = section.tierList[tierIndex];
      if (tier.name !== '') {
        // const tierPart: TextVisualPart = new TextVisualPart(this.graphics, tier.name, 12);
        this.addChild(this.PREFIX_TIER_NAME + tier.name, new BitmapTextVisualPart(this.graphics, '[]' + tier.name, this.dimensions.tierNoFontSize));
      }
    }
  }
  private initHatchCover() {
    if (this.bay.hatchCoverList) {
      for (const index in this.bay.hatchCoverList) {
        this.addChild(this.getHatchCoverId(index.toString()), new HatchCoverVisualPart(this.graphics));
      }
    }
  }
  private initSection(section: Section, maxRow: number, maxTier: number) {
    for (let rowIndex = 0; rowIndex < maxRow; rowIndex++) {
      for (let tierIndex = 0; tierIndex < maxTier; tierIndex++) {

        const cell: Cell = section.cellList[rowIndex * maxTier + tierIndex];
        if (cell && cell.loadableSpace) {
          const cellVisualPart: CellVisualPart = new CellVisualPart(this.graphics, cell, rowIndex, tierIndex);
          if (this.vesselHelper.findOccupiedCellKeys(cell.stowage) == null) {
            cellVisualPart.hasPairCell = false;
          }
          this.addChild(cell.stowage, cellVisualPart);
        }
      }
    }
  }
  private initContainer() {
    if (!this.bayPlan.containers) {
      return;
    }
    for (const container of this.bayPlan.containers) {
      let hasOverSlot = false;
      let cellPart = this.getChild(container.stowage) as CellVisualPart;
      if (!cellPart) {
        const pairStowage: string[] = this.vesselHelper.findOccupiedCellKeys(container.stowage);
        if (pairStowage && pairStowage.length > 0) {
          cellPart = this.getChild(pairStowage[pairStowage.length - 1]) as CellVisualPart;
        }
      }
      if (cellPart) {
        const cntrVp = cellPart.pushContainer(container);
        // hasOverSlot = container.hasOverSlot();
        // if (hasOverSlot) {
        //   this.pushChild(new OverSlotVisualPart(this.graphics, cntrVp));
        // }
      }

      const pairCellKeys: string[] = this.vesselHelper.findOccupiedCellKeys(container.stowage);
      if (pairCellKeys && container.getLength() > 20) {
        cellPart = this.getChild(pairCellKeys[0]) as CellVisualPart;
        if (cellPart) {
          cellPart.setOccupied();
        }
      }
    }
  }
  private setBoundsOfOverSlots() {
    this.children.forEach(child => {

      if (child instanceof OverSlotVisualPart) {
        const osVp = child as OverSlotVisualPart;

        osVp.resetBounds();
      }
    });
  }

  getStowage(cellKey: string, length: number): string {
    return this.vesselHelper.getStowage(cellKey, length);
  }
  getPairCellVisualParts(stowage: string): CellVisualPart[] {
    const pairParts = new Array<CellVisualPart>();
    const cellKeys: string[] = this.vesselHelper.getPairStowage(stowage);

    if (!cellKeys) {
      return;
    }

    cellKeys.forEach(key => {

      const pairBayPart = this.getParent().getChild(key.substring(0, 3)) as BayVisualPart;
      if (pairBayPart) {

        const pairCellPart = pairBayPart.getChild(key) as CellVisualPart;
        if (pairCellPart) {
          pairParts.push(pairCellPart);
        }
      }
    });

    return pairParts;
  }

  setBounds(x: number, y: number, width: number, height: number, dimensions?: BayDimensions) {
    super.setBounds(x, y, width, height);
    this.dimensions = dimensions;
    let xPosition = x;
    let yPosition = y;

    this.setBayNumberBounds(x, y);

    yPosition = yPosition + this.dimensions.bayNoHeight * 2;

    if (this.dimensions.rowNoFontSize > 0) {
      this.setRowNumberBounds(this.bay.deckSection, xPosition, yPosition);
      yPosition = yPosition + this.dimensions.rowNoHeight;
    }

    this.setSectionBounds(this.bay.deckSection, xPosition, yPosition, this.vesselHelper.getMaxDeckRow(), this.vesselHelper.getMaxDeckTier());

    const tierXPosition = xPosition + this.vesselHelper.getMaxDeckRow() * (this.dimensions.cellWidth + this.dimensions.cellMargin);

    this.setTierNumberBounds(this.bay.deckSection, tierXPosition, yPosition);

    yPosition += this.vesselHelper.getMaxDeckTier() * (this.dimensions.cellHeight + this.dimensions.cellMargin) + this.dimensions.yMargin;
    this.setHatchCoverBounds(xPosition, yPosition);

    yPosition += this.dimensions.hatchCoverHeight + this.dimensions.yMargin;
    this.setTierNumberBounds(this.bay.holdSection, tierXPosition, yPosition);

    xPosition += (this.vesselHelper.getMaxDeckRow() - this.vesselHelper.getMaxHoldRow()) / 2 * (this.dimensions.cellWidth + this.dimensions.cellMargin);
    this.setSectionBounds(this.bay.holdSection, xPosition, yPosition, this.vesselHelper.getMaxHoldRow(), this.vesselHelper.getMaxHoldTier());

    yPosition += this.vesselHelper.getMaxHoldTier() * (this.dimensions.cellHeight + this.dimensions.cellMargin);
    this.setRowNumberBounds(this.bay.holdSection, xPosition, yPosition);

    // this.setBoundsOfOverSlots();
  }
  private setBayNumberBounds(xPosition: number, yPosition: number) {
    const bayNumberPart = this.getChild(this.KEY_BAY_NAME) as VisualPart;

    if (bayNumberPart) {
      bayNumberPart.setBounds(xPosition, yPosition, this.getBounds().width, this.dimensions.bayNoHeight * 2);
    }
  }
  private setRowNumberBounds(section: Section, xPosition: number, yPosition: number) {
    const vesselRow = section.deck ? this.vesselHelper.getMaxDeckRow() : this.vesselHelper.getMaxHoldRow();
    const rows = Math.round((vesselRow - section.rowList.length) / 2);
    for (let rowIndex = 0; rowIndex < section.rowList.length; rowIndex++) {
      const row: Row = section.rowList[rowIndex];
      if (row.name !== '') {
        const rowPart: BitmapTextVisualPart = this.getChild(this.getRowNumberId( section.deck + row.name)) as BitmapTextVisualPart;
        if (rowPart) {
          rowPart.fontSize = this.dimensions.rowNoFontSize;
          // rowPart.fontSize = 8;
          rowPart.setBounds(xPosition + (rowIndex + rows) * (this.dimensions.cellWidth + this.dimensions.cellMargin) + (this.dimensions.cellWidth - this.dimensions.rowNoFontSize) / 2
            , yPosition
            , this.dimensions.cellWidth / 2
            , this.dimensions.rowNoHeight);
        }

      }
    }
  }
  private setTierNumberBounds(section: Section, xPosition: number, yPosition: number) {

    for (let tierIndex = 0; tierIndex < section.tierList.length; tierIndex++) {
      const tier: Tier = section.tierList[tierIndex];
      if (tier.name !== '') {
        const tierPart: BitmapTextVisualPart = this.getChild(this.getTierNumberId(tier.name)) as BitmapTextVisualPart;

        if (tierPart) {
          tierPart.fontSize = this.dimensions.tierNoFontSize;
          tierPart.setBounds(xPosition + (this.dimensions.tierWidth - this.dimensions.tierNoFontSize) / 4
            , yPosition + tierIndex * (this.dimensions.cellHeight + this.dimensions.cellMargin)
            , this.dimensions.cellWidth
            , this.dimensions.cellHeight);
        }

      }
    }
  }
  private setHatchCoverBounds(xPosition: number, yPosition: number) {

    let index = 0;
    for (const hatchCover of this.bay.hatchCoverList) {

      const hatchCoverPart: HatchCoverVisualPart = this.getChild(this.getHatchCoverId((index++).toString()));
      if (hatchCoverPart) {
        hatchCoverPart.setBounds(xPosition + hatchCover.upperStartPoint * (this.dimensions.cellWidth + this.dimensions.cellMargin) / 4
          , yPosition, (hatchCover.upperEndPoint - hatchCover.upperStartPoint) *
          (this.dimensions.cellWidth + this.dimensions.cellMargin) / 4, this.dimensions.hatchCoverHeight);
      }
    }
  }
  private setSectionBounds(section: Section, xPosition: number, yPosition: number, maxRow: number, maxTier: number) {
    const vesselRow = section.deck ? this.vesselHelper.getMaxDeckRow() : this.vesselHelper.getMaxHoldRow();
    const rows = Math.round((vesselRow - section.rowList.length) / 2 );
    for (let rowIndex = 0; rowIndex < maxRow; rowIndex++) {
      for (let tierIndex = 0; tierIndex < maxTier; tierIndex++) {

        const cell: Cell = section.cellList[rowIndex * maxTier + tierIndex];
        if (cell && cell.loadableSpace) {
          const cellVisualPart = this.getChild(cell.stowage) as CellVisualPart;

          if (cellVisualPart && cell.loadableSpace) {
            cellVisualPart.setBounds(xPosition + (rowIndex + rows) * (this.dimensions.cellWidth + this.dimensions.cellMargin)
              , yPosition + tierIndex * (this.dimensions.cellHeight + this.dimensions.cellMargin)
              , this.dimensions.cellWidth, this.dimensions.cellHeight);

            if (this.vesselHelper.findOccupiedCellKeys(cell.stowage) == null) {
              cellVisualPart.hasPairCell = false;
            }
          }
        }
      }
    }
  }
  draw() {
    super.draw();
  }
  getBayTitle() {
    return this.bayTitle;
  }
  private getRowNumberId(rowName: string): string {
    return this.PREFIX_ROW_NAME + rowName;
  }
  private getTierNumberId(rowName: string): string {
    return this.PREFIX_TIER_NAME + rowName;
  }
  private getHatchCoverId(hatchCoverName: string): string {
    return this.PREFIX_HATCH_COVER + hatchCoverName;
  }
  private getBayNameVisualPart(): TextVisualPart {
    return this.getChild(this.KEY_BAY_NAME) as TextVisualPart;
  }
  private getCellWidth(): number {

    return 8;
  }
  private getCellHeight(): number {

    return 8;
  }
  private getCellMargin(): number {
    return 4;
  }
}
