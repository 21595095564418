import {VisualPart} from '../../../core/pixi/visual-part';
import * as PIXI from 'pixi.js';

export class OccupiedMarkVisualPart extends VisualPart {
  constructor(parentGraphics: PIXI.Container) {
    super(parentGraphics);
  }

  draw() {
    super.draw();
    const width = this.bounds.width / 4;
    const height = this.bounds.height / 4;
    this.getGraphics().lineStyle(1, 0x999999)
      .moveTo(this.bounds.x + width, this.bounds.y + height)
      .lineTo(this.bounds.x + width * 3, this.bounds.y + height * 3);
    this.getGraphics().lineStyle(1, 0x999999)
      .moveTo(this.bounds.x + width * 3, this.bounds.y + height)
      .lineTo(this.bounds.x + width, this.bounds.y + height * 3);
  }

}
