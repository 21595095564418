import {BayPlan} from '../../service/model/bay-plan';
import {Vessel} from '../model/vessel';
import {Bay} from '../model/bay';
import {Section} from '../model/section';
import {Row} from '../model/row';
import {Tier} from '../model/tier';
import {Cell} from '../model/cell';
import {Hatch} from '../model/hatch';
import {HatchCover} from '../model/hatch-cover';
import {Container} from '../../service/model/container';
import {VesselUtils} from './vessel-utils';

export class BayPlanVesselParser {

  static translate(bayPlan: BayPlan): Vessel {
    const bayMap: Map<string, BayData> = new Map<string, BayData>();

    let maxHoldTierNo = 0;

    let anyDeckZeroRow = false;
    let anyHoldZeroRow = false;

    for (const container of bayPlan.containers) {

      const bayNo = VesselUtils.fixStowageType(container.stowage.substring(0, container.stowage.length - 4), 3);
      let rowNo = container.stowage.substring(container.stowage.length - 4, container.stowage.length - 2);
      const tierNo = container.stowage.substring(container.stowage.length - 2, container.stowage.length);

      if (isNaN(Number(bayNo)) || isNaN(Number(rowNo)) || isNaN(Number(tierNo)) || Number(bayNo) < 0 || Number(bayNo) > 150
        || Number(rowNo) < 0 || Number(rowNo) > 30 || Number(tierNo) < 1 || Number(tierNo) > 99 || (Number(tierNo) > 30 && Number(tierNo) < 60)) {
        continue;
      }

      let bayData: BayData = bayMap.get(bayNo);
      if (!bayData) {
        bayData = new BayData();
        bayData.name = bayNo;
        bayData.minHoldTierNo = 0;
      }
      if (container.getLength() > 20) {
        bayData.length40 += 1;
      } else {
        bayData.length20 += 1;
      }
      let zeroRow = false;
      if (rowNo === '00') {
        zeroRow = true;
      }
      if (Number(rowNo) % 2 === 1) {
        rowNo = (Number(rowNo) + 1).toString();
      }
      if (Number(tierNo) > 50) {
        if (zeroRow) {
          bayData.zeroDeckRow = true;
          anyDeckZeroRow = true;
        }
        bayData.maxDeckRowNo = Math.max(bayData.maxDeckRowNo, Number(rowNo));
        bayData.maxDeckTierNo = Math.max(bayData.maxDeckTierNo, Number(tierNo));
        if (bayData.minDeckTierNo === 0) {
          bayData.minDeckTierNo = Number(tierNo);
        } else {
          bayData.minDeckTierNo = Math.min(bayData.minDeckTierNo, Number(tierNo));
        }
      } else {
        if (zeroRow) {
          bayData.zeroHoldRow = true;
          anyHoldZeroRow = true;
        }

        bayData.maxHoldRowNo = Math.max(bayData.maxHoldRowNo, Number(rowNo));

        maxHoldTierNo = Math.max(maxHoldTierNo, Number(tierNo));
        bayData.maxHoldTierNo = maxHoldTierNo;

        if (bayData.minHoldTierNo === 0) {
          bayData.minHoldTierNo = Number(tierNo);
        } else {
          bayData.minHoldTierNo = Math.min(bayData.minHoldTierNo, Number(tierNo));
        }
      }
      bayMap.set(bayNo, bayData);
    }

    for (const data of bayMap.values()) {
      // if (data.zeroDeckRow) {
      if (anyDeckZeroRow) {
        data.maxDeckRowNo += 1;
      }
      // if (data.zeroHoldRow) {
      if (anyHoldZeroRow) {
        data.maxHoldRowNo += 1;
        // console.info(data.name + ')' + data.maxHoldRowNo);
      }
    }
    return this.resetBayList(bayMap, bayPlan.header.vesselCode, bayPlan.containers);
  }
  static makeBayData(bayList: Array<Bay>): Vessel {
    const bayMap: Map<string, BayData> = new Map<string, BayData>();
    for (const bay of bayList) {
      const bayData = new BayData();
      bayData.setBay(bay);
      bayMap.set(bay.name, bayData);
    }
    return this.resetBayList(bayMap);
  }
  static resetBayList(bayMap: Map<string, BayData>, vesselCode?: string, containers?: Container[]): Vessel {

    const vessel: Vessel = new Vessel();
    vessel.maxBay = bayMap.size;
    vessel.callSign = vesselCode;
    const baySortMap = new Map([...bayMap.entries()].sort());

    const bayList = new Array<Bay>();
    const hatchList = new Array<Hatch>();
    let hatch: Hatch = new Hatch();
    hatch.name = '1';
    const maxBayData: BayData = new BayData();
    maxBayData.minDeckTierNo = 99;
    maxBayData.minHoldTierNo = 50;
    let lastBayData: BayData = new BayData();
    lastBayData.minDeckTierNo = 99;
    lastBayData.minHoldTierNo = 50;
    lastBayData.hatchName = hatch.name;

    for (const data of baySortMap.values()) {
      const bay: Bay = new Bay();
      bay.name = data.name;
      if (bayList.length > 0 && Number(data.name) !== Number(bayList[bayList.length - 1].name) + 1) {
        const hatchIndex = hatch.name;
        if (lastBayData.minDeckTierNo === 99) {
          lastBayData.minDeckTierNo = 0;
        }
        if (lastBayData.minHoldTierNo === 50) {
          lastBayData.minHoldTierNo = 0;
        }
        this.resetMaxValueInHatch(baySortMap, lastBayData);

        if (hatch.bayNumberList.length === 2 && bayList[bayList.length - 2].bayLength !== bayList[bayList.length - 1].bayLength) {
          this.checkAdditionalBayList(bayList, hatch, baySortMap, bayMap);
        }
        hatchList.push(hatch);

        hatch = new Hatch();
        hatch.name = (Number(hatchIndex) + 1).toString();
        lastBayData = new BayData();
        lastBayData.minDeckTierNo = 99;
        lastBayData.minHoldTierNo = 50;
        lastBayData.hatchName = hatch.name;
      }
      bay.hatchName = hatch.name;
      data.hatchName = hatch.name;
      if (data.length20 < data.length40) {
        bay.bayLength = 40;
      } else {
        bay.bayLength = 20;
      }

      const deckSection: Section = new Section();
      deckSection.deck = true;
      bay.deckSection = deckSection;

      const holdSection: Section = new Section();
      holdSection.deck = false;
      bay.holdSection = holdSection;

      bayList.push(bay);

      vessel.maxDeckRow = Math.max(vessel.maxDeckRow, data.maxDeckRowNo);
      vessel.maxHoldRow = Math.max(vessel.maxHoldRow, data.maxHoldRowNo);
      vessel.maxDeckTier = Math.max(vessel.maxDeckTier, (data.maxDeckTierNo - data.minDeckTierNo + 2) / 2);
      vessel.maxHoldTier = Math.max(vessel.maxHoldTier, (data.maxHoldTierNo - data.minHoldTierNo + 2) / 2);

      lastBayData.maxDeckRowNo = Math.max(lastBayData.maxDeckRowNo, data.maxDeckRowNo);
      lastBayData.zeroDeckRow = lastBayData.zeroDeckRow || data.zeroDeckRow;
      lastBayData.maxHoldRowNo = Math.max(lastBayData.maxHoldRowNo, data.maxHoldRowNo);
      lastBayData.zeroHoldRow = lastBayData.zeroHoldRow || data.zeroHoldRow;
      lastBayData.maxDeckTierNo = Math.max(lastBayData.maxDeckTierNo, data.maxDeckTierNo);
      lastBayData.maxHoldTierNo = Math.max(lastBayData.maxHoldTierNo, data.maxHoldTierNo);
      if (data.minDeckTierNo > 0) {
        lastBayData.minDeckTierNo = Math.min(lastBayData.minDeckTierNo, data.minDeckTierNo);
      }
      if (data.minHoldTierNo > 0) {
        lastBayData.minHoldTierNo = Math.min(lastBayData.minHoldTierNo, data.minHoldTierNo);
      }

      maxBayData.maxDeckRowNo = Math.max(maxBayData.maxDeckRowNo, data.maxDeckRowNo);
      maxBayData.zeroDeckRow = maxBayData.zeroDeckRow || data.zeroDeckRow;
      maxBayData.maxHoldRowNo = Math.max(maxBayData.maxHoldRowNo, data.maxHoldRowNo);
      maxBayData.zeroHoldRow = maxBayData.zeroHoldRow || data.zeroHoldRow;
      maxBayData.maxDeckTierNo = Math.max(maxBayData.maxDeckTierNo, data.maxDeckTierNo);
      maxBayData.maxHoldTierNo = Math.max(maxBayData.maxHoldTierNo, data.maxHoldTierNo);
      if (data.minDeckTierNo > 0) {
        maxBayData.minDeckTierNo = Math.min(maxBayData.minDeckTierNo, data.minDeckTierNo);
      }
      if (data.minHoldTierNo > 0) {
        maxBayData.minHoldTierNo = Math.min(maxBayData.minHoldTierNo, data.minHoldTierNo);
      }

      hatch.bayNumberList.push(bay.name);
    }

    if (hatch.bayNumberList.length === 2 && bayList[bayList.length - 2].bayLength !== bayList[bayList.length - 1].bayLength) {
      this.checkAdditionalBayList(bayList, hatch, baySortMap, bayMap);
    }
    hatchList.push(hatch);
    vessel.hatchList = hatchList;

    const maxRowMinTierMap = this.gerRowTierThreshold(vessel.hatchList, bayList, containers);

    for (const bay of bayList) {

      const bayData: BayData = bayMap.get(bay.name);
      if (bayData.maxDeckTierNo === 0) {
        bayData.maxDeckTierNo = maxBayData.maxDeckTierNo;
      }
      if (bayData.minDeckTierNo === 0) {
        bayData.minDeckTierNo = maxBayData.minDeckTierNo;
      }
      if (bayData.maxHoldTierNo === 0) {
        bayData.maxHoldTierNo = maxBayData.maxHoldTierNo;
      }
      if (bayData.minHoldTierNo === 0) {
        bayData.minHoldTierNo = maxBayData.minHoldTierNo;
      }
      bay.maxDeckTierNo = bayData.maxDeckTierNo;
      bay.minDeckTierNo = bayData.minDeckTierNo;
      bay.maxHoldTierNo = bayData.maxHoldTierNo;
      bay.minHoldTierNo = bayData.minHoldTierNo;

      // DECK ROW/TIER No
      let rowList: Array<Row> = new Array<Row>();
      for (let rowIndex = 0; rowIndex < vessel.maxDeckRow; rowIndex++) {
        // const row = this.getRowData(vessel.maxDeckRow - 2 * rowIndex, vessel.maxDeckRow % 2 === 1);
        const row = this.getRowData(vessel.maxDeckRow - 2 * rowIndex, bayMap.get(bay.name).zeroDeckRow);
        rowList.push(row);
      }
      bay.deckSection.rowList = rowList;

      const deckTierList: Array<Tier> = new Array<Tier>();
      const tierGap = vessel.maxDeckTier - (bayData.maxDeckTierNo - bayData.minDeckTierNo + 2) / 2;
      for (let tierIndex = 0; tierIndex < vessel.maxDeckTier; tierIndex++) {
        let tierNo = 0;
        if (tierIndex < tierGap) {
          tierNo = bayData.maxDeckTierNo + 2 * (tierGap - tierIndex);
        } else {
          tierNo = bayData.maxDeckTierNo - 2 * (tierIndex - tierGap);
        }
        const tier: Tier = new Tier();
        if (tierNo > 0) {
          tier.name = tierNo.toString();
          if (tier.name.length === 1) {
            tier.name = '0' + tier.name;
          }
        } else {
          tier.name = '';
        }
        deckTierList.push(tier);
      }
      bay.deckSection.tierList = deckTierList;

      // HOLD ROW/TIER No
      let rowGap = Number(((vessel.maxHoldRow - bayData.maxHoldRowNo) / 2 - 0.5).toFixed(0));
      if (rowGap < 0) {
        rowGap = 0;
      }
      rowList = new Array<Row>();
      for (let rowIndex = 0; rowIndex < vessel.maxHoldRow; rowIndex++) {

        // const row = this.getRowData(vessel.maxHoldRow - 2 * rowIndex, vessel.maxHoldRow % 2 === 1);
        const row = this.getRowData(vessel.maxHoldRow - 2 * rowIndex, bayMap.get(bay.name).zeroHoldRow);
        rowList.push(row);
      }
      bay.holdSection.rowList = rowList;

      if (vessel.maxHoldTier < (bayData.maxHoldTierNo - bayData.minHoldTierNo) / 2) {
        bayData.maxHoldTierNo = 2 * vessel.maxHoldTier;
      }

      const holdTierList = new Array<Tier>();
      for (let tierIndex = 0; tierIndex < vessel.maxHoldTier; tierIndex++) {
        let tierNo = bayData.maxHoldTierNo - 2 * tierIndex;
        if (!bayData.zeroHoldRow && tierNo < 1) {
          tierNo = tierNo - 2;
        }
        const tier: Tier = new Tier();
        if (tierNo >= bayData.minHoldTierNo && tierNo > 0) {
          tier.name = tierNo.toString();
          if (tier.name.length === 1) {
            tier.name = '0' + tier.name;
          }
        } else {
          tier.name = '';
        }
        holdTierList.push(tier);
      }
      bay.holdSection.tierList = holdTierList;

      const hatchCoverList: Array<HatchCover> = new Array<HatchCover>();
      const hatchCover: HatchCover = new HatchCover();
      const startHatchRow = vessel.maxHoldRow - bayData.maxHoldRowNo + (vessel.maxDeckRow - vessel.maxHoldRow);
      const hatchWidthRow = bayData.maxHoldRowNo;
      hatchCover.upperStartPoint = startHatchRow / 2 * 4;
      hatchCover.lowerStartPoint = hatchCover.upperStartPoint;
      hatchCover.upperEndPoint =  hatchWidthRow * 4 + hatchCover.upperStartPoint;
      hatchCover.lowerEndPoint = hatchCover.upperEndPoint;
      hatchCoverList.push(hatchCover);
      bay.hatchCoverList = hatchCoverList;

      // Generating cells

      const deckCellList: Array<Cell> = new Array<Cell>();

      const maxRow = maxRowMinTierMap.ROW.get(bay.name);

      for (let rowIndex = 0; rowIndex < vessel.maxDeckRow; rowIndex++) {

        const rowName =  bay.deckSection.rowList[rowIndex].name;

        const rowVisible = rowName <= maxRow ? true : false;

        for (let tierIndex = 0; tierIndex < vessel.maxDeckTier; tierIndex++) {

          const tierName = bay.deckSection.tierList[tierIndex].name;

          const cell: Cell = new Cell();
          cell.stowage = bay.name + rowName + tierName;

          let rowNo = Number(bay.deckSection.rowList[rowIndex].name);
          if (rowNo % 2 === 1) {
            rowNo = rowNo + 1;
          }
          if (cell.stowage.length < 6 || !rowVisible) {
            cell.loadableSpace = false;
          }
          deckCellList.push(cell);
        }
      }
      bay.deckSection.cellList = deckCellList;

      const holdCellList: Array<Cell> = new Array<Cell>();
      for (let rowIndex = 0; rowIndex < vessel.maxHoldRow; rowIndex++) {

        const rowName = bay.holdSection.rowList[rowIndex].name;

        const bayTier = maxRowMinTierMap.TIER.get(bay.name);

        let minTier = '';
        if (bayTier) {
          minTier = bayTier.get(rowName);
        }

        for (let tierIndex = 0; tierIndex < vessel.maxHoldTier; tierIndex++) {

          const tierName = bay.holdSection.tierList[tierIndex].name;

          const cell: Cell = new Cell();
          cell.stowage = bay.name + rowName + tierName;

          let rowNo = Number(bay.holdSection.rowList[rowIndex].name);
          if (rowNo % 2 === 1) {
            rowNo = rowNo + 1;
          }
          cell.loadableSpace = cell.stowage.length >= 6 && minTier <= tierName && minTier ? true : false;
          holdCellList.push(cell);
        }
      }
      bay.holdSection.cellList = holdCellList;
    }
    vessel.bayList = bayList;
    return vessel;
  }

  static checkAdditionalBayList(bayList: Array<Bay>, hatch: Hatch, baySortMap: Map<any, any>, bayMap: Map<string, BayData>) {
    let addBay;
    let target;
    if (bayList[bayList.length - 2].bayLength === 20) {
      target = baySortMap.get(bayList[bayList.length - 2].name);
      addBay = this.cloneBayModel(bayList, 2);
      bayList.push(addBay);
      hatch.bayNumberList.push(addBay.name);
    } else {
      target = baySortMap.get(bayList[bayList.length - 1].name);
      addBay = this.cloneBayModel(bayList, 1);
      bayList.push(addBay);
      bayList[bayList.length - 1] = bayList[bayList.length - 2];
      bayList[bayList.length - 2] = bayList[bayList.length - 3];
      bayList[bayList.length - 3] = addBay;
      hatch.bayNumberList.push(addBay.name);
      hatch.bayNumberList[hatch.bayNumberList.length - 1] = hatch.bayNumberList[hatch.bayNumberList.length - 2];
      hatch.bayNumberList[hatch.bayNumberList.length - 2] = hatch.bayNumberList[hatch.bayNumberList.length - 3];
      hatch.bayNumberList[hatch.bayNumberList.length - 3] = addBay.name;
    }
    let source = new BayData();
    source.name = addBay.name;
    if (target) {
      source = this.cloneBayData(source, target);
    }
    bayMap.set(addBay.name, source);
    // baySortMap.set(addBay.name, source);
  }

  static cloneBayModel(bayList: Array<Bay>, count: number): Bay {
    const bay = new Bay();
    if (count === 1) {
      bay.name = VesselUtils.fixStowageType((Number(bayList[bayList.length - 2].name) - 1).toString(), 3);
    } else {
      bay.name = VesselUtils.fixStowageType((Number(bayList[bayList.length - 1].name) + 1).toString(), 3);
    }
    bay.bayLength = bayList[bayList.length - count].bayLength;
    bay.hatchName = bayList[bayList.length - count].hatchName;
    bay.maxDeckTierNo = bayList[bayList.length - count].maxDeckTierNo;
    bay.maxHoldTierNo = bayList[bayList.length - count].maxHoldTierNo;
    bay.minDeckTierNo = bayList[bayList.length - count].minDeckTierNo;
    bay.minHoldTierNo = bayList[bayList.length - count].minHoldTierNo;
    const addDeckSection: Section = new Section();
    addDeckSection.deck = true;
    bay.deckSection = addDeckSection;
    const addHoldSection: Section = new Section();
    addHoldSection.deck = false;
    bay.holdSection = addHoldSection;
    return bay;
  }

  static cloneBayData(source: BayData, target: BayData): BayData {
    source.maxDeckRowNo = target.maxDeckRowNo;
    source.zeroDeckRow = target.zeroDeckRow;
    source.maxHoldRowNo = target.maxHoldRowNo;
    source.zeroHoldRow = target.zeroHoldRow;
    source.maxDeckTierNo = target.maxDeckTierNo;
    source.minDeckTierNo = target.minDeckTierNo;
    source.maxHoldTierNo = target.maxHoldTierNo;
    source.minHoldTierNo = target.minHoldTierNo;
    return source;
  }

  static getRowData(rowNo: number, zeroRow: boolean): Row {
    if (zeroRow) {
      rowNo = rowNo - 1;
    }
    if (!zeroRow && rowNo <= 0) {
      rowNo = rowNo - 2;
    }
    if (rowNo < 0) {
      rowNo++;
      rowNo = rowNo * -1;
    }
    const row: Row = new Row();
    row.name = rowNo.toString();
    if (row.name.length === 1) {
      row.name = '0' + rowNo;
    }
    return row;
  }

  private static resetMaxValueInHatch(baySortMap: Map<string, BayData>, lastBayData: BayData) {
    for (const data of baySortMap.values()) {
      if (data.hatchName === lastBayData.hatchName) {
        data.maxDeckRowNo = lastBayData.maxDeckRowNo;
        data.zeroDeckRow = lastBayData.zeroDeckRow;
        data.maxHoldRowNo = lastBayData.maxHoldRowNo;
        data.zeroHoldRow = lastBayData.zeroHoldRow;
        data.maxDeckTierNo = lastBayData.maxDeckTierNo;
        data.minDeckTierNo = lastBayData.minDeckTierNo;
        data.maxHoldTierNo = lastBayData.maxHoldTierNo;
        data.minHoldTierNo = lastBayData.minHoldTierNo;
      }
    }
  }
  private static gerRowTierThreshold(hatches: Hatch[], bays: Bay[], containers: Container[]): {ROW: Map<string, string>, TIER: Map<string, Map<string, string>>} {

    const bayMap = new Map<string, Bay>();
    bays.forEach(bay => {
      bayMap.set(bay.name, bay);
    });

    const hatchMap = new Map<string, Hatch>();
    hatches.forEach(hatch => {
      hatchMap.set(hatch.name, hatch);
    });

    let maxRowMap = new Map<string, string>();
    let minTierMap = new Map<string, Map<string, string>>();

    for (const container of containers) {

      const stowage = container.stowage;
      const bayName = stowage.substring(0, stowage.length - 4);
      const rowName = stowage.substring(stowage.length - 4, stowage.length - 2);
      const tierName = stowage.substring(stowage.length - 2, stowage.length);

      if (!bayMap.get(bayName)) {
        continue;
      }

      if (container.getLength() > 20) {
        hatchMap.get(bayMap.get(bayName).hatchName).bayNumberList.forEach(pairBayName => {

          maxRowMap = this.setMaxRowMap(maxRowMap, pairBayName, rowName);
          minTierMap = this.setMinTierMap(minTierMap, pairBayName, rowName, tierName);

        });
      } else {

        maxRowMap = this.setMaxRowMap(maxRowMap, bayName, rowName);
        minTierMap = this.setMinTierMap(minTierMap, bayName, rowName, tierName);
      }
    }
    return {ROW: maxRowMap, TIER: minTierMap};
  }
  private static setMaxRowMap(maxRowMap: Map<string, string>, bayName: string, rowName: string): Map<string, string> {

    const maxRow = maxRowMap.get(bayName);

    if (maxRow) {

      if (maxRow < rowName) {
        maxRowMap.set(bayName, rowName);
      }

    } else {
      maxRowMap.set(bayName, rowName);
    }
    return maxRowMap;
  }
  private static setMinTierMap(minTierMap: Map<string, Map<string, string>>, bayName: string, rowName: string, tierName: string): Map<string, Map<string, string>> {

    let bay = minTierMap.get(bayName);

    if (!bay) {
      bay = new Map<string, string>();
      minTierMap.set(bayName, bay);
    }

    const minTier = bay.get(rowName);

    if (minTier) {
      if (minTier > tierName) {
        bay.set(rowName, tierName);
      }
    } else {
      bay.set(rowName, tierName);
    }
    return minTierMap;
  }
}

export class BayData {
  name = '';
  hatchName = '';
  length20 = 0;
  length40 = 0;
  maxDeckRowNo = 0;
  zeroDeckRow = false;
  maxHoldRowNo = 0;
  zeroHoldRow = false;
  maxDeckTierNo = 0;
  minDeckTierNo = 0;
  maxHoldTierNo = 0;
  minHoldTierNo = 0;

  setBay(bay: Bay) {
    this.name = bay.name;
    this.hatchName = bay.hatchName;
    if (bay.bayLength === 20) {
      this.length20 = 1;
    } else {
      this.length40 = 1;
    }
    this.maxDeckTierNo = bay.maxDeckTierNo;
    this.minDeckTierNo = bay.minDeckTierNo;
    this.maxHoldTierNo = bay.maxHoldTierNo;
    this.minHoldTierNo = bay.minHoldTierNo;
    if (bay.deckSection) {
      for (const row of bay.deckSection.rowList) {
        this.maxDeckRowNo = Math.max(this.maxDeckRowNo, Number(row.name));
        if (Number(row.name) === 0) {
          this.zeroDeckRow = true;
        }
      }
    }
    if (this.zeroDeckRow) {
      this.maxDeckRowNo = this.maxDeckRowNo + 1;
    }
    if (bay.holdSection) {
      for (const row of bay.holdSection.rowList) {
        this.maxHoldRowNo = Math.max(this.maxHoldRowNo, Number(row.name));
        if (Number(row.name) === 0) {
          this.zeroHoldRow = true;
        }
      }
    }
    if (this.zeroHoldRow) {
      this.maxHoldRowNo = this.maxHoldRowNo + 1;
    }
  }

}
