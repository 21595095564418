<div fxLayout="column" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="space-between center" >
    <div>
      Filter entries like below
    </div>
    <div>
      <button mat-icon-button class="primary" color="basic" type="submit"
              matTooltip="Apply"
              matTooltipClass="tooltip"
              aria-label="Button that displays a tooltip when focused or hovered over">
        <mat-icon>bolt</mat-icon>
      </button>
      <button mat-icon-button class="primary" color="basic" type="submit"
              matTooltip="Close"
              matTooltipClass="tooltip"
              aria-label="Button that displays a tooltip when focused or hovered over">
        <mat-icon>close</mat-icon>
      </button>

    </div>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z0"  class="topics">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>Filter</th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox (click)="$event.stopPropagation()"
                      [checked]="element.selected">*
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Property</th>
      <td mat-cell *matCellDef="let element">{{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>Value</th>
      <td mat-cell *matCellDef="let element" >
        <div [ngClass]="getStyleClass(element)" [style.background]="getBackColor(element)" [style.color]="getForeColor(element)">
          {{element.formattedValue}}
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;">
  </table>
</div>
