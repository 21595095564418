import {EventEmitter, Injectable} from '@angular/core';
import {CoreEvent} from './core-event';
import {VisualEvent} from './visual-event';
import {VisualPart} from '../pixi/visual-part';
import {EventIds} from './event-ids';
import {SessionIds} from '../service/session-ids';
import {ZoomLevel} from '../../edit/bay-plan/visual-part/bay-plan-dimensions';
import {LayoutModes} from '../../edit/bay-plan/visual-part/bay-plan-root.visual-part';
import {DisplayMode} from '../../toolbars/bay-toolbar/bay-toolbar.component';
import {LayoutZoom} from '../../edit/bay-plan/visual-part/layout-zoom';

@Injectable({
  providedIn: 'root'
})
export class ToolbarEventService {

  private eventEmitter = new EventEmitter<VisualEvent>();

  layoutZoom = new LayoutZoom();

  constructor() {
  }
  emit(id: string, newValue?: any, source?: any) {
    this.eventEmitter.emit(new VisualEvent(id, newValue, source));
  }
  getEmitter() {
    return this.eventEmitter;
  }
  unsubscribe() {
    return this.eventEmitter.unsubscribe();
  }
}
