import {HatchCover} from './hatch-cover';
import {Section} from './section';
import {EditableModel} from '../../be-com/etable/editable-model';

export class Bay implements EditableModel {

  name = '';
  hatchName = '';
  bayLength = 20;

  deckLcg = 0;
  holdLcg = 0;

  deckBaseLine = 0;
  remark = '';

  deckSection = new Section();
  holdSection = new Section();
  hatchCoverList: Array<HatchCover> = new Array<HatchCover>();

  maxDeckTierNo = 0;
  minDeckTierNo = 0;
  maxHoldTierNo = 0;
  minHoldTierNo = 0;

  constructor() {
  }
  id = '';
  modified = false;
}
