export const MenuUrls = {

  ABOUT: '/about',
  FILE: '/edi/files',
  COMPARE: '/edi/compare',
  EDIT_BAY: '/edit/bay',
  EDIT_LIST: '/edit/list',
  EDIT_SUMMARY: '/edit/summary',
  EDIT_SHARING: '/edit/sharing',
  EDIT_CHARTS: '/edit/charts',

  COMPARE_BAY: '/compare/bay',
  COMPARE_LIST: '/compare/list',
  COMPARE_SUMMARY: '/compare/summary',
  COMPARE_CHARTS: '/compare/charts',

  CODE_OPERATOR: '/code/carrier',
  CODE_VESSEL: '/code/vessel',
  CODE_PORT: '/code/port',
  CODE_SERVICE_LANE: '/code/service-lane',
  CODE_WEIGHT_GROUP: '/code/weight-group',
  CODE_SIZE_TYPE: '/code/size-type',

  ADMIN_VESSEL_DESIGN: '/admin/vessel-design',
  SYSTEM_CODE: '/admin/system-code',
  AGREEMENT_EDIT: '/admin/agreement-edit',
  COLOR: '/admin/color',
  TEAMS: '/teams'
};
