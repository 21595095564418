import {Datetime} from '../../my-plans/service/model/datetime';
import {DatePipe} from '@angular/common';

export class DateTimeUtils {

  /**
   * 203: CCYYMMDDHHMM..
   * @param datetime
   */
  static toEdiFormat(datetime: string): string {
    return this.toEdiFormatLength(datetime, 12);
  }

  static toEdiFormatLength(datetime: string, length: number): string {
    datetime = datetime.replace('-', '').replace('-', '').replace('T', '').replace(':', '').replace(':', '');
    if (!datetime) {
      return '';
    }
    if (datetime.length === 6 || datetime.length === 8 || datetime.length === 10 || datetime.length === 13) {
      datetime = '20' + datetime;
    }
    return datetime.padEnd(length, '0').substring(0, length);
  }

  /**
   * YYYY-MM-DD'T'HH:MM..
   * @param datetime
   */
  static toRfcFormat(dt: string): string {

    const datetime = this.toEdiFormat(dt);

    // console.info(datetime + '\t' + dt);

    if (datetime.length < 12) {
      return datetime;

    } else if (datetime.length === 12) {
      return datetime.substring(0, 4) + '-' + datetime.substring(4, 6) + '-' + datetime.substring(6, 8) + 'T' + datetime.substring(8, 10) + ':' + datetime.substring(10);

    } else {
      return datetime.substring(0, 4) + '-' + datetime.substring(4, 6) + '-' + datetime.substring(6, 8) + 'T'
        + datetime.substring(8, 10) + ':' + datetime.substring(10, 12) + ':' + datetime.substring(12);
    }
  }

  /**
   * yyyy-MM-ddTHH:mm:ss
   * @param addDay
   */
  static getLocalDateTime(addDay = 0): string {

    const date = new Date();
    const offset = date.getTimezoneOffset();

    const localDate = new Date(date.getTime() - (offset * 60 * 1000) + (addDay * 60 * 60 * 1000 * 24));

    return localDate.toISOString().substring(0, 19);
  }
  /**
   * @deprecated
   * @param dateTime
   */
  private static parseDateTime2String(datetime: Datetime): string {
    let date = datetime.dateTime;
    if ( datetime.dateTimeFormat === '101' && datetime.dateTime.length > 5) {
      date = '20' + datetime.dateTime.substring(0, 2) + datetime.dateTime.substring(2, 4) + datetime.dateTime.substring(4, 6) + '0000';
    } else if ( datetime.dateTimeFormat === '102' && datetime.dateTime.length > 6) {
      date = datetime.dateTime.substring(0, 4) + datetime.dateTime.substring(4, 6) + datetime.dateTime.substring(6, 8) + '0000';
      // } else if ( datetime.dateTimeFormatQualifier === '201' && datetime.dateTime.length > 9) {
      //   date = '20' + datetime.dateTime.substring(0, 2) + '-' + datetime.dateTime.substring(2, 4) + '-' + datetime.dateTime.substring(4, 6)
      //     + 'T' + datetime.dateTime.substring(6, 8) + ':' + datetime.dateTime.substring(8, 10);
    } else if ( datetime.dateTimeFormat === '203' && datetime.dateTime.length > 11) {
      date = datetime.dateTime.substring(0, 4) + datetime.dateTime.substring(4, 6) + datetime.dateTime.substring(6, 8) + datetime.dateTime.substring(8, 10) + datetime.dateTime.substring(10, 12);
    } else {
      date = this.toStandardFormat(datetime.dateTime);
    }
    return date;
  }
  static formatDateTime(dateTime: string): string {

    if (dateTime) {
      const dateTime2 = DateTimeUtils.toRfcFormat(dateTime);
      const m = dateTime2.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{1,2}):(\d{1,2})/);
      if (m) {
        const datePipe = new DatePipe('en-US');
        try {
          return datePipe.transform(new Date(dateTime2), 'yyyy/MM/dd HH:mm');
        } catch (e) {
          // console.error(dateTime + ' -> ' + dateTime2 + '\t' + e);
          // return  ;
        }
      }
    }
    return dateTime;
  }
  /**
   * @deprecated
   * @param dateTime
   */
  private static toStandardFormat(dateTime: string): string {
    dateTime = dateTime.replace('-', '').replace('-', '').replace('T', '').replace(':', '');
    let date = dateTime;
    if ( dateTime.length > 11) {
      date = dateTime.substring(0, 12);
    } else if (dateTime.length > 9) {
      date = '20' + dateTime.substring(0, 2) + dateTime.substring(2, 4) + dateTime.substring(4, 6) + dateTime.substring(6, 8) + dateTime.substring(8, 10);
    } else if (dateTime.length > 7) {
      date = '20' + dateTime.substring(0, 2) + dateTime.substring(2, 4) + dateTime.substring(4, 6) + dateTime.substring(6, 8) + '00';
    } else if (dateTime.length > 5) {
      date = '20' + dateTime.substring(0, 2) + dateTime.substring(2, 4) + dateTime.substring(4, 6) + '0000';
    }
    return date;
  }
  /**
  * @deprecated
  * @param dateTime
  */
  private static toDisplayFormat(dateTime: string): string {
    if (dateTime.length === 12) {
      return dateTime.substring(0, 4) + '-' + dateTime.substring(4, 6) + '-' + dateTime.substring(6, 8) + 'T' + dateTime.substring(8, 10) + ':' + dateTime.substring(10, 12);
    }
    return dateTime;
  }

}
