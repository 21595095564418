import { Injectable } from '@angular/core';
import Dexie, {PromiseExtended} from 'dexie';
import {SessionIds} from '../core/service/session-ids';
import {Observable} from 'rxjs';

export interface Entry {
  seq?: number;
  userId: string;
  id?: string;
  content: string;
}
@Injectable({
  providedIn: 'root'
})
export class LocalDbService {

  vesselTable: Dexie.Table<Entry, [string, string]>;
  ediTextTable: Dexie.Table<Entry, [string, string]>;
  preferenceTable: Dexie.Table<Entry, [string, string]>;
  consoleTable: Dexie.Table<Entry, number>;
  db = new Dexie('database');

  constructor() {
    this.db.version(31 ).stores(
      {
        vessels: '[userId+id], userId, id',
        ediTexts: '[userId+id], userId, id',
        preferences: '[userId+id], userId, id',
        console: '++seq, userId',
      }
    );
    this.vesselTable = this.db.table('vessels');
    this.ediTextTable = this.db.table('ediTexts');
    this.preferenceTable = this.db.table('preferences');
    this.consoleTable = this.db.table('console');
  }
  putPreference(key: string, value: string) {
    this.preferenceTable.put({userId: sessionStorage.getItem(SessionIds.USER_ID), id: key, content: value});
  }
  getPreference(key: string): Observable<string> {

    return new Observable<string>( (observer) => {
      this.preferenceTable.where({userId: sessionStorage.getItem(SessionIds.USER_ID), id: key}).first().then(item => {
        observer.next(item.content);
        observer.complete();
      }, error  => {
        observer.next('');
        observer.complete();
      });
    });
  }
}
