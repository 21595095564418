<div *ngIf="showProgress">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div fxLayout="row" fxLayoutAlign="start stretch" style="width: 98%; height: {{bottomHeight-5}}px;">
  <textarea #lineNumberText class="rownr" rows="20"
                  cols="3" [value]="lineNumbers"
            matInput
            readonly></textarea>
      <textarea #ediTextArea style="margin-left: 4px;font-family: 'Consolas';"
                matInput [value]="ediText"
                readonly
                (scroll)="lineNumberText.scrollTop = ediTextArea.scrollTop"
      ></textarea>
</div>

