import {EditableModel} from '../../../be-com/etable/editable-model';

export class SyntaxMessageNode implements EditableModel {
  expandable: boolean;
  level: number;
  children?: SyntaxMessageNode[] = [];

  category: string;
  lineIndex: number;
  lineText: string;
  syntaxName: string;
  description: string;
  stowage = '';

  constructor() {
    this.children = [];
  }

  id: string;
  modified: boolean;
}
