<div *ngIf="showProgress">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div>
  <mat-form-field class="two-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.STOWAGE)}}</mat-label>
    <input matInput type="text"  [(ngModel)]="container.stowage">
  </mat-form-field>
  <mat-form-field class="two-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.CONTAINER_NO)}}</mat-label>
    <input matInput type="text"  style="text-transform:uppercase"
           [ngModel]="container.containerNo"
           (ngModelChange) = "container.containerNo = $event.toUpperCase()"
    >
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.CARRIER)}}</mat-label>
    <input matInput type="text"  style="text-transform:uppercase"
           [ngModel]="container.carrier"
           (ngModelChange) = "container.carrier = $event.toUpperCase()"
           [formControl]="operatorControl"
           [matAutocomplete]="autoOperator">
    <mat-autocomplete #autoOperator="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isOperatorLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isOperatorLoading">
        <mat-option *ngFor="let operator of filteredOperators" [value]="operator.code">
          <span>{{operator.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.ISO_SIZE_TYPE)}}</mat-label>
    <input matInput type="text"  style="text-transform:uppercase"
           [ngModel]="container.isoSizeType"
           (ngModelChange) = "container.isoSizeType = $event.toUpperCase()"
           [formControl]="sizeTypeControl"
           [matAutocomplete]="autoSizeType">
    <mat-autocomplete #autoSizeType="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isSizeTypeLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isSizeTypeLoading">
        <mat-option *ngFor="let sizeType of filteredSizeTypes" [value]="sizeType.code">
          <span>{{sizeType.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field appearance="fill" class="unit-form-field">
    <mat-label>Full/Empty</mat-label>
    <mat-select [(ngModel)]="container.fullEmpty">
      <mat-option *ngFor="let unit of fullEmpty" [value]="unit" >
        {{unit}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="property-group">
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.POR)}}</mat-label>
    <input matInput type="text" style="text-transform:uppercase"
           [ngModel]="container.por"
           (ngModelChange) = "container.por = $event.toUpperCase()"
           [formControl]="porControl"
           [matAutocomplete]="autoPor">
    <mat-autocomplete #autoPor="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isPorLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isPorLoading">
        <mat-option *ngFor="let port of filteredPors" [value]="port.code">
          <span>{{port.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.POL)}}</mat-label>
    <input matInput type="text"  style="text-transform:uppercase"
           [ngModel]="container.pol"
           (ngModelChange) = "container.pol = $event.toUpperCase()"
           [formControl]="polControl"
           [matAutocomplete]="autoPol">
    <mat-autocomplete #autoPol="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isPolLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isPolLoading">
        <mat-option *ngFor="let port of filteredPols" [value]="port.code">
          <span>{{port.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.POD)}}</mat-label>
    <input matInput type="text"  style="text-transform:uppercase"
           [ngModel]="container.pod"
           (ngModelChange) = "container.pod = $event.toUpperCase()"
           [formControl]="podControl"
           [matAutocomplete]="autoPod">
    <mat-autocomplete #autoPod="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isPodLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isPodLoading">
        <mat-option *ngFor="let port of filteredPods" [value]="port.code">
          <span>{{port.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.NPOD)}}</mat-label>
    <input matInput type="text"  style="text-transform:uppercase"
           [ngModel]="container.nPod"
           (ngModelChange) = "container.nPod = $event.toUpperCase()"
           [formControl]="nPodControl"
           [matAutocomplete]="autoNpod">
    <mat-autocomplete #autoNpod="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isNpodLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isNpodLoading">
        <mat-option *ngFor="let port of filteredNpods" [value]="port.code">
          <span>{{port.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.FPOD)}}</mat-label>
    <input matInput type="text"  style="text-transform:uppercase"
           [ngModel]="container.fPod"
           (ngModelChange) = "container.fPod = $event.toUpperCase()"
           [formControl]="fPodControl"
           [matAutocomplete]="autoNpod">
    <mat-autocomplete #autoNpod="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isFpodLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isFpodLoading">
        <mat-option *ngFor="let port of filteredFpods" [value]="port.code">
          <span>{{port.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD)}}</mat-label>
    <input matInput type="text"
           [ngModel]="container.optPod"
           (ngModelChange) = "container.optPod = $event.toUpperCase()"
           [formControl]="optPodControl"
           [matAutocomplete]="autoOptPod">
    <mat-autocomplete #autoOptPod="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isOptPodLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isOptPodLoading">
        <mat-option *ngFor="let port of filteredOptPods" [value]="port.code">
          <span>{{port.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD1)}}</mat-label>
    <input matInput type="text"  style="text-transform:uppercase"
           [ngModel]="container.optPod1"
           (ngModelChange) = "container.optPod1 = $event.toUpperCase()"
           [formControl]="optPod1Control"
           [matAutocomplete]="autoOptPod1">
    <mat-autocomplete #autoOptPod1="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isOptPod1Loading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isOptPod1Loading">
        <mat-option *ngFor="let port of filteredOptPod1s" [value]="port.code">
          <span>{{port.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD2)}}</mat-label>
    <input matInput type="text"  style="text-transform:uppercase"
           [ngModel]="container.optPod2"
           (ngModelChange) = "container.optPod2 = $event.toUpperCase()"
           [formControl]="optPod2Control"
           [matAutocomplete]="autoOptPod2">
    <mat-autocomplete #autoOptPod2="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isOptPod2Loading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isOptPod2Loading">
        <mat-option *ngFor="let port of filteredOptPod2s" [value]="port.code">
          <span>{{port.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD3)}}</mat-label>
    <input matInput type="text"  style="text-transform:uppercase"
           [ngModel]="container.optPod3"
           (ngModelChange) = "container.optPod3 = $event.toUpperCase()"
           [formControl]="optPod3Control"
           [matAutocomplete]="autoOptPod3">
    <mat-autocomplete #autoOptPod3="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isOptPod3Loading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isOptPod3Loading">
        <mat-option *ngFor="let port of filteredOptPod3s" [value]="port.code">
          <span>{{port.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD4)}}</mat-label>
    <input matInput type="text"  style="text-transform:uppercase"
           [ngModel]="container.optPod4"
           (ngModelChange) = "container.optPod4 = $event.toUpperCase()"
           [formControl]="optPod4Control"
           [matAutocomplete]="autoOptPod4">
    <mat-autocomplete #autoOptPod4="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isOptPod4Loading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isOptPod4Loading">
        <mat-option *ngFor="let port of filteredOptPod4s" [value]="port.code">
          <span>{{port.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD5)}}</mat-label>
    <input matInput type="text"  style="text-transform:uppercase"
           [ngModel]="container.optPod5"
           (ngModelChange) = "container.optPod5 = $event.toUpperCase()"
           [formControl]="optPod5Control"
           [matAutocomplete]="autoOptPod5">
    <mat-autocomplete #autoOptPod5="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isOptPod5Loading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isOptPod5Loading">
        <mat-option *ngFor="let port of filteredOptPod5s" [value]="port.code">
          <span>{{port.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.TS_PORT)}}</mat-label>
    <input matInput type="text"  style="text-transform:uppercase"
           [ngModel]="container.tsPort"
           (ngModelChange) = "container.tsPort = $event.toUpperCase()"
           [formControl]="tsPortControl"
           [matAutocomplete]="autoTsPort">
    <mat-autocomplete #autoTsPort="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isTsPortLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
      <ng-container *ngIf="!isTsPortLoading">
        <mat-option *ngFor="let port of filteredTsPorts" [value]="port.code">
          <span>{{port.code}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="three-form-field" >
    <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.PLACE_OF_DELIVERY)}}</mat-label>
    <input matInput type="text"  [(ngModel)]="container.placeOfDelivery">
  </mat-form-field>
</div>
<div class="property-group">
  <div>
    <mat-form-field class="value-unit-field">
      <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.GROSS_WEIGHT)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.grossWeight" [formControl]="grossWeightFormControl" >
<!--      <mat-error *ngIf="form.controls['grossWeightFormControl'].errors">-->
<!--        Number only-->
<!--      </mat-error>-->
      <small *ngIf="grossWeightFormControl.errors" class="mat-text-warn">
        Number only
      </small>
    </mat-form-field>
    <mat-form-field appearance="fill" class="unit-form-field">
      <mat-label>Unit</mat-label>
      <mat-select [(ngModel)]="container.grossWeightUnit">
        <mat-option *ngFor="let unit of weightUnits" [value]="unit" >
          {{unit}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="value-unit-field">
      <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.VGM_WEIGHT)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.vgmWeight" [formControl]="vgmWeightFormControl">
      <small *ngIf="vgmWeightFormControl.errors" class="mat-text-warn">
        Number only
      </small>
    </mat-form-field>
    <mat-form-field appearance="fill" class="unit-form-field">
      <mat-label>Unit</mat-label>
      <mat-select [(ngModel)]="container.vgmWeightUnit">
        <mat-option *ngFor="let unit of weightUnits" [value]="unit" >
          {{unit}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-form-field class="column-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.VGM_CERTI_CODE)}}</mat-label>
    <input matInput type="text" #vgmCertiCode (keyup)="vgmCertiCodeChanged(vgmCertiCode.value)" [value]="getVgmCertiCode()">
  </mat-form-field>
  <mat-form-field class="column-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.VGM_INFO1)}}</mat-label>
    <input matInput type="text" #vgmInfo1 (keyup)="vgmInfoChanged(vgmInfo1.value, 1)" [value]="getVgmInfo(1)">
  </mat-form-field>
  <mat-form-field class="column-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.VGM_INFO2)}}</mat-label>
    <input matInput type="text" #vgmInfo2 (keyup)="vgmInfoChanged(vgmInfo2.value, 2)" [value]="getVgmInfo(2)">
  </mat-form-field>
  <mat-form-field class="column-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.VGM_INFO3)}}</mat-label>
    <input matInput type="text" #vgmInfo3 (keyup)="vgmInfoChanged(vgmInfo3.value, 3)" [value]="getVgmInfo(3)">
  </mat-form-field>
  <mat-form-field class="column-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.VGM_INFO4)}}</mat-label>
    <input matInput type="text" #vgmInfo4 (keyup)="vgmInfoChanged(vgmInfo4.value, 4)" [value]="getVgmInfo(4)">
  </mat-form-field>
  <mat-form-field class="column-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.VGM_INFO5)}}</mat-label>
    <input matInput type="text" #vgmInfo5 (keyup)="vgmInfoChanged(vgmInfo5.value, 5)" [value]="getVgmInfo(5)">
  </mat-form-field>
</div>
<div class="property-group">
  <div>
    <mat-form-field class="value-unit-field">
      <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.TEMPERATURE)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.temperature" [formControl]="temperatureFormControl">
      <small *ngIf="temperatureFormControl.errors" class="mat-text-warn">
        Number only
      </small>
    </mat-form-field>
    <mat-form-field appearance="fill" class="unit-form-field">
      <mat-label>Unit</mat-label>
      <mat-select [(ngModel)]="container.temperatureUnit">
        <mat-option *ngFor="let unit of temperatureUnits" [value]="unit" >
          {{unit}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div style="margin-left: 5px">
    Temperature range:
  </div>
  <div>
    <mat-form-field class="three-form-field" >
      <mat-label>Min</mat-label>
      <input matInput type="text" [(ngModel)]="container.temperatureRangeMin" [formControl]="minTemperatureFormControl">
      <small *ngIf="minTemperatureFormControl.errors" class="mat-text-warn">
        Number only
      </small>
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>Max</mat-label>
      <input matInput type="text" [(ngModel)]="container.temperatureRangeMax" [formControl]="maxTemperatureFormControl">
      <small *ngIf="maxTemperatureFormControl.errors" class="mat-text-warn">
        Number only
      </small>
    </mat-form-field>
    <mat-form-field appearance="fill" class="unit-form-field">
      <mat-label>Unit</mat-label>
      <mat-select [(ngModel)]="container.temperatureRangeUnit">
        <mat-option *ngFor="let unit of temperatureUnits" [value]="unit" >
          {{unit}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="property-group">
  <div>
    <mat-form-field class="value-unit-field">
      <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.OVER_DIMENSION_FRONT)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.overDimFront" [formControl]="overDimFrontFormControl">
      <small *ngIf="overDimFrontFormControl.errors" class="mat-text-warn">
        Number only
      </small>
    </mat-form-field>
    <mat-form-field appearance="fill" class="unit-form-field">
      <mat-label>Unit</mat-label>
      <mat-select [(ngModel)]="container.overDimFrontUnit">
        <mat-option *ngFor="let unit of dimensionUnits" [value]="unit" >
          {{unit}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="value-unit-field">
      <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.OVER_DIMENSION_BACK)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.overDimBack" [formControl]="overDimBackFormControl">
      <small *ngIf="overDimBackFormControl.errors" class="mat-text-warn">
        Number only
      </small>
    </mat-form-field>
    <mat-form-field appearance="fill" class="unit-form-field">
      <mat-label>Unit</mat-label>
      <mat-select [(ngModel)]="container.overDimBackUnit">
        <mat-option *ngFor="let unit of dimensionUnits" [value]="unit" >
          {{unit}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="value-unit-field">
      <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.OVER_DIMENSION_RIGHT)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.overDimRight" [formControl]="overDimRightFormControl">
      <small *ngIf="overDimRightFormControl.errors" class="mat-text-warn">
        Number only
      </small>
    </mat-form-field>
    <mat-form-field appearance="fill" class="unit-form-field">
      <mat-label>Unit</mat-label>
      <mat-select [(ngModel)]="container.overDimRightUnit">
        <mat-option *ngFor="let unit of dimensionUnits" [value]="unit" >
          {{unit}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="value-unit-field">
      <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.OVER_DIMENSION_LEFT)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.overDimLeft" [formControl]="overDimLeftFormControl">
      <small *ngIf="overDimLeftFormControl.errors" class="mat-text-warn">
        Number only
      </small>
    </mat-form-field>
    <mat-form-field appearance="fill" class="unit-form-field">
      <mat-label>Unit</mat-label>
      <mat-select [(ngModel)]="container.overDimLeftUnit">
        <mat-option *ngFor="let unit of dimensionUnits" [value]="unit" >
          {{unit}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="value-unit-field">
      <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.OVER_DIMENSION_HEIGHT)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.overDimHeight" [formControl]="overDimHeightFormControl">
      <small *ngIf="overDimHeightFormControl.errors" class="mat-text-warn">
        Number only
      </small>
    </mat-form-field>
    <mat-form-field appearance="fill" class="unit-form-field">
      <mat-label>Unit</mat-label>
      <mat-select [(ngModel)]="container.overDimHeightUnit">
        <mat-option *ngFor="let unit of dimensionUnits" [value]="unit" >
          {{unit}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="property-group">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <div style="margin-left: 5px;margin-top: 10px">Over slots</div>
    <mat-form-field class="three-form-field" >
      <mat-label>Front</mat-label>
      <input matInput type="text"  [(ngModel)]="container.overSlotFront" [formControl]="overSlotFrontFormControl">
      <small *ngIf="overSlotFrontFormControl.errors" class="mat-text-warn">
        Natural number only
      </small>
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>Back</mat-label>
      <input matInput type="text"  [(ngModel)]="container.overSlotBack" [formControl]="overSlotBackFormControl">
      <small *ngIf="overSlotBackFormControl.errors" class="mat-text-warn">
        Natural number only
      </small>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <mat-form-field class="three-form-field" >
      <mat-label>Right</mat-label>
      <input matInput type="text"  [(ngModel)]="container.overSlotRight" [formControl]="overSlotRightFormControl">
      <small *ngIf="overSlotRightFormControl.errors" class="mat-text-warn">
        Natural number only
      </small>
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>Left</mat-label>
      <input matInput type="text"  [(ngModel)]="container.overSlotLeft" [formControl]="overSlotLeftFormControl">
      <small *ngIf="overSlotLeftFormControl.errors" class="mat-text-warn">
        Natural number only
      </small>
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>Height</mat-label>
      <input matInput type="text"  [(ngModel)]="container.overSlotHeight" [formControl]="overSlotHeightFormControl">
      <small *ngIf="overSlotHeightFormControl.errors" class="mat-text-warn">
        Natural number only
      </small>
    </mat-form-field>
  </div>
</div>
<div class="property-group">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <div style="margin-left: 5px;margin-top: 10px">Break bulk</div>
    <mat-form-field appearance="fill" class="unit-form-field">
      <mat-label>Unit</mat-label>
      <mat-select [(ngModel)]="container.breakBulkDimensionUnit">
        <mat-option *ngFor="let unit of dimensionUnits" [value]="unit" >
          {{unit}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="three-form-field" >
      <mat-label>Length</mat-label>
      <input matInput type="text"  [(ngModel)]="container.breakBulkLength" [formControl]="breakBulkLengthFormControl">
      <small *ngIf="breakBulkHeightFormControl.errors" class="mat-text-warn">
        Number only
      </small>
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>Height</mat-label>
      <input matInput type="text"  [(ngModel)]="container.breakBulkHeight" [formControl]="breakBulkHeightFormControl">
      <small *ngIf="breakBulkHeightFormControl.errors" class="mat-text-warn">
        Number only
      </small>
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>Width</mat-label>
      <input matInput type="text"  [(ngModel)]="container.breakBulkWidth" [formControl]="breakBulkWidthFormControl">
      <small *ngIf="breakBulkWidthFormControl.errors" class="mat-text-warn">
        Number only
      </small>
    </mat-form-field>
  </div>
</div>
<div class="property-group">
  <mat-form-field class="column-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.DESCRIPTION_OF_GOODS)}}</mat-label>
    <input matInput type="text" #AAA [value]="getRemark(ColumnIds.DESCRIPTION_OF_GOODS)" (keyup)="remarkChanged(ColumnIds.DESCRIPTION_OF_GOODS, AAA.value)">
  </mat-form-field>
  <mat-form-field class="column-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.HANDLING_INSTRUCTION)}}</mat-label>
    <input matInput type="text" #HAN [value]="getRemark(ColumnIds.HANDLING_INSTRUCTION)" (keyup)="remarkChanged(ColumnIds.HANDLING_INSTRUCTION, HAN.value)" >
  </mat-form-field>
  <mat-form-field class="column-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.LOADING_REMARK)}}</mat-label>
    <input matInput type="text" #CLR [value]="getRemark(ColumnIds.LOADING_REMARK)" (keyup)="remarkChanged(ColumnIds.LOADING_REMARK, CLR.value)">
  </mat-form-field>
  <mat-form-field class="column-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.SPECIAL_CARGO_INSTRUCTION)}}</mat-label>
    <input matInput type="text" #SIN [value]="getRemark(ColumnIds.SPECIAL_CARGO_INSTRUCTION)" (keyup)="remarkChanged(ColumnIds.SPECIAL_CARGO_INSTRUCTION, SIN.value)">
  </mat-form-field>
  <mat-form-field class="column-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.GENERAL_INFORMATION)}}</mat-label>
    <input matInput type="text" #AAI [value]="getRemark(ColumnIds.GENERAL_INFORMATION)" (keyup)="remarkChanged(ColumnIds.GENERAL_INFORMATION, AAI.value)">
  </mat-form-field>
  <mat-form-field class="column-form-field" >
    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.MUTUALLY_DEFINED_REMARK)}}</mat-label>
    <input matInput type="text" #ZZZ [value]="getRemark(ColumnIds.MUTUALLY_DEFINED_REMARK)" (keyup)="remarkChanged(ColumnIds.MUTUALLY_DEFINED_REMARK, ZZZ.value)">
  </mat-form-field>
<!--  <mat-form-field class="column-form-field" >-->
<!--    <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.GROUP_CODE)}}</mat-label>-->
<!--    <input matInput type="text"  [(ngModel)]="container.groupCode">-->
<!--  </mat-form-field>-->
</div>
<div class="property-group">
    <mat-form-field class="two-form-field" >
      <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.BL_NO)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.blNo">
    </mat-form-field>
    <mat-form-field class="two-form-field" >
      <mat-label>{{ColumnUtils.getDefaultFullName(ColumnIds.BOOKING_NO)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.bookingNo">
    </mat-form-field>
</div>
<div class="property-group">
  <div style="margin-left: 10px">
    Terminal code of:
  </div>
  <div>
    <mat-form-field class="three-form-field" >
      <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.POR_TERMINAL)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.porTerminalCode">
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.POL_TERMINAL)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.polTerminalCode">
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.POD_TERMINAL)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.podTerminalCode">
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.NPOD_TERMINAL)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.nPodTerminalCode">
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD_TERMINAL)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.optPodTerminalCode">
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD1_TERMINAL)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.optPod1TerminalCode">
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD2_TERMINAL)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.optPod2TerminalCode">
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD3_TERMINAL)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.optPod3TerminalCode">
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD4_TERMINAL)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.optPod4TerminalCode">
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD5_TERMINAL)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.optPod5TerminalCode">
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.TS_TERMINAL)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.tsTerminalCode">
    </mat-form-field>
    <mat-form-field class="three-form-field" >
      <mat-label>{{ColumnUtils.getDefaultShortName(ColumnIds.PLACE_OF_DELIVERY_TERMINAL)}}</mat-label>
      <input matInput type="text"  [(ngModel)]="container.placeOfDeliveryTerminalCode">
    </mat-form-field>
  </div>
</div>

