export class EditableColumn {

  static LINE_SELECTION = 2;
  static CELL_SELECTION = 4;
  // static NO_SELECTION = 8;
  static EDITABLE = 8;
  static COLORABLE = 16;

  colorable = false;
  cellSelection = false;
  lineSelection = false;
  editable = false;

  constructor(public code: string, public name: string, private style?: number) {
    this.colorable = (this.style | EditableColumn.COLORABLE) === this.style;
    this.editable = (this.style | EditableColumn.EDITABLE) === this.style;
    this.lineSelection = (this.style | EditableColumn.LINE_SELECTION) === this.style;
    this.cellSelection = this.editable || ((this.style | EditableColumn.CELL_SELECTION) === this.style);

    // console.info(code + '\tthis.colorable:' + this.colorable + ', this.editable:' + this.editable + ', this.lineSelection:' + this.lineSelection
    // + ', this.cellSelection:' + this.cellSelection);
  }
}
