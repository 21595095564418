import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {properties} from '../../../../environments/environment';
import {EdiOperator} from './model/edi.operator';
import {Operator} from './model/operator';
import {Observable} from 'rxjs';
import {EdiOperatorInput} from './model/edi.operator.input';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {

  constructor(private http: HttpClient) { }

  getOwnOperators() {
    return this.http.get<Operator[]>(properties.serverUrl + '/operator/own');
  }
  getEdiOperators() {
    return this.http.get<EdiOperator[]>(properties.serverUrl + '/operator/edi');
  }
  updateOwnOperators(operators: Operator[]) {
    return this.http.post(properties.serverUrl + '/operator/own', operators);
  }
  changeOperatorColor(operator: Operator, serviceLaneCode?: string) {
    return this.http.post(properties.serverUrl + '/operator/color?lane=' + serviceLaneCode, operator);
  }
  updateEdiOperators(operators: EdiOperator[]) {
    return this.http.post(properties.serverUrl + '/operator/edi', operators);
  }
  deleteOwnOperators(operators: Operator[]) {

    let ids = '';

    operators.forEach(port => {

      ids = ids + ',' + port.id;
    });
    return this.http.delete(properties.serverUrl + '/operator/own?id=' + ids);
  }
  deleteEdiOperators(operators: EdiOperator[]) {

    let ids = '';

    operators.forEach(data => {

      ids = ids + ',' + data.id;
    });
    return this.http.delete(properties.serverUrl + '/operator/edi?id=' + ids);
  }
  getOperatorsBeforeReconciled(operatorCodes: string[]) : Observable<EdiOperator[]>{

    let codes = '';

    operatorCodes.forEach(data => {

      codes = codes + ',' + data;
    });
    return this.http.get<EdiOperator[]>(properties.serverUrl + '/operator/reconcile?code=' + codes);
  }
  reconcileOperators(items: EdiOperatorInput[]): Observable<Operator[]> {

    return this.http.post<Operator[]>(properties.serverUrl + '/operator/reconcile', items);
  }
}
