import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {OperatorButtonEvent} from '../../code/operator/operator.component';
import {TabButtonEvent} from '../../core/event/tab-button.event';
import {TabAction} from '../../core/event/tab-action';

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.css']
})
export class TeamMembersComponent implements OnInit {

  @Output()
  tabEmitter = new EventEmitter<OperatorButtonEvent>();

  showProgressEvent = new EventEmitter<boolean>();

  private selectedTabIndex = 0;
  constructor() { }

  ngOnInit(): void {
  }
  selectTab($event: MatTabChangeEvent) {
    this.selectedTabIndex = $event.index;
    this.search();
  }
  search() {
    this.tabEmitter.emit(new TabButtonEvent(TabAction.SEARCH, this.selectedTabIndex));
  }
}

