import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {
  vesselDesign = localStorage.getItem(PreferenceCode.VesselDesign);

  constructor() { }

  ngOnInit(): void {

  }

  save() {

  }

  changeValue(code: string) {
    localStorage.setItem(code, this.vesselDesign);

    console.info(code + ': ' + localStorage.getItem(code));
  }
}
export enum PreferenceCode {
  VesselDesign = 'VesselDesign'
}
export enum PreferenceValue {
  VesselDesign_Certified = 'Certified',
  VesselDesign_Baplie = 'Baplie'
}
