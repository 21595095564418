import {Equipment} from './equipment';

export class ContainerEquipment {
  equipment: Equipment;
  attachEquipments: Array<Equipment>;
  carrier: string;
  carrierParty = 'CA';
  carrierAgency = '20';
  constructor() {
    this.equipment = new Equipment();
    this.attachEquipments = new Array<Equipment>();
  }
}
