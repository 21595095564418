<mat-toolbar-row style="width: 100%">
  <mat-form-field appearance="legacy" class="unit-form-field">
<!--    <mat-label>Bay</mat-label>-->
    <mat-select [(value)]="toolbarEventService.layoutZoom.layoutMode" (selectionChange)="changeHatch($event.value)">
      <ng-container *ngIf="hatches">
        <mat-option *ngFor="let hatch of hatches" [value]="hatch.name">
          {{hatch.displayName}}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <button mat-icon-button color="basic" (click)="clickLeftButton()" matTooltip="Go to previous bays" [disabled] = "!leftButtonEnabled">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-icon-button color="basic" (click)="clickRightButton()" matTooltip="Go to next bays" [disabled] = "!rightButtonEnabled">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
    <button *ngIf="showZoomButtons()" mat-icon-button color="basic" (click)="zoomIn()" matTooltip="Zoom In" [disabled] = "!zoomInButtonEnabled">
      <mat-icon>zoom_in</mat-icon>
    </button>
    <button *ngIf="showZoomButtons()" mat-icon-button color="basic" (click)="zoomOut()" matTooltip="Zoom Out" [disabled] = "!zoomOutButtonEnabled">
      <mat-icon>zoom_out</mat-icon>
    </button>
<!--    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" color="basic">-->
<!--      <mat-icon>{{displayTextButton}}</mat-icon>-->
<!--    </button>-->
<!--    <mat-menu #menu="matMenu">-->
<!--      <button mat-menu-item>-->
<!--        <mat-icon>dialpad</mat-icon>-->
<!--        <span>Weight</span>-->
<!--      </button>-->
<!--      <button mat-menu-item>-->
<!--        <mat-icon>voicemail</mat-icon>-->
<!--        <span>Container No</span>-->
<!--      </button>-->
<!--      <button mat-menu-item>-->
<!--        <mat-icon>notifications_off</mat-icon>-->
<!--        <span>H/I</span>-->
<!--      </button>-->
<!--    </mat-menu>-->

    <button mat-icon-button [matMenuTriggerFor]="colorMenu" class="color" aria-label="Example icon-button with a menu" color="basic">
      <!-- <mat-icon>color_lens</mat-icon> -->
      <img src="assets/icons/color_lens_icon_white.png">
      <span>{{getColorLabel()}}</span>
    </button>
    <mat-menu #colorMenu="matMenu">
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_POD)">
        <span>{{getColorLabel(sessionValues.COLOR_POD)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_POD">star</mat-icon>
      </button>
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_POL)">
        <span>{{getColorLabel(sessionValues.COLOR_POL)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_POL">star</mat-icon>
      </button>
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_POR)">
        <span>{{getColorLabel(sessionValues.COLOR_POR)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_POR">star</mat-icon>
      </button>
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_TS_PORT)">
        <span>{{getColorLabel(sessionValues.COLOR_TS_PORT)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_TS_PORT">star</mat-icon>
      </button>
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_NPOD)">
        <span>{{getColorLabel(sessionValues.COLOR_NPOD)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_NPOD">star</mat-icon>
      </button>
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_FPOD)">
        <span>{{getColorLabel(sessionValues.COLOR_FPOD)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_FPOD">star</mat-icon>
      </button>
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_PLACE_OF_DELIVERY)">
        <span>{{getColorLabel(sessionValues.COLOR_PLACE_OF_DELIVERY)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_PLACE_OF_DELIVERY">star</mat-icon>
      </button>
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_LENGTH)">
        <span>{{getColorLabel(sessionValues.COLOR_LENGTH)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_LENGTH">star</mat-icon>
      </button>
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_HEIGHT)">
        <span>{{getColorLabel(sessionValues.COLOR_HEIGHT)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_HEIGHT">star</mat-icon>
      </button>
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_TYPE)">
        <span>{{getColorLabel(sessionValues.COLOR_TYPE)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_TYPE">star</mat-icon>
      </button>
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_OPERATOR)">
        <span>{{getColorLabel(sessionValues.COLOR_OPERATOR)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_OPERATOR">star</mat-icon>
      </button>
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_FULL_EMPTY)">
        <span>{{getColorLabel(sessionValues.COLOR_FULL_EMPTY)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_FULL_EMPTY">star_border</mat-icon>
      </button>
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_CARGO)">
        <span>{{getColorLabel(sessionValues.COLOR_CARGO)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_CARGO">star</mat-icon>
      </button>
      <button mat-menu-item (click)="changeColor(sessionValues.COLOR_WEIGHT_GROUP)">
        <span>{{getColorLabel(sessionValues.COLOR_WEIGHT_GROUP)}}</span>
        <mat-icon *ngIf="colorOption===sessionValues.COLOR_WEIGHT_GROUP">star</mat-icon>
      </button>
    </mat-menu>

<!--    <button mat-icon-button color="accent">-->
<!--      <mat-icon matBadge="5">notification_important</mat-icon>-->
<!--    </button>-->
<!--    <button mat-icon-button color="warn">-->
<!--      <mat-icon matBadge="8">warning</mat-icon>-->
<!--    </button>-->
<!--  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">-->
<!--    <mat-icon>more_vert</mat-icon>-->
<!--  </button>-->
<!--  <mat-menu #menu="matMenu">-->
<!--    <button mat-menu-item (click)="loadableSpace()">-->
<!--      <mat-icon>dialpad</mat-icon>-->
<!--      <span>Loadable IMDG</span>-->
<!--    </button>-->
<!--    <button mat-menu-item>-->
<!--      <mat-icon>voicemail</mat-icon>-->
<!--      <span>Loadable Reefer</span>-->
<!--    </button>-->
<!--    <button mat-menu-item>-->
<!--      <mat-icon>notifications_off</mat-icon>-->
<!--      <span>Loadable 45FT</span>-->
<!--    </button>-->
<!--  </mat-menu>-->
<!--    <mat-button-toggle-group #group="matButtonToggleGroup">-->
<!--      <mat-button-toggle value="left" aria-label="Text align left" (click)="clickWholeBayView()">-->
<!--        <mat-icon>view_module</mat-icon>-->
<!--      </mat-button-toggle>-->
<!--      <mat-button-toggle value="right" aria-label="Text align right" (click)="clickSectionView()">-->
<!--        <mat-icon>view_sidebar</mat-icon>-->
<!--      </mat-button-toggle>-->
<!--      <mat-button-toggle value="justify" aria-label="Text align justify">-->
<!--        <mat-icon>view_column</mat-icon>-->
<!--      </mat-button-toggle>-->
<!--    </mat-button-toggle-group>-->
    <!--
    <button mat-stroked-button class="condition-text">Draft[1.2/2.0/2.3] GM[1.89] V.[350m]</button>
    -->
<!--    <mat-button-toggle-group #group="matButtonToggleGroup" [value]="selectionMode"  color="primary">-->
<!--      <mat-button-toggle value="{{sessionValues.SELECTION_MODE_INFO}}" aria-label="Text align left"-->
<!--                         (click)="changeSelectionMode(sessionValues.SELECTION_MODE_INFO)"-->
<!--                         matTooltip="Searching Information">-->
<!--        <mat-icon>info</mat-icon>-->
<!--      </mat-button-toggle>-->
<!--      <mat-button-toggle value="{{sessionValues.SELECTION_MODE_SHIFT}}" aria-label="Text align justify"-->
<!--                         (click)="changeSelectionMode(sessionValues.SELECTION_MODE_SHIFT)"-->
<!--                         matTooltip="Shifting Containers">-->
<!--        <mat-icon>swap_calls</mat-icon>-->
<!--      </mat-button-toggle>-->
<!--      <mat-button-toggle value="{{sessionValues.SELECTION_MODE_COD}}" aria-label="Text align justify"-->
<!--                         (click)="changeSelectionMode(sessionValues.SELECTION_MODE_COD)"-->
<!--                         matTooltip="Changing POD">-->
<!--        <mat-icon>published_with_changes</mat-icon>-->
<!--      </mat-button-toggle>-->
<!--      <mat-button-toggle value="{{sessionValues.SELECTION_MODE_MOVINS}}" aria-label="Text align right"-->
<!--                         (click)="changeSelectionMode(sessionValues.SELECTION_MODE_MOVINS)"-->
<!--                         matTooltip="Planning MOVINS">-->
<!--        <mat-icon>library_add</mat-icon>-->
<!--      </mat-button-toggle>-->
<!--    </mat-button-toggle-group>-->
<!--    <button mat-icon-button>-->
<!--      <mat-icon>filter_alt</mat-icon>-->
<!--    </button>-->
<!--    <mat-chip-list aria-label="Fish selection">-->
<!--      <mat-chip color="primary" selected class="mat-chip-cargo">RF</mat-chip>-->
<!--      <mat-chip color="accent" selected class="mat-chip-cargo">DG</mat-chip>-->
<!--    </mat-chip-list>-->
<!--  </div>-->
<!--  <div>-->
<!--    <button mat-icon-button color="basic" (click)="undo()">-->
<!--      <mat-icon>undo</mat-icon>-->
<!--    </button>-->
<!--    <button mat-icon-button color="basic" (click)="redo()">-->
<!--      <mat-icon>redo</mat-icon>-->
<!--    </button>-->
<!--    <button mat-icon-button color="accent">-->
<!--      <mat-icon>save</mat-icon>Save-->
<!--    </button>-->
 </mat-toolbar-row>
