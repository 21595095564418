import * as PIXI from 'pixi.js';
import {BayPlanRoot} from './bay-plan-root';
import {SizeType} from '../../code/size-type/service/model/size-type';

export class VisualPart implements BayPlanRoot {

  graphics = new PIXI.Graphics();
  children = new Array<VisualPart>();
  childrenMap = new Map<string, VisualPart>();
  private parent: VisualPart;

  mark: PIXI.DisplayObject;

  protected bounds = new Bounds(0, 0, 0, 0);
  margin = 4;
  lineBorder = 1;

  model: any;
  protected selected = false;

  constructor(parentGraphics: PIXI.Container) {
    this.init();
    parentGraphics.addChild(this.graphics);
  }

  protected init() {
    this.graphics.removeChildren(0, this.graphics.children.length);
    this.graphics.clear();
    this.children = new Array<VisualPart>();
    this.childrenMap = new Map<string, VisualPart>();
  }
  getGraphics() {
    return this.graphics;
  }
  getBounds(): Bounds {
    return this.bounds;
  }
  setBounds(x: number, y: number, width: number, height: number) {

    if (x < 0 || y < 0 || width < 0 || height < 0) {
      // console.error('x: ' + x + ' y: ' + y + ' width: ' + width + ' height: ' + height);
      return;
    }
    this.bounds.x = x;
    this.bounds.y = y;
    this.bounds.width = width;
    this.bounds.height = height;

    if(this.mark) {
      this.mark.x = x + 1;
      this.mark.y = y + 1;
    }
  }

  addChild(key: string, child: VisualPart, bounds?: Bounds) {

    if (bounds) {
      child.setBounds(bounds.x, bounds.y, bounds.width, bounds.height);
    }
    child.setParent(this);
    this.childrenMap.set(key, child);
  }
  deleteChild(key: string) {

    const child = this.getChild(key);
    if (child) {
      this.graphics.removeChild(child.graphics);
      this.childrenMap.delete(key);
    }
  }
  removeChild(item: VisualPart) {

    this.children.forEach((child, index) => {
      if (item === child) {
        this.graphics.removeChild(item.graphics);
        this.children.splice(index, 1);
      }
    });
  }
  removeChildren(items: VisualPart[]) {

    const found = new Array<number>();
    this.children.forEach((child, index) => {
      items.forEach(item => {
        if (item === child) {
          found.push(index);
          this.graphics.removeChild(item.graphics);
        }
      });
    });

    found.forEach(childIndex => {
      this.children.splice(childIndex, 1);
    });

  }

  pushChild(child: VisualPart, bounds?: Bounds) {

    if (bounds) {
      child.setBounds(bounds.x, bounds.y, bounds.width, bounds.height);
    }
    child.setParent(this);
    this.children.push(child);

  }
  getChild(key: string): VisualPart {
    return this.childrenMap.get(key);

  }
  findChildren(instanceType: any): VisualPart[] {
    return this.findVisualPart(this, instanceType);
  }

  private findVisualPart(visualPart: VisualPart, instanceType: any): VisualPart[] {

    const found = new Array<VisualPart>();

    // visualPart.children.forEach(child => {
    //   if (child instanceof instanceType) {
    //     found.push(child);
    //   }
    //   found.push.apply(found, this.findVisualPart(child, instanceType));
    // });

    for (let i = 0 ; i < visualPart.children.length; i++ ) {
      const child = visualPart.children[i];
      if (child instanceof instanceType) {
        found.push(child);
      }
      found.push.apply(found, this.findVisualPart(child, instanceType));
    }

    visualPart.childrenMap.forEach(child => {
      if (child instanceof instanceType) {
        found.push(child);
      }
      found.push.apply(found, this.findVisualPart(child, instanceType));
    });

    return found;
  }

  draw() {
    this.graphics.clear();

    this.childrenMap.forEach( child => {

      child.draw();
    });

    this.children.forEach(child => {

      child.draw();
    });

  }
  setWidth(width: number) {
    this.setBounds(this.bounds.x, this.bounds.y, width, this.bounds.height);
  }
  setHeight(height: number) {
    this.setBounds(this.bounds.x, this.bounds.y, this.bounds.width, height);
  }
  select(mark?: PIXI.DisplayObject) {
    this.selected = true;
    this.draw();

    if (!this.selected) {
      this.removeMark();
    }

    if (mark) {
      this.graphics.removeChild(this.mark);
      this.mark = mark;
      this.mark.x = this.getBounds().x + 1;
      this.mark.y = this.getBounds().y + 1;
      this.graphics.addChild(this.mark);
    }
  }
  deselect() {
    if (!this.selected) {
      return;
    }

    this.selected = false;
    this.removeMark();
    this.draw();
  }
  isSelected(): boolean {
    return this.selected;
  }
  private removeMark() {
    this.graphics.removeChild(this.mark);
    this.mark = null;
  }
  getParent(): VisualPart {
    return this.parent;
  }
  setParent(parent: VisualPart) {
    this.parent = parent;
  }
  getRoot(): VisualPart {

    if (this.parent) {
      return this.parent.getRoot();
    }
    return this;
  }

  getSizeTypeMap(): Map<string, SizeType> {
    // throw new Error('Method not implemented.');
    return undefined;
  }
  getContentDisplayMode(): string {
    // throw new Error('Method not implemented.');
    return '';
  }
  getZoomLevel(): number {
    // throw new Error('Method not implemented.');
    return -1;
  }
}
export class Bounds {
  x: number;
  y: number;
  width: number;
  height: number;

  constructor(x: number, y: number, width: number, height: number) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }
}
