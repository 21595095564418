import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {Container} from '../../../service/model/container';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ColumnService} from '../../columns/service/column.service';
import {ContainerColumn} from '../../columns/service/model/container-column';
import {BbkDimensions, BundleContainers, ColumnUtils, OogDimensions} from '../../columns/service/model/column-utils';
import {ColumnIds} from '../../columns/service/model/column-ids';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BayPlanService} from '../../../service/bay-plan.service';
import {CodeService} from '../../../code/service/code.service';
import {Port} from '../../../code/port/service/model/port';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Operator} from '../../../code/operator/service/model/operator';
import {SizeType} from '../../../code/size-type/service/model/size-type';
import {MatDialog} from '@angular/material/dialog';
import {OkDialogComponent} from '../../../dialogs/ok-dialog/ok-dialog.component';
import {StringUtils} from '../../../utils/string-utils';
import {TranslateService} from '@ngx-translate/core';
import {Freetext} from '../../../my-plans/service/model/freetext';


@Component({
  selector: 'app-container-edit',
  templateUrl: './container-edit.component.html',
  styleUrls: ['./container-edit.component.css']
})
export class ContainerEditComponent implements OnInit, OnDestroy {

  @Input()
  container: Container = new Container();

  @Input()
  containerEmitter = new EventEmitter<Container>();

  private eventSubscription;
  private containerSubscription;

  columns: ContainerColumn[] = [];
  showProgress = false;

  weightUnits = ['', 'KGM', 'LBR'];
  temperatureUnits = ['', 'CEL', 'FAH'];
  dimensionUnits = ['', 'CMT', 'INH'];
  fullEmpty = ['', 'F', 'M'];

  oogDimensions = [OogDimensions.FRONT, OogDimensions.BACK, OogDimensions.RIGHT, OogDimensions.LEFT, OogDimensions.HEIGHT];
  bbkDimensions = [BbkDimensions.LENGTH, BbkDimensions.HEIGHT, BbkDimensions.WIDTH];
  bundleContainers = [BundleContainers.CONTAINER2, BundleContainers.CONTAINER3, BundleContainers.CONTAINER4, BundleContainers.CONTAINER5];
  vgmInfo = [ColumnIds.VGM_CERTI_CODE, ColumnIds.VGM_INFO1, ColumnIds.VGM_INFO2, ColumnIds.VGM_INFO3, ColumnIds.VGM_INFO4, ColumnIds.VGM_INFO5];
  firstLine = [ColumnIds.CONTAINER_NO, ColumnIds.STOWAGE];
  secondLine = [ColumnIds.CARRIER, ColumnIds.ISO_SIZE_TYPE, ColumnIds.FULL_EMPTY];
  blBkg = [ColumnIds.BL_NO, ColumnIds.BOOKING_NO];

  terminalCodes = [ColumnIds.POR_TERMINAL
    , ColumnIds.POL_TERMINAL
    , ColumnIds.POD_TERMINAL
    , ColumnIds.OPT_POD_TERMINAL
    , ColumnIds.OPT_POD1_TERMINAL
    , ColumnIds.OPT_POD2_TERMINAL
    , ColumnIds.OPT_POD3_TERMINAL
    , ColumnIds.OPT_POD4_TERMINAL
    , ColumnIds.OPT_POD5_TERMINAL
    , ColumnIds.NPOD_TERMINAL
    , ColumnIds.FPOD_TERMINAL
    , ColumnIds.TS_TERMINAL
    , ColumnIds.PLACE_OF_DELIVERY_TERMINAL
  ];

  ports = [ColumnIds.POR
    , ColumnIds.POL
    , ColumnIds.POD
    , ColumnIds.OPT_POD1
    , ColumnIds.OPT_POD2
    , ColumnIds.OPT_POD3
    , ColumnIds.OPT_POD4
    , ColumnIds.OPT_POD5
    , ColumnIds.OPT_POD
    , ColumnIds.NPOD
    , ColumnIds.FPOD
    , ColumnIds.TS_PORT
    , ColumnIds.PLACE_OF_DELIVERY
  ];
  ColumnIds = ColumnIds;
  ColumnUtils = ColumnUtils;


  form: FormGroup;

  vgmWeightFormControl = new FormControl('', [
    Validators.pattern(StringUtils.positiveNumberReg)
  ]);
  grossWeightFormControl = new FormControl('', [
    Validators.pattern(StringUtils.positiveNumberReg)
  ]);

  temperatureFormControl = new FormControl('', [
    Validators.pattern(StringUtils.numberReg)
  ]);
  minTemperatureFormControl = new FormControl('', [
    Validators.pattern(StringUtils.numberReg)
  ]);
  maxTemperatureFormControl = new FormControl('', [
    Validators.pattern(StringUtils.numberReg)
  ]);
  overDimFrontFormControl = new FormControl('', [
    Validators.pattern(StringUtils.positiveNumberReg)
  ]);
  overDimBackFormControl = new FormControl('', [
    Validators.pattern(StringUtils.positiveNumberReg)
  ]);
  overDimRightFormControl = new FormControl('', [
    Validators.pattern(StringUtils.positiveNumberReg)
  ]);
  overDimLeftFormControl = new FormControl('', [
    Validators.pattern(StringUtils.positiveNumberReg)
  ]);
  overDimHeightFormControl = new FormControl('', [
    Validators.pattern(StringUtils.positiveNumberReg)
  ]);
  breakBulkLengthFormControl = new FormControl('', [
    Validators.pattern(StringUtils.positiveNumberReg)
  ]);
  breakBulkHeightFormControl = new FormControl('', [
    Validators.pattern(StringUtils.positiveNumberReg)
  ]);
  breakBulkWidthFormControl = new FormControl('', [
    Validators.pattern(StringUtils.positiveNumberReg)
  ]);
  overSlotFrontFormControl = new FormControl('', [
    Validators.pattern(StringUtils.naturalNumberReg)
  ]);
  overSlotBackFormControl = new FormControl('', [
    Validators.pattern(StringUtils.naturalNumberReg)
  ]);
  overSlotRightFormControl = new FormControl('', [
    Validators.pattern(StringUtils.naturalNumberReg)
  ]);
  overSlotLeftFormControl = new FormControl('', [
    Validators.pattern(StringUtils.naturalNumberReg)
  ]);
  overSlotHeightFormControl = new FormControl('', [
    Validators.pattern(StringUtils.naturalNumberReg)
  ]);

  porControl = new FormControl();
  isPorLoading = false;
  filteredPors: Port[];

  polControl = new FormControl();
  isPolLoading = false;
  filteredPols: Port[];

  podControl = new FormControl();
  isPodLoading = false;
  filteredPods: Port[];

  nPodControl = new FormControl();
  isNpodLoading = false;
  filteredNpods: Port[];

  fPodControl = new FormControl();
  isFpodLoading = false;
  filteredFpods: Port[];

  optPodControl = new FormControl();
  isOptPodLoading = false;
  filteredOptPods: Port[];

  optPod1Control = new FormControl();
  isOptPod1Loading = false;
  filteredOptPod1s: Port[];

  optPod2Control = new FormControl();
  isOptPod2Loading = false;
  filteredOptPod2s: Port[];

  optPod3Control = new FormControl();
  isOptPod3Loading = false;
  filteredOptPod3s: Port[];

  optPod4Control = new FormControl();
  isOptPod4Loading = false;
  filteredOptPod4s: Port[];

  optPod5Control = new FormControl();
  isOptPod5Loading = false;
  filteredOptPod5s: Port[];

  tsPortControl = new FormControl();
  isTsPortLoading = false;
  filteredTsPorts: Port[];

  operatorControl = new FormControl();
  isOperatorLoading = false;
  filteredOperators: Operator[];

  sizeTypeControl = new FormControl();
  isSizeTypeLoading = false;
  filteredSizeTypes: SizeType[];

  constructor(private columnService: ColumnService,
              private cdr: ChangeDetectorRef,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              private dialog: MatDialog,
              private bayPlanService: BayPlanService,
              private codeService: CodeService,
              private fb: FormBuilder
  ) { }

  ngOnInit(): void {


    if (!this.container) {
      this.container = new Container();
    }

    this.containerSubscription = this.containerEmitter.subscribe(data => {

      try {
        if (data) {
          this.container = data;
        } else {
          this.container = new Container();
        }

        this.cdr.detectChanges();

      } catch (error) {
        console.error(error);
      }

    });
    this.initFormControls();
    this.initPortControls();
  }
  private initFormControls() {
    this.form = this.fb.group({
      vgmWeightFormControl: this.vgmWeightFormControl,
      grossWeightFormControl: this.grossWeightFormControl,
      temperatureFormControl: this.temperatureFormControl,
      minTemperatureFormControl: this.minTemperatureFormControl,
      maxTemperatureFormControl: this.maxTemperatureFormControl,
      overDimFrontFormControl: this.overDimFrontFormControl,
      overDimBackFormControl: this.overDimBackFormControl,
      overDimRightFormControl: this.overDimRightFormControl,
      overDimLeftFormControl: this.overDimLeftFormControl,
      overDimHeightFormControl: this.overDimHeightFormControl,
      breakBulkLengthFormControl: this.breakBulkLengthFormControl,
      breakBulkHeightFormControl: this.breakBulkHeightFormControl,
      breakBulkWidthFormControl: this.breakBulkWidthFormControl,
      overSlotFrontFormControl: this.overSlotFrontFormControl,
      overSlotBackFormControl: this.overSlotBackFormControl,
      overSlotRightFormControl: this.overSlotRightFormControl,
      overSlotLeftFormControl: this.overSlotLeftFormControl,
      overSlotHeightFormControl: this.overSlotHeightFormControl,
    });
  }
  initPortControls(): void {
    const ports = this.codeService.getOwnPorts();

    this.filteredPors = ports;
    this.porControl.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isPorLoading = true),
        switchMap(value => this.filterPorts(ports, value).pipe(finalize(() => this.isPorLoading = false)), )
      ).subscribe(data => {
        this.filteredPods = data;
    });

    this.filteredPols = ports;
    this.polControl.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isPolLoading = true),
        switchMap(value => this.filterPorts(ports, value).pipe(finalize(() => this.isPolLoading = false)), )
      ).subscribe(data => {
        this.filteredPols = data;
    });

    this.filteredPods = ports;
    this.podControl.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isPodLoading = true),
        switchMap(value => this.filterPorts(ports, value).pipe(finalize(() => this.isPodLoading = false)), )
      ).subscribe(data => {
        this.filteredPods = data;
    });

    this.filteredNpods = ports;
    this.nPodControl.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isNpodLoading = true),
        switchMap(value => this.filterPorts(ports, value).pipe(finalize(() => this.isNpodLoading = false)), )
      ).subscribe(data => {
        this.filteredNpods = data;
    });

    this.filteredFpods = ports;
    this.fPodControl.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isFpodLoading = true),
        switchMap(value => this.filterPorts(ports, value).pipe(finalize(() => this.isFpodLoading = false)), )
      ).subscribe(data => {
        this.filteredFpods = data;
    });

    this.filteredOptPods = ports;
    this.optPodControl.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isOptPodLoading = true),
        switchMap(value => this.filterPorts(ports, value).pipe(finalize(() => this.isOptPodLoading = false)), )
      ).subscribe(data => {
        this.filteredOptPods = data;
    });

    this.filteredOptPod1s = ports;
    this.optPod1Control.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isOptPod1Loading = true),
        switchMap(value => this.filterPorts(ports, value).pipe(finalize(() => this.isOptPod1Loading = false)), )
      ).subscribe(data => {
        this.filteredOptPod1s = data;
    });

    this.filteredOptPod2s = ports;
    this.optPod2Control.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isOptPod2Loading = true),
        switchMap(value => this.filterPorts(ports, value).pipe(finalize(() => this.isOptPod2Loading = false)), )
      ).subscribe(data => {
        this.filteredOptPod2s = data;
    });

    this.filteredOptPod3s = ports;
    this.optPod3Control.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isOptPod3Loading = true),
        switchMap(value => this.filterPorts(ports, value).pipe(finalize(() => this.isOptPod3Loading = false)), )
      ).subscribe(data => {
        this.filteredOptPod3s = data;
    });

    this.filteredOptPod4s = ports;
    this.optPod4Control.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isOptPod4Loading = true),
        switchMap(value => this.filterPorts(ports, value).pipe(finalize(() => this.isOptPod4Loading = false)), )
      ).subscribe(data => {
        this.filteredOptPod4s = data;
    });

    this.filteredOptPod5s = ports;
    this.optPod5Control.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isOptPod5Loading = true),
        switchMap(value => this.filterPorts(ports, value).pipe(finalize(() => this.isOptPod5Loading = false)), )
      ).subscribe(data => {
        this.filteredOptPod5s = data;
    });

    this.filteredTsPorts = ports;
    this.tsPortControl.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isTsPortLoading = true),
        switchMap(value => this.filterPorts(ports, value).pipe(finalize(() => this.isTsPortLoading = false)), )
      ).subscribe(data => {
        this.filteredTsPorts = data;
    });

    const operators = this.codeService.getOwnOperators();
    this.filteredOperators = operators;
    this.operatorControl.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isOperatorLoading = true),
        switchMap(value => this.filterOperators(operators, value).pipe(finalize(() => this.isOperatorLoading = false)), )
      ).subscribe(data => {
        this.filteredOperators = data;
    });

    const sizeTypes = this.codeService.getSizeTypes();
    this.filteredSizeTypes = sizeTypes;
    this.sizeTypeControl.valueChanges
      .pipe(
        debounceTime(100),
        tap(() => this.isSizeTypeLoading = true),
        switchMap(value => this.filterSizeTypes(sizeTypes, value).pipe(finalize(() => this.isSizeTypeLoading = false)), )
      ).subscribe(data => {
        this.filteredSizeTypes = data;
    });
  }
  filterPorts(ports: Port[], filter: string): Observable<Port[]> {
    if (!ports) {
      return of(new Array());
    }
    const list = ports.filter(data => data.code.toUpperCase().startsWith(filter.toUpperCase()));
    return of(list);
  }
  filterOperators(operators: Operator[], filter: string): Observable<Operator[]> {
    if (!operators) {
      return of(new Array());
    }
    const list = operators.filter(data => data.code.toUpperCase().startsWith(filter.toUpperCase()));
    return of(list);
  }
  filterSizeTypes(sizeTypes: SizeType[], filter: string): Observable<SizeType[]> {
    if (!sizeTypes) {
      return of(new Array());
    }
    const list = sizeTypes.filter(data => data.code.toUpperCase().startsWith(filter.toUpperCase()));
    return of(list);
  }
  getUnits(code: string) {

    if (code === ColumnIds.VGM_WEIGHT || code === ColumnIds.GROSS_WEIGHT) {
      return ['', 'KGM', 'LBR'];
    } else if (code === ColumnIds.TEMPERATURE || code === ColumnIds.TEMPERATURE_RANGE_UNIT) {
      return ['', 'CEL', 'FAH'];
    } else if (code === ColumnIds.OVER_DIMENSIONS || code === ColumnIds.BREAK_BULK) {
      return ['', 'CMT', 'INH'];
    }

    return [''];
  }
  getContainerDetails() {

    if (!this.container) {
      return 'nothing selected';
    }
    return this.container.stowage + ' ' + this.container.containerNo;
  }
  save() {
    // console.info('Save ' + this.container.containerNo);
    if (this.form.invalid) {

      this.openSnackBar('Resolve input errors to save');
      return;
    }
    this.showProgress = true;
    this.bayPlanService.updateToServer().subscribe(ediId => {
      this.showProgress = false;
      this.openSnackBar(this.container.stowage + ' saved');
      this.cdr.detectChanges();
    }, error => {
      this.showErrorDialog(error);
    });

  }
  openSnackBar(message: string) {
    this.snackBar.open(message,  'Close', {
      duration: 2000,
    });
  }

  getContainerProperty(id: string) {

    if (!this.container) {
      return '';
    }
    if (id === ColumnIds.STOWAGE) {
      return this.container.stowage;
    } else if (id === ColumnIds.GROSS_WEIGHT) {
      return this.container.grossWeight;
    } else if (id === ColumnIds.VGM_WEIGHT) {
      return this.container.vgmWeight;
    } else if (id === ColumnIds.TEMPERATURE) {
      return this.container.temperature;
    }

    return ColumnUtils.getValueFormatted(id, this.container);
  }

  getFullColumnName(code: string) {

    return ColumnUtils.getDefaultFullName(code);
  }
  isStandardInput(code: string): boolean {

    if (code === ColumnIds.VGM_WEIGHT
      || code === ColumnIds.GROSS_WEIGHT
      || code === ColumnIds.TEMPERATURE
      || code === ColumnIds.OVER_DIMENSIONS
      || code === ColumnIds.BREAK_BULK
      || code === ColumnIds.BUNDLED_CONTAINER
      || code === ColumnIds.TEMPERATURE_RANGE
    ) {
      return false;
    }
    return true;
  }
  isPortInput(code: string): boolean {

    for (const item of this.ports) {
      if (item === code) {
        return true;
      }
    }
    return false;
  }
  isTerminalCodeInput(code: string): boolean {

    for (const item of this.terminalCodes) {
      if (item === code) {
        return true;
      }
    }
    return false;
  }
  isVgmStatement(code: string): boolean {

    for (const item of this.vgmInfo) {
      if (item === code) {
        return true;
      }
    }
    return false;
  }
  isFirstLine(code: string): boolean {

    for (const item of this.firstLine) {
      if (item === code) {
        return true;
      }
    }
    return false;
  }
  isSecondLine(code: string): boolean {

    for (const item of this.secondLine) {
      if (item === code) {
        return true;
      }
    }
    return false;
  }
  isBlBkg(code: string): boolean {

    for (const item of this.blBkg) {
      if (item === code) {
        return true;
      }
    }
    return false;
  }
  getTerminalCodeShortName(code: string): string {

    if (code === ColumnIds.POL_TERMINAL) {
      return ColumnUtils.getDefaultShortName(ColumnIds.POL);
    } else if (code === ColumnIds.TS_TERMINAL) {
      return ColumnUtils.getDefaultShortName(ColumnIds.TS_PORT);
    } else if (code === ColumnIds.OPT_POD_TERMINAL) {
      return ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD);
    } else if (code === ColumnIds.NPOD_TERMINAL) {
      return ColumnUtils.getDefaultShortName(ColumnIds.NPOD);
    } else if (code === ColumnIds.FPOD_TERMINAL) {
      return ColumnUtils.getDefaultShortName(ColumnIds.FPOD);
    } else if (code === ColumnIds.POR_TERMINAL) {
      return ColumnUtils.getDefaultShortName(ColumnIds.POR);
    } else if (code === ColumnIds.POD_TERMINAL) {
      return ColumnUtils.getDefaultShortName(ColumnIds.POD);
    } else if (code === ColumnIds.PLACE_OF_DELIVERY_TERMINAL) {
      return ColumnUtils.getDefaultShortName(ColumnIds.PLACE_OF_DELIVERY);
    } else if (code === ColumnIds.OPT_POD1_TERMINAL) {
      return ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD1);
    } else if (code === ColumnIds.OPT_POD2_TERMINAL) {
      return ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD2);
    } else if (code === ColumnIds.OPT_POD3_TERMINAL) {
      return ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD3);
    } else if (code === ColumnIds.OPT_POD4_TERMINAL) {
      return ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD4);
    } else if (code === ColumnIds.OPT_POD5_TERMINAL) {
      return ColumnUtils.getDefaultShortName(ColumnIds.OPT_POD5);
    }
    return '';
  }
  getWeightUnit(id: string) {

    const container = this.container;
    if (!container) {
      return '';
    }
    if (id === ColumnIds.VGM_WEIGHT) {
      return container.vgmWeightUnit;
    } else if (id === ColumnIds.GROSS_WEIGHT) {
      return container.grossWeightUnit;
    }
    return '';
  }

  getDimensionValue(code: string) {

    const container = this.container;
    if (!container) {
      return '';
    }

    if (code === OogDimensions.FRONT) {
      return container.overDimFront;
    } else if (code === OogDimensions.BACK) {
      return container.overDimBack;
    } else if (code === OogDimensions.RIGHT) {
      return container.overDimRight;
    } else if (code === OogDimensions.LEFT) {
      return container.overDimLeft;
    } else if (code === OogDimensions.HEIGHT) {
      return container.overDimHeight;
    }

    return '';
  }
  getDimensionUnit(code: string) {

    const container = this.container;
    if (!container) {
      return '';
    }

    if (code === OogDimensions.FRONT) {
      return container.overDimFrontUnit;
    } else if (code === OogDimensions.BACK) {
      return container.overDimBackUnit;
    } else if (code === OogDimensions.RIGHT) {
      return container.overDimRightUnit;
    } else if (code === OogDimensions.LEFT) {
      return container.overDimLeftUnit;
    } else if (code === OogDimensions.HEIGHT) {
      return container.overDimHeightUnit;
    }
    return '';
  }
  getBbkDimensionValue(code: string) {

    const container = this.container;
    if (!container) {
      return '';
    }
    if (code === BbkDimensions.LENGTH) {
      return container.breakBulkLength;
    } else if (code === BbkDimensions.HEIGHT) {
      return container.breakBulkHeight;
    } else if (code === BbkDimensions.WIDTH) {
      return container.breakBulkWidth;
    }
    return '';
  }
  getBundleContainerValue(code: string) {

    const container = this.container;
    if (!container) {
      return '';
    }

    if (code === BundleContainers.CONTAINER2 && container.bundledContainers.length > 0) {
      return container.bundledContainers[0];
    } else if (code === BundleContainers.CONTAINER3 && container.bundledContainers.length > 1) {
      return container.bundledContainers[1];
    } else if (code === BundleContainers.CONTAINER4 && container.bundledContainers.length > 2) {
      return container.bundledContainers[2];
    } else if (code === BundleContainers.CONTAINER5 && container.bundledContainers.length > 3) {
      return container.bundledContainers[3];
    }
    return '';
  }
  hasError(code: string): boolean {

    if (!this.container) {
      return false;
    }

    if (code === ColumnIds.TEMPERATURE) {

      const regNumber = '^[-+]?[0-9]*.?[0-9]*';
      const temp = this.trim(this.container.temperature);

      if (temp.match(regNumber)) {
        return false;
      }
      return true;
    }
    return false;
  }

  changeContainerProperty(code: string, $event: Event) {

    ColumnUtils.setValue(this.container, code, $event);
  }
  trim(value: any): string {

    if (value) {
      return value.trim();
    }
    return '';
  }
  displayFn(code: string): string {

    return code;
  }
  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }
  showErrorDialog(error: any) {
    console.error(error);
    this.showProgress = false;
    let errorMessage = error.message;
    if (typeof error.error === 'string') {
      errorMessage = this.translate.instant(error.error);
    }
    const dialogRef = this.dialog.open(OkDialogComponent, {
      maxWidth: '800px',
      data: {header: error.statusText, message: errorMessage}
    });
  }

  vgmCertiCodeChanged(value: string) {
    ColumnUtils.setRemark(this.container, ColumnIds.VGM_CERTI_CODE, value);
  }
  vgmInfoChanged(value: string, index: number) {
    ColumnUtils.setVgmInfo(this.container, value, index);
  }

  remarkChanged(code: string, value: string) {

    ColumnUtils.setRemark(this.container, code, value);
  }
  getRemark(code: string): string {
    return ColumnUtils.getRemarksAppended(this.container, code);
  }

  getVgmCertiCode() {

    const vgmList = this.container.freeTexts.get('AAY') as Array<Freetext>;

    if (vgmList && vgmList.length > 0) {
      return vgmList[0].vgmCertificationCode;
    }
    return '';
  }

  getVgmInfo(index: number) {
    const vgmList = this.container.freeTexts.get('AAY') as Array<Freetext>;

    if (vgmList && vgmList.length > 0) {

      if (index === 1) {
        return vgmList[0].remark;
      } else if (index === 2) {
        return vgmList[0].vgmInfo2;
      } else if (index === 3) {
        return vgmList[0].vgmInfo3;
      } else if (index === 4) {
        return vgmList[0].vgmInfo4;
      } else if (index === 5) {
        return vgmList[0].vgmInfo5;
      }
    }

    return '';
  }
}

