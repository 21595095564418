export class Hatch {

  name: string;
  holdName: string;
  bayNumberList: Array<string>;

  loadableMixedLength: boolean;

  reeferReceptacleDeckBow: boolean;
  reeferReceptacleDeckStern: boolean;
  reeferReceptacleHoldBow: boolean;
  reeferReceptacleHoldStern: boolean;

  shipCraneTypeStern: string;
  shipCraneTypeBow: string;

  constructor() {
    this.bayNumberList = new Array<string>();
    this.loadableMixedLength = true;
    this.reeferReceptacleDeckBow = false;
    this.reeferReceptacleDeckStern = false;
    this.reeferReceptacleHoldBow = false;
    this.reeferReceptacleHoldStern = false;
  }

}
