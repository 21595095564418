import {VisualPart} from './visual-part';
import PIXI from 'pixi.js';

export class MouseTrackableVisualPart extends VisualPart {

  protected tracker: PIXI.Graphics;

  public setTracker(tracker: PIXI.Graphics) {
    this.tracker = tracker;
    this.graphics.interactive = true;
    this.initTracker(tracker);

  }
  protected initTracker(tracker: PIXI.Graphics) {

    if (!tracker) {
      return;
    }
    if (!this.tracker) {
      this.graphics.removeChild(tracker);
    }
    this.graphics.addChild(tracker);
  }
}
