import {Component, Input, OnInit, EventEmitter, OnDestroy} from '@angular/core';
import {EventService} from '../../core/event/event.service';
import {VisualEvent} from '../../core/event/visual-event';
import {EventIds} from '../../core/event/event-ids';
import {BayPlanService} from '../../service/bay-plan.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {BottomTabText} from '../bottom-panel/bottom-panel.component';

@Component({
  selector: 'app-edi-text',
  templateUrl: './edi-text.component.html',
  styleUrls: ['./edi-text.component.css']
})
export class EdiTextComponent implements OnInit, OnDestroy {

  showProgress = false;
  lineNumbers = '';
  ediText = '';

  @Input()
  bottomHeight = 200;

  @Input()
  tabEmitter: EventEmitter<MatTabChangeEvent>;
  private eventSubscription;
  private tabSubscription;

  constructor(private bayPlanService: BayPlanService, eventService: EventService) {

    this.eventSubscription = eventService.getEmitter().subscribe(data => {
      const event = data as VisualEvent;
      if (event.id === EventIds.LOAD_BAY_PLAN) {
        this.search();
      }
    });
  }

  ngOnInit(): void {

    this.search();

    this.tabSubscription = this.tabEmitter.subscribe(event => {
      if (event.tab.textLabel === BottomTabText.EDI_TEXT) {
        this.search();
      }
    });
  }
  private search() {
    this.bayPlanService.getEdiText().subscribe(ediText => {
      this.showProgress = true;
      if (ediText) {
        this.ediText = ediText;
      } else {
        this.ediText = '';
      }
      this.lineNumbers = this.generateLineNumbers(ediText);
      this.showProgress = false;
    });
  }
  private generateLineNumbers(ediText: string): string {

    if (!ediText) {
      return '';
    }
    let lineNumbers = '';

    const lines = ediText.split('\n').length;
    if (lines > 0) {
      for (let i = 1 ; i <= lines; i++) {
        lineNumbers = lineNumbers + String(i) + '\n';
      }
      return lineNumbers;
    }

    return '';
  }
  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.tabSubscription) {
      this.tabSubscription.unsubscribe();
    }
  }
}
