import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {MyTeam, UserGroupInput} from './model/my-team';
import {MyMember, UserGroupMemberInput} from './model/my-member';

@Injectable({
  providedIn: 'root'
})
export class UserGroupService {

  constructor(private http: HttpClient) { }

  getMyTeams(): Observable<MyTeam[]> {
    return this.http.get<MyTeam[]>(environment.serverUrl + '/team');

    // return this.http.get<MyTeam>(environment.serverUrl + '/team')
    //   .pipe(map(teams => {
    //     console.info(teams);
    //
    //     return teams;
    //   }));
  }
  createNewTeam(team: UserGroupInput): Observable<any> {
    return this.http.post(environment.serverUrl + '/team', team);
  }
  getMyMembers(groupId: string): Observable<MyMember[]> {
    return this.http.get<MyMember[]>(environment.serverUrl + '/member?group_id=' + groupId);
  }
  inviteMember(member: UserGroupMemberInput): Observable<any> {
    return this.http.post(environment.serverUrl + '/member', member);
  }
  updateMemberRole(id: string, roleCode: string): Observable<any> {

    const param = {id, roleCode};

    // console.info(param);
    return this.http.post(environment.serverUrl + '/member/role', param);
  }
  removeMember(id: string): Observable<any> {
    return this.http.delete(environment.serverUrl + '/member?id=' + id);
  }
  disableTeam(id: string): Observable<any> {
    return this.http.delete(environment.serverUrl + '/team?id=' + id);
  }
  disableTeams(teams: MyTeam[]): Observable<any> {
    let ids = '';

    teams.forEach(team => {

      ids = ids + ',' + team.id;
    });
    return this.http.delete(environment.serverUrl + '/team?id=' + ids);
  }
  updateTeamNames(teams: MyTeam[]): Observable<any> {
    return this.http.patch(environment.serverUrl + '/team', teams);
  }
}
