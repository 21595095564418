<div fxLayout="column" fxLayoutAlign="start stretch">
  <table mat-table [dataSource]="dataSource"
         cdkDropList [cdkDropListData]="dataSource"
         (cdkDropListDropped)="drop($event)"
         class="mat-elevation-z0"  class="topics">
    <mat-radio-group [(ngModel)]="selectedProperty" (ngModelChange)="selectProperty()">
    <ng-container matColumnDef="property">
      <th mat-header-cell *matHeaderCellDef class="property-header">Property</th>
      <td mat-cell *matCellDef="let element" matTooltip="{{element.fullName}}" matTooltipClass="tooltip"><span class="common-label">{{element.name}}</span></td>
    </ng-container>
    <ng-container matColumnDef="pivot-row">
      <th mat-header-cell *matHeaderCellDef class="row-column">
          <span *ngIf="isForTable()">Row</span>
          <span *ngIf="isForChart()">Select</span>
      </th>
      <td mat-cell *matCellDef="let element" class="row-column">


        <mat-checkbox *ngIf="isForTable()"
                      (click)="$event.stopPropagation()"
                      [checked]="element.rowSelected"
                      (change) = "element.rowSelected = !element.rowSelected; element.columnSelected = false">
        </mat-checkbox>
        <mat-radio-button *ngIf="isForChart()" [value]="element"
        (click)="print(element.rowSelected)">

        </mat-radio-button>

      </td>
    </ng-container>
    <ng-container matColumnDef="pivot-column">
      <th mat-header-cell *matHeaderCellDef class="row-column">
        <span *ngIf="isForTable()">Column</span>
<!--        <span *ngIf="isForChart()">X</span>-->
      </th>
      <td mat-cell *matCellDef="let element" class="row-column">
        <mat-checkbox *ngIf="isForTable()"
                      (click)="$event.stopPropagation()"
                      [checked]="element.columnSelected"
                      (change) = "element.columnSelected = !element.columnSelected; element.rowSelected = false">
        </mat-checkbox>
<!--        <mat-radio-button *ngIf="isForChart()" [checked]="element.columnSelected">-->

<!--        </mat-radio-button>-->
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"
        cdkDragLockAxis="y" cdkDrag [cdkDragData]="row">
    </mat-radio-group>
  </table>
  <div style="margin-top: 10px" fxLayout="row" fxLayoutAlign="end center">
    <button class="primary" mat-raised-button color="primary" (click)="apply()" >Save</button>
    <button class="primary" mat-raised-button color="basic" (click)="cancel()" >Close</button>
  </div>

</div>
