import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Container} from '../../../service/model/container';
import {ContainerColumn} from '../../columns/service/model/container-column';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {ColumnService} from '../../columns/service/column.service';
import {ColumnUtils} from '../../columns/service/model/column-utils';
import {ColumnIds} from '../../columns/service/model/column-ids';
import {CodeService} from '../../../code/service/code.service';
import {ContainerFilter} from '../../../service/model/container-filter';
import {EventService} from '../../../core/event/event.service';
import {EventIds} from '../../../core/event/event-ids';
import {Router} from '@angular/router';
import {MenuUrls} from '../../../core/menu/menu-urls';
import {AbstractEditableTableComponent} from '../../../be-com/etable/abstract-editable-table.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EditableColumn} from '../../../be-com/etable/editable-column';
import {EditableModel} from '../../../be-com/etable/editable-model';
import {PreferenceService} from '../../../service/preference.service';
import {ExcelService} from '../../../service/excel.service';
import {BayPlanService} from '../../../service/bay-plan.service';

@Component({
  selector: 'app-container-dialog',
  templateUrl: './container-dialog.component.html',
  styleUrls: ['./container-dialog.component.scss']
})
export class ContainerDialogComponent extends AbstractEditableTableComponent implements OnInit {

  columnNameMap = new Map<string, string>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  containers: Array<Container>;
  filter: ContainerFilter;
  bayPlanButtonVisible = true;
  editListButtonVisible = true;
  constructor(private dialogRef: MatDialogRef<ContainerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: Map<string, any>,
              private eventService: EventService,
              public columnService: ColumnService,
              private codeService: CodeService,
              private preferenceService: PreferenceService,
              protected elementRef: ElementRef,
              protected snackBar: MatSnackBar,
              private bayPlanService: BayPlanService,
              private excelService: ExcelService,
              private router: Router) {

    super(elementRef, snackBar);

    this.containers = data.get('CONTAINERS');
    this.filter = data.get('FILTER');

    if (data.get('SOURCE') === MenuUrls.EDIT_BAY) {
      this.bayPlanButtonVisible = false;
    }
    if (data.get('SOURCE') === MenuUrls.EDIT_LIST) {
      this.editListButtonVisible = false;
    }

  }

  ngOnInit(): void {
    this.search();
    super.ngOnInit();

  }
  search() {
    this.columnService.getColumns().then(columns => {
      try {
        this.headerCodes = new Array<string>();
        this.columns = new Array<ContainerColumn>();
        this.headerCodes.push('select');

        columns.forEach(column => {

          this.columnNameMap.set(column.code, column.name);
          if (column.visible) {
            this.headerCodes.push(column.code);
            this.columns.push(column);
          }
        });

        this.setDataSource();
        // super.ngOnInit();
      } catch (error) {
        console.error(error);
      }
    });
  }
  setDataSource() {
    this.deselectRow();
    this.dataSource = new MatTableDataSource<Container>(this.containers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (cntr, headerId) => {
      return this.getValueFormatted(cntr as Container, headerId).toUpperCase();
    };
    this.selection.clear();
    this.setPropertyDirty(false);
  }
  getColumnFullName(code: string) {

    return ColumnUtils.getDefaultFullName(code);
  }
  getTooTip(code: string) {

    return ColumnUtils.getToolTip(code);
  }
  getColumnName(code: string): string {

    let columnName = code;
    this.columns.forEach(column => {
      if (column.code === code) {
        columnName = column.name;
        return;
      }
    });
    return columnName;
  }
  isColoredColumn(column: EditableColumn): boolean {
    return (column as ContainerColumn).colored;
  }
  getBackColor(item: EditableModel, column: EditableColumn) {

    const cntr = item as Container;
    const columnId = column.code;
    if (ColumnUtils.isPortColumn(columnId)) {
      const codeMap = this.codeService.getOwnPortMap();

      if (codeMap) {
        const code = codeMap.get(ColumnUtils.getPortCode(cntr, columnId));

        if (code) {

          return code.backColor;
        }
      }
    }
    if (columnId === ColumnIds.CARRIER) {
      const codeMap = this.codeService.getOwnOperatorMap();
      if (codeMap) {
        const code = codeMap.get(cntr.carrier);

        if (code) {
          return code.backColor;
        }
      }
    }
    if (columnId === ColumnIds.CONTAINER_LENGTH
      || columnId === ColumnIds.CONTAINER_HEIGHT
      || columnId === ColumnIds.CONTAINER_TYPE
    ) {
      const sizeTypeMap = this.codeService.getSizeTypeMap();
      if (sizeTypeMap) {
        const sizeType = sizeTypeMap.get(cntr.isoSizeType);
        if (sizeType) {
          if (columnId === ColumnIds.CONTAINER_LENGTH) {
            return this.trim(sizeType.lengthBackColor);
          } else if (columnId === ColumnIds.CONTAINER_HEIGHT) {
            return this.trim(sizeType.heightBackColor);
          } else if (columnId === ColumnIds.CONTAINER_TYPE) {
            return this.trim(sizeType.typeBackColor);
          }
        }
      }
    }
    if (columnId === ColumnIds.FULL_EMPTY) {

      const preference = this.preferenceService.getPreference('017' + cntr.fullEmpty);

      if (preference) {
        return preference.value2;
      }
    }
    if (columnId === ColumnIds.CARGO_TYPE) {
      const pre = this.preferenceService.getPreference(cntr.getCargoTypeCode());
      return pre.value2;
    }

    return '';
  }

  getForeColor(item: EditableModel, column: EditableColumn) {

    const cntr = item as Container;
    const columnId = column.code;

    if (ColumnUtils.isPortColumn(columnId)) {

      const codeMap = this.codeService.getOwnPortMap();

      if (codeMap) {
        const code = codeMap.get(ColumnUtils.getPortCode(cntr, columnId));
        if (code) {
          return code.foreColor;
        }
      }
    }
    if (columnId === ColumnIds.CARRIER) {
      const codeMap = this.codeService.getOwnOperatorMap();

      if (codeMap) {
        const code = codeMap.get(cntr.carrier);

        if (code) {
          return code.foreColor;
        }
      }
    }
    if (columnId === ColumnIds.CONTAINER_LENGTH
      || columnId === ColumnIds.CONTAINER_HEIGHT
      || columnId === ColumnIds.CONTAINER_TYPE
    ) {
      const sizeTypeMap = this.codeService.getSizeTypeMap();
      if (sizeTypeMap) {
        const sizeType = sizeTypeMap.get(cntr.isoSizeType);
        if (sizeType) {
          if (columnId === ColumnIds.CONTAINER_LENGTH) {
            return this.trim(sizeType.lengthForeColor);
          } else if (columnId === ColumnIds.CONTAINER_HEIGHT) {
            return this.trim(sizeType.heightForeColor);
          } else if (columnId === ColumnIds.CONTAINER_TYPE) {
            return this.trim(sizeType.typeForeColor);
          }
        }
      }
    }
    if (columnId === ColumnIds.FULL_EMPTY) {
      if (cntr.fullEmpty) {

        const preference = this.preferenceService.getPreference('017' + cntr.fullEmpty);

        if (preference) {
          return preference.value;
        }
      }
    }
    if (columnId === ColumnIds.CARGO_TYPE) {
      const pre = this.preferenceService.getPreference(cntr.getCargoTypeCode());
      return pre.value;
    }
    return '';
  }
  getStyleClass(container: Container, columnCode) {
    if (ColumnUtils.isPortColumn(columnCode)) {
      return 'port-label';
    } else if (columnCode === ColumnIds.CARRIER
      || columnCode === ColumnIds.CONTAINER_TYPE
      || columnCode === ColumnIds.CONTAINER_HEIGHT
      || columnCode === ColumnIds.CONTAINER_LENGTH
      || columnCode === ColumnIds.FULL_EMPTY
      || columnCode === ColumnIds.CARGO_TYPE
    ) {
      return 'operator-label';
    } else if (columnCode === ColumnIds.VGM_WEIGHT
      || columnCode === ColumnIds.GROSS_WEIGHT
      || columnCode === ColumnIds.TEMPERATURE
    ) {
      return 'number-label';
    } else if (columnCode === ColumnIds.CONTAINER_NO
    ) {
      return 'monospace-label';
    }

    return 'etable-common-label';
  }
  applyFilter($event: KeyboardEvent) {
    this.eventService.emit(EventIds.APPLY_FILTER, this.filter);
  }
  goToCargoList() {
    this.applyFilter(null);
    this.dialogRef.close();
    this.router.navigateByUrl('edit/list');
  }

  goToBayPlan() {
    this.applyFilter(null);
    this.dialogRef.close();
    this.router.navigateByUrl('edit/bay');
  }

  downloadExcel() {

  }
  exportToExcel(visibleOnly: boolean) {
    const list = new Array();

    this.columnService.getColumns().then(columns => {

      let entry = new Array();
      columns.forEach(column => {

        if (visibleOnly && !column.visible) {
          return;
        }

        entry.push(column.name.toUpperCase());
      });
      list.push(entry);

      this.dataSource.data.forEach(item => {

        const cntr = item as Container;
        entry = new Array();
        columns.forEach(column => {
          if (visibleOnly && !column.visible) {
            return;
          }
          entry.push(this.getValueFormatted(cntr, column.code));
        });
        list.push(entry);
      });

      const header = this.bayPlanService.getBayPlan().header;

      let fileName = 'baplie_data';

      if (header.fileName) {
        fileName = header.fileName;
      } else {

        if (header.vesselName) {
          fileName = header.vesselName;
        }
        if (header.voyageNo) {
          fileName = fileName + '_' + header.voyageNo;
        }
      }

      this.excelService.exportAsExcelFile(list, fileName);
    });
  }
  getValue(cntr: Container, columnId: string) {

    return ColumnUtils.getValue(columnId, cntr);
  }

  getValueFormatted(cntr: Container, columnId: string): string {
    if (columnId === ColumnIds.CONTAINER_LENGTH
      || columnId === ColumnIds.CONTAINER_HEIGHT
      || columnId === ColumnIds.CONTAINER_TYPE
    ) {
      const sizeTypeMap = this.codeService.getSizeTypeMap();
      if (sizeTypeMap) {
        const sizeType = sizeTypeMap.get(cntr.isoSizeType);
        if (sizeType) {
          if (columnId === ColumnIds.CONTAINER_LENGTH) {
            return sizeType.lengthName;
          } else if (columnId === ColumnIds.CONTAINER_HEIGHT) {
            return sizeType.heightCode.substring(3);
          } else if (columnId === ColumnIds.CONTAINER_TYPE) {
            return sizeType.typeName;
          }
        }
      }
      return '';
    }
    return ColumnUtils.getValueFormatted(columnId, cntr);
  }

  initColumns(): EditableColumn[] {
    return this.columns;
  }

  setValue(item: Container, code: string, value: string): boolean {
    return false;
  }
  trim(value: any) {
    if (!value) {
      return '';
    }
    return value;
  }
}
