import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cod',
  templateUrl: './cod.component.html',
  styleUrls: ['./cod.component.css']
})
export class CodComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
