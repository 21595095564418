import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {ColumnService} from '../service/column.service';
import {EventService} from '../../../core/event/event.service';
import {ContainerColumn} from '../service/model/container-column';
import {ColumnUtils} from '../service/model/column-utils';
import {ColumnActionIds} from '../columns.component';
import {EventIds} from '../../../core/event/event-ids';
import {OkDialogComponent} from '../../../dialogs/ok-dialog/ok-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-column-edit',
  templateUrl: './column-edit.component.html',
  styleUrls: ['./column-edit.component.css']
})
export class ColumnEditComponent implements OnInit, OnDestroy {

  @Input()
  visible: boolean;

  @Input()
  actionEmitter: EventEmitter<string>;

  private actionSubscription;

  columns: ContainerColumn[] = [];
  showProgress = false;

  constructor(private columnService: ColumnService,
              private eventService: EventService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private translate: TranslateService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
   this.columnService.getColumns().then(data => {
    this.columns = data;
    this.cdr.detectChanges();
   });

   this.actionSubscription = this.actionEmitter.subscribe(event => {
      if (event === ColumnActionIds.SAVE && this.visible) {
        this.save();
      } else if (event === ColumnActionIds.EDIT_SELECTED) {
        this.visible = true;
      } else if (event === ColumnActionIds.INFO_SELECTED) {
        this.visible = false;
      }
   });
  }

  getFullColumnName(code: string) {

    return ColumnUtils.getDefaultFullName(code);
  }

  valueChanged() {
    this.actionEmitter.emit(ColumnActionIds.MODIFIED);
  }
  save() {
    this.showProgress = true;
    this.columnService.updateColumns(this.columns).subscribe(data => {
      this.showProgress = false;
      this.actionEmitter.emit(ColumnActionIds.MODIFICATION_CLEANED);
      this.eventService.emit(EventIds.COLUMNS_CHANGED);
      this.openSnackBar('Saved', 'Closed');
    }, error => {
      this.showErrorDialog(error);

    });
  }
  ngOnDestroy(): void {
    if (this.actionSubscription) {
      this.actionSubscription.unsubscribe();
    }
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  showErrorDialog(error: any) {
    let errorMessage = error.message;
    if (typeof error.error === 'string') {
      errorMessage = this.translate.instant(error.error);
    }
    const dialogRef = this.dialog.open(OkDialogComponent, {
      maxWidth: '800px',
      data: {header: error.statusText, message: errorMessage}
    });
  }
}
