<div *ngIf="showProgress">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div fxLayout="row">
  <div class="group">
    <p class="title"><b>Interchange header</b></p>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Sender</div>
      <div *ngIf="!editable()">: {{ediHeader.sender}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Sender</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.sender">
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Recipient</div>
      <div *ngIf="!editable()">: {{ediHeader.recipient}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Recipient</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.recipient">
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Prepared at</div>
      <div *ngIf="!editable()">: {{formatDateTime(ediHeader.preparationDate + ediHeader.preparationTime)}}</div>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Control Count</div>
      <div *ngIf="!editable()">: {{ediHeader.interchangeControlCount}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Control Count</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.interchangeControlCount">
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Control Ref.</div>
      <div *ngIf="!editable()">: {{ediHeader.interchangeControlRefStart}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Control Ref.</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.interchangeControlRefStart">
      </mat-form-field>
    </div>
    <p class="title"><b>Interchange Tailer</b></p>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Control Ref.</div>
      <div *ngIf="!editable()">: {{ediHeader.interchangeControlRefEnd}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Control Ref.</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.interchangeControlRefEnd">
      </mat-form-field>
    </div>
  </div>
  <div class="group">
    <p class="title"><b>Message header</b></p>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Message Ref No</div>
      <div *ngIf="!editable()">: {{ediHeader.messageRefNo}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Message Ref No</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.messageRefNo">
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Type</div>
      <div *ngIf="!editable()">: {{ediHeader.messageType}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Message Type</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.messageType">
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Version</div>
      <div *ngIf="!editable()">: {{ediHeader.smdgVersion}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>SMDG version</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.smdgVersion">
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Document No</div>
      <div *ngIf="!editable()">: {{ediHeader.documentNo}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Document No</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.documentNo">
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Function</div>
      <div *ngIf="!editable()">: {{ediHeader.messageFunction}} {{getMessageFunctionDesc(ediHeader.messageFunction)}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Function</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.messageFunction">
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Compiled at</div>
      <div *ngIf="!editable()">: {{formatDateTime(ediHeader.compilationDatetime)}}</div>
    </div>
  </div>
  <div class="group">
    <p class="title"><b>Transport</b></p>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Carrier</div>
      <div *ngIf="!editable()">: {{ediHeader.carrier}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Carrier</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.carrier">
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div class="key" *ngIf="!editable(true)">Service Lane</div>
      <div *ngIf="!editable(true)">: {{ediHeader.serviceLaneCode}}</div>
      <mat-form-field class="service-lane-width" *ngIf="editable(true)">
        <mat-label>Service Lane</mat-label>
        <input class="search" matInput placeholder="Service Lane code"
               [(ngModel)]="ediHeader.serviceLaneCode"
               [formControl]="serviceLaneControl"
               [matAutocomplete]="auto"
               id ="serviceLaneInput">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngIf="isServiceLaneLoading" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
          <ng-container *ngIf="!isServiceLaneLoading">
            <mat-option *ngFor="let lane of filteredServiceLanes" [value]="lane.code">
              <span>{{lane.code}}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Vessel Name</div>
      <div *ngIf="!editable()">: {{ediHeader.vesselName}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Vessel Name</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.vesselName">
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Vessel Code</div>
      <div *ngIf="!editable()">: {{ediHeader.vesselCode}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Vessel Code</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.vesselCode">
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Voyage</div>
      <div *ngIf="!editable()">: {{ediHeader.voyageNo}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Voyage</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.voyageNo">
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Departure Port</div>
      <div *ngIf="!editable()">: {{ediHeader.portCode}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Departure Port</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.portCode">
      </mat-form-field>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!editable()" class="key">Arrival Port</div>
      <div *ngIf="!editable()">: {{ediHeader.nextPortCode}}</div>
      <mat-form-field class="port-code-form-field" *ngIf="editable()">
        <mat-label>Arrival Port</mat-label>
        <input matInput type="text" [(ngModel)]="ediHeader.nextPortCode">
      </mat-form-field>
    </div>
  </div>
  <div class="group">
    <p class="title"><b>Schedule</b></p>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div class="key-schedule">ETD</div> <div>: {{formatDateTime(ediHeader.etd)}}</div>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div class="key-schedule">ETA</div> <div>: {{formatDateTime(ediHeader.nextEta)}}</div>
    </div>
  </div>
  <div class="group">
    <p class="title"><b>Flat File</b></p>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div class="key">Name</div> <div>: {{ediHeader.fileName}}</div>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div class="key">Importer</div> <div>: {{ediHeader.creatorName}}</div>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div class="key">Imported at</div> <div>: {{formatDateTime(ediHeader.creationDatetime)}}</div>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div class="key">Updater</div> <div>: {{ediHeader.updaterName}}</div>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div class="key">Updated at</div> <div>: {{formatDateTime(ediHeader.updateDatetime)}}</div>
    </div>
    <div class="row" fxLayout="row" fxLayoutAlign="start start">
      <div class="key">ID</div> <div>: {{formatDateTime(ediHeader.id)}}</div>
    </div>
  </div>
  <div *ngIf="editable(true)" fxLayout="row">
    <button mat-icon-button color="primary"
            matTooltipClass="tooltip"
            matTooltip="Lock for readonly"
            (click)="changeEditable(false)">
      <mat-icon>lock_open</mat-icon>
    </button>
    <button mat-icon-button color="primary" (click)="save()">
      <mat-icon>save</mat-icon>
    </button>
  </div>
  <div *ngIf="!editable(true) && ediHeader.id && eligibleToEdit()" fxLayout="row">
    <button mat-icon-button color="primary"
            matTooltipClass="tooltip"
            matTooltip="Unlock to edit properties"
            (click)="changeEditable(true)"
    >
      <mat-icon>lock</mat-icon>
    </button>
  </div>
</div>
