<mat-card class="material" fxLayout="column" fxLayoutAlign="start stretch">
  <div><h2>Members of {{activeTeamName}}</h2></div>
  <div>
    <form  [formGroup]="form" (ngSubmit)="inviteMember()">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field class="email">
          <mat-label for="email">Email</mat-label>
          <input matInput type="text"
                 placeholder="Pick one"
                 aria-label="Number"
                 (keyup)="enter($event)"
                 (focusout)="focusOut($event)"
                 [formControl]="f.email"
                 [ngClass]="{ 'is-invalid': submitted && f.email.errors }">

          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Username is required</div>
            <div *ngIf="f.username.errors.minlength">Username must be at least 4 characters</div>
            <div *ngIf="f.username.errors.maxlength">Maximum 20 characters are allowed.</div>
          </div>
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>Member role</mat-label>
          <mat-select [(value)]="selectedRoleCode">
            <mat-option *ngFor="let roleCode of roleCodes" [value]="roleCode.code">
              {{roleCode.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button class="primary" color="primary" type="submit"
                matTooltip="Invite"
                matTooltipClass="tooltip"
                [disabled]="!form.valid">Invite</button>
      </div>
    </form>
  </div>
<!--  <div class="table">-->
    <table id="editTable"
           mat-table
           [dataSource]="dataSource" matSort
           class="mat-elevation-z3"
           class="code-table"
           (contextmenu)="$event.preventDefault()">

      <ng-container matColumnDef="select" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
<!--          <mat-checkbox (change)="$event ? masterToggle() : null"-->
<!--                        [checked]="selection.hasValue() && isAllSelected()"-->
<!--                        [indeterminate]="selection.hasValue() && !isAllSelected()"-->
<!--                        [aria-label]="checkboxLabel()">-->
<!--          </mat-checkbox>-->
        </th>
        <td mat-cell *matCellDef="let element; let i = index"
            (mousedown)="mouseDownOnCell($event, element, i, selectionBoxColumn)"
            (mouseup)="mouseUpOnCell($event, i, selectionBoxColumn)"
            (mouseenter)="mouseEnterOnCell($event, i, selectionBoxColumn)"
            (mouseleave)="mouseLeaveOnCell($event, i, selectionBoxColumn)"
        >
<!--          <mat-checkbox (click)="$event.stopPropagation()"-->
<!--                        (change)="$event ? selection.toggle(element) : null"-->
<!--                        [checked]="selection.isSelected(element)"-->
<!--                        [aria-label]="checkboxLabel(element)">-->
<!--          </mat-checkbox>-->
<!--          <container *ngIf="element.modified" style="margin-left: 4px">*</container>-->
        </td>
      </ng-container>
      <ng-container *ngFor="let column of columns" matColumnDef="{{column.code}}" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            (mousedown)="mouseDownOnHeader(column)"
            class="header"
            matTooltip="{{column.name}}"
            matTooltipClass="tooltip"><div [ngClass]="getStyleClass('', column.code)" class="header">{{column.name}}</div></th>
        <td mat-cell *matCellDef="let element; let i = index"
            (mousedown)="mouseDownOnCell($event, element, i, column)"
            (mouseup)="mouseUpOnCell($event, i, column)"
            (mouseenter)="mouseEnterOnCell($event, i, column)"
            (mouseleave)="mouseLeaveOnCell($event, i, column)"
            [ngClass]="getTdStyleClass(i, column)"
        >
          <div *ngIf="showPlanLabel(i, column)"
               [ngClass]="getStyleClass(element, column.code)"
               [style.background]="getBackColor(element, column)"
               [style.color]="getForeColor(element, column)" >

            {{getValueFormatted(element, column.code)}}
          </div>
          <ng-container *ngIf="isColumnInEditMode(i, column) && column.code === 'Role'">
            <mat-form-field appearance="legacy">
              <mat-label>{{column.name}}</mat-label>
              <mat-select
                value="{{getValue(element, column.code)}}"
                (selectionChange)="updateRole(element.id, $event.value)">
                <mat-option *ngFor="let roleCode of roleCodes" [value]="roleCode.code">
                  <div [ngClass]="getStyleClass(element, column.code)"
                       >{{roleCode.name}}</div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="column.code === 'Remove'">
            <button mat-stroked-button type="submit" *ngIf="!isMySelf(element)" (click)="removeMember(element.id)" color="basic">Remove</button>
          </ng-container>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headerCodes; sticky: true" ></tr>
      <tr mat-row *matRowDef="let row; columns: headerCodes;let i = index"
          [ngClass]="{ 'etable-selected': isReadOnlyRowSelected(row)}">
    </table>
    <br>
    <mat-paginator [pageSizeOptions]="[100, 500]"></mat-paginator>
<!--  </div>-->
</mat-card>
