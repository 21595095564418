import {Vessel} from '../model/vessel';
import {Hatch} from '../model/hatch';
import {Bay} from '../model/bay';
import {Cell} from '../model/cell';

export class VesselUtils {

  vessel: Vessel;
  hatchNameMaps: Map<string, Hatch>;
  bayNameMaps: Map<string, Bay>;
  cellMaps: Map<string, Cell>;

  constructor(vessel: Vessel) {

    if (!vessel) {
      return;
    }
    this.vessel = vessel;

    this.hatchNameMaps = new Map<string, Hatch>();

    for (const hatch of vessel.hatchList) {
      this.hatchNameMaps.set(hatch.name, hatch);
    }

    this.bayNameMaps = new Map<string, Bay>();
    this.cellMaps = new Map<string, Cell>();
    for (const bay of vessel.bayList) {
      this.bayNameMaps.set(bay.name, bay);
      for (const cell of bay.deckSection.cellList) {
        if (cell !== undefined) {
          this.cellMaps.set(cell.stowage, cell);
        }
      }
      for (const cell of bay.holdSection.cellList) {
        if (cell !== undefined) {
          this.cellMaps.set(cell.stowage, cell);
        }
      }
    }
  }
  static fixStowageType(value: string, length: number): string {
    let returnValue = value;
    if (value.length > length) {
      let i = 0;
      for (; i < value.length - length; i++) {
        if (value.substring(i, i + 1) !== '0') {
          break;
        }
      }
      return value.substring(i, value.length);
    } else {
      for (let i = value.length; i < length; i++) {
        returnValue = '0' + returnValue;
      }
    }
    return returnValue;
  }
  getHatchName(stowage: string, count: number): string {
    for (let hatchIndex = 0; hatchIndex < this.vessel.hatchList.length; hatchIndex++) {
      for (const hatchBayNo of this.vessel.hatchList[hatchIndex].bayNumberList) {
        if (hatchBayNo === stowage.substring(0, stowage.length - 4)) {
          if (hatchIndex + count > -1 && hatchIndex + count < this.vessel.hatchList.length) {
            return this.vessel.hatchList[hatchIndex + count].name;
          }
          return '';
        }
      }
    }
    return '';
  }
  getBayPhysicalGap(stowage1: string, stowage2: string): number {
    const bay1 = this.bayNameMaps.get(stowage1.substring(0, stowage1.length - 4));
    const bay2 = this.bayNameMaps.get(stowage2.substring(0, stowage1.length - 4));
    if (!bay1 || !bay2 || bay1.name === bay2.name || (bay1.bayLength !== bay1.bayLength && bay1.hatchName === bay2.hatchName)) {
      return 0;
    }

    let firstLength;
    let hatchName;
    let count = 0;
    for (const bay of this.vessel.bayList) {
      if (bay1.name === bay.name || bay2.name === bay.name) {
        if (!firstLength) {
          firstLength = bay.bayLength;
        } else {
          return count;
        }
      } else if (firstLength && (bay.bayLength === 20 || hatchName !== bay.hatchName)) {
        if (bay.bayLength === 40) {
          count = count + 2;
        } else {
          count = count + 1;
        }
      }
      hatchName = bay.hatchName;
    }
    return 0;
  }
  getRowIndex(stowage: string, isDeck: boolean): number {
    let rowIndex = -1;
    const bay = this.bayNameMaps.get(stowage.substring(0, stowage.length - 4));
    if (bay) {
      const section = isDeck ? bay.deckSection : bay.holdSection;
      for (const row of section.rowList) {
        rowIndex++;
        if (row.name === stowage.substring(stowage.length - 4, stowage.length - 2)) {
          return rowIndex;
        }
      }
    }
    return rowIndex;
  }
  getTierIndex(stowage: string): number {
    let tierIndex = -1;
    const bay = this.bayNameMaps.get(stowage.substring(0, stowage.length - 4));
    if (bay) {
      for (const tier of bay.deckSection.tierList) {
        tierIndex++;
        if (tier.name === stowage.substring(stowage.length - 2, stowage.length)) {
          return tierIndex;
        }
      }
    }
    return tierIndex;
  }
  getBay(stowage: string): Bay {
    return this.bayNameMaps.get(stowage.substring(0, stowage.length - 4));
  }
  getBayArray(stowage: string, count: number): Array<Bay> {
    const bays = new Array<Bay>();
    const bayNo = stowage.substring(0, stowage.length - 4);
    for (let index = 0; index < this.vessel.bayList.length; index++) {
      if (this.vessel.bayList[index].name === bayNo) {
        for (let index2 = index - count; index2 < index + count; index2++) {
          if (index2 < 0) {
            continue;
          }
          if (index2 >= index + count) {
            break;
          }
          bays.push(this.vessel.bayList[index2]);
        }
      }
    }
    return bays;
  }
  isDeck(stowage: string): boolean {
    const bay = this.bayNameMaps.get(stowage.substring(0, stowage.length - 4));
    if (bay) {
      for (const tier of bay.deckSection.tierList) {
        if (tier.name === stowage.substring(stowage.length - 2, stowage.length)) {
          return true;
        }
      }
    }
    return false;
  }
  findOccupiedCellKeys(stowage: string): string[] {
    const occupyStowage: string[] = [];
    if (stowage === undefined || stowage.length < 7) {
      return occupyStowage;
    }
    const bay: Bay = this.bayNameMaps.get(stowage.substring(0, 3));
    if (!bay) {
      return;
    }
    const hatch: Hatch = this.hatchNameMaps.get(bay.hatchName);
    const rowTier = stowage.substring(3);

    if (bay.bayLength > 20) {
      if (hatch.bayNumberList.length === 3 && this.bayNameMaps.get(hatch.bayNumberList[0])) {
        let otherStowage: string = this.bayNameMaps.get(hatch.bayNumberList[0]).name + rowTier;
        let cell: Cell = this.cellMaps.get(otherStowage);
        if (cell != null && cell.loadableSpace) {
          occupyStowage[0] = this.bayNameMaps.get(hatch.bayNumberList[0]).name + rowTier;
        }
        otherStowage = this.bayNameMaps.get(hatch.bayNumberList[2]).name + rowTier;
        cell = this.cellMaps.get(otherStowage);
        if (cell != null && cell.loadableSpace) {
          occupyStowage[1] = this.bayNameMaps.get(hatch.bayNumberList[2]).name + rowTier;
        }
        if (occupyStowage.length > 0) {
          return occupyStowage;
        }
      }

    } else {
      if (hatch && hatch.bayNumberList.length === 3) {
        const otherStowage: string = this.bayNameMaps.get(hatch.bayNumberList[1]).name + rowTier;
        const cell: Cell = this.cellMaps.get(otherStowage);
        if (cell != null && cell.loadableSpace) {
          occupyStowage[0] = this.bayNameMaps.get(hatch.bayNumberList[1]).name + rowTier;
          return occupyStowage;
        }
      }
    }

    return null;
  }
  getPairStowage(stowage: string): string[] {
    const pairStowage: string[] = [];
    if (stowage === undefined || stowage.length < 7) {
      return pairStowage;
    }
    const bay: Bay = this.bayNameMaps.get(stowage.substring(0, 3));
    if (!bay) {
      return pairStowage;
    }
    const hatch: Hatch = this.hatchNameMaps.get(bay.hatchName);
    const rowTier = stowage.substring(3);

    if (bay.bayLength <= 20) {
      if (hatch.bayNumberList.length === 3) {
        let otherStowage = '';
        if (bay.name !== this.bayNameMaps.get(hatch.bayNumberList[0]).name) {
          otherStowage = this.bayNameMaps.get(hatch.bayNumberList[0]).name + rowTier;
        } else {
          otherStowage = this.bayNameMaps.get(hatch.bayNumberList[2]).name + rowTier;
        }
        const cell: Cell = this.cellMaps.get(otherStowage);
        if (cell && cell.loadableSpace) {
          pairStowage[0] = cell.stowage;
          return pairStowage;
        }
      }
    }
    return pairStowage;
  }

  getMaxDeckRow(): number {
    return this.vessel.maxDeckRow;
  }
  getMaxDeckTier(): number {
    return this.vessel.maxDeckTier;
  }
  getMaxHoldRow(): number {
    return this.vessel.maxHoldRow;
  }
  getMaxHoldTier(): number {
    return this.vessel.maxHoldTier;
  }
  getStowage(stowage: string, length: number): string {
    if (stowage === undefined || stowage.length < 7) {
      return stowage;
    }
    const bay: Bay = this.bayNameMaps.get(stowage.substring(0, 3));
    if (!bay) {
      return stowage;
    }
    const hatch: Hatch = this.hatchNameMaps.get(bay.hatchName);
    const rowTier = stowage.substring(3);

    if (length > 20 && hatch.bayNumberList.length === 3) {
      const otherStowage = this.bayNameMaps.get(hatch.bayNumberList[1]).name + rowTier;
      const cell: Cell = this.cellMaps.get(otherStowage);
      if (cell && cell.loadableSpace) {
        return otherStowage;
      }
    }
    return stowage;
  }
  getCell(stowage: string): Cell {
    return this.cellMaps.get(stowage);
  }
}
