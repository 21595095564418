import * as PIXI from 'pixi.js';
import {VisualPart} from './visual-part';

export class TextVisualPart extends VisualPart {

  public readonly name: string;

  private textStyle = new PIXI.TextStyle(
    {fontFamily: 'Arial',
      fill: ['#000000'],
    }
  );
  readonly rowText: PIXI.Text;
  constructor(protected parentGraphics: PIXI.Container, name: string, public fontSize?: number, private bold?: boolean) {
    super(parentGraphics);

    this.textStyle.fontSize = fontSize;
    if (bold) {
      this.textStyle.fontWeight = 'bold';
    }

    this.rowText = new PIXI.Text(name, this.textStyle);
    this.graphics.addChild(this.rowText);
  }

  draw() {
    super.draw();

    const x = this.bounds.x + (this.bounds.width - this.rowText.width) / 2;
    const y = this.bounds.y + (this.bounds.height - this.rowText.height) / 2;
    if (x > 0 && y > 0) {
      this.rowText.x = x;
      this.rowText.y = y;
    }
  }
}
