import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';

import { MenuService } from './menu.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {AuthEventId, AuthService} from '../../user/service/auth.service';

@Component({
  selector: 'app-menu',
  template: `
    <ng-material-multilevel-menu
      [configuration]="config"
      [items]="menuItems"
      class="navigation"
    ></ng-material-multilevel-menu>
  `,
  providers: [MenuService]
})
export class MenuComponent implements OnChanges, OnInit, OnDestroy {
  @Input() direction: string;

  private langChangeSubscription!: Subscription;
  private authServiceSubscription: Subscription;

  currentLang = 'en';
  menuItems = [];

  config = {
    paddingAtStart: false,
    interfaceWithRoute: true,
    collapseOnSelect: true,
    highlightOnSelect: true,
    rtlLayout: true,
    selectedListFontColor: '#3f51b5'
  };

  constructor(
    public translate: TranslateService,
    private authService: AuthService,
    public menuService: MenuService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.config = {
      ...this.config,
      rtlLayout: this.direction === 'rtl' ? true : false
    };
  }

  ngOnInit() {
    const menu = this.menuService.getAll();
    this.menuItems = menu;

    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      const updatedMenu = this.menuService.getAll();
      this.menuItems = updatedMenu;
    });
    this.authServiceSubscription = this.authService.getEmitter().subscribe(event => {
        if (event.id === AuthEventId.SIGN_IN || event.id === AuthEventId.SIGN_OUT) {
          const updatedMenu = this.menuService.getAll();
          this.menuItems = updatedMenu;
        }
    });
  }

  ngOnDestroy() {

    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
    if (this.authServiceSubscription) {
      this.authServiceSubscription.unsubscribe();
    }
  }
}
