export class Row {

  name: string;
  gapPoint: number;
  stackingWeight: number;
  hatchCoverClearance: number;
  cellGuideClearancePort: number;
  cellGuideClearanceStarboard: number;
  tcg: number;

}
