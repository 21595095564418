import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {BayPlanService} from '../../service/bay-plan.service';
import {Bay} from '../../hull/model/bay';
import {Vessel} from '../../hull/model/vessel';
import {EventIds} from '../../core/event/event-ids';
import {EventService} from '../../core/event/event.service';
import {BayPlanVesselParser} from '../../hull/service/bay-plan-vessel-parser';
import {Row} from '../../hull/model/row';
import {Tier} from '../../hull/model/tier';
import {VesselDesignInput} from '../../hull/model/vessel-design-input';
import {properties} from '../../../environments/environment';
import {VesselTextParserService} from '../../hull/service/vessel-text-parser.service';
import {HttpClient} from '@angular/common/http';

export interface BayElement {
  code: string;
  name: string;
}

@Component({
  selector: 'app-vessel-edit',
  templateUrl: './vessel-edit.component.html',
  styleUrls: ['./vessel-edit.component.css']
})
export class VesselEditComponent implements OnInit {

  columns: BayElement[] = [{code: 'bayNo', name: 'Bay No'}, {code: 'hatchGroup', name: 'Hatch Group'}, {code: 'length', name: 'Length'},
    {code: 'deckMaxTierNo', name: '(Deck) Max TierNo'}, {code: 'deckMinTierNo', name: '(Deck) Min TierNo'}, {code: 'holdMaxTierNo', name: '(Hold) Max TierNo'},
    {code: 'holdMinTierNo', name: '(Hold) Min TierNo'}, {code: 'row00', name: 'Row-00'}];
  columnCodes: string[];
  vessel = new Vessel();
  clickedRow = new Bay();
  private eventSubscription;

  @Input()
  tabEmitter: EventEmitter<MatTabChangeEvent>;

  constructor(private bayPlanService: BayPlanService,
              private eventService: EventService,
              private http: HttpClient,
              private vesselTextParserService: VesselTextParserService) {
    this.eventSubscription = this.eventService.getEmitter().subscribe(event => {
      if (event.id ===  EventIds.LOAD_BAY_PLAN) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    this.columnCodes = [];
    for (const column of this.columns) {
      this.columnCodes.push(column.code);
    }
    this.vessel = this.bayPlanService.getVessel();
  }

  save() {
    this.vessel.maxDeckRow = Number(this.vessel.maxDeckRow);
    this.vessel.maxHoldRow = Number(this.vessel.maxHoldRow);
    this.vessel.maxDeckTier = Number(this.vessel.maxDeckTier);
    this.vessel.maxHoldTier = Number(this.vessel.maxHoldTier);
    for (const bay of this.vessel.bayList) {
      let rowList: Array<Row> = new Array<Row>();
      for (let rowIndex = 0; rowIndex < this.vessel.maxDeckRow; rowIndex++) {
        const row = BayPlanVesselParser.getRowData(this.vessel.maxDeckRow - 2 * rowIndex, this.vessel.maxDeckRow % 2 === 1);
        rowList.push(row);
      }
      bay.deckSection.rowList = rowList;
      rowList = new Array<Row>();
      for (let rowIndex = 0; rowIndex < this.vessel.maxHoldRow; rowIndex++) {
        const row = BayPlanVesselParser.getRowData(this.vessel.maxHoldRow - 2 * rowIndex, this.vessel.maxHoldRow % 2 === 1);
        rowList.push(row);
      }
      bay.holdSection.rowList = rowList;

      let tierList: Array<Tier> = new Array<Tier>();
      if (this.vessel.maxDeckTier < bay.deckSection.tierList.length) {
        let minDeckTier = 0;
        for (let tierIndex = 0; tierIndex < this.vessel.maxDeckTier; tierIndex++) {
          tierList.push(bay.deckSection.tierList[tierIndex]);
          if (Number(bay.deckSection.tierList[tierIndex].name) > 0) {
            minDeckTier = Number(bay.deckSection.tierList[tierIndex].name);
          }
        }
        bay.deckSection.tierList = tierList;
        if (minDeckTier > 0) {
          bay.maxDeckTierNo = minDeckTier;
        }
      }
      if (this.vessel.maxHoldTier < bay.holdSection.tierList.length) {
        tierList = new Array<Tier>();
        let minHoldTier = 0;
        for (let tierIndex = 0; tierIndex < this.vessel.maxHoldTier; tierIndex++) {
          tierList.push(bay.holdSection.tierList[tierIndex]);
          if (Number(bay.holdSection.tierList[tierIndex].name) > 0)  {
            minHoldTier = Number(bay.holdSection.tierList[tierIndex].name);
          }
        }
        bay.holdSection.tierList = tierList;
        if (minHoldTier > 0) {
          bay.minHoldTierNo = minHoldTier;
        }
      }
    }
    this.registerBayList(this.vessel.bayList);

    const vesselDesignInput = new VesselDesignInput(this.bayPlanService.getBayPlan().header);
    vesselDesignInput.content = this.vesselTextParserService.parseVessel2Text(this.vessel);
    if (this.bayPlanService.getBayPlan().header.vesselId) {
      vesselDesignInput.id = this.bayPlanService.getBayPlan().header.vesselId;
    } else if (this.vessel.id) {
      vesselDesignInput.id = this.vessel.id;
    }
    vesselDesignInput.confirmed = 'Y';
    const vessels = new Array<VesselDesignInput>();
    vessels.push(vesselDesignInput);
    this.http.post<string>(properties.serverUrl + '/vessel/design', vessels).toPromise().then(data => {
    });
  }
  modifyBay() {
    for (const bay of this.vessel.bayList) {
      if (bay.name === this.clickedRow.name) {
        bay.hatchName = this.clickedRow.hatchName;
        bay.bayLength = this.clickedRow.bayLength;
        break;
      }
    }
    this.registerBayList(this.vessel.bayList);
  }
  addBay() {
    const bayList: Array<Bay> = new Array<Bay>();
    for (const bay of this.vessel.bayList) {
      if (this.clickedRow.name && Number(bay.name) > Number(this.clickedRow.name)) {
        this.clickedRow.maxDeckTierNo = bay.maxDeckTierNo;
        this.clickedRow.minDeckTierNo = bay.minDeckTierNo;
        this.clickedRow.maxHoldTierNo = bay.maxHoldTierNo;
        this.clickedRow.minHoldTierNo = bay.minHoldTierNo;
        bayList.push(this.clickedRow);
        this.clickedRow = new Bay();
      }
      bayList.push(bay);
    }
    if (this.clickedRow.name) {
      this.vessel.maxBay = this.vessel.maxBay + 1;
      this.clickedRow.maxDeckTierNo = this.vessel.bayList[this.vessel.bayList.length - 1].maxDeckTierNo;
      this.clickedRow.minDeckTierNo = this.vessel.bayList[this.vessel.bayList.length - 1].minDeckTierNo;
      this.clickedRow.maxHoldTierNo = this.vessel.bayList[this.vessel.bayList.length - 1].maxHoldTierNo;
      this.clickedRow.minHoldTierNo = this.vessel.bayList[this.vessel.bayList.length - 1].minHoldTierNo;
      bayList.push(this.clickedRow);
    }
    this.registerBayList(bayList);
  }
  removeBay() {
    const bayList: Array<Bay> = new Array<Bay>();
    for (const bay of this.vessel.bayList) {
      if (bay.name !== this.clickedRow.name) {
        bayList.push(bay);
      } else {
        this.vessel.maxBay = this.vessel.maxBay - 1;
      }
    }
    this.registerBayList(bayList);
  }

  private registerBayList(bayList: Array<Bay>) {
    // this.vessel = BayPlanVesselParser.resetBayList(this.makeBayDataMap(bayList));
    this.vessel = BayPlanVesselParser.makeBayData(bayList);
    this.bayPlanService.setVessel(this.vessel);
    this.eventService.emit(EventIds.CHANGE_VESSEL_MODEL);
  }
  getVessel(): Vessel {
    if (this.vessel) {
      return this.vessel;
    }
    return new Vessel();
  }
  clickRow(row: Bay) {
    if (this.clickedRow === row) {
      this.clickedRow = new Bay();
    } else {
      this.clickedRow = row;
    }
  }
  getElementValue(bay: Bay, columnId: string) {
    if (columnId === 'bayNo') {
      return bay.name;
    } else if (columnId === 'hatchGroup') {
      return bay.hatchName;
    } else if (columnId === 'length') {
      return bay.bayLength;
    } else if (columnId === 'deckMaxTierNo') {
      return bay.maxDeckTierNo;
    } else if (columnId === 'deckMinTierNo') {
      return bay.minDeckTierNo;
    } else if (columnId === 'holdMaxTierNo') {
      return bay.maxHoldTierNo;
    } else if (columnId === 'holdMinTierNo') {
      return bay.minHoldTierNo;
    }
    return '';
  }
}
