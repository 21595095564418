import {VisualPart} from '../../../core/pixi/visual-part';
import * as PIXI from 'pixi.js';

export class HatchVisualPart extends VisualPart {
  leftLine = true;
  rightLine = true;
  constructor(parentGraphics: PIXI.Container) {
    super(parentGraphics);
  }
  draw() {
    super.draw();
    this.getGraphics().lineStyle(1, 0x000000);
    this.getGraphics().drawRect(this.bounds.x, this.bounds.y, this.bounds.width, this.bounds.height);
    this.getGraphics().endFill();
  }
}
