import {EditableModel} from '../../../be-com/etable/editable-model';

export class MyMember implements EditableModel {
  id = '';
  modified = false;
  userId = '';
  roleName = '';
  roleCode = '';
  username = '';
  fullName = '';
  email = '';
}

export class UserGroupMemberInput {
  userGroupId: string;
  memberId: string;
  roleCode: string;
}
