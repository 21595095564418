import {EditableModel} from '../../be-com/etable/editable-model';

export class VesselDesign implements EditableModel{
  id = '';
  modified: boolean;
  callSign = '';
  imoNo = '';
  vesselCode = '';
  vesselName = '';
  content = '';
  maxTeu;
  confirmed = '';
}
