import {Dangerous} from '../../my-plans/service/model/dangerous';
import {v4 as uuidv4} from 'uuid';
import {EditableModel} from '../../be-com/etable/editable-model';
import {ContainerUtils} from './container-utils';
import {Freetext} from '../../my-plans/service/model/freetext';

export class Container implements EditableModel {
  id = uuidv4();
  extraElements = new Array();
  containerNo = '';  // equipmentNumber
  stowage = '';      // stowageCell
  stowageFormat = '';      // stowageCell
  isoSizeType = '';  // equipmentSizeType
  deliveryCode = '';   // delivery code
  fullEmpty = '';
  pol = '';            // Place/Port of Loading
  polAgency = '';
  pod = '';            // Place/Port of discharge
  podAgency = '';
  tsPort = '';         // Transshipment port/Place of transshipment
  tsPortAgency = '';
  optPod1 = '';           // 1st optional port of discharge
  optPod1Agency = '';
  optPod2 = '';           // 2nd optional port of discharge
  optPod2Agency = '';
  optPod3 = '';           // 3rd optional port of discharge
  optPod3Agency = '';
  optPod4 = '';           // 4th optional port of discharge
  optPod4Agency = '';
  optPod5 = '';           // 5rd optional port of discharge
  optPod5Agency = '';
  optPod = '';         // Optional place/port of discharge
  optPodAgency = '';
  nPod = '';           // Next port of discharge
  nPodAgency = '';
  fPod = '';          // Final port or place of discharge
  fPodAgency = '';
  por = '';            // Original port of loading
  porAgency = '';
  placeOfDelivery = '';  // Place of delivery
  placeOfDeliveryAgency = '';

  freeTexts = new Map<string, Array<Freetext>>();
  descriptionOfGoods = ''; // freeTextGood
  handlingInstruction = '';
  loadingRemark = '';    // freeTextLoadingRemark
  specialCargoInstruction = '';
  generalInformation = '';  // freeTextGeneral
  vgmCertificationCode = '';    // freeTextVGM1
  vgmInfo1 = '';
  vgmInfo2 = '';         // freeTextVGM2
  vgmInfo3 = '';         // freeTextVGM3
  vgmInfo4 = '';         // freeTextVGM4
  vgmInfo5 = '';         // freeTextVGM5
  mutuallyDefinedRemark = '';

  grossWeight = '';      // grossWeight
  grossWeightUnit = '';
  vgmWeight = '';     // vgmWeight
  vgmWeightUnit = ''; // vgmWeightUnit
  overDimLeft = '';       // overLengthLeft
  overDimLeftUnit = '';   // overLengthLeftUnit
  overDimRight = '';      // overLengthRight
  overDimRightUnit = '';  // overLengthRightUnit
  overDimHeight = '';     // overLengthHeight
  overDimHeightUnit = ''; // overLengthHeightUnit
  overDimFront = '';      // overLengthFront
  overDimFrontUnit = '';  // overLengthFrontUnit
  overDimBack = '';       // overLengthBack
  overDimBackUnit = '';   // overLengthBackUnit
  temperature = '';
  temperatureUnit = '';
  temperatureRangeMin = '';
  temperatureRangeMax = '';
  temperatureRangeUnit = '';
  temperatureRangeType = '';
  blNo = '';
  bookingNo = '';
  groupCode = '';            // equipmentStatusCode
  bundledContainers: Array<string> = new Array<string>();
  dangerousGoods: Array<Dangerous> = new Array<Dangerous>();
  carrier = '';
  carrierParty = 'CA';
  carrierAgency = '';
  leadingStowage = '';

  breakBulkLength = '';
  breakBulkWidth = '';
  breakBulkHeight = '';
  breakBulkDimensionUnit = '';
  overSlotFront = 0;
  overSlotBack = 0;
  overSlotRight = 0;
  overSlotLeft = 0;
  overSlotHeight = 0;

  shiftStowage: string;
  shiftResponsibleParty: string;

  // ADD all parameter in EdiDocument
  equipmentType = 'CN';
  packageQuantity = '';
  packageType = '';
  polTerminalCode = '';            // Place/Port of Loading
  podTerminalCode = '';            // Place/Port of discharge
  tsTerminalCode = '';            // Transshipment port/Place of transshipment
  optPod1TerminalCode = '';           // 1st optional port of discharge
  optPod2TerminalCode = '';           // 2nd optional port of discharge
  optPod3TerminalCode = '';           // 3rd optional port of discharge
  optPod4TerminalCode = '';           // 4th optional port of discharge
  optPod5TerminalCode = '';           // 5rd optional port of discharge
  optPodTerminalCode = '';         // Optional place/port of discharge
  nPodTerminalCode = '';           // Next port of discharge
  fPodTerminalCode = '';           // Next port of discharge
  porTerminalCode = '';            // Original port of loading
  placeOfDeliveryTerminalCode = '';
  cargoNatures = new Array<string>();

  lineIndex: number;
  modified = false;


  getLength(): number {
    if (this.isoSizeType === undefined || this.isoSizeType.length < 1) {
      return 0;
    }
    const length: string = this.isoSizeType.substring(0, 1);
    if (length === '1') {
      return 10;
    } else if (length === '2') {
      return 20;
    } else if (length === '3') {
      return 30;
    } else if (length === 'B') {
      return 24;
    } else if (length === 'C') {
      return 24.6;
    } else if (length === 'G') {
      return 41;
    } else if (length === 'H') {
      return 43;
    } else if (length === 'L') {
      return 45;
    } else if (length === 'M') {
      return 48;
    } else if (length === 'N') {
      return 49;
    }
    return 40;
  }
  getLengthCode(): string {

    if (1 < this.getLength() && this.getLength() <= 20) {
      return '0112';
    } else if (20 < this.getLength() && this.getLength() <= 40) {
      return '0114';
    } else if (40 < this.getLength() && this.getLength() <= 45) {
      return '011L';
    } else if (45 < this.getLength()) {
      return '011M';
    }
    return '';
  }
  getSlotLengthCode(): string {

    if (this.getLength() <= 20) {
      return '0212';
    } else {
      return '0214';
    }
  }

  getHeight(): number {
    if (this.isoSizeType === undefined || this.isoSizeType.length < 2) {
      return 0;
    }
    const height: string = this.isoSizeType.substring(1, 2);
    if (height === '4') {
      return 9;
    } else if (height > '4') {
      return 9.6;
    }
    return 8.6;
  }
  getFullEmpty(): string {

    if ('M' === this.fullEmpty) {
      return 'Empty';
    } else if ('F' === this.fullEmpty) {
      return 'Full';
    }
    return '';
  }

  getCargoType(): string {
    return ContainerUtils.decodeCargoType(this.getCargoTypeCode());
  }

  getCargoTypeCode(): string {
    if (this.temperature && this.temperature !== '' && this.dangerousGoods.length > 0) {
      return '016RD';
    } else if (this.temperature && this.temperature !== '') {
      return '016RF';
    } else if (this.dangerousGoods.length > 0 && 'M' ===  this.fullEmpty) {
      return '016DM';
    } else if (this.dangerousGoods.length > 0) {
      return '016DG';
    } else if (this.dangerousGoods.length == 0 && 'M' ===  this.fullEmpty) {
      return '016MT';
    }
    return '016OT';
  }
  getWeight(): string {
    if (this.vgmWeight) {
      return this.vgmWeight;
    } else {
      return this.grossWeight;
    }
  }
  hasOverSlot(): boolean {

    return (this.overSlotLeft > 0
      || this.overSlotRight > 0
      || this.overSlotFront > 0
      || this.overSlotBack > 0
      || this.overSlotHeight > 0);
  }
  hasOverDimension(): boolean {
    return (this.checkNull(this.overDimLeft) !== ''
      || this.checkNull(this.overDimRight) !== ''
      || this.checkNull(this.overDimHeight) !== ''
      || this.checkNull(this.overDimFront) !== ''
      || this.checkNull(this.overDimBack) !== '');
  }
  hasBreakBulk(): boolean {

    return (this.checkNull(this.breakBulkLength) !== ''
      || this.checkNull(this.breakBulkHeight) !== ''
      || this.checkNull(this.breakBulkWidth) !== '');
  }
  hasBundledContainers(): boolean {

    return (this.bundledContainers && this.bundledContainers.length > 0);
  }
  hasTemperatureRange(): boolean {

    return (this.temperatureRangeMin !== '' || this.temperatureRangeMax !== '');
  }
  hasVgmInfo(): boolean {

    const qualifier = 'AAY';
    const list = this.freeTexts.get(qualifier) as Array<Freetext>;
    let hasVgmInfo = false;
    if (list && list.length > 0) {
      list.forEach(fx => {

        if (!hasVgmInfo) {
          hasVgmInfo = fx.vgmCertificationCode !== '' || fx.remark !== '' || fx.vgmInfo2 !== '' || fx.vgmInfo3 !== '' || fx.vgmInfo4 !== '' || fx.vgmInfo5 !== '';
        }
      });
    }

    return hasVgmInfo;
  }
  private checkNull(value: string): string {
    if (value) {
      return value;
    } else {
      return '';
    }
  }
}
