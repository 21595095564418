<div>
  <button mat-stroked-button [ngClass]="isSelected(LEGEND)? 'selectedButton': 'tabButton'" color="basic" *ngIf="showLegend" (click)="clickButton(LEGEND)">Legend</button>
  <button mat-stroked-button [ngClass]="isSelected(HELP)? 'selectedButton': 'tabButton'" color="basic" *ngIf="showHelp" (click)="clickButton(HELP)">Help</button>
  <button mat-stroked-button [ngClass]="isSelected(COLUMNS)? 'selectedButton': 'tabButton'" color="basic" *ngIf="showColumns" (click)="clickButton(COLUMNS)">Columns</button>
  <button mat-stroked-button [ngClass]="isSelected(CONTAINER_FILTER)? 'selectedButton': 'tabButton'" color="basic" *ngIf="showContainerFilter" (click)="clickButton(CONTAINER_FILTER)">
    Filter
    <mat-icon *ngIf="filter.numOfFilters() > 0">filter_alt</mat-icon>
    <span class="notification-label" *ngIf="filter.numOfFilters() > 0">{{filter.numOfFilters()}}</span>
  </button>
  <button mat-stroked-button [ngClass]="isSelected(PROPERTIES)? 'selectedButton': 'tabButton'" color="basic" *ngIf="showProperties" (click)="clickButton(PROPERTIES)">Properties</button>
  <button mat-stroked-button [ngClass]="isSelected(SELECTIONS)? 'selectedButton': 'tabButton'" color="basic" *ngIf="showSelections" (click)="clickButton(SELECTIONS)">Selections</button>
  <button mat-stroked-button [ngClass]="isSelected(CHANGE_OF_DISCHARGE)? 'selectedButton': 'tabButton'" color="basic" *ngIf="showChangeOfDischarge" (click)="clickButton(CHANGE_OF_DISCHARGE)">Change of POD</button>
  <button mat-stroked-button [ngClass]="isSelected(MOVINSPALETTE)? 'selectedButton': 'tabButton'" color="basic" *ngIf="showMovinsPalette" (click)="clickButton(MOVINSPALETTE)">Movins Palette</button>
  <button mat-stroked-button [ngClass]="isSelected(PREFERENCES)? 'selectedButton': 'tabButton'" color="basic" *ngIf="showPreferences" (click)="clickButton(PREFERENCES)">Preferences</button>
</div>
<div class="tabBox" *ngIf="showLegend && selectedTab===LEGEND">
  <app-legend></app-legend>
</div>

<div class="tabBox" *ngIf="showHelp && selectedTab===HELP">
  <app-help></app-help>
</div>

<div class="tabBox" *ngIf="showSelections && selectedTab===SELECTIONS">
  <app-selections></app-selections>
</div>

<div class="tabBox" *ngIf="showColumns && selectedTab===COLUMNS">
  <app-columns></app-columns>
</div>
<div class="tabBox" *ngIf="showContainerFilter && selectedTab===CONTAINER_FILTER">
  <app-container-filter [filter]="filter"></app-container-filter>
</div>
<div class="tabBox" *ngIf="showProperties && selectedTab===PROPERTIES">
  <app-properties></app-properties>
</div>
<div class="tabBox" *ngIf="showChangeOfDischarge && selectedTab===CHANGE_OF_DISCHARGE">
  <app-cod></app-cod>
</div>

<div class="tabBox" *ngIf="showMovinsPalette && selectedTab===MOVINSPALETTE">
  <app-palette></app-palette>
</div>

<div class="tabBox" *ngIf="showPreferences && selectedTab===PREFERENCES">
  <app-preferences></app-preferences>
</div>

