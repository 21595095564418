<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="tableContextMenu">
</div>
<mat-menu #tableContextMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="applyContainerFilter()">Filter by stowage</button>
<!--    <button mat-menu-item (click)="openFilter(item)">Paste</button>-->
    <!--    <button mat-menu-item (click)="openFilter(item)">Export to Excel file with visible columns</button>-->
    <!--    <button mat-menu-item (click)="openFilter(item)">Export to Excel file with all columns </button>-->
    <!--    <button mat-menu-item (click)="openFilter(item)">Export to EDI</button>-->
    <!--    <button mat-menu-item (click)="openFilter(item)">Filter entries like this</button>-->
  </ng-template>
</mat-menu>
<table id="editTable"
       mat-table
       [dataSource]="dataSource" matSort
       class="mat-elevation-z3" class="topics"
       (contextmenu)="$event.preventDefault()"
>
  <ng-container matColumnDef="select" >
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let element; let i = index"
        (mousedown)="mouseDownOnCell($event, element, i, selectionBoxColumn)"
        (mouseup)="mouseUpOnCell($event, i, selectionBoxColumn)"
        (mouseenter)="mouseEnterOnCell($event, i, selectionBoxColumn)"
        (mouseleave)="mouseLeaveOnCell($event, i, selectionBoxColumn)"
    >
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(element) : null"
                    [checked]="selection.isSelected(element)"
                    [aria-label]="checkboxLabel(element)">
      </mat-checkbox>
      <container *ngIf="element.modified" style="margin-left: 4px">*</container>
    </td>
  </ng-container>
  <ng-container *ngFor="let column of columns" matColumnDef="{{column.code}}" >

    <ng-container *ngIf="column.code === columnCodes.CATEGORY || column.code === columnCodes.ITEM ">
      <th mat-header-cell *matHeaderCellDef
          (mousedown)="mouseDownOnHeader(column)"
          class="header"
          matTooltip="{{column.name}}"
          matTooltipClass="tooltip">
        <mat-form-field  *ngIf="column.code === columnCodes.CATEGORY" class="port-code-form-field" style="margin-top: 3px">
          <mat-label>Category 1</mat-label>
          <mat-select [formControl]="selectedCategory" (ngModelChange)="displayCategory()" style="width: 150px" multiple>
            <mat-option *ngFor="let k of categoryList" [value]="k">{{k}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field  *ngIf="column.code === columnCodes.ITEM" class="port-code-form-field" style="margin-top: 3px">
          <mat-label>Category 2</mat-label>
          <mat-select [formControl]="selectedItem" (ngModelChange)="displayItem()" style="width: 150px" multiple>
            <mat-option *ngFor="let k of itemList" [value]="k">{{k}}</mat-option>
          </mat-select>
        </mat-form-field>
      </th>
    </ng-container>
    <ng-container *ngIf="!(column.code === columnCodes.CATEGORY || column.code === columnCodes.ITEM)">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
          (mousedown)="mouseDownOnHeader(column)"
          class="header"
          matTooltip="{{column.name}}"
          matTooltipClass="tooltip">
        <div [ngClass]="getStyleClass('', column.code)" class="header">{{column.name}}</div>
      </th>
    </ng-container>
    <td mat-cell *matCellDef="let element; let i = index"
        (mousedown)="mouseDownOnCell($event, element, i, column)"
        (mouseup)="mouseUpOnCell($event, i, column)"
        (mouseenter)="mouseEnterOnCell($event, i, column)"
        (mouseleave)="mouseLeaveOnCell($event, i, column)"
        [ngClass]="isSelectedCell(i, column.code)?'etable-select-td':''"
    >
      <div [ngClass]="getStyleClass(element, column.code)" >
        {{getValue(element, column.code)}}
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="headerCodes; sticky: true" ></tr>
  <tr mat-row *matRowDef="let row; columns: headerCodes;let i = index"
      (contextmenu)="onContextMenu($event, row)"
      [ngClass]="{ 'etable-selected': isReadOnlyRowSelected(row)}"
  >
</table>
<mat-paginator [pageSizeOptions]="[100, 500]" showFirstLastButtons></mat-paginator>
<br>
<!--<table id="editTable"-->
<!--       mat-table [dataSource]="dataSource"-->
<!--       class="mat-elevation-z8" class="topics"-->
<!--       (contextmenu)="$event.preventDefault()">-->
<!--  <ng-container matColumnDef="select">-->
<!--    <th mat-header-cell *matHeaderCellDef>-->
<!--      <mat-checkbox (change)="$event ? masterToggle() : null"-->
<!--                    [checked]="selection.hasValue() && isAllSelected()"-->
<!--                    [indeterminate]="selection.hasValue() && !isAllSelected()"-->
<!--                    [aria-label]="checkboxLabel()">-->
<!--      </mat-checkbox>-->
<!--    </th>-->
<!--    <td mat-cell *matCellDef="let row">-->
<!--      <mat-checkbox (click)="$event.stopPropagation()"-->
<!--                    (change)="$event ? selection.toggle(row) : null"-->
<!--                    [checked]="selection.isSelected(row)"-->
<!--                    [aria-label]="checkboxLabel(row)">-->
<!--      </mat-checkbox>-->
<!--    </td>-->
<!--  </ng-container>-->
<!--  <ng-container matColumnDef="category">-->
<!--    <th mat-header-cell *matHeaderCellDef class="column-header-center">-->
<!--      Category&nbsp;&nbsp;-->
<!--      <select id="Id" #Id="ngModel" class="hideLabel form-control" [(ngModel)]="categoryId" name="Id" (change)="displayCategory()">-->
<!--        <option selected="" value=""></option>-->
<!--        <option [ngValue]="k.Id" *ngFor="let k of categoryIds">-->
<!--          {{k.Name}}-->
<!--        </option>-->
<!--      </select>-->
<!--    </th>-->
<!--    <td mat-cell *matCellDef="let data">-->
<!--      <span class="bold-label">{{data.category}}</span>-->
<!--    </td>-->
<!--  </ng-container>-->
<!--  <ng-container matColumnDef="syntaxName">-->
<!--    <th mat-header-cell *matHeaderCellDef class="column-header-center">-->
<!--      Item&nbsp;&nbsp;-->
<!--      <select id="Id" #Id="ngModel" class="hideLabel form-control" [(ngModel)]="itemId" name="Id" (change)="displayItem()">-->
<!--        <option selected="" value=""></option>-->
<!--        <option [ngValue]="k.Id" *ngFor="let k of itemIds">-->
<!--          {{k.Name}}-->
<!--        </option>-->
<!--      </select>-->
<!--    </th>-->
<!--    <td mat-cell *matCellDef="let data"><span class="bold-label"> {{data.syntaxName}} </span></td>-->
<!--  </ng-container>-->
<!--  <ng-container matColumnDef="description">-->
<!--    <th mat-header-cell *matHeaderCellDef class="column-header-center"> Description </th>-->
<!--    <td mat-cell *matCellDef="let data"><span class="bold-label"> {{data.description}} </span></td>-->
<!--  </ng-container>-->
<!--  <ng-container matColumnDef="lineText">-->
<!--    <th mat-header-cell *matHeaderCellDef class="column-header-left"> Description-2 </th>-->
<!--    <td mat-cell *matCellDef="let data"><span class="common-label"> {{data.lineText}} </span></td>-->
<!--  </ng-container>-->
<!--  <ng-container matColumnDef="lineIndex">-->
<!--    <th mat-header-cell *matHeaderCellDef class="column-header-center"> Location </th>-->
<!--    <td mat-cell *matCellDef="let data"><span class="common-label-center"> {{data.lineIndex}} </span></td>-->
<!--  </ng-container>-->

<!--  <tr mat-header-row *matHeaderRowDef="markersDisplayedColumns"></tr>-->
<!--  <tr mat-row *matRowDef="let row; columns: markersDisplayedColumns"></tr>-->
<!--</table>-->
