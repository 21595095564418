import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthService} from './user/service/auth.service';
import {MenuUrls} from './core/menu/menu-urls';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
              private authService: AuthService,
              private translate: TranslateService,
              private meta: Meta,
              private title: Title) {

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url === '/' && this.authService.isMemberOrAdmin()) {
          window.location.href = MenuUrls.FILE;
          return;
        }
        if (!this.authService.isAuthenticated()) {
          if (event.url !== '/' && !(event.url.match('^(/session|/user|/landing|/sitemap|/google|/robots)\\.*'))) {
            // window.location.href = '/';
          }
        }

    });
    translate.addLangs(['en', 'kr']);
    translate.setDefaultLang('en');

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|kr/) ? browserLang : 'en');

  }

  ngOnInit(): void {
    // this.meta.addTags([
    //   {name: 'keywords', content: 'BAPLIE, MOVINS, Bay Plan, Stowage Plan, Dangerous Goods Segregation, DG Segregation'},
    //   {name: 'robots', content: 'index, follow'},
    //   {name: 'author', content: 'Play Baplie'},
    //   {name: 'date', content: '2022-04-23', scheme: 'YYYY-MM-DD' },
    //   {name: 'description', content: 'Play Baplie is a web tool to visualize, edit, analyze and validate BAPLIE & MOVINS.' }
    // ]);
    // this.title.setTitle('Play Baplie - Visualizing, Editing, Analyzing and Validating');
    // this.meta.updateTag({name: 'description', content: 'Play Baplie is a web tool to visualize, edit, analyze and validate BAPLIE and MOVINS.'});
  }
}
