import {ColumnIds} from '../../panels/columns/service/model/column-ids';
import {StringUtils} from '../../utils/string-utils';

export class ContainerFilter {

  alpha = 1;

  private readonly PROPERTY_SEP = '\'';
  private readonly PROPERTY_KV_SEP = '^';
  private readonly MAP_ITEM_SEP = '+';
  private readonly MAP_KV_SEP = ':';

  private readonly WEIGHT = 'WEIGHT';
  weight = false;
  minWeight = -1;
  maxWeight = -1;

  containerNo = '';

  stowage = '';

  hatchMap = new Map<string, string>();

  private readonly BAY = 'BAY';
  bayMap = new Map<string, string>();

  private readonly MULTI_CONTAINER = 'MULTI_CONTAINER';
  multiContainerMap = new Map<string, string>();

  private readonly MULTI_STOWAGE = 'MULTI_STOWAGE';
  multiStowageMap = new Map<string, string>();

  carrierMap = new Map<string, string>();

  porMap = new Map<string, string>();

  polMap = new Map<string, string>();

  podMap = new Map<string, string>();

  optPodMap = new Map<string, string>();

  tsPortMap = new Map<string, string>();

  nPodMap = new Map<string, string>();

  fPodMap = new Map<string, string>();

  placeOfDeliveryMap = new Map<string, string>();

  isoSizeTypeMap = new Map<string, string>();

  lengthCodeMap = new Map<string, string>();

  heightCodeMap = new Map<string, string>();

  typeCodeMap = new Map<string, string>();

  fullEmptyMap = new Map<string, string>();

  cargoTypeMap = new Map<string, string>();

  imdgMap = new Map<string, string>();

  bundledYnMap = new Map<string, string>();

  breakBulkYnMap = new Map<string, string>();

  overDimensionYnMap = new Map<string, string>();

  overSlotYnMap = new Map<string, string>();

  temperatureRangeYnMap = new Map<string, string>();

  vgmYnMap = new Map<string, string>();

  vgmCertificationCodeMap = new Map<string, string>();

  vgmInfoYnMap = new Map<string, string>();

  private readonly GROUP_CODE = 'GROUP_CODE';
  groupCodeMap = new Map<string, string>();

  groupCodeYnMap = new Map<string, string>();

  descriptionOfGoodsMap = new Map<string, string>();
  handlingInstructionMap = new Map<string, string>();
  loadingRemarksMap = new Map<string, string>();
  specialCargoInstructionMap = new Map<string, string>();
  generalInformationMap = new Map<string, string>();
  mutuallyDefinedRemarksMap = new Map<string, string>();

  specialCargoInstructionYnMap = new Map<string, string>();

  loadingRemarkYnMap = new Map<string, string>();

  generalInformationYnMap = new Map<string, string>();

  mutuallyDefinedRemarkYnMap = new Map<string, string>();

  descriptionOfGoodsYnMap = new Map<string, string>();

  cargoNatureYnMap = new Map<string, string>();

  packageQuantityYnMap = new Map<string, string>();

  packageTypeYnMap = new Map<string, string>();

  blYnMap = new Map<string, string>();

  bookingNoYnMap = new Map<string, string>();

  porTerminalMap = new Map<string, string>();

  polTerminalMap = new Map<string, string>();

  podTerminalMap = new Map<string, string>();

  optPodTerminalMap = new Map<string, string>();

  tsTerminalMap = new Map<string, string>();

  nPodTerminalMap = new Map<string, string>();

  fPodTerminalMap = new Map<string, string>();

  placeOfDeliveryTerminalMap = new Map<string, string>();

  isDefault() {


    return this.numOfFilters() === 0;
  }
  numOfFilters(): number {

    let num = 0;

    if (this.weight) {
      num++;
    }
    if (this.containerNo.trim().length > 0) {
      num++;
    }
    if (this.stowage.trim().length > 0) {
      num++;
    }
    if (this.hatchMap.size > 0) {
      num++;
    }
    if (this.multiContainerMap.size > 0) {
      num++;
    }
    if (this.multiStowageMap.size > 0) {
      num++;
    }
    if (this.carrierMap.size > 0) {
      num++;
    }
    if (this.porMap.size > 0) {
      num++;
    }
    if (this.polMap.size > 0) {
      num++;
    }
    if (this.podMap.size > 0) {
      num++;
    }
    if (this.optPodMap.size > 0) {
      num++;
    }
    if (this.tsPortMap.size > 0) {
      num++;
    }
    if (this.nPodMap.size > 0) {
      num++;
    }
    if (this.fPodMap.size > 0) {
      num++;
    }
    if (this.placeOfDeliveryMap.size > 0) {
      num++;
    }
    if (this.isoSizeTypeMap.size > 0) {
      num++;
    }
    if (this.lengthCodeMap.size > 0) {
      num++;
    }
    if (this.heightCodeMap.size > 0) {
      num++;
    }
    if (this.typeCodeMap.size > 0) {
      num++;
    }
    if (this.fullEmptyMap.size > 0) {
      num++;
    }
    if (this.cargoTypeMap.size > 0) {
      num++;
    }
    if (this.imdgMap.size > 0) {
      num++;
    }
    if (this.bundledYnMap.size > 0) {
      num++;
    }
    if (this.breakBulkYnMap.size > 0) {
      num++;
    }
    if (this.overDimensionYnMap.size > 0) {
      num++;
    }
    if (this.overSlotYnMap.size > 0) {
      num++;
    }
    if (this.temperatureRangeYnMap.size > 0) {
      num++;
    }
    if (this.vgmYnMap.size > 0) {
      num++;
    }
    if (this.vgmCertificationCodeMap.size > 0) {
      num++;
    }
    if (this.vgmInfoYnMap.size > 0) {
      num++;
    }
    if (this.groupCodeMap.size > 0) {
      num++;
    }
    if (this.groupCodeYnMap.size > 0) {
      num++;
    }
    if (this.descriptionOfGoodsMap.size > 0) {
      num++;
    }
    if (this.handlingInstructionMap.size > 0) {
      num++;
    }
    if (this.loadingRemarksMap.size > 0) {
      num++;
    }
    if (this.specialCargoInstructionMap.size > 0) {
      num++;
    }
    if (this.generalInformationMap.size > 0) {
      num++;
    }
    if (this.mutuallyDefinedRemarksMap.size > 0) {
      num++;
    }
    if (this.specialCargoInstructionYnMap.size > 0) {
      num++;
    }
    if (this.loadingRemarkYnMap.size > 0) {
      num++;
    }
    if (this.generalInformationYnMap.size > 0) {
      num++;
    }
    if (this.mutuallyDefinedRemarkYnMap.size > 0) {
      num++;
    }
    if (this.descriptionOfGoodsYnMap.size > 0) {
      num++;
    }
    if (this.cargoNatureYnMap.size > 0) {
      num++;
    }
    if (this.packageQuantityYnMap.size > 0) {
      num++;
    }
    if (this.packageTypeYnMap.size > 0) {
      num++;
    }

    if (this.blYnMap.size > 0) {
      num++;
    }
    if (this.bookingNoYnMap.size > 0) {
      num++;
    }

    if (this.porTerminalMap.size > 0) {
      num++;
    }
    if (this.polTerminalMap.size > 0) {
      num++;
    }
    if (this.podTerminalMap.size > 0) {
      num++;
    }
    if (this.optPodTerminalMap.size > 0) {
      num++;
    }
    if (this.tsTerminalMap.size > 0) {
      num++;
    }
    if (this.nPodTerminalMap.size > 0) {
      num++;
    }
    if (this.fPodTerminalMap.size > 0) {
      num++;
    }
    if (this.placeOfDeliveryTerminalMap.size > 0) {
      num++;
    }
    return num;
  }
  apply(code: string, value: string) {

    if (code === ColumnIds.HATCH) {
      this.hatchMap.set(value, value);
      value.split(' ').forEach(bay => {
        this.bayMap.set(bay, bay);
      });
    }
    if (code === ColumnIds.CONTAINER_NO) {
      this.multiContainerMap.set(value, value);
    }
    if (code === ColumnIds.STOWAGE) {
      this.multiStowageMap.set(value, value);
    }
    if (code === ColumnIds.CARRIER) {
      this.carrierMap.set(value, value);
    }
    if (code === ColumnIds.POR) {
      this.porMap.set(value, value);
    }
    if (code === ColumnIds.POL) {
      this.polMap.set(value, value);
    }
    if (code === ColumnIds.POD) {
      this.podMap.set(value, value);
    }
    if (code === ColumnIds.TS_PORT) {
      this.tsPortMap.set(value, value);
    }
    if (code === ColumnIds.OPT_POD) {
      this.optPodMap.set(value, value);
    }
    if (code === ColumnIds.NPOD) {
      this.nPodMap.set(value, value);
    }
    if (code === ColumnIds.FPOD) {
      this.fPodMap.set(value, value);
    }
    if (code === ColumnIds.PLACE_OF_DELIVERY) {
      this.placeOfDeliveryMap.set(value, value);
    }

    if (code === ColumnIds.ISO_SIZE_TYPE) {
      // this.isoSizeType = value;
      this.isoSizeTypeMap.set(value, value);
    }
    if (code === ColumnIds.CONTAINER_LENGTH) {
      this.lengthCodeMap.set(value, value);
    }
    if (code === ColumnIds.CONTAINER_HEIGHT) {
      this.heightCodeMap.set(value, value);
    }
    if (code === ColumnIds.CONTAINER_TYPE) {
      this.typeCodeMap.set(value, value);
    }
    if (code === ColumnIds.FULL_EMPTY) {

      let name = value;

      if (value === 'M') {
        name = 'Empty';
      } else if (value === 'F') {
        name = 'Full';
      }
      this.fullEmptyMap.set(value, name);
    }
    if (code === ColumnIds.CARGO_TYPE) {
      this.cargoTypeMap.set(value, value);
    }
    if (code === ColumnIds.IMDG) {
      this.imdgMap.set(value, value);
    }

    if (code === ColumnIds.BUNDLED_CONTAINER_YN) {
      this.bundledYnMap.set(value, value);
    }
    if (code === ColumnIds.BREAK_BULK_YN) {
      this.breakBulkYnMap.set(value, value);
    }
    if (code === ColumnIds.OVER_DIMENSIONS_YN) {
      this.overDimensionYnMap.set(value, value);
    }
    if (code === ColumnIds.OVER_SLOTS_YN) {
      this.overSlotYnMap.set(value, value);
    }
    if (code === ColumnIds.TEMPERATURE_RANGE_YN) {
      this.temperatureRangeYnMap.set(value, value);
    }

    if (code === ColumnIds.VGM_WEIGHT_YN) {
      this.vgmYnMap.set(value, value);
    }
    if (code === ColumnIds.VGM_CERTI_CODE) {
      this.vgmCertificationCodeMap.set(value, value);
    }
    if (code === ColumnIds.VGM_INFO_YN) {
      this.vgmInfoYnMap.set(value, value);
    }
    if (code === ColumnIds.GROUP_CODE_YN) {
      this.groupCodeYnMap.set(value, value);
    }
    if (code === ColumnIds.GROUP_CODE) {
      this.groupCodeMap.set(value, value);
    }

    if (code === ColumnIds.DESCRIPTION_OF_GOODS) {
      this.descriptionOfGoodsMap.set(value, value);
    }
    if (code === ColumnIds.HANDLING_INSTRUCTION) {
      this.handlingInstructionMap.set(value, value);
    }
    if (code === ColumnIds.LOADING_REMARK) {
      this.loadingRemarksMap.set(value, value);
    }
    if (code === ColumnIds.SPECIAL_CARGO_INSTRUCTION) {
      this.generalInformationMap.set(value, value);
    }
    if (code === ColumnIds.GENERAL_INFORMATION) {
      this.specialCargoInstructionMap.set(value, value);
    }
    if (code === ColumnIds.MUTUALLY_DEFINED_REMARK) {
      this.mutuallyDefinedRemarksMap.set(value, value);
    }
    if (code === ColumnIds.SPECIAL_CARGO_INSTRUCTION_YN) {
      this.specialCargoInstructionYnMap.set(value, value);
    }
    if (code === ColumnIds.LOADING_REMARK_YN) {
      this.loadingRemarkYnMap.set(value, value);
    }
    if (code === ColumnIds.GENERAL_INFORMATION_YN) {
      this.generalInformationYnMap.set(value, value);
    }
    if (code === ColumnIds.MUTUALLY_DEFINED_REMARK_YN) {
      this.mutuallyDefinedRemarkYnMap.set(value, value);
    }
    if (code === ColumnIds.DESCRIPTION_OF_GOODS_YN) {
      this.descriptionOfGoodsYnMap.set(value, value);
    }
    if (code === ColumnIds.CARGO_NATURE_YN) {
      this.cargoNatureYnMap.set(value, value);
    }
    if (code === ColumnIds.PACKAGE_QUANTITY_YN) {
      this.packageQuantityYnMap.set(value, value);
    }
    if (code === ColumnIds.PACKAGE_TYPE_YN) {
      this.packageTypeYnMap.set(value, value);
    }

    if (code === ColumnIds.BL_NO_YN) {
      this.blYnMap.set(value, value);
    }
    if (code === ColumnIds.BOOKING_NO_YN) {
      this.bookingNoYnMap.set(value, value);
    }

    if (code === ColumnIds.POL_TERMINAL) {
      this.polTerminalMap.set(value, value);
    }
    if (code === ColumnIds.POD_TERMINAL) {
      this.podTerminalMap.set(value, value);
    }
    if (code === ColumnIds.OPT_POD_TERMINAL) {
      this.optPodTerminalMap.set(value, value);
    }
    if (code === ColumnIds.NPOD_TERMINAL) {
      this.nPodTerminalMap.set(value, value);
    }
    if (code === ColumnIds.FPOD_TERMINAL) {
      this.fPodTerminalMap.set(value, value);
    }
    if (code === ColumnIds.POR_TERMINAL) {
      this.podTerminalMap.set(value, value);
    }
    if (code === ColumnIds.PLACE_OF_DELIVERY_TERMINAL) {
      this.placeOfDeliveryTerminalMap.set(value, value);
    }
    // if (code === ColumnIds.EQUIPMENT_TYPE) {
    //
    // }

  }
  toString(): string {

    const pptSep = this.PROPERTY_SEP;
    const pptKvSep = this.PROPERTY_KV_SEP;

    let serial = '';

    if (this.weight) {
      serial = serial + pptSep + ColumnIds.WEIGHT + pptKvSep;
      serial = serial + this.weight + this.MAP_ITEM_SEP + this.minWeight + this.MAP_KV_SEP + this.maxWeight;
    }

    if (this.containerNo.trim().length > 0) {
      serial = serial + pptSep + ColumnIds.CONTAINER_NO + pptKvSep;
      serial = serial + this.containerNo;
    }

    if (this.hatchMap.size > 0) {
      serial = serial + pptSep + ColumnIds.HATCH + pptKvSep;
      serial = serial + this.mapToString(this.hatchMap);
    }

    if (this.bayMap.size > 0) {
      serial = serial + pptSep + this.BAY + pptKvSep;
      serial = serial + this.mapToString(this.bayMap);
    }

    if (this.multiContainerMap.size > 0) {
      serial = serial + pptSep + this.MULTI_CONTAINER + pptKvSep;
      serial = serial + this.mapToString(this.multiContainerMap);
    }

    if (this.multiStowageMap.size > 0) {
      serial = serial + pptSep + this.MULTI_STOWAGE + pptKvSep;
      serial = serial + this.mapToString(this.multiContainerMap);
    }

    if (this.stowage.trim().length > 0) {
      serial = serial + pptSep + ColumnIds.STOWAGE + pptKvSep;
      serial = serial + this.stowage;
    }

    if (this.carrierMap.size > 0) {
      serial = serial + pptSep + ColumnIds.CARRIER + pptKvSep;
      serial = serial + this.mapToString(this.carrierMap);
    }

    if (this.porMap.size > 0) {
      serial = serial + pptSep + ColumnIds.POR + pptKvSep;
      serial = serial + this.mapToString(this.porMap);
    }

    if (this.polMap.size > 0) {
      serial = serial + pptSep + ColumnIds.POL + pptKvSep;
      serial = serial + this.mapToString(this.polMap);
    }

    if (this.podMap.size > 0) {
      serial = serial + pptSep + ColumnIds.POD + pptKvSep;
      serial = serial + this.mapToString(this.podMap);
    }

    if (this.optPodMap.size > 0) {
      serial = serial + pptSep + ColumnIds.OPT_POD + pptKvSep;
      serial = serial + this.mapToString(this.optPodMap);
    }

    if (this.tsPortMap.size > 0) {
      serial = serial + pptSep + ColumnIds.TS_PORT + pptKvSep;
      serial = serial + this.mapToString(this.tsPortMap);
    }

    if (this.nPodMap.size > 0) {
      serial = serial + pptSep + ColumnIds.NPOD + pptKvSep;
      serial = serial + this.mapToString(this.nPodMap);
    }
    if (this.fPodMap.size > 0) {
      serial = serial + pptSep + ColumnIds.FPOD + pptKvSep;
      serial = serial + this.mapToString(this.fPodMap);
    }

    if (this.placeOfDeliveryMap.size > 0) {
      serial = serial + pptSep + ColumnIds.PLACE_OF_DELIVERY + pptKvSep;
      serial = serial + this.mapToString(this.placeOfDeliveryMap);
    }

    if (this.isoSizeTypeMap.size > 0) {
      serial = serial + pptSep + ColumnIds.ISO_SIZE_TYPE + pptKvSep;
      serial = serial + this.mapToString(this.isoSizeTypeMap);
    }

    if (this.lengthCodeMap.size > 0) {
      serial = serial + pptSep + ColumnIds.CONTAINER_LENGTH + pptKvSep;
      serial = serial + this.mapToString(this.lengthCodeMap);
    }

    if (this.heightCodeMap.size > 0) {
      serial = serial + pptSep + ColumnIds.CONTAINER_HEIGHT + pptKvSep;
      serial = serial + this.mapToString(this.heightCodeMap);
    }

    if (this.typeCodeMap.size > 0) {
      serial = serial + pptSep + ColumnIds.CONTAINER_TYPE + pptKvSep;
      serial = serial + this.mapToString(this.typeCodeMap);
    }

    if (this.fullEmptyMap.size > 0) {
      serial = serial + pptSep + ColumnIds.FULL_EMPTY + pptKvSep;
      serial = serial + this.mapToString(this.fullEmptyMap);
    }

    if (this.cargoTypeMap.size > 0) {
      serial = serial + pptSep + ColumnIds.CARGO_TYPE_CODE + pptKvSep;
      serial = serial + this.mapToString(this.cargoTypeMap);
    }

    if (this.imdgMap.size > 0) {
      serial = serial + pptSep + ColumnIds.IMDG + pptKvSep;
      serial = serial + this.mapToString(this.imdgMap);
    }

    if (this.bundledYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.BUNDLED_CONTAINER_YN + pptKvSep;
      serial = serial + this.mapToString(this.bundledYnMap);
    }

    if (this.breakBulkYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.BREAK_BULK_YN + pptKvSep;
      serial = serial + this.mapToString(this.breakBulkYnMap);
    }

    if (this.overDimensionYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.OVER_DIMENSIONS_YN + pptKvSep;
      serial = serial + this.mapToString(this.overDimensionYnMap);
    }

    if (this.overSlotYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.OVER_SLOTS_YN + pptKvSep;
      serial = serial + this.mapToString(this.overSlotYnMap);
    }

    if (this.temperatureRangeYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.TEMPERATURE_RANGE_YN + pptKvSep;
      serial = serial + this.mapToString(this.temperatureRangeYnMap);
    }

    if (this.vgmYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.VGM_WEIGHT_YN + pptKvSep;
      serial = serial + this.mapToString(this.vgmYnMap);
    }

    if (this.vgmCertificationCodeMap.size > 0) {
      serial = serial + pptSep + ColumnIds.VGM_CERTI_CODE + pptKvSep;
      serial = serial + this.mapToString(this.vgmCertificationCodeMap);
    }

    if (this.vgmInfoYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.VGM_INFO_YN + pptKvSep;
      serial = serial + this.mapToString(this.vgmInfoYnMap);
    }

    if (this.groupCodeMap.size > 0) {
      serial = serial + pptSep + ColumnIds.GROUP_CODE + pptKvSep;
      serial = serial + this.mapToString(this.groupCodeMap);
    }

    if (this.groupCodeYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.GROUP_CODE_YN + pptKvSep;
      serial = serial + this.mapToString(this.groupCodeYnMap);
    }

    if (this.descriptionOfGoodsMap.size > 0) {
      serial = serial + pptSep + ColumnIds.DESCRIPTION_OF_GOODS + pptKvSep;
      serial = serial + this.mapToString(this.descriptionOfGoodsMap);
    }

    if (this.handlingInstructionMap.size > 0) {
      serial = serial + pptSep + ColumnIds.HANDLING_INSTRUCTION + pptKvSep;
      serial = serial + this.mapToString(this.handlingInstructionMap);
    }

    if (this.loadingRemarksMap.size > 0) {
      serial = serial + pptSep + ColumnIds.LOADING_REMARK + pptKvSep;
      serial = serial + this.mapToString(this.loadingRemarksMap);
    }
    if (this.specialCargoInstructionMap.size > 0) {
      serial = serial + pptSep + ColumnIds.SPECIAL_CARGO_INSTRUCTION + pptKvSep;
      serial = serial + this.mapToString(this.specialCargoInstructionMap);
    }
    if (this.generalInformationMap.size > 0) {
      serial = serial + pptSep + ColumnIds.GENERAL_INFORMATION + pptKvSep;
      serial = serial + this.mapToString(this.generalInformationMap);
    }

    if (this.mutuallyDefinedRemarksMap.size > 0) {
      serial = serial + pptSep + ColumnIds.MUTUALLY_DEFINED_REMARK + pptKvSep;
      serial = serial + this.mapToString(this.mutuallyDefinedRemarksMap);
    }

    if (this.specialCargoInstructionYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.SPECIAL_CARGO_INSTRUCTION_YN + pptKvSep;
      serial = serial + this.mapToString(this.specialCargoInstructionYnMap);
    }

    if (this.loadingRemarkYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.LOADING_REMARK_YN + pptKvSep;
      serial = serial + this.mapToString(this.loadingRemarkYnMap);
    }

    if (this.generalInformationYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.GENERAL_INFORMATION_YN + pptKvSep;
      serial = serial + this.mapToString(this.generalInformationYnMap);
    }

    if (this.mutuallyDefinedRemarkYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.MUTUALLY_DEFINED_REMARK_YN + pptKvSep;
      serial = serial + this.mapToString(this.mutuallyDefinedRemarkYnMap);
    }

    if (this.descriptionOfGoodsYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.DESCRIPTION_OF_GOODS_YN + pptKvSep;
      serial = serial + this.mapToString(this.descriptionOfGoodsYnMap);
    }

    if (this.cargoNatureYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.CARGO_NATURE_YN + pptKvSep;
      serial = serial + this.mapToString(this.cargoNatureYnMap);
    }

    if (this.packageQuantityYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.PACKAGE_QUANTITY_YN + pptKvSep;
      serial = serial + this.mapToString(this.packageQuantityYnMap);
    }

    if (this.packageTypeYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.PACKAGE_TYPE_YN + pptKvSep;
      serial = serial + this.mapToString(this.packageTypeYnMap);
    }

    if (this.blYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.BL_NO_YN + pptKvSep;
      serial = serial + this.mapToString(this.blYnMap);
    }

    if (this.bookingNoYnMap.size > 0) {
      serial = serial + pptSep + ColumnIds.BOOKING_NO_YN + pptKvSep;
      serial = serial + this.mapToString(this.bookingNoYnMap);
    }

    if (this.porTerminalMap.size > 0) {
      serial = serial + pptSep + ColumnIds.POR_TERMINAL + pptKvSep;
      serial = serial + this.mapToString(this.porTerminalMap);
    }

    if (this.polTerminalMap.size > 0) {
      serial = serial + pptSep + ColumnIds.POL_TERMINAL + pptKvSep;
      serial = serial + this.mapToString(this.polTerminalMap);
    }

    if (this.podTerminalMap.size > 0) {
      serial = serial + pptSep + ColumnIds.POD_TERMINAL + pptKvSep;
      serial = serial + this.mapToString(this.podTerminalMap);
    }

    if (this.optPodTerminalMap.size > 0) {
      serial = serial + pptSep + ColumnIds.OPT_POD_TERMINAL + pptKvSep;
      serial = serial + this.mapToString(this.optPodTerminalMap);
    }

    if (this.tsTerminalMap.size > 0) {
      serial = serial + pptSep + ColumnIds.TS_TERMINAL + pptKvSep;
      serial = serial + this.mapToString(this.tsTerminalMap);
    }

    if (this.nPodTerminalMap.size > 0) {
      serial = serial + pptSep + ColumnIds.NPOD_TERMINAL + pptKvSep;
      serial = serial + this.mapToString(this.nPodTerminalMap);
    }

    if (this.fPodTerminalMap.size > 0) {
      serial = serial + pptSep + ColumnIds.FPOD_TERMINAL + pptKvSep;
      serial = serial + this.mapToString(this.fPodTerminalMap);
    }

    if (this.placeOfDeliveryTerminalMap.size > 0) {
      serial = serial + pptSep + ColumnIds.PLACE_OF_DELIVERY_TERMINAL + pptKvSep;
      serial = serial + this.mapToString(this.placeOfDeliveryTerminalMap);
    }

    return serial;
  }

  private mapToString(map: Map<string, string>): string {

    let str = '';
    if (map.size > 0) {
      map.forEach((v, k) => {

        let key = k;
        if (!key) {
          key = '';
        }

        let val = v;
        if (!val) {
          val = '';
        }
        str = str + key + this.MAP_KV_SEP + val + this.MAP_ITEM_SEP;

      });

      str = str.substring(0, str.length - 1);
    }

    return str;
  }
  applyFromString(str: string) {

    if (!str) {
      return;
    }
    const properties: string[] = str.split(this.PROPERTY_SEP);

    if (properties.length <= 0) {
      return;
    }

    properties.forEach(property => {
      const pptKv: string[] = property.split(this.PROPERTY_KV_SEP);

      if (pptKv.length < 2) {
        return;
      }
      const key = pptKv[0];
      const value = pptKv[1];

      if (key === this.WEIGHT) {
        const weightMinMax: string[] = value.split(this.MAP_ITEM_SEP);

        if (weightMinMax.length === 2) {
          this.weight = weightMinMax[0] === 'true';

          const minMax: string[] = weightMinMax[1].split(this.MAP_KV_SEP);

          if (minMax.length === 2) {
            this.minWeight = StringUtils.toNumber(minMax[0]);
            this.maxWeight = StringUtils.toNumber(minMax[1]);
          }
        }
      }
      if (key === ColumnIds.CONTAINER_NO) {
        this.containerNo = value;
      }
      if (key === ColumnIds.STOWAGE) {
        this.stowage = value;
      }
      if (key === ColumnIds.HATCH) {
        this.hatchMap = this.valueStringToMap(value);
      }
      if (key === this.BAY) {
        this.bayMap = this.valueStringToMap(value);
      }
      if (key === this.MULTI_CONTAINER) {
        this.multiContainerMap = this.valueStringToMap(value);
      }
      if (key === this.MULTI_STOWAGE) {
        this.multiStowageMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.CARRIER) {
        this.carrierMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.POR) {
        this.porMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.POL) {
        this.polMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.POD) {
        this.podMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.OPT_POD) {
        this.optPodMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.TS_PORT) {
        this.tsPortMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.NPOD) {
        this.nPodMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.FPOD) {
        this.fPodMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.PLACE_OF_DELIVERY) {
        this.placeOfDeliveryMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.ISO_SIZE_TYPE) {
        this.isoSizeTypeMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.CONTAINER_LENGTH) {
        this.lengthCodeMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.CONTAINER_HEIGHT) {
        this.heightCodeMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.CONTAINER_TYPE) {
        this.typeCodeMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.FULL_EMPTY) {
        this.fullEmptyMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.CARGO_TYPE_CODE) {
        this.cargoTypeMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.IMDG) {
        this.imdgMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.BUNDLED_CONTAINER_YN) {
        this.bundledYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.BREAK_BULK_YN) {
        this.breakBulkYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.OVER_DIMENSIONS_YN) {
        this.overDimensionYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.OVER_SLOTS_YN) {
        this.overSlotYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.TEMPERATURE_RANGE_YN) {
        this.temperatureRangeYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.VGM_WEIGHT_YN) {
        this.vgmYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.VGM_CERTI_CODE) {
        this.vgmCertificationCodeMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.VGM_INFO_YN) {
        this.vgmInfoYnMap = this.valueStringToMap(value);
      }
      if (key === this.GROUP_CODE) {
        this.groupCodeMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.GROUP_CODE_YN) {
        this.groupCodeYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.DESCRIPTION_OF_GOODS) {
        this.descriptionOfGoodsMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.HANDLING_INSTRUCTION) {
        this.handlingInstructionMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.LOADING_REMARK) {
        this.loadingRemarksMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.SPECIAL_CARGO_INSTRUCTION) {
        this.specialCargoInstructionMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.GENERAL_INFORMATION) {
        this.generalInformationMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.MUTUALLY_DEFINED_REMARK) {
        this.mutuallyDefinedRemarksMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.SPECIAL_CARGO_INSTRUCTION_YN) {
        this.specialCargoInstructionYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.LOADING_REMARK_YN) {
        this.loadingRemarkYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.GENERAL_INFORMATION_YN) {
        this.generalInformationYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.MUTUALLY_DEFINED_REMARK_YN) {
        this.mutuallyDefinedRemarkYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.DESCRIPTION_OF_GOODS_YN) {
        this.descriptionOfGoodsYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.CARGO_NATURE_YN) {
        this.cargoNatureYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.PACKAGE_QUANTITY_YN) {
        this.packageQuantityYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.PACKAGE_TYPE_YN) {
        this.packageTypeYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.BL_NO_YN) {
        this.blYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.BOOKING_NO_YN) {
        this.bookingNoYnMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.POR_TERMINAL) {
        this.porTerminalMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.POL_TERMINAL) {
        this.polTerminalMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.POD_TERMINAL) {
        this.podTerminalMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.OPT_POD_TERMINAL) {
        this.optPodTerminalMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.TS_TERMINAL) {
        this.tsTerminalMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.NPOD_TERMINAL) {
        this.nPodTerminalMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.FPOD_TERMINAL) {
        this.fPodTerminalMap = this.valueStringToMap(value);
      }
      if (key === ColumnIds.PLACE_OF_DELIVERY_TERMINAL) {
        this.placeOfDeliveryTerminalMap = this.valueStringToMap(value);
      }
    });
  }
  private valueStringToMap(pptValue: string): Map<string, string> {
    const map = new Map<string, string>();
    const items = pptValue.split(this.MAP_ITEM_SEP);

    items.forEach(item => {
      const kv = item.split(this.MAP_KV_SEP);

      if (kv.length === 2) {
        map.set(kv[0], kv[1]);
      }
    });

    return map;
  }
}
