export const EventIds = {

  ROUTE_CHANGE: 'ROUTE_CHANGE',
  CHANGE_RIGHT_PANEL: 'CHANGE_RIGHT_PANEL',
  BAY_PLAN_LAYOUT_MODE: 'BAY_PLAN_LAYOUT_MODE',
  CHANGE_SELECTION_MODE: 'CHANGE_SELECTION_MODE',
  CHANGE_COLOR_OPTION: 'CHANGE_COLOR_OPTION',
  BAY_PLAN_ZOOM: 'BAY_PLAN_ZOOM',

  CONSOLE_LOGGING: 'CONSOLE_LOGGING',

  SELECT_VISUAL_PARTS: 'SELECT_VISUAL_PARTS',

  LOAD_BAY_PLAN: 'LOAD_BAY_PLAN',
  VESSEL_DESIGN_LOADED: 'VESSEL_DESIGN_RECONCILED',
  SELECT_EDI_FILE: 'SELECT_EDI_FILE',
  CHANGE_EDI_HEADER: 'CHANGE_EDI_HEADER',
  CHANGE_VESSEL_MODEL: 'CHANGE_VESSEL_MODEL',
  MARKERS: 'MARKERS',

  STORAGE_VESSEL: 'STORAGE_VESSEL',
  STORAGE_EDI_DOCUMENT: 'STORAGE_EDI_DOCUMENT',
  STORAGE_PORT_CODE: 'STORAGE_PORT_CODE',

  UNDO_BAY_PLAN: 'UNDO_BAY_PLAN',
  REDO_BAY_PLAN: 'REDO_BAY_PLAN',
  SAVE_BAY_PLAN: 'SAVE_BAY_PLAN',

  CODES_RECONCILED: 'CODES_RECONCILED',
  APPLY_FILTER: 'APPLY_FILTER',
  FILTER_FROM_LIST: 'FILTER_FROM_LIST',
  COLUMNS_CHANGED: 'COLUMNS_CHANGED',
  CONTAINERS_SELECTED: 'CONTAINERS_SELECTED',
  UPDATE_WEIGHT_GROUPS: 'UPDATE_WEIGHT_GROUPS',
  TEAMS_UPDATED: 'TEAMS_UPDATED',

  TABLE_ITEM_SELECTED: 'TABLE_ITEM_SELECTED',
  BAY_PLAN_DIRTY: 'BAY_PLAN_DIRTY',
  BAPLIE_HEADER_SAVED: 'BAPLIE_HEADER_SAVED'
};
