<div fxLayout="col">
  <div>
    <table class="table table-responsive table-hover table-bordered">
    <thead>
    <tr>
      <th colspan="2">
        <select id="Id" #Id="ngModel" class="hideLabel form-control" [(ngModel)]="rowType" name="Id" (change)="displayContainerQuantity()">
          <option selected="" value=""></option>
          <option [ngValue]="k.Id" *ngFor="let k of nameList">
            {{k.Name}}
          </option>
        </select>
      </th>
      <ng-container *ngIf="total.lengthUnknown == 0">
        <th class='header' colspan="4">Container Length</th>
      </ng-container>
      <ng-container *ngIf="total.lengthUnknown > 0">
        <th class='header' colspan="5">Container Length</th>
      </ng-container>

      <th class='header' colspan="6">Cargo Type</th>
      <th class='header' colspan="4"></th>
    </tr>
    <tr>
      <th></th>
      <th class="center" scope="col">Sum</th>

      <th class="center" scope="col"> 20' </th>
      <th class="center" scope="col"> 40' </th>
      <th class="center" scope="col"> 45' </th>
      <th class="center" scope="col"> >45' </th>
      <ng-container *ngIf="total.lengthUnknown > 0">
        <th class="center" scope="col"> Unknown </th>
      </ng-container>

      <th class="center" scope="col">{{decodeCargoType('016MT')}}</th>
      <th class="center" scope="col">{{decodeCargoType('016DM')}}</th>
      <th class="center" scope="col">{{decodeCargoType('016RF')}}</th>
      <th class="center" scope="col">{{decodeCargoType('016DG')}}</th>
      <th class="center" scope="col">{{decodeCargoType('016RD')}}</th>
      <th class="center" scope="col">{{decodeCargoType('016OT')}}</th>

      <th class="center" scope="col">Over dimensions</th>
      <th class="center" scope="col">Over slot</th>
      <th class="center" scope="col">Break bulk</th>
      <th class="center" scope="col">Bundled</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of items">
      <th scope="row" class="center" style="background-color: {{item.color}}" (click)="onClickItem($event.target, item.rowValue, '', 0)">{{item.rowValue}}</th>
      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.total)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, properties.TOTAL, item.total)">{{numberFormat(item.total)}}</td>

      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.length20)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.CONTAINER_LENGTH, item.length20, properties.LENGTH_2)">{{numberFormat(item.length20)}}</td>
      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.length40)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.CONTAINER_LENGTH, item.length40, properties.LENGTH_4)">{{numberFormat(item.length40)}}</td>
      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.lengthL)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.CONTAINER_LENGTH, item.lengthL, properties.LENGTH_L)">{{numberFormat(item.lengthL)}}</td>
      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.lengthM)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.CONTAINER_LENGTH, item.lengthM, properties.LENGTH_M)">{{numberFormat(item.lengthM)}}</td>

      <ng-container *ngIf="total.lengthUnknown > 0">
        <td (mouseenter)="mouseEnterCell($event.target, 'number', item.lengthUnknown)"
            (mouseleave)="mouseLeaveCell($event.target, 'number')"
            (click)="onClickItem($event.target, item.rowValue, columnIds.CONTAINER_LENGTH, item.lengthUnknown, properties.LENGTH_UNKNOWN)">{{numberFormat(item.lengthUnknown)}}</td>
      </ng-container>

      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.empty)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.CARGO_TYPE_CODE, item.empty, properties.EMPTY)">{{numberFormat(item.empty)}}</td>

      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.emptyDangerous)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.CARGO_TYPE_CODE, item.emptyDangerous, properties.EMPTY_DG)">{{numberFormat(item.emptyDangerous)}}</td>

      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.reefer)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.CARGO_TYPE_CODE, item.reefer, properties.REEFER)">{{numberFormat(item.reefer)}}</td>

      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.dangerous)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.CARGO_TYPE_CODE, item.dangerous, properties.DG)">{{numberFormat(item.dangerous)}}</td>

      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.reeferDangerous)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.CARGO_TYPE_CODE, item.reeferDangerous, properties.REEFER_DG)">{{numberFormat(item.reeferDangerous)}}</td>

      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.otherFull)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.CARGO_TYPE_CODE, item.otherFull, properties.OTHER_FULL)">{{numberFormat(item.otherFull)}}</td>

      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.overDimension)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.OVER_DIMENSIONS_YN, item.overDimension)">{{numberFormat(item.overDimension)}}</td>

      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.overSlot)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.OVER_SLOTS_YN, item.overSlot)">{{numberFormat(item.overSlot)}}</td>

      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.breakBulk)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.BREAK_BULK_YN, item.breakBulk)">{{numberFormat(item.breakBulk)}}</td>

      <td (mouseenter)="mouseEnterCell($event.target, 'number', item.bundled)"
          (mouseleave)="mouseLeaveCell($event.target, 'number')"
          (click)="onClickItem($event.target, item.rowValue, columnIds.BUNDLED_CONTAINER_YN, item.bundled)">{{numberFormat(item.bundled)}}</td>
    </tr>
    </tbody>
      <tfoot>
      <tr>
        <th></th>
        <td class="sum">{{numberFormat(total.total)}}</td>

        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.length20)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.CONTAINER_LENGTH, total.length20, properties.LENGTH_2)">
          {{numberFormat(total.length20)}}</td>
        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.length40)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.CONTAINER_LENGTH, total.length40, properties.LENGTH_4)">
          {{numberFormat(total.length40)}}</td>
        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.lengthL)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.CONTAINER_LENGTH, total.lengthL, properties.LENGTH_L)">
          {{numberFormat(total.lengthL)}}</td>
        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.lengthM)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.CONTAINER_LENGTH, total.lengthM, properties.LENGTH_M)">
          {{numberFormat(total.lengthM)}}</td>

        <ng-container *ngIf="total.lengthUnknown > 0">
          <td class="sum"
              (mouseenter)="mouseEnterCell($event.target, 'sum', total.length20)"
              (mouseleave)="mouseLeaveCell($event.target, 'sum')"
              (click)="onClickItem($event.target, 'SUM', columnIds.CONTAINER_LENGTH, total.length20, properties.LENGTH_2)">
            {{numberFormat(total.lengthUnknown)}}</td>
        </ng-container>

        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.empty)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.CARGO_TYPE_CODE, total.empty, properties.EMPTY)">{{numberFormat(total.empty)}}</td>
        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.emptyDangerous)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.CARGO_TYPE_CODE, total.emptyDangerous, properties.EMPTY_DG)">{{numberFormat(total.emptyDangerous)}}</td>
        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.reefer)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.CARGO_TYPE_CODE, total.reefer, properties.REEFER)">{{numberFormat(total.reefer)}}</td>
        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.dangerous)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.CARGO_TYPE_CODE, total.dangerous, properties.DG)">{{numberFormat(total.dangerous)}}</td>
        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.reeferDangerous)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.CARGO_TYPE_CODE, total.reeferDangerous, properties.REEFER_DG)">{{numberFormat(total.reeferDangerous)}}</td>
        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.otherFull)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.CARGO_TYPE_CODE, total.otherFull, properties.OTHER_FULL)">{{numberFormat(total.otherFull)}}</td>

        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.overDimension)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.OVER_DIMENSIONS_YN, total.overDimension)">{{numberFormat(total.overDimension)}}</td>

        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.overSlot)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.OVER_SLOTS_YN, total.overSlot)">{{numberFormat(total.overSlot)}}</td>

        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.breakBulk)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.BREAK_BULK_YN, total.breakBulk)">{{numberFormat(total.breakBulk)}}</td>

        <td class="sum"
            (mouseenter)="mouseEnterCell($event.target, 'sum', total.bundled)"
            (mouseleave)="mouseLeaveCell($event.target, 'sum')"
            (click)="onClickItem($event.target, 'SUM', columnIds.BUNDLED_CONTAINER_YN, total.bundled)">{{numberFormat(total.bundled)}}</td>
      </tr>
      </tfoot>
  </table>
  </div>
  <button *ngIf="filter.numOfFilters() > 0" mat-icon-button color="primary"  (click)="clearFilters()"
          matTooltipClass="tooltip"
          matTooltip="Clear">
    <mat-icon>filter_alt_off</mat-icon>
  </button>
</div>
