import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {properties} from '../../../../environments/environment';
import {WeightGroup} from './weight-group';
import {EventService} from '../../../core/event/event.service';

@Injectable({
  providedIn: 'root'
})
export class WeightGroupService {

  constructor(private http: HttpClient) { }

  selectWeightGroup(laneCode: string): Observable<WeightGroup[]> {
    return this.http.get<WeightGroup[]>(properties.serverUrl + '/weight_group?service_lane_code=' + laneCode);
  }
  updateWeightGroup(items: WeightGroup[]) {
    return this.http.post(properties.serverUrl + '/weight_group', items);
  }
  deleteWeightGroup(items: WeightGroup[]) {
    let ids = '';

    items.forEach(item => {
      if (ids === '' ) {
        ids = item.id;
      } else {
        ids = ids + ',' + item.id;
      }
    });
    return this.http.delete(properties.serverUrl + '/weight_group?id=' + ids);
  }
}
