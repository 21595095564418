import {SyntaxMessageError} from './syntax-message-error';

export class SyntaxMessage {
  lineIndex: number;
  lineText: string;
  errorMessageArray: Array<SyntaxMessageError>;

  constructor() {
    this.errorMessageArray = [];
  }
}
