import * as PIXI from 'pixi.js';
import {VisualPart} from '../../../core/pixi/visual-part';
import {HatchCover} from '../../../hull/model/hatch-cover';

export class HatchCoverVisualPart extends VisualPart {

  constructor(parentGraphics: PIXI.Container, protected hatchCover?: HatchCover) {
    super(parentGraphics);
  }

  draw() {
    super.draw();
    this.getGraphics().beginFill(0x000000);
    this.getGraphics().drawRect(this.bounds.x, this.bounds.y, this.bounds.width, this.bounds.height);
    this.getGraphics().endFill();
  }

}
