import xml2js from 'xml2js';
import {BayPlan} from '../../service/model/bay-plan';
import {SegmentItem, SyntaxItem} from '../../service/bay-plan-edi-parser.service';
import {BaplieReaderService} from './baplie-reader.service';
import {HttpClient} from '@angular/common/http';
import {Dangerous} from './model/dangerous';
import {Container} from '../../service/model/container';
import {Injectable} from '@angular/core';
import {EdiHeader} from './model/edi-header';

@Injectable({
  providedIn: 'root'
})
export class EdiWriterService {

  private parser: xml2js;

  constructor(private http: HttpClient) {
    this.parser = new xml2js.Parser({trim: true, explicitArray: true});
  }

  async parseBayPlan2Text(bayPlan: BayPlan, ediVersion?: string): Promise<string> {
    const schemaArray: Array<SyntaxItem> = [];
    let schemaItem: SyntaxItem = new SyntaxItem('UNB', 0);
    schemaItem.segmentArray.push(new SegmentItem('s001', 'e0001', undefined));
    schemaItem.segmentArray.push(new SegmentItem('s001', 'e0002', undefined));
    schemaItem.segmentArray.push(new SegmentItem('s002.e0004', undefined, bayPlan.header.sender));
    schemaItem.segmentArray.push(new SegmentItem('s003.e0010', undefined, bayPlan.header.recipient));
    schemaItem.segmentArray.push(new SegmentItem('s004', 'e0017', bayPlan.header.preparationDate));
    schemaItem.segmentArray.push(new SegmentItem('s004', 'e0019', bayPlan.header.preparationTime));
    schemaItem.segmentArray.push(new SegmentItem('e0020', undefined, bayPlan.header.interchangeControlRefStart));
    schemaItem.segmentArray.push(new SegmentItem('e0032', undefined, bayPlan.header.communicationsAgreementId));
    schemaArray.push(schemaItem);

    schemaItem = new SyntaxItem('UNH', 0);
    schemaItem.segmentArray.push(new SegmentItem('e0062', undefined, bayPlan.header.messageRefNo));
    schemaItem.segmentArray.push(new SegmentItem('s009', 'e0065', bayPlan.header.messageType));
    schemaItem.segmentArray.push(new SegmentItem('s009', 'e0052', bayPlan.header.versionNo));
    schemaItem.segmentArray.push(new SegmentItem('s009', 'e0054', bayPlan.header.messageRefNo));
    schemaItem.segmentArray.push(new SegmentItem('s009', 'e0051', undefined));
    schemaItem.segmentArray.push(new SegmentItem('s009', 'e0057', undefined));
    schemaArray.push(schemaItem);

    schemaItem = new SyntaxItem('BGM', 0);
    // schemaItem.segmentArray.push(new SegmentItem('C002', 'e1001', bayPlan.header.documentType));
    // schemaItem.segmentArray.push(new SegmentItem('C002', 'e1000', bayPlan.header.documentName));
    schemaItem.segmentArray.push(new SegmentItem('e1004', undefined, bayPlan.header.documentNo));
    // schemaItem.segmentArray.push(new SegmentItem('C106', 'e1004', bayPlan.header.documentNo));
    // schemaItem.segmentArray.push(new SegmentItem('C106', 'e1056', bayPlan.header.documentVersion));
    // schemaItem.segmentArray.push(new SegmentItem('C106', 'e1060', bayPlan.header.documentRevision));
    schemaItem.segmentArray.push(new SegmentItem('e1225', undefined, bayPlan.header.messageFunction));
    schemaItem.segmentArray.push(new SegmentItem('e4343', undefined, bayPlan.header.documentResponseType));
    schemaItem.segmentArray.push(new SegmentItem('e1373', undefined, bayPlan.header.documentStatus));
    schemaArray.push(schemaItem);

    if (bayPlan.header.compilationDatetime) {
      schemaItem = new SyntaxItem('DTM', 0);
      schemaItem.segmentArray.push(new SegmentItem('c507', 'e2005', undefined));
      schemaItem.segmentArray.push(new SegmentItem('c507', 'e2380', bayPlan.header.compilationDatetime));
      schemaItem.segmentArray.push(new SegmentItem('c507', 'e2379', bayPlan.header.compilationDateTimeFormat));
      schemaArray.push(schemaItem);
    }

    // schemaItem = new SyntaxItem('RFF', 0);
    // schemaItem.segmentArray.push(new SegmentItem('c506', 'e1153', document.referenceType));
    // schemaItem.segmentArray.push(new SegmentItem('c506', 'e1154', document.referenceId));
    // schemaItem.segmentArray.push(new SegmentItem('c506', 'e1156', document.referenceDocument));
    // schemaItem.segmentArray.push(new SegmentItem('c506', 'e1056', document.referenceVersion));
    // schemaItem.segmentArray.push(new SegmentItem('c506', 'e1060', document.referenceRevision));
    // schemaArray.push(schemaItem);

    schemaItem = new SyntaxItem('TDT', 1);
    schemaItem.segmentArray.push(new SegmentItem('e8051', undefined, undefined));
    schemaItem.segmentArray.push(new SegmentItem('e8028', undefined, bayPlan.header.conveyanceRefNo));

    schemaItem.segmentArray.push(new SegmentItem('c040', 'e3127', bayPlan.header.carrier));
    schemaItem.segmentArray.push(new SegmentItem('c040', 'c040.e1131', undefined));
    schemaItem.segmentArray.push(new SegmentItem('c040', 'e3055', bayPlan.header.transportCarrierResponsible));
    schemaItem.segmentArray.push(new SegmentItem('c222', 'e8213', bayPlan.header.vesselCode));
    schemaItem.segmentArray.push(new SegmentItem('c222', 'c222.e1131', bayPlan.header.vesselCodeType));
    schemaItem.segmentArray.push(new SegmentItem('c222', 'c222.e3055', bayPlan.header.transportResponsible));
    schemaItem.segmentArray.push(new SegmentItem('c222', 'e8212', bayPlan.header.vesselName));
    schemaItem.segmentArray.push(new SegmentItem('c222', 'e8453', bayPlan.header.transportCountryCode));

    schemaItem.segmentArray.push(new SegmentItem('c040_15', 'e8213_15', bayPlan.header.vesselCode));
    schemaItem.segmentArray.push(new SegmentItem('c040_15', 'c040.e1131_15', bayPlan.header.vesselCodeType));
    schemaItem.segmentArray.push(new SegmentItem('c040_15', 'e3055_15', bayPlan.header.transportResponsible));
    schemaItem.segmentArray.push(new SegmentItem('c040_15', 'e8212_15', bayPlan.header.vesselName));
    schemaItem.segmentArray.push(new SegmentItem('c040_15', 'e8453_15', bayPlan.header.transportCountryCode));
    schemaItem.segmentArray.push(new SegmentItem('c222_15', 'e3127_15', bayPlan.header.carrier));
    schemaItem.segmentArray.push(new SegmentItem('c222_15', 'c222.e1131_15', undefined));
    schemaItem.segmentArray.push(new SegmentItem('c222_15', 'e3055_15', bayPlan.header.transportCarrierResponsible));
    // console.log(bayPlan.header);
    schemaArray.push(schemaItem);

    if (bayPlan.header.portCode) {
      schemaItem = new SyntaxItem('LOC', 1);
      schemaItem.segmentArray.push(new SegmentItem('e3227', undefined, '5'));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3225', bayPlan.header.portCode));
      schemaItem.segmentArray.push(new SegmentItem('e3225', undefined, bayPlan.header.portCode));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'c517.e1131', undefined));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'c517.e3055', bayPlan.header.portResponsibleAgency));
      if (bayPlan.header.portCountryId) {
        schemaItem.segmentArray.push(new SegmentItem('c519', 'e3223', bayPlan.header.portCountryId));
        schemaItem.segmentArray.push(new SegmentItem('c519', 'c519.e1131', undefined));
        schemaItem.segmentArray.push(new SegmentItem('c519', 'c519.e3055', bayPlan.header.portResponsibleAgency));
      }
      if (bayPlan.header.portStateId) {
        schemaItem.segmentArray.push(new SegmentItem('c553', 'e3223', bayPlan.header.portStateId));
        schemaItem.segmentArray.push(new SegmentItem('c553', 'e1131', undefined));
      }
      schemaArray.push(schemaItem);
    }

    if (bayPlan.header.nextPortCode) {
      schemaItem = new SyntaxItem('LOC', 1);
      schemaItem.segmentArray.push(new SegmentItem('e3227', undefined, '61'));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3225', bayPlan.header.nextPortCode));
      schemaItem.segmentArray.push(new SegmentItem('e3225', undefined, bayPlan.header.nextPortCode));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'c517.e1131', undefined));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'c517.e3055', bayPlan.header.nextPortResponsibleAgency));
      if (bayPlan.header.nextPortCountryId) {
        schemaItem.segmentArray.push(new SegmentItem('c519', 'e3223', bayPlan.header.nextPortCountryId));
        schemaItem.segmentArray.push(new SegmentItem('c519', 'c519.e1131', undefined));
        schemaItem.segmentArray.push(new SegmentItem('c519', 'c519.e3055', bayPlan.header.nextPortResponsibleAgency));
      }
      if (bayPlan.header.nextPortStateId) {
        schemaItem.segmentArray.push(new SegmentItem('c553', 'e3223', bayPlan.header.nextPortStateId));
        schemaItem.segmentArray.push(new SegmentItem('c553', 'e1131', undefined));
      }
      schemaArray.push(schemaItem);
    }

    if (bayPlan.header.id || bayPlan.header.updateDatetime || bayPlan.header.serviceLaneCode) {
      schemaItem = new SyntaxItem('LOC', 1);
      schemaItem.segmentArray.push(new SegmentItem('e3227', undefined, 'PB'));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3225', bayPlan.header.id + '^' + bayPlan.header.updateDatetime + '^' + bayPlan.header.serviceLaneCode));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e1131', undefined));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3055', undefined));
      schemaItem.segmentArray.push(new SegmentItem('c519', 'e3223', undefined));
      schemaItem.segmentArray.push(new SegmentItem('c519', 'e1131', undefined));
      schemaItem.segmentArray.push(new SegmentItem('c519', 'e3055', undefined));
      schemaItem.segmentArray.push(new SegmentItem('c553', 'e3223', undefined));
      schemaItem.segmentArray.push(new SegmentItem('c553', 'e1131', undefined));
      schemaArray.push(schemaItem);
    }

    this.registerSchedule(schemaArray, '131', bayPlan.header.ata);
    this.registerSchedule(schemaArray, '132', bayPlan.header.nextEta);
    this.registerSchedule(schemaArray, '133', bayPlan.header.etd);
    this.registerSchedule(schemaArray, '134', bayPlan.header.atd);

    if (bayPlan.header.loadingVoyageNo) {
      schemaItem = new SyntaxItem('RFF', 1);
      schemaItem.segmentArray.push(new SegmentItem('c506', 'e1153', 'VON'));
      schemaItem.segmentArray.push(new SegmentItem('c506', 'e1154', bayPlan.header.loadingVoyageNo));
      schemaArray.push(schemaItem);
    }

    schemaItem = new SyntaxItem('HAN', 1);
    schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'LOA'));
    schemaArray.push(schemaItem);
    this.parseStowages(schemaArray, bayPlan.containers, bayPlan.header);

    if (bayPlan.dischargeContainers.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'DIS'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, bayPlan.dischargeContainers, bayPlan.header);
    }

    if (bayPlan.shiftContainers.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'SHI'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, bayPlan.shiftContainers, bayPlan.header);
    }

    if (bayPlan.restowContainers.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'RES'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, bayPlan.restowContainers, bayPlan.header);
    }

    if (bayPlan.codContainers.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'COD'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, bayPlan.codContainers, bayPlan.header);
    }

    if (bayPlan.excessContainers.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'EXC'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, bayPlan.excessContainers, bayPlan.header);
    }

    if (bayPlan.balanceContainers.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'BAL'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, bayPlan.balanceContainers, bayPlan.header);
    }

    if (bayPlan.avoidContainers.length > 0) {
      schemaItem = new SyntaxItem('HAN', 1);
      schemaItem.segmentArray.push(new SegmentItem('c524.e4079', undefined, 'VOI'));
      schemaArray.push(schemaItem);
      this.parseStowages(schemaArray, bayPlan.avoidContainers, bayPlan.header);
    }

    schemaItem = new SyntaxItem('UNT', 0);
    schemaItem.segmentArray.push(new SegmentItem('e0074', undefined, bayPlan.header.segmentNo));
    schemaItem.segmentArray.push(new SegmentItem('e0062', undefined, bayPlan.header.referenceNo));
    schemaArray.push(schemaItem);

    schemaItem = new SyntaxItem('UNZ', 0);
    schemaItem.segmentArray.push(new SegmentItem('e0036', undefined, bayPlan.header.interchangeControlCount));
    schemaItem.segmentArray.push(new SegmentItem('e0020', undefined, bayPlan.header.interchangeControlRefEnd));
    schemaArray.push(schemaItem);

    if (ediVersion === undefined || ediVersion === '') {
      if (bayPlan.header.messageType !== undefined && bayPlan.header.messageType !== '' && bayPlan.header.smdgVersion !== undefined && bayPlan.header.smdgVersion.length === 6) {
        ediVersion = bayPlan.header.messageType + bayPlan.header.smdgVersion.substring(4, 5) + '.' + bayPlan.header.smdgVersion.substring(5, 6);
      }
    }
    return this.parseSchema2Text(schemaArray, ediVersion);
  }
  private registerSchedule(schemaArray: Array<SyntaxItem>, dateType: string, date: string) {
    if (date) {
      const schemaItem = new SyntaxItem('DTM', 1);
      schemaItem.segmentArray.push(new SegmentItem('c507', 'e2005', dateType));
      schemaItem.segmentArray.push(new SegmentItem('c507', 'e2380', date));
      schemaItem.segmentArray.push(new SegmentItem('c507', 'e2379', undefined));
      schemaArray.push(schemaItem);
    }
  }
  private parseStowages(schemaArray: Array<SyntaxItem>, containers: Array<Container>, header: EdiHeader) {
    for (const container of containers) {
      let schemaItem: SyntaxItem = new SyntaxItem('LOC', 2);
      schemaItem.segmentArray.push(new SegmentItem('e3227', undefined, undefined));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3225', container.stowage));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3055', container.stowageFormat));
      schemaArray.push(schemaItem);

      schemaItem = new SyntaxItem('GID', 1);
      schemaItem.segmentArray.push(new SegmentItem('c213', 'e7224', container.packageQuantity));
      schemaItem.segmentArray.push(new SegmentItem('c213', 'e7065', container.packageType));
      schemaArray.push(schemaItem);

      for (const cargo of container.cargoNatures) {
        schemaItem = new SyntaxItem('GDS', 1);
        schemaItem.segmentArray.push(new SegmentItem('c703.e7085', undefined, cargo));
        schemaArray.push(schemaItem);
      }

      // Starting single remarks
      // this.registerRemark(schemaArray, 'AAA', container.descriptionOfGoods);
      // this.registerRemark(schemaArray, 'HAN', container.handlingInstruction);
      // this.registerRemark(schemaArray, 'CLR', container.loadingRemark);
      // this.registerRemark(schemaArray, 'AAI', container.generalInformation);
      // this.registerRemark(schemaArray, 'ZZZ', container.mutuallyDefinedRemark);
      //
      // if (container.hasVgmInfo()) {
      //   schemaItem = new SyntaxItem('FTX', 2);
      //   schemaItem.segmentArray.push(new SegmentItem('e4451', undefined, 'AAY'));
      //   schemaItem.segmentArray.push(new SegmentItem('c107.4441', undefined, container.vgmCertificationCode));
      //   schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.1', container.vgmInfo1));
      //   schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.2', container.vgmInfo2));
      //   schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.3', container.vgmInfo3));
      //   schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.4', container.vgmInfo4));
      //   schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.5', container.vgmInfo5));
      //   schemaArray.push(schemaItem);
      // }
      // Ended single remarks

      // console.log(container.freeTexts);
      for (const remarkArray of container.freeTexts.values()) {
        for (const remark of remarkArray) {
          schemaItem = new SyntaxItem('FTX', 2);
          schemaItem.segmentArray.push(new SegmentItem('e4451', undefined, remark.type));
          schemaItem.segmentArray.push(new SegmentItem('c107.4441', undefined, remark.vgmCertificationCode));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.1', remark.remark));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.2', remark.vgmInfo2));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.3', remark.vgmInfo3));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.4', remark.vgmInfo4));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.5', remark.vgmInfo5));
          schemaArray.push(schemaItem);
        }
      }

      if (container.grossWeight) {
        schemaItem = new SyntaxItem('MEA', 1);
        schemaItem.segmentArray.push(new SegmentItem('e6311', undefined, 'WT'));
        schemaItem.segmentArray.push(new SegmentItem('c174', 'e6411', container.grossWeightUnit));
        schemaItem.segmentArray.push(new SegmentItem('c174', 'e6314', container.grossWeight));
        schemaArray.push(schemaItem);
      }
      if (container.vgmWeight) {
        schemaItem = new SyntaxItem('MEA', 1);
        schemaItem.segmentArray.push(new SegmentItem('e6311', undefined, 'VGM'));
        schemaItem.segmentArray.push(new SegmentItem('c174', 'e6411', container.vgmWeightUnit));
        schemaItem.segmentArray.push(new SegmentItem('c174', 'e6314', container.vgmWeight));
        schemaArray.push(schemaItem);
      }

      if (container.overDimFront) {
        schemaItem = new SyntaxItem('DIM', 1);
        schemaItem.segmentArray.push(new SegmentItem('e6145', undefined, '5'));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6411', container.overDimFrontUnit));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6168', container.overDimFront));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6140', undefined));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6008', undefined));
        schemaArray.push(schemaItem);
      }
      if (container.overDimBack) {
        schemaItem = new SyntaxItem('DIM', 1);
        schemaItem.segmentArray.push(new SegmentItem('e6145', undefined, '6'));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6411', container.overDimBackUnit));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6168', container.overDimBack));
        schemaArray.push(schemaItem);
      }
      if (container.overDimRight) {
        schemaItem = new SyntaxItem('DIM', 1);
        schemaItem.segmentArray.push(new SegmentItem('e6145', undefined, '7'));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6411', container.overDimRightUnit));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6168', undefined));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6140', container.overDimRight));
        schemaArray.push(schemaItem);
      }
      if (container.overDimLeft) {
        schemaItem = new SyntaxItem('DIM', 1);
        schemaItem.segmentArray.push(new SegmentItem('e6145', undefined, '8'));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6411', container.overDimLeftUnit));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6168', undefined));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6140', container.overDimLeft));
        schemaArray.push(schemaItem);
      }
      if (container.overDimHeight) {
        schemaItem = new SyntaxItem('DIM', 1);
        schemaItem.segmentArray.push(new SegmentItem('e6145', undefined, '9'));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6411', container.overDimHeightUnit));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6168', undefined));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6140', undefined));
        schemaItem.segmentArray.push(new SegmentItem('c211', 'e6008', container.overDimHeight));
        schemaArray.push(schemaItem);
      }

      if (container.temperature) {
        schemaItem = new SyntaxItem('TMP', 1);
        schemaItem.segmentArray.push(new SegmentItem('e6145', undefined, undefined));
        schemaItem.segmentArray.push(new SegmentItem('c239', 'e6246', container.temperature));
        schemaItem.segmentArray.push(new SegmentItem('c239', 'e6411', container.temperatureUnit));
        schemaArray.push(schemaItem);
      }

      schemaItem = new SyntaxItem('RNG', 1);
      schemaItem.segmentArray.push(new SegmentItem('e6167', undefined, container.temperatureRangeType));
      schemaItem.segmentArray.push(new SegmentItem('c280', 'e6411', container.temperatureRangeUnit));
      schemaItem.segmentArray.push(new SegmentItem('c280', 'e6162', container.temperatureRangeMin));
      schemaItem.segmentArray.push(new SegmentItem('c280', 'e6152', container.temperatureRangeMax));
      schemaArray.push(schemaItem);

      if (header.smdgVersion === 'SMDG15') {
        this.registerPortCode(schemaArray, '6', container.pol, container.polAgency, container.polTerminalCode);
      } else {
        this.registerPortCode(schemaArray, '9', container.pol, container.polAgency, container.polTerminalCode);
      }
      if (header.smdgVersion === 'SMDG15') {
        this.registerPortCode(schemaArray, '12', container.pod, container.podAgency, container.podTerminalCode);
      } else {
        this.registerPortCode(schemaArray, '11', container.pod, container.podAgency, container.podTerminalCode);
      }
      this.registerPortCode(schemaArray, '13', container.tsPort, container.tsPortAgency, container.tsTerminalCode);
      this.registerPortCode(schemaArray, '64', container.optPod1, container.optPod1Agency, container.optPod1TerminalCode);
      this.registerPortCode(schemaArray, '68', container.optPod2, container.optPod2Agency, container.optPod2TerminalCode);
      this.registerPortCode(schemaArray, '70', container.optPod3, container.optPod3Agency, container.optPod3TerminalCode);
      this.registerPortCode(schemaArray, '71', container.optPod4, container.optPod4Agency, container.optPod4TerminalCode);
      this.registerPortCode(schemaArray, '74', container.optPod5, container.optPod5Agency, container.optPod5TerminalCode);
      this.registerPortCode(schemaArray, '76', container.por, container.porAgency, container.porTerminalCode);
      this.registerPortCode(schemaArray, '83', container.placeOfDelivery, container.placeOfDeliveryAgency, container.placeOfDeliveryTerminalCode);
      this.registerPortCode(schemaArray, '97', container.optPod, container.optPodAgency, container.optPodTerminalCode);
      this.registerPortCode(schemaArray, '152', container.nPod, container.nPodAgency, container.nPodTerminalCode);
      this.registerPortCode(schemaArray, '65', container.fPod, container.fPodAgency, container.fPodTerminalCode);

      this.registerReference(schemaArray, 'BM', container.blNo);
      this.registerReference(schemaArray, 'BN', container.bookingNo);
      this.registerReference(schemaArray, 'ET', container.leadingStowage);

      schemaItem = new SyntaxItem('EQD', 2);
      schemaItem.segmentArray.push(new SegmentItem('e8053', undefined, container.equipmentType));
      schemaItem.segmentArray.push(new SegmentItem('c237.e8260', undefined, container.containerNo));
      schemaItem.segmentArray.push(new SegmentItem('c224.e8155', undefined, container.isoSizeType));
      schemaItem.segmentArray.push(new SegmentItem('e8249', undefined, container.deliveryCode));
      if (container.fullEmpty === 'M') {
        schemaItem.segmentArray.push(new SegmentItem('e8169', undefined, '4'));
      } else if (container.fullEmpty === 'F') {
        schemaItem.segmentArray.push(new SegmentItem('e8169', undefined, '5'));
      }
      schemaArray.push(schemaItem);

      for (const equipment of container.bundledContainers) {
        schemaItem = new SyntaxItem('EQA', 2);
        schemaItem.segmentArray.push(new SegmentItem('e8053', undefined, undefined));
        schemaItem.segmentArray.push(new SegmentItem('c237.e8260', undefined, equipment));
        schemaArray.push(schemaItem);
      }

      schemaItem = new SyntaxItem('NAD', 2);
      schemaItem.segmentArray.push(new SegmentItem('e3035', undefined, container.carrierParty));
      schemaItem.segmentArray.push(new SegmentItem('c082', 'e3039', container.carrier));
      schemaItem.segmentArray.push(new SegmentItem('c082', 'e1131', undefined));
      schemaItem.segmentArray.push(new SegmentItem('c082', 'e3055', container.carrierAgency));
      schemaArray.push(schemaItem);

      for (const dangerous of container.dangerousGoods) {
        schemaItem = new SyntaxItem('DGS', 2);
        schemaItem.segmentArray.push(new SegmentItem('e8273', undefined, undefined));
        schemaItem.segmentArray.push(new SegmentItem('c205', 'e8351', dangerous.imdg));
        schemaItem.segmentArray.push(new SegmentItem('c205', 'e8078', dangerous.hazardPageNo));
        schemaItem.segmentArray.push(new SegmentItem('c234.e7124', undefined, dangerous.unno));
        schemaItem.segmentArray.push(new SegmentItem('c233', 'e7106', dangerous.shipmentFlashPoint));
        schemaItem.segmentArray.push(new SegmentItem('c233', 'e6411', dangerous.measureUnitCode));
        schemaItem.segmentArray.push(new SegmentItem('e8339', undefined, dangerous.packingGroup));
        schemaItem.segmentArray.push(new SegmentItem('e8364', undefined, dangerous.emsNo));
        schemaItem.segmentArray.push(new SegmentItem('e8410', undefined, dangerous.mfag));
        schemaItem.segmentArray.push(new SegmentItem('c235', 'e8158', dangerous.hazardIdUpper));
        schemaItem.segmentArray.push(new SegmentItem('c235', 'e8186', dangerous.hazardIdLower));
        schemaItem.segmentArray.push(new SegmentItem('c236', 'e8246', dangerous.goodsLabelMark1));
        schemaItem.segmentArray.push(new SegmentItem('c236', 'e8246.1', dangerous.goodsLabelMark2));
        schemaItem.segmentArray.push(new SegmentItem('c236', 'e8246.2', dangerous.goodsLabelMark3));
        schemaArray.push(schemaItem);

        for (const remark of dangerous.remarks) {
          schemaItem = new SyntaxItem('FTX', 4);
          schemaItem.segmentArray.push(new SegmentItem('e4451', undefined, remark.freeTextCode));
          schemaItem.segmentArray.push(new SegmentItem('c107.4441', undefined, remark.freeTextValueCode));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.1', remark.freeText1));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.2', remark.freeText2));
          schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.3', remark.freeText3));
          schemaArray.push(schemaItem);
        }
      }

      if (container.overSlotLeft) {
        this.overSlotRow(container, container.overSlotLeft, schemaArray, true);
      }
      if (container.overSlotRight) {
        this.overSlotRow(container, container.overSlotRight, schemaArray, false);
      }
      if (container.overSlotHeight) {
        this.overSlotTier(container, schemaArray, false);
      }
      if (container.overSlotFront) {
        this.overSlotBay(container, container.overSlotFront, schemaArray, true);
      }
      if (container.overSlotBack) {
        this.overSlotBay(container, container.overSlotBack, schemaArray, false);
      }
    }
  }
  private overSlotRow(container: Container, overSlot: number, schemaArray: Array<SyntaxItem>, left: boolean) {
    let row = Number(container.stowage.substring(container.stowage.length - 4, container.stowage.length - 2));
    for (let i = 0; i < overSlot; i++) {
      if (left) {
        if (row === 0) {
          row = 1;
        } else if (row % 2 === 0) {
          row = Math.abs(row - 2);
        } else {
          row = Math.abs(row + 2);
        }
      } else {
        if (row === 1) {
          row = 0;
        } else if (row % 2 === 0) {
          row = Math.abs(row + 2);
        } else {
          row = Math.abs(row - 2);
        }
      }
      let rowNo = row.toString();
      if (rowNo.length ===  1) {
        rowNo = '0' + rowNo;
      }
      let schemaItem = new SyntaxItem('LOC', 2);
      schemaItem.segmentArray.push(new SegmentItem('e3227', undefined, undefined));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3225', container.stowage.substring(0, container.stowage.length - 4) + rowNo
        + container.stowage.substring(container.stowage.length - 2, container.stowage.length)));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3055', container.stowageFormat));
      schemaArray.push(schemaItem);

      schemaItem = new SyntaxItem('MEA', 1);
      schemaItem.segmentArray.push(new SegmentItem('e6311', undefined, 'WT'));
      schemaItem.segmentArray.push(new SegmentItem('c174', 'e6411', 'KGM'));
      schemaItem.segmentArray.push(new SegmentItem('c174', 'e6314', '0'));
      schemaArray.push(schemaItem);

      schemaItem = new SyntaxItem('RFF', 2);
      schemaItem.segmentArray.push(new SegmentItem('c506', 'e1153', 'ET'));
      schemaItem.segmentArray.push(new SegmentItem('c506', 'e1154', container.stowage));
      schemaArray.push(schemaItem);

      schemaItem = new SyntaxItem('EQD', 2);
      schemaItem.segmentArray.push(new SegmentItem('e8053', undefined, 'BB'));
      schemaItem.segmentArray.push(new SegmentItem('c237.e8260', undefined, ''));
      schemaArray.push(schemaItem);
    }
  }
  private overSlotBay(container: Container, overSlot: number, schemaArray: Array<SyntaxItem>, front: boolean) {
    let bay = Number(container.stowage.substring(0, container.stowage.length - 4));
    for (let i = 0; i < overSlot; i++) {
      if (front) {
        bay--;
      } else {
        bay++;
      }
      let bayNo = bay.toString();
      if (bayNo.length ===  2) {
        bayNo = '0' + bayNo;
      } else if (bayNo.length ===  1) {
        bayNo = '00' + bayNo;
      }
      let schemaItem = new SyntaxItem('LOC', 2);
      schemaItem.segmentArray.push(new SegmentItem('e3227', undefined, undefined));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3225', bayNo + container.stowage.substring(container.stowage.length - 4, container.stowage.length)));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3055', container.stowageFormat));
      schemaArray.push(schemaItem);

      schemaItem = new SyntaxItem('MEA', 1);
      schemaItem.segmentArray.push(new SegmentItem('e6311', undefined, 'WT'));
      schemaItem.segmentArray.push(new SegmentItem('c174', 'e6411', 'KGM'));
      schemaItem.segmentArray.push(new SegmentItem('c174', 'e6314', '0'));
      schemaArray.push(schemaItem);

      schemaItem = new SyntaxItem('RFF', 2);
      schemaItem.segmentArray.push(new SegmentItem('c506', 'e1153', 'ET'));
      schemaItem.segmentArray.push(new SegmentItem('c506', 'e1154', container.stowage));
      schemaArray.push(schemaItem);

      schemaItem = new SyntaxItem('EQD', 2);
      schemaItem.segmentArray.push(new SegmentItem('e8053', undefined, 'BB'));
      schemaItem.segmentArray.push(new SegmentItem('c237.e8260', undefined, ''));
      schemaArray.push(schemaItem);
    }
  }
  private overSlotTier(container: Container, schemaArray: Array<SyntaxItem>, left: boolean) {
    const tier = Number(container.stowage.substring(container.stowage.length - 2, container.stowage.length));
    for (let i = 0; i < container.overSlotHeight; i++) {
      let tierNo = (tier + 2 * (i + 1)).toString();
      if (tierNo.length ===  1) {
        tierNo = '0' + tierNo;
      }
      let schemaItem = new SyntaxItem('LOC', 2);
      schemaItem.segmentArray.push(new SegmentItem('e3227', undefined, undefined));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3225', container.stowage.substring(0, container.stowage.length - 2) + tierNo));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3055', container.stowageFormat));
      schemaArray.push(schemaItem);

      schemaItem = new SyntaxItem('MEA', 1);
      schemaItem.segmentArray.push(new SegmentItem('e6311', undefined, 'WT'));
      schemaItem.segmentArray.push(new SegmentItem('c174', 'e6411', 'KGM'));
      schemaItem.segmentArray.push(new SegmentItem('c174', 'e6314', '0'));
      schemaArray.push(schemaItem);

      schemaItem = new SyntaxItem('RFF', 2);
      schemaItem.segmentArray.push(new SegmentItem('c506', 'e1153', 'ET'));
      schemaItem.segmentArray.push(new SegmentItem('c506', 'e1154', container.stowage));
      schemaArray.push(schemaItem);

      schemaItem = new SyntaxItem('EQD', 2);
      schemaItem.segmentArray.push(new SegmentItem('e8053', undefined, 'BB'));
      schemaItem.segmentArray.push(new SegmentItem('c237.e8260', undefined, ''));
      schemaArray.push(schemaItem);
    }
  }
  private registerRemark(schemaArray: Array<SyntaxItem>, dataType: string, remark: string) {
    if (remark) {
      const schemaItem = new SyntaxItem('FTX', 2);
      schemaItem.segmentArray.push(new SegmentItem('e4451', undefined, dataType));
      schemaItem.segmentArray.push(new SegmentItem('c107.4441', undefined, ''));
      schemaItem.segmentArray.push(new SegmentItem('c108', 'e4440.2', remark));
      schemaItem.segmentArray.push(new SegmentItem('c108.4440', undefined, remark));
      // schemaItem.segmentArray.push(new SegmentItem('c108.4440', undefined, remark));
      schemaArray.push(schemaItem);
    }
  }
  private async registerPortCode(schemaArray: Array<SyntaxItem>, type: string, portId: string, portAgencyCode: string, terminalId: string) {
    if (portId) {
      const schemaItem = new SyntaxItem('LOC', 2.5);
      schemaItem.segmentArray.push(new SegmentItem('e3227', undefined, type));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'e3225', portId));
      schemaItem.segmentArray.push(new SegmentItem('e3225', undefined, portId));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'c517.e1131', '139'));
      schemaItem.segmentArray.push(new SegmentItem('c517', 'c517.e3055', portAgencyCode));
      schemaItem.segmentArray.push(new SegmentItem('c519', 'e3223', terminalId));
      schemaItem.segmentArray.push(new SegmentItem('c519', 'c519.e1131', undefined));
      schemaItem.segmentArray.push(new SegmentItem('c519', 'c519.e3055', undefined));
      schemaArray.push(schemaItem);
    }
  }
  private registerReference(schemaArray: Array<SyntaxItem>, type: string, referenceNo: string) {
    if (referenceNo) {
      const schemaItem = new SyntaxItem('RFF', 2);
      schemaItem.segmentArray.push(new SegmentItem('c506', 'e1153', type));
      schemaItem.segmentArray.push(new SegmentItem('c506', 'e1154', referenceNo));
      schemaArray.push(schemaItem);
    }
  }

  private async parseSchema2Text(schemaArray: Array<SyntaxItem>, baplieVersion?: string): Promise<string> {

    let schemaFile = '/assets/schema/BAPLIE22.xml';

    if (baplieVersion === 'BAPLIE3.1') {
      schemaFile = '/assets/schema/BAPLIE31.xml';

    } else if (baplieVersion === 'BAPLIE1.5') {
      schemaFile = '/assets/schema/BAPLIE15.xml';

    } else if (baplieVersion === 'MOVINS2.0') {
      schemaFile = '/assets/schema/MOVINS20.xml';

    } else if (baplieVersion === 'MOVINS2.1') {
      schemaFile = '/assets/schema/MOVINS21.xml';
    }

    const schema = await this.http.get(schemaFile, {responseType: 'text'}).toPromise();
    const parser: xml2js = new xml2js.Parser({trim: true, explicitArray: true});

    let syntax = '';
    parser.parseString(schema, function _(err, data) {
      syntax = data;
    });

    let ediText = '';
    const ediReaderService: BaplieReaderService = new BaplieReaderService(undefined, undefined);
    for (const item of schemaArray) {
      ediReaderService.setSyntaxGroup(Number(item.syntaxGroup));
      const groupItem = ediReaderService.findSegmentSyntax(item.segmentId, syntax);
      if (!groupItem) {
        continue;
      }
      let text = '';
      text += item.segmentId;
      let segmentId = '';
      let tmpText = '';
      for (const segment of item.segmentArray) {
        if (segmentId !== segment.segmentId) {
          text += this.removeDelimiter(tmpText, ':');
          tmpText = '';
        }
        let elementItem;
        // tslint:disable-next-line:prefer-for-of
        for (let s = 0; s < groupItem.segment.length; s = s + 1) {
          if (groupItem.segment[s].$.id === segment.segmentId) {
            if (segment.elementId === undefined) {
              elementItem = groupItem.segment[s];
            } else {
              // tslint:disable-next-line:prefer-for-of
              for (let e = 0; e < groupItem.segment[s].element.length; e = e + 1) {
                if (groupItem.segment[s].element[e].$.id === segment.elementId) {
                  elementItem = groupItem.segment[s].element[e];
                  break;
                }
              }
            }
            break;
          }
        }
        if (!elementItem) {
          continue;
        }
        let symbolCount = 1;
        if (elementItem && elementItem.$.symbol) {
          symbolCount = Number(elementItem.$.symbol);
        }
        const delimiter: string = segmentId !== segment.segmentId ? '+' : ':';
        for (let i = 0; i < symbolCount; i++) {
          tmpText += delimiter;
        }
        if (elementItem && elementItem.$.constant !== undefined) {
          tmpText += elementItem.$.constant;
        } else if (segment.value !== undefined) {
          tmpText += segment.value;
        }
        segmentId = segment.segmentId;
      }
      const tmpTexts2 = tmpText.replace('+', '').split(':');
      // if (tmpTexts2.length > 0 && tmpTexts2[0].length !== 0) {
      if (tmpTexts2.length > 0) {
        text += this.removeDelimiter(tmpText, ':');
      }
      const newText = this.removeDelimiter(text, '+');
      if (newText.length > 4 || groupItem.$.mandatory === 'M') {
        ediText += newText + '\'\n';
      }
    }
    return ediText;
  }

  private removeDelimiter(value: string, delimiter: string): string {
    for (let i = value.length; i > 0; i--) {
      if (value.substring(i - 1, i) !== delimiter) {
        return value.substring(0, i);
      }
    }
    return '';
  }
}
