import {ContainerEquipment} from './container-equipment';

export class Equipment {
  type: string;
  id: string;
  sizeType: string;
  statusCode: string;
  fullEmpty: string;
  sizeTypeNonIso: string;
  constructor() {
  }
}
