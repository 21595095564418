<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="tableContextMenu">
</div>
<mat-menu #tableContextMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="executeCopy()">Copy</button>
<!--    <button mat-menu-item (click)="filterContainers()" *ngIf="selection.selected.length == 1">Filter containers like these</button>-->
  </ng-template>
</mat-menu>
<div fxLayout="column" fxLayoutAlign=" stretch" >
  <div fxLayout="row" fxLayoutAlign="end center">
    <button mat-icon-button class="action" (click)="goToBayPlan()" *ngIf="bayPlanButtonVisible">
      <mat-icon svgIcon="bay_view"></mat-icon>
    </button>
    <button mat-icon-button class="action" (click)="goToCargoList()" *ngIf="editListButtonVisible">
      <mat-icon>view_list</mat-icon>
    </button>
    <button mat-icon-button class="action"
            matTooltip="Export"
            matTooltipClass="tooltip"
            [matMenuTriggerFor]="exportMenu">
      <mat-icon>file_download</mat-icon>
    </button>
    <mat-menu #exportMenu="matMenu">
      <button mat-menu-item (click)="exportToExcel(true)">
        <!--          <mat-icon>dialpad</mat-icon>-->
        <span>Excel file with visible columns</span>
      </button>
      <button mat-menu-item (click)="exportToExcel(false)">
        <!--          <mat-icon>voicemail</mat-icon>-->
        <span>Excel file with all columns</span>
      </button>
    </mat-menu>
  </div>
  <table mat-table id="editTable"
         [dataSource]="dataSource" matSort
         class="mat-elevation-z3" class="topics" >
    <ng-container matColumnDef="select" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element; let i = index"
          (mousedown)="mouseDownOnCell($event, element, i, selectionBoxColumn)"
          (mouseup)="mouseUpOnCell($event, i, selectionBoxColumn)"
          (mouseenter)="mouseEnterOnCell($event, i, selectionBoxColumn)"
          (mouseleave)="mouseLeaveOnCell($event, i, selectionBoxColumn)"
      >
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(element) : null"
                      [checked]="selection.isSelected(element)"
                      [aria-label]="checkboxLabel(element)">
        </mat-checkbox>
        <container *ngIf="element.modified" style="margin-left: 4px">*</container>
      </td>
    </ng-container>
    <ng-container *ngFor="let column of columns" matColumnDef="{{column.code}}" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header
          (mousedown)="mouseDownOnHeader(column)"
          class="header"
          matTooltip="{{getTooTip(column.code)}}"
          matTooltipClass="tooltip"><div [ngClass]="getStyleClass('', column.code)" class="header">{{getColumnName(column.code)}}</div></th>
      <td mat-cell *matCellDef="let element; let i = index"
          (keydown)="keyDownOnCell($event, element, i, column)"
          (mousedown)="mouseDownOnCell($event, element, i, column)"
          (mouseup)="mouseUpOnCell($event, i, column)"
          (mouseenter)="mouseEnterOnCell($event, i, column)"
          (mouseleave)="mouseLeaveOnCell($event, i, column)"
          [ngClass]="getTdStyleClass(i, column, element)"
      >
        <div [ngClass]="getStyleClass(element, column.code)"
             [style.background]="isColoredColumn(column)? getBackColor(element, column) : ''"
             [style.color]="isColoredColumn(column)? getForeColor(element, column) : ''"
        >
          {{getValueFormatted(element, column.code)}}
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="headerCodes; sticky: true" ></tr>
    <tr mat-row *matRowDef="let row; columns: headerCodes;let i = index"
        (contextmenu)="onContextMenu($event, row)"
        [ngClass]="{ 'etable-selected': isReadOnlyRowSelected(row)}"
    >
  </table>
  <mat-paginator #paginator [pageSizeOptions]="[20]" showFirstLastButtons></mat-paginator>
</div>
